exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-common-imageView--image-view-index {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  padding: 10% 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.7);\n  z-index: 3; }\n  .src-components-common-imageView--image-view-index img {\n    cursor: -webkit-zoom-out;\n    cursor: zoom-out; }\n", ""]);

// exports
exports.locals = {
	"image-view": "src-components-common-imageView--image-view-index"
};