import baibai from '../../../assets/image/star/星球君表情包拜拜.gif';
import bixin from '../../../assets/image/star/星球君表情包比心.gif';
import nuli from '../../../assets/image/star/星球君表情包不想努力了.gif';
import chongya from '../../../assets/image/star/星球君表情包冲鸭.gif';
import dakeng from '../../../assets/image/star/星球君表情包大坑.gif';
import hongbao from '../../../assets/image/star/星球君表情包发红包.gif';
import gan from '../../../assets/image/star/星球君表情包干就完了.gif';
import ha from '../../../assets/image/star/星球君表情包哈哈哈哈哈.gif';
import hai from '../../../assets/image/star/星球君表情包嗨.gif';
import ganga from '../../../assets/image/star/星球君表情包好尴尬啊.gif';
import gandong from '../../../assets/image/star/星球君表情包好感动啊.gif';
import xipin from '../../../assets/image/star/星球君表情包你品你细品.gif';
import xinku from '../../../assets/image/star/星球君表情包您辛苦啦.gif';
import xiexie from '../../../assets/image/star/星球君表情包谢谢.gif';
import ye from '../../../assets/image/star/星球君表情包耶.gif';
import yue from '../../../assets/image/star/星球君表情包您的余额已不足.gif';
import suoha from '../../../assets/image/star/星球君表情包梭哈.gif';

// import emojiListHeader from '../../../assets/image/biaoqing-8@2x.png';
const emojiListHeader =
  'https://piccdn.0daily.com/202212/14094205/7wwv1hc5j3j0prmx.png';
import starListHeader from '../../../assets/image/starListHeader.png';

// const emojiList = [
//   { name: ':grinning:', content: '😀', num: '&#x1F600;' },
//   { name: ':smiley:', content: '😃', num: '&#x1f603;' },
//   { name: ':smile:', content: '😄', num: '&#x1f604;' },
//   { name: ':grin:', content: '😁', num: '&#x1f601;' },
//   { name: ':laughing:', content: '😆', num: '&#x1f606;' },
//   { name: ':sweat_smile:', content: '😅', num: '&#x1f605;' },
//   { name: ':joy:', content: '😂', num: '&#x1f602;' },
//   { name: ':rofl:', content: '🤣', num: '&#x1f923;' },
//   { name: ':blush:', content: '😊', num: '&#x1f60a;' },
//   { name: ':innocent:', content: '😇', num: '&#x1f607;' },
//   { name: ':slight_smile:', content: '🙂', num: '&#x1f642;' },
//   { name: ':upside_down:', content: '🙃', num: '&#x1f643;' },
//   { name: ':wink:', content: '😉', num: '&#x1f609;' },
//   { name: ':relieved:', content: '😌', num: '&#x1f60c;' },
//   { name: ':smiling_face_with_tear:', content: '🥲', num: '&#x1f972;' },
//   { name: ':heart_eyes:', content: '😍', num: '&#x1f60d;' },
//   { name: ':smiling_face_with_3_hearts:', content: '🥰', num: '&#x1f970;' },
//   { name: ':kissing_heart:', content: '😘', num: '&#x1f618;' },
//   { name: ':kissing:', content: '😗', num: '&#x1f617;' },
//   { name: ':kissing_smiling_eyes:', content: '😙', num: '&#x1f619;' },
//   { name: ':kissing_closed_eyes:', content: '😚', num: '&#x1f61a;' },
//   { name: ':yum:', content: '😋', num: '&#x1f60b;' },
//   { name: ':stuck_out_tongue:', content: '😛', num: '&#x1f61b;' },
//   { name: ':stuck_out_tongue_closed_eyes:', content: '😝', num: '&#x1f61d;' },
//   { name: ':stuck_out_tongue_winking_eye:', content: '😜', num: '&#x1f61c;' },
//   { name: ':zany_face:', content: '🤪', num: '&#x1f92a;' },
//   { name: ':face_with_raised_eyebrow:', content: '🤨', num: '&#x1f928;' },
//   { name: ':face_with_monocle:', content: '🧐', num: '&#x1f9d0;' },
//   { name: ':nerd:', content: '🤓', num: '&#x1f913;' },
//   { name: ':sunglasses:', content: '😎', num: '&#x1f60e;' },
//   { name: ':star_struck:', content: '🤩', num: '&#x1f929;' },
//   { name: ':partying_face:', content: '🥳', num: '&#x1f973;' },
//   { name: ':smirk:', content: '😏', num: '&#x1f60f;' },
//   { name: ':unamused:', content: '😒', num: '&#x1f612;' },
//   { name: ':disappointed:', content: '😞', num: '&#x1f61e;' },
//   { name: ':pensive:', content: '😔', num: '&#x1f614;' },
//   { name: ':worried:', content: '😟', num: '&#x1f61f;' },
//   { name: ':confused:', content: '😕', num: '&#x1f615;' },
//   { name: ':slight_frown:', content: '🙁', num: '&#x1f641;' },
//   { name: ':persevere:', content: '😣', num: '&#x1f623;' },
//   { name: ':confounded:', content: '😖', num: '&#x1f616;' },
//   { name: ':tired_face:', content: '😫', num: '&#x1f62b;' },
//   { name: ':weary:', content: '😩', num: '&#x1f629;' },
//   { name: ':pleading_face:', content: '🥺', num: '&#x1f97a;' },
//   { name: ':cry:', content: '😢', num: '&#x1f622;' },
//   { name: ':sob:', content: '😭', num: '&#x1f62d;' },
//   { name: ':triumph:', content: '😤', num: '&#x1f624;' },
//   { name: ':angry:', content: '😠', num: '&#x1f620;' },
//   { name: ':rage:', content: '😡', num: '&#x1f621;' },
//   { name: ':face_with_symbols_over_mouth:', content: '🤬', num: '&#x1f92c;' },
//   { name: ':exploding_head:', content: '🤯', num: '&#x1f92f;' },
//   { name: ':flushed:', content: '😳', num: '&#x1f633;' },
//   { name: ':hot_face:', content: '🥵', num: '&#x1f975;' },
//   { name: ':cold_face:', content: '🥶', num: '&#x1f976;' },
//   { name: ':scream:', content: '😱', num: '&#x1f631;' },
//   { name: ':fearful:', content: '😨', num: '&#x1f628;' },
//   { name: ':cold_sweat:', content: '😰', num: '&#x1f630;' },
//   { name: ':disappointed_relieved:', content: '😥', num: '&#x1f625;' },
//   { name: ':sweat:', content: '😓', num: '&#x1f613;' },
//   { name: ':hugging:', content: '🤗', num: '&#x1f917;' },
//   { name: ':thinking:', content: '🤔', num: '&#x1f914;' },
//   { name: ':face_with_hand_over_mouth:', content: '🤭', num: '&#x1f92d;' },
//   { name: ':yawning_face:', content: '🥱', num: '&#x1f971;' },
//   { name: ':shushing_face:', content: '🤫', num: '&#x1f92b;' },
//   { name: ':lying_face:', content: '🤥', num: '&#x1f925;' },
//   { name: ':no_mouth:', content: '😶', num: '&#x1f636;' },
//   { name: ':neutral_face:', content: '😐', num: '&#x1f610;' },
//   { name: ':expressionless:', content: '😑', num: '&#x1f611;' },
//   { name: ':grimacing:', content: '😬', num: '&#x1f62c;' },
//   { name: ':hushed:', content: '😯', num: '&#x1f62f;' },
//   { name: ':frowning:', content: '😦', num: '&#x1f626;' },
//   { name: ':anguished:', content: '😧', num: '&#x1f627;' },
//   { name: ':open_mouth:', content: '😮', num: '&#x1f62e;' },
//   { name: ':astonished:', content: '😲', num: '&#x1f632;' },
//   { name: ':sleeping:', content: '😴', num: '&#x1f634;' },
//   { name: ':drooling_face:', content: '🤤', num: '&#x1f924;' },
//   { name: ':sleepy:', content: '😪', num: '&#x1f62a;' },
//   { name: ':dizzy_face:', content: '😵', num: '&#x1f635;' },
//   { name: ':zipper_mouth:', content: '🤐', num: '&#x1f910;' },
//   { name: ':woozy_face:', content: '🥴', num: '&#x1f974;' },
//   { name: ':nauseated_face:', content: '🤢', num: '&#x1f922;' },
//   { name: ':face_vomiting:', content: '🤮', num: '&#x1f92e;' },
//   { name: ':sneezing_face:', content: '🤧', num: '&#x1f927;' },
//   { name: ':mask:', content: '😷', num: '&#x1f637;' },
//   { name: ':thermometer_face:', content: '🤒', num: '&#x1f912;' },
//   { name: ':head_bandage:', content: '🤕', num: '&#x1f915;' },
//   { name: ':money_mouth:', content: '🤑', num: '&#x1f911;' },
//   { name: ':cowboy:', content: '🤠', num: '&#x1f920;' },
//   { name: ':disguised_face:', content: '🥸', num: '&#x1f978;' },
//   { name: ':smiling_imp:', content: '😈', num: '&#x1f608;' },
//   { name: ':clown:', content: '🤡', num: '&#x1f921;' },
//   { name: ':robot:', content: '🤖', num: '&#x1f916;' },
//   { name: ':smiley_cat:', content: '😺', num: '&#x1f63a;' },
//   { name: ':smile_cat:', content: '😸', num: '&#x1f638;' },
//   { name: ':joy_cat:', content: '😹', num: '&#x1f639;' },
//   { name: ':heart_eyes_cat:', content: '😻', num: '&#x1f63b;' },
//   { name: ':smirk_cat:', content: '😼', num: '&#x1f63c;' },
//   { name: ':kissing_cat:', content: '😽', num: '&#x1f63d;' },
//   { name: ':scream_cat:', content: '🙀', num: '&#x1f640;' },
//   { name: ':crying_cat_face:', content: '😿', num: '&#x1f63f;' },
//   { name: ':pouting_cat:', content: '😾', num: '&#x1f63e;' }
// ];

const emojiList = [
  { content: '😀' },
  { content: '😁' },
  { content: '😂' },
  { content: '🤣' },
  { content: '😃' },
  { content: '😄' },
  { content: '😅' },
  { content: '😆' },
  { content: '😉' },
  { content: '😊' },
  { content: '😋' },
  { content: '😎' },
  { content: '😍' },
  { content: '😘' },
  { content: '🥰' },
  { content: '😗' },
  { content: '😙' },
  { content: '😚' },
  { content: '🙂' },
  { content: '🤗' },
  { content: '🤩' },
  { content: '🤔' },
  { content: '🤨' },
  { content: '😐' },
  { content: '😑' },
  { content: '😶' },
  { content: '🙄' },
  { content: '😏' },
  { content: '😣' },
  { content: '😥' },
  { content: '😮' },
  { content: '🤐' },
  { content: '😯' },
  { content: '😪' },
  { content: '😫' },
  { content: '😴' },
  { content: '😌' },
  { content: '😛' },
  { content: '😜' },
  { content: '😝' },
  { content: '🤤' },
  { content: '😒' },
  { content: '😓' },
  { content: '😔' },
  { content: '😕' },
  { content: '🙃' },
  { content: '🤑' },
  { content: '😲' },
  { content: '🙁' },
  { content: '😖' },
  { content: '😞' },
  { content: '😟' },
  { content: '😤' },
  { content: '😢' },
  { content: '😭' },
  { content: '😦' },
  { content: '😧' },
  { content: '😨' },
  { content: '😩' },
  { content: '🤯' },
  { content: '😬' },
  { content: '😰' },
  { content: '😱' },
  { content: '🥵' },
  { content: '🥶' },
  { content: '😳' },
  { content: '🤪' },
  { content: '😵' },
  { content: '😡' },
  { content: '😠' },
  { content: '🤬' },
  { content: '😷' },
  { content: '🤒' },
  { content: '🤕' },
  { content: '🤢' },
  { content: '🤮' },
  { content: '🤧' },
  { content: '😇' },
  { content: '🤠' },
  { content: '🥳' },
  { content: '🥴' },
  { content: '🥺' },
  { content: '🤥' },
  { content: '🤫' },
  { content: '🤭' },
  { content: '🧐' },
  { content: '🤓' },
  { content: '😈' },
  { content: '👿' },
  { content: '🤡' },
  { content: '👹' },
  { content: '👺' },
  { content: '💀' },
  { content: '👻' },
  { content: '👽' },
  { content: '👾' },
  { content: '🤖' },
  { content: '💩' },
  { content: '😺' },
  { content: '😸' },
  { content: '😹' },
  { content: '😻' },
  { content: '😼' },
  { content: '😽' },
  { content: '🙀' },
  { content: '😿' },
  { content: '😾' },
  { content: '🙈' },
  { content: '🙉' },
  { content: '🙊' },
  { content: '👶' },
  { content: '🧒' },
  { content: '👦' },
  { content: '👧' },
  { content: '🧑' },
  { content: '👨' },
  { content: '👩' },
  { content: '🧓' },
  { content: '👴' },
  { content: '👵' }
];

const starList = [
  { name: ':拜拜:', src: baibai, key: '拜拜' },
  { name: ':比心:', src: bixin, key: '比心' },
  { name: ':不想努力了:', src: nuli, key: '不想努力了' },
  { name: ':冲鸭:', src: chongya, key: '冲鸭' },
  { name: ':大坑:', src: dakeng, key: '大坑' },
  { name: ':发红包:', src: hongbao, key: '发红包' },
  { name: ':干就完了:', src: gan, key: '干就完了' },
  { name: ':哈哈哈哈:', src: ha, key: '哈哈哈哈' },
  { name: ':嗨:', src: hai, key: '嗨' },
  { name: ':好尴尬啊:', src: ganga, key: '好尴尬啊' },
  { name: ':好感动啊:', src: gandong, key: '好感动啊' },
  { name: ':你细品:', src: xipin, key: '你细品' },
  { name: ':您辛苦啦:', src: xinku, key: '您辛苦啦' },
  { name: ':谢谢:', src: xiexie, key: '谢谢' },
  { name: ':耶:', src: ye, key: '耶' },
  { name: ':余额不足:', src: yue, key: '余额不足' },
  { name: ':梭哈:', src: suoha, key: '梭哈' }
];

const allEmoji = {
  header: [
    { list: emojiList, img: emojiListHeader },
    { list: starList, img: starListHeader }
  ],
  allList: {}
};

emojiList.map(item => {
  allEmoji.allList[item.name] = item;
});
starList.map(item => {
  allEmoji.allList[item.name] = item;
});

export { emojiList, starList, allEmoji };
