import React, { Component } from 'react';
import styles from '../../index.scss';

export class AgreementZhcn extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>應用權限申請與使用情況說明</h5>
        <p>
          1.為保障ODAILYApp產品功能實現與安全穩定運行目的，我們可能會申請或使用操作系統的相關權限；
        </p>
        <p>
          2.為保障您的知情權，我們通過下列列表將產品可能申請、使用的相關操作系統權限進行展示，您可以根據實際需要對相關權限進行管理；
        </p>
        <p>
          3.根據產品的升級，申請、使用權限的類型與目的可能會有變動，我們將及時根據這些變動對列表進行調整，以確保您及時獲悉權限的申請與使用情況；
        </p>
        <p>
          4.請您知悉，我們為業務與產品的功能與安全需要，我們可能也會使用第三方SDK，這些第三方也可能會申請或使用相關操作系統權限，詳情請見《ODAILY隱私政策》第五條提供的《信息共享清單》；
        </p>
        <p>
          5.在使用產品的過程中，您可能會使用第三方開發的H5頁面或小程序，這些第三方開發的插件或小程序也可能因業務功能所必需而申請或使用相關操作系統權限；
        </p>
        <p>6.以下適用ODAILYApp，如有單獨適用的場景或目的將在下列列表說明。</p>

        <h6>Andriod應用權限申請列表</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>權限名稱</h6>
              </td>
              <td>
                <h6>權限說明</h6>
              </td>
              <td>
                <h6>使用場景或目的</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>讀取外置存儲器</p>
              </td>
              <td>
                <p>用於用戶上傳圖片、文件等內容</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>寫入外置存儲器</p>
              </td>
              <td>
                <p>用於用戶保存圖片、在本地記錄崩潰日誌信息（如有）</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MOUNT_UNMOUNT_FILESYSTEMS</p>
              </td>
              <td>
                <p>掛載/反掛載外部文件系統</p>
              </td>
              <td>
                <p>用於用戶上傳圖片、文件等內容</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_PHONE_STATE</p>
              </td>
              <td>
                <p>讀取電話狀態</p>
              </td>
              <td>
                <p>
                  用於訪問手機設備標識等信息，該權限無法監聽、獲取您的任何通話內容與信息
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAMERA</p>
              </td>
              <td>
                <p>使用攝像頭</p>
              </td>
              <td>
                <p>用於用戶在更換頭像時拍攝照片使用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_ALERT_WINDOW</p>
              </td>
              <td>
                <p>系統懸浮窗</p>
              </td>
              <td>
                <p>用於用戶在瀏覽文章退出時，給用戶展示稍候再看浮窗</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_OVERLAY_WINDOW</p>
              </td>
              <td>
                <p>系統頂層窗口</p>
              </td>
              <td>
                <p>用於用戶在應用內使用時及時接收到推送</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>INTERNET</p>
              </td>
              <td>
                <p>訪問網絡</p>
              </td>
              <td>
                <p>用於設備聯網</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_NETWORK_STATE</p>
              </td>
              <td>
                <p>獲取網絡狀態信息</p>
              </td>
              <td>
                <p>
                  用於根據網絡狀態（蜂窩、wifi）播放或暫停視頻，節約用戶流量消耗
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_WIFI_STATE</p>
              </td>
              <td>
                <p>獲取當前Wifi狀態信息</p>
              </td>
              <td>
                <p>
                  用於根據網絡狀態（蜂窩、wifi）播放或暫停視頻，節約用戶流量消耗
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_WIFI_STATE</p>
              </td>
              <td>
                <p>改變WiFi狀態</p>
              </td>
              <td>
                <p>用於輔助GPS 確定位置，該權限無法獲取您的wifi賬號和密碼</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_NETWORK_STATE</p>
              </td>
              <td>
                <p>允許程序改變網絡狀態</p>
              </td>
              <td>
                <p>用於在網絡不穩定時，為用戶提供更好的服務</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_LOGS</p>
              </td>
              <td>
                <p>讀取系統日誌</p>
              </td>
              <td>
                <p>用於收集應用程序的錯誤日誌信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_SETTINGS</p>
              </td>
              <td>
                <p>寫入系統設置</p>
              </td>
              <td>
                <p>用於同步系統通知、亮度等設置項</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_SETTINGS</p>
              </td>
              <td>
                <p>讀取系統設置</p>
              </td>
              <td>
                <p>用於用戶改變系統通知、亮度等設置項</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MODIFY_AUDIO_SETTINGS</p>
              </td>
              <td>
                <p>修改聲音設置</p>
              </td>
              <td>
                <p>用於在觀看視頻時調整音量大小</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WAKE_LOCK</p>
              </td>
              <td>
                <p>允許鎖屏時保持運行狀態</p>
              </td>
              <td>
                <p>用於觀看視頻時，不自動鎖屏</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECEIVE_BOOT_COMPLETED</p>
              </td>
              <td>
                <p>允許開機自動運行</p>
              </td>
              <td>
                <p>
                  用於提高消息推送的到達率，該權限在任何條件下都無法自動啟動
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VIBRATE</p>
              </td>
              <td>
                <p>允許程序使用震動</p>
              </td>
              <td>
                <p>用於接收重要推送或部分交互時採用震動提示用戶</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FOREGROUND_SERVICE</p>
              </td>
              <td>
                <p>允許使用前台服務</p>
              </td>
              <td>
                <p>用於播放音頻內容時，防止用戶在聽取音頻時服務被中斷</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECORD_AUDIO</p>
              </td>
              <td>
                <p>使用麥克風</p>
              </td>
              <td>
                <p>用於訪問開ODAILY業務，部分場景下使用音頻記錄筆記</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MANAGE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>允許訪問所有文件</p>
              </td>
              <td>
                <p>
                  用於訪問開ODAILY業務，用戶保存圖片、在本地記錄崩潰日誌信息（如有）
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FLASHLIGHT</p>
              </td>
              <td>
                <p>允許訪問閃光燈</p>
              </td>
              <td>
                <p>用於接收重要推送時採用閃光燈提示用戶</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_INSTALL_PACKAGES</p>
              </td>
              <td>
                <p>未知來源安裝權限</p>
              </td>
              <td>
                <p>用於ODAILYApp的更新安裝，該權限無法安裝其它應用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECIEVE_MCS_MESSAGE</p>
              </td>
              <td>
                <p>完全的網絡訪問權限</p>
              </td>
              <td>
                <p>用於訪問網絡</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CALL_PHONE</p>
              </td>
              <td>
                <p>在不通過撥號器的情況下發起電話呼叫用戶界面</p>
              </td>
              <td>
                <p>用於撥號</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_FINE_LOCATION</p>
              </td>
              <td>
                <p>獲取位置信息權限</p>
              </td>
              <td>
                <p>用於獲取位置</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_IGNORE_BATTERY_OPTIMIZATIONS</p>
              </td>
              <td>
                <p>獲取電池優化權限</p>
              </td>
              <td>
                <p>用於優化電池使用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_SHORTCUT</p>
              </td>
              <td>
                <p>更新權限</p>
              </td>
              <td>
                <p>用於更新權限</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_BADGE</p>
              </td>
              <td>
                <p>消息通知權限</p>
              </td>
              <td>
                <p>用於推送消息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_COUNT</p>
              </td>
              <td>
                <p>更新數量權限</p>
              </td>
              <td>
                <p>更新短信通知</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_BADGE</p>
              </td>
              <td>
                <p>更新短信通知</p>
              </td>
              <td>
                <p>用於獲取短信內容</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_BADGE</p>
              </td>
              <td>
                <p>應用桌面角標權限</p>
              </td>
              <td>
                <p>用於顯示角標信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>GET_TASKS</p>
              </td>
              <td>
                <p>允許應用程序獲取當前進程</p>
              </td>
              <td>
                <p>用於獲取當前進程</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH</p>
              </td>
              <td>
                <p>獲取藍牙功能</p>
              </td>
              <td>
                <p>用於使用藍牙</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH_ADMIN</p>
              </td>
              <td>
                <p>獲取藍牙權限</p>
              </td>
              <td>
                <p>用於獲取藍牙權限</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_COARSE_LOCATION</p>
              </td>
              <td>
                <p>獲取大致位置信息</p>
              </td>
              <td>
                <p>接收小米推送</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MIPUSH_RECEIVE</p>
              </td>
              <td>
                <p>接收小米推送</p>
              </td>
              <td>
                <p>用於接收小米推送</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_ADDED</p>
              </td>
              <td>
                <p>接受隱式廣播</p>
              </td>
              <td>
                <p>用於接收廣播</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_CHANGED</p>
              </td>
              <td>
                <p>監聽應用程序變化</p>
              </td>
              <td>
                <p>用於查看應用程序變化</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_INSTALL</p>
              </td>
              <td>
                <p>應用程序安裝</p>
              </td>
              <td>
                <p>用於安裝應用程序</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_REPLACED</p>
              </td>
              <td>
                <p>應用程序替換</p>
              </td>
              <td>
                <p>用於替換舊版本應用程序</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RESTART_PACKAGES</p>
              </td>
              <td>
                <p>應用程序重啟</p>
              </td>
              <td>
                <p>用於重啟應用程序</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MESSAGE</p>
              </td>
              <td>
                <p>獲取信息權限</p>
              </td>
              <td>
                <p>用於獲取信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>C2D_MESSAGE</p>
              </td>
              <td>
                <p>獲取C2D消息</p>
              </td>
              <td>
                <p>用於獲取C2D信息</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>iOS應用權限申請列表</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>權限名稱</h6>
              </td>
              <td>
                <h6>權限說明</h6>
              </td>
              <td>
                <h6>使用場景或目的</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-CameraUsageDescription</p>
              </td>
              <td>
                <p>使用攝像頭</p>
              </td>
              <td>
                <p>用於用戶在更換頭像時拍攝照片使用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-MicrophoneUsageDescription</p>
              </td>
              <td>
                <p>使用麥克風</p>
              </td>
              <td>
                <p>用於訪問開ODAILY業務，部分場景下使用音頻記錄筆記</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryAdditionsUsageDescription</p>
              </td>
              <td>
                <p>向相冊中添加內容</p>
              </td>
              <td>
                <p>用於用戶保存圖片</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryUsageDescription</p>
              </td>
              <td>
                <p>讀取相冊中內容</p>
              </td>
              <td>
                <p>用於用戶上傳頭像</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default AgreementZhcn;
