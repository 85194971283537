import { trim } from 'lodash/string';

export const transformPrice = value => {
  if (value >= 1) {
    return Number(value).toFixed(2);
  } else {
    return Number(value).toFixed(4);
  }
};

export const transformKeywords = value => {
  let keyword_str = '';
  try {
    let keywords = JSON.parse(value);
    keywords.forEach(function(keyword) {
      var pattern = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]",
        'g'
      );
      let keyword_tmp = trim(keyword.name).replace(pattern, '');
      if (keyword_tmp.length >= 2) {
        keyword_str = keyword_str + ',' + keyword_tmp;
      }
    });
  } catch (e) {
    keyword_str = '';
    console.info('keywords errors');
  }
  keyword_str =
    keyword_str +
    ',链游,元宇宙,nft,比特币,区块链,btc,eth,狗狗币,空投,web3钱包,ai,web3新闻媒体,web3资讯,区块链新闻,区块链技术';
  return keyword_str;
};
