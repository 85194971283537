// eslint-disable-next-line react/display-name
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Helmet from 'react-helmet';
import cookies from 'js-cookie';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import { PostDetail } from '../../components/post';
import ShareBar from '../../components/post/shareBar';
import PostRecommend from '../../components/post/recommend';
import NewAuthor from '../../components/NewAuthor';
import AdPicture from '../../components/adPicture';
import { RePost } from '../../components/recommendPost';
import { HotPostItem } from '../../components/recommendPost/item';
import Sticky from '../../components/common/sticky';
import DownLoad from '../../components/download';

import { getHelmet } from '../../common/scripts/helmet';
import { loginFlag } from '../../common/scripts/login';
import wechatShare from '../../common/scripts/wechat';

import {
  getPostById,
  secondaryTag,
  postCollect,
  postLike,
  addShare,
  getPostViewpoint,
  getCollectInfo,
  getPostOdata
  // getSecondaryTagList
} from '../../common/api/post';
import { getUserInfo, getUserPosts } from '../../common/api/user';
import { getSecondaryList } from '../../common/api/columns';
import { getAdvert } from '../../common/api/slide';
import { getTopPost } from '../../common/api/found';
import { getFeedsByChannelId } from '../../common/api/feeds';
import { postFavorite } from '../../common/api/library';
import locales from '../../utils/locales';
import { transformKeywords } from '../../utils/transform';
export class Post extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    postDetail: PropTypes.object.isRequired,
    postUser: PropTypes.object.isRequired,
    postCounters: PropTypes.object.isRequired,
    secondary: PropTypes.array.isRequired,
    userPostList: PropTypes.array.isRequired,
    articleList: PropTypes.array.isRequired,
    feedList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    advertBottom: PropTypes.array,
    advertFeed: PropTypes.array,
    topPost: PropTypes.array.isRequired,
    postViewpoint: PropTypes.array.isRequired,
    postFavoriteList: PropTypes.array.isRequired,
    odataProject: PropTypes.array.isRequired,
    odataOrganization: PropTypes.array.isRequired
  };

  static fetchData(store, match, res) {
    const { lang } = match.params;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      const postId = match.params.id;
      const source = match.query.source;

      let userId;
      if (_.isNaN(Number(postId))) {
        res.redirect(postHref);
      }
      getPostOdata(postId, lang, postHref)
        .then(
          data => {
            if (data.code === 0) {
              if (data.data.items && data.data.items.length) {
                store.dispatch({
                  type: 'SET_POST_ODATA_PROJECT',
                  list: data.data.items
                });
              }
              if (data.data.organization && data.data.organization.length) {
                store.dispatch({
                  type: 'SET_POST_ODATA_ORGANIZATION',
                  list: data.data.organization
                });
              }
            }
            return getPostById(postId, lang);
          },
          data => {
            console.log('error111:', data);
            return getPostById(postId, lang);
          }
        )
        .then(data => {
          const postData = _.get(data, 'data');
          userId = _.get(postData, 'user_id');
          store.dispatch({
            type: 'SET_POST_DETAIL',
            title: postData.title,
            cover: postData.cover,
            published_at: postData.published_at,
            user: postData.user,
            summary: postData.summary,
            content: postData.content,
            extraction_tags: postData.extraction_tags,
            source_type: postData.source_type,
            source_urls: postData.source_urls,
            next_post: postData.next_post,
            keywords: postData.keywords
          });
          store.dispatch({
            type: 'SET_POST_COUNTERS',
            counters: postData.counters
          });
          return getUserInfo(userId, lang);
        })
        .then(data => {
          const userData = _.get(data, 'data');
          store.dispatch({
            type: 'SET_POST_USER',
            id: userData.id,
            avatar: userData.avatar_url,
            name: userData.name,
            introduction: userData.introduction,
            title: userData.title,
            total: userData.counters.post_create
          });
          return getAdvert('home_advertisement_bottom', lang);
          // return postFavorite('post', postId);
        })
        // .then(data => {
        //   if (data && data.data) {
        //     store.dispatch({
        //       type: 'SET_POST_FAVORITE_LIST',
        //       list: data.data
        //     });
        //   }

        //   return getAdvert('home_advertisement_bottom');
        // })
        .then(
          data => {
            const list = data.data.items;
            store.dispatch({
              type: 'SET_ADVERT_BOTTOM',
              list: list
            });
            return getAdvert('home_advertisement_feed', lang);
          },
          data => {
            console.log('error222:', data);
            if (data.code === 22000) {
              return res.redirect(postHref);
            }
            return getAdvert('home_advertisement_feed', lang);
          }
        )
        .then(
          data => {
            const list = data.data.items;
            store.dispatch({
              type: 'SET_ADVERT_FEED',
              list: list
            });
            return getTopPost(undefined, lang);
          },
          data => {
            console.log('error333:', data);
            return getTopPost(undefined, lang);
          }
        )
        .then(data => {
          const postList = data.data.items;
          store.dispatch({
            type: 'SET_TOPPOST',
            list: postList
          });
          return getUserPosts(userId, undefined, undefined, undefined, lang);
        })
        .then(data => {
          const list = data.data.items.data;
          store.dispatch({
            type: 'SET_USER_POST',
            list: list
          });
          return getPostViewpoint(postId, lang);
        })
        .then(data => {
          if (data.data && data.data.length) {
            store.dispatch({
              type: 'SET_POST_VIEWPOINT',
              list: data.data
            });
          }
          return getFeedsByChannelId(280, '', 20, lang);
        })
        .then(data => {
          let list = data.data.items.filter(item => {
            return item.entity_type === 'post';
          });
          store.dispatch({
            type: 'SET_POST_FEEDLIST',
            list
          });
          return secondaryTag(postId, lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_POST_SECONDARY',
            list: list
          });
          let slug = data.data.items.filter(item => {
            return !!item.slug;
          });
          let slug_strs = '';
          if (Array.isArray(slug) && slug.length > 0) {
            let slug_arr = [];
            for (let key in slug) {
              slug_arr.push(slug[key]['slug']);
            }
            slug_strs = slug_arr.join(',');
            return getSecondaryList(slug_strs, 1, 10, lang);
          } else {
            resolve();
          }
        })
        .then(data => {
          if (data) {
            const list = data.data.items.filter(item => {
              return item.id != postId;
            });
            store.dispatch({
              type: 'SET_POST_ARTICLELIST',
              list: list
            });
          }

          resolve();
        })
        .catch(error => {
          console.log(error);
          if (error.code === 22000) {
            res.redirect(postHref);
          }
        });

      if (source === 'share') {
        addShare(postId, 'post');
      }
    });
  }

  static defaultProps = {
    advertFeed: [],
    advertBottom: []
  };

  constructor(props) {
    super(props);
    this.state = {
      isCollected: false,
      isFavour: false,
      favourCount: 0
    };
  }

  isLogin() {
    return cookies.get('kr_plus_id');
  }

  toLogin() {
    const { dispatch } = this.props;
    loginFlag(dispatch, true);
  }

  favourHandler() {
    if (this.isLogin()) {
      postLike(this.props.match.params.id).then(() => {
        if (this.state.isFavour) {
          this.setState({
            favourCount: Number(this.state.favourCount) - 1,
            isFavour: false
          });
        } else {
          this.setState({
            favourCount: Number(this.state.favourCount) + 1,
            isFavour: true
          });
        }
      });
    } else {
      this.toLogin();
    }
  }

  collectHandler() {
    const postId = this.props.match.params.id;
    const { isCollected } = this.state;
    if (this.isLogin()) {
      postCollect(postId).then(() => {
        if (isCollected) {
          this.setState({
            isCollected: false
          });
        } else {
          this.setState({
            isCollected: true
          });
        }
      });
    } else {
      this.toLogin();
    }
  }

  getCollect() {
    const postId = this.props.match.params.id;

    getCollectInfo(postId).then(data => {
      if (data.data) {
        this.setState({ isCollected: data.data.is_favorite });
      }
    });
  }

  getHotPosts(value) {
    this.setState(
      {
        postType: value.value
      },
      () => {
        this.getTopPost();
      }
    );
  }

  getTopPost() {
    const { postType } = this.state;
    getTopPost(postType)
      .then(data => {
        this.props.dispatch({
          type: 'SET_TOPPOST',
          list: data.data.items
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  componentDidMount() {
    const { postDetail } = this.props;
    this.getCollect();
    wechatShare({
      title: postDetail.title,
      desc: postDetail.summary
    });
    this.getPostFavorite();
  }

  getPostFavorite() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    postFavorite('post', id).then(data => {
      if (data && data.data) {
        dispatch({
          type: 'SET_POST_FAVORITE_LIST',
          list: data.data
        });
      }
    });
  }

  renderShareBar() {
    const { postDetail, postFavoriteList } = this.props;
    let { favourCount, isFavour, isCollected } = this.state;
    const postId = this.props.match.params.id;

    return (
      <div className={styles['share-bar']}>
        <ShareBar
          title={postDetail.title}
          // secondary={secondary}
          favourCount={favourCount}
          isFavour={isFavour}
          isCollected={isCollected}
          favourHandler={this.favourHandler.bind(this)}
          collectHandler={this.collectHandler.bind(this)}
          getCollect={this.getCollect.bind(this)}
          postFavoriteList={postFavoriteList}
          id={postId}
        />
      </div>
    );
  }

  renderPostHot() {
    const postList = this.props.topPost;

    if (postList.length > 0) {
      return postList
        .filter((item, index) => {
          return index < 5;
        })
        .map((item, index) => {
          const props = Object.assign(item, {
            type: 'small',
            sort: index + 1
          });
          return <HotPostItem {...props} key={index} />;
        });
    }
  }

  render() {
    const {
      postDetail,
      advertFeed,
      articleList,
      secondary,
      postUser,
      userPostList,
      advertBottom,
      feedList,
      postViewpoint,
      match,
      odataProject,
      odataOrganization
    } = this.props;
    const { next_post, keywords } = postDetail;
    const postId = this.props.match.params.id;
    const { lang } = match.params;
    let keywords_str = transformKeywords(keywords);
    let helmetData = getHelmet({
      title: `${postDetail.title}_${locales(lang, 'information')}`,
      description: `${postDetail.title}_${postDetail.summary}`,
      keywords: `${postDetail.title}${keywords_str}`,
      twitterTitle: `${postDetail.title}`,
      twitterDescription: `${postDetail.summary}`,
      ogTitle: `${postDetail.title}`,
      ogDescription: `${postDetail.summary}`,
      ogUrl: `${process.env.ODAILY}${match.url}`
    });
    // twitterCard: 'summary_large_image'

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.container_wrapper}>
            <div className={styles.container_left}>
              {/* 文章内容 */}
              <PostDetail
                odataProject={odataProject}
                odataOrganization={odataOrganization}
                {...postDetail}
                secondary={secondary}
              />

              {/* 广告 */}
              {advertFeed &&
              advertFeed.length > 0 &&
              advertFeed[0].url &&
              advertFeed[0].cover ? (
                <div className={styles.advertFeed}>
                  <AdPicture
                    width="840px"
                    height="105px"
                    url={advertFeed[0].url}
                    img={advertFeed[0].cover}
                  />
                </div>
              ) : (
                ''
              )}

              {/* 分享 */}
              <div
                className={styles.share}
                // style={{ position: 'sticky', bottom: show ? '0px' : '-66px' }}
              >
                {this.renderShareBar()}
              </div>

              {postViewpoint && postViewpoint.length ? (
                <div className={styles.container_left_border} />
              ) : (
                ''
              )}

              {postViewpoint && postViewpoint.length ? (
                <div>
                  {postViewpoint.map((item, index) => {
                    return (
                      <div
                        className={styles.postViewpoint}
                        key={`postViewpoint${index}`}
                      >
                        <div className={styles.postViewpoint_header}>
                          <div
                            className={styles.postViewpoint_cover}
                            style={{ backgroundImage: `url(${item.cover})` }}
                          />
                          <div className={styles.postViewpoint_header_right}>
                            <div className={styles.postViewpoint_name}>
                              {item.name}
                            </div>
                            <div
                              className={styles.postViewpoint_account_number}
                            >
                              {item.account_number}
                            </div>
                          </div>
                        </div>

                        <div className={styles.postViewpoint_summary}>
                          {item.summary}({locales(lang, 'source')}:
                          <a
                            href={item.entity_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.source}
                          </a>
                          )
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}

              <div className={styles.container_left_border} />
              {(articleList && articleList.length) ||
              (feedList && feedList.length) ? (
                <div className={styles.postRecommend}>
                  <PostRecommend
                    id={postId}
                    articlelist={articleList}
                    feedList={feedList}
                    tags={secondary}
                  />
                </div>
              ) : (
                ''
              )}
            </div>

            <div className={styles.container_right}>
              {/* 作者栏 */}
              <div className={styles.author}>
                <NewAuthor
                  {...postUser}
                  postid={postId}
                  userPostList={userPostList}
                />
              </div>

              {/* 文章热榜 */}
              <Sticky styleData={{ marginTop: '20px' }}>
                <div
                  className={styles.RePost}
                  style={{
                    top:
                      advertBottom &&
                      advertBottom.length > 0 &&
                      advertBottom[0].url &&
                      advertBottom[0].cover
                        ? null
                        : '15px'
                  }}
                >
                  <RePost
                    getHotPosts={this.getHotPosts.bind(this)}
                    title="文章热榜"
                  >
                    {this.renderPostHot()}
                  </RePost>

                  {/* 下一篇 */}
                  {next_post ? (
                    <div className={styles.next_post}>
                      <div className={styles.next_post_title}>
                        {locales(lang, 'next_Art', { isTitle: true })}
                      </div>

                      <div className={styles.next_post_text_wrapper}>
                        <a
                          className={styles.next_post_text}
                          href={`/post/${next_post.id}`}
                        >
                          <div
                            className={styles.next_post_text_box}
                            style={{ WebkitBoxOrient: 'vertical' }}
                          >
                            {next_post.title}
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                {/* 首页边栏-底部 */}
                {advertBottom &&
                advertBottom.length > 0 &&
                advertBottom[0].url &&
                advertBottom[0].cover ? (
                  <div className={styles.advertMiddle}>
                    <AdPicture
                      width="340px"
                      height="226px"
                      url={advertBottom[0].url}
                      img={advertBottom[0].cover}
                    />
                  </div>
                ) : (
                  ''
                )}

                <DownLoad />
              </Sticky>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    postDetail: state.post.detail,
    postUser: state.post.user,
    postCounters: state.post.counters,
    postSecondaryTag: state.post.secondaryTag,
    secondary: state.post.secondary,
    userPostList: state.post.userPostList,
    articleList: state.post.articleList,
    feedList: state.post.feedList,
    advertBottom: state.home.advertBottom,
    advertFeed: state.home.advertFeed,
    topPost: state.home.topPost,
    postViewpoint: state.post.viewpointList,
    postFavoriteList: state.post.postFavoriteList,
    odataProject: state.post.odataProject,
    odataOrganization: state.post.odataOrganization
  };
};

export default withRouter(connect(mapStateToProps)(Post));
