import React, { Component } from 'react';
import styles from './Language.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import up from '../../assets/image/lang/up.png';
// import down from '../../assets/image/lang/down.png';
// import langIcon from '../../assets/image/lang/langIcon.png';
import { withRouter } from 'react-router-dom';
import { noI18nPath } from '../../utils/locales';
export class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [
        {
          name: '简中',
          lang: ''
        },
        {
          name: '繁中',
          lang: 'zhtw'
        },
        {
          name: 'English',
          lang: 'en'
        },
        {
          name: '日本语',
          lang: 'ja'
        },
        {
          name: '한국어',
          lang: 'ko'
        },
        {
          name: 'ภาษาไทย',
          lang: 'th'
        },
        {
          name: 'Tiếng Việt',
          lang: 'vi'
        }
      ],
      currentLang: ''
    };
    this.langRefArr = [];
  }
  static propTypes = {
    changeLang: PropTypes.func,
    lang: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  };
  // 语言切换事件
  handleLang = lang => {
    let pathname = this.props.location.pathname;
    // 过滤url
    this.state.languages.forEach(item => {
      if (pathname.includes(`/${item.lang}/`) || pathname === `/${item.lang}`) {
        pathname = pathname.replace(`/${item.lang}/`, '/');
      }
    });
    if (lang === this.state.currentLang) return;
    this.props.changeLang(lang);
    this.setState({
      currentLang: lang
    });
    window.location.href = lang ? `/${lang}${pathname}` : pathname;
  };
  // 拦截a链接跳转事件
  handleHref = event => {
    const { lang } = this.props.match.params;
    // 获取a链接标签
    const target = event.target.closest('a');
    if (target && target.tagName === 'A') {
      event.preventDefault();
      // 获取a链接路径
      let hrefUrl = target.getAttribute('href');
      // 如果 为javascript:; 不做任何操作
      if (~target.getAttribute('href').indexOf('javascript:;')) return;
      // 获取a链接跳转方式
      const hrefTarget = target.getAttribute('target');
      const open = window.open(hrefTarget);
      // 如果是外部链接直接跳
      if (~hrefUrl.indexOf('http')) return (open.location.href = hrefUrl);
      // 过滤url 如果a href路径有语言标识符，跳转时替换当前页面a链接路径中的语言标识符
      this.state.languages.forEach(item => {
        if (hrefUrl.includes(`/${item.lang}/`)) {
          hrefUrl = hrefUrl.replace(`/${item.lang}/`, '/');
        }
      });
      // 拼接语言标识符
      const locationHref = lang ? `/${lang}${hrefUrl}` : hrefUrl;
      open.location.href = locationHref;
    }
  };
  componentDidMount() {
    const { lang: currentLang } = this.props.match.params;
    // 初次加载滚动到当前翻译选项
    this.langRefArr.forEach(item => {
      if (item.lang === this.props.match.params.lang) {
        // 当前语言选项是否超出可视区域，如果超出则滚动 否则不滚动
        const scrollLeft =
          item.offsetLeft > this.languageMore.clientWidth ? item.offsetLeft : 0;
        this.languageMore.scrollTo({
          left: scrollLeft,
          behavior: 'smooth'
        });
      }
    });

    // 设置cookie是为了防止跳转新页面时 store状态初始化
    this.props.changeLang(currentLang || '');
    this.setState({
      currentLang: currentLang ? currentLang : this.state.currentLang
    });
    // a链接拦截器
    document.addEventListener('click', this.handleHref);
  }
  // 上滚动
  handleUp = () => {
    let scroll = this.languageMore.scrollLeft - this.languageMore.clientWidth;
    this.languageMore.scrollTo({
      left: scroll,
      behavior: 'smooth'
    });
  };
  // 下滚动
  handleDown = () => {
    let scroll = this.languageMore.scrollLeft + this.languageMore.clientWidth;
    this.languageMore.scrollTo({
      left: scroll,
      behavior: 'smooth'
    });
  };

  render() {
    const { languages } = this.state;
    const { pathname } = this.props.location;
    const isHide = ~noI18nPath.indexOf(pathname);
    return (
      <div className={styles.Language}>
        {isHide ? (
          ''
        ) : (
          <div className={styles.languageBox}>
            <img
              src={up}
              className={styles.up}
              onClick={this.handleUp}
              loading="lazy"
              alt="odaily"
            />
            <div
              className={styles.languageMore}
              ref={e => {
                this.languageMore = e;
              }}
            >
              <div className={styles.languageItemBoxDiv}>
                {languages.map(language => (
                  <div
                    key={language.name}
                    lang={language.lang}
                    className={styles.languageItemBox}
                    ref={e => {
                      this.langRefArr.push(e);
                    }}
                  >
                    <span
                      className={styles.languageItem}
                      onClick={() => this.handleLang(language.lang)}
                      style={
                        language.lang === this.state.currentLang
                          ? { fontWeight: 'bold', color: '#164491' }
                          : {}
                      }
                    >
                      {language.name}
                    </span>
                    <span className={styles.split}>·</span>
                  </div>
                ))}
              </div>
              <div
                className={styles.languageChatGPT}
                ref={e => {
                  this.langRefArr.push(e);
                }}
              >
                <React.Fragment>
                  <img
                    className={styles.languageImg}
                    src="https://piccdn.0daily.com/202307/28105455/hpuaruvyxzr05gy2"
                    alt="odaily"
                  />
                </React.Fragment>
              </div>
            </div>

            <div>
              <img
                alt="odaily"
                src="https://piccdn.0daily.com/202307/28105137/fk0no8rcl8y19vki"
                className={styles.down}
                onClick={this.handleDown}
                loading="lazy"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    changeLang(lang) {
      const action = { type: 'SET_LANG_TYPE', lang };
      dispatch(action);
    }
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Language)
);
