import React, { Component } from 'react';
import styles from '../../index.scss';

export class AgreementEn extends Component {
  render() {
    return (
      <div className={`${styles.content} ${styles.agreement}`}>
        <h5>ODAILY Media User Service Agreement</h5>
        <h6>【The first part and introduction】</h6>
        <p>
          Welcome to ODAILY! In order to use the ODAILY service (hereinafter
          referred to as &quot;this service&quot;), you should read and abide by
          the &quot;ODAILY Media User Service Agreement&quot; (hereinafter
          referred to as &quot;this agreement&quot;). Please be sure to
          carefully read and fully understand the contents of each clause,
          especially the clauses of exemption or limitation of liability, as
          well as the separate agreement for opening or using a certain service,
          and choose to accept or not. Restrictions and disclaimers may remind
          you to pay attention in bold form. Unless you have read and accepted
          all the terms of this agreement and related agreements, you have no
          right to use the service. Your login, viewing, posting information and
          other behaviors on this service shall be deemed that you have read and
          agreed to be bound by this agreement. If you are under the age of 18,
          please read this agreement and other above-mentioned agreements
          accompanied by your legal guardian, and pay special attention to the
          terms of use for minors.
        </p>
        <h6>1. 【Scope of Agreement】</h6>
        <p>
          1.1 This Agreement is an agreement between you and ODAILY regarding
          your use of ODAILY. &quot;ODAILY&quot; refers to the possible
          operating affiliates of Hainan Moti Culture Media Co., Ltd. and its
          related services. &quot;Platform user&quot; refers to an individual or
          organization that registers, logs in, and uses an ODAILY account, and
          is more referred to as &quot;you&quot; in this agreement. &quot;Other
          users&quot; refers to users related to the service, including
          subscribers and other users other than platform users themselves.
        </p>
        <p>
          1.2 This service includes but is not limited to ODAILY official
          website www.odaily.news, ODAILYAPP, Odaily APP, ODAILY WeChat public
          account Odaily (WeChat ID: O-daily), Odaily WeChat service account,
          ODAILYAPP open platform, ODAILYAPP Weibo, etc. The business platform
          is a communication channel, and ODAILY provides information release
          and brand promotion services for individuals or enterprises. After the
          platform user registers an ODAILY account, he can perform operations
          such as information release, brand promotion and subscription user
          comment management through ODAILY.
        </p>
        <h6>2.【Account Registration and Account Permissions】</h6>
        <p>
          You need to register an ODAILY account before using this service, and
          the ODAILY account is generated by binding mobile phone number and
          other information.
        </p>
        <p>
          2.2 ODAILY will review your registered account information. You shall
          be solely responsible for the authenticity, legality, accuracy and
          validity of the account information you provide. If any damage is
          caused to ODAILY or a third party, you shall make compensation
          according to law.
        </p>
        <p>
          2.3 ODAILY has the right to modify the news you publish through ODAILY
          (including but not limited to text, pictures, videos, charts, etc.)
          Odaily APP, ODAILY WeChat Official Account Odaily (WeChat ID:
          O-daily), Odaily WeChat Service Account, ODAILYAPP Open Platform,
          ODAILYAPP Weibo and other ODAILY business platforms are pushed to the
          public. ODAILY and its affiliates and partners have worldwide,
          permanent, irrevocable, free and non-exclusive right to use any
          content you upload and publish through ODAILY.
        </p>
        <p>2.4 By using this service, you can obtain the following rights:</p>
        <p>
          2.4.1 Manage messages and interact with subscribers through ODAILY;
          2.4.2 Obtain the number of subscribers and message clicks of your
          ODAILY account; 2.4.3 With the consent of ODAILY, you have the right
          to conduct messages through ODAILY Commercial activities such as
          posting branding messages and/or paid subscriptions. You also agree
          that ODAILY has the right to charge technical service costs for your
          commercial activities through ODAILY.
        </p>
        <h6>3. [Personal Information Protection of Platform Users]</h6>
        <p>
          3.1 During the process of applying for this service, you may need to
          provide some necessary information, please keep this information true,
          accurate, legal, valid and pay attention to timely update, so that
          ODAILY can provide you with timely and effective help, or better serve
          you You provide the service. According to relevant laws, regulations
          and policies, please fill in your real identity information. If the
          information you fill in is incomplete or inaccurate, you may not be
          able to use this service or be restricted during use.
        </p>
        <p>
          3.2 ODAILY is committed to the protection of personal information
          together with platform users, and protecting the personal information
          of platform users is a basic principle of ODAILY. Without your
          consent, ODAILY will not disclose your personal information to any
          company, organization or individual other than ODAILY, unless
          otherwise stipulated by laws and regulations.
        </p>
        <p>
          3.3 You should fully respect the personal information of anyone
          including but not limited to other users that you know, receive or
          have access to through this service, and you should not collect, copy,
          store, disseminate or use other User&#39;s personal information,
          otherwise, the resulting consequences shall be borne by you.
        </p>
        <h6>4. [Platform Content Specifications]</h6>
        <p>
          4.1 The platform content mentioned in this article refers to any
          content produced, copied, published, and disseminated by you during
          the use of this service, including but not limited to ODAILY account
          avatar, name, platform user description and other registration
          information, or text, voice, pictures, Sending, replying or
          auto-replying messages and related link pages, as well as other
          content generated by using ODAILY accounts or ODAILY services, such as
          videos, pictures and texts.
        </p>
        <p>
          4.2 When using this service, you must abide by the &quot;seven bottom
          lines&quot; requirements such as laws and regulations, socialist
          system, national interests, citizens&#39; legitimate rights and
          interests, social public order, morality, and information
          authenticity.
        </p>
        <p>
          You may not use this service to produce, copy, publish, or disseminate
          content prohibited by the following laws, regulations, and policies:
        </p>
        <p>
          (1) Opposing the basic principles established by the Constitution; (2)
          Endangering national security, leaking state secrets, subverting state
          power, and undermining national unity; (3) Harming national honor and
          interests; (4) Inciting ethnic hatred, ethnic Discrimination,
          undermining national unity; (5) Undermining national religious
          policies, promoting cults and feudal superstitions; (6) Spreading
          rumors, disrupting social order, and undermining social stability; (7)
          Spreading obscenity, pornography, gambling, violence, and murder (8)
          Insulting or slandering others, infringing on the legitimate rights
          and interests of others; (9) Inciting illegal assemblies,
          associations, processions, demonstrations, gatherings to disrupt
          social order; {10} Activities in the name of illegal civil
          organizations; (11) Information containing other content prohibited by
          laws, regulations and policies.
        </p>
        <p>
          4.3 You understand and agree that ODAILY has always been committed to
          providing platform users with a civilized, healthy, standardized and
          orderly network environment. You are not allowed to use this service
          to produce, copy, publish, or disseminate the following information
          that interferes with the normal operation of ODAILY or violates the
          legal laws of other users or third parties. Contents of benefits:
        </p>
        <p>
          (1) Contains any sexual or sexually suggestive content; (2)
          Advertising, harassment, spam; (3) Involving other people&#39;s
          privacy, personal information or data; (4) Infringing on other
          people&#39;s reputation rights, portrait rights, intellectual property
          rights, commercial (5) Contains other information that interferes with
          the normal operation of ODAILY and violates the legal rights and
          interests of other users or third parties.
        </p>
        <h6>5. 【Platform Usage Rules】</h6>
        <p>
          5.1 The use of the platform mentioned in this article refers to any
          behavior you perform by using this service, including but not limited
          to registration and login, account operation and promotion, and other
          behaviors performed by using your ODAILY account or ODAILY services.
        </p>
        <p>
          5.2 You shall not use your ODAILY account or ODAILY services to
          conduct the following acts:
        </p>
        <p>
          (1) Submitting or publishing false information, or impersonating or
          using the name of others; (2) Enforce or induce other users to pay
          attention to, click on linked pages or share information; (3)
          Fabricate facts and conceal the truth to mislead or deceive others;
          (4) Infringe on other legal rights such as reputation rights, portrait
          rights, intellectual property rights, trade secrets, etc.; (5) Use
          ODAILY accounts to promote third parties without the written
          permission of ODAILY (including but not limited to adding third-party
          links, advertisements, etc. behavior); (6) use plug-ins, plug-ins or
          other third-party tools and services to access this service and
          related systems without the written permission of ODAILY; (7) use
          ODAILY account or ODAILY service to engage in any illegal and criminal
          activities; (8) make , Publish methods and tools related to the above
          behaviors, or operate or disseminate such methods and tools,
          regardless of whether these behaviors are for commercial purposes; Or
          acts not expressly authorized by ODAILY.
        </p>
        <h6>6. [Account Management]</h6>
        <p>
          6.1 The ownership of the ODAILY account is owned by Hainan Moti
          Culture Media Co., Ltd. After the platform users complete the
          application registration procedures, they will obtain the right to use
          the ODAILY account. This right to use belongs only to the initial
          applicant registrant. Gifting, borrowing, renting, and transfer are
          prohibited or sell. Hainan Moti Culture Media Co., Ltd. has the right
          to terminate this service due to business needs.
        </p>
        <p>
          6.2 You are responsible for properly keeping the registered account
          information and account password safe, and you need to bear legal
          responsibility for the behavior under the registered account and
          password. You agree not to disclose account or password information to
          others under any circumstances. When you suspect that others are using
          your account or password, you agree to notify Hainan Moti Culture
          Media Co., Ltd. immediately.
        </p>
        <p>
          6.3 You should abide by the terms of this agreement and use the
          service correctly and appropriately. If you violate any terms of this
          agreement, Hainan Moti Culture Media Co., Ltd. has the right to
          terminate the provision of services to your ODAILY account in
          accordance with this agreement. Serve. At the same time, ODAILY
          reserves the right to withdraw the ODAILY account and platform user
          name at any time.
        </p>
        <h6>7.【Data storage】</h6>
        <p>
          7.1 ODAILY is not responsible for the deletion or storage failure of
          your relevant data in this service.
        </p>
        <p>
          7.2 ODAILY has the right to determine the maximum storage period for
          the data of a single platform user in this service according to the
          actual situation, and allocate the maximum storage space for the data
          on the server. You can back up relevant data in this service according
          to your own needs.
        </p>
        <p>
          7.3 If you stop using the service or the service is terminated or
          canceled, ODAILY can permanently delete your data from the server.
          After the service is stopped, terminated or canceled, ODAILY is not
          obliged to return any data to you.
        </p>
        <h6>8. 【Risk and Disclaimer】</h6>
        <p>
          8.1 You understand and agree that this service is only a platform for
          platform users to share, disseminate and obtain information, and you
          must be responsible for all actions under your registered account,
          including the authenticity, legality, accuracy, and validity of the
          content you publish. sex, and bear the results arising from the use of
          this service. Platform users should make their own judgments on the
          content in ODAILY, and bear all risks arising from the use of the
          content, including risks arising from reliance on the authenticity,
          legality, accuracy, and effectiveness of the content. ODAILY cannot
          and will not be liable for losses or damages caused by platform user
          actions.
        </p>
        <p>
          If you find that anyone violates the provisions of this agreement or
          uses ODAILY services in other improper ways, please report or complain
          to ODAILY immediately, and we will deal with it according to law.
        </p>
        <p>
          8.2 You understand and agree that due to business development needs,
          ODAILY reserves the right to unilaterally change, suspend, limit,
          terminate or cancel all or part of the service content of this service
          at any time without any notice, and platform users shall bear the
          responsibility risk.
        </p>
        <p>
          8.3 Any articles, newsletters, quotations, reports, etc. published by
          ODAILY do not constitute investment advice for you. You are fully
          aware of and understand the investment risks of blockchain and digital
          currency, and all investment matters are based on your own
          understanding.
        </p>
        <h6>9. 【Intellectual Property Statement】</h6>
        <p>
          9.1 The intellectual property rights of the content provided by ODAILY
          in this service (including but not limited to web pages, text,
          pictures, audio, video, charts, etc.) belong to ODAILY, and the
          intellectual property rights of the content generated by platform
          users in the use of this service belong to the platform All rights
          reserved by the user or related rights holders.
        </p>
        <p>
          9.2 Unless otherwise specifically stated, the copyright, patent right
          and other intellectual property rights of the software on which ODAILY
          provides this service are owned by ODAILY.
        </p>
        <p>
          9.3 &quot;ODAILY&quot; and other commercial logos used by ODAILY in
          this service, the copyright or trademark rights of which are owned by
          Hainan Moti Culture Media Co., Ltd.
        </p>
        <p>
          9.4 The intellectual property rights of the above and any other
          content contained in this service are protected by law. Without the
          written permission of ODAILY, platform users or relevant obligees, no
          one may use or create related derivative works in any form.
        </p>
        <h6>X. 【Legal Responsibilities】</h6>
        <p>
          10.1 If ODAILY finds or receives reports or complaints from others
          that platform users violate this agreement, ODAILY has the right to
          delete the relevant content at any time without notice, and depending
          on the circumstances of the behavior, impose penalties including but
          not limited to warnings, deletion of part or all of the account.
          Penalties for subscribing users, restricting or prohibiting the use of
          all or part of the functions, account banning or cancellation, and
          announcement of the results.
        </p>
        <p>
          10.2 You understand and agree that ODAILY has the right to punish
          violations of relevant laws and regulations or the provisions of this
          agreement based on reasonable judgment, take appropriate legal actions
          against anyone who violates laws and regulations, and store relevant
          information to report to relevant departments in accordance with laws
          and regulations, etc. , platform users shall solely bear all legal
          responsibilities arising therefrom.
        </p>
        <p>
          10.3 You understand and agree that you shall compensate ODAILY and its
          cooperative companies and affiliates for any claim, demand or loss
          claimed by any third party, including reasonable attorney fees, caused
          or arising from your violation of the provisions of this Agreement or
          the relevant terms of service. company and protect it from harm.
        </p>
        <h6>11. [Effectiveness and Changes of the Agreement]</h6>
        <p>
          11.1 Your use of the service is deemed to have read and agreed to be
          bound by this agreement.
        </p>
        <p>
          11.2 ODAILY has the right to modify the terms of this agreement when
          necessary. You can check the latest version of the agreement terms on
          the relevant service page.
        </p>
        <p>
          11.3 After the terms of this agreement are changed, if you continue to
          use ODAILY services, you are deemed to have accepted the revised
          agreement. If you do not accept the revised agreement, you should stop
          using this service.
        </p>
        <h6>12. [Other]</h6>
        <p>
          12.1 The signing place of this agreement is Chaoyang District,
          Beijing, People&#39;s Republic of China.
        </p>
        <p>
          12.2 The establishment, entry into force, performance, interpretation
          and dispute resolution of this agreement shall be governed by the laws
          of the mainland of the People&#39;s Republic of China (excluding
          conflict of laws).
        </p>
        <p>
          12.3 If there is any dispute or controversy between you and ODAILY, it
          should first be resolved through friendly negotiation; if the
          negotiation fails, you agree to submit the dispute or dispute to the
          competent people&#39;s court in the place where this agreement is
          signed.
        </p>
        <p>
          12.4 The titles of all clauses in this agreement are for convenience
          of reading only, and have no actual meaning in themselves, and cannot
          be used as the basis for interpretation of the meaning of this
          agreement.
        </p>
        <p>
          12.5 No matter what part of the terms of this agreement is invalid or
          unenforceable for any reason, the rest of the terms are still valid
          and binding on both parties.
        </p>
        <p>12.6 This Agreement shall come into force on May 1, 2018.</p>
      </div>
    );
  }
}

export default AgreementEn;
