import React, { Component } from 'react';
import styles from '../../index.scss';

export class ApplicationJa extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>アプリケーションの許可申請と使用手順</h5>
        <p>
          1. ODAILYApp
          製品の機能実現と安全かつ安定した動作を保証するために、当社はオペレーティング
          システムの関連許可を申請または使用する場合があります。
        </p>
        <p>
          2.
          お客様の知る権利を保護するために、製品が適用および使用できる関連するオペレーティング
          システムのアクセス許可を次のリストに表示します。お客様は、実際のニーズに応じて関連するアクセス許可を管理できます。
        </p>
        <p>
          3.
          製品のアップグレードに応じて、アプリケーションおよび使用許可の種類および目的が変更される場合があります。当社は、これらの変更に応じてリストを速やかに調整し、お客様に許可のアプリケーションおよび使用についてタイムリーに通知できるようにします。
        </p>
        <p>
          4.
          当社は、当社の事業および製品の機能およびセキュリティのニーズのためにサードパーティの
          SDK
          を使用する場合もあり、これらのサードパーティも関連するオペレーティング
          システムの許可を申請または使用する場合があることに注意してください。詳細については、第
          5 部を参照してください。
          「ODAILYプライバシーポリシー」の記事で提供する「情報共有リスト」
        </p>
        <p>
          5. 製品を使用する過程で、サードパーティが開発した H5
          ページまたはアプレットを使用することができます。また、サードパーティが開発したこれらのプラグインまたはアプレットは、ビジネス機能に必要に応じて、関連するオペレーティング
          システムの許可を申請または使用することもあります。
        </p>
        <p>
          6.
          以下はODAILYアプリに適用され、別途適用されるシナリオや目的がある場合は、以下のリストに記載されます。
        </p>

        <h6>Androidアプリ許可アプリ一覧</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>権限名</h6>
              </td>
              <td>
                <h6>権限の説明</h6>
              </td>
              <td>
                <h6>使用シナリオまたは目的</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>外部メモリを読み取る</p>
              </td>
              <td>
                <p>
                  ユーザーが写真、ファイル、その他のコンテンツをアップロードするため
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>外部メモリに書き込む</p>
              </td>
              <td>
                <p>
                  ユーザーが写真を保存し、クラッシュ
                  ログ情報をローカルに記録するために使用されます
                  (存在する場合)。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MOUNT_UNMOUNT_FILESYSTEMS</p>
              </td>
              <td>
                <p>外部ファイルシステムのマウント/アンマウント</p>
              </td>
              <td>
                <p>
                  ユーザーが写真、ファイル、その他のコンテンツをアップロードするため
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_PHONE_STATE</p>
              </td>
              <td>
                <p>電話のステータスを読み取る</p>
              </td>
              <td>
                <p>
                  携帯電話端末の識別情報やその他の情報にアクセスするために使用されますが、この権限により通話内容や情報を監視したり取得したりすることはできません。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAMERA</p>
              </td>
              <td>
                <p>カメラを使用する</p>
              </td>
              <td>
                <p>
                  ユーザーがアバターを変更するときに写真を撮るために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_ALERT_WINDOW</p>
              </td>
              <td>
                <p>システムフローティングウィンドウ</p>
              </td>
              <td>
                <p>
                  ユーザーが後で記事の閲覧を終了するときに、フローティング
                  ウィンドウを見るようにユーザーに示すために使用されます。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_OVERLAY_WINDOW</p>
              </td>
              <td>
                <p>システムのトップレベルウィンドウ</p>
              </td>
              <td>
                <p>
                  ユーザーがアプリを使用するときにタイムリーにプッシュ通知を受け取るために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>INTERNET</p>
              </td>
              <td>
                <p>アクセスネットワーク</p>
              </td>
              <td>
                <p>デバイスネットワーキング用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_NETWORK_STATE</p>
              </td>
              <td>
                <p>ネットワークステータス情報の取得</p>
              </td>
              <td>
                <p>
                  ユーザーのトラフィック消費を節約するために、ネットワーク状態
                  (セルラー、Wi-Fi)
                  に応じてビデオを再生または一時停止するために使用されます。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_WIFI_STATE</p>
              </td>
              <td>
                <p>現在の Wi-Fi ステータス情報を取得する</p>
              </td>
              <td>
                <p>
                  ユーザーのトラフィック消費を節約するために、ネットワーク状態
                  (セルラー、Wi-Fi)
                  に応じてビデオを再生または一時停止するために使用されます。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_WIFI_STATE</p>
              </td>
              <td>
                <p>WiFiステータスを変更する</p>
              </td>
              <td>
                <p>
                  GPS による位置特定を支援するために使用されます。この権限は
                  Wi-Fi アカウントとパスワードを取得できません
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_NETWORK_STATE</p>
              </td>
              <td>
                <p>プログラムがネットワークの状態を変更できるようにします</p>
              </td>
              <td>
                <p>
                  ネットワークが不安定な場合に、ユーザーにより良いサービスを提供するために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_LOGS</p>
              </td>
              <td>
                <p>syslogを読む</p>
              </td>
              <td>
                <p>
                  アプリケーションのエラーログ情報を収集するために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_SETTINGS</p>
              </td>
              <td>
                <p>システム設定を書き込む</p>
              </td>
              <td>
                <p>
                  システム通知、明るさ、その他の設定を同期するために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_SETTINGS</p>
              </td>
              <td>
                <p>システム設定の読み取り</p>
              </td>
              <td>
                <p>
                  ユーザーがシステム通知、明るさ、その他の設定を変更するために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MODIFY_AUDIO_SETTINGS</p>
              </td>
              <td>
                <p>サウンド設定を変更する</p>
              </td>
              <td>
                <p>動画視聴時の音量調整に使用します</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WAKE_LOCK</p>
              </td>
              <td>
                <p>ロック画面でも実行を続けることを許可する</p>
              </td>
              <td>
                <p>動画の視聴に使用すると、画面が自動的にロックされません。</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECEIVE_BOOT_COMPLETED</p>
              </td>
              <td>
                <p>自動起動を許可する</p>
              </td>
              <td>
                <p>
                  メッセージプッシュの到着率を向上させるために使用されます。この権限は、いかなる条件下でも自動的にアクティブ化することはできません。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VIBRATE</p>
              </td>
              <td>
                <p>プログラムが振動を使用できるようにする</p>
              </td>
              <td>
                <p>
                  重要なプッシュや部分的なインタラクションを受信したときに、振動を使用してユーザーに思い出させます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FOREGROUND_SERVICE</p>
              </td>
              <td>
                <p>フォアグラウンド サービスの使用を許可する</p>
              </td>
              <td>
                <p>
                  オーディオ
                  コンテンツの再生に使用すると、ユーザーがオーディオを聞いている間のサービスの中断を防ぎます。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECORD_AUDIO</p>
              </td>
              <td>
                <p>マイクを使用する</p>
              </td>
              <td>
                <p>
                  ODAILY
                  ビジネスにアクセスして開き、一部のシナリオで音声を使用してメモを記録するために使用されます。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MANAGE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>すべてのファイルへのアクセスを許可する</p>
              </td>
              <td>
                <p>
                  ODAILY
                  ビジネスにアクセスして開くために使用され、ユーザーは写真を保存し、クラッシュ
                  ログ情報 (存在する場合) をローカルに記録します。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FLASHLIGHT</p>
              </td>
              <td>
                <p>フラッシュへのアクセスを許可する</p>
              </td>
              <td>
                <p>
                  重要なプッシュを受信したときにライトの点滅でユーザーに通知するために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_INSTALL_PACKAGES</p>
              </td>
              <td>
                <p>不明なソースからのインストール許可</p>
              </td>
              <td>
                <p>
                  ODAILYApp
                  の更新インストールの場合、この権限では他のアプリケーションをインストールできません
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECIEVE_MCS_MESSAGE</p>
              </td>
              <td>
                <p>完全なネットワークアクセス</p>
              </td>
              <td>
                <p>ネットワークにアクセスするため</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CALL_PHONE</p>
              </td>
              <td>
                <p>ダイヤラーを経由せずに通話 UI を開始する</p>
              </td>
              <td>
                <p>ダイヤル用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_FINE_LOCATION</p>
              </td>
              <td>
                <p>位置情報の許可を取得する</p>
              </td>
              <td>
                <p>位置情報を取得するため</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_IGNORE_BATTERY_OPTIMIZATIONS</p>
              </td>
              <td>
                <p>バッテリー最適化の許可を取得する</p>
              </td>
              <td>
                <p>バッテリー使用量を最適化するために使用されます</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_SHORTCUT</p>
              </td>
              <td>
                <p>権限の更新</p>
              </td>
              <td>
                <p>権限を更新するため</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_BADGE</p>
              </td>
              <td>
                <p>メッセージ通知許可</p>
              </td>
              <td>
                <p>プッシュメッセージの場合</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_COUNT</p>
              </td>
              <td>
                <p>数量の更新許可</p>
              </td>
              <td>
                <p>更新数を取得するために使用されます</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_BADGE</p>
              </td>
              <td>
                <p>SMS 通知を更新する</p>
              </td>
              <td>
                <p>SMS コンテンツを取得するために使用されます</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_BADGE</p>
              </td>
              <td>
                <p>デスクトップバッジ権限を適用する</p>
              </td>
              <td>
                <p>コーナーマーク情報の表示に使用します</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>GET_TASKS</p>
              </td>
              <td>
                <p>アプリケーションが現在のプロセスを取得できるようにします</p>
              </td>
              <td>
                <p>現在のプロセスを取得するには</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH</p>
              </td>
              <td>
                <p>ブルートゥースを入手する</p>
              </td>
              <td>
                <p>Bluetoothを使用するための</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH_ADMIN</p>
              </td>
              <td>
                <p>Bluetoothの許可を取得する</p>
              </td>
              <td>
                <p>Bluetoothの許可を取得するために使用されます</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_COARSE_LOCATION</p>
              </td>
              <td>
                <p>おおよその位置情報を取得する</p>
              </td>
              <td>
                <p>位置情報の取得に使用します</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MIPUSH_RECEIVE</p>
              </td>
              <td>
                <p>Xiaomi プッシュを受信する</p>
              </td>
              <td>
                <p>Xiaomi プッシュを受信するために使用されます</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_ADDED</p>
              </td>
              <td>
                <p>暗黙的なブロードキャストを受け入れる</p>
              </td>
              <td>
                <p>放送受信用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_CHANGED</p>
              </td>
              <td>
                <p>アプリケーションの変更をリッスンする</p>
              </td>
              <td>
                <p>アプリケーションの変更を表示するために使用されます</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_INSTALL</p>
              </td>
              <td>
                <p>アプリケーションのインストール</p>
              </td>
              <td>
                <p>アプリケーションのインストール用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_REPLACED</p>
              </td>
              <td>
                <p>アプリケーションの置き換え</p>
              </td>
              <td>
                <p>
                  古いバージョンのアプリケーションを置き換えるために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RESTART_PACKAGES</p>
              </td>
              <td>
                <p>アプリケーションの再起動</p>
              </td>
              <td>
                <p>アプリケーションを再起動するため</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MESSAGE</p>
              </td>
              <td>
                <p>情報へのアクセス</p>
              </td>
              <td>
                <p>情報を得るために</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>C2D_MESSAGE</p>
              </td>
              <td>
                <p>C2Dメッセージを取得する</p>
              </td>
              <td>
                <p>C2D情報を取得するために使用されます</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>iOSアプリ許可アプリ一覧</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>権限名</h6>
              </td>
              <td>
                <h6>権限の説明</h6>
              </td>
              <td>
                <h6>使用シナリオまたは目的</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-CameraUsageDescription</p>
              </td>
              <td>
                <p>カメラを使用する</p>
              </td>
              <td>
                <p>
                  ユーザーがアバターを変更するときに写真を撮るために使用されます
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-MicrophoneUsageDescription</p>
              </td>
              <td>
                <p>マイクを使用する</p>
              </td>
              <td>
                <p>
                  ODAILY
                  ビジネスにアクセスして開き、一部のシナリオで音声を使用してメモを記録するために使用されます。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryAdditionsUsageDescription</p>
              </td>
              <td>
                <p>アルバムにコンテンツを追加する</p>
              </td>
              <td>
                <p>ユーザーが写真を保存する場合</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryUsageDescription</p>
              </td>
              <td>
                <p>アルバムの内容を読む</p>
              </td>
              <td>
                <p>ユーザーがアバターをアップロードする場合</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ApplicationJa;
