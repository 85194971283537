import { combineReducers } from 'redux';

const navigationList = (state = [], action) => {
  switch (action.type) {
    case 'SET_NAV_LIST':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  navigationList
});
