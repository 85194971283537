import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

class ListSelect extends React.Component {
  static defaultProps = {
    className: '',
    selectStyle: {},
    onChange: () => {},
    disabled: false,
    data: [''],
    columns: {},
    defaultValue: true
  };
  static propTypes = {
    className: PropTypes.string,
    selectStyle: PropTypes.object,
    onChange: PropTypes.func,
    data: PropTypes.array,
    columns: PropTypes.object,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.bool,
    value: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showOption: false,
      selectInp: '',
      selectFlat: true
    };
  }

  // 检测是否传了必传值
  detectionValue() {
    const { data, columns } = this.props;
    data.length === 0 ? console.error('未传必传值data') : null;
    // columns+''==='[object Object]'?console.error('未传必传值columns'):null;
    switch (undefined) {
      case columns.optionValue:
        console.error('未传必传值columns.optionValue');
        break;
      case columns.optionKey:
        console.error('未传必传值columns.optionKey');
        break;
      case columns.optionLabel:
        console.error('未传必传值columns.optionLabel');
        break;
      default:
        null;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { selectFlat } = this.state;
    let { value } = this.props;
    const { data, columns, defaultValue } = nextProps;
    let selectInp = '';
    if (value) {
      selectInp = value;
    } else {
      selectInp =
        Object.prototype.toString.call(data[0]) === '[object String]'
          ? data[0]
          : data[0][columns.optionLabel];
    }

    selectFlat && defaultValue
      ? this.setState({ selectInp })
      : this.setState({ selectFlat: true });
  }

  getSelectInp(value) {
    this.setState({
      selectInp: value,
      showOption: false
    });
  }

  componentDidMount() {
    this.detectionValue();

    document.addEventListener(
      'click',
      e => {
        if (
          this.cell &&
          this.cell !== e.target &&
          !this.cell.contains(e.target)
        ) {
          this.setState({
            showOption: false
          });
        }
      },
      true
    );
  }

  renderOption() {
    const { data, columns, onChange, selectStyle } = this.props;
    let { selectInp } = this.state;

    return data.map(item => {
      let flag = Object.prototype.toString.call(item);
      let optionItemValue =
        flag === '[object String]' ? item : item[columns.optionLabel];
      let optionItemStyle = {
        ...selectStyle.optionItem,
        background: optionItemValue === selectInp ? '#fbe6d0' : null
      };

      return (
        <li
          className={styles.optionItem}
          value={flag === '[object String]' ? item : item[columns.optionValue]}
          key={flag === '[object String]' ? item : item[columns.optionKey]}
          onClick={e => {
            let value = e.target.getAttribute('value')
              ? e.target.getAttribute('value')
              : e.target.innerText;

            this.setState(
              {
                selectFlat: false
              },
              () => {
                onChange(value);
              }
            );

            this.getSelectInp.call(this, e.target.innerText);
          }}
          style={optionItemStyle}
        >
          {optionItemValue}
        </li>
      );
    });
  }

  render() {
    const { selectStyle, disabled, className } = this.props;
    const { showOption, selectInp } = this.state;

    return (
      <div
        className={className ? [styles.select, className] : styles.select}
        style={selectStyle.select}
        ref={e => (this.cell = e)}
      >
        <div
          className={styles.selectWrapper}
          onClick={() => {
            // 禁用不能弹出下拉框
            if (!disabled) {
              this.setState({
                showOption: !showOption
              });
            }
          }}
        >
          <i
            className={styles.icon}
            style={{
              transform: showOption ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
          {selectInp}
        </div>
        <div
          style={showOption ? { position: 'relative' } : { display: 'none' }}
        >
          <ul className={styles.optionWrapper} style={selectStyle.option}>
            {this.renderOption()}
          </ul>
        </div>
      </div>
    );
  }
}

export { ListSelect };
