import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { timesAgo } from '../../../common/scripts/time';

export class VideoDetail extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired
  };

  render() {
    const { title, published_at, url, poster } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles['post-title']}>{title}</div>
        <div className={styles['post-time']}>{timesAgo(published_at)}</div>
        <div className={styles['post-video']}>
          <video src={url} poster={poster} controls />
        </div>
      </div>
    );
  }
}
