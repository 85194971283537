import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import styles from './index.scss';
import { ResearchItem } from '../../components/research';
import AppShare from '../../components/common/appshare';
// import Layout from '../../components/common/layout';
import { PostItem } from '../../components/post';
import { getNewsflash } from '../../common/api/newsflash';
import { checkOrgStatus } from '../../common/api/org';
import { getPostHot } from '../../common/api/post';
import NewsFlashItem from '../../components/newsflash/item';
import Helmet from 'react-helmet';
// import CreateNewsflash from '../../components/newsflash/create';
import cookies from 'js-cookie';
import Header from '../../components/common/header';
import { getFeedsByResearch } from '../../common/api/feeds';
const getDate = time => moment(time).format('YYYY-MM-DD');

const timesAgo = value => {
  const isToday = moment(value).isSame(moment(), 'day');
  const isYesterday = moment(value).isSame(moment().subtract(1, 'days'), 'day');
  if (isToday) {
    return '今天';
  } else if (isYesterday) {
    return '昨天';
  } else {
    return getDate(value);
  }
};

import { getHelmet } from '../../common/scripts/helmet';

const BANNER_APPLY_NEWSFLASH =
  'https://piccdn.0daily.com/201809/14050108/4btfe0mb501qkf2l.png';

// const ImgOutdated = require('../../assets/image/research-img1.png');

class Date extends React.PureComponent {
  static propTypes = {
    date: PropTypes.string.isRequired
  };
  render() {
    const { date } = this.props;
    return (
      <div className={styles['date']}>
        <span className={styles['dot']}>
          <i className={styles['dot-inner']} />
        </span>
        <h4>{timesAgo(date)}</h4>
      </div>
    );
  }
}

export class Research extends React.Component {
  static propTypes = {
    newsflashList: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    searchList: PropTypes.object.isRequired,
    talkList: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };
  static fetchData(store, match) {
    const { lang } = match.params;
    return new Promise((resolve, reject) => {
      getNewsflash({}, lang)
        .then(data => {
          const list = data.data.items;
          list.forEach(item => {
            const date = getDate(item.published_at);
            const list = item;
            store.dispatch({
              type: 'SET_NEWS_FLASH',
              date,
              list
            });
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      postHostList: [],
      showCreateDialog: false,
      canPostNews: false,
      banner: null,
      lastKey: [],
      talkKey: [],
      talkPage: 1,
      searchPage: 1,
      talkTotalPgae: 0,
      searchTotalPage: 0
    };
  }
  componentDidMount() {
    this.getPostData('249', this.state.talkPage);
    this.getPostData('221', this.state.searchPage);
    const userId = cookies.get('kr_plus_id');
    const preloadBanner = () => {
      const bannerImg = new Image();
      bannerImg.src = BANNER_APPLY_NEWSFLASH;
      bannerImg.onload = () => {
        this.setState({
          banner: BANNER_APPLY_NEWSFLASH
        });
      };
    };
    this.getPostHotData();
    if (userId) {
      checkOrgStatus(userId)
        .then(data => {
          if (data && data.data.status === 1) {
            this.setState({
              canPostNews: true
            });
          } else {
            preloadBanner();
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      preloadBanner();
    }
  }
  getPostData(feedId, page) {
    getFeedsByResearch(feedId, page)
      .then(data => {
        if (feedId == '249') {
          if (data.code == 0) {
            this.props.dispatch({
              type: 'SET_FEEDS_SEARCH',
              list: data.data.items
            });
            let totalPage =
              Math.ceil(data.data.total_count) / data.data.page_size;
            this.setState({
              searchTotalPage: totalPage
            });
          }
        } else {
          if (data.code == 0) {
            let totalPage2 =
              Math.ceil(data.data.total_count) / data.data.page_size;
            this.props.dispatch({
              type: 'SET_FEEDS_TALK',
              list: data.data.items
            });
            this.setState({
              talkTotalPgae: totalPage2
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  getPostHotData() {
    getPostHot().then(data => {
      const postHostList = data.data.items;
      this.setState({
        postHostList
      });
    });
  }
  renderPostHot() {
    return this.state.postHostList.map((item, index) => {
      const props = Object.assign(item, {
        type: 'small',
        sort: index + 1
      });
      return <PostItem key={item.entity_id} {...props} />;
    });
  }
  renderList() {
    const { newsflashList } = this.props;
    return Object.keys(newsflashList).map(date => {
      const listRender = newsflashList[date].map(item => {
        return <NewsFlashItem key={item.id} {...item} />;
      });
      return [<Date key={date} date={date} />, listRender];
    });
  }
  handleLoadMore() {
    const { newsflashList, dispatch } = this.props;
    const lastDate = _.findLastKey(newsflashList);
    const lastItem = _.last(newsflashList[lastDate]);
    const bId = _.get(lastItem, 'id');
    this.setState({
      loading: true
    });
    getNewsflash({ bId })
      .then(data => {
        const list = data.data.items;
        if (list && list.length === 0) {
          this.setState({
            loaded: true
          });
        }
        list.forEach(item => {
          const date = getDate(item.published_at);
          const list = item;
          dispatch({
            type: 'SET_NEWS_FLASH',
            date,
            list
          });
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }
  toggleCreateDialog = () => {
    const state = this.state;
    this.setState({
      showCreateDialog: !state.showCreateDialog
    });
  };

  renderSearchItem() {
    const { list } = this.props.searchList;
    if (list !== undefined) {
      return list.map(item => {
        return <ResearchItem key={item.id} {...item} />;
      });
    }
  }
  renderTalkItem() {
    const { list } = this.props.talkList;
    if (list !== undefined) {
      return list.map(item => {
        return <ResearchItem key={item.id} {...item} />;
      });
    }
  }
  prevHandle(params) {
    const { searchPage, talkPage } = this.state;
    if (params == 249) {
      var prevPage = searchPage;
      prevPage -= 1;
      if (searchPage == 1) {
        return;
      }
      this.setState({
        searchPage: prevPage,
        searchTag: true
      });
      this.getPostData(params, prevPage);
    } else {
      var prevPage2 = talkPage;
      prevPage2 -= 1;
      if (talkPage == 1) {
        return;
      }
      this.setState({
        talkPage: prevPage2
      });
      this.getPostData(params, prevPage2);
    }
  }
  handlePost() {
    window.location.href = 'https://www.odaily.news/post/5133672';
  }
  nextHandle(params) {
    const { searchTotalPage, searchPage, talkTotalPgae, talkPage } = this.state;
    let nextPage = searchPage;
    let nextPage2 = talkPage;
    if (params == 249) {
      if (searchPage >= searchTotalPage) {
        return;
      }
      nextPage += 1;
      this.setState({
        searchPage: nextPage
      });
      this.getPostData(params, nextPage);
    } else {
      if (talkPage >= talkTotalPgae) {
        return;
      }
      nextPage2 += 1;
      this.setState({
        talkPage: nextPage2
      });
      this.getPostData(params, nextPage2);
    }
  }
  render() {
    let helmetData = getHelmet({
      twitterImage:
        'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
      ogImage:
        'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
    });
    return (
      <React.Fragment>
        <Helmet {...helmetData} />
        <AppShare />
        {/* 头部导航 */}
        <Header />
        {/* 大图 */}
        <div className={styles['bgbox']}>
          <span className={styles['jiantou']} />
        </div>
        {/* 研究院报告 */}
        <div className={styles['introduceBox']}>
          <h3>
            研究院介绍
            <span />
          </h3>
          <p className={styles['describe']}>
            ODAILY研究院通过对区块链相关产业的全景式扫描，传递区块链发展声音，汇集区块链研究成果，推动区块链发展进程。
          </p>
          <div className={styles['itemLfet']}>
            <div className={styles['itemLfetImg']} />
            <h3 className={styles['itemH3']}>
              内容定位
              <span />
            </h3>
            <p className={styles['itemText']}>及时、深度、严谨、活泼</p>
          </div>
          <div className={styles['itemRight']}>
            <div className={styles['itemLfetImg']} />
            <h3 className={styles['itemH3']}>
              旗下栏目
              <span />
            </h3>
            <p className={styles['itemText']}>研报、图说、“一文读懂”系列</p>
          </div>
        </div>
        {/* 研究报告 */}
        <div className={`${styles.itembg} ${styles.bg1}`}>
          <div className={styles['Masker']}>
            <h3 className={styles['itemH3']}>
              研究报告
              <span />
            </h3>
          </div>
        </div>
        {/* 下面的3个文章 */}
        <div className={styles['postBox']}>
          <div
            onClick={this.nextHandle.bind(this, 249)}
            className={styles['researchIconNext']}
          />
          <div
            onClick={this.prevHandle.bind(this, 249)}
            className={styles['researchIconPrev']}
          />
          {this.renderSearchItem()}
        </div>
        {/* 图说系列 */}
        <div className={`${styles.itembg} ${styles.bg2}`}>
          <div className={styles['Masker']}>
            <h3 className={styles['itemH3']}>
              “图说”系列
              <span />
            </h3>
          </div>
        </div>
        {/* 下面的文章 */}
        <div className={styles['postBox']}>
          <div
            onClick={this.nextHandle.bind(this, 221)}
            className={styles['researchIconNext']}
          />
          <div
            onClick={this.prevHandle.bind(this, 221)}
            className={styles['researchIconPrev']}
          />
          {this.renderTalkItem()}
        </div>
        {/* 榜单评选 */}
        <div className={styles['introduceBox']}>
          <h3>
            榜单评选
            <span />
          </h3>
          <p className={styles['describe']}>
            ODAILY研究院联合独家战略合作方36Kr、鲸准研究院发布“2018区块链NEXT榜单”
          </p>
        </div>

        <div onClick={this.handlePost} className={styles['topImg']} />
        {/* 尾部 */}
        <div className={styles['footBox']}>
          <div className={styles['footContent']}>
            <h3>寻求合作</h3>
            <p className={styles['footText1']}>
              ODAILY研究院与各企业、机构就数据合作、数据报告、
              <br />
              行业报告、研究项目定制等方向开展合作
            </p>
            <p className={styles['footText2']}>
              Email:
              <br />
              haofangzhou@odaily.com &nbsp;&nbsp; luobinmeng@odaily.com
            </p>
            <div className={styles['footWx']}>
              <div />
              <p>微信公众号</p>
            </div>
          </div>
          <p className={styles['footFriendLink']}>
            <a href="../aboutus#us">关于我们</a>&nbsp;&nbsp;|&nbsp;&nbsp;{' '}
            <a href="../aboutus#contact">联系我们</a>&nbsp;&nbsp;|&nbsp;&nbsp;{' '}
            <a href="../aboutus#join">加入我们</a> &nbsp;&nbsp;|&nbsp;&nbsp;{' '}
            <a href="../aboutus#privacy">隐私条款</a>
            &nbsp;&nbsp;|&nbsp;&nbsp;合作伙伴：{' '}
            <a href="https://36kr.com" rel="nofollow noopener noreferrer">
              36氪
            </a>{' '}
            &nbsp;&nbsp;
            <a
              href="https://www.jingdata.com"
              rel="nofollow noopener noreferrer"
            >
              鲸准
            </a>
            &nbsp;&nbsp;{' '}
            <a href="https://www.krspace.cn" rel="nofollow noopener noreferrer">
              氪空间
            </a>
            &nbsp;&nbsp;{' '}
            <a href="https://www.36jr.com" rel="nofollow noopener noreferrer">
              迷你投
            </a>
            &nbsp;&nbsp;{' '}
          </p>
          <p className={styles['footInfo']}>
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼ICP备 2022000863号-1{' '}
            </a>
            |{' '}
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼ICP备 2022000863号-2{' '}
            </a>
            |{' '}
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼ICP备 2022000863号-4{' '}
            </a>
            |{' '}
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010602001322"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼公网安备 46010602001322号{' '}
            </a>
            |{' '}
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010602001292"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼公网安备 46010602001292号
            </a>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    newsflashList: state.newsflash,
    currentUser: state.user.current,
    searchList: state.research.searchFeeds,
    talkList: state.research.talkFeeds
  };
};

export default withRouter(connect(mapStateToProps)(Research));
