import React, { Component } from 'react';
import styles from '../../index.scss';

export class AgreementZhtw extends Component {
  render() {
    return (
      <div className={`${styles.content} ${styles.agreement}`}>
        <h5>ODAILY媒體用戶服務協議</h5>
        <h6>【首部及導言】</h6>
        <p>
          歡迎您使用ODAILY！為使用ODAILY服務（以下簡稱“本服務”），您應當閱讀並遵守《ODAILY媒體用戶服務協議》（以下簡稱“本協議”）。請您務必審慎閱讀、充分理解各條款內容，特別是免除或者限制責任的條款，以及開通或使用某項服務的單獨協議，並選擇接受或不接受。限制、免責條款可能以加粗形式提示您注意。除非您已閱讀並接受本協議及相關協議的所有條款，否則您無權使用本服務。您對本服務的登錄、查看、發布信息等行為即視為您已閱讀並同意本協議的約束。如果您未滿18週歲，請在法定監護人的陪同下閱讀本協議及其他上述協議，並特別注意未成年人使用條款。
        </p>
        <h6>一、【協議的範圍】</h6>
        <p>
          1.1本協議是您與ODAILY之間關於您使用ODAILY所訂立的協議。
          “ODAILY”是指海南省莫蒂文化傳媒有限責任公司及其相關服務可能存在的運營關聯單位。
          “平台用戶”是指註冊、登錄、使用ODAILY帳號的個人或組織，在本協議中更多地稱為“您”。
          “其他用戶”是指包括訂閱用戶等除平台用戶本人外與本服務相關的用戶。
        </p>
        <p>
          1.2
          本服務以包括但不限於ODAILY官方網站www.odaily.news、ODAILYAPP、Odaily
          APP、ODAILY微信公眾號Odaily（微信號：O-daily）、Odaily微信服務號、ODAILYAPP開放平台、ODAILYAPP微博等ODAILY業務平台為傳播渠道,由ODAILY針對個人或企業推出的信息發布和品牌推廣服務。平台用戶註冊ODAILY帳號後可以通過ODAILY進行信息發布、品牌推廣以及訂閱用戶評論管理等操作。
        </p>
        <h6>二、【帳號註冊及帳號權限】</h6>
        <p>
          您在使用本服務前需要註冊一個ODAILY帳號，ODAILY帳號由手機號碼等信息綁定生成。
        </p>
        <p>
          2.2ODAILY將對您的註冊帳號信息進行審查。您應當對您提供的帳號資料的真實性、合法性、準確性和有效性獨立承擔責任。如因此給ODAILY或第三方造成損害的，您應當依法予以賠償。
        </p>
        <p>
          2.3ODAILY有權將您通過ODAILY所發布的消息（包括但不限於文字、圖片、視頻、圖表等）進行不改變原意的修改，並在包括但不限於ODAILY官方網站www.odaily.news、ODAILYAPP、
          Odaily
          APP、ODAILY微信公眾號Odaily（微信號：O-daily）、Odaily微信服務號、ODAILYAPP開放平台、ODAILYAPP微博等ODAILY業務平台上推送給公眾。
          ODAILY及其關聯方、合作方對您通過ODAILY所上傳發布的任何內容具有全世界範圍內的、永久的、不可撤銷的、免費的、非獨家的使用權。
        </p>
        <p>2.4使用本服務，您可獲得以下權限：</p>
        <p>
          2.4.1 通過ODAILY進行消息管理及與訂閱用戶進行互動；2.4.2
          獲取您的ODAILY帳號的訂閱用戶量及消息點擊次數量；2.4.3在得到ODAILY同意的情況下，您有權通過ODAILY進行發布品牌推廣信息和/或付費訂閱等商業活動。您亦同意，ODAILY有權就您通過ODAILY進行商業活動收取技術服務成本費。
        </p>
        <h6>三、【平台用戶個人信息保護】</h6>
        <p>
          3.1您在申請本服務的過程中，可能需要提供一些必要的信息，請保持這些信息的真實、準確、合法、有效並註意及時更新，以便ODAILY向您提供及時有效的幫助，或更好地為您提供服務。根據相關法律法規和政策，請您填寫真實的身份信息。若您填寫的信息不完整或不准確，則可能無法使用本服務或在使用過程中受到限制。
        </p>
        <p>
          3.2ODAILY與平台用戶一同致力於個人信息的保護，保護平台用戶個人信息是ODAILY的一項基本原則。未經你的同意，ODAILY不會向ODAILY以外的任何公司、組織或個人披露你的個人信息，但法律法規另有規定的除外。
        </p>
        <p>
          3.3您應對通過本服務了解、接收或可接觸到的包括但不限於其他用戶在內的任何人的個人信息予以充分尊重，您不應以蒐集、複製、存儲、傳播或以其他任何方式使用其他用戶的個人信息，否則，由此產生的後果由您自行承擔。
        </p>
        <h6>四、【平台內容規範】</h6>
        <p>
          4.1本條所述平台內容是指您使用本服務過程中所製作、複製、發布、傳播的任何內容，包括但不限於ODAILY帳號頭像、名稱、平台用戶說明等註冊信息，或文字、語音、圖片、視頻、圖文等發送、回复或自動回复消息和相關鏈接頁面，以及其他使用ODAILY帳號或ODAILY服務所產生的內容。
        </p>
        <p>
          4.2您在使用本服務時需遵守法律法規、社會主義制度、國家利益、公民合法權益、社會公共秩序、道德風尚及信息真實性等“七條底線”要求。
        </p>
        <p>
          您不得利用本服務製作、複製、發布、傳播如下法律、法規和政策禁止的內容：
        </p>
        <p>
          (1) 反對憲法所確定的基本原則的；(2)
          危害國家安全，洩露國家秘密，顛覆國家政權，破壞國家統一的；(3)
          損害國家榮譽和利益的；(4) 煽動民族仇恨、民族歧視，破壞民族團結的；(5)
          破壞國家宗教政策，宣揚邪教和封建迷信的；(6)
          散佈謠言，擾亂社會秩序，破壞社會穩定的；(7)
          散佈淫穢、色情、賭博、暴力、兇殺、恐怖或者教唆犯罪的；(8)
          侮辱或者誹謗他人，侵害他人合法權益的；(9)煽動非法集會、結社、遊行、示威、聚眾擾亂社會秩序；{' '}
          {10}以非法民間組織名義活動的； (11)
          含有法律、法規和政策禁止的其他內容的信息。
        </p>
        <p>
          4.3您理解並同意，ODAILY一直致力於為平台用戶提供文明健康、規範有序的網絡環境，您不得利用本服務製作、複製、發布、傳播如下乾擾ODAILY正常運營，以及侵犯其他用戶或第三方合法權益的內容：
        </p>
        <p>
          (1) 含有任何性或性暗示的；(2) 廣告、騷擾、垃圾信息的；(3)
          涉及他人隱私、個人信息或資料的；(4)
          侵害他人名譽權、肖像權、知識產權、商業秘密等合法權利的；(5)
          含有其他幹擾ODAILY正常運營和侵犯其他用戶或第三方合法權益內容的信息。
        </p>
        <h6>五、【平台使用規則】</h6>
        <p>
          5.1本條所述平台使用是指您使用本服務所進行的任何行為，包括但不限於註冊登錄、帳號運營推廣以及其他使用ODAILY帳號或ODAILY服務所進行的行為。
        </p>
        <p>5.2 您不得利用ODAILY帳號或ODAILY服務進行如下行為：</p>
        <p>
          (1) 提交、發布虛假信息，或冒充、利用他人名義的；(2)
          強制、誘導其他用戶關注、點擊鏈接頁面或分享信息的；(3)
          虛構事實、隱瞞真相以誤導、欺騙他人的； (4)
          侵害他人名譽權、肖像權、知識產權、商業秘密等合法權利的；(5)
          未經ODAILY書面許可利用ODAILY帳號為第三方進行推廣的（包括但不限於加入第三方鏈接、廣告等行為）；(6)
          未經ODAILY書面許可使用插件、外掛或其他第三方工具、服務接入本服務和相關係統；(7)
          利用ODAILY帳號或ODAILY服務從事任何違法犯罪活動的；(8)
          製作、發布與以上行為相關的方法、工具，或對此類方法、工具進行運營或傳播，無論這些行為是否為商業目的；(9)
          其他違反法律法規規定、侵犯其他用戶合法權益、幹擾產品正常運營或ODAILY未明示授權的行為。
        </p>
        <h6>六、【帳號管理】</h6>
        <p>
          6.1ODAILY帳號的所有權歸海南省莫蒂文化傳媒有限責任公司所有，平台用戶完成申請註冊手續後，獲得ODAILY帳號的使用權，該使用權僅屬於初始申請註冊人，禁止贈與、借用、租用、轉讓或售賣。海南省莫蒂文化傳媒有限責任公司因經營需要，有權終止本服務。
        </p>
        <p>
          6.2您有責任妥善保管註冊帳戶信息及帳戶密碼的安全，您需要對註冊帳戶以及密碼下的行為承擔法律責任。您同意在任何情況下不向他人透露帳戶或密碼信息。在您懷疑他人在使用您的帳戶或密碼時，您同意立即通知海南省莫蒂文化傳媒有限責任公司。
        </p>
        <p>
          6.3您應遵守本協議的各項條款，正確、適當地使用本服務，如您違反本協議中的任何條款，海南省莫蒂文化傳媒有限責任公司有權依據本協議終止對您的ODAILY帳號提供服務。同時，ODAILY保留在任何時候收回ODAILY帳號、平台用戶名的權利。
        </p>
        <h6>七、【數據的儲存】</h6>
        <p>7.1 ODAILY不對您在本服務中相關數據的刪除或儲存失敗負責。</p>
        <p>
          7.2ODAILY有權根據實際情況自行決定單個平台用戶在本服務中數據的最長儲存期限，並在服務器上為其分配數據最大存儲空間等。你可根據自己的需要自行備份本服務中的相關數據。
        </p>
        <p>
          7.3如果你停止使用本服務或服務被終止或取消，ODAILY可以從服務器上永久地刪除你的數據。服務停止、終止或取消後，ODAILY沒有義務向你返還任何數據。
        </p>
        <h6>八、【風險及免責】</h6>
        <p>
          8.1您理解並同意，本服務僅為平台用戶提供信息分享、傳播及獲取的平台，您必須為自己註冊帳戶下的一切行為負責，包括您所發表內容的真實性、合法性、準確性、有效性，以及承擔因本服務使用行為產生的結果。平台用戶應對ODAILY中的內容自行加以判斷，並承擔因使用內容而引起的所有風險，包括因對內容真實性、合法性、準確性、有效性的依賴而產生的風險。
          ODAILY無法且不會對因平台用戶行為而導致的損失或損害承擔責任。
        </p>
        <p>
          如果你發現任何人違反本協議規定或以其他不當的方式使用ODAILY服務，請立即向ODAILY舉報或投訴，我們將依法進行處理。
        </p>
        <p>
          8.2您理解並同意，因業務發展需要，ODAILY保留單方面對本服務的全部或部分服務內容在任何時候不經任何通知的情況下變更、暫停、限制、終止或撤銷的權利，平台用戶需承擔此風險。
        </p>
        <p>
          8.3ODAILY所發布的任何文章、快訊、行情、報導等內容不構成對您的投資建議。您充分知悉並了解區塊鏈、數字貨幣的投資風險，所有投資事項均是依據自己的理解而進行。
        </p>
        <h6>九、【知識產權聲明】</h6>
        <p>
          9.1ODAILY在本服務中提供的內容（包括但不限於網頁、文字、圖片、音頻、視頻、圖表等）的知識產權歸ODAILY所有，平台用戶在使用本服務中所產生的內容的知識產權歸平台用戶或相關權利人所有。
        </p>
        <p>
          9.2
          除另有特別聲明外，ODAILY提供本服務時所依托軟件的著作權、專利權及其他知識產權均歸ODAILY所有。
        </p>
        <p>
          9.3ODAILY在本服務中所使用的“ODAILY”等商業標識，其著作權或商標權歸海南省莫蒂文化傳媒有限責任公司所有。
        </p>
        <p>
          9.4上述及其他任何本服務包含的內容的知識產權均受到法律保護，未經ODAILY、平台用戶或相關權利人書面許可，任何人不得以任何形式進行使用或創造相關衍生作品。
        </p>
        <h6>十、【法律責任】</h6>
        <p>
          10.1如果ODAILY發現或收到他人舉報或投訴平台用戶違反本協議約定的，ODAILY有權不經通知隨時對相關內容進行刪除，並視行為情節對違規帳號處以包括但不限於警告、刪除部分或全部訂閱用戶、限製或禁止使用全部或部分功能、帳號封禁直至註銷的處罰，並公告處理結果。
        </p>
        <p>
          10.2您理解並同意，ODAILY有權依合理判斷對違反有關法律法規或本協議規定的行為進行處罰，對違法違規的任何人士採取適當的法律行動，並依據法律法規保存有關信息向有關部門報告等，平台用戶應獨自承擔由此而產生的一切法律責任。
        </p>
        <p>
          10.3您理解並同意，因您違反本協議或相關的服務條款的規定，導致或產生的任何第三方主張的任何索賠、要求或損失，包括合理的律師費，您應當賠償ODAILY與合作公司、關聯公司，並使之免受損害。
        </p>
        <h6>十一、【協議的生效與變更】</h6>
        <p>11.1 您使用本服務即視為您已閱讀並同意受本協議的約束。</p>
        <p>
          11.2
          ODAILY有權在必要時修改本協議條款。您可以在相關服務頁面查閱最新版本的協議條款。
        </p>
        <p>十二、【其他】</p>
        <h6>十二、【其他】</h6>
        <p>12.1 本協議簽訂地為中華人民共和國北京市朝陽區。</p>
        <p>
          12.2本協議的成立、生效、履行、解釋及糾紛解決，適用中華人民共和國大陸地區法律（不包括衝突法）。
        </p>
        <p>
          12.3若您和ODAILY之間發生任何糾紛或爭議，首先應友好協商解決；協商不成的，您同意將糾紛或爭議提交本協議簽訂地有管轄權的人民法院管轄。
        </p>
        <p>
          12.4
          本協議所有條款的標題僅為閱讀方便，本身並無實際涵義，不能作為本協議涵義解釋的依據。
        </p>
        <p>
          12.5
          本協議條款無論因何種原因部分無效或不可執行，其餘條款仍有效，對雙方具有約束力。
        </p>
        <p>12.6 本協議自2018年5月1日施行。</p>
      </div>
    );
  }
}

export default AgreementZhtw;
