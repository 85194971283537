exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a {\n  text-decoration: none; }\n\n.src-pages-activityMore--container-index {\n  width: 100%;\n  min-height: 100vh; }\n  .src-pages-activityMore--container-index .src-pages-activityMore--wrapper-index {\n    width: 1200px;\n    margin: 0 auto;\n    padding-bottom: 100px; }\n    .src-pages-activityMore--container-index .src-pages-activityMore--wrapper-index .src-pages-activityMore--carousel-index {\n      margin-top: 30px; }\n    .src-pages-activityMore--container-index .src-pages-activityMore--wrapper-index .src-pages-activityMore--activity_nav-index {\n      width: 100%;\n      display: -ms-flexbox;\n      display: flex;\n      -ms-flex-align: center;\n          align-items: center;\n      margin-top: 30px; }\n      .src-pages-activityMore--container-index .src-pages-activityMore--wrapper-index .src-pages-activityMore--activity_nav-index .src-pages-activityMore--activity_tab-index {\n        font-size: 16px;\n        font-weight: 400;\n        font-family: PingFangSC-Medium;\n        color: #8CA3CD;\n        line-height: 24px;\n        margin-right: 32px;\n        cursor: pointer; }\n      .src-pages-activityMore--container-index .src-pages-activityMore--wrapper-index .src-pages-activityMore--activity_nav-index .src-pages-activityMore--active-index {\n        color: #1B499D; }\n    .src-pages-activityMore--container-index .src-pages-activityMore--wrapper-index .src-pages-activityMore--activityPage_list-index {\n      width: 100%;\n      margin-top: 30px;\n      background: #FFFFFF;\n      -webkit-box-shadow: 0px 0px 8px 0px #F3F3F3;\n              box-shadow: 0px 0px 8px 0px #F3F3F3;\n      border-radius: 6px;\n      -webkit-box-sizing: border-box;\n              box-sizing: border-box; }\n    .src-pages-activityMore--container-index .src-pages-activityMore--wrapper-index .src-pages-activityMore--HomeLoadMore-index {\n      margin-top: 14px; }\n", ""]);

// exports
exports.locals = {
	"container": "src-pages-activityMore--container-index",
	"wrapper": "src-pages-activityMore--wrapper-index",
	"carousel": "src-pages-activityMore--carousel-index",
	"activity_nav": "src-pages-activityMore--activity_nav-index",
	"activity_tab": "src-pages-activityMore--activity_tab-index",
	"active": "src-pages-activityMore--active-index",
	"activityPage_list": "src-pages-activityMore--activityPage_list-index",
	"HomeLoadMore": "src-pages-activityMore--HomeLoadMore-index"
};