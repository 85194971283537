import React from 'react';
import styles from './index.scss';
import PropTypes from 'prop-types';

const Circle = props => {
  const { name, top, left } = props.data;
  return (
    <div
      className={`${styles.mapItem}`}
      style={{ top: top + 'px', left: left + 'px' }}
    >
      <div className={styles.wave}>
        <div className={styles.circle}>
          <div className={styles.card} />
        </div>
      </div>
      <p>{name}</p>
    </div>
  );
};

Circle.propTypes = {
  data: PropTypes.object
};

export default Circle;
