import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import cookies from 'js-cookie';

import CollectLibrary from '../../collectLibrary';
import { loginFlag } from '../../../common/scripts/login';

import styles from './index.scss';
import locales from '../../../utils/locales';
import { withRouter } from 'react-router-dom';

export class ShareBar extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    secondary: PropTypes.array.isRequired,
    postFavoriteList: PropTypes.array.isRequired,
    favourCount: PropTypes.number.isRequired,
    isFavour: PropTypes.bool.isRequired,
    isCollected: PropTypes.bool.isRequired,
    favourHandler: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getCollect: PropTypes.func,
    match: PropTypes.object
    // collectHandler: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      weiboShareUrl: '',
      TwitterUrl: '',
      newLibraryFlag: false,
      collectFlag: false
    };
  }

  handleScrollTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    const { title } = this.props;
    const url = window.location.origin + window.location.pathname;

    const shareQRCodeCanvas = document.getElementById('share-qrcode');
    QRCode.toCanvas(shareQRCodeCanvas, url, { width: 52 });

    const weiboShareUrl = `http://service.weibo.com/share/share.php?&appkey=2523671237&content=utf-8&url=${url}&title=${title +
      ' @ODAILY '}&sourceUrl=${url}
    `;
    const TwitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}&via=OdailyChina`;

    this.setState({
      weiboShareUrl,
      TwitterUrl
    });
  }

  toLogin() {
    const { dispatch } = this.props;
    loginFlag(dispatch, true);
  }

  changeCollectFlag() {
    const { collectFlag } = this.state;
    if (cookies.get('kr_plus_id')) {
      this.setState({ collectFlag: !collectFlag });
    } else {
      this.toLogin();
    }
  }

  renderSecondary() {
    const { secondary } = this.props;
    return secondary
      .filter(item => {
        return item.slug;
      })
      .map((item, index) => {
        return (
          <a
            key={`tag${index}`}
            className={styles['tag']}
            href={`/search/${item.name}`}
            rel="noopener noreferrer"
          >
            {item.name}
          </a>
        );
      });
  }

  render() {
    const { weiboShareUrl, TwitterUrl, collectFlag } = this.state;
    const {
      isFavour,
      isCollected,
      favourCount,
      // collectHandler,
      favourHandler,
      secondary,
      postFavoriteList,
      id,
      getCollect,
      match
    } = this.props;
    const { lang } = match.params;
    const share2twitter = encodeURI(TwitterUrl);
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.container_left}>
            {secondary ? this.renderSecondary() : ''}
          </div>
          <div className={styles.container_right}>
            {/* 点赞 */}
            <div className={styles.button}>
              <div
                onClick={() => {
                  favourHandler();
                }}
                className={
                  isFavour
                    ? styles['icon-favour-active']
                    : styles['icon-favour']
                }
              />
              {favourCount ? (
                <div className={styles.num_box}>
                  <div className={styles.num}>{favourCount}</div>
                </div>
              ) : (
                ''
              )}
            </div>
            {/* 收藏 */}
            <div className={styles.button}>
              <div
                onClick={() => {
                  // collectHandler();
                  this.changeCollectFlag();
                }}
                className={
                  isCollected
                    ? styles['icon-collect-active']
                    : styles['icon-collect']
                }
              />
            </div>
            {/* 微信 */}
            <div className={styles.button}>
              <div className={styles.qrcode_icon} />
              <div
                className={styles.qrcode}
                style={{
                  width: !lang || lang == 'zhtw' ? '160px' : '220px'
                }}
              >
                <div className={styles.inner}>
                  <canvas id="share-qrcode" />
                  <div className={styles.tips}>
                    <p>{locales(lang, 'scan')}</p>
                    <span>{locales(lang, 'share_article')}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* 微博 */}
            <div className={styles.button}>
              <a
                href={weiboShareUrl}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <div className={styles.weibo_icon} />
              </a>
            </div>
            {/* 推特 */}
            <div className={styles.button}>
              <a
                href={share2twitter}
                target="_blank"
                rel="nofollow noopener noreferrer"
                title={`${locales(lang, 'shareTo')} Twitter`}
              >
                <div className={styles.twitter_icon} />
              </a>
            </div>
            {/* 回到顶部 */}
            <div className={styles.button}>
              <div
                className={styles.top_icon}
                onClick={() => {
                  this.handleScrollTop();
                }}
              />
            </div>
          </div>
        </div>

        {collectFlag ? (
          <CollectLibrary
            isCollected={isCollected}
            getCollect={getCollect}
            collectFlag={collectFlag}
            changeCollectFlag={this.changeCollectFlag.bind(this)}
            postFavoriteList={postFavoriteList}
            id={id}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withRouter(connect()(ShareBar));
