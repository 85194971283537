import React, { Component } from 'react';
import styles from '../../index.scss';

export class ApplicationZhcn extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>应用权限申请与使用情况说明</h5>
        <p>
          1.为保障ODAILYApp产品功能实现与安全稳定运行目的，我们可能会申请或使用操作系统的相关权限；
        </p>
        <p>
          2.为保障您的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；
        </p>
        <p>
          3.根据产品的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申请与使用情况；
        </p>
        <p>
          4.请您知悉，我们为业务与产品的功能与安全需要，我们可能也会使用第三方SDK，这些第三方也可能会申请或使用相关操作系统权限，详情请见《ODAILY隐私政策》第五条提供的《信息共享清单》；
        </p>
        <p>
          5.在使用产品的过程中，您可能会使用第三方开发的H5页面或小程序，这些第三方开发的插件或小程序也可能因业务功能所必需而申请或使用相关操作系统权限；
        </p>
        <p>6.以下适用ODAILYApp，如有单独适用的场景或目的将在下列列表说明。</p>

        <h6>Andriod应用权限申请列表</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>权限名称</h6>
              </td>
              <td>
                <h6>权限说明</h6>
              </td>
              <td>
                <h6>使用场景或目的</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>读取外置存储器</p>
              </td>
              <td>
                <p>用于用户上传图片、文件等内容</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>写入外置存储器</p>
              </td>
              <td>
                <p>用于用户保存图片、在本地记录崩溃日志信息（如有）</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MOUNT_UNMOUNT_FILESYSTEMS</p>
              </td>
              <td>
                <p>挂载/反挂载外部文件系统</p>
              </td>
              <td>
                <p>用于用户上传图片、文件等内容</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_PHONE_STATE</p>
              </td>
              <td>
                <p>读取电话状态</p>
              </td>
              <td>
                <p>
                  用于访问手机设备标识等信息，该权限无法监听、获取您的任何通话内容与信息
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAMERA</p>
              </td>
              <td>
                <p>使用摄像头</p>
              </td>
              <td>
                <p>用于用户在更换头像时拍摄照片使用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_ALERT_WINDOW</p>
              </td>
              <td>
                <p>系统悬浮窗</p>
              </td>
              <td>
                <p>用于用户在浏览文章退出时，给用户展示稍候再看浮窗</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_OVERLAY_WINDOW</p>
              </td>
              <td>
                <p>系统顶层窗口</p>
              </td>
              <td>
                <p>用于用户在应用内使用时及时接收到推送</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>INTERNET</p>
              </td>
              <td>
                <p>访问网络</p>
              </td>
              <td>
                <p>用于设备联网</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_NETWORK_STATE</p>
              </td>
              <td>
                <p>获取网络状态信息</p>
              </td>
              <td>
                <p>
                  用于根据网络状态（蜂窝、wifi）播放或暂停视频，节约用户流量消耗
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_WIFI_STATE</p>
              </td>
              <td>
                <p>获取当前Wifi状态信息</p>
              </td>
              <td>
                <p>
                  用于根据网络状态（蜂窝、wifi）播放或暂停视频，节约用户流量消耗
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_WIFI_STATE</p>
              </td>
              <td>
                <p>改变WiFi状态</p>
              </td>
              <td>
                <p>用于辅助 GPS 确定位置，该权限无法获取您的wifi账号和密码</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_NETWORK_STATE</p>
              </td>
              <td>
                <p>允许程序改变网络状态</p>
              </td>
              <td>
                <p>用于在网络不稳定时，为用户提供更好的服务</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_LOGS</p>
              </td>
              <td>
                <p>读取系统日志</p>
              </td>
              <td>
                <p>用于收集应用程序的错误日志信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_SETTINGS</p>
              </td>
              <td>
                <p>写入系统设置</p>
              </td>
              <td>
                <p>用于同步系统通知、亮度等设置项</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_SETTINGS</p>
              </td>
              <td>
                <p>读取系统设置</p>
              </td>
              <td>
                <p>用于用户改变系统通知、亮度等设置项</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MODIFY_AUDIO_SETTINGS</p>
              </td>
              <td>
                <p>修改声音设置</p>
              </td>
              <td>
                <p>用于在观看视频时调整音量大小</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WAKE_LOCK</p>
              </td>
              <td>
                <p>允许锁屏时保持运行状态</p>
              </td>
              <td>
                <p>用于观看视频时，不自动锁屏</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECEIVE_BOOT_COMPLETED</p>
              </td>
              <td>
                <p>允许开机自动运行</p>
              </td>
              <td>
                <p>
                  用于提高消息推送的到达率，该权限在任何条件下都无法自动启动
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VIBRATE</p>
              </td>
              <td>
                <p>允许程序使用震动</p>
              </td>
              <td>
                <p>用于接收重要推送或部分交互时采用震动提示用户</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FOREGROUND_SERVICE</p>
              </td>
              <td>
                <p>允许使用前台服务</p>
              </td>
              <td>
                <p>用于播放音频内容时，防止用户在听取音频时服务被中断</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECORD_AUDIO</p>
              </td>
              <td>
                <p>使用麦克风</p>
              </td>
              <td>
                <p>用于访问开ODAILY业务，部分场景下使用音频记录笔记</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MANAGE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>允许访问所有文件</p>
              </td>
              <td>
                <p>
                  用于访问开ODAILY业务，用户保存图片、在本地记录崩溃日志信息（如有）
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FLASHLIGHT</p>
              </td>
              <td>
                <p>允许访问闪光灯</p>
              </td>
              <td>
                <p>用于接收重要推送时采用闪光灯提示用户</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_INSTALL_PACKAGES</p>
              </td>
              <td>
                <p>未知来源安装权限</p>
              </td>
              <td>
                <p>用于ODAILYApp的更新安装，该权限无法安装其它应用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECIEVE_MCS_MESSAGE</p>
              </td>
              <td>
                <p>完全的网络访问权限</p>
              </td>
              <td>
                <p>用于访问网络</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CALL_PHONE</p>
              </td>
              <td>
                <p>在不通过拨号器的情况下发起电话呼叫用户界面</p>
              </td>
              <td>
                <p>用于拨号</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_FINE_LOCATION</p>
              </td>
              <td>
                <p>获取位置信息权限</p>
              </td>
              <td>
                <p>用于获取位置</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_IGNORE_BATTERY_OPTIMIZATIONS</p>
              </td>
              <td>
                <p>获取电池优化权限</p>
              </td>
              <td>
                <p>用于优化电池使用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_SHORTCUT</p>
              </td>
              <td>
                <p>更新权限</p>
              </td>
              <td>
                <p>用于更新权限</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_BADGE</p>
              </td>
              <td>
                <p>消息通知权限</p>
              </td>
              <td>
                <p>用于推送消息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_COUNT</p>
              </td>
              <td>
                <p>更新数量权限</p>
              </td>
              <td>
                <p>用于获取更新数量</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_BADGE</p>
              </td>
              <td>
                <p>更新短信通知</p>
              </td>
              <td>
                <p>用于获取短信内容</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_BADGE</p>
              </td>
              <td>
                <p>应用桌面角标权限</p>
              </td>
              <td>
                <p>用于显示角标信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>GET_TASKS</p>
              </td>
              <td>
                <p>允许应用程序获取当前进程</p>
              </td>
              <td>
                <p>用于获取当前进程</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH</p>
              </td>
              <td>
                <p>获取蓝牙功能</p>
              </td>
              <td>
                <p>用于使用蓝牙</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH_ADMIN</p>
              </td>
              <td>
                <p>获取蓝牙权限</p>
              </td>
              <td>
                <p>用于获取蓝牙权限</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_COARSE_LOCATION</p>
              </td>
              <td>
                <p>获取大致位置信息</p>
              </td>
              <td>
                <p>用于获取位置信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MIPUSH_RECEIVE</p>
              </td>
              <td>
                <p>接收小米推送</p>
              </td>
              <td>
                <p>用于接收小米推送</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_ADDED</p>
              </td>
              <td>
                <p>接受隐式广播</p>
              </td>
              <td>
                <p>用于接收广播</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_CHANGED</p>
              </td>
              <td>
                <p>监听应用程序变化</p>
              </td>
              <td>
                <p>用于查看应用程序变化</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_INSTALL</p>
              </td>
              <td>
                <p>应用程序安装</p>
              </td>
              <td>
                <p>用于安装应用程序</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_REPLACED</p>
              </td>
              <td>
                <p>应用程序替换</p>
              </td>
              <td>
                <p>用于替换旧版本应用程序</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RESTART_PACKAGES</p>
              </td>
              <td>
                <p>应用程序重启</p>
              </td>
              <td>
                <p>用于重启应用程序</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MESSAGE</p>
              </td>
              <td>
                <p>获取信息权限</p>
              </td>
              <td>
                <p>用于获取信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>C2D_MESSAGE</p>
              </td>
              <td>
                <p>获取C2D消息</p>
              </td>
              <td>
                <p>用于获取C2D信息</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>iOS应用权限申请列表</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>权限名称</h6>
              </td>
              <td>
                <h6>权限说明</h6>
              </td>
              <td>
                <h6>使用场景或目的</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-CameraUsageDescription</p>
              </td>
              <td>
                <p>使用摄像头</p>
              </td>
              <td>
                <p>用于用户在更换头像时拍摄照片使用</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-MicrophoneUsageDescription</p>
              </td>
              <td>
                <p>使用麦克风</p>
              </td>
              <td>
                <p>用于访问开ODAILY业务，部分场景下使用音频记录笔记</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryAdditionsUsageDescription</p>
              </td>
              <td>
                <p>向相册中添加内容</p>
              </td>
              <td>
                <p>用于用户保存图片</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryUsageDescription</p>
              </td>
              <td>
                <p>读取相册中内容</p>
              </td>
              <td>
                <p>用于用户上传头像</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ApplicationZhcn;
