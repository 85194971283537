import { combineReducers } from 'redux';

const list = (state = [], action) => {
  switch (action.type) {
    case 'SET_COLUMN':
      return action.list;
    default:
      return state;
  }
};

const Sensitives = (state = [], action) => {
  switch (action.type) {
    case 'SET_SENSITIVE':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({ list, Sensitives });
