import React from 'react';
import styles from './index.scss';
import Helmet from 'react-helmet';

import { getHelmet } from '../../common/scripts/helmet';

import Layout from '../../components/common/layout';
import { ListSelect } from '../../components/activity/select';

import { getFatDate, getFatTable } from '../../common/api/columns';

import gold1 from '../../assets/image/金1.png';
import silver1 from '../../assets/image/银1.png';
import copper1 from '../../assets/image/铜1.png';
import gold2 from '../../assets/image/金2.png';
import silver2 from '../../assets/image/银2.png';
import copper2 from '../../assets/image/铜3.png';
import arrow_blogger from '../../assets/image/箭头（财经博主）.png';
import arrow_organization from '../../assets/image/箭头（机构）.png';
import arrow_decline from '../../assets/image/下降.png';
import image_new from '../../assets/image/NEW-8.png';
import tu1 from '../../assets/image/100强11.png';
import tu2 from '../../assets/image/100强12.png';
import bang from '../../assets/image/榜单.jpg';

const columns = { optionValue: 'item', optionKey: 'item', optionLabel: 'item' };
const KOLNav = [
  { id: 2, name: '财经博主' },
  { id: 1, name: '机构' }
];

export default class Activityed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listFlat: 3,
      navFlat: 1,
      tr: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      tr1: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      tr2: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      columnDate: [''],
      columnDate1: [''],
      columnDate2: [''],
      navList: [{ id: '' }],
      dateValue: '',
      dateValue1: '',
      dateValue2: '',
      defaultValue: true
    };
  }

  componentDidMount() {
    if (window.location.hash) {
      this.getFatList(Number(window.location.hash.slice(1)));
    }
    getFatDate(1).then(data => {
      if (data.code === 0) {
        if (window.location.hash.slice(1) !== '2') {
          this.setState({
            dateValue: data.data.items.periods[0],
            columnDate: data.data.items.periods
          });
        }
        this.setState({
          columnDate1: data.data.items.periods,
          navList: data.data.items.institution,
          dateValue1: data.data.items.periods[0]
        });
      }
    });

    getFatDate(2).then(data => {
      if (data.code === 0) {
        if (window.location.hash.slice(1) === '2') {
          this.setState({
            dateValue: data.data.items.periods[0],
            columnDate: data.data.items.periods
          });
        }
        this.setState({
          columnDate2: data.data.items.periods,
          dateValue2: data.data.items.periods[0]
        });
      }
    });

    getFatTable(1, 0, 0).then(data => {
      if (data.code === 0) {
        if (window.location.hash.slice(1) !== '2') {
          this.setState({
            tr: data.data.items
          });
        }
        this.setState({
          tr1: data.data.items
        });
      }
    });

    getFatTable(2, 2, 0).then(data => {
      if (data.code === 0) {
        if (window.location.hash.slice(1) === '2') {
          this.setState({
            tr: data.data.items
          });
        }
        this.setState({
          tr2: data.data.items
        });
      }
    });
  }

  // replaceDate(date) {
  //   let { listFlat } = this.state;
  //   if (listFlat === 1) {
  //     return date;
  //   } else {
  //     return date.map(item => {
  //       return item.replace(/2020年/g, '');
  //     });
  //   }
  // }

  getDateValue(value) {
    let { listFlat, dateValue, navFlat, navList } = this.state;
    // if (listFlat === 2) {
    //   value = '2020年' + value;
    // }

    if (dateValue === value) {
      return;
    }
    let user_type =
      listFlat === 1 ? navList[navFlat - 1].id : KOLNav[navFlat - 1].id;
    getFatTable(listFlat, user_type, value).then(data => {
      if (data.code === 0) {
        this.setState({
          tr: data.data.items,
          dateValue: value
        });
      }
    });
  }

  getFatList(fat = 3) {
    const {
      listFlat,
      columnDate1,
      columnDate2,
      dateValue1,
      dateValue2,
      tr1,
      tr2
    } = this.state;
    if (listFlat === fat) {
      return;
    }
    if (fat === 3 || fat === 4) {
      this.setState({
        listFlat: fat
      });
      return;
    }
    this.setState({
      listFlat: fat,
      defaultValue: true,
      navFlat: 1,
      columnDate: fat === 1 ? columnDate1 : columnDate2,
      dateValue: fat === 1 ? dateValue1 : dateValue2,
      tr: fat === 1 ? tr1 : tr2
    });
  }

  getFatNav(index, id) {
    const { listFlat, navList, dateValue, navFlat } = this.state;
    if (navFlat === index + 1) {
      return;
    }

    this.setState({
      navFlat: index + 1
    });

    const time = dateValue ? dateValue : 0;
    const user_type = id
      ? id
      : listFlat === 1
      ? navList[index].id
      : KOLNav[index].id;
    getFatTable(listFlat, user_type, time).then(data => {
      if (data.code === 0) {
        this.setState({
          tr: data.data.items
        });
      }
    });
  }

  renderNav() {
    const { navList, navFlat, listFlat } = this.state;
    let nav = listFlat === 1 ? navList : KOLNav;
    return (
      <ul
        className={styles.nav}
        style={{ width: listFlat === 1 ? '398px' : '155px' }}
      >
        {nav.map((item, index) => {
          return (
            <li
              className={navFlat === index + 1 ? styles.selected : null}
              key={index}
              onClick={() => {
                this.setState({ defaultValue: false }, () => {
                  this.getFatNav(index);
                });
              }}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  }

  renderImg(index) {
    const { listFlat } = this.state;
    if (listFlat === 1) {
      switch (index) {
        case 1:
          return (
            <img alt="" src={gold1} className={styles.rankImg} loading="lazy" />
          );
        case 2:
          return (
            <img
              alt=""
              src={silver1}
              className={styles.rankImg}
              loading="lazy"
            />
          );
        case 3:
          return (
            <img
              alt=""
              src={copper1}
              className={styles.rankImg}
              loading="lazy"
            />
          );
      }
    } else {
      switch (index) {
        case 1:
          return (
            <img alt="" src={gold2} className={styles.rankImg} loading="lazy" />
          );
        case 2:
          return (
            <img
              alt=""
              src={silver2}
              className={styles.rankImg}
              loading="lazy"
            />
          );
        case 3:
          return (
            <img
              alt=""
              src={copper2}
              className={styles.rankImg}
              loading="lazy"
            />
          );
      }
    }
  }

  renderFloat_status(status) {
    const { navFlat } = this.state;
    switch (status) {
      case 1:
        return navFlat === 1 ? (
          <img
            alt=""
            src={arrow_blogger}
            className={styles.arrow}
            loading="lazy"
          />
        ) : (
          <img
            alt=""
            src={arrow_organization}
            className={styles.arrow}
            loading="lazy"
          />
        );
      case -1:
        return (
          <img
            alt=""
            src={arrow_decline}
            className={styles.arrow}
            loading="lazy"
          />
        );
      case 0:
        return (
          <img
            alt=""
            src={image_new}
            className={styles.image_new}
            loading="lazy"
          />
        );
      default:
        return null;
    }
  }

  renderTh() {
    let { listFlat } = this.state;
    if (listFlat === 1) {
      return (
        <ul className={styles.line}>
          <li className={styles.th}>排行</li>
          <li className={styles.th}>作者名称</li>
          <li className={styles.th}>文章数</li>
          <li className={styles.th}>热度</li>
        </ul>
      );
    } else if (listFlat === 2) {
      return (
        <ul className={styles.line}>
          <li className={styles.KOLth}>排行</li>
          <li className={styles.KOLth}>作者名称</li>
          <li className={styles.KOLth}>新增微博数</li>
          <li className={styles.KOLth}>互动数</li>
          <li className={styles.KOLth}>
            影响力 <i className={styles.icon} />
          </li>
        </ul>
      );
    }
  }

  renderTr() {
    let { tr, listFlat } = this.state;
    let color = '';
    if (listFlat === 1) {
      return tr.map((item, index) => {
        switch (index + 1) {
          case 1:
            color = '#FFAF14';
            break;
          case 2:
            color = '#56A6FF';
            break;
          case 3:
            color = '#FF8054';
            break;
          default:
            color = '#333';
            break;
        }

        return (
          <ul className={styles.tr} key={index}>
            <li className={styles.td}>
              {index + 1 > 3 ? index + 1 : this.renderImg(index + 1)}
            </li>
            <li className={styles.td}>
              <div className={styles.headPortrait}>
                <img alt="" src={item.avatar_url} loading="lazy" />
              </div>
              {item.user_name}
            </li>
            <li className={styles.td}>{item.post_num}</li>
            <li className={styles.td} style={{ color }}>
              {item.hot_num}
            </li>
          </ul>
        );
      });
    } else {
      return tr.map((item, index) => {
        switch (index + 1) {
          case 1:
            color = '#FFAF14';
            break;
          case 2:
            color = '#56A6FF';
            break;
          case 3:
            color = '#FF8054';
            break;
          default:
            color = '#333';
            break;
        }

        return (
          <ul className={styles.tr} key={index}>
            <li className={styles.KOLtd}>
              {this.renderFloat_status(item.float_status, index)}
            </li>
            <li className={styles.KOLtd}>
              {index + 1 > 3 ? index + 1 : this.renderImg(index + 1)}
            </li>
            <li className={styles.KOLtd}>
              <div className={styles.headPortrait}>
                <img alt="" src={item.avatar_url} loading="lazy" />
              </div>
              {item.user_name}
            </li>
            <li className={styles.KOLtd}>{item.new_microblog_num}</li>
            <li className={styles.KOLtd}>{item.interaction_num}</li>
            <li className={styles.KOLtd} style={{ color }}>
              {item.influence_num}
            </li>
          </ul>
        );
      });
    }
  }

  render() {
    let helmetData = getHelmet({
      title: '区块链游戏,区块链新闻资讯,Defi,NFT',
      description:
        '时值岁末，ODAILY将再次携手权威专家学者，开启 2020「FAT」价值时代榜单评选，用最客观的数据、最专业的眼光、最权威的视角呈现区块链行业最具价值榜单的诞生。',
      keywords: '区块链游戏,区块链新闻资讯,Defi,NFT'
    });
    let {
      listFlat,
      columnDate,
      defaultValue,
      dateValue,
      navList,
      navFlat
    } = this.state;
    let user_type =
      listFlat === 1
        ? navList[navFlat - 1].id
        : KOLNav[navFlat - 1] === undefined
        ? KOLNav[0].id
        : KOLNav[navFlat - 1].id;
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          {/* 头部 */}
          <a
            href="/Activityed2020"
            target={'_blank'}
            rel="noreferrer"
            className={styles.title}
          />
          <div className={styles.wrapper}>
            {/* 表格左边的三个按钮 */}
            <div className={styles.tableActiveList}>
              <a href="/Activityed2020" target={'_blank'} rel="noreferrer">
                区块链胖榜单
              </a>
              <div className={styles.border} />
              <a
                href="javascript:;"
                className={listFlat === 3 ? styles.tableSelected : null}
                onClick={() => {
                  this.getFatList(3);
                }}
              >
                区块链金融科技领先100强
              </a>
              <div className={styles.border} />
              <a
                href="javascript:;"
                className={listFlat === 4 ? styles.tableSelected : null}
                onClick={() => {
                  this.getFatList(4);
                }}
              >
                Filecoin挖矿厂商榜
              </a>
              <div className={styles.border} />
              <a
                href="javascript:;"
                className={listFlat === 1 ? styles.tableSelected : null}
                onClick={() => {
                  this.getFatList(1);
                }}
              >
                星球专栏榜单
              </a>
              <div className={styles.border} />
              <a
                href="javascript:;"
                className={listFlat === 2 ? styles.tableSelected : null}
                onClick={() => {
                  this.getFatList(2);
                }}
              >
                区块链KOL公信榜
              </a>
              <div className={styles.border} />
              <a href="/AcyivityVote" target={'_blank'} rel="noreferrer">
                N—POWER榜单
              </a>
            </div>
            {/* 表格 */}
            <div className={styles.main}>
              <div
                className={styles.tableHeader}
                style={{
                  display: listFlat === 1 || listFlat === 2 ? 'block' : 'none'
                }}
              >
                <div className={styles.tableTitle}>
                  {listFlat === 1 ? '星球专栏榜单' : '区块链KOL公信榜'}
                </div>
                {this.renderNav()}
                <div className={styles.date}>
                  <div className={styles.ListSelect}>
                    <ListSelect
                      data={columnDate}
                      columns={columns}
                      selectStyle={{
                        select: {
                          color: 'rgba(167, 167, 167, 1)',
                          background: 'rgba(248, 248, 248, 1)'
                        }
                      }}
                      onChange={this.getDateValue.bind(this)}
                      defaultValue={defaultValue}
                    />
                  </div>
                  <a
                    className={styles.download}
                    href={`/service/fat_list/image?list_type=${listFlat}&user_type=${user_type}&rand_period=${dateValue}`}
                  >
                    下载榜单 <i className={styles.icon} />
                  </a>
                </div>
              </div>
              <div
                className={styles.table}
                style={{
                  display: listFlat === 1 || listFlat === 2 ? 'block' : 'none'
                }}
              >
                {this.renderTh()}
                {this.renderTr()}
              </div>

              {/* 100强榜单 */}
              <div
                style={{ display: listFlat === 3 ? 'block' : 'none' }}
                className={styles.Top100}
              >
                <div className={styles.Top100Title}>
                  区块链金融科技领先100强
                </div>
                <div className={styles.nav}>榜单介绍</div>
                <div className={styles.text}>
                  过去十年，区块链伴随着争议成长。当技术走向成熟，逐渐回归产业，我们看到区块链行业正在迎来一个新的周期和变革。金融科技作为驱动区块链行业发展的引擎，也将开启区块链的新篇章。未来十年，我们期待区块链行业从喧嚣回归理性，从激荡走向繁荣。
                  <br /> <br />
                  ODAILY联合36氪重磅推出“区块链金融科技领先100强”，旨在表彰那些在金融科技领域里表现卓越、成长快速、勇于创新、具有未来潜力的领先企业。
                </div>
                <div className={styles.nav}>评选维度</div>
                <div className={styles.img} />
                <div className={styles.resultTitle}>评选结果</div>
                <div className={styles.result}>
                  <img alt="" src={tu1} loading="lazy" />
                  <img alt="" src={tu2} loading="lazy" />
                </div>
                <div className={styles.img2} />
              </div>

              {/* 挖矿厂商榜单 */}
              <div
                className={styles.mining}
                style={{ display: listFlat === 4 ? 'block' : 'none' }}
              >
                <div className={styles.miningTitle}>Filecoin挖矿厂商榜单</div>
                <div className={styles.nav}>榜单介绍</div>
                <div className={styles.text}>
                  Filecoin挖矿不仅是一个新兴的市场，还是一个尚未被验证的市场，缺乏标准、缺乏监管，但却已有许多热钱;涌入其中，布局投资。在采访调查过程中，我们也遇到不少虚假宣传，不敢提供信息或是数据完全离谱的公司投资者需要一套判别项目是否值得信赖的标准，数据的公开透明、团队的背景、技术实力都显得尤为重要。
                  <br />
                  <br /> 因此，ODAILY作为独立的第E三方平台，决定重磅推出【FAT
                  Filecoin挖矿厂商榜单】 <br />
                  <br />
                  ODAILY在此郑重声明:参与榜单评选绝对不收取任何费用，仅以综合实力和数据指标作为唯一标准
                </div>
                <div className={styles.nav}>评选维度</div>
                <div className={styles.img} />
                <div className={styles.nav}>榜单发布</div>
                <div className={styles.img2}>
                  <a href="/post/5157419" target={'_blank'} rel="noreferrer">
                    <img src={bang} alt="" loading="lazy" />
                  </a>
                </div>
                <div className={styles.button}>
                  <a href="/post/5157419" target={'_blank'} rel="noreferrer">
                    点击查看
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
