import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import animate from 'animate.css';
import { timesAgo } from '../../../common/scripts/time';
//import _ from 'lodash';

import ImgDefaultAvatar from '../../../assets/image/default-author-avatar.png';

const summarySlug = summary =>
  summary.length > 76 ? summary.substr(0, 76) + '...' : summary;

export class VideoItem extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['normal', 'small']),
    sort: PropTypes.number,
    entity_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    cover: PropTypes.string.isRequired,
    column: PropTypes.object,
    published_at: PropTypes.string.isRequired,
    highlightTitle: PropTypes.string,
    highlightSummary: PropTypes.string,
    extraction_tags: PropTypes.array
  };
  static defaultProps = {
    type: 'normal',
    user: {}
  };
  constructor(props) {
    super(props);
    this.coverImage = React.createRef();
  }
  componentDidMount() {
    if (this.coverImage.current) {
      const imgNode = this.coverImage.current;
      const fadeInStyle = animate['fadeIn'];
      const url = imgNode.dataset.src;
      const image = new Image();
      image.src = url;
      image.onload = function() {
        imgNode.src = url;
        imgNode.style.opacity = 1;
        imgNode.classList.add(fadeInStyle);
      };
    }
  }
  renderUserInfo(user) {
    return (
      <a className={styles['link']} href={`/user/${user.id}`}>
        <span className={styles['avatar']}>
          <img
            src={user.avatar_url || ImgDefaultAvatar}
            alt={user.name}
            loading="lazy"
          />
        </span>
        <span className={styles['name']}>{user.name}</span>
        <span className={styles['dot']}>&middot;</span>
      </a>
    );
  }
  renderHightlightTitle() {
    const markup = () => {
      return {
        __html: this.props.highlightTitle
      };
    };
    return (
      <h3 className={styles['post-title']} dangerouslySetInnerHTML={markup()} />
    );
  }
  renderHightlightSummary() {
    const markup = () => {
      return {
        __html: this.props.highlightSummary
      };
    };
    return (
      <p
        className={styles['post-summary']}
        dangerouslySetInnerHTML={markup()}
      />
    );
  }
  // renderTags(extraction_tags) {
  //   const tags = extraction_tags.map(item => item[0]);
  //   return tags.map((tag, key) => {
  //     return (
  //       <span key={key} className={styles['post-tag']}>
  //         {tag}
  //       </span>
  //     );
  //   });
  // }
  renderNormal() {
    const {
      entity_id,
      title,
      summary,
      cover,
      column,
      user,
      published_at,
      highlightTitle,
      highlightSummary
      //extraction_tags
    } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles['post-cover']}>
          <a
            className={`${styles['cover']} ${animate.animated} ${animate.fadeIn}`}
            href={`/video/${entity_id}`}
            target="_blank"
            rel=" noopener noreferrer"
            style={{ backgroundImage: `url(${cover})` }}
          >
            <div className={styles['goIcon']} />
            {column ? (
              <span className={styles['post-column']}>{column.name}</span>
            ) : null}
          </a>
        </div>
        <div className={styles['post-intro']}>
          <a
            className={styles['post-title-link']}
            href={`/video/${entity_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {highlightTitle ? (
              this.renderHightlightTitle()
            ) : (
              <h3 className={styles['post-title']}>{title}</h3>
            )}
          </a>
          {highlightSummary ? (
            this.renderHightlightSummary()
          ) : (
            <p className={styles['post-summary']}>{summarySlug(summary)}</p>
          )}
          <div className={styles['post-info']}>
            <div className={styles['post-poster']}>
              {user.id ? this.renderUserInfo(user) : null}
              <span className={styles['published-time']}>
                {timesAgo(published_at)}
              </span>
            </div>
            {/*<div className={styles['post-tags']}>*/}
            {/*{_.isArray(extraction_tags)*/}
            {/*? this.renderTags(extraction_tags.slice(0, 3))*/}
            {/*: null}*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  }
  renderSmall() {
    const { cover, title, entity_id, published_at, sort } = this.props;
    const sortHotStyle = sort < 3 ? styles['sort-hot'] : '';
    return (
      <div className={styles['small-container']}>
        <div className={styles['cover']}>
          {sort ? (
            <span className={`${styles['sort']} ${sortHotStyle}`}>{sort}</span>
          ) : null}
          <img src={cover} alt={title} loading="lazy" />
        </div>
        <div className={styles['intro']}>
          <h5 className={styles['title']}>
            <a href={`/video/${entity_id}`}>{title}</a>
          </h5>
          <span className={styles['published-time']}>
            {timesAgo(published_at)}
          </span>
        </div>
      </div>
    );
  }
  render() {
    if (this.props.type === 'normal') {
      return this.renderNormal();
    }
    if (this.props.type === 'small') {
      return this.renderSmall();
    }
  }
}
