import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import styles from './list.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import Layout from '../../components/common/layout';
import HomeLoadMore from '../../components/common/homeloadmore';

import {
  getLibraryList,
  getFavoriteList,
  getRecommendList
} from '../../common/api/library';

import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

export class LibraryList extends Component {
  static fetchData(store, match) {
    return new Promise((resolve, reject) => {
      const type = match.query.type;
      const lang = match.params.lang;
      if (type === 'library') {
        getLibraryList(lang)
          .then(data => {
            if (data.data && data.data.length) {
              let obj = {
                title: locales(lang, 'create_library'),
                type: 'library',
                list: data.data,
                page: { page: 1, per_page: 0, total: 0 }
              };
              store.dispatch({
                type: 'SET_LIBRARY_LIST_DATA',
                data: obj
              });
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      } else if (type === 'favorite') {
        getFavoriteList(1, 20, lang)
          .then(data => {
            if (data.data && data.data.data) {
              let obj = {
                title: '收藏文库',
                type: 'favorite',
                list: data.data.data,
                page: {
                  page: data.data.current_page + 1,
                  per_page: data.data.per_page,
                  total: data.data.total
                }
              };
              store.dispatch({
                type: 'SET_LIBRARY_LIST_DATA',
                data: obj
              });
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      } else if (type === 'recommend') {
        getRecommendList(1, 20, lang)
          .then(data => {
            if (data.data && data.data.data) {
              let obj = {
                title: '推荐文库',
                type: 'recommend',
                list: data.data.data,
                page: {
                  page: data.data.current_page + 1,
                  per_page: data.data.per_page,
                  total: data.data.total
                }
              };
              store.dispatch({
                type: 'SET_LIBRARY_LIST_DATA',
                data: obj
              });
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  }

  static propTypes = {
    libraryListData: PropTypes.object.isRequired,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      libraryList: props.libraryListData.list,
      libraryPage: props.libraryListData.page
    };
  }

  componentDidMount() {}

  getFavoriteListFunc() {
    const { libraryList, libraryPage } = this.state;
    const { page, per_page } = libraryPage;
    getFavoriteList(page, per_page).then(data => {
      if (data.data && data.data.data) {
        this.setState({
          libraryList: libraryList.concat(data.data.data),
          libraryPage: {
            page: data.data.current_page + 1,
            per_page: data.data.per_page,
            total: data.data.total
          }
        });
      }
    });
  }

  getRecommendListFunc() {
    const { libraryList, libraryPage } = this.state;
    const { page, per_page } = libraryPage;
    getRecommendList(page, per_page).then(data => {
      if (data.data && data.data.data) {
        this.setState({
          libraryList: libraryList.concat(data.data.data),
          libraryPage: {
            page: data.data.current_page + 1,
            per_page: data.data.per_page,
            total: data.data.total
          }
        });
      }
    });
  }

  loadMore() {
    const { type } = this.props.libraryListData;

    if (type === 'favorite') {
      this.getFavoriteListFunc();
    } else {
      this.getRecommendListFunc();
    }
  }

  render() {
    const { libraryList, libraryPage } = this.state;
    const { total, page, per_page } = libraryPage;
    const { type, title } = this.props.libraryListData;
    const { lang } = this.props.match.params;
    let helmetData = getHelmet(
      {
        titleFlag: true,
        twitterImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
        ogImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
      },
      lang
    );
    return (
      <React.Fragment>
        {/* 管理文档头，可以通过js动态的改变标签内的内容 */}
        <Helmet {...helmetData} />
        <Layout>
          <div className={styles.libraryList}>
            <div className={styles.libraryList_wrapper}>
              <div className={styles.libraryList_title}>{title}</div>
              <div className={styles.libraryList_list}>
                {libraryList.map((item, index) => {
                  item = type === 'favorite' ? item.library : item;

                  return (
                    <a
                      key={`libraryList_item${index}`}
                      href={`/library/${item.id}`}
                      className={styles.libraryList_item}
                    >
                      <div
                        className={styles.libraryList_item_cover}
                        style={{ backgroundImage: `url(${item.cover})` }}
                      />
                      <div className={styles.libraryList_item_content}>
                        <div
                          className={styles.libraryList_item_text}
                          style={{ WebkitBoxOrient: 'vertical' }}
                        >
                          {item.name}
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>

              {total > (page - 1) * per_page ? (
                <div className={styles.libraryList_button}>
                  <HomeLoadMore
                    loading={false}
                    loaded={false}
                    onClick={this.loadMore.bind(this)}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    libraryListData: state.library.listData
  };
};

export default withRouter(connect(mapStateToProps)(LibraryList));
