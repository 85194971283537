import { combineReducers } from 'redux';

const list = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_LIST':
      return action.list;
    default:
      return state;
  }
};

const slide = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_SLIDE':
      return action.list;
    default:
      return state;
  }
};

const more = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_MORE':
      return action.list;
    default:
      return state;
  }
};

const detail = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_DETAIL':
      return action.list;
    default:
      return state;
  }
};

const live = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_LIVE':
      return action.list;
    default:
      return state;
  }
};

const secondaryList = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_SECONDARY':
      return action.list;
    default:
      return state;
  }
};

const home_activity = (state = [], action) => {
  switch (action.type) {
    case 'SET_HOME_ACTIVITY':
      return action.list;
    default:
      return state;
  }
};

const commentsList = (state = '', action) => {
  switch (action.type) {
    case 'SET_COMMENTS_LIST':
      return action.list;
    default:
      return state;
  }
};

const time = (state = 0, action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_TIME':
      return action.list;
    default:
      return state;
  }
};

const info = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_INFO':
      return action.list;
    default:
      return state;
  }
};

const likeNum = (state = 0, action) => {
  switch (action.type) {
    case 'SET_LIKE_NUM':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  slide,
  more,
  detail,
  secondaryList,
  live,
  home_activity,
  commentsList,
  time,
  info,
  likeNum
});
