import React, { Component } from 'react';
import styles from '../../index.scss';

export class InfoShareTh extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>รายการตรวจสอบการแบ่งปันข้อมูล</h5>
        <p>
          เพื่อให้คุณเข้าใจชัดเจนยิ่งขึ้นเกี่ยวกับการแบ่งปันข้อมูลส่วนบุคคลของเรา
          เราได้กำหนดรายการต่อไปนี้:
        </p>
        <h6>1. รายการ SDK ของบุคคลที่สาม</h6>
        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>แบ่งปัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>วีแชท</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>
                ให้ผู้ใช้มีฟังก์ชั่นการเข้าสู่ระบบของบุคคลที่สามเพื่อช่วยให้ผู้ใช้แบ่งปันเนื้อหาไปยัง
                WeChat
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>เซินเจิ้น Tencent Computer System Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>แบ่งปัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>ซินา</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>
                ให้ผู้ใช้มีฟังก์ชั่นการเข้าสู่ระบบของบุคคลที่สามเพื่อช่วยให้ผู้ใช้แบ่งปันเนื้อหากับ
                Sina
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>Beijing Weimeng Chuangke Network Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>400-096-0960</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://weibo.com/signup/v5/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://weibo.com/signup/v5/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>ที่จะผลักดัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>ดัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ผลักดันเนื้อหาข้อมูลที่เกี่ยวข้อง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>บริษัท เดลี่ อินเตอร์แอคทีฟ จำกัด</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>support@getui.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://docs.getui.com/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.getui.com/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>ที่จะผลักดัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>ออโรร่า</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ผลักดันเนื้อหาข้อมูลที่เกี่ยวข้อง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>เซินเจิ้น Hexun Huagu Information Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>sales@jiguang.cn</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.jiguang.cn/license/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.jiguang.cn/license/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>ที่จะผลักดัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>หัวเว่ย</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ผลักดันเนื้อหาข้อมูลที่เกี่ยวข้อง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>บริษัท หัวเว่ย ซอฟต์แวร์ เทคโนโลยี จำกัด</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>ที่จะผลักดัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>ข้าวฟ่าง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ผลักดันเนื้อหาข้อมูลที่เกี่ยวข้อง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>Xiaomi โมบายล์ซอฟต์แวร์ บจก.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.mi.com/support"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.mi.com/support
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://dev.mi.com/console/doc/detail?pId=1822"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://dev.mi.com/console/doc/detail?pId=1822
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>ที่จะผลักดัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>OPPO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ผลักดันเนื้อหาข้อมูลที่เกี่ยวข้อง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>Guangdong Huantai Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>
                <a
                  href="https://brand.heytap.com/privacy-feedback.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://brand.heytap.com/privacy-feedback.html
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://open.oppomobile.com/wiki/doc#id=10288"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://open.oppomobile.com/wiki/doc#id=10288
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>ที่จะผลักดัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ผลักดันเนื้อหาข้อมูลที่เกี่ยวข้อง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>บริษัท วีโว่ โมบายล์ คอมมิวนิเคชั่น จำกัด</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>privacy@vivo.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.vivo.com.cn/about-vivo/privacy-policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>ตำแหน่ง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>ทอง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ให้เนื้อหาข้อมูลท้องถิ่นแก่ผู้ใช้</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์และเปิดใช้งานข้อมูล GPS ภายใต้การอนุญาตตำแหน่ง</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>Gaode Software Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>Privacy.Amap@service.autonavi.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://lbs.amap.com/pages/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://lbs.amap.com/pages/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>เข้าสู่ระบบด้วยคลิกเดียว</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>มือถือจีน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ให้บริการล็อกอินแบบไม่มีรหัสยืนยันแก่ผู้ใช้</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์ หมายเลขท้องถิ่น</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>China Mobile Communications Group Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>10086</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="http://dev.10086.cn/docInside?contentId=10000009826805"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  http://dev.10086.cn/docInside?contentId=10000009826805
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>แบ่งปัน</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>ช่วยให้ผู้ใช้แบ่งปันเนื้อหา ODAILY กับ QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>เซินเจิ้น Tencent Computer System Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>สถิติ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>พันธมิตร</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>การวิเคราะห์ข้อมูลทางสถิติ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>Youmeng Tongxin (ปักกิ่ง) Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>Umeng_Legal@service.umeng.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.umeng.com/page/policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.umeng.com/page/policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>สถิติ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>หัวเว่ย</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>การวิเคราะห์ข้อมูลทางสถิติ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>บริษัท หัวเว่ย ซอฟต์แวร์ เทคโนโลยี จำกัด</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>รวบรวมบันทึก</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>bugly</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>
                รวบรวมข้อมูลความผิดพลาดของแอพ เพื่อให้เราสามารถค้นหาปัญหาได้
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>เซินเจิ้น Tencent Computer System Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>ประเภทฟังก์ชัน</p>
            </td>
            <td>
              <p>การติดตั้งแอพด้วยคลิกเดียว</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อ SDK</p>
            </td>
            <td>
              <p>openinstall</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วัตถุประสงค์ในการใช้งาน</p>
            </td>
            <td>
              <p>
                ช่วยให้ผู้ใช้ตระหนักถึงการกระโดดอย่างรวดเร็วและดาวน์โหลดและติดตั้งแอพด้วยคลิกเดียว
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ช่องข้อมูลส่วนบุคคล</p>
            </td>
            <td>
              <p>ข้อมูลอุปกรณ์</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการประมวลผล</p>
            </td>
            <td>
              <p>
                นำการไม่ระบุตัวตน การเข้ารหัส และวิธีการอื่นๆ
                มาใช้สำหรับการส่งและการประมวลผล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>วิธีการแบ่งปัน</p>
            </td>
            <td>
              <p>คอลเลกชันดั้งเดิมของ SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ชื่อเรื่อง</p>
            </td>
            <td>
              <p>เซินเจิ้น Fenmiao Network Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>ข้อมูลติดต่อ</p>
            </td>
            <td>
              <p>cooperation@openinstall.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>นโยบายความเป็นส่วนตัว</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.openinstall.io/privacy.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.openinstall.io/privacy.html
                </a>
              </p>
            </td>
          </tr>
        </table>

        <h6>2. รายชื่อสถาบันสหกรณ์บุคคลที่สาม</h6>
        <p>
          เราไม่ได้แบ่งปันข้อมูลส่วนบุคคลของคุณกับพันธมิตรบุคคลที่สามในขณะนี้
          หากสถานการณ์ข้างต้นเกิดขึ้น
          เราจะอัปเดตรายการนี้และขอรับการอนุญาตและความยินยอมจากคุณ
        </p>

        <h6>3. รายชื่อบริษัทในเครือ</h6>
        <p>
          เราไม่ได้แบ่งปันข้อมูลส่วนบุคคลของคุณกับบริษัทในเครือในขณะนี้
          หากเกิดสถานการณ์ข้างต้น เราจะอัปเดตรายการนี้และขออนุญาตและยินยอมจากคุณ
        </p>
      </div>
    );
  }
}

export default InfoShareTh;
