import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import animate from 'animate.css';
import { timesAgo } from '../../../common/scripts/time';

export class PostItem extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['normal', 'small']),
    sort: PropTypes.number,
    lang: PropTypes.string,
    id: PropTypes.number.isRequired,
    entity_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    cover: PropTypes.string.isRequired,
    column: PropTypes.object,
    published_at: PropTypes.string.isRequired,
    highlightTitle: PropTypes.string,
    highlightSummary: PropTypes.string,
    extraction_tags: PropTypes.array,
    images: PropTypes.array,
    secondary_tag: PropTypes.array
  };
  static defaultProps = {
    type: 'normal',
    user: {}
  };
  constructor(props) {
    super(props);
    this.coverImage = React.createRef();
  }
  componentDidMount() {
    if (this.coverImage.current) {
      const imgNode = this.coverImage.current;
      const fadeInStyle = animate['fadeIn'];
      const url = imgNode.dataset.src;
      const image = new Image();
      image.src = url;
      image.onload = function() {
        imgNode.src = url;
        imgNode.style.opacity = 1;
        imgNode.classList.add(fadeInStyle);
      };
    }
  }

  renderHightlightTitle() {
    const markup = () => {
      return {
        __html: this.props.highlightTitle
      };
    };
    return (
      <h3 className={styles['post-title']} dangerouslySetInnerHTML={markup()} />
    );
  }
  renderHightlightSummary() {
    const markup = () => {
      return {
        __html: this.props.summary
      };
    };
    return (
      <p
        className={styles['post-summary']}
        dangerouslySetInnerHTML={markup()}
      />
    );
  }
  renderHightlightSummary2() {
    const markup = () => {
      return {
        __html: this.props.highlightSummary
      };
    };
    return (
      <p
        className={styles['post-summary']}
        dangerouslySetInnerHTML={markup()}
      />
    );
  }
  renderTags(secondary_tag) {
    if (secondary_tag && typeof secondary_tag === 'object') {
      return secondary_tag.slice(0, 4).map(item => {
        return (
          <a
            key={item.slug}
            className={styles['post-tag']}
            href={`/secondTag/${item.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.name}
          </a>
        );
      });
    }
  }
  renderNormal() {
    const {
      entity_id,
      title,
      cover,
      published_at,
      highlightTitle,
      images,
      secondary_tag,
      highlightSummary,
      id,
      lang
    } = this.props;
    let imgCover = cover === '' ? images[0] : cover;
    return (
      <div className={styles.wrapper}>
        <div className={styles.post_intro}>
          <a
            className={styles['post-title-link']}
            href={`/post/${entity_id ? entity_id : id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {highlightTitle ? (
              this.renderHightlightTitle()
            ) : (
              <h3 className={styles['post-title']}>{title}</h3>
            )}
            {highlightSummary
              ? this.renderHightlightSummary2()
              : this.renderHightlightSummary()}
          </a>
          <div className={styles.post_info}>
            <span className={styles['published-time']}>
              <p>{timesAgo(published_at, undefined, lang)}</p>
            </span>

            {this.renderTags(secondary_tag ? JSON.parse(secondary_tag) : '')}
          </div>
        </div>

        <div className={styles['post-cover']}>
          <a
            className={`${styles['cover']} ${animate.animated} ${animate.fadeIn}`}
            href={`/post/${entity_id ? entity_id : id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                imgCover
                  ? imgCover
                  : 'https://piccdn.0daily.com/202104/15074731/n7rbk73cqsx452sj.png'
              }
              alt={title}
              loading="lazy"
            />
          </a>
        </div>
      </div>
    );
  }
  renderSmall() {
    const { cover, title, id, published_at, sort, lang } = this.props;
    const sortHotStyle = sort < 3 ? styles['sort-hot'] : '';
    return (
      <div className={styles['small-container']}>
        <div className={styles['cover']}>
          {sort ? (
            <span className={`${styles['sort']} ${sortHotStyle}`}>{sort}</span>
          ) : null}
          <img src={cover} alt={title} loading="lazy" />
        </div>
        <div className={styles['intro']}>
          <h5 className={styles['title']}>
            <a href={`/post/${id}`}>{title}</a>
          </h5>
          <span className={styles['published-time']}>
            {timesAgo(published_at, undefined, lang)}
          </span>
        </div>
      </div>
    );
  }
  render() {
    if (this.props.type === 'normal') {
      return this.renderNormal();
    }
    if (this.props.type === 'small') {
      return this.renderSmall();
    }
  }
}
