// import img from '../../assets/image/topic_top_pin.png';
import mainListImg1 from '../../assets/image/年度人物奖.png';
// import mainListImg2 from '../../assets/image/年度区块链新基建企业.png';
import mainListImg3 from '../../assets/image/年度项目奖.png';
import mainListImg4 from '../../assets/image/年度投资机构奖.png';
import mainListImg5 from '../../assets/image/年度加密金融服务商.png';
import mainListImg6 from '../../assets/image/年度矿业品牌.png';
import transboundaryList1 from '../../assets/image/跨界榜图.png';
// import specialimg1 from '../../assets/image/36位坚守价值行业OG.png';
import specialimg2 from '../../assets/image/90后榜图.png';
import footerList1_2 from '../../assets/image/36氪.png';
import footerList2_1 from '../../assets/image/金色财经.png';
import footerList2_2 from '../../assets/image/火星财经.png';
import footerList2_3 from '../../assets/image/深链财经.png';
import footerList2_4 from '../../assets/image/cointelegraph.png';
import footerList3_1 from '../../assets/image/律动.png';
import footerList3_2 from '../../assets/image/火讯财经.png';
import footerList3_3 from '../../assets/image/陀螺财经.png';
import footerList3_4 from '../../assets/image/blocklike.png';
import footerList3_5 from '../../assets/image/PANews.png';
import footerList3_6 from '../../assets/image/bibi.png';
import footerList3_7 from '../../assets/image/coinvoice.png';
import footerList3_8 from '../../assets/image/猎云财经.png';
import footerList3_9 from '../../assets/image/币牛牛.png';
import footerList3_10 from '../../assets/image/羊驼财经.png';
import footerList4_1 from '../../assets/image/币乎.png';
import footerList4_2 from '../../assets/image/无涯社区.png';
import footerList4_3 from '../../assets/image/链节点.png';
import footerList4_4 from '../../assets/image/hold.png';
import footerList4_6 from '../../assets/image/一块.png';
import footerList4_7 from '../../assets/image/polkabase.png';
import footerList4_5 from '../../assets/image/链读.png';
import footerList3_11 from '../../assets/image/blockark.png';
import footerList3_12 from '../../assets/image/博链.png';
import footerList3_13 from '../../assets/image/anypay.png';
import footerList3_14 from '../../assets/image/币世界.png';
import footerList3_15 from '../../assets/image/快链.png';
import footerList3_16 from '../../assets/image/星际视界.png';
import footerList3_17 from '../../assets/image/链捕手.png';
import footerList5_1 from '../../assets/image/中关村区块链产业联盟.png';
import footerList5_2 from '../../assets/image/数字经济中心.png';
import footerList6_1 from '../../assets/image/BCA.png';

import BenHorowitz from '../../assets/image/BenHorowitz.png';
import CharlesHoskinson from '../../assets/image/CharlesHoskinson.png';
import chang from '../../assets/image/长铗.png';
import DanielLarimer from '../../assets/image/EOS创始人.png';
import BrianArmstrong from '../../assets/image/Coinbase联合创始人兼首席执行官.png';
import AdamBack from '../../assets/image/Blockstream联合创始人兼CEO.png';
import BarrySilbert from '../../assets/image/数字货币集团创始人兼CEO.png';
import du from '../../assets/image/杜均.png';
import da from '../../assets/image/达鸿飞.png';
import FredEhrsam from '../../assets/image/原Coinbase交易所联合创始人.png';
import ge from '../../assets/image/葛越晟.png';
import gu from '../../assets/image/币乎创始人咕噜.png';
import he from '../../assets/image/何一.png';
import jiang from '../../assets/image/江桌尔.png';
import ju from '../../assets/image/巨蟹.png';
import JosephLubin from '../../assets/image/以太坊联合创始人.png';
import liling from '../../assets/image/李林.png';
import ling from '../../assets/image/林嘉鹏.png';
import lv from '../../assets/image/吕国宁.png';
import liuyi from '../../assets/image/刘毅.png';
import liqiwei from '../../assets/image/莱特币创始人.png';
import pan from '../../assets/image/潘志彪.png';
import RogerVer from '../../assets/image/R.png';
import shen from '../../assets/image/神鱼.png';
import shenbo from '../../assets/image/沈波.png';
import tian from '../../assets/image/田甲.png';
import wu from '../../assets/image/吴忌涵.png';
import xu from '../../assets/image/徐明星.png';
import xiong from '../../assets/image/熊越.png';
import yang from '../../assets/image/杨海坡.png';
import luyang from '../../assets/image/陆yang.png';
import wugang from '../../assets/image/吴钢.png';
import yangzuoxing from '../../assets/image/杨作兴.png';
import zhangnangen from '../../assets/image/张楠赓.png';
import zhao from '../../assets/image/CZ.png';
import xuxin from '../../assets/image/许昕.png';

import AlyssaTsai from '../../assets/image/AlyssaTsai.png';
import an from '../../assets/image/安鑫鑫.png';
import AmitiUttarwar from '../../assets/image/AmitiUttarwar.png';
import AlexisGauba from '../../assets/image/AlexisGauba.png';
import AlexanderLiegl from '../../assets/image/AlexanderLiegl.png';
import Amos from '../../assets/image/AmosZhang.png';
import BobbyOng from '../../assets/image/BobbyOng.png';
import Blockpunk from '../../assets/image/Blockpunk.png';
import BrianTubergen from '../../assets/image/BrianTubergen.png';
import baiyi from '../../assets/image/白一.png';
import bi from '../../assets/image/毕彤彤.png';
import chenfeng from '../../assets/image/陈枫.png';
import chenzihao from '../../assets/image/陈子豪.png';
import CharlieNoyes from '../../assets/image/CharlieNoyes.png';
import ChandlerSong from '../../assets/image/ChandlerSong.png';
import caiyan from '../../assets/image/蔡彦.png';
import CamilaDeSouza from '../../assets/image/CamilaDeSouza.png';
import chuan from '../../assets/image/船长.png';
import Dora from '../../assets/image/Dora.png';
import daishichao from '../../assets/image/代世超.png';
import Dominic from '../../assets/image/Dominic.png';
import DavidGan from '../../assets/image/DavidGan.png';
import EvaBeylin from '../../assets/image/EvaBeylin.png';
import EricChen from '../../assets/image/EricChen.png';
import EvanShapiro from '../../assets/image/EvanShapiro.png';
import fupan from '../../assets/image/付盼.png';
import FloriMarquez from '../../assets/image/FloriMarquez.png';
import gaojin from '../../assets/image/高金.png';
import huchuangyue from '../../assets/image/嘉兴区块链技术研究院胡创悦.png';
import HenryYan from '../../assets/image/Herry.png';
import Hayden from '../../assets/image/Hayden.png';
import JiaYaoqi from '../../assets/image/JiaYaoqi.png';
import JosephKrug from '../../assets/image/JosephKrug.png';
import JackMallers from '../../assets/image/JackMallers.png';
import JeniferLiu from '../../assets/image/Jennifer.png';
import kuanglizhong from '../../assets/image/匡立中.png';
import kuaidong from '../../assets/image/郐冬.png';
import KatherineWu from '../../assets/image/KatherineWu.png';
import kamie from '../../assets/image/卡咩.png';
import KinsaDurst from '../../assets/image/KinsaDurst.png';
import liyang from '../../assets/image/李阳.png';
import liangqingkai from '../../assets/image/梁清凯.png';
import linweihao from '../../assets/image/林维浩.png';
import liuyunhan from '../../assets/image/刘云汉.png';
import Lily from '../../assets/image/lily.png';
import lichenxing from '../../assets/image/李辰星.png';
import luyaoyuan from '../../assets/image/陆遥远.png';
import Lurpis from '../../assets/image/Lurpis.png';
import LarrySukernik from '../../assets/image/LarrySukernik.png';
import lixi from '../../assets/image/李希.png';
import LindaXie from '../../assets/image/LindaXie.png';
import MableJiang from '../../assets/image/MableJiang.png';
import Melody from '../../assets/image/Melody.png';
import mahaobo from '../../assets/image/马昊伯.png';
import Mia from '../../assets/image/Mia.png';
import Mila from '../../assets/image/Mila.png';
import Molly from '../../assets/image/Molly.png';
// import pima from '../../assets/image/匹马.png';
import QinWen from '../../assets/image/QinWen.png';
import qukuailian from '../../assets/image/区块链威廉.png';
import SiddharthaDutta from '../../assets/image/SiddharthaDutta.png';
import songting from '../../assets/image/宋婷.png';
import StaniKulechov from '../../assets/image/StaniKulechov.png';
import StellaKung from '../../assets/image/StellaKungQtumCMO.png';
import SujiYan from '../../assets/image/SujiYan.png';
import SamBankmanFried from '../../assets/image/SamBankman-Fried.png';
import sunyuchen from '../../assets/image/孙宇晨.png';
import ShanAggarwal from '../../assets/image/Shan.png';
import suolaotou from '../../assets/image/索老头.png';
import suye from '../../assets/image/宿冶.png';
import TMLee from '../../assets/image/TMLee.png';
import tonglin from '../../assets/image/佟林.png';
import VitalikButerin from '../../assets/image/VitalikButerin.png';
import wangdachui from '../../assets/image/王大锤.png';
import wangyongze from '../../assets/image/王永泽.png';
import WillWarren from '../../assets/image/WillWarren.png';
import weizheng from '../../assets/image/魏征.png';
import wuxiao from '../../assets/image/吴啸.png';
import wenjie from '../../assets/image/文杰.png';
import xumaotong from '../../assets/image/徐茂桐.png';
import xuyemu from '../../assets/image/徐野木.png';
import yangxiao from '../../assets/image/杨笑.png';
import yilong from '../../assets/image/一龙.png';
import yaxun from '../../assets/image/雅询.png';
import yuzhu from '../../assets/image/玉竹.png';
import zhangling from '../../assets/image/张灵.png';
import zuochangbo from '../../assets/image/左常柏.png';
import dajingjing from '../../assets/image/大静静.png';

import BinanceSmartChain from '../../assets/image/BinanceSmartChain.png';
import Conflux from '../../assets/image/Confluxlogo.png';
import DFINITY from '../../assets/image/dfinity.png';
import Filecoin from '../../assets/image/Filecoin.png';
import NEARProtocol from '../../assets/image/NEARProtocol.png';
import Neo from '../../assets/image/neo_color_dark.png';
import PlatON from '../../assets/image/PlatON-logo.png';
import Polkadot from '../../assets/image/polkadot.png';
import Solana from '../../assets/image/Solana.png';
import TRON from '../../assets/image/波场.png';
import inch from '../../assets/image/inch.png';
import Aave from '../../assets/image/AAVE.png';
import Balancer from '../../assets/image/Balancer.png';
import Chainlink from '../../assets/image/Chainlink.png';
import Compound from '../../assets/image/Compound.png';
import Loopring from '../../assets/image/LOOPRING_blue.png';
import MakerDao from '../../assets/image/MakerDao.png';
import SushiSwap from '../../assets/image/SushiSwap.png';
import TheGraph from '../../assets/image/TheGraph.png';
import Yearn from '../../assets/image/Yearn.png';

import AndreCronje from '../../assets/image/AndreCronje.png';
import GavinWood from '../../assets/image/gavinwood.png';
import HaydenAdams from '../../assets/image/HaydenAdams.png';
import SamBankmanFried1 from '../../assets/image/SamBankman-Fried.png';
import VitalikButerin1 from '../../assets/image/VitalikButerin.png';

import bitedalu from '../../assets/image/比特大陆.png';
import diancun from '../../assets/image/点存.png';
import huoxing from '../../assets/image/火星云矿logo.png';
import jike from '../../assets/image/极客矿业.png';
import jianan from '../../assets/image/嘉楠科技.png';
import RRMine from '../../assets/image/RRMine.png';
import shiliu from '../../assets/image/石榴矿池.png';
import xinghuo from '../../assets/image/星火矿池.png';
import yinbite from '../../assets/image/印比log黑.png';
import yuchi from '../../assets/image/鱼池.png';

import AmberGroup from '../../assets/image/AmberGroup.png';
import BitKeep from '../../assets/image/BitKeep.png';
import beibao from '../../assets/image/贝宝.png';
import bitepai from '../../assets/image/比特派.png';
import Cobo from '../../assets/image/cobo.png';
import CyberX from '../../assets/image/CyberX.png';
import DeBank from '../../assets/image/DeBank.png';
import HashKeyHub from '../../assets/image/hashkeyhub.png';
import Hashquark from '../../assets/image/Hashquark.png';
import imToken from '../../assets/image/imtoker.png';
import kushen from '../../assets/image/库神钱包.png';
import MathWallet from '../../assets/image/MathWallet.png';
import Matrixport from '../../assets/image/Matrixport_logo_final_1.png';
import Pionex from '../../assets/image/Pionex派网.png';
import Tokenpocket from '../../assets/image/Tokenpocket.png';

import AlamedaResearch from '../../assets/image/AlamedaResearch.png';
import ArringtonXRPCapital from '../../assets/image/arringtonXRP.png';
import Axia8Ventures from '../../assets/image/axia8-logo.png';
import BinanceLabs from '../../assets/image/BinanceLabs.png';
import CoinFund from '../../assets/image/CoinFund.png';
import CoinbaseVentures from '../../assets/image/coinbaseventures.png';
import ConsenSysLabs from '../../assets/image/ConsenSysLabs.png';
import D1Ventures from '../../assets/image/D1Ventures.png';
import DigitalCurrencyGroup from '../../assets/image/DigitalCurrencyGroup.png';
import DragonflyCapital from '../../assets/image/Dragonfly.png';
import FenbushiCapital from '../../assets/image/FenbushiCapital.png';
import FrameworkVentures from '../../assets/image/FrameworkVentures.png';
import HashkeyCapital from '../../assets/image/HashKeyCapital.png';
import huobi from '../../assets/image/火币资本.png';
import IncubaAlpha from '../../assets/image/IncubaAlpha.png';
import IOSGVentures from '../../assets/image/IOSGVentures.png';
import jinke from '../../assets/image/金氪资本.png';
import keyin from '../../assets/image/科银资本.png';
import liaode from '../../assets/image/了得资本.png';
import MulticoinCapital from '../../assets/image/MulticoinCapital.png';
import NGCVentures from '../../assets/image/NGC.png';
import PanteraCapital from '../../assets/image/Pantera.png';
import Paradigm from '../../assets/image/Paradigm.png';
import ParaFiCapital from '../../assets/image/ParaFiCapital.png';
import PolychainCapital from '../../assets/image/polychain.png';
import SNZ from '../../assets/image/SNZ.png';
import tongzhou from '../../assets/image/同舟资本.png';

import songting2 from '../../assets/image/宋婷2.png';
import RobbieBarrat from '../../assets/image/RobbieBarrat.png';
import Fewocious from '../../assets/image/Fewocious.png';
import Reva from '../../assets/image/Reva.png';
import Pak from '../../assets/image/Pak.png';
import AlidaSun from '../../assets/image/AlidaSun.png';
import dabei from '../../assets/image/大悲宇宙.png';
import chenbaoyang from '../../assets/image/chenbaoyang.png';
import chenlinji from '../../assets/image/陈麟机.png';
import Hipworth from '../../assets/image/Hipworth.png';
import MicahJohnson from '../../assets/image/MicahJohnson.png';
import Suryantosur from '../../assets/image/Suryantosur.png';

const mainList = [
  {
    title: '2020「FAT」年度人物',
    text:
      '他们是行业不可或缺的引领者和开拓者，拨开迷雾，探寻未来，为区块链的技术与商业发展指明下一方向，开拓出更广阔的生态与市场。他们代表的企业/项目为区块链世界树立了标杆。他们不吝输出观点和新知，让区块链被更广泛地认知，加速破圈。',
    children: [
      '所属企业的行业地位',
      '对行业发展的突出贡献',
      '社区与社交媒体上活跃度',
      '破圈影响力'
    ],
    img: mainListImg1,
    href: 'http://odailyblockchain.mikecrm.com/lR7HnM7',
    imgList: [
      { name: 'Andre Cronje', url: AndreCronje, text: 'YFI创始人' },
      { name: 'Barry Silbert', url: BarrySilbert, text: 'DCG创始人' },
      { name: 'Gavin Wood', url: GavinWood, text: '波卡创始人' },
      { name: 'Hayden Adams', url: HaydenAdams, text: 'Uniswap创始人' },
      {
        name: 'Sam Bankman-Fried',
        url: SamBankmanFried1,
        text: 'FTX& Alameda Research 创始人 & CEO'
      },
      { name: 'Vitalik Buterin', url: VitalikButerin1, text: '以太坊创始人' },
      { name: '赵长鹏', url: zhao, text: '币安创始人' }
    ]
  },
  // {
  //   title: '2020「FAT」 年度区块链新基建企业',
  //   text:
  //     '他们背靠实体产业，用创新技术解决最迫切、最重大的现实问题，在区块链技术的推广和落地、业务各环节的相互信任等方面发挥着决定性作用。',
  //   children: [
  //     '落地场景适配度',
  //     '业务量级',
  //     '产业上下游合作案例',
  //     '业务落地进展'
  //   ],
  //   img: mainListImg2,
  //   href: 'http://odailyblockchain.mikecrm.com/6A0z4M2'
  // },
  {
    title: '2020「FAT」 年度项目',
    text:
      '他们是兼备敏锐视角与执行力的创新者，也是直面问题并提出解决方案的勇者，更是价值的发现与捕获者。他们或是底层建设的引领者，或是应用创新的破局者。（本榜单将评选十大公链项目、十大DeFi项目）',
    children: [
      '所属赛道的热度和发展潜力',
      '业务体量&用户数',
      '上下游合作关系',
      '模式创新能力'
    ],
    img: mainListImg3,
    href: 'http://odailyblockchain.mikecrm.com/DafiwfV',
    imgList: [
      { name: 'Binance Smart Chain', url: BinanceSmartChain },
      { name: 'Conflux', url: Conflux },
      { name: 'DFINITY', url: DFINITY },
      { name: 'Filecoin', url: Filecoin },
      { name: 'NEAR Protocol', url: NEARProtocol },
      { name: 'Neo', url: Neo },
      { name: 'PlatON', url: PlatON },
      { name: 'Polkadot', url: Polkadot },
      { name: 'Solana', url: Solana },
      { name: 'TRON', url: TRON },
      { name: '1inch', url: inch },
      { name: 'Aave', url: Aave },
      { name: 'Balancer', url: Balancer },
      { name: 'Chainlink', url: Chainlink },
      { name: 'Compound', url: Compound },
      { name: 'Loopring', url: Loopring },
      { name: 'MakerDao', url: MakerDao },
      { name: 'SushiSwap', url: SushiSwap },
      { name: 'The Graph', url: TheGraph },
      { name: 'Yearn', url: Yearn }
    ]
  },
  {
    title: '2020「FAT」年度投资机构',
    text:
      '他们是群有眼光有实力的猎手，通过深入的研判、严谨的调查和全方位的支持，发现并塑造行业中的下一个独角兽。',
    children: ['投资回报', '投资规模', '布局广度和精准度', '投后支持'],
    img: mainListImg4,
    href: 'http://odailyblockchain.mikecrm.com/jK46oHc',
    imgList: [
      { name: 'Alameda Research', url: AlamedaResearch },
      { name: 'Arrington XRP Capital', url: ArringtonXRPCapital },
      { name: 'Axia8 Ventures', url: Axia8Ventures },
      { name: 'Binance Labs', url: BinanceLabs },
      { name: 'Coin Fund', url: CoinFund },
      { name: 'Coinbase Ventures', url: CoinbaseVentures },
      { name: 'ConsenSys Labs', url: ConsenSysLabs },
      { name: 'D1 Ventures', url: D1Ventures },
      { name: 'Digital Currency Group', url: DigitalCurrencyGroup },
      { name: 'Dragonfly Capital', url: DragonflyCapital },
      { name: 'Fenbushi Capital', url: FenbushiCapital },
      { name: 'FrameworkVentures', url: FrameworkVentures },
      { name: 'HashKey Capital', url: HashkeyCapital },
      { name: '火币资本', url: huobi },
      { name: 'Incuba Alpha', url: IncubaAlpha },
      { name: 'IOSG Ventures', url: IOSGVentures },
      { name: '金氪资本', url: jinke },
      { name: '科银资本', url: keyin },
      { name: '了得资本', url: liaode },
      { name: 'Multicoin Capital', url: MulticoinCapital },
      { name: 'NGC Ventures', url: NGCVentures },
      { name: 'Pantera Capital', url: PanteraCapital },
      { name: 'Paradigm', url: Paradigm },
      { name: 'ParaFi Capital', url: ParaFiCapital },
      { name: 'Polychain Capital', url: PolychainCapital },
      { name: 'SNZ', url: SNZ },
      { name: '同舟资本', url: tongzhou }
    ]
  },
  {
    title: '2020「FAT」年度加密金融服务商',
    text:
      '他们站在加密财富的中心，接管客户们的各类委托和多元诉求，实现加密资产的安心托付和保值增值。',
    children: [
      '产品能力&丰富度',
      '业务体量&用户数',
      '行业敏锐度和前瞻性',
      '用户评价与忠诚度'
    ],
    img: mainListImg5,
    href: 'http://odailyblockchain.mikecrm.com/HiwvDPH',
    imgList: [
      { name: 'Amber Group', url: AmberGroup },
      { name: 'BitKeep', url: BitKeep },
      { name: '贝宝金融', url: beibao },
      { name: '比特派钱包', url: bitepai },
      { name: 'Cobo', url: Cobo },
      { name: 'CyberX', url: CyberX },
      { name: 'DeBank', url: DeBank },
      { name: 'HashKey Hub', url: HashKeyHub },
      { name: 'HashQuark', url: Hashquark },
      { name: 'imToken', url: imToken },
      { name: '库神钱包', url: kushen },
      { name: 'MathWallet', url: MathWallet },
      { name: 'Matrixport', url: Matrixport },
      { name: 'Pionex派网', url: Pionex },
      { name: 'TokenPocket', url: Tokenpocket }
    ]
  },
  {
    title: '2020「FAT」 年度矿业品牌',
    text:
      '他们守护着底层网络的安全与稳定运行，在加密资产的上游生产环节默默耕耘，长期稳固着区块链的基础设施。',
    children: [
      '企业规模和同类中排名',
      '长期稳定运营能力',
      '为客户/用户带来的价值',
      '模式创新能力'
    ],
    img: mainListImg6,
    href: 'http://odailyblockchain.mikecrm.com/WqhAiS9',
    imgList: [
      { name: '比特大陆', url: bitedalu },
      { name: '点存科技', url: diancun },
      { name: '火星云矿', url: huoxing },
      { name: '极客矿业', url: jike },
      { name: '嘉楠科技', url: jianan },
      { name: 'RRMine', url: RRMine },
      { name: '石榴矿池', url: shiliu },
      { name: '星火矿池', url: xinghuo },
      { name: '印比特', url: yinbite },
      { name: '鱼池F2Pool', url: yuchi }
    ]
  }
];

const timerList = [
  {
    time: '11.23',
    text: '各奖项候选人征集'
  },
  {
    time: '11.30',
    text: '人气投票开始'
  },
  {
    time: '12.25',
    text: '人气投票截止'
  },
  {
    time: '12.28',
    text: '奖项评选'
  },
  {
    time: '01.07',
    text: '奖项公布及颁奖典礼'
  }
];

// const popularityList = [
//   {
//     title: 'F.A.T年度最受社区欢迎区块链项目',
//     id: 11,
//     text: '他们为用户带来了解决问题的实际方案，也为投资者赚取了丰厚的收益。',
//     imgList: [{ name: 'Binance Smart Chain', url: BinanceSmartChain }],
//     href: 'http://odailyblockchain.mikecrm.com/IQfwIeV'
//   },
//   {
//     title: 'F.A.T年度最受欢迎加密交易平台',
//     id: 12,
//     text:
//       '他们为资产交换提供了高效便捷安全的平台，以丰富的产品业务满足持有者的多元需求。',
//     imgList: [
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 }
//     ],
//     href: 'http://odailyblockchain.mikecrm.com/QSgoMRZ'
//   },
//   {
//     title: 'F.A.T年度最受用户欢迎加密社区',
//     id: 13,
//     text:
//       '他们聚集了意见领袖的精华观点，时刻传递着区块链世界最新、最重要的信息。',
//     imgList: [
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 }
//     ],
//     href: 'http://odailyblockchain.mikecrm.com/QczgVCX'
//   },
//   {
//     title: 'F.A.T年度最具影响力微博意见领袖',
//     id: 14,
//     text: '他们积极拓宽区块链的影响边界，对外主动发声，指明下一个行业焦点。',
//     imgList: [
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 },
//       { name: '虚位以待', num: 100 }
//     ],
//     href: 'http://odailyblockchain.mikecrm.com/ec8xcM2'
//   }
// ];

const popularityList = [
  {
    title: 'F.A.T年度最受社区欢迎区块链项目',
    id: 11,
    text: '他们为用户带来了解决问题的实际方案，也为投资者赚取了丰厚的收益。',
    imgList: [
      {
        name: 'Bifrost',
        url: 'https://piccdn.0daily.com/202011/30060038/httq3hexr9jx2nif.png'
      },
      {
        name: 'BigBang Core',
        url: 'https://piccdn.0daily.com/202012/02083254/9bba80leboz9hsba.jpg'
      },
      {
        name: 'CertiK Chain',
        url: 'https://piccdn.0daily.com/202012/02032357/3uwwsked8e7119pn.jpg'
      },
      {
        name: 'Cocos-BCX',
        url: 'https://piccdn.0daily.com/202011/30060036/n81lirgahabyb63u.jpg'
      },
      {
        name: 'Conflux',
        url: 'https://piccdn.0daily.com/202011/30061710/44neftxf63xmr3rr.png'
      },
      {
        name: 'Cortex Labs',
        url: 'https://piccdn.0daily.com/202012/21025703/3m0dy3ewyhyzyy9z.jpg'
      },
      {
        name: 'dForce',
        url: 'https://piccdn.0daily.com/202012/09065321/p61otooprsgkmk3l.jpg'
      },
      {
        name: 'DODO',
        url: 'https://piccdn.0daily.com/202012/01102410/djqrdhkj2yivfqwn.jpg'
      },
      {
        name: 'Findora',
        url: 'https://piccdn.0daily.com/202012/07033810/r6ftwbe3p7ce95nv.jpg'
      },
      {
        name: 'Firo',
        url: 'https://piccdn.0daily.com/202011/30102859/nlk3913k23fm0cnc.png'
      },
      {
        name: 'ForTube',
        url: 'https://piccdn.0daily.com/202012/18070050/bndhe7zf4p2u4ths.jpg'
      },
      {
        name: 'Farmland',
        url: 'https://piccdn.0daily.com/202101/08051503/mis8d41acmoep03p.png'
      },
      {
        name: 'Injective Protocol',
        url: 'https://piccdn.0daily.com/202011/30061758/2ewyuaglic5oyhov.png'
      },
      {
        name: 'Mask Network',
        url: 'https://piccdn.0daily.com/202011/30060026/vlnrfm560d60kal6.png'
      },
      {
        name: 'Organix协议',
        url: 'https://piccdn.0daily.com/202011/30060042/x2c662f0cy6mc4xt.png'
      },
      {
        name: 'Props',
        url: 'https://piccdn.0daily.com/202012/07095824/b4hqfi2hjvkeb1yj.jpg'
      },
      {
        name: 'Solana',
        url: 'https://piccdn.0daily.com/202012/08074803/ce33xv55byugtlyb.png'
      },
      {
        name: 'Sperax',
        url: 'https://piccdn.0daily.com/202012/02064035/6eb9ackzjliqyr9n.png'
      },
      {
        name: 'TOP Network',
        url: 'https://piccdn.0daily.com/202012/02103206/hynrb2j4a1897t8e.png'
      },
      {
        name: 'Vtrading',
        url: 'https://piccdn.0daily.com/202012/03021017/40ipb15zkfhaxag3.png'
      },
      {
        name: 'YFII',
        url: 'https://piccdn.0daily.com/202012/09074324/v50b81rkirdgua8a.png'
      }
    ],
    href: 'http://odailyblockchain.mikecrm.com/IQfwIeV'
  },
  {
    title: 'F.A.T年度最受欢迎加密交易平台',
    id: 12,
    text:
      '他们为资产交换提供了高效便捷安全的平台，以丰富的产品业务满足持有者的多元需求。',
    imgList: [
      {
        name: 'AAX',
        url: 'https://piccdn.0daily.com/202011/30060709/ryqoylljwcb8rnnl.png'
      },
      {
        name: '币看智能交易平台',
        url: 'https://piccdn.0daily.com/202012/04035024/171ac5kbp15h9uxq.png'
      },
      {
        name: 'BiKi',
        url: 'https://piccdn.0daily.com/202011/30065914/bcafmh29bx66o18d.png'
      },
      {
        name: 'Binance',
        url: 'https://piccdn.0daily.com/202011/30060833/jq7p3yz9qc837c4j.png'
      },
      {
        name: '币赢CoinW',
        url: 'https://piccdn.0daily.com/202012/23021546/9a6rnhmrw9rz2cxl.png'
      },
      {
        name: 'BitMax',
        url: 'https://piccdn.0daily.com/202012/23033233/ca2ji0743zyymx9m.png'
      },
      {
        name: 'BitWell',
        url: 'https://piccdn.0daily.com/202012/02070146/hzks3arn9jbntqfr.png'
      },
      {
        name: 'BKEX Global',
        url: 'https://piccdn.0daily.com/202012/08072827/5rp6zaz1iwq4gani.png'
      },
      {
        name: 'Deepcoin',
        url: 'https://piccdn.0daily.com/202012/04084130/lmdgnqd0hppow9h0.png'
      },
      {
        name: 'FTX',
        url: 'https://piccdn.0daily.com/202012/10084945/4x5ofg97a7meq87y.jpg'
      },
      {
        name: 'Gate.io',
        url: 'https://piccdn.0daily.com/202012/02051744/tlje29utad46fsqv.png'
      },
      {
        name: '虎符交易所',
        url: 'https://piccdn.0daily.com/202011/30060721/3143mkyntwyre4c8.jpg'
      },
      {
        name: '火币全球站',
        url: 'https://piccdn.0daily.com/202011/30065803/b1oko4uk8zap2ylp.png'
      },
      {
        name: '霍比特HBTC',
        url: 'https://piccdn.0daily.com/202012/02070149/bcthrk7edb8bvvdg.jpg'
      },
      {
        name: '聚币',
        url: 'https://piccdn.0daily.com/202012/23021548/53pki5e4wy2lgfsl.png'
      },
      {
        name: 'LBank',
        url: 'https://piccdn.0daily.com/202012/02094116/5jr62qhub9hgvrdo.png'
      },
      {
        name: 'MXC抹茶',
        url: 'https://piccdn.0daily.com/202011/30073747/myz54m3e7fag6k59.jpg'
      },
      {
        name: 'OKEx',
        url: 'https://piccdn.0daily.com/202011/30060718/9m67sdq1avg7k717.png'
      },
      {
        name: 'WhiteBit',
        url: 'https://piccdn.0daily.com/202101/07092416/75wbu3v5b6nqmtu3.png'
      },
      {
        name: '58COIN',
        url: 'https://piccdn.0daily.com/202012/07112247/opkom7dagw4xix2o.png'
      }
    ],
    href: 'http://odailyblockchain.mikecrm.com/QSgoMRZ'
  },
  {
    title: 'F.A.T年度最受用户欢迎加密社区',
    id: 13,
    text:
      '他们聚集了意见领袖的精华观点，时刻传递着区块链世界最新、最重要的信息。',
    imgList: [
      {
        name: '499Block',
        url: 'https://piccdn.0daily.com/202012/24105858/e71z8d1i4d4dsmdt.png'
      },
      {
        name: 'BlockArk区块方舟',
        url: 'https://piccdn.0daily.com/202012/02094119/9bteeu5nlzxtishj.png'
      },
      {
        name: 'DoraHacks',
        url: 'https://piccdn.0daily.com/202012/03031510/x1sig9nyal500usv.png'
      },
      {
        name: 'HOLD厚得社区',
        url: 'https://piccdn.0daily.com/202012/24082633/hczq3ahrj6flgb6i.png'
      },
      {
        name: 'OG无涯社区',
        url: 'https://piccdn.0daily.com/202012/03063246/gx53x4d89sd2e05n.png'
      },
      {
        name: '币coin',
        url: 'https://piccdn.0daily.com/202011/30060326/i52ew57yr87x5wx2.png'
      },
      {
        name: '加密阁',
        url: 'https://piccdn.0daily.com/202012/23033230/q9ynlnpy7qtcrmq2.png'
      },
      {
        name: '力场',
        url: 'https://piccdn.0daily.com/202012/23033227/i5mfdvjizwndq7x7.png'
      },
      {
        name: '头等仓',
        url: 'https://piccdn.0daily.com/202011/30060338/c4jv18io77zj25cl.png'
      },
      {
        name: '真本聪',
        url: 'https://piccdn.0daily.com/202011/30060844/ertsovjzvetyml6h.png'
      }
    ],
    href: 'http://odailyblockchain.mikecrm.com/QczgVCX'
  },
  {
    title: 'F.A.T年度最具影响力微博意见领袖',
    id: 14,
    text: '他们积极拓宽区块链的影响边界，对外主动发声，指明下一个行业焦点。',
    imgList: [
      {
        name: 'BeatleNews',
        url:
          'https://tvax1.sinaimg.cn/crop.0.0.1080.1080.180/0070Uenrly8fz3ttdjetvj30u00u0aak.jpg?KID=imgbed,tva&Expires=1601298684&ssig=CcxpAm%2FmRQ'
      },
      {
        name: '半木夏',
        url:
          'https://tvax4.sinaimg.cn/crop.0.180.1080.1080.180/9d6d01f9ly1gdmj3ac93vj20u0140u0x.jpg?KID=imgbed,tva&Expires=1605788224&ssig=ZypjipJ7yg'
      },
      {
        name: '比特币凯撒',
        url:
          'https://tvax1.sinaimg.cn/crop.0.0.512.512.180/af25a5dcly8gdindqrq5oj20e80e8gm0.jpg?KID=imgbed,tva&Expires=1605788058&ssig=i9w6viSWKY'
      },
      {
        name: '比特大魔王V',
        url:
          'https://tvax1.sinaimg.cn/crop.0.0.1080.1080.180/b1ac3b43ly8g46lzemvjhj20u00u0gnw.jpg?KID=imgbed,tva&Expires=1605788796&ssig=l4b3SZl4zc'
      },
      {
        name: '币圈仲达',
        url:
          'https://tvax3.sinaimg.cn/crop.0.0.1002.1002.180/92632b53ly8gbxjw0ltiuj20ru0ruwhv.jpg?KID=imgbed,tva&Expires=1605791305&ssig=n6AN6XKsdQ'
      },
      {
        name: '懂币哥',
        url:
          'https://tvax2.sinaimg.cn/crop.0.0.996.996.180/007Lf1Gdly8gd2znddfb3j30ro0ro75q.jpg?KID=imgbed,tva&Expires=1605790185&ssig=G0%2B52RJQRC'
      },
      {
        name: '肥宅比特币',
        url:
          'https://tvax4.sinaimg.cn/crop.0.0.1080.1080.180/007Udf6mly8ggon2jiyjaj30u00u0jtm.jpg?KID=imgbed,tva&Expires=1605788501&ssig=1fXPJB2V5%2F'
      },
      {
        name: '九妹是我',
        url:
          'https://tvax1.sinaimg.cn/crop.0.0.512.512.180/eaed0af4ly8gfspsvuodsj20e80e83yx.jpg?KID=imgbed,tva&Expires=1605791288&ssig=EFa8WrePHP'
      },
      {
        name: '区块链威廉',
        url:
          'https://tvax1.sinaimg.cn/crop.8.0.1225.1225.180/006P7uWaly8fmoyy98jpfj30yi0y1tar.jpg?KID=imgbed,tva&Expires=1605791226&ssig=8Sc7zOGP9y'
      },
      {
        name: '浙里区块链',
        url:
          'https://tvax3.sinaimg.cn/crop.0.0.996.996.180/e8f932e9ly8gigk8jllqtj20ro0roac6.jpg?KID=imgbed,tva&Expires=1605790914&ssig=N8%2BFegV4Km'
      }
    ],
    href: 'http://odailyblockchain.mikecrm.com/ec8xcM2'
  }
];

const transboundaryList = [
  {
    title: '最具潜力加密艺术家',
    text:
      '他们走在时代前端，以独特的创作理念描绘人类与科技的未来，他们从缪斯女神处获得灵感，开创了加密与艺术的跨界融合。',
    img: transboundaryList1,
    url: 'http://odailyblockchain.mikecrm.com/F99qA09',
    children: [],
    imgList: [
      { name: 'Alida Sun', url: AlidaSun },
      { name: '陈抱阳', url: chenbaoyang },
      { name: '陈麒机', url: chenlinji },
      { name: '大悲宇宙', url: dabei },
      { name: 'Fewocious', url: Fewocious },
      { name: 'Hipworth', url: Hipworth },
      { name: 'Micah Johnson', url: MicahJohnson },
      { name: 'Pak', url: Pak },
      { name: 'Reva', url: Reva },
      { name: 'Robbie Barrat', url: RobbieBarrat },
      { name: 'Song Ting', url: songting2 },
      { name: 'Suryanto Sur', url: Suryantosur }
    ]
  }
];

const special = [
  // {
  //   title: '坚守价值行业OG',
  //   text:
  //     '他们在区块链世界，坚守信念，深耕行业5年以上，他们能在纷繁的环境中找到钥匙，依靠强有力的行动，不断向前开拓创新，带领行业前行。',
  //   img: specialimg1,
  //   url: 'http://odailyblockchain.mikecrm.com/XydyWgd',
  //   children: [
  //     '区块链行业从业时间5年以上',
  //     '对行业发展的贡献度',
  //     '社区与社交媒体上活跃度'
  //   ]
  // },
  {
    title: '90后杰出从业者',
    text:
      '他们充满创意、热情和行动力，志在用最前沿的技术打破原有的商业格局和既定规则，在区块链的世界中竖立起新的旗帜。',
    img: specialimg2,
    url: 'http://odailyblockchain.mikecrm.com/0gUPdqe',
    children: [
      '出身年份在1990年及之后',
      '对行业发展的贡献度',
      '社区与社交媒体上活跃度'
    ]
  }
];

const specialList = [
  [
    {
      name: 'Adam Back',
      text: 'Blockstream联合创始人兼CEO',
      url: AdamBack
    },
    {
      name: 'Brian Armstrong',
      text: 'Coinbase联合创始人兼首席执行官',
      url: BrianArmstrong
    },
    {
      name: 'Ben Horowitz',
      text: 'Andreessen Horowitz联合创始人',
      url: BenHorowitz
    },
    {
      name: 'Barry Silbert',
      text: '数字货币集团创始人兼CEO',
      url: BarrySilbert
    },
    {
      name: 'Charles Hoskinson',
      text: 'Cardano创始人',
      url: CharlesHoskinson
    },
    {
      name: '长铗',
      text: '巴比特创始人',
      url: chang
    },
    {
      name: 'Daniel Larimer',
      text: 'EOS创始人',
      url: DanielLarimer
    },
    {
      name: '杜均',
      text: '节点资本创始人',
      url: du
    },
    {
      name: '达鸿飞',
      text: 'Neo创始人',
      url: da
    },
    {
      name: 'Fred Ehrsam',
      text: '原Coinbase联合创始人',
      url: FredEhrsam
    },
    {
      name: '葛越晟',
      text: 'Matrixport首席执行官',
      url: ge
    },
    {
      name: '咕噜',
      text: '币乎创始人',
      url: gu
    },
    {
      name: '何一',
      text: '币安Binance联合创始人',
      url: he
    },
    {
      name: '江卓尔',
      text: '莱比特矿池(BTC.TOP) CEO',
      url: jiang
    },
    {
      name: '巨蟹',
      text: 'BTS理事会成员',
      url: ju
    },
    {
      name: 'Joseph Lubin',
      text: '以太坊联合创始人',
      url: JosephLubin
    },
    {
      name: '李林',
      text: '火币创始人兼董事长',
      url: liling
    },
    {
      name: '林嘉鹏',
      text: 'LinkVC创始人',
      url: ling
    },
    {
      name: '吕国宁',
      text: 'Nervos联合创始人',
      url: lv
    },
    {
      name: '李启威',
      text: '莱特币创始人',
      url: liqiwei
    },
    {
      name: '陆扬',
      text: '唯链CEO',
      url: luyang
    },
    {
      name: '刘毅',
      text: 'Cdot Network创始人& Random Capital合伙人',
      url: liuyi
    },
    {
      name: '潘志彪',
      text: '币印矿池创始人',
      url: pan
    },
    {
      name: 'Roger Ver',
      text: '比特币耶稣',
      url: RogerVer
    },
    {
      name: '神鱼',
      text: '鱼池&Cobo钱包联合创始人',
      url: shen
    },
    {
      name: '沈波',
      text: '分布式资本创始人',
      url: shenbo
    },
    {
      name: '田甲',
      text: 'Cortex实验室联合创始人',
      url: tian
    },
    {
      name: '吴忌寒',
      text: '比特大陆创始人',
      url: wu
    },
    {
      name: '吴钢',
      text: '币信CEO',
      url: wugang
    },
    {
      name: '徐明星',
      text: '欧科集团创始人',
      url: xu
    },
    {
      name: '熊越',
      text: '币信钱包COO',
      url: xiong
    },
    {
      name: '许昕',
      text: '星火矿池CEO',
      url: xuxin
    },
    {
      name: '杨海坡',
      text: 'ViaBTC微比特CEO,CoinEx创始人',
      url: yang
    },
    {
      name: '杨作兴',
      text: '神马矿机创始人',
      url: yangzuoxing
    },
    {
      name: '张楠赓',
      text: '嘉楠耘智创始人',
      url: zhangnangen
    },
    {
      name: '赵长鹏',
      text: '币安Binance创始人',
      url: zhao
    }
  ],
  [
    {
      name: 'Alyssa Tsai',
      text: 'PANews',
      url: AlyssaTsai
    },
    {
      name: '安鑫鑫',
      text: '金色财经',
      url: an
    },
    {
      name: 'Amiti Uttarwar',
      text: 'Bitcoin Core Developer',
      url: AmitiUttarwar
    },
    {
      name: 'Alexis Gauba',
      text: 'Opyn',
      url: AlexisGauba
    },
    {
      name: 'Alexander Liegl',
      text: 'Layer1 Technologies',
      url: AlexanderLiegl
    },
    {
      name: 'Amos',
      text: 'Near Protocol',
      url: Amos
    },
    {
      name: 'Bobby Ong',
      text: 'CoinGecko',
      url: BobbyOng
    },
    {
      name: 'Blockpunk',
      text: '无涯 & Reset Dao',
      url: Blockpunk
    },
    {
      name: 'Brian Tubergen',
      text: 'CoinList',
      url: BrianTubergen
    },
    {
      name: '白一',
      text: '加密阁',
      url: baiyi
    },
    {
      name: '毕彤彤',
      text: 'PANews',
      url: bi
    },
    {
      name: '陈枫',
      text: 'Bandot',
      url: chenfeng
    },
    {
      name: '陈子豪',
      text: 'NGC',
      url: chenzihao
    },
    {
      name: 'Charlie Noyes',
      text: 'Paradigm',
      url: CharlieNoyes
    },
    {
      name: 'Chandler Song',
      text: 'Ankr Network',
      url: ChandlerSong
    },
    {
      name: '蔡彦',
      text: 'NGC',
      url: caiyan
    },
    {
      name: 'Camila De Souza',
      text: 'Andreessen Horowitz',
      url: CamilaDeSouza
    },
    {
      name: '船长',
      text: 'BlockArk区块方舟',
      url: chuan
    },
    {
      name: 'Dora',
      text: 'HashQuark',
      url: Dora
    },
    {
      name: '代世超',
      text: 'DODO',
      url: daishichao
    },
    {
      name: 'Dominic',
      text: 'Decred',
      url: Dominic
    },
    {
      name: 'DavidGan',
      text: 'Huobi Labs 管理合伙人',
      url: DavidGan
    },
    {
      name: 'Eva Beylin',
      text: 'The Graph',
      url: EvaBeylin
    },
    {
      name: 'Eric Chen',
      text: 'Injective 联合创始人& CEO',
      url: EricChen
    },
    {
      name: 'Evan Shapiro',
      text: 'O(1) Labs',
      url: EvanShapiro
    },
    {
      name: '付盼',
      text: 'TokenPocket',
      url: fupan
    },
    {
      name: 'Flori Marquez',
      text: 'BlockFi',
      url: FloriMarquez
    },
    {
      name: '高金',
      text: 'YFII核心开发者',
      url: gaojin
    },
    {
      name: '葛越晟',
      text: 'Matrixport',
      url: ge
    },
    {
      name: '胡创悦',
      text: '嘉兴区块链技术研究院',
      url: huchuangyue
    },
    {
      name: 'Henry Yan',
      text: 'Findora',
      url: HenryYan
    },
    {
      name: 'Hayden',
      text: 'Uniswap',
      url: Hayden
    },
    {
      name: 'JiaYaoqi',
      text: 'Parity（Polkadot）',
      url: JiaYaoqi
    },
    {
      name: 'Joseph Krug',
      text: 'Augur',
      url: JosephKrug
    },
    {
      name: 'Jack Mallers',
      text: 'Zap Solutions',
      url: JackMallers
    },
    {
      name: 'Jenifer Liu',
      text: 'D1',
      url: JeniferLiu
    },
    {
      name: '大静静',
      text: 'WXY',
      url: dajingjing
    },
    {
      name: '匡立中',
      text: '杭州趣链科技有限公司',
      url: kuanglizhong
    },
    {
      name: '郐冬',
      text: 'NewBloc',
      url: kuaidong
    },
    {
      name: 'Katherine Wu',
      text: 'Principal at Notation Capital',
      url: KatherineWu
    },
    {
      name: '卡咩',
      text: 'Stafi',
      url: kamie
    },
    {
      name: 'KinsaDurst',
      text: 'Reef finance',
      url: KinsaDurst
    },
    {
      name: '李阳',
      text: '橙皮书',
      url: liyang
    },
    {
      name: '梁清凯',
      text: 'Celer',
      url: liangqingkai
    },
    {
      name: '林维浩',
      text: 'Axi8',
      url: linweihao
    },
    {
      name: '刘云汉',
      text: 'Avalanche',
      url: liuyunhan
    },
    {
      name: 'Lily',
      text: 'Findora',
      url: Lily
    },
    {
      name: '李辰星',
      text: 'Conflux（用烤仔）',
      url: lichenxing
    },
    {
      name: '陆遥远',
      text: '麦子钱包',
      url: luyaoyuan
    },
    {
      name: 'Lurpis',
      text: 'Bifrost',
      url: Lurpis
    },
    {
      name: 'Larry Sukernik',
      text: 'DCG',
      url: LarrySukernik
    },
    {
      name: '李希',
      text: '了得资本',
      url: lixi
    },
    {
      name: 'Linda Xie',
      text: 'Scalar Capital',
      url: LindaXie
    },
    {
      name: 'Mable Jiang',
      text: 'Multicoin Capital',
      url: MableJiang
    },
    {
      name: 'Melody',
      text: 'Incuba Capital',
      url: Melody
    },
    {
      name: '马昊伯',
      text: '好扑科技',
      url: mahaobo
    },
    {
      name: 'Mia',
      text: 'Dragonfly',
      url: Mia
    },
    {
      name: 'Mila',
      text: 'Kira Network',
      url: Mila
    },
    {
      name: 'Molly',
      text: 'Hashkey Me',
      url: Molly
    },
    // {
    //   name: '匹马',
    //   text: 'Continue Capital',
    //   url: pima
    // },
    {
      name: 'QinWen',
      text: 'Web3基金会；Polkadot网络',
      url: QinWen
    },
    {
      name: '区块链威廉',
      text: '行业KOL',
      url: qukuailian
    },
    {
      name: 'Siddhartha Dutta',
      text: 'Marlin',
      url: SiddharthaDutta
    },
    {
      name: '神鱼',
      text: '鱼池&Cobo',
      url: shen
    },
    {
      name: '宋婷',
      text: '加密艺术家',
      url: songting
    },
    {
      name: 'Stani Kulechov',
      text: 'AAVE',
      url: StaniKulechov
    },
    {
      name: 'Stella Kung',
      text: 'Qtum',
      url: StellaKung
    },
    {
      name: 'Suji Yan',
      text: 'Maskbook ',
      url: SujiYan
    },
    {
      name: 'Sam Bankman-Fried',
      text: 'FTX',
      url: SamBankmanFried
    },
    {
      name: '孙宇晨',
      text: 'Tron',
      url: sunyuchen
    },
    {
      name: 'Shan Aggarwal',
      text: 'Coinbase',
      url: ShanAggarwal
    },
    {
      name: '索老头',
      text: '真本聪',
      url: suolaotou
    },
    {
      name: '宿冶',
      text: '区块方舟',
      url: suye
    },
    {
      name: 'TM Lee',
      text: 'CoinGecko',
      url: TMLee
    },
    {
      name: '佟林',
      text: 'Phala Network',
      url: tonglin
    },
    {
      name: 'Vitalik Buterin',
      text: 'Ethereum',
      url: VitalikButerin
    },
    {
      name: '王大锤',
      text: 'Parity',
      url: wangdachui
    },
    {
      name: '王永泽',
      text: 'Cortex',
      url: wangyongze
    },
    {
      name: 'Will Warren',
      text: '0x',
      url: WillWarren
    },
    {
      name: '魏铮',
      text: 'N7 Labs',
      url: weizheng
    },
    {
      name: '吴啸',
      text: '纯白矩阵',
      url: wuxiao
    },
    {
      name: '文杰',
      text: 'ZMQ量化',
      url: wenjie
    },
    {
      name: '徐茂桐',
      text: 'ARPA',
      url: xumaotong
    },
    {
      name: '徐野木',
      text: 'ARPA',
      url: xuyemu
    },
    {
      name: '杨海坡',
      text: 'ViaBTC',
      url: yang
    },
    {
      name: '杨笑',
      text: '熊猫矿机&牛比特',
      url: yangxiao
    },
    {
      name: '一龙',
      text: 'Near Protocol',
      url: yilong
    },
    {
      name: '雅珣',
      text: '一块+创始人',
      url: yaxun
    },
    {
      name: '玉竹',
      text: 'BitWell CMO',
      url: yuzhu
    },
    {
      name: '张灵',
      text: '币安Binance',
      url: zhangling
    },
    {
      name: '左常伯',
      text: 'Formation Group',
      url: zuochangbo
    }
  ]
];

const footerList = [
  {
    title: '合作伙伴',
    items: [footerList1_2]
  },
  {
    title: '指导机构',
    items: [footerList5_1, footerList5_2]
  },
  {
    title: '独家艺术赋能',
    items: [footerList6_1]
  },
  {
    title: '战略媒体',
    items: [footerList2_1, footerList2_2, footerList2_3, footerList2_4]
  },
  {
    title: '合作媒体',
    items: [
      footerList3_14,
      footerList3_1,
      footerList3_11,
      footerList3_2,
      footerList3_5,
      footerList3_4,
      footerList3_3,
      footerList3_6,
      footerList3_7,
      footerList3_12,
      footerList3_17,
      footerList3_8,
      footerList3_9,
      footerList3_16,
      footerList3_10,
      footerList4_5,
      footerList3_13,
      footerList3_15
    ]
  },
  {
    title: '合作社区',
    items: [
      footerList4_1,
      footerList4_2,
      footerList4_3,
      footerList4_4,
      footerList4_6,
      footerList4_7
    ]
  }
];

export {
  mainList,
  popularityList,
  transboundaryList,
  specialList,
  footerList,
  timerList,
  special
};
