exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a {\n  text-decoration: none; }\n\n.src-components-adPicture--down-index {\n  width: 100%;\n  height: 100%;\n  display: block;\n  cursor: pointer;\n  position: relative; }\n  .src-components-adPicture--down-index img {\n    width: 100%;\n    height: 100%; }\n  .src-components-adPicture--down-index .src-components-adPicture--icon-index {\n    width: 50px;\n    height: 24px;\n    background: #002CAE;\n    border-radius: 0px 0px 10px 0px;\n    font-size: 12px;\n    font-weight: 400;\n    color: #FFFFFF;\n    text-align: center;\n    line-height: 24px;\n    position: absolute;\n    top: 0;\n    left: 0; }\n", ""]);

// exports
exports.locals = {
	"down": "src-components-adPicture--down-index",
	"icon": "src-components-adPicture--icon-index"
};