import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import styles from './index.scss';
import Layout from '../../components/common/layout';
import NewsFlashItem from '../../components/newsflash/item';
import LoadMore from '../../components/common/homeloadmore';
import NewPost from '../../components/newsflash/newNewPost';
import AdPicture from '../../components/adPicture';
import Sticky from '../../components/common/sticky';
import Helmet from 'react-helmet';
// import animate from 'animate.css';
import { getAdvert } from '../../common/api/slide';

import { getNewPost } from '../../common/api/post';
import { getNewsflash } from '../../common/api/newsflash';
import wechatShare from '../../common/scripts/wechat';

const getDate = time => moment(time).format('YYYY.MM.DD.ddd');

import { getHelmet } from '../../common/scripts/helmet';
import { dot } from '../../common/scripts/dot';
import locales from '../../utils/locales';

const timer = 30;

export class NewsFlash extends React.Component {
  static propTypes = {
    newsflashList: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    advertMiddle: PropTypes.array,
    postList: PropTypes.array.isRequired,
    match: PropTypes.object
  };

  static fetchData(store, match) {
    const { lang } = match.params;
    return new Promise((resolve, reject) => {
      getNewsflash({}, lang)
        .then(data => {
          const list = data.data.items;
          list.forEach(item => {
            const date = getDate(item.published_at);
            const list = item;
            store.dispatch({
              type: 'SET_NEWS_FLASH',
              date,
              list
            });
          });
          return getAdvert('home_advertisement_middle', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_MIDDLE',
            list: list
          });
          return getNewPost(undefined, lang);
        })
        .then(data => {
          if (data) {
            const list = data.data.data;
            store.dispatch({
              type: 'SET_NEWSFLASH_POSTLIST',
              list: list
            });
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      newsflashFlag: false,
      newsflashTime: timer,
      isImportChecked: false
    };
  }

  componentDidMount() {
    dot({ id: 33 });
    wechatShare({});
  }

  changeNewsflashFlag() {
    const { newsflashFlag } = this.state;
    if (!newsflashFlag) {
      setTimeout(() => {
        this.changeNewsflashTime();
      }, 1000);
      dot({ id: 32 });
    }
    this.setState({
      newsflashFlag: !newsflashFlag
    });
  }

  changeIsImportChecked() {
    const checked = !this.state.isImportChecked;
    this.setState({
      isImportChecked: checked
    });
    this.refreshNewsflashData(checked);
  }

  changeNewsflashTime() {
    const { newsflashFlag, newsflashTime } = this.state;

    if (!newsflashFlag) {
      return;
    }

    setTimeout(() => {
      this.changeNewsflashTime();
    }, 1000);

    if (newsflashTime === 0) {
      this.getNewsflashData();
      this.setState({ newsflashTime: timer });
      return;
    }

    this.setState({
      newsflashTime: newsflashTime - 1
    });
  }

  getNewsflashData() {
    const { newsflashList, dispatch } = this.props;
    getNewsflash({
      per_page: newsflashList.length,
      is_import: this.state.isImportChecked
    }).then(data => {
      const list = data.data.items;
      list.forEach(item => {
        const date = getDate(item.published_at);
        const list = item;

        dispatch({
          type: 'SET_NEWS_FLASH',
          date,
          list,
          flag: 'front'
        });
      });
    });
  }

  refreshNewsflashData(checked) {
    const { dispatch } = this.props;
    getNewsflash({ bId: '', perPage: 10, is_import: checked }).then(data => {
      const list = data.data.items;
      dispatch({
        type: 'SET_NEWSFLASH_EMPTY'
      });
      list.forEach(item => {
        const date = getDate(item.published_at);
        const list = item;
        dispatch({
          type: 'SET_NEWS_FLASH',
          date,
          list
        });
      });
    });
  }

  renderDateTitle(date, index) {
    let month = date.slice(5, 10);
    let year = date.slice(0, 4);
    let week = date.slice(11);
    const { newsflashFlag, newsflashTime } = this.state;
    const { lang } = this.props.match.params;
    return (
      <div className={styles['date']}>
        <div className={styles.date_month}>{month}</div>
        <div className={styles.date_box}>
          <div className={styles.date_week}>{week}</div>
          <div className={styles.date_year}>{year}</div>
        </div>
        {index === 0 ? (
          <div className={styles.right_buttons}>
            <div className={styles.import_box}>
              <input
                type="checkbox"
                id="is_import"
                checked={this.state.isImportChecked}
                onChange={() => {
                  this.changeIsImportChecked();
                }}
              />
              <label htmlFor="is_import">只看重要快讯</label>
            </div>
            <div
              className={styles.newsflash_button}
              onClick={() => {
                this.changeNewsflashFlag();
              }}
            >
              {!newsflashFlag ? (
                <div className={styles.newsflash_button_text}>
                  {locales(lang, 'page_Rfr')}
                </div>
              ) : (
                ''
              )}

              <div className={styles.newsflash_button_icon_box}>
                <div
                  className={
                    newsflashFlag
                      ? styles.newsflash_button_icon_active
                      : styles.newsflash_button_icon
                  }
                />
                <div className={styles.newsflash_button_icon_box_text}>
                  {newsflashFlag ? `${newsflashTime}s` : ''}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  renderList() {
    const { newsflashList } = this.props;
    return Object.keys(newsflashList).map((date, index) => {
      const listRender = newsflashList[date].map(item => {
        return (
          <NewsFlashItem key={item.id} {...item} newsflash={'newsflash'} />
        );
      });
      return (
        <div
          className={styles.newsflash_list_item}
          key={`newsflash_list_item${index}`}
        >
          {this.renderDateTitle(date, index)}
          <div className={styles.newsflash_list_item_list}>{listRender}</div>
        </div>
      );
    });
  }

  handleLoadMore() {
    const { newsflashList, dispatch } = this.props;
    const lastDate = _.findLastKey(newsflashList);
    const lastItem = _.last(newsflashList[lastDate]);
    const bId = _.get(lastItem, 'id');
    this.setState({
      loading: true
    });
    getNewsflash({ bId, is_import: this.state.isImportChecked })
      .then(data => {
        const list = data.data.items;
        if (list && list.length === 0) {
          this.setState({
            loaded: true
          });
        }
        list.forEach(item => {
          const date = getDate(item.published_at);
          const list = item;
          dispatch({
            type: 'SET_NEWS_FLASH',
            date,
            list
          });
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  render() {
    const { lang } = this.props.match.params;
    let helmetData = getHelmet(
      {
        title: `${locales(lang, 'newsflashTitle')}${locales(lang, 'flash')}`,
        titleFlag: true,
        twitterImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
        ogImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
      },
      lang
    );
    const { loading, loaded } = this.state;
    const { postList, advertMiddle } = this.props;

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.newsflash}>
            <div className={styles.newsflash_left}>
              <div className={styles.newsflash_list}>{this.renderList()}</div>
              <div className={styles.LoadMore}>
                <LoadMore
                  loading={loading}
                  loaded={loaded}
                  onClick={this.handleLoadMore.bind(this)}
                />
              </div>
            </div>
            <div className={styles.newsflash_right}>
              <Sticky>
                <div className={styles.RePost}>
                  <NewPost postList={postList} />

                  {/* 首页边栏-底部 */}
                  {advertMiddle &&
                  advertMiddle.length > 0 &&
                  advertMiddle[0].url &&
                  advertMiddle[0].cover ? (
                    <div className={styles.advertMiddle}>
                      <AdPicture
                        width="340px"
                        height="226px"
                        url={advertMiddle[0].url}
                        img={advertMiddle[0].cover}
                        icon={true}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Sticky>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    advertMiddle: state.home.advertMiddle,
    newsflashList: state.newsflash,
    postList: state.post.postList
  };
};

export default withRouter(connect(mapStateToProps)(NewsFlash));
