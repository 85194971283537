import { combineReducers } from 'redux';

const info = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER_INFO':
      return {
        ...state,
        avatar: action.avatar,
        name: action.name,
        introduction: action.introduction,
        title: action.title,
        total: action.total
      };
    default:
      return state;
  }
};

const posts = (state = [], action) => {
  switch (action.type) {
    case 'SET_USER_POSTS':
      return action.list;
    default:
      return state;
  }
};

const current = (state = { nickname: '', introduction: '' }, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        nickname: action.user.nickname,
        introduction: action.user.introduction,
        ...action.user
      };
    default:
      return state;
  }
};

const basicData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CURRENT_BASIC':
      return action.basic;
    default:
      return state;
  }
};
const changepwd = (state = '', action) => {
  switch (action.type) {
    case 'CHANGE_PSW':
      return action.data;
    default:
      return state;
  }
};
const status = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CURRENT_WXSTATUS':
      return action.status;
    default:
      return state;
  }
};

export default combineReducers({
  info,
  posts,
  current,
  basicData,
  changepwd,
  status
});
