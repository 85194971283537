import React from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/common/layout';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import styles from './index.scss';
import {
  getSearchResult,
  getSearchResultNew,
  searchProject,
  searchInstitution
} from '../../common/api/search';

import { HotPostItem } from '../../components/recommendPost/item';
import { RePost } from '../../components/recommendPost';
import AdPicture from '../../components/adPicture';
import Sticky from '../../components/common/sticky';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { PostItem } from '../../components/search/item';
import NewsFlashItem from '../../components/topic/newsFlashItem';
import { TopicItem } from '../../components/searchTopic/searchItem';
// import { VideoItem } from '../../components/searchVideo/item';
import HomeLoadMore from '../../components/common/homeloadmore';
import { getHelmet } from '../../common/scripts/helmet';
import { getTopPost } from '../../common/api/found';
import { getAdvert } from '../../common/api/slide';
import wechatShare from '../../common/scripts/wechat';
import locales from '../../utils/locales';

const img = 'https://piccdn.0daily.com/202209/23084500/vhd7ft23g464przy.png';

export class Search extends React.Component {
  static propTypes = {
    postResults: PropTypes.array.isRequired,
    newsflashResults: PropTypes.array.isRequired,
    videoResults: PropTypes.array.isRequired,
    totalResults: PropTypes.object.isRequired,
    userResults: PropTypes.array.isRequired,
    monographicResults: PropTypes.array.isRequired,
    keywords: PropTypes.string.isRequired,
    pageData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    topPost: PropTypes.array.isRequired,
    projectList: PropTypes.array.isRequired,
    projectPage: PropTypes.object.isRequired,
    institutionList: PropTypes.array.isRequired,
    institutionPage: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    advertBottom: PropTypes.array,
    match: PropTypes.object
  };

  static fetchData(store, match) {
    let { keywords, lang } = match.params;
    store.dispatch({
      type: 'SET_SEARCH_KEYWORDS',
      keywords
    });
    return new Promise(resolve => {
      getSearchResultNew(keywords, undefined, undefined, lang)
        .then(data => {
          const list = data.data;
          if (list) {
            store.dispatch({
              type: 'SET_SEARCH_TOTAL',
              list
            });
          }
          return getSearchResult(keywords, 'post', 1, 20, lang);
        })
        .then(data => {
          const list = _.get(data.data, 'items');
          if (list) {
            store.dispatch({
              type: 'SET_SEARCH_POST',
              list
            });
          }
          return getSearchResult(keywords, 'newsflash', 1, 20, lang);
        })
        .then(data => {
          const list = _.get(data.data, 'items');
          if (list) {
            store.dispatch({
              type: 'SET_SEARCH_NEWSFLASH',
              list
            });
          }
          //   return getSearchResult(keywords, 'video', 1, 20, lang);
          // })
          // .then(data => {
          //   const list = _.get(data.data, 'items');
          //   if (list) {
          //     store.dispatch({
          //       type: 'SET_SEARCH_VIDEO',
          //       list
          //     });
          //   }
          return getSearchResult(keywords, 'monographic', 1, 20, lang);
        })
        .then(data => {
          const list = _.get(data.data, 'items');
          if (list) {
            store.dispatch({
              type: 'SET_SEARCH_MONOGRAPHIC',
              list
            });
          }
          return getSearchResult(keywords, 'user', 1, 20, lang);
        })
        .then(data => {
          const list = _.get(data.data, 'items');
          if (list) {
            store.dispatch({
              type: 'SET_SEARCH_USER',
              list
            });
          }
          return getTopPost(undefined, lang);
        })
        .then(data => {
          const postList = data.data.items;
          store.dispatch({
            type: 'SET_TOPPOST',
            list: postList
          });
          return getAdvert('home_advertisement_bottom', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_BOTTOM',
            list: list
          });
          return searchProject(
            { value: keywords, page: 1, per_page: 30 },
            lang
          );
        })
        .then(data => {
          if (data.data && data.data.items) {
            const list = data.data.items;
            let page = {
              page: data.data.page,
              per_page: data.data.page_size,
              total_count: data.data.total_count
            };
            store.dispatch({
              type: 'SET_SEARCH_PROJECT_LIST',
              data: list
            });
            store.dispatch({
              type: 'SET_SEARCH_PROJECT_PAGE',
              page
            });
          }

          return searchInstitution(
            { value: keywords, page: 1, per_page: 30 },
            lang
          );
        })
        .then(data => {
          if (data && data.data) {
            const list = data.data.items;
            let page = {
              page: data.data.page,
              per_page: data.data.page_size,
              total_count: data.data.total_count
            };
            store.dispatch({
              type: 'SET_SEARCH_INSTITUTION_LIST',
              data: list
            });
            store.dispatch({
              type: 'SET_SEARCH_INSTITUTION_PAGE',
              page
            });
          }

          resolve();
        })
        .catch(() => {
          return resolve();
        });
    });
  }

  constructor(props) {
    super(props);
    this.searchType = [
      {
        type: 'total',
        name: 'all'
      },
      {
        type: 'odata',
        name: 'Odata',
        dispatch: 'SET_SEARCH_ODATA',
        icon: true
      },
      {
        type: 'post',
        name: 'article',
        dispatch: 'SET_SEARCH_POST'
      },
      {
        type: 'newsflash',
        name: 'flash',
        dispatch: 'SET_SEARCH_NEWSFLASH'
      },
      // {
      //   type: 'video',
      //   name: '视频',
      //   dispatch: 'SET_SEARCH_VIDEO'
      // },
      {
        type: 'monographic',
        name: 'special',
        dispatch: 'SET_SEARCH_MONOGRAPHIC'
      },
      {
        type: 'user',
        name: 'author',
        dispatch: 'SET_SEARCH_USER'
      }
    ];

    this.state = {
      postHotList: [],
      currentType: 'total',
      loaded: false,
      loading: false,
      page: 1
    };

    this.searchInput = React.createRef();
  }

  componentDidMount() {
    let { hash } = this.props.location;
    wechatShare({});
    if (hash) {
      let type = hash.slice(1);
      let flag = this.searchType.find(item => {
        return item.type === type;
      });
      if (flag) {
        this.changeTypeHandler(type);
      }
    }
  }

  renderNoData() {
    const { lang } = this.props.match.params;
    return (
      <div className={styles.nodata}>
        <div className={styles.nodata_img} />
        <div className={styles.nodata_text}>
          {locales(lang, 'noResult', {
            content: <span>{decodeURIComponent(this.props.keywords)}</span>
          })}
          {/* 很抱歉，没有找到 “
          <span>{decodeURIComponent(this.props.keywords)}</span>
          ” 相关结果
          <br />
          换个词搜索吧～ */}
        </div>
      </div>
    );
  }

  keywordsHandler() {
    const { lang } = this.props.match.params;
    let key = this.searchInput.current.value;
    let encode_key = encodeURIComponent(key);
    window.location = lang
      ? `/${lang}/search/${encode_key}`
      : `/search/${encode_key}`;
  }

  keywordsClean() {
    this.searchInput.current.value = '';
  }

  loadMore(listData, total_count, page) {
    const { currentType } = this.state;
    const { keywords, dispatch, totalResults } = this.props;

    let find = this.searchType.find(item => {
      return item.type === currentType;
    });

    page++;
    this.setState({
      loading: true
    });
    getSearchResult(keywords, currentType, page)
      .then(data => {
        if (data.data.items.length < 1) {
          this.setState({
            loaded: true,
            loading: false
          });
          return false;
        }
        if (find) {
          const list = _.concat(listData, data.data.items);
          dispatch({
            type: find.dispatch,
            list
          });
          let obj = {
            items: list,
            page: data.data.page,
            total_count: data.data.total_count
          };
          let newTotalResults = { ...totalResults };
          newTotalResults[currentType] = obj;
          dispatch({
            type: 'SET_SEARCH_TOTAL',
            list: newTotalResults
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  changeTypeHandler(type) {
    this.setState({
      currentType: type,
      loaded: false,
      loading: false
    });
  }

  getHotPosts(value) {
    this.setState(
      {
        postType: value.value
      },
      () => {
        this.getTopPost();
      }
    );
  }

  getTopPost() {
    const { postType } = this.state;
    getTopPost(postType)
      .then(data => {
        this.props.dispatch({
          type: 'SET_TOPPOST',
          list: data.data.items
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  getOdataProject() {
    const { projectList, projectPage, keywords, dispatch } = this.props;
    this.setState({
      loading: true
    });
    searchProject({
      value: keywords,
      page: projectPage.page + 1,
      per_page: 30
    }).then(data => {
      this.setState({
        loading: false
      });
      const list = data.data.items;

      let page = {
        page: data.data.page,
        per_page: data.data.page_size,
        total_count: data.data.total_count
      };
      dispatch({
        type: 'SET_SEARCH_PROJECT_LIST',
        data: projectList.concat(list)
      });
      dispatch({
        type: 'SET_SEARCH_PROJECT_PAGE',
        page
      });
    });
  }

  getOdataInstitution() {
    const { institutionList, institutionPage, keywords, dispatch } = this.props;
    this.setState({
      loading: true
    });
    searchInstitution({
      value: keywords,
      page: institutionPage.page + 1,
      per_page: 30
    }).then(data => {
      this.setState({
        loading: false
      });
      const list = data.data.items;

      let page = {
        page: data.data.page,
        per_page: data.data.page_size,
        total_count: data.data.total_count
      };
      dispatch({
        type: 'SET_SEARCH_INSTITUTION_LIST',
        data: institutionList.concat(list)
      });
      dispatch({
        type: 'SET_SEARCH_INSTITUTION_PAGE',
        page
      });
    });
  }

  renderSearchTab() {
    const { lang } = this.props.match.params;
    return this.searchType.map((item, index) => {
      const activeStyles =
        item.type === this.state.currentType ? styles['item-active'] : '';
      return (
        <div
          key={index}
          className={`${styles.item}  ${activeStyles} ${
            item.icon ? styles['item-icon'] : ''
          }`}
          onClick={() => {
            this.changeTypeHandler(item.type);
          }}
        >
          {locales(lang, item.name)}
        </div>
      );
    });
  }

  renderPost(type) {
    const { postResults, totalResults, match } = this.props;
    const { total_count, page } = totalResults[type];
    const { currentType, loading, loaded } = this.state;
    const { lang } = match.params;
    if (postResults && total_count === 0 && currentType === type) {
      return (
        <div>
          <div className={styles.searchNew_box_header}>
            {locales(lang, 'article')}
            <div className={styles.searchNew_box_total}>
              {locales(lang, 'results', { content: total_count })}
            </div>
          </div>
          {this.renderNoData()}
        </div>
      );
    }

    if (postResults && postResults.length === 0) {
      return '';
    }

    let list = currentType === 'total' ? postResults.slice(0, 5) : postResults;

    return (
      <div className={styles.searchNew_box}>
        <div className={styles.searchNew_box_header}>
          {locales(lang, 'article')}
          <div className={styles.searchNew_box_total}>
            {locales(lang, 'results', { content: total_count })}
          </div>
        </div>

        <div className={styles.searchNew_box_post}>
          <div className={styles.searchNew_box_post_border} />
          {list.map((item, index) => {
            let { highlight, title, summary } = item;
            const highlightTitle = highlight.title
              ? highlight.title.join('')
              : title;
            const highlightSummary = highlight.content
              ? highlight.content_light.join('')
              : summary;
            const props = Object.assign(item, {
              type: 'normal',
              highlightTitle,
              summary: highlightSummary
            });
            return <PostItem {...props} key={`PostItem${index}`} />;
          })}

          {total_count > 5 ? (
            <div className={styles.homeLoadMore}>
              <HomeLoadMore
                loading={loading}
                loaded={loaded}
                onClick={() => {
                  currentType === type
                    ? this.loadMore(postResults, total_count, page)
                    : this.changeTypeHandler(type);
                }}
              />
            </div>
          ) : (
            ''
          )}

          {loaded ? (
            <div className={styles.tip}>{locales(lang, 'nomore')}~</div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  renderNewsflash(type) {
    const { newsflashResults, totalResults, match } = this.props;
    const { total_count, page } = totalResults[type];
    const { currentType, loading, loaded } = this.state;
    const { lang } = match.params;
    if (newsflashResults && total_count === 0 && currentType === type) {
      return (
        <div>
          <div className={styles.searchNew_box_header}>
            {locales(lang, 'flash')}
            <div className={styles.searchNew_box_total}>
              {locales(lang, 'results', { content: total_count })}
            </div>
          </div>
          {this.renderNoData()}
        </div>
      );
    }

    if (newsflashResults && newsflashResults.length === 0) {
      return '';
    }

    let list =
      currentType === 'total' ? newsflashResults.slice(0, 5) : newsflashResults;

    return (
      <div className={styles.searchNew_box}>
        <div className={styles.searchNew_box_header}>
          {locales(lang, 'flash')}
          <div className={styles.searchNew_box_total}>
            {locales(lang, 'results', { content: total_count })}
          </div>
        </div>

        <div className={styles.searchNew_box_newsflash}>
          {list.map((item, index) => {
            let { highlight, title, description } = item;
            const highlightTitle = highlight.title
              ? highlight.title.join('')
              : title;
            const highlightSummary = highlight.description
              ? highlight.description.join('')
              : description;
            const props = Object.assign(item, {
              title: highlightTitle,
              description: highlightSummary
            });
            return <NewsFlashItem {...props} key={`NewsFlashItem${index}`} />;
          })}
          {total_count > 5 ? (
            <div className={styles.homeLoadMore}>
              <HomeLoadMore
                loading={loading}
                loaded={loaded}
                onClick={() => {
                  currentType === type
                    ? this.loadMore(newsflashResults, total_count, page)
                    : this.changeTypeHandler(type);
                }}
              />
            </div>
          ) : (
            ''
          )}
          {loaded ? (
            <div className={styles.tip}>{locales(lang, 'nomore')}~</div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  // renderVideo(type) {
  //   const { videoResults, totalResults } = this.props;
  //   const { total_count, page } = totalResults[type];
  //   const { currentType, loading, loaded } = this.state;

  //   if (videoResults && total_count === 0 && currentType === type) {
  //     return (
  //       <div>
  //         <div className={styles.searchNew_box_header}>
  //           视频
  //           <div className={styles.searchNew_box_total}>
  //             共{total_count}个结果
  //           </div>
  //         </div>
  //         {this.renderNoData()}
  //       </div>
  //     );
  //   }

  //   if (videoResults && videoResults.length === 0) {
  //     return '';
  //   }

  //   let list =
  //     currentType === 'total' ? videoResults.slice(0, 5) : videoResults;

  //   return (
  //     <div className={styles.searchNew_box}>
  //       <div className={styles.searchNew_box_header}>
  //         视频
  //         <div className={styles.searchNew_box_total}>
  //           共{total_count}个结果
  //         </div>
  //       </div>

  //       <div className={styles.searchNew_box_post}>
  //         <div className={styles.searchNew_box_user_border}></div>
  //         {list.map((item, index) => {
  //           let { highlight, title, summary } = item;
  //           const highlightTitle = highlight.title
  //             ? highlight.title.join('')
  //             : title;
  //           const highlightSummary = highlight.content
  //             ? highlight.content.join('')
  //             : summary;
  //           const props = Object.assign(item, {
  //             type: 'normal',
  //             title: highlightTitle,
  //             summary: highlightSummary
  //           });
  //           return <VideoItem {...props} key={`VideoItem${index}`} />;
  //         })}

  //         {total_count > 5 ? (
  //           <div className={styles.homeLoadMore}>
  //             <HomeLoadMore
  //               loading={loading}
  //               loaded={loaded}
  //               onClick={() => {
  //                 currentType === type
  //                   ? this.loadMore(videoResults, total_count, page)
  //                   : this.changeTypeHandler(type);
  //               }}
  //             />
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //       </div>
  //     </div>
  //   );
  // }

  renderMonographic(type) {
    const { monographicResults, totalResults, match } = this.props;
    const { total_count, page } = totalResults[type];
    const { currentType, loading, loaded } = this.state;
    const { lang } = match.params;
    if (monographicResults && total_count === 0 && currentType === type) {
      return (
        <div>
          <div className={styles.searchNew_box_header}>
            {locales(lang, 'special')}
            <div className={styles.searchNew_box_total}>
              {locales(lang, 'results', { content: total_count })}
            </div>
          </div>
          {this.renderNoData()}
        </div>
      );
    }

    if (monographicResults && monographicResults.length === 0) {
      return '';
    }

    let list =
      currentType === 'total'
        ? monographicResults.slice(0, 5)
        : monographicResults;

    return (
      <div className={styles.searchNew_box}>
        <div className={styles.searchNew_box_header}>
          {locales(lang, 'special')}
          <div className={styles.searchNew_box_total}>
            {locales(lang, 'results', { content: total_count })}
          </div>
        </div>

        <div className={styles.searchNew_box_post}>
          <div className={styles.searchNew_box_user_border} />
          {list.map((item, index) => {
            let { highlight, title } = item;
            const highlightTitle = highlight.title
              ? highlight.title.join('')
              : title;
            const props = Object.assign(item, {
              type: 'normal',
              title: highlightTitle
            });
            return <TopicItem {...props} key={`TopicItem${index}`} />;
          })}

          {total_count > 5 ? (
            <div className={styles.homeLoadMore}>
              <HomeLoadMore
                loading={loading}
                loaded={loaded}
                onClick={() => {
                  currentType === type
                    ? this.loadMore(monographicResults, total_count, page)
                    : this.changeTypeHandler(type);
                }}
              />
            </div>
          ) : (
            ''
          )}

          {loaded ? (
            <div className={styles.tip}>{locales(lang, 'nomore')}~</div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  renderUser(type) {
    const { userResults, totalResults, match } = this.props;
    const { total_count, page } = totalResults[type];
    const { currentType, loading, loaded } = this.state;
    const { lang } = match.params;
    if (userResults && total_count === 0 && currentType === type) {
      return (
        <div>
          <div className={styles.searchNew_box_header}>
            {locales(lang, 'author')}
            <div className={styles.searchNew_box_total}>
              {locales(lang, 'results', { content: total_count })}
            </div>
          </div>
          {this.renderNoData()}
        </div>
      );
    }

    if (userResults && userResults.length === 0) {
      return '';
    }

    let list = currentType === 'total' ? userResults.slice(0, 5) : userResults;

    return (
      <div className={styles.searchNew_box}>
        <div className={styles.searchNew_box_header}>
          {locales(lang, 'author')}
          <div className={styles.searchNew_box_total}>
            {locales(lang, 'results', { content: total_count })}
          </div>
        </div>

        <div className={styles.searchNew_box_post}>
          <div className={styles.searchNew_box_user_border} />
          {list.map((item, index) => {
            let { highlight, nickname } = item;
            const highlightTitle = highlight.nickname
              ? highlight.nickname.join('')
              : nickname;
            return (
              <div
                className={styles.searchNew_box_user_item}
                key={`searchNew_box_user_item${index}`}
              >
                <a
                  href={`/user/${item.id}`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  className={styles.searchNew_box_user_item_wrapper}
                >
                  <div
                    className={styles['searchNew_box_user_item_cover']}
                    style={{ backgroundImage: `url(${item.avatar_url})` }}
                  />

                  <div className={styles.searchNew_box_user_item_content}>
                    <div className={styles.searchNew_box_user_item_header}>
                      <div
                        className={styles['searchNew_box_user_item_title']}
                        dangerouslySetInnerHTML={{
                          __html: highlightTitle
                        }}
                      />
                      <div className={styles.searchNew_box_user_item_icon}>
                        {item.title}
                      </div>
                    </div>
                    <div
                      className={styles.searchNew_box_user_item_introduction}
                    >
                      {item.introduction}
                    </div>
                    <div className={styles.searchNew_box_user_item_post_sum}>
                      共{item.post_sum}篇文章
                    </div>
                  </div>
                </a>
              </div>
            );
          })}

          {total_count > 5 ? (
            <div className={styles.homeLoadMore}>
              <HomeLoadMore
                loading={loading}
                loaded={loaded}
                onClick={() => {
                  currentType === type
                    ? this.loadMore(userResults, total_count, page)
                    : this.changeTypeHandler(type);
                }}
              />
            </div>
          ) : (
            ''
          )}

          {loaded ? (
            <div className={styles.tip}>{locales(lang, 'nomore')}~</div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  renderPostHot() {
    const postList = this.props.topPost;

    if (postList.length > 0) {
      return postList
        .filter((item, index) => {
          return index < 5;
        })
        .map((item, index) => {
          const props = Object.assign(item, {
            type: 'small',
            sort: index + 1
          });
          return <HotPostItem {...props} key={index} />;
        });
    }
  }

  renderProject(data) {
    let item_id = data.items && data.items.length ? data.items[0]['id'] : '';
    return (
      <a
        className={styles.odata_item}
        href={`${process.env.ODATA}/project/${data.id}?item_id=${item_id}`}
        target={'_blank'}
        rel="noopener noreferrer"
        style={{ alignItems: data.summary ? 'flex-start' : null }}
      >
        <div className={styles.odata_item_project_icon} />
        <div
          className={styles.odata_item_project}
          style={{ backgroundImage: `url(${data.cover ? data.cover : img})` }}
        />
        <div className={styles.odata_item_content}>
          <div className={styles.odata_item_title}>{data.en_name}</div>
          <div
            className={styles.odata_item_summary}
            style={{ WebkitBoxOrient: 'vertical' }}
          >
            {data.summary}
          </div>
        </div>
      </a>
    );
  }

  renderInstitution(data) {
    return (
      <a
        className={styles.odata_item}
        href={`${process.env.ODATA}/institution/${data.id}`}
        target={'_blank'}
        rel="noopener noreferrer"
        style={{ alignItems: data.summary ? 'flex-start' : null }}
      >
        <div className={styles.odata_item_institution_icon} />
        <div
          className={styles.odata_item_institution}
          style={{ backgroundImage: `url(${data.cover ? data.cover : img})` }}
        />
        <div className={styles.odata_item_content}>
          <div className={styles.odata_item_title}>{data.en_name}</div>
          <div
            className={styles.odata_item_summary}
            style={{ WebkitBoxOrient: 'vertical' }}
          >
            {data.summary}
          </div>
        </div>
      </a>
    );
  }

  renderOdataBox() {
    const {
      // projectPage,
      // institutionPage,
      projectList,
      institutionList,
      match
    } = this.props;
    // const { loading, loaded } = this.state;
    const { lang } = match.params;
    return (
      <div className={styles.searchNew_box}>
        <div className={styles.searchNew_box_header}>
          Odata
          {/* <div className={styles.searchNew_box_total}>
            共{projectPage.total_count + institutionPage.total_count}个结果
          </div> */}
          <div
            onClick={() => {
              this.changeTypeHandler('odata');
            }}
            className={styles.searchNew_box_more}
          >
            {locales(lang, 'seeMore')}
          </div>
        </div>

        <div className={styles.searchNew_box_odata}>
          {projectList.slice(0, 2).map(item => {
            return this.renderProject(item);
          })}
          {institutionList.slice(0, 1).map(item => {
            return this.renderInstitution(item);
          })}

          {/* {projectPage.total_count > 6 || institutionPage.total_count > 6 ? (
            <div className={styles.odataLoadMore}>
              <HomeLoadMore
                loading={loading}
                loaded={loaded}
                onClick={() => {
                  this.changeTypeHandler('odata');
                }}
              />
            </div>
          ) : (
            ''
          )} */}
        </div>
      </div>
    );
  }

  renderOdata() {
    const {
      projectPage,
      institutionPage,
      projectList,
      institutionList,
      match
    } = this.props;
    const { loading, loaded } = this.state;
    const { lang } = match.params;
    if (!(projectPage.total_count || institutionPage.total_count)) {
      return (
        <div>
          <div className={styles.searchNew_box_header}>
            Odata
            <div className={styles.searchNew_box_total}>
              {locales(lang, 'results', { content: 0 })}
            </div>
          </div>
          {this.renderNoData()}
        </div>
      );
    }

    return (
      <div>
        {projectPage.total_count ? (
          <div className={styles.searchNew_box}>
            <div className={styles.searchNew_box_header}>
              项目
              <div className={styles.searchNew_box_total}>
                {locales(lang, 'results', { content: projectPage.total_count })}
              </div>
            </div>

            <div className={styles.searchNew_box_odata}>
              {projectList.map(item => {
                return this.renderProject(item);
              })}

              {projectPage.total_count > projectList.length ? (
                <div className={styles.odataLoadMore}>
                  <HomeLoadMore
                    loading={loading}
                    loaded={loaded}
                    onClick={() => {
                      this.getOdataProject();
                    }}
                  />
                </div>
              ) : (
                ''
              )}

              {loaded ? (
                <div className={styles.tip}>{locales(lang, 'nomore')}~</div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {institutionPage.total_count ? (
          <div className={styles.searchNew_box}>
            <div className={styles.searchNew_box_header}>
              机构
              <div className={styles.searchNew_box_total}>
                {locales(lang, 'results', {
                  content: institutionPage.total_count
                })}
              </div>
            </div>

            <div className={styles.searchNew_box_odata}>
              {institutionList.map(item => {
                return this.renderInstitution(item);
              })}

              {institutionPage.total_count > institutionList.length ? (
                <div className={styles.odataLoadMore}>
                  <HomeLoadMore
                    loading={loading}
                    loaded={loaded}
                    onClick={() => {
                      this.getOdataInstitution();
                    }}
                  />
                </div>
              ) : (
                ''
              )}

              {loaded ? (
                <div className={styles.tip}>{locales(lang, 'nomore')}~</div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    const {
      keywords,
      monographicResults,
      newsflashResults,
      postResults,
      userResults,
      // videoResults,
      advertBottom,
      projectList,
      institutionList,
      match
    } = this.props;
    const { currentType } = this.state;

    let helmetData = getHelmet({
      title: `ODAILY搜索 ${keywords}结果 `,
      description: `ODAILY搜索 ${keywords}内容详情`,
      ogTitle: `ODAILY搜索 ${keywords}结果 `,
      ogDescription: `ODAILY搜索 ${keywords}内容详情`,
      twitterTitle: `ODAILY搜索 ${keywords}结果 `,
      twitterDescription: `ODAILY搜索 ${keywords}内容详情`,
      titleFlag: true,
      ogUrl: `${process.env.ODAILY}${match.url}`,
      ogImage:
        'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.searchNew_wrapper}>
            <div className={styles.searchNew_left}>
              <div className={styles.searchNew_header}>
                <div className={styles.searchNew_inp_box}>
                  <input
                    defaultValue={decodeURIComponent(keywords)}
                    className={styles.searchNew_inp}
                    type="text"
                    ref={this.searchInput}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        this.keywordsHandler();
                      }
                    }}
                  />
                  <div
                    className={styles.searchNew_clean}
                    onClick={() => {
                      this.keywordsClean();
                    }}
                  />
                  <div
                    className={styles.searchNew_button}
                    onClick={() => {
                      this.keywordsHandler();
                    }}
                  />
                </div>
                <div className={styles.searchNew_nav_box}>
                  <div className={styles.searchNew_nav}>
                    {this.renderSearchTab()}
                  </div>
                </div>
              </div>

              <div className={styles.searchNew_main}>
                {currentType === 'total' &&
                (projectList.length || institutionList.length)
                  ? this.renderOdataBox()
                  : ''}
                {currentType === 'total' || currentType === 'post'
                  ? this.renderPost('post')
                  : ''}
                {currentType === 'total' || currentType === 'newsflash'
                  ? this.renderNewsflash('newsflash')
                  : ''}
                {/* {currentType === 'total' || currentType === 'video'
                  ? this.renderVideo('video')
                  : ''} */}
                {currentType === 'total' || currentType === 'monographic'
                  ? this.renderMonographic('monographic')
                  : ''}
                {currentType === 'total' || currentType === 'user'
                  ? this.renderUser('user')
                  : ''}
                {currentType === 'odata' ? this.renderOdata() : ''}
                {monographicResults &&
                monographicResults.length === 0 &&
                newsflashResults &&
                newsflashResults.length === 0 &&
                postResults &&
                postResults.length === 0 &&
                userResults &&
                userResults.length === 0 &&
                // videoResults &&
                // videoResults.length === 0 &&
                projectList.length === 0 &&
                institutionList.length === 0 &&
                currentType === 'total'
                  ? this.renderNoData()
                  : ''}
              </div>
            </div>
            <div className={styles.searchNew_right}>
              <Sticky>
                <div className={styles.RePost}>
                  <RePost
                    getHotPosts={this.getHotPosts.bind(this)}
                    title="文章热榜"
                  >
                    {this.renderPostHot()}
                  </RePost>
                </div>
              </Sticky>

              {advertBottom &&
              advertBottom.length > 0 &&
              advertBottom[0].url &&
              advertBottom[0].cover ? (
                <div className={styles.advertMiddle}>
                  <AdPicture
                    width="340px"
                    height="226px"
                    url={advertBottom[0].url}
                    img={advertBottom[0].cover}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    keywords: state.search.keywords,
    postResults: state.search.post,
    videoResults: state.search.video,
    totalResults: state.search.total,
    userResults: state.search.user,
    projectList: state.search.projectList,
    projectPage: state.search.projectPage,
    institutionList: state.search.institutionList,
    institutionPage: state.search.institutionPage,
    monographicResults: state.search.monographic,
    newsflashResults: state.search.newsflash,
    topPost: state.home.topPost,
    advertBottom: state.home.advertBottom
  };
};

export default withRouter(connect(mapStateToProps)(Search));
