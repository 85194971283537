import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHelmet } from '../../common/scripts/helmet';
import Layout from '../../components/common/layout';
import Helmet from 'react-helmet';
import styles from './index.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import symbolBtcImg from '../../assets/image/market/symbol/btc.svg';
import symbolEthImg from '../../assets/image/market/symbol/eth.svg';
import symbolHtxImg from '../../assets/image/market/symbol/htx.svg';
import symbolSolImg from '../../assets/image/market/symbol/sol.svg';
import symbolBnbImg from '../../assets/image/market/symbol/bnb.svg';

import starImg from '../../assets/image/market/star.svg';
import starSelectedImg from '../../assets/image/market/star-selected.svg';
import htxLogoImg from '../../assets/image/market/htx-logo.svg';
import arrowUpImg from '../../assets/image/market/arrow-up.svg';
import arrowDownImg from '../../assets/image/market/arrow-down.svg';
import {
  addMarketOptional,
  cancelMarketOptional,
  getCoinListBySymbols,
  getExchangeRate,
  getMarketRankList,
  getMarketRankListByType,
  getOptionalList
} from '../../common/api/newMarket';
import Toast from '../../components/common/toast';
import KlineChart from './components/klineChart';
import locales from '../../utils/locales';
import cookies from 'js-cookie';
import { loginFlag } from '../../common/scripts/login';

const handleOpenHtxHome = () => {
  window.open('https://htx.com', '_blank');
};

const handleOpenHtxTrade = symbol => {
  console.log('symbol :', symbol);
  // symbol = symbol.replace('usdt', '').replace('/', '').toLowerCase();
  // window.open(`https://www.htx.com/trade/${symbol}_usdt/`, '_blank');
  window.open('https://htx.com', '_blank');
};

export class Market extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  static fetchData() {}

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: undefined,
      topLimit: 100,
      usdt_cny_rate: 7,
      currentTabbar: 'market',
      currentRank: 'up',
      cardMap: {},
      collectList: [],
      marketList: [],
      rankList: []
    };
  }

  isLogin() {
    return cookies.get('kr_plus_id');
  }

  updateTimer = null;

  componentDidMount() {
    this.getAllData();
    this.updateTimer = setInterval(() => {
      this.getAllData();
    }, 10 * 1000);
  }

  componentWillUnmount() {
    this.updateTimer && clearInterval(this.updateTimer);
  }

  buildCardItem(symbol, logoImage) {
    const _data = this.state.cardMap[symbol];
    const name = _data ? _data.name : '';
    const usdt = _data ? _data.usdt : '';
    const cny = _data ? _data.cny : '';
    const rate = _data ? _data.rate : '';
    const isUp = _data ? _data.isUp : false;
    return (
      <div
        className={styles.cardItem}
        onClick={() => handleOpenHtxTrade(symbol)}
      >
        <div className={styles.line1}>
          <span className={styles.symbol}>{name}</span>
          <img src={logoImage} alt={''} className={styles.logo} />
        </div>
        <div className={styles.line2}>
          {isUp ? (
            <span className={classNames(styles.usdt, styles.up)}>{usdt}</span>
          ) : (
            <span className={classNames(styles.usdt, styles.down)}>{usdt}</span>
          )}
          <span className={styles.cny}>≈￥{cny}</span>
        </div>
        <div className={styles.line3}>
          {isUp ? (
            <span className={classNames(styles.price, styles.up)}>
              +{rate}%
            </span>
          ) : (
            <span className={classNames(styles.price, styles.down)}>
              -{rate}%
            </span>
          )}
          {isUp ? (
            <img src={arrowUpImg} alt={''} className={styles.arrow} />
          ) : (
            <img src={arrowDownImg} alt={''} className={styles.arrow} />
          )}
        </div>
      </div>
    );
  }

  buildLeftTableLine(
    index,
    collected,
    symbol,
    price,
    rate,
    lowPrice,
    highPrice,
    deal,
    market,
    klineData,
    isUp
  ) {
    return (
      <div className={styles.line}>
        <div
          className={styles.item}
          style={{ width: '62px', marginLeft: '12px' }}
        >
          <span className={styles.text}>{index}</span>
        </div>
        <div
          className={styles.item}
          style={{
            width: '62px',
            marginLeft: '12px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          {collected ? (
            <img
              src={starSelectedImg}
              alt={''}
              className={styles.icon}
              onClick={() => this.handleCancelCollect(index, symbol)}
            />
          ) : (
            <img
              src={starImg}
              alt={''}
              className={styles.icon}
              onClick={() => this.handleCollect(index, symbol)}
            />
          )}
          <span
            className={styles.text}
            onClick={() => handleOpenHtxTrade(symbol)}
          >
            {this.getNameBySymbol(symbol)}
          </span>
        </div>
        <div
          className={styles.item}
          style={{ width: '105px', marginLeft: '10px' }}
        >
          <span
            className={styles.text}
            onClick={() => handleOpenHtxTrade(symbol)}
          >
            {this.roundNumber(price, 8)}
          </span>
        </div>
        <div
          className={styles.item}
          style={{ width: '70px', marginLeft: '12px' }}
        >
          {isUp ? (
            <span
              className={classNames(styles.text, styles.up)}
              onClick={() => handleOpenHtxTrade(symbol)}
            >
              <img
                src={arrowUpImg}
                alt={''}
                className={styles.arrow}
                style={{ marginRight: 4 }}
              />
              {this.roundNumber(rate * 100, 2)}%
            </span>
          ) : (
            <span
              className={classNames(styles.text, styles.down)}
              onClick={() => handleOpenHtxTrade(symbol)}
            >
              <img
                src={arrowDownImg}
                alt={''}
                className={styles.arrow}
                style={{ marginRight: 4 }}
              />
              {this.roundNumber(rate * 100, 2)}%
            </span>
          )}
        </div>
        <div
          className={styles.item}
          style={{ width: '80px', marginLeft: '22px' }}
        >
          <span
            className={styles.text}
            onClick={() => handleOpenHtxTrade(symbol)}
          >
            {this.roundNumber(highPrice, 8)}
          </span>
        </div>
        <div
          className={styles.item}
          style={{ width: '80px', marginLeft: '18px' }}
        >
          <span
            className={styles.text}
            onClick={() => handleOpenHtxTrade(symbol)}
          >
            {this.roundNumber(lowPrice, 8)}
          </span>
        </div>
        <div
          className={styles.item}
          style={{ width: '100px', marginLeft: '12px' }}
        >
          <span
            className={styles.text}
            onClick={() => handleOpenHtxTrade(symbol)}
          >
            ${this.getUsdUnitPrice(deal)}
          </span>
        </div>
        <div
          className={styles.item}
          style={{ width: '154px', marginLeft: '18px' }}
        >
          <span
            className={styles.text}
            onClick={() => handleOpenHtxTrade(symbol)}
          >
            ${this.showCurrency(market)}
          </span>
        </div>
        <div
          className={styles.item}
          style={{ width: '120px', marginLeft: '2px' }}
        >
          <div
            className={styles.image}
            onClick={() => handleOpenHtxTrade(symbol)}
          >
            <KlineChart symbol={symbol} klineDataList={klineData} isUp={isUp} />
          </div>
        </div>
      </div>
    );
  }

  showPriceTag(price) {
    if (isNaN(Number(price))) {
      return <span>{price}</span>;
    }
    const number = Number(price).toFixed(20);
    const strings = String(number).split('.');
    if (strings.length !== 2) {
      return <span>{this.roundNumber(number, 8)}</span>;
    }
    const string = strings[0];
    const string1 = strings[1];
    let zeroSize = 0;
    for (let i = 0; i < string1.length; i++) {
      const element = string1[i];
      if (element !== '0') {
        break;
      }
      zeroSize++;
    }
    if (zeroSize < 5) {
      return <span>{this.roundNumber(number, 8)}</span>;
    }
    let s = string1.substring(zeroSize, zeroSize + 5);
    let lastZeroSize = 0;
    for (let i = s.length - 1; i >= 0; i--) {
      const sElement = s[i];
      if (sElement !== '0') {
        continue;
      }
      lastZeroSize++;
    }
    if (lastZeroSize > 0) {
      s = s.substring(0, s.length - lastZeroSize);
    }
    return (
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end'
        }}
      >
        {string}.0
        <sub
          style={{
            transform: 'scale(0.8)',
            fontSize: '100%',
            display: 'inline-flex',
            lineHeight: '100%',
            marginBottom: '3px'
          }}
        >
          {zeroSize}
        </sub>
        {s}
      </span>
    );
  }

  buildRightTableLine(symbol, price, rate, isUp, marketValue = '') {
    return (
      <div className={styles.line} onClick={() => handleOpenHtxTrade(symbol)}>
        <div className={styles.item}>{symbol}</div>
        <div className={styles.item}>{this.showPriceTag(price)}</div>
        {this.state.currentRank === 'deal' ? (
          <div className={classNames(styles.item, styles.deal)}>
            ${this.getChineseUnitPrice(marketValue)}
          </div>
        ) : isUp ? (
          <div className={classNames(styles.item, styles.up)}>
            +{this.roundNumber(rate, 2)}%
          </div>
        ) : (
          <div className={classNames(styles.item, styles.down)}>
            -{this.roundNumber(rate, 2)}%
          </div>
        )}
      </div>
    );
  }

  handleTopLimitChange(event) {
    const value = event.target.value;
    console.log('handleTopLimitChange , value : ', value);
    this.setState({
      topLimit: value
    });
  }

  handleKeywordsChange(event) {
    const value = event.target.value;
    console.log('handleKeywordsChange , value : ', value);
    this.setState({
      searchKeyword: value
    });
  }

  handleChangeTabbar(tabbar) {
    const { dispatch } = this.props;
    if (this.state.currentTabbar === tabbar) {
      return;
    }
    if (!this.isLogin() && tabbar === 'collect') {
      loginFlag(dispatch, true);
      return;
    }
    this.setState({
      currentTabbar: tabbar
    });
  }

  handleChangeRank(rank) {
    if (this.state.currentRank === rank) {
      return;
    }
    this.setState({
      currentRank: rank
    });
    this.getRankList(rank);
  }

  handleCollect(index, symbol) {
    const { dispatch } = this.props;
    if (!this.isLogin()) {
      loginFlag(dispatch, true);
      return;
    }
    console.log(`handleCollect ${index}, symbol : `, symbol);
    addMarketOptional(symbol).then(() => {
      this.getCollectList();
      this.getMarketList();
      Toast.done({
        msg: '添加成功'
      });
    });
  }

  handleCancelCollect(index, symbol) {
    const { dispatch } = this.props;
    if (!this.isLogin()) {
      loginFlag(dispatch, true);
      return;
    }
    console.log(`handleCancelCollect ${index}, symbol : `, symbol);
    cancelMarketOptional(symbol).then(() => {
      this.getCollectList();
      this.getMarketList();
      Toast.done({
        msg: '取消成功'
      });
    });
  }

  getAllData() {
    this.getExchangeRate(() => {
      this.getCardList();
      if (this.isLogin()) {
        this.getCollectList();
      }
      this.getMarketList();
      this.getRankList(this.state.currentRank);
    });
  }

  getExchangeRate(callback) {
    getExchangeRate().then(res => {
      let rate = res.data;
      this.setState(
        {
          usdt_cny_rate: rate
        },
        () => {
          callback && callback();
        }
      );
    });
  }

  showCnyPrice(usdt) {
    let cny = usdt * this.state.usdt_cny_rate;
    return this.roundNumber(cny, 8);
  }

  getChineseUnitPrice(price) {
    const { match } = this.props;
    const { lang } = match.params;

    if (price < 10000) {
      return this.roundNumber(price, 2);
    } else if (price < 100000000) {
      return this.roundNumber(price / 10000, 2) + locales(lang, 'unit_wan');
    } else if (price < 1000000000000) {
      return this.roundNumber(price / 100000000, 2) + locales(lang, 'unit_yi');
    } else {
      return (
        this.roundNumber(price / 1000000000000, 2) +
        locales(lang, 'unit_wan') +
        locales(lang, 'unit_yi')
      );
    }
  }

  getUsdUnitPrice(price) {
    if (price < 1000) {
      return this.roundNumber(price, 2);
    } else if (price < 1000000) {
      return this.roundNumber(price / 1000, 2) + 'K';
    } else if (price < 1000000000) {
      return this.roundNumber(price / 1000000, 2) + 'M';
    } else {
      return this.roundNumber(price / 1000000000, 2) + 'B';
    }
  }

  getChineseUnitPriceBuyUsdt(usdt) {
    let cny = this.showCnyPrice(usdt);
    return this.getChineseUnitPrice(cny);
  }

  roundNumber(number, decimals) {
    if (!isNaN(Number(number))) {
      number = Number(number).toFixed(decimals + 2);
    }
    const factor = 10 ** decimals;
    return Math.round(number * factor) / factor;
  }

  showCurrency(price) {
    price = this.roundNumber(price, 8);
    price += '';
    let x = price.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/; //3就是间隔的位数
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return (x1 + x2).toLocaleString();
  }

  showRate(rate) {
    if (!isNaN(Number(rate))) {
      rate = Number(rate).toFixed(4);
    }
    return this.roundNumber(rate * 100, 2);
  }

  getCardList() {
    getCoinListBySymbols().then(res => {
      const list = res.data;
      const _cardMap = [];
      for (let i = 0; i < list.length; i++) {
        const _item = list[i];
        _cardMap[_item.symbol] = {
          name: `${this.getNameBySymbol(_item.symbol)}/USDT`,
          symbol: _item.symbol,
          usdt: this.roundNumber(_item.price, 8),
          cny: this.showCnyPrice(_item.price),
          rate: this.showRate(_item.rate_24h),
          isUp: _item.is_up === 1
        };
      }
      this.setState({
        cardMap: _cardMap
      });
    });
  }

  getNameBySymbol(symbol) {
    return symbol.replace('usdt', '').toUpperCase();
  }

  getCollectList() {
    getOptionalList().then(res => {
      let list = res.data;
      const _collectList = [];
      for (let i = 0; i < list.length; i++) {
        const _item = list[i];
        _collectList.push({
          index: i + 1,
          rank: _item.rank,
          collected: _item.collected,
          name: this.getNameBySymbol(_item.symbol),
          symbol: _item.symbol,
          price: _item.price,
          isUp: _item.is_up === 1,
          rate: _item.rate_24h,
          lowPrice: _item.low_24h,
          highPrice: _item.high_24h,
          deal: _item.volume_24h,
          market: _item.market_value,
          klineData: JSON.parse(_item.k_line_7d)
        });
      }
      this.setState({
        collectList: _collectList
      });
    });
  }

  getMarketList() {
    getMarketRankList().then(res => {
      let list = res.data;
      const _marketList = [];
      for (let i = 0; i < list.length; i++) {
        const _item = list[i];
        _marketList.push({
          index: i + 1,
          rank: _item.rank,
          collected: _item.collected,
          name: this.getNameBySymbol(_item.symbol),
          symbol: _item.symbol,
          price: _item.price,
          isUp: _item.is_up === 1,
          rate: _item.rate_24h,
          lowPrice: _item.low_24h,
          highPrice: _item.high_24h,
          deal: _item.volume_24h,
          market: _item.market_value,
          klineData: JSON.parse(_item.k_line_7d)
        });
      }
      this.setState({
        marketList: _marketList
      });
    });
  }

  getRankList(currentRank) {
    let rankType = 1;
    if (currentRank === 'up') {
      rankType = 1;
    } else if (currentRank === 'new') {
      rankType = 4;
    } else if (currentRank === 'deal') {
      rankType = 2;
    }
    getMarketRankListByType(rankType).then(res => {
      const list = res.data;
      const _rankList = [];
      for (let i = 0; i < list.length; i++) {
        const _item = list[i];
        _rankList.push({
          symbol: `${this.getNameBySymbol(_item.symbol)}`,
          price: this.roundNumber(_item.price, 20),
          rate: this.showRate(_item.rate),
          isUp: _item.is_up,
          rank: _item.rank,
          marketValue: _item.market_value
        });
      }
      this.setState({
        rankList: _rankList
      });
    });
  }

  render() {
    const { match } = this.props;
    const { lang } = match.params;
    let helmetData = getHelmet({}, lang);

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container_wrapper}>
          <div className={styles.container}>
            {/* 币种上浮卡片 */}
            <div className={styles.cardList}>
              {this.buildCardItem('btcusdt', symbolBtcImg)}
              {this.buildCardItem('ethusdt', symbolEthImg)}
              {this.buildCardItem('htxusdt', symbolHtxImg)}
              {this.buildCardItem('solusdt', symbolSolImg)}
              {this.buildCardItem('bnbusdt', symbolBnbImg)}
            </div>
            {/* tabBar */}
            <div className={styles.opTabBar}>
              <div className={styles.powerBy}>Powered by</div>
              <div className={styles.content}>
                <div className={styles.logoBox} onClick={handleOpenHtxHome}>
                  <img src={htxLogoImg} alt={''} className={styles.logo} />
                  <i className={styles.mask} />
                </div>
                <div className={styles.tabbar}>
                  <div
                    className={styles.item}
                    onClick={() => this.handleChangeTabbar('collect')}
                  >
                    {this.state.currentTabbar === 'collect' ? (
                      <img
                        src={starSelectedImg}
                        alt={''}
                        className={styles.icon}
                      />
                    ) : (
                      <img src={starImg} alt={''} className={styles.icon} />
                    )}
                    {this.state.currentTabbar === 'collect' ? (
                      <span className={classNames(styles.text, styles.active)}>
                        {locales(lang, 'market_page_tabbar_auto')}
                      </span>
                    ) : (
                      <span className={styles.text}>
                        {locales(lang, 'market_page_tabbar_auto')}
                      </span>
                    )}
                  </div>
                  <div
                    className={styles.item}
                    onClick={() => this.handleChangeTabbar('market')}
                  >
                    {this.state.currentTabbar === 'market' ? (
                      <span className={classNames(styles.text, styles.active)}>
                        {locales(lang, 'market_page_tabbar_market')}
                      </span>
                    ) : (
                      <span className={styles.text}>
                        {locales(lang, 'market_page_tabbar_market')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* 表格 */}
            <div className={styles.tableBox}>
              {/* 左侧表格 */}
              <div className={styles.leftTableBox}>
                {/* 表头 */}
                <div className={styles.tableTitle}>
                  <input
                    className={styles.inputBox}
                    placeholder={'请输入'}
                    onInput={event => this.handleKeywordsChange(event)}
                  />
                  {/*<select*/}
                  {/*  className={styles.selectBox}*/}
                  {/*  value={this.state.topLimit}*/}
                  {/*  onInput={event => this.handleTopLimitChange(event)}*/}
                  {/*>*/}
                  {/*  <option value={100}>top 100</option>*/}
                  {/*  <option value={50}>top 50</option>*/}
                  {/*  <option value={10}>top 10</option>*/}
                  {/*</select>*/}
                </div>
                {/* 表数据 */}
                <div className={styles.tableHeader}>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '12px' }}
                  >
                    <span className={styles.text}>#</span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '8px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_symbol')}
                    </span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '33px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_price')}
                    </span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '33px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_rate')}
                    </span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '33px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_high_price')}
                    </span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '33px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_low_price')}
                    </span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '75px', marginLeft: '33px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_vol_24h')}
                    </span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '72px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_market_value')}
                    </span>
                  </div>
                  <div
                    className={styles.item}
                    style={{ width: '62px', marginLeft: '79px' }}
                  >
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_kline_7d')}
                    </span>
                  </div>
                </div>
                <div className={styles.tableBody}>
                  {this.state.currentTabbar === 'collect'
                    ? this.state.collectList.map((item, index) =>
                        index >= this.state.topLimit ||
                        (this.state.searchKeyword &&
                          item.symbol.replace('usdt', '').toUpperCase() !==
                            this.state.searchKeyword.toUpperCase())
                          ? null
                          : this.buildLeftTableLine(
                              item.index,
                              item.collected,
                              item.symbol,
                              item.price,
                              item.rate,
                              item.lowPrice,
                              item.highPrice,
                              item.deal,
                              item.market,
                              item.klineData,
                              item.isUp
                            )
                      )
                    : this.state.marketList.map((item, index) =>
                        index >= this.state.topLimit ||
                        (this.state.searchKeyword &&
                          item.symbol.replace('usdt', '').toUpperCase() !==
                            this.state.searchKeyword.toUpperCase())
                          ? null
                          : this.buildLeftTableLine(
                              item.index,
                              item.collected,
                              item.symbol,
                              item.price,
                              item.rate,
                              item.lowPrice,
                              item.highPrice,
                              item.deal,
                              item.market,
                              item.klineData,
                              item.isUp
                            )
                      )}
                </div>
              </div>
              {/* 右侧列表 */}
              <div className={styles.rightTableBox}>
                {/* 分类 */}
                <div className={styles.rankList}>
                  <div
                    className={styles.rankItem}
                    onClick={() => this.handleChangeRank('up')}
                  >
                    {this.state.currentRank === 'up' ? (
                      <span className={classNames(styles.text, styles.active)}>
                        {locales(lang, 'market_page_rank_up')}
                      </span>
                    ) : (
                      <span className={styles.text}>
                        {locales(lang, 'market_page_rank_up')}
                      </span>
                    )}
                  </div>
                  <div
                    className={styles.rankItem}
                    onClick={() => this.handleChangeRank('new')}
                  >
                    {this.state.currentRank === 'new' ? (
                      <span className={classNames(styles.text, styles.active)}>
                        {locales(lang, 'market_page_rank_new')}
                      </span>
                    ) : (
                      <span className={styles.text}>
                        {locales(lang, 'market_page_rank_new')}
                      </span>
                    )}
                  </div>
                  <div
                    className={styles.rankItem}
                    style={{ width: '62px' }}
                    onClick={() => this.handleChangeRank('deal')}
                  >
                    {this.state.currentRank === 'deal' ? (
                      <span className={classNames(styles.text, styles.active)}>
                        {locales(lang, 'market_page_rank_deal')}
                      </span>
                    ) : (
                      <span className={styles.text}>
                        {locales(lang, 'market_page_rank_deal')}
                      </span>
                    )}
                  </div>
                </div>
                <div className={styles.tableHeader}>
                  <div className={styles.item}>
                    {locales(lang, 'market_page_table_title_symbol')}
                  </div>
                  <div className={styles.item}>
                    <span className={styles.text}>
                      {locales(lang, 'market_page_table_title_price')}
                    </span>
                  </div>
                  <div className={styles.item}>
                    {this.state.currentRank === 'deal' ? (
                      <span className={styles.text}>
                        {locales(lang, 'market_page_table_title_vol')}
                      </span>
                    ) : (
                      <span className={styles.text}>
                        {locales(lang, 'market_page_table_title_rate')}
                      </span>
                    )}
                  </div>
                </div>
                {/* 表头 */}
                <div className={styles.tableBody}>
                  {this.state.rankList.map(item =>
                    this.buildRightTableLine(
                      item.symbol,
                      item.price,
                      item.rate,
                      item.isUp,
                      item.marketValue
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps)(Market));
