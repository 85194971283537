import http, { service } from '../scripts/http';

export function getSearchResult(
  keyword,
  type = 'post',
  page = 1,
  perPage = 20,
  lang
) {
  return http.get(
    `/search/entity-search?page=${page}&per_page=${perPage}&keyword=${keyword}&entity_type=${type}`,
    { lang }
  );
}

export function getSearchResultNew(keyword, page = 1, perPage = 20, lang) {
  return http.get(
    `/search/entity-aggregation?page=${page}&perPage=${perPage}&keyword=${keyword}`,
    { lang }
  );
}

export const getHotSearch = lang => http.get('/allhotword', { lang });

export function searchProject({ value, per_page = 100, page = 1 }, lang) {
  return service.post(`/odata/search`, {
    keyword: decodeURI(value),
    per_page: per_page,
    page: page,
    type: 'item',
    lang: lang
  });
}

export function searchInstitution({ value, per_page = 50, page = 1 }, lang) {
  return service.post(`odata/search`, {
    keyword: decodeURI(value),
    per_page: per_page,
    page: page,
    type: 'organization',
    lang: lang
  });
}
