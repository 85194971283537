import locales from '../../utils/locales';
import url from 'url';

export const defaultMeta = {
  zh: {
    title: '区块链技术_元宇宙新闻_web3资讯-odaily',
    description:
      'ODAILY是36氪独家战略合作的区块链媒体，通过全面、深度的信息资讯服务为区块链爱好者与项目决策者提供每日必备的前沿区块链技术资讯、区块链游戏、元宇宙、Web3.0行业解读和NFT前景预测等方面的内容。',
    twitterTitle: '专业的区块链新闻资讯平台_区块链技术应用_元宇宙_web3',
    twitterDescription:
      'ODAILY是36kr独家战略合作的区块链媒体，通过全面、深度的信息资讯服务为区块链爱好者与项目决策者提供每日必备的前沿区块链技术资讯、区块链游戏、元宇宙、Web3.0行业解读和NFT前景预测等方面的内容。',
    twitterImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogTitle: '专业的区块链新闻资讯平台_区块链技术应用_元宇宙_web3',
    ogDescription:
      'ODAILY是36kr独家战略合作的区块链媒体，通过全面、深度的信息资讯服务为区块链爱好者与项目决策者提供每日必备的前沿区块链技术资讯、区块链游戏、元宇宙、Web3.0行业解读和NFT前景预测等方面的内容。',
    ogImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogUrl: 'https://www.odaily.news',
    titleFlag: false
  },
  th: {
    title:
      'แพลตฟอร์มข้อมูลข่าว Blockchain ระดับมืออาชีพ_แอปพลิเคชันเทคโนโลยี Blockchain_Metaverse_web3',
    description:
      'ODAILY เป็นสื่อบล็อกเชนความร่วมมือเชิงกลยุทธ์สุดพิเศษของ 36 Kr ด้วยบริการข้อมูลที่ครอบคลุมและเชิงลึกทำให้ผู้ที่ชื่นชอบบล็อกเชนและผู้มีอำนาจตัดสินใจโครงการได้รับข้อมูลเทคโนโลยีบล็อกเชนที่ทันสมัยเกมบล็อกเชนหยวนคอสมอสการตีความอุตสาหกรรม Web3.0 และการทำนายโอกาสของ NFT และด้านอื่นๆ',
    twitterTitle:
      'แพลตฟอร์มข้อมูลข่าว Blockchain ระดับมืออาชีพ_แอปพลิเคชันเทคโนโลยี Blockchain_Metaverse_web3',
    twitterDescription:
      'ODAILY เป็นสื่อบล็อกเชนความร่วมมือเชิงกลยุทธ์สุดพิเศษของ 36 Kr ด้วยบริการข้อมูลที่ครอบคลุมและเชิงลึกทำให้ผู้ที่ชื่นชอบบล็อกเชนและผู้มีอำนาจตัดสินใจโครงการได้รับข้อมูลเทคโนโลยีบล็อกเชนที่ทันสมัยเกมบล็อกเชนหยวนคอสมอสการตีความอุตสาหกรรม Web3.0 และการทำนายโอกาสของ NFT และด้านอื่นๆ',
    twitterImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogTitle:
      'แพลตฟอร์มข้อมูลข่าว Blockchain ระดับมืออาชีพ_แอปพลิเคชันเทคโนโลยี Blockchain_Metaverse_web3',
    ogDescription:
      'ODAILY เป็นสื่อบล็อกเชนความร่วมมือเชิงกลยุทธ์สุดพิเศษของ 36 Kr ด้วยบริการข้อมูลที่ครอบคลุมและเชิงลึกทำให้ผู้ที่ชื่นชอบบล็อกเชนและผู้มีอำนาจตัดสินใจโครงการได้รับข้อมูลเทคโนโลยีบล็อกเชนที่ทันสมัยเกมบล็อกเชนหยวนคอสมอสการตีความอุตสาหกรรม Web3.0 และการทำนายโอกาสของ NFT และด้านอื่นๆ',
    ogImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogUrl: 'https://www.odaily.news',
    titleFlag: false
  },
  en: {
    title:
      'Professional Blockchain News Information Platform_Blockchain Technology Application_Metaverse_web3-odaily',
    description:
      'ODAILY is the exclusive strategic cooperation blockchain media of 36 Kr. Through comprehensive and in-depth information services, it provides blockchain enthusiasts and project decision makers with daily necessary cutting-edge blockchain technology information, blockchain games, yuan Cosmos, Web3.0 industry interpretation and NFT prospect prediction and other aspects.',
    twitterTitle:
      'Professional Blockchain News Information Platform_Blockchain Technology Application_Metaverse_web3',
    twitterDescription:
      'ODAILY is the exclusive strategic cooperation blockchain media of 36 Kr. Through comprehensive and in-depth information services, it provides blockchain enthusiasts and project decision makers with daily necessary cutting-edge blockchain technology information, blockchain games, yuan Cosmos, Web3.0 industry interpretation and NFT prospect prediction and other aspects.',
    twitterImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogTitle:
      'Professional Blockchain News Information Platform_Blockchain Technology Application_Metaverse_web3',
    ogDescription:
      'ODAILY is the exclusive strategic cooperation blockchain media of 36 Kr. Through comprehensive and in-depth information services, it provides blockchain enthusiasts and project decision makers with daily necessary cutting-edge blockchain technology information, blockchain games, yuan Cosmos, Web3.0 industry interpretation and NFT prospect prediction and other aspects.',
    ogImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogUrl: 'https://www.odaily.news',
    titleFlag: false
  },
  ja: {
    title:
      '専門的なブロックチェーンニュース情報プラットフォーム_ブロックチェーン技術アプリケーション_Metaverse_web3-odaily',
    description:
      'ODAILYは36クリプトン社の独占的な戦略協力ブロックチェーンメディアであり、包括的で詳細な情報サービスを通じて、ブロックチェーン愛好家やプロジェクトの意思決定者に毎日必要な最先端のブロックチェーン技術情報、ブロックチェーンゲーム、元コスモス、Web3.0業界解釈を提供します。およびNFTの見通し予測およびその他の側面。',
    twitterTitle:
      '専門的なブロックチェーンニュース情報プラットフォーム_ブロックチェーン技術アプリケーション_Metaverse_web3',
    twitterDescription:
      'ODAILYは36クリプトン社の独占的な戦略協力ブロックチェーンメディアであり、包括的で詳細な情報サービスを通じて、ブロックチェーン愛好家やプロジェクトの意思決定者に毎日必要な最先端のブロックチェーン技術情報、ブロックチェーンゲーム、元コスモス、Web3.0業界解釈を提供します。およびNFTの見通し予測およびその他の側面。',
    twitterImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogTitle:
      '専門的なブロックチェーンニュース情報プラットフォーム_ブロックチェーン技術アプリケーション_Metaverse_web3',
    ogDescription:
      'ODAILYは36クリプトン社の独占的な戦略協力ブロックチェーンメディアであり、包括的で詳細な情報サービスを通じて、ブロックチェーン愛好家やプロジェクトの意思決定者に毎日必要な最先端のブロックチェーン技術情報、ブロックチェーンゲーム、元コスモス、Web3.0業界解釈を提供します。およびNFTの見通し予測およびその他の側面。',
    ogImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogUrl: 'https://www.odaily.news',
    titleFlag: false
  },
  vi: {
    title:
      'Nền tảng thông tin tin tức chuỗi khối chuyên nghiệp_Ứng dụng công nghệ chuỗi khối_Metaverse_web3-odaily',
    description:
      'ODAILY là phương tiện blockchain hợp tác chiến lược độc quyền của 36 Kr. Thông qua các dịch vụ thông tin chuyên sâu và toàn diện, nó cung cấp cho những người đam mê blockchain và những người ra quyết định dự án thông tin công nghệ blockchain tiên tiến cần thiết hàng ngày, trò chơi blockchain, nhân dân tệ Cosmos, diễn giải ngành Web3.0 và dự báo triển vọng NFT và các khía cạnh khác.',
    twitterTitle:
      'Nền tảng thông tin tin tức chuỗi khối chuyên nghiệp_Ứng dụng công nghệ chuỗi khối_Metaverse_web3',
    twitterDescription:
      'ODAILY là phương tiện blockchain hợp tác chiến lược độc quyền của 36 Kr. Thông qua các dịch vụ thông tin chuyên sâu và toàn diện, nó cung cấp cho những người đam mê blockchain và những người ra quyết định dự án thông tin công nghệ blockchain tiên tiến cần thiết hàng ngày, trò chơi blockchain, nhân dân tệ Cosmos, diễn giải ngành Web3.0 và dự báo triển vọng NFT và các khía cạnh khác.',
    twitterImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogTitle:
      'Nền tảng thông tin tin tức chuỗi khối chuyên nghiệp_Ứng dụng công nghệ chuỗi khối_Metaverse_web3',
    ogDescription:
      'ODAILY là phương tiện blockchain hợp tác chiến lược độc quyền của 36 Kr. Thông qua các dịch vụ thông tin chuyên sâu và toàn diện, nó cung cấp cho những người đam mê blockchain và những người ra quyết định dự án thông tin công nghệ blockchain tiên tiến cần thiết hàng ngày, trò chơi blockchain, nhân dân tệ Cosmos, diễn giải ngành Web3.0 và dự báo triển vọng NFT và các khía cạnh khác.',
    ogImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogUrl: 'https://www.odaily.news',
    titleFlag: false
  },
  zhtw: {
    title: '專業的區塊鏈新聞資訊平台_區塊鏈技術應用_元宇宙_web3-odaily',
    description:
      'ODAILY是36氪獨家戰略合作的區塊鏈媒體，通過全面、深度的信息資訊服務為區塊鏈愛好者與項目決策者提供每日必備的前沿區塊鏈技術資訊、區塊鏈遊戲、元宇宙、Web3.0行業解讀和NFT前景預測等方面的內容。',
    twitterTitle: '專業的區塊鏈新聞資訊平台_區塊鏈技術應用_元宇宙_web3',
    twitterDescription:
      'ODAILY是36氪獨家戰略合作的區塊鏈媒體，通過全面、深度的信息資訊服務為區塊鏈愛好者與項目決策者提供每日必備的前沿區塊鏈技術資訊、區塊鏈遊戲、元宇宙、Web3.0行業解讀和NFT前景預測等方面的內容。',
    twitterImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogTitle: '專業的區塊鏈新聞資訊平台_區塊鏈技術應用_元宇宙_web3',
    ogDescription:
      'ODAILY是36氪獨家戰略合作的區塊鏈媒體，通過全面、深度的信息資訊服務為區塊鏈愛好者與項目決策者提供每日必備的前沿區塊鏈技術資訊、區塊鏈遊戲、元宇宙、Web3.0行業解讀和NFT前景預測等方面的內容。',
    ogImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogUrl: 'https://www.odaily.news',
    titleFlag: false
  },
  ko: {
    title:
      '전문 블록체인 뉴스 정보 플랫폼_블록체인 기술 응용 프로그램_Metaverse_web3-odaily',
    description:
      'ODAILY는 36 크립톤의 독점적인 전략적 협력 블록체인 미디어입니다.종합적이고 심층적인 정보 서비스를 통해 블록체인 애호가 및 프로젝트 결정권자에게 매일 필요한 최신 블록체인 기술 정보, 블록체인 게임, 위안 코스모스, Web3.0 산업 해석을 제공합니다. NFT 전망 예측 및 기타 측면.',
    twitterTitle:
      '전문 블록체인 뉴스 정보 플랫폼_블록체인 기술 응용 프로그램_Metaverse_web3',
    twitterDescription:
      'ODAILY는 36 크립톤의 독점적인 전략적 협력 블록체인 미디어입니다.종합적이고 심층적인 정보 서비스를 통해 블록체인 애호가 및 프로젝트 결정권자에게 매일 필요한 최신 블록체인 기술 정보, 블록체인 게임, 위안 코스모스, Web3.0 산업 해석을 제공합니다. NFT 전망 예측 및 기타 측면.',
    twitterImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogTitle:
      '전문 블록체인 뉴스 정보 플랫폼_블록체인 기술 응용 프로그램_Metaverse_web3',
    ogDescription: '',
    ogImage:
      'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
    ogUrl: 'https://www.odaily.news',
    titleFlag: false
  }
};

export function helment_locale(key, lang) {
  const cnt_lang = lang || 'zh';
  let ret;
  try {
    ret = defaultMeta[cnt_lang][key] || defaultMeta['zh'][key];
  } catch (e) {
    console.info(
      '~~~helment_locale~~key~~~lang~~~~~',
      key,
      '~~~~~~~~~',
      lang,
      cnt_lang
    );
    ret = defaultMeta['zh'][key];
  }
  return ret;
}

export function getHelmet(obj, lang) {
  const cnt_lang = lang || 'zh';
  let o_obj = obj || {};
  let o_defaultMeta = defaultMeta['zh'];
  try {
    o_defaultMeta = defaultMeta[cnt_lang] || defaultMeta['zh'];
  } catch (e) {
    console.info('helmet~~~error~~');
  }
  let helmet = Object.assign(
    o_defaultMeta,
    {
      keywords: locales(cnt_lang, 'helmetKeywords')
    },
    o_obj
  );
  let {
    title,
    description,
    keywords,
    twitterTitle,
    twitterDescription,
    twitterImage,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl
  } = helmet;

  const M_SITE = process.env.M_SITE;
  let pathname = url.parse(ogUrl).pathname;
  let meta = [
    {
      content: 'index,follow,archive',
      name: 'robots'
    },
    {
      content: 'summary',
      property: `twitter:card`,
      name: 'twitter:card'
    },
    {
      content: '@OdailyChina',
      property: `twitter:site`,
      name: 'twitter:site'
    },
    {
      content: '@OdailyChina',
      property: `twitter:creator`,
      name: 'twitter:creator'
    },
    {
      content: 'website',
      name: 'og:type'
    }
  ];

  let link = [
    {
      rel: 'canonical',
      href: ogUrl
    }
  ];
  if (lang == 'zh') {
    // 中文才有谷歌的alternate，其他的还没做
    link.append({
      rel: 'alternate',
      media: 'only screen and (max-width: 1200px)',
      href: `${M_SITE}${pathname}`
    });
  }
  meta.push({
    name: 'description',
    content: description
  });

  meta.push({
    name: 'keywords',
    content: keywords
  });

  meta.push({
    content: twitterTitle,
    property: `twitter:title`,
    name: 'twitter:title'
  });

  meta.push({
    content: twitterDescription,
    property: `twitter:description`,
    name: 'twitter:description'
  });

  meta.push({
    content: twitterImage,
    property: `twitter:image`,
    name: 'twitter:image'
  });

  meta.push({
    content: ogTitle,
    property: `og:title`,
    name: 'og:title'
  });

  meta.push({
    content: ogDescription,
    property: `og:description`,
    name: 'og:description'
  });

  meta.push({
    content: ogImage,
    property: `og:image`,
    name: 'og:image'
  });

  meta.push({
    content: ogUrl,
    property: `og:url`,
    name: 'og:url'
  });

  if (!helmet.titleFlag) {
    title = title + '-odaily';
  }
  helmet.titleFlag = false;
  return {
    title,
    meta,
    link
  };
}
