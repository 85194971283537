import http from '../scripts/http';
import { service } from '../scripts/http';

export function getPostById(postId, lang) {
  return http.get(`/post/${postId}`, { lang });
}

export function getPostPreviewById(postId, lang) {
  return http.get(`/post/${postId}/preview`, { lang });
}

export function getPostNextById(postId) {
  return http.get(`/post/${postId}/next`);
}

export function getPostHot() {
  return http.get('/post-hot?key=main_site');
}

export function getPostComment(cid, ctype = 'post', perPage = 10, bId = '') {
  return http.get(
    `/comment?cid=${cid}&ctype=${ctype}&per_page=${perPage}&b_id=${bId}`
  );
}

export function postComment({ cid, ctype = 'post', ancestry, content }) {
  return http.post('/comment', {
    cid,
    ctype,
    ancestry,
    content
  });
}

export function postLike(postId) {
  return http.put(`/post/${postId}/like`);
}

export function postCollect(postId) {
  return http.put(`/post/${postId}/favorite`);
}

export function getLikeInfo(postId) {
  return http.get(`/post/${postId}/like-info`);
}

export function getCollectInfo(postId) {
  return http.get(`/post/${postId}/favorite-info`);
}

export function delComment(commentId) {
  return http.delete(`/comment/${commentId}`);
}

export function secondaryTag(commentId, lang) {
  return service.get(`/postTag/${commentId}`, { lang });
}

export function getSecondaryTag() {
  return service.get(`secondary_tag/index`);
}

export function getSecondaryTagList(id) {
  return service.get(`secondary_tag/show?post_id=${id}`);
}

export function getConcern(followId) {
  return http.get(`/follow-status?fid=${followId}&ftype=user`);
}

export function getPost(channelId = 280, bId = '', perPage = 30) {
  return http.get(
    `/app-front/feed-stream?feed_id=${channelId}&b_id=${bId}&per_page=${perPage}`
  );
}

export function getNewPost(perPage = 5, lang) {
  return http.get(`/feed-stream/post?per_page=${perPage}`, { lang });
}

export function addShare(entity_id, entity_type) {
  return http.post(`/counter/add_share`, { entity_id, entity_type });
}

export function getPostViewpoint(id, lang) {
  return http.get(`/post/viewpoints/${id}`, { lang });
}

export function getPostOdata(id, lang) {
  return http.get(`/post/odata/${id}`, { lang });
}
