exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a {\n  text-decoration: none; }\n\n.src-components-video-detail--container-index {\n  width: 100%; }\n  .src-components-video-detail--container-index .src-components-video-detail--post-title-index {\n    font-size: 22px;\n    font-family: PingFangSC-Medium;\n    font-weight: 500;\n    color: #333333;\n    line-height: 36px;\n    padding: 22px 0 0; }\n  .src-components-video-detail--container-index .src-components-video-detail--post-time-index {\n    font-size: 12px;\n    font-weight: 400;\n    color: #ACAFB2;\n    line-height: 12px;\n    padding: 13px 0 0; }\n  .src-components-video-detail--container-index .src-components-video-detail--post-video-index {\n    width: 100%;\n    height: 477px;\n    border: 1px solid #333333;\n    margin-top: 20px;\n    overflow: hidden;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box; }\n    .src-components-video-detail--container-index .src-components-video-detail--post-video-index video {\n      width: 830px;\n      height: 467px; }\n", ""]);

// exports
exports.locals = {
	"container": "src-components-video-detail--container-index",
	"post-title": "src-components-video-detail--post-title-index",
	"post-time": "src-components-video-detail--post-time-index",
	"post-video": "src-components-video-detail--post-video-index"
};