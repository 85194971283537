import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './index.scss';
import Layout from '../../components/common/layout';
import Tab from '../../components/tab';
import { RePost } from '../../components/recommendPost';
import { HotPostItem } from '../../components/recommendPost/item';
import { PostItem } from '../../components/post';
import { NewsItem } from '../../components/newsflash';
// import { HomeMarket } from '../../components/homeMarket';
import Recommend from '../../components/recommend';
// import HomeEcharts from '../../components/homeEcharts';
import AdPicture from '../../components/adPicture';
import Sticky from '../../components/common/sticky';
import { getColumns } from '../../common/api/columns';
import {
  getFeedsByChannelId,
  getmonographic_video
} from '../../common/api/feeds';
import { getSlide, getAdvert, getHome_activity } from '../../common/api/slide';
import { SideList } from '../../components/homeSide';
import Slide from '../../components/slide';
import ActivitySlide from '../../components/activitySlide';
import HomeLoadMore from '../../components/common/homeloadmore';
// import RecommendAuthor from '../../components/recommendAuthor';
import Helmet from 'react-helmet';
import { getNewsflash, getHotNewsflash } from '../../common/api/newsflash';
import moment from 'moment';
import Download from '../../components/download/index';
import HomeViewpoint from '../../components/homeViewpoint';

import { getHelmet } from '../../common/scripts/helmet';

import { dot } from '../../common/scripts/dot';
import wechatShare from '../../common/scripts/wechat';

import {
  getTopCommend,
  getTopPost,
  getTopPostList
} from '../../common/api/found';
import { getViewpointList } from '../../common/api/viewpoint';
import locales from '../../utils/locales';
import { HomeMarketBar } from '../../components/homeMarketBar';

const channelId = 280;

export class Home extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    feeds: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    topPost: PropTypes.array.isRequired,
    topPostList: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    slide: PropTypes.array.isRequired,
    currentUser: PropTypes.object,
    newsList: PropTypes.array.isRequired,
    activityList: PropTypes.array.isRequired,
    viewpointList: PropTypes.array.isRequired,
    advertTop: PropTypes.array,
    advertMiddle: PropTypes.array,
    advertBottom: PropTypes.array,
    advertFeed: PropTypes.array,
    hotNewsflashList: PropTypes.array
  };

  static fetchData(store, match) {
    const lang = match.params.lang;
    return new Promise((resolve, reject) => {
      getSlide(lang)
        .then(data => {
          const list = data.data.items.map(i => ({
            ...i,
            url: lang
              ? `${i.url.split('news')[0]}news/${lang}${i.url.split('news')[1]}`
              : i.url
          }));
          store.dispatch({
            type: 'SET_SLIDE',
            list
          });
          return getColumns(lang);
        })
        .then(data => {
          const list = data.data;
          store.dispatch({
            type: 'SET_COLUMN',
            list
          });
          return getFeedsByChannelId(channelId, '', 20, lang);
        })
        .then(data => {
          store.dispatch({
            type: 'SET_FEEDS',
            channelId,
            list: data.data.items
          });
          return getTopPost('week', lang);
        })
        .then(data => {
          const postList = data.data.items;
          store.dispatch({
            type: 'SET_TOPPOST',
            list: postList
          });
          return getTopCommend(lang);
        })
        .then(data => {
          store.dispatch({
            type: 'SET_TOPCOMMEND',
            list: data.data.items
          });
          return getNewsflash({}, lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_NEWS_LIST',
            list: list
          });
          return getTopPostList(lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_TOP_LIST',
            list: list
          });
          return getHome_activity(lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_HOME_ACTIVITY',
            list: list
          });
          return getViewpointList(undefined, undefined, lang);
        })
        .then(data => {
          if (data.data && data.data.data) {
            const list = data.data.data;
            store.dispatch({
              type: 'SET_VIEWPOINT_LIST',
              list: list
            });
          }
          return getAdvert('home_advertisement', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_TOP',
            list: list
          });
          return getAdvert('home_advertisement_middle', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_MIDDLE',
            list: list
          });
          return getAdvert('home_advertisement_bottom', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_BOTTOM',
            list: list
          });
          return getAdvert('home_advertisement_feed', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_FEED',
            list: list
          });
          return getHotNewsflash(lang);
        })
        .then(data => {
          const list = data.data;
          store.dispatch({
            type: 'SET_HOT_NEWSFLASH',
            list: list
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static defaultProps = {
    advertFeed: [],
    advertMiddle: [],
    advertBottom: [],
    advertTop: [],
    hotNewsflashList: []
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      loading: false,
      topAuthorList: [],
      postType: 'day',
      flag: true,
      monographicList: [],
      videoList: [],
      week: ''
    };
  }

  renderFeeds() {
    const { feeds, currentUser, advertFeed, match } = this.props;
    const { monographicList, videoList } = this.state;
    let currentFeeds = feeds[channelId] || [];
    let monographic = { entity_type: 'monographicList' };
    let advertisement = { entity_type: 'advertisement' };
    // let video = { entity_type: 'videoList' };
    currentFeeds =
      Object.prototype.toString.call(currentFeeds) === '[object Array]'
        ? currentFeeds
        : [];
    if (
      currentFeeds[2] &&
      currentFeeds[2].entity_type !== 'advertisement' &&
      advertFeed &&
      advertFeed.length > 0 &&
      advertFeed[0].url &&
      advertFeed[0].cover
    ) {
      currentFeeds.splice(2, 0, advertisement);
    }
    if (
      currentFeeds[5] &&
      currentFeeds[6] &&
      currentFeeds[5].entity_type !== 'monographicList' &&
      currentFeeds[6].entity_type !== 'monographicList' &&
      monographicList &&
      monographicList.length
    ) {
      currentFeeds.splice(5, 0, monographic);
    }
    // if (
    //   currentFeeds[8].entity_type !== 'videoList' &&
    //   currentFeeds[9].entity_type !== 'videoList' &&
    //   videoList &&
    //   videoList.length
    // ) {
    //   currentFeeds.splice(8, 0, video);
    // }

    return currentFeeds.map(feed => {
      if (feed.entity_type === 'post' && !feed.type) {
        return (
          <PostItem
            key={feed.id}
            {...feed}
            {...match}
            currentUser={currentUser}
          />
        );
      }
      if (feed.entity_type === 'newsflash') {
        return (
          <NewsItem
            key={feed.id}
            {...feed}
            {...match}
            currentUser={currentUser}
          />
        );
      }
      if (feed.entity_type === 'monographicList') {
        return (
          <Recommend
            key={feed.id}
            type="monographic"
            {...match}
            list={monographicList}
            currentUser={currentUser}
          />
        );
      }
      if (feed.entity_type === 'videoList') {
        return (
          <Recommend
            {...match}
            key={feed.id}
            type="video"
            list={videoList}
            currentUser={currentUser}
          />
        );
      }
      if (feed.entity_type === 'advertisement') {
        return (
          <div className={styles.advertisement} key={`advertisement${feed}`}>
            <AdPicture
              width="840px"
              height="104px"
              url={advertFeed[0].url}
              img={advertFeed[0].cover}
            />
          </div>
        );
      }
    });
  }

  loadMore() {
    const { feeds, dispatch } = this.props;
    const lastOfCurrentFeeds = _.last(feeds[channelId]);
    const bId = _.get(lastOfCurrentFeeds, 'id');
    this.setState({
      loading: true
    });
    this.setState({
      flag: false
    });
    getFeedsByChannelId(channelId, bId)
      .then(data => {
        if (data.data.items.length === 0) {
          this.setState({
            loading: true,
            loaded: true
          });
          return false;
        }
        const list = _.concat(feeds[channelId], data.data.items);
        dispatch({
          type: 'SET_FEEDS',
          channelId,
          list
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
          flag: true
        });
      });
  }

  renderTopPost() {
    let { week } = this.state;
    let { topPostList } = this.props;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.topPost}>
        <div className={styles.topPost_header}>
          <div className={styles.topPost_title}>
            {locales(lang, 'planet', { isTitle: true })}
          </div>
          <div className={styles.topPost_time}>
            <div className={styles.topPost_time_text}>{week}</div>
          </div>
        </div>

        <div className={styles.topPost_list}>
          {topPostList.map(item => {
            return (
              <div
                className={styles.topPost_item}
                key={`topPost${item.post_id}`}
              >
                <a
                  href={
                    lang
                      ? `/${lang}/post/${item.post_id}`
                      : `/post/${item.post_id}`
                  }
                  target={'_blank'}
                  rel="noreferrer"
                  className={styles.topPost_size}
                  onClick={() => {
                    dot({ id: 20, user_id: this.props.currentUser.id });
                  }}
                >
                  {item.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  getTopPost() {
    const { postType } = this.state;
    getTopPost(postType)
      .then(data => {
        this.props.dispatch({
          type: 'SET_TOPPOST',
          list: data.data.items
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  getmonographic_videoData() {
    getmonographic_video().then(data => {
      if (data.code === 0) {
        this.setState({
          monographicList: data.data.items.monographic,
          videoList: data.data.items.video
        });
      }
    });
  }

  componentDidMount() {
    this.setState({
      loaded: false,
      loading: false
    });
    this.getmonographic_videoData();

    let week = moment().day();
    switch (week) {
      case 1:
        this.setState({ week: 'Mon' });
        break;
      case 2:
        this.setState({ week: 'Tue' });
        break;
      case 3:
        this.setState({ week: 'Wed' });
        break;
      case 4:
        this.setState({ week: 'Thu' });
        break;
      case 5:
        this.setState({ week: 'Fri' });
        break;
      case 6:
        this.setState({ week: 'Sat' });
        break;
      case 0:
        this.setState({ week: 'Sun' });
        break;
    }
    wechatShare({});
  }

  renderPostHot() {
    const postList = this.props.topPost;
    const { currentUser } = this.props;

    if (postList.length > 0) {
      return postList
        .filter((item, index) => {
          return index < 5;
        })
        .map((item, index) => {
          const props = Object.assign(item, {
            type: 'small',
            sort: index + 1,
            targetFlag: '_blank'
          });
          return (
            <HotPostItem {...props} key={index} currentUser={currentUser} />
          );
        });
    }
  }

  getHotPosts(value) {
    this.setState(
      {
        postType: value.value
      },
      () => {
        this.getTopPost();
      }
    );
  }

  renderActivity() {
    const { lang } = this.props.match.params;
    return (
      <div className={styles.activity_wrapper}>
        <div className={styles.activity_header}>
          <div className={styles.activity_header_title}>
            {locales(lang, 'selected', { isTitle: true })}
          </div>
          <a
            href={lang ? `/${lang}/activityPage` : '/activityPage'}
            className={styles.activity_button}
            target={'block'}
            onClick={() => {
              dot({ id: 28 });
            }}
          />
        </div>

        <div
          className={styles.activitySlide}
          onClick={() => {
            dot({ id: 27 });
          }}
        >
          <ActivitySlide
            width={296}
            height={278}
            list={this.props.activityList}
            font={16}
          />
        </div>
      </div>
    );
  }

  render() {
    const state = this.state;
    const tablist = [];
    for (var item of this.props.columns) {
      tablist.push(item);
    }
    const {
      feeds,
      currentUser,
      advertTop,
      advertMiddle,
      advertBottom,
      hotNewsflashList,
      viewpointList,
      match
    } = this.props;
    const { lang } = match.params;
    let currentFeeds = feeds[channelId];
    const defaultMeta = {
      title: '区块链技术_元宇宙新闻_web3资讯-odaily',
      description:
        'ODAILY是36氪独家战略合作的区块链媒体，通过全面、深度的信息资讯服务为区块链爱好者与项目决策者提供每日必备的前沿区块链技术资讯、区块链游戏、元宇宙、Web3.0行业解读和NFT前景预测等方面的内容。',
      twitterTitle: '专业的区块链新闻资讯平台_区块链技术应用_元宇宙_web3',
      twitterDescription:
        'ODAILY是36kr独家战略合作的区块链媒体，通过全面、深度的信息资讯服务为区块链爱好者与项目决策者提供每日必备的前沿区块链技术资讯、区块链游戏、元宇宙、Web3.0行业解读和NFT前景预测等方面的内容。',
      twitterImage:
        'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
      ogTitle: '专业的区块链新闻资讯平台_区块链技术应用_元宇宙_web3',
      ogDescription:
        'ODAILY是36kr独家战略合作的区块链媒体，通过全面、深度的信息资讯服务为区块链爱好者与项目决策者提供每日必备的前沿区块链技术资讯、区块链游戏、元宇宙、Web3.0行业解读和NFT前景预测等方面的内容。',
      ogImage:
        'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
      ogUrl: 'https://www.odaily.news',
      titleFlag: false
    };
    let helmetData = getHelmet(
      {
        title: defaultMeta['title'],
        description: defaultMeta['description'],
        ogTitle: defaultMeta['ogTitle'],
        ogDescription: defaultMeta['ogDescription'],
        twitterTitle: defaultMeta['twitterTitle'],
        twitterDescription: defaultMeta['twitterDescription'],
        titleFlag: true,
        ogUrl: `${process.env.ODAILY}${match.url}`,
        ogImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
      },
      lang
    );
    return (
      <Layout>
        <Helmet {...helmetData} />
        {/* 管理文档头，可以通过js动态的改变标签内的内容 */}
        <div className={styles.container_wrapper}>
          {/* 行情栏 */}
          <HomeMarketBar />
          {/* 头部下的内容 */}
          <div className={styles.container}>
            {/* 左边 */}
            <div className={styles['content-left']}>
              {/* 轮播图 */}
              <div className={styles.imgBox}>
                <div className={styles.slide_wrapper}>
                  <div className={styles.slide}>
                    <Slide
                      width={432}
                      height={243}
                      list={this.props.slide}
                      font={18}
                      currentUser={currentUser}
                    />
                  </div>
                </div>

                {/* 星球精选 */}
                {this.renderTopPost()}
              </div>

              {/* 内容的头部导航 */}
              {tablist && tablist.length ? (
                <Tab list={tablist} currentUser={currentUser} />
              ) : (
                ''
              )}

              {/* 内容 */}
              <div className={styles.home_feeds}>{this.renderFeeds()}</div>
              {/* 查看更多 */}
              <div className={styles.HomeLoadMore}>
                {currentFeeds.length < 20 ? (
                  ''
                ) : (
                  <HomeLoadMore
                    loading={state.loading}
                    loaded={state.loaded}
                    onClick={this.loadMore.bind(this)}
                  />
                )}
              </div>
            </div>

            {/* 右边 */}
            <div className={styles['content-right']}>
              {/* 首页边栏-顶部 */}
              {advertTop &&
              advertTop.length > 0 &&
              advertTop[0].url &&
              advertTop[0].cover ? (
                <div className={styles.advertTop}>
                  <AdPicture
                    width="340px"
                    height="226px"
                    url={advertTop[0].url}
                    img={advertTop[0].cover}
                  />
                </div>
              ) : (
                ''
              )}

              <div className={styles.odata_img_box}>
                <a
                  className={styles.odata_img}
                  href={process.env.ODATA}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </div>

              {/* 快讯 */}
              <SideList
                title="7X24h快讯"
                newsList={this.props.newsList}
                currentUser={currentUser}
              />

              {/* 精选观点 */}
              {viewpointList && viewpointList.length ? (
                <HomeViewpoint list={viewpointList} />
              ) : (
                ''
              )}

              <Download />

              {/* 精选活动 */}
              {this.props.activityList.length > 0 ? this.renderActivity() : ''}

              {/* echarts图 */}
              {/* <HomeEcharts currentUser={currentUser} /> */}

              {/* 首页边栏-中部 */}
              {advertMiddle &&
              advertMiddle.length > 0 &&
              advertMiddle[0].url &&
              advertMiddle[0].cover ? (
                <div className={styles.advertMiddle}>
                  <AdPicture
                    width="340px"
                    height="226px"
                    url={advertMiddle[0].url}
                    img={advertMiddle[0].cover}
                    icon={true}
                  />
                </div>
              ) : (
                ''
              )}

              {/* 推荐作者 */}
              {/* <div className={styles.recommendAuthor}>
                  <RecommendAuthor />
                </div> */}

              {/* 文章热榜 */}
              <Sticky styleData={{ marginTop: '20px' }}>
                <div className={styles.RePost}>
                  <RePost
                    getHotPosts={this.getHotPosts.bind(this)}
                    title="文章热榜"
                  >
                    {this.renderPostHot()}
                  </RePost>

                  {hotNewsflashList && hotNewsflashList.length ? (
                    <div className={styles.hotNewsflash}>
                      <div className={styles.hotNewsflash_header}>
                        <div className={styles.hotNewsflash_title}>
                          {locales(lang, 'kx_hot', { isTitle: true })}
                        </div>
                      </div>
                      {hotNewsflashList.map((item, index) => {
                        return (
                          <a
                            className={styles.hotNewsflash_item}
                            key={`hotNewsflash_item${item.id}`}
                            href={
                              lang
                                ? `/${lang}/newsflash/${item.id}`
                                : `/newsflash/${item.id}`
                            }
                            target={'_blank'}
                            rel="noreferrer"
                          >
                            <div
                              className={
                                styles[`hotNewsflash_item_icon${index}`]
                              }
                            />
                            <div className={styles[`hotNewsflash_item_title`]}>
                              {item.title}
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}

                  {/* 首页边栏-底部 */}
                  {advertBottom &&
                  advertBottom.length > 0 &&
                  advertBottom[0].url &&
                  advertBottom[0].cover ? (
                    <div className={styles.advertMiddle}>
                      <AdPicture
                        width="340px"
                        height="226px"
                        url={advertBottom[0].url}
                        img={advertBottom[0].cover}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Sticky>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    columns: state.columns.list,
    feeds: state.feeds,
    slide: state.slide,
    currentUser: state.user.current,
    topPost: state.home.topPost,
    topPostList: state.home.topPostList,
    newsList: state.home.newsList,
    activityList: state.activity.home_activity,
    advertTop: state.home.advertTop,
    advertMiddle: state.home.advertMiddle,
    advertBottom: state.home.advertBottom,
    advertFeed: state.home.advertFeed,
    hotNewsflashList: state.home.hotNewsflashList,
    viewpointList: state.viewpoint.list
  };
};

export default withRouter(connect(mapStateToProps)(Home));
