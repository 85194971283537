import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import styles from './index.scss';

import Layout from '../../components/common/layout';
import AdPicture from '../../components/adPicture';
import Share from '../../components/newsflash/share';
import PostRecommend from '../../components/post/recommend';
import ActivityLive from '../../components/activity/live';
import DownLoad from '../../components/download';

import {
  getActivityDetail,
  getLive,
  getCommentsList,
  getTime,
  getActivityInfo,
  getLikeNum
} from '../../common/api/activity';
import { getSecondaryList } from '../../common/api/columns';
import { getAdvert } from '../../common/api/slide';
import { dot } from '../../common/scripts/dot';
import { getHelmet } from '../../common/scripts/helmet';
import wechatShare from '../../common/scripts/wechat';
import locales from '../../utils/locales';

const buttonList = [
  {
    title: 'live_Cons',
    img: 'https://piccdn.0daily.com/202104/26033523/7oleg3qnw006b2uw.png',
    text: 'live_Cons',
    borderFlag: true
  },
  {
    title: 'bus_Coop',
    img: 'https://piccdn.0daily.com/202212/21104424/7uuld1f6qqxr0e2a.png',
    text: 'bus_Coop'
  }
];

const TransformHtml = html => {
  const changeImgDesc = html.replace(/img-desc/g, styles['img-desc']);
  return changeImgDesc;
};

export class Activity extends Component {
  static propTypes = {
    activityDetail: PropTypes.object.isRequired,
    liveData: PropTypes.object,
    secondaryList: PropTypes.array.isRequired,
    commentsList: PropTypes.object,
    now: PropTypes.number,
    activityInfo: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    advertBottom: PropTypes.array,
    likeNum: PropTypes.number,
    dispatch: PropTypes.func.isRequired
  };

  static fetchData(store, match, res) {
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      const id = match.params.id;
      let lsid = '';
      getAdvert('home_advertisement_bottom', lang)
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_BOTTOM',
            list: list
          });
          return getTime();
        })
        .then(data => {
          const list = data.data.items.now;
          store.dispatch({
            type: 'SET_ACTIVITY_TIME',
            list
          });
          return getActivityDetail(id, lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_DETAIL',
            list
          });
          lsid = list.live_url;
          let ary = [];
          data.data.items.secondary_tag.map(item => {
            ary.push(item.slug);
          });
          let str = ary.join(',');
          return getSecondaryList(str, 1, 6, lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_SECONDARY',
            list
          });
          if (lsid) {
            return getLive(lsid);
          } else {
            resolve();
          }
        })
        .then(data => {
          if (lsid) {
            const list = data.data.items;
            store.dispatch({
              type: 'SET_ACTIVITY_LIVE',
              list
            });
            return getCommentsList({ lsId: lsid, page: 1, pageSize: 50 });
          }
        })
        .then(data => {
          if (lsid) {
            const list = data.data;
            store.dispatch({
              type: 'SET_COMMENTS_LIST',
              list
            });
            return getActivityInfo({ lsid, lang });
          }
        })
        .then(data => {
          if (lsid) {
            const list = data.data.items;
            store.dispatch({
              type: 'SET_ACTIVITY_INFO',
              list
            });
            return getLikeNum({ lsid });
          }
        })
        .then(data => {
          if (lsid) {
            const list = data.data.items;
            store.dispatch({
              type: 'SET_LIKE_NUM',
              list
            });
            resolve();
          }
        })
        .catch(() => {
          res.redirect(postHref);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      weiboShareUrl: '',
      TwitterUrl: '',
      secondaryList: props.secondaryList
    };
  }

  handleScrollTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    const { title } = this.props.activityDetail;

    const { secondaryList } = this.props;

    if (secondaryList.length < 6) {
      this.getSecondary();
    }

    wechatShare({ title });
  }

  renderHeader() {
    let { activityDetail } = this.props;
    let { cover, title, published_at, site, sponsor } = activityDetail;
    return (
      <div className={styles.header_wrapper}>
        <div className={styles.header_left_wrapper}>
          <div className={styles.header_left}>
            <img src={cover} loading="lazy" />
          </div>
        </div>

        <div className={styles.header_right}>
          <div className={styles.content_title_wrapper}>
            <div
              className={styles.content_title}
              style={{ WebkitBoxOrient: 'vertical' }}
            >
              {title}
            </div>
          </div>

          <div className={styles.content_place}>
            <div
              className={styles.content_place_icon}
              style={{
                backgroundImage:
                  'url(https://piccdn.0daily.com/202302/21111212/cxjndmqp1l07ydq2.png)'
              }}
            />
            <div className={styles.content_place_text}>
              {published_at.replace(/-/g, '/')}
            </div>
          </div>

          <div
            className={styles.content_place}
            style={{
              display: site ? null : 'none'
            }}
          >
            <div
              className={styles.content_place_icon}
              style={{
                backgroundImage:
                  'url(https://piccdn.0daily.com/202302/21111212/v7jiwk68klypqfsg.png)'
              }}
            />
            <div
              className={styles.content_place_text}
              style={{ WebkitBoxOrient: 'vertical' }}
            >
              {site}
            </div>
          </div>

          <div
            className={styles.content_place}
            style={{ display: sponsor ? null : 'none' }}
          >
            <div
              className={styles.content_place_icon}
              style={{
                backgroundImage:
                  'url(https://piccdn.0daily.com/202302/21111212/gh8yd4z6gyse6cv7.png)'
              }}
            />
            <div
              className={styles.content_place_text}
              style={{ WebkitBoxOrient: 'vertical' }}
            >
              {sponsor}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getSecondary() {
    let { activityDetail, secondaryList } = this.props;
    let { secondary_tag } = activityDetail;

    if (secondary_tag[1]) {
      getSecondaryList(secondary_tag[1].slug, 1, 6 - secondaryList.length).then(
        data => {
          if (data.code === 0) {
            this.setState({
              secondaryList: secondaryList.concat(data.data.items)
            });
          }
        }
      );
    }
  }

  renderSecondary() {
    let { activityDetail } = this.props;
    let { secondary_tag } = activityDetail;

    return (
      <div className={styles.secondaryList}>
        {secondary_tag.map(item => {
          return (
            <a
              className={styles.secondaryItem}
              key={`secondaryItem${item.name}`}
              href={`/search/${item.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          );
        })}
      </div>
    );
  }

  renderRead() {
    let { activityDetail, match } = this.props;
    let { secondary_tag } = activityDetail;
    let { secondaryList } = this.state;
    const { lang } = match.params;
    return (
      <div className={styles.secondary}>
        <div className={styles.secondary_herder}>
          {locales(lang, 'recommended_reading')}
          <a
            href={`/secondTag/${secondary_tag[0].slug}`}
            target={'_blank'}
            rel="noreferrer"
            onClick={() => {
              dot({ id: 31 });
            }}
          >
            {locales(lang, 'seeMore')}
          </a>
        </div>
        <div className={styles.secondaryList}>
          {secondaryList.map((item, index) => {
            return (
              <div
                className={styles.secondaryList_item}
                key={`secondaryList${index}`}
              >
                <a
                  href={`/post/${item.id}`}
                  target={'_blank'}
                  rel="noreferrer"
                  style={{ WebkitBoxOrient: 'vertical' }}
                >
                  {item.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    let {
      activityDetail,
      liveData,
      commentsList,
      now,
      activityInfo,
      likeNum,
      advertBottom,
      dispatch,
      match
    } = this.props;
    const { lang } = match.params;
    let { live_url, detail, title, secondary_tag } = activityDetail;
    let { secondaryList } = this.state;

    const markup = () => {
      return {
        __html: TransformHtml(detail.content || '')
      };
    };

    let helmetData = getHelmet(
      {
        twitterTitle: `${activityDetail.title}_${locales(lang, 'activity')}`,
        ogTitle: `${activityDetail.title}`,
        ogUrl: `${process.env.ODAILY}${match.url}`
      },
      lang
    );

    return (
      <Layout>
        {/* 管理文档头，可以通过js动态的改变标签内的内容 */}
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* 直播或者图片 */}
            {live_url ? (
              <ActivityLive
                activityDetail={activityDetail}
                liveData={liveData}
                commentsList={commentsList}
                now={now}
                activityInfo={activityInfo}
                likeNum={likeNum}
                dispatch={dispatch}
              />
            ) : (
              this.renderHeader()
            )}
            {/* 直播下面的内容 */}
            <div className={styles.main}>
              <div className={styles.main_left}>
                {/* 活动介绍 */}
                <div className={styles.content}>
                  <div className={styles.content_title}>
                    {locales(lang, 'event_intro')}
                  </div>
                  <div
                    className={styles.content_text}
                    dangerouslySetInnerHTML={markup()}
                  />
                  <div className={styles.tips}>
                    {locales(lang, 'disclaimer_title')}
                    <br />
                    {`${locales(lang, 'odaily_reminder')}${locales(
                      lang,
                      'please_establish_correct_concepts'
                    )}`}
                  </div>
                </div>

                {secondary_tag && secondary_tag.length
                  ? this.renderSecondary()
                  : ''}

                {/* 分享栏 */}
                <div className={styles.share}>
                  <Share title={title} secondary={secondary_tag} />
                </div>

                <div className={styles.container_left_border} />

                {/* 推荐阅读 */}
                {secondaryList && secondaryList.length ? (
                  <div className={styles.postRecommend}>
                    <PostRecommend
                      articlelist={secondaryList}
                      tags={secondary_tag}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className={styles.main_right}>
                {/* 联系我们 */}
                <div className={styles.main_right_box}>
                  <div className={styles.font}>
                    {' '}
                    {locales(lang, 'contactUs')}
                  </div>
                  <div className={styles.buttonList}>
                    {buttonList.map((item, index) => {
                      return (
                        <div className={styles.button} key={`button${index}`}>
                          <i className={styles.button_icon} />
                          {locales(lang, item.title)}
                          <div className={styles.button_box}>
                            <div
                              className={`${styles.img} ${
                                item.borderFlag ? styles.imgBorder : ''
                              }`}
                            >
                              <img src={item.img} loading="lazy" />
                            </div>
                            <p className={styles.button_font}>
                              {locales(lang, item.text)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* 广告 */}
                {advertBottom &&
                advertBottom.length > 0 &&
                advertBottom[0].url &&
                advertBottom[0].cover ? (
                  <div className={styles.advertMiddle}>
                    <AdPicture
                      width="340px"
                      height="226px"
                      url={advertBottom[0].url}
                      img={advertBottom[0].cover}
                      icon={true}
                    />
                  </div>
                ) : (
                  ''
                )}

                <DownLoad />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    activityDetail: state.activity.detail,
    secondaryList: state.activity.secondaryList,
    liveData: state.activity.live,
    commentsList: state.activity.commentsList,
    now: state.activity.time,
    activityInfo: state.activity.info,
    likeNum: state.activity.likeNum,
    advertBottom: state.home.advertBottom
  };
};

export default withRouter(connect(mapStateToProps)(Activity));
