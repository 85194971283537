import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

import Select from 'react-select';
import _ from 'lodash';
import moment from 'moment';

import Toast from '../../../components/common/toast';

import {
  setUserAccountInfo,
  setUserInfo,
  upload
} from '../../../common/api/user';

const fieldValidate = {
  nickname: {
    min: 1,
    max: 16,
    msg: '请确认昵称填写正确'
  },
  introduction: {
    max: 48,
    msg: '请确认简介填写正确'
  }
};

const DEFAULT_AVATAR =
  'https://piccdn.0daily.com/202302/21092129/9md836k12tgpl399.png';

const selectStyles = {
  container: styles => {
    return {
      ...styles,
      marginRight: '12px'
    };
  },
  control: styles => {
    delete styles['&:hover'];
    return {
      ...styles,
      cursor: 'pointer',
      width: '140px',
      height: '40px',
      backgroundColor: '#F5F7FB',
      border: '1px solid #ACAFB2',
      borderRadius: 0
    };
  },
  indicatorSeparator: styles => {
    return {
      ...styles,
      display: 'none'
    };
  },
  menu: styles => {
    return {
      ...styles,
      backgroundColor: '#F5F7FB',
      border: '1px solid #ACAFB2',
      borderRadius: '0',
      marginTop: '0',
      boxShadow: 'none'
    };
  },
  singleValue: styles => {
    return {
      ...styles,
      fontSize: '14px',
      fontWeight: '400',
      color: '#ACAFB2'
    };
  },
  placeholder: styles => {
    return {
      ...styles,
      fontSize: '14px',
      fontWeight: '400',
      color: '#ACAFB2'
    };
  },
  option: (styles, { isSelected }) => {
    delete styles[':active'];
    styles['&:hover'] = {
      backgroundColor: '#E9EBEF'
    };
    return {
      ...styles,
      height: '40px',
      backgroundColor: isSelected ? '#E9EBEF' : '#fff',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 400,
      color: '#333',
      textAlign: 'center',
      lineHeight: '24px'
    };
  }
};

const dateSelectStyles = Object.assign(_.cloneDeep(selectStyles), {
  control: styles => {
    delete styles['&:hover'];
    return {
      ...styles,
      cursor: 'pointer',
      width: '85px',
      height: '40px',
      backgroundColor: '#F5F7FB',
      border: '1px solid #ACAFB2',
      borderRadius: 0
    };
  }
  // indicatorsContainer: styles => {
  //   return {
  //     ...styles,
  //     padding: '14px 20px',
  //     width: 0,
  //     height: 0,
  //     backgroundImage:
  //       'url(https://piccdn.0daily.com/202212/15103357/8dodi9vy41wa5w07.png)',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'center center',
  //     backgroundSize: '10px 8px',
  //     overflow: 'hidden'
  //   };
  // }
});

const YEAR_RANGE = (() => {
  let startYear = 1920;
  const endYear = Number(new Date().getFullYear());
  const yearRange = [];
  while (startYear <= endYear) {
    yearRange.push(startYear);
    startYear++;
  }
  return yearRange;
})();

const MONTH_RANGE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const transArrToOptions = arr => {
  return arr.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const YEAR_OPTIONS = transArrToOptions(YEAR_RANGE);
const MONTH_OPTIONS = transArrToOptions(MONTH_RANGE);
const getDayOptions = (year, month) => {
  const date = new Date(year, month, 0).getDate();
  const dateRange = [];
  for (let index = 1; index <= date; index++) {
    dateRange.push({
      label: index,
      value: index
    });
  }
  return dateRange;
};

const OCCUPATION_LIST = [
  '学生',
  '白领',
  '中层管理者',
  '企业老板',
  '创业者',
  '投资人',
  '自由职业者',
  '其他'
];
const OCCUPATION_OPTIONS = transArrToOptions(OCCUPATION_LIST);

const INDUSTRY_LIST = [
  '互联网',
  'IT',
  '制造业',
  '金融',
  '教育',
  '医疗机构',
  '建筑房地产',
  '传媒',
  '政府机关',
  '其他'
];
const INDUSTRY_OPTIONS = transArrToOptions(INDUSTRY_LIST);

export class BasicInformation extends React.PureComponent {
  static propTypes = {
    nickname: PropTypes.string.isRequired,
    avatar_url: PropTypes.string.isRequired,
    sex: PropTypes.string.isRequired,
    birthday: PropTypes.string.isRequired,
    introduction: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    industry: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  fileInput = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      nickNameFocus: false,
      introductionFocus: false,
      dayOptions: []
    };
  }
  componentDidMount() {}

  uploadButtonHandler() {
    const fileInput = this.fileInput.current;
    fileInput.click();
  }

  fileUploadHandler() {
    upload().then(data => {
      const { policy, signature } = data.data;
      const file = this.fileInput.current.files[0];
      const xhr = new XMLHttpRequest();
      const formdata = new FormData();
      formdata.append('file', file);
      formdata.append('policy', policy);
      formdata.append('signature', signature);
      xhr.open('POST', 'https://v0.api.upyun.com/odaily-krplus-pic', true);
      xhr.send(formdata);
      xhr.addEventListener(
        'load',
        ({ target }) => {
          const resp = JSON.parse(target.response);
          const avatar_url = `https://piccdn.0daily.com/${resp.url}`;
          const user = Object.assign(
            { ...this.props },
            {
              avatar_url
            }
          );
          this.props.dispatch({
            type: 'SET_CURRENT_USER',
            user
          });
          this.fileInput.current.value = '';
        },
        false
      );
    });
  }

  nicknameChange(e) {
    const nickname = e.target.value;
    const user = Object.assign(
      { ...this.props },
      {
        nickname
      }
    );
    this.props.dispatch({
      type: 'SET_CURRENT_USER',
      user
    });
  }

  changeNickNameFocus(boolean) {
    this.setState({
      nickNameFocus: boolean
    });
  }

  genderChange(e) {
    const checkedGender = e.target.value;
    const user = Object.assign(
      { ...this.props },
      {
        sex: checkedGender
      }
    );
    this.props.dispatch({
      type: 'SET_CURRENT_USER',
      user
    });
  }

  birthdayChange(type, value) {
    const props = this.props;
    const formatArr = ['year', 'month', 'day'];
    let birthdayArr = props.birthday
      ? moment(props.birthday)
          .format('YYYY-M-D')
          .split('-')
      : [];

    birthdayArr[formatArr.indexOf(type)] = value;
    this.setState({
      dayOptions: getDayOptions(birthdayArr[0], birthdayArr[1])
    });
    const birthday = birthdayArr.join('/');
    const user = Object.assign(
      { ...this.props },
      {
        birthday
      }
    );

    this.props.dispatch({
      type: 'SET_CURRENT_USER',
      user
    });
  }

  introductionChange(e) {
    const introduction = e.target.value;
    const user = Object.assign(
      { ...this.props },
      {
        introduction
      }
    );
    this.props.dispatch({
      type: 'SET_CURRENT_USER',
      user
    });
  }

  changeIntroductionFocus(boolean) {
    this.setState({
      introductionFocus: boolean
    });
  }

  jobChange(option) {
    const job = option.value;
    const user = Object.assign(
      { ...this.props },
      {
        job
      }
    );
    this.props.dispatch({
      type: 'SET_CURRENT_USER',
      user
    });
  }

  industryChange(option) {
    const industry = option.value;
    const user = Object.assign(
      { ...this.props },
      {
        industry
      }
    );
    this.props.dispatch({
      type: 'SET_CURRENT_USER',
      user
    });
  }

  fieldValidator() {
    return Object.keys(fieldValidate).map(key => {
      const field = fieldValidate[key];
      const value = this.props[key] || '';
      if (field.min && value.length < field.min) {
        Toast.error({
          msg: field.msg
        });
        return false;
      }
      if (field.max && value.length > field.max) {
        Toast.error({
          msg: field.msg
        });
        return false;
      }
      return true;
    });
  }

  saveAccoutSetting() {
    const {
      id,
      birthday,
      sex,
      job,
      introduction,
      industry,
      nickname,
      avatar_url
    } = this.props;
    const userId = id;
    const birthdayData = birthday
      ? moment(birthday).format('YYYY-MM-DD')
      : null;
    if (this.fieldValidator().indexOf(false) >= 0) {
      return false;
    }

    const saveOtherPromise = setUserAccountInfo(userId, {
      sex: sex,
      job: job,
      birthday: birthdayData,
      introduction: introduction,
      industry: industry
    });
    const saveBasePromise = setUserInfo({
      nickName: nickname,
      avatar: avatar_url
    });

    Promise.all([saveBasePromise, saveOtherPromise])
      .then(() => {
        Toast.done({
          msg: '保存成功'
        });
      })
      .catch(data => {
        Toast.error({
          msg: data.msg
        });
      });
  }

  renderStringLenthCheck({ fieldLenth, max }) {
    return (
      <div className={styles.count}>
        <span style={{ color: fieldLenth > max ? '#EC4343' : null }}>
          {fieldLenth}
        </span>
        /{max}
      </div>
    );
  }

  render() {
    const {
      nickname,
      avatar_url,
      sex,
      birthday,
      introduction,
      job,
      industry
    } = this.props;
    const { nickNameFocus, introductionFocus } = this.state;

    const birthdayArr = moment(birthday)
      .format('YYYY-M-D')
      .split('-');
    const defaultBirthdayYearValue =
      YEAR_OPTIONS[YEAR_RANGE.indexOf(Number(birthdayArr[0]))];
    const defaultBirthdayMonth =
      MONTH_OPTIONS[MONTH_RANGE.indexOf(Number(birthdayArr[1]))];
    const dayOptions = getDayOptions(birthdayArr[0], birthdayArr[1]);
    const dayIndex = _.findIndex(dayOptions, {
      label: Number(birthdayArr[2]),
      value: Number(birthdayArr[2])
    });
    const defaultBirthdayDay = dayOptions[dayIndex];
    const defaultJobValue = OCCUPATION_OPTIONS[OCCUPATION_LIST.indexOf(job)];
    const defaultIndustryValue =
      INDUSTRY_OPTIONS[INDUSTRY_LIST.indexOf(industry)];

    return (
      <div className={styles.basicInformation_wrapper}>
        <div
          className={styles.basicInformation_avatar_url}
          style={{
            backgroundImage: `url(${avatar_url ? avatar_url : DEFAULT_AVATAR})`
          }}
        >
          <div
            className={styles.basicInformation_avatar_url_wrapper}
            onClick={this.uploadButtonHandler.bind(this)}
          >
            修改头像
          </div>
          <input
            type="file"
            accept="image/png,image/jpeg"
            ref={this.fileInput}
            onChange={this.fileUploadHandler.bind(this)}
          />
        </div>

        <div className={styles.basicInformation_item}>
          <div className={styles.label}>昵称</div>
          <div
            className={`${styles.nickname} ${
              nickNameFocus ? styles.nickname_active : ''
            }`}
          >
            <input
              type="text"
              defaultValue={nickname}
              onChange={e => {
                this.nicknameChange(e);
              }}
              onFocus={() => {
                this.changeNickNameFocus(true);
              }}
              onBlur={() => {
                this.changeNickNameFocus(false);
              }}
            />
            {this.renderStringLenthCheck({
              fieldLenth: nickname.length,
              max: fieldValidate.nickname.max
            })}
          </div>
        </div>

        <div className={styles.basicInformation_item}>
          <div className={styles.label}>性别</div>
          <div className={styles.radio}>
            <input
              type="radio"
              name="gender"
              id="male"
              value="male"
              onChange={this.genderChange.bind(this)}
              defaultChecked={sex === 'male' ? true : false}
            />
            <label htmlFor="male">男</label>
            <input
              type="radio"
              name="gender"
              id="female"
              value="female"
              onChange={this.genderChange.bind(this)}
              defaultChecked={sex === 'female' ? true : false}
            />
            <label htmlFor="female">女</label>
          </div>
        </div>

        <div className={styles.basicInformation_item}>
          <div className={styles.label}>生日</div>
          <div className={styles.birthday}>
            <Select
              styles={dateSelectStyles}
              defaultValue={defaultBirthdayYearValue}
              options={YEAR_OPTIONS}
              placeholder="年"
              isSearchable={false}
              onChange={({ value }) => this.birthdayChange('year', value)}
            />
            <Select
              styles={dateSelectStyles}
              defaultValue={defaultBirthdayMonth}
              options={MONTH_OPTIONS}
              placeholder="月"
              isSearchable={false}
              onChange={({ value }) => this.birthdayChange('month', value)}
            />
            <Select
              styles={dateSelectStyles}
              defaultValue={defaultBirthdayDay}
              options={dayOptions}
              placeholder="日"
              isSearchable={false}
              onChange={({ value }) => this.birthdayChange('day', value)}
            />
          </div>
        </div>

        <div className={styles.basicInformation_item}>
          <div className={styles.label}>简介</div>
          <div
            className={`${styles.introduction} ${
              introductionFocus ? styles.introduction_active : ''
            }`}
          >
            <textarea
              defaultValue={introduction}
              onChange={this.introductionChange.bind(this)}
              onFocus={() => {
                this.changeIntroductionFocus(true);
              }}
              onBlur={() => {
                this.changeIntroductionFocus(false);
              }}
            />

            {this.renderStringLenthCheck({
              fieldLenth: introduction.length,
              max: fieldValidate.introduction.max
            })}
          </div>
        </div>

        <div className={styles.basicInformation_item}>
          <div className={styles.label}>职业</div>
          <Select
            styles={selectStyles}
            defaultValue={defaultJobValue}
            options={OCCUPATION_OPTIONS}
            placeholder="请选择职业"
            isSearchable={false}
            onChange={this.jobChange.bind(this)}
          />
        </div>

        <div className={styles.basicInformation_item}>
          <div className={styles.label}>行业</div>
          <Select
            styles={selectStyles}
            defaultValue={defaultIndustryValue}
            options={INDUSTRY_OPTIONS}
            placeholder="请选择行业"
            isSearchable={false}
            onChange={this.industryChange.bind(this)}
          />
        </div>

        <div
          className={styles.basicInformation_button}
          onClick={this.saveAccoutSetting.bind(this)}
        >
          保存
        </div>
      </div>
    );
  }
}
