import React, { Component } from 'react';
import styles from '../../index.scss';

export class InfoShareJa extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>情報共有チェックリスト</h5>
        <p>
          当社による個人情報の共有をより明確に理解していただくために、次のリストを作成しました。
        </p>
        <h6>1. サードパーティSDKリスト</h6>
        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>共有</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>微信</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>
                ユーザーが WeChat
                にコンテンツを共有できるように、サードパーティのログイン機能をユーザーに提供します。
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>深センテンセントコンピュータシステム株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>共有</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>シナ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>
                ユーザーが Sina
                にコンテンツを共有できるように、サードパーティのログイン機能をユーザーに提供します。
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>北京威夢創科ネットワーク技術有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>400-096-0960</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://weibo.com/signup/v5/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://weibo.com/signup/v5/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>プッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>押す</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>関連する情報コンテンツをプッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>株式会社デイリーインタラクティブ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>support@getui.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://docs.getui.com/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.getui.com/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>プッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>オーロラ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>関連する情報コンテンツをプッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>深セン市和孫華谷情報技術有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>sales@jiguang.cn</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.jiguang.cn/license/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.jiguang.cn/license/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>プッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>ファーウェイ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>関連する情報コンテンツをプッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>ファーウェイソフトウェアテクノロジー株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>プッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>アワ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>関連する情報コンテンツをプッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>シャオミモバイルソフトウェア株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.mi.com/support"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.mi.com/support
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://dev.mi.com/console/doc/detail?pId=1822"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://dev.mi.com/console/doc/detail?pId=1822
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>プッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>OPPO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>関連する情報コンテンツをプッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>広東環泰科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>
                <a
                  href="https://brand.heytap.com/privacy-feedback.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://brand.heytap.com/privacy-feedback.html
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://open.oppomobile.com/wiki/doc#id=10288"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://open.oppomobile.com/wiki/doc#id=10288
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>プッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>関連する情報コンテンツをプッシュする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>株式会社ビボモバイルコミュニケーション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>privacy@vivo.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.vivo.com.cn/about-vivo/privacy-policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>位置</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>金</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>ユーザーにローカルな情報コンテンツを提供する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報、位置情報許可で GPS 情報を有効にする</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>高徳ソフトウェア株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>Privacy.Amap@service.autonavi.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://lbs.amap.com/pages/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://lbs.amap.com/pages/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>ワンクリックログイン</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>チャイナモバイル</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>認証コード不要のログインサービスをユーザーに提供</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報、ローカル番号</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>中国移動通信集団有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>10086</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="http://dev.10086.cn/docInside?contentId=10000009826805"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  http://dev.10086.cn/docInside?contentId=10000009826805
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>共有</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>
                ユーザーが ODAILY コンテンツを QQ に共有できるように支援します
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>深センテンセントコンピュータシステム株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>統計</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>同盟国</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>データ統計分析</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>友夢同新（北京）科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>Umeng_Legal@service.umeng.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.umeng.com/page/policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.umeng.com/page/policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>統計</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>ファーウェイ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>データ統計分析</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>ファーウェイソフトウェアテクノロジー株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>ログを収集する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>bugly</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>問題を特定できるよう、アプリのクラッシュ情報を収集します。</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>深センテンセントコンピュータシステム株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>機能の種類</p>
            </td>
            <td>
              <p>ワンクリックでアプリをインストール</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名</p>
            </td>
            <td>
              <p>openinstall</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>
                ユーザーが高速ジャンプとワンクリックでアプリをダウンロードしてインストールできるように支援します
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人情報欄</p>
            </td>
            <td>
              <p>デバイス情報</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>加工方法</p>
            </td>
            <td>
              <p>送信と処理に匿名化、暗号化、その他の方法を採用する</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共有方法</p>
            </td>
            <td>
              <p>SDKネイティブコレクション</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>件名</p>
            </td>
            <td>
              <p>深センフェンミャオネットワークテクノロジー株式会社</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>連絡先</p>
            </td>
            <td>
              <p>cooperation@openinstall.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>プライバシーポリシー</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.openinstall.io/privacy.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.openinstall.io/privacy.html
                </a>
              </p>
            </td>
          </tr>
        </table>

        <h6>2. 第三者協力機関一覧</h6>
        <p>
          当社は、当面の間、お客様の個人情報を第三者パートナーと共有することはありませんが、上記の状況が発生した場合には、このリストを更新し、お客様の承認と同意を得ます。
        </p>

        <h6>3. 関係会社一覧</h6>
        <p>
          当社は、当面の間、お客様の個人情報を関連会社と共有することはありませんが、上記の状況が発生した場合には、このリストを更新し、お客様の承認と同意を得ます。
        </p>
      </div>
    );
  }
}

export default InfoShareJa;
