import React from 'react';
import styles from './index.scss';

const list = [
  { title: 'Odaily Website 官网', href: '/' },
  { title: 'Discord Official', href: process.env.DISCORD },
  { title: 'Telegram Chat', href: 'https://t.me/Odaily_CryptoPunk' },
  { title: 'Twitter Official', href: 'https://twitter.com/OdailyChina' },
  { title: '商务合作', children: true }
];

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={styles.menu}>
        <div className={styles.menu_wrapper}>
          <div className={styles.menu_img} />
          <div className={styles.menu_title}>Odaily</div>
          <div className={styles.menu_text}>让一部分人先读懂Web3.0</div>

          <div className={styles.button_list}>
            {list.map((item, index) => {
              if (item.children) {
                return (
                  <div className={styles.button} key={`button${index}`}>
                    {item.title}
                    <div className={styles.menu_box} />
                  </div>
                );
              }
              return (
                <a
                  href={item.href}
                  target={'_blank'}
                  rel="nofollow noopener noreferrer"
                  className={styles.button}
                  key={`button${index}`}
                >
                  {item.title}
                  {item.children ? <div className={styles.menu_box} /> : ''}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
