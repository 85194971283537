import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import styles from './index.scss';
import Layout from '../../components/common/layout';
import { getHelmet } from '../../common/scripts/helmet';

const banner1Png = 'https://piccdn.0daily.com/202407/01000103/banner-1.png';
const banner2Png = 'https://piccdn.0daily.com/202407/01000103/banner-2.png';
const titleBgPng = 'https://piccdn.0daily.com/202407/01000103/title-bg.png';

import timeBoxBgPng from '../../assets/image/fat/2024/time-box-bg.png';
import timeItemBgPng from '../../assets/image/fat/2024/time-item-bg.png';
import timeItemBorderSvg from '../../assets/image/fat/2024/time-item-border.svg';

import itemBg1Png from '../../assets/image/fat/2024/item-bg-1.png';
import itemBg2Png from '../../assets/image/fat/2024/item-bg-2.png';

const item1Png = 'https://piccdn.0daily.com/202407/01000103/item-1.png';
const item2Png = 'https://piccdn.0daily.com/202407/01000103/item-2.png';
const item3Png = 'https://piccdn.0daily.com/202407/01000103/item-3.png';
const item4Png = 'https://piccdn.0daily.com/202407/01000103/item-4.png';
const item5Png = 'https://piccdn.0daily.com/202407/01000103/item-5.png';

import title1Svg from '../../assets/image/fat/2024/title-1.svg';
import title2Svg from '../../assets/image/fat/2024/title-2.svg';
import title3Svg from '../../assets/image/fat/2024/title-3.svg';
import title4Svg from '../../assets/image/fat/2024/title-4.svg';
import title5Svg from '../../assets/image/fat/2024/title-5.svg';

// import offlineTitleSvg from '../../assets/image/fat/2024/offline-title.svg';
import offlineContractSvg from '../../assets/image/fat/2024/offline-contract.svg';
import offline36krPng from '../../assets/image/fat/2024/36kr.png';
// import offlineBgSvg from '../../assets/image/fat/2024/offline-bg.svg';
import locationSvg from '../../assets/image/fat/2024/location.svg';
import timeSvg from '../../assets/image/fat/2024/time.svg';
import themeSvg from '../../assets/image/fat/2024/theme.svg';

const item31Png = 'https://piccdn.0daily.com/202407/01000507/item3-1.png';
const item32Png = 'https://piccdn.0daily.com/202407/01000507/item3-2.png';
import arrowBottomSvg from '../../assets/image/fat/2024/arrow-bottom.svg';
import classNames from 'classnames';
import { FatOver } from './over';

// const offlineItem1Png =
//   'https://piccdn.0daily.com/202407/01000103/offline-item-1.png';
// const offlineItem2Png =
//   'https://piccdn.0daily.com/202407/01000103/offline-item-2.png';
// const offlineItem3Png =
//   'https://piccdn.0daily.com/202407/01000103/offline-item-3.png';
// const offlineItem4Png =
//   'https://piccdn.0daily.com/202407/01000103/offline-item-4.png';
// const offlineItem5Png =
//   'https://piccdn.0daily.com/202407/01000103/offline-item-5.png';
// const offlineItem6Png =
//   'https://piccdn.0daily.com/202407/01000103/offline-item-6.png';

const history2020Png =
  'https://piccdn.0daily.com/202407/01000103/history-2020.png';

// import Swiper from './components/Swiper';

export class Fat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestsExpand: false,
      isOver: true
    };
    this.handleExpandGuests = this.handleExpandGuests.bind(this);
  }

  handleExpandGuests() {
    this.setState({ guestsExpand: true });
  }

  render() {
    let helmetData = getHelmet({
      title: '区块链游戏,区块链新闻资讯,Defi,NFT',
      description:
        '时值岁末，ODAILY将再次携手权威专家学者，开启 2024「FAT」价值时代榜单评选，用最客观的数据、最专业的眼光、最权威的视角呈现区块链行业最具价值榜单的诞生。',
      keywords: '区块链游戏,区块链新闻资讯,Defi,NFT'
    });

    function handleOpenHref(href, target = '_blank') {
      window.open(href, target);
    }

    // const offlineSwiperList = [
    //   { img: offlineItem1Png },
    //   { img: offlineItem2Png },
    //   { img: offlineItem3Png },
    //   { img: offlineItem4Png },
    //   { img: offlineItem5Png },
    //   { img: offlineItem6Png }
    // ];

    return this.state.isOver ? (
      <FatOver />
    ) : (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <img className={styles.bannerImage} alt={''} src={banner1Png}></img>
            <div className={styles.title1}>
              <img className={styles.image} alt={''} src={title1Svg}></img>
            </div>
            <div className={styles.banner2}>
              <img className={styles.image} alt={''} src={banner2Png}></img>
              <div className={styles.text1} style={{ marginTop: '62px' }}>
                暌违三年，盛大回归，由ODAILY发起的「 FAT Awards
                2024」评选正式启动！
              </div>
              <div className={styles.text1} style={{ marginTop: '60px' }}>
                「FAT」是ODAILY于2020年创办的榜单盛典+高峰论坛品牌，旨在嘉奖Web3和加密行业的领航者与创新者、聚焦价值范式与共识时刻，此前曾连续2年在京举办，参与一线项目超过500个，参与人数超过3000人，覆盖人次超过500万。
              </div>
              <div className={styles.text1} style={{ marginTop: '60px' }}>
                2024，值加密行业日新月异、叙事为主流世界认可之际，新鲜叙事涌动、生态百花齐放、优质项目接连上线。
              </div>
              <div className={styles.text2}>
                ODAILY再次开启
                FAT榜单评选，并将于9月在新加坡举办线下盛典，期待您的关注与莅临！
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://lu.ma/yvyh6uch')}
                >
                  <span>活动报名</span>
                </div>
              </div>
            </div>

            <div className={styles.timeBox}>
              <img className={styles.timeBoxBg} alt={''} src={timeBoxBgPng} />
              <div className={styles.item}>
                <div className={styles.top}>
                  <img
                    className={styles.border}
                    alt={''}
                    src={timeItemBorderSvg}
                    style={{ transform: 'rotate(15deg)' }}
                  />
                  <div className={styles.content}>
                    <img
                      className={styles.contentBg}
                      alt={''}
                      src={timeItemBgPng}
                    />
                    <span>报名</span>
                  </div>
                </div>
                <div className={styles.text}>7月中-8月中</div>
              </div>
              <div className={styles.line}></div>
              <div className={styles.item}>
                <div className={styles.top}>
                  <img
                    className={styles.border}
                    alt={''}
                    src={timeItemBorderSvg}
                  />
                  <div className={styles.content}>
                    <img
                      className={styles.contentBg}
                      alt={''}
                      src={timeItemBgPng}
                    />
                    <span>评选</span>
                  </div>
                </div>
                <div className={styles.text}>8月中-9月初</div>
              </div>
              <div className={styles.line}></div>
              <div className={styles.item}>
                <div className={styles.top}>
                  <img
                    className={styles.border}
                    alt={''}
                    src={timeItemBorderSvg}
                    style={{ transform: 'rotate(-15deg)' }}
                  />
                  <div className={styles.content}>
                    <img
                      className={styles.contentBg}
                      alt={''}
                      src={timeItemBgPng}
                    />
                    <span>公布</span>
                  </div>
                </div>
                <div className={styles.text}>9月16日新加坡线下盛典</div>
              </div>
            </div>

            <div className={styles.itemBox3}>
              <div className={styles.imageBox}>
                <img className={styles.image} src={item31Png} alt={''} />
              </div>
              <div className={styles.content}>
                <div className={styles.box1}>
                  <div className={styles.line}>
                    <img className={styles.icon} src={timeSvg} alt={''} />
                    <div className={styles.label}>时间</div>
                    <div className={styles.text}>9月16日（星期一）</div>
                  </div>
                  <div className={styles.line}>
                    <div className={styles.icon}>
                      <img
                        src={locationSvg}
                        alt={''}
                        style={{ width: '25px', height: '32px' }}
                      />
                    </div>
                    <div className={styles.label}>地点</div>
                    <div className={styles.text}>
                      新加坡康莱德酒店（滨海湾）
                    </div>
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://lu.ma/yvyh6uch')}
                >
                  <span className={styles.text}>活动报名</span>
                </div>
                <div className={styles.box2}>
                  <span className={styles.text}>
                    「FAT Awards 2024」
                    将于9月16日在新加坡期间邀请头部机构，一线项目，流量社区，热点生态带着最锐利的视角，最新的趋势一同为Web3.0市场揭晓这个周期最具影响力，最具创造性，最具前瞻性的行业领袖和顶级机构；共同为大家创造一个自由表达，认知互换的社交场合；欢迎加入我们，一起寻找新的密码！
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.itemBox3}>
              <div className={styles.imageBox}>
                <img className={styles.image} src={item32Png} alt={''} />
              </div>
              <div className={styles.content}>
                <div className={styles.box1} style={{ padding: '20px' }}>
                  <div
                    className={styles.line}
                    style={{ height: '70px', alignItems: 'flex-start' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '10px'
                      }}
                    >
                      <img className={styles.icon} src={themeSvg} alt={''} />
                      <div className={styles.label}>主题</div>
                    </div>
                    <div className={styles.text}>
                      Pudgy Penguins TCG 首玩PARTY
                    </div>
                  </div>
                  <div className={styles.line}>
                    <div className={styles.icon}>
                      <img
                        src={locationSvg}
                        alt={''}
                        style={{ width: '25px', height: '32px' }}
                      />
                    </div>
                    <div className={styles.label}>地点</div>
                    <div className={styles.text}>
                      新加坡康莱德酒店（滨海湾）
                    </div>
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://lu.ma/etvqe70c')}
                >
                  <span className={styles.text}>活动报名</span>
                </div>
                <div className={styles.box2} style={{ padding: '42px 15px' }}>
                  <span className={styles.text}>
                    The Pudgy Penguins TCG 将于 9 月 16 日举行第 1
                    版卡牌预售线下活动。活动期间开放线上报名订购和线下购买第 1
                    版卡牌的资格。所有现场参会者还将获得活动限定卡牌。
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.guestsBox} style={{ display: 'none' }}>
              <div className={styles.guestsHeader}>
                <div className={styles.dot}></div>
                <div className={styles.content}>
                  <div className={styles.text}>嘉宾</div>
                  <div className={styles.text2}>Guests</div>
                </div>
                <div className={styles.dot}></div>
              </div>
              <div
                className={classNames(
                  styles.guestsList,
                  this.state.guestsExpand ? styles.expand : null
                )}
              >
                {[...Array(12)].map((_, index) => (
                  <div className={styles.item} key={index}>
                    <img
                      className={styles.image}
                      src={itemBg1Png}
                      alt={''}
                    ></img>
                    <div className={styles.name}>
                      <div className={styles.text}>Lily</div>
                    </div>
                    <div className={styles.subName}>Solana Foundation</div>
                  </div>
                ))}
              </div>
              {this.state.guestsExpand ? null : (
                <div className={styles.guestsFooter}>
                  <div
                    className={styles.iconBox}
                    onClick={this.handleExpandGuests}
                  >
                    <img
                      className={styles.icon}
                      src={arrowBottomSvg}
                      alt={''}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className={styles.title} style={{ marginTop: '130px' }}>
              <div className={styles.line}></div>
              <div className={styles.titleBg}>
                <img className={styles.img} alt={''} src={titleBgPng}></img>
                <img className={styles.text} alt={''} src={title2Svg}></img>
              </div>
              <div className={styles.line}></div>
            </div>
            <div className={styles.itemBox1}>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item1Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>20大活跃投资机构</div>
                  <div className={styles.text2}>
                    Top 20 Active Venture Capitals
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/ZGVZAY')}
                >
                  <span>报名</span>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item2Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>20大金融服务机构</div>
                  <div className={styles.text2}>Top 20 CeFi Institutions</div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/oDn554')}
                >
                  <span>报名</span>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item3Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>20大基础设施</div>
                  <div className={styles.text2}>
                    Top 20 Infrastructure Providers
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/hBbPd5')}
                >
                  <span>报名</span>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item4Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>20大增量应用</div>
                  <div className={styles.text2}>
                    Top 20 Projects Driving Mass Adoption
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/EbycAv')}
                >
                  <span>报名</span>
                </div>
              </div>
            </div>

            <div className={styles.title} style={{ marginTop: '140px' }}>
              <div className={styles.line}></div>
              <div className={styles.titleBg}>
                <img className={styles.img} alt={''} src={titleBgPng}></img>
                <img className={styles.text} alt={''} src={title3Svg}></img>
              </div>
              <div className={styles.line}></div>
            </div>
            <div className={styles.itemBox1}>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item1Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>Solana生态20大项目</div>
                  <div className={styles.text2}>
                    Top 20 Projects in Solana Ecosystem
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/y1oGjF')}
                >
                  <span>报名</span>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item2Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>比特币生态20大项目</div>
                  <div className={styles.text2}>
                    Top 20 Projects in Bitcoin Ecosystem
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/DdKNYq')}
                >
                  <span>报名</span>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item3Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>Ton生态20大项目</div>
                  <div className={styles.text2}>
                    Top 20 Projects in TON Ecosystem
                  </div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/oDLkoj')}
                >
                  <span>报名</span>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item4Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>AI+Crypto 20大项目</div>
                  <div className={styles.text2}>Top 20 AI+Crypto Projects</div>
                </div>
                <div
                  className={styles.btn}
                  onClick={() => handleOpenHref('https://jsj.top/f/ETdaB3')}
                >
                  <span>报名</span>
                </div>
              </div>
            </div>

            <div className={styles.title} style={{ marginTop: '140px' }}>
              <div className={styles.line}></div>
              <div className={styles.titleBg}>
                <img className={styles.img} alt={''} src={titleBgPng}></img>
                <img className={styles.text} alt={''} src={title4Svg}></img>
              </div>
              <div className={styles.line}></div>
            </div>
            <div className={styles.itemBox2}>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg2Png}></img>
                <img className={styles.img} alt={''} src={item5Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>最受社区欢迎50大项目</div>
                  <div className={styles.text2}>
                    Top 50 Community-Favorite Projects
                  </div>
                </div>
                <div className={styles.btn__group}>
                  <div
                    className={styles.btn}
                    onClick={() => handleOpenHref('https://jsj.top/f/FO3nI3')}
                  >
                    <span>报名</span>
                  </div>
                  <div
                    className={styles.btn}
                    onClick={() => handleOpenHref('/fat2024vote/18', '_self')}
                  >
                    <span>投票</span>
                  </div>
                </div>
              </div>
              <div className={styles.item}>
                <img className={styles.bgImg} alt={''} src={itemBg2Png}></img>
                <img className={styles.img} alt={''} src={item5Png}></img>
                <div className={styles.content}>
                  <div className={styles.text1}>最受社区欢迎50大KOL</div>
                  <div className={styles.text2}>
                    Top 50 Community-Favorite KOLs
                  </div>
                </div>
                <div className={styles.btn__group}>
                  <div
                    className={styles.btn}
                    onClick={() => handleOpenHref('https://jsj.top/f/ssxuHJ')}
                  >
                    <span>报名</span>
                  </div>
                  <div
                    className={styles.btn}
                    onClick={() => handleOpenHref('/fat2024vote/19', '_self')}
                  >
                    <span>投票</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.title} style={{ marginTop: '140px' }}>
              <div className={styles.line}></div>
              <div className={styles.titleBg}>
                <img className={styles.img} alt={''} src={titleBgPng}></img>
                <img className={styles.text} alt={''} src={title5Svg}></img>
              </div>
              <div className={styles.line}></div>
            </div>
            <div className={styles.itemBox2}>
              <div
                className={styles.item}
                style={{ justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenHref('/post/5198005')}
              >
                <img className={styles.bgImg} alt={''} src={itemBg2Png}></img>
                <img className={styles.img} alt={''} src={item5Png}></img>
                <div className={styles.content} style={{ marginTop: '0' }}>
                  <div className={styles.text1}>2024十大风向叙事</div>
                  <div className={styles.text2}>Top 10 Narratives of 2024</div>
                </div>
              </div>
            </div>
            <div className={styles.itemBox1}>
              <div
                className={styles.item}
                style={{ justifyContent: 'center', cursor: 'pointer' }}
              >
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item1Png}></img>
                <div className={styles.content} style={{ marginTop: '0' }}>
                  <div className={styles.text1}>2024 十大影响力X推文</div>
                  <div className={styles.text2}>
                    Top 10 Influential X Tweets of 2024
                  </div>
                </div>
              </div>
              <div
                className={styles.item}
                style={{ justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenHref('/post/5198006')}
              >
                <img className={styles.bgImg} alt={''} src={itemBg1Png}></img>
                <img className={styles.img} alt={''} src={item2Png}></img>
                <div className={styles.content} style={{ marginTop: '0' }}>
                  <div className={styles.text1}>2024十大拐点事件</div>
                  <div className={styles.text2}>
                    Top 10 Turning Point Events
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.offlineBox}>
              {/*<img className={styles.boxBg} alt={''} src={offlineBgSvg}></img>*/}
              {/*<img*/}
              {/*  className={styles.titleImg}*/}
              {/*  alt={''}*/}
              {/*  src={offlineTitleSvg}*/}
              {/*></img>*/}
              {/*<div className={styles.content1}>*/}
              {/*  <div className={styles.content1Left}>*/}
              {/*    <div className={styles.text}>*/}
              {/*      「FAT Awards*/}
              {/*      2024」将于9月在新加坡TOKEN2049期间举办为大家创造一个前所未有的自由表达、平等交流的社交圈和能量场。*/}
              {/*    </div>*/}
              {/*    <div className={styles.text}>*/}
              {/*      我们将邀请Web3行业顶级投资机构、热门项目、以及榜单获奖者——这些最饱有蓬勃动力、最富有想象力和最具有创造力的Web3坚守者一起寻找新的机遇。*/}
              {/*    </div>*/}
              {/*    <div className={styles.text}>*/}
              {/*      时间地点后续揭晓，敬请期待。*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className={styles.content1Right}>*/}
              {/*    <Swiper*/}
              {/*      list={offlineSwiperList}*/}
              {/*      width={470}*/}
              {/*      height={314}*/}
              {/*      unit={'px'}*/}
              {/*      interval={5000}*/}
              {/*      speed={500}*/}
              {/*      activeIndex={0}*/}
              {/*      paginationFlag={false}*/}
              {/*      autoTimerFlag={true}*/}
              {/*      arrowFlag={true}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className={styles.line}></div>*/}
              <div className={styles.line}></div>
              <div className={styles.content2}>
                <div className={styles.row}>
                  <div className={styles.label}>招商请联系</div>
                  <div className={styles.dot}>：</div>
                  <div className={styles.text}>market@odaily.email</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>媒体合作请联系</div>
                  <div className={styles.dot}>：</div>
                  <div className={styles.text}>yanglin@odaily.email</div>
                </div>
              </div>
              <div className={styles.line}></div>
              <div className={styles.contract}>
                <img
                  className={styles.img}
                  alt={''}
                  src={offlineContractSvg}
                ></img>
                <img
                  className={styles.img2}
                  alt={''}
                  src={offline36krPng}
                ></img>
              </div>
            </div>

            <a
              href={'/activityed2020'}
              target={'_blank'}
              rel="noopener noreferrer"
              className={styles.historyBox}
            >
              <img
                className={styles.historyImg}
                alt={''}
                src={history2020Png}
              />
            </a>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Fat);
