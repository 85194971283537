import React, { Component } from 'react';
import styles from '../../index.scss';

export class InfoShareVi extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>Danh sách kiểm tra chia sẻ thông tin</h5>
        <p>
          Để giúp bạn hiểu rõ hơn về việc chúng tôi chia sẻ thông tin cá nhân,
          danh sách sau đây đã được xây dựng:
        </p>
        <h6>1. Danh sách SDK của bên thứ ba</h6>
        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>chia sẻ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>Wechat</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>
                Cung cấp cho người dùng chức năng đăng nhập của bên thứ ba để
                giúp người dùng chia sẻ nội dung lên WeChat
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Hệ thống máy tính Tencent Thâm Quyến</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>chia sẻ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>Sina</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>
                Cung cấp cho người dùng chức năng đăng nhập của bên thứ ba để
                giúp người dùng chia sẻ nội dung lên Sina
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Bắc Kinh Weimeng Chuangke Network Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>400-096-0960</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://weibo.com/signup/v5/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://weibo.com/signup/v5/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>đẩy</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>xô</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Đẩy nội dung thông tin liên quan</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Tương tác hàng ngày</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>support@getui.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://docs.getui.com/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.getui.com/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>đẩy</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>rạng Đông</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Đẩy nội dung thông tin liên quan</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Công nghệ Thông tin Thâm Quyến Hexun Huagu</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>sales@jiguang.cn</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.jiguang.cn/license/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.jiguang.cn/license/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>đẩy</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>Huawei</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Đẩy nội dung thông tin liên quan</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Công nghệ Phần mềm Huawei</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>đẩy</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>Cây kê</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Đẩy nội dung thông tin liên quan</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Phần mềm Di động Xiaomi</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.mi.com/support"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.mi.com/support
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://dev.mi.com/console/doc/detail?pId=1822"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://dev.mi.com/console/doc/detail?pId=1822
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>đẩy</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>OPPO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Đẩy nội dung thông tin liên quan</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Quảng Đông Huantai Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>
                <a
                  href="https://brand.heytap.com/privacy-feedback.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://brand.heytap.com/privacy-feedback.html
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://open.oppomobile.com/wiki/doc#id=10288"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://open.oppomobile.com/wiki/doc#id=10288
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>đẩy</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Đẩy nội dung thông tin liên quan</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Truyền thông Di động Vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>privacy@vivo.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.vivo.com.cn/about-vivo/privacy-policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>chức vụ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>Vàng</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Cung cấp cho người dùng nội dung thông tin địa phương</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị và bật thông tin GPS theo quyền vị trí</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH phần mềm Gaode</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>Privacy.Amap@service.autonavi.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://lbs.amap.com/pages/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://lbs.amap.com/pages/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>Đăng nhập bằng một cú nhấp chuột</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>điện thoại Trung Quốc</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>
                Cung cấp cho người dùng dịch vụ đăng nhập không cần mã xác minh
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị, số địa phương</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Tập đoàn Truyền thông Di động Trung Quốc</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>10086</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="http://dev.10086.cn/docInside?contentId=10000009826805"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  http://dev.10086.cn/docInside?contentId=10000009826805
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>chia sẻ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Giúp người dùng chia sẻ nội dung ODAILY lên QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Hệ thống máy tính Tencent Thâm Quyến</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>Số liệu thống kê</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>đồng minh</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Phân tích thống kê dữ liệu</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Công nghệ Youmeng Tongxin (Bắc Kinh)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>Umeng_Legal@service.umeng.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.umeng.com/page/policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.umeng.com/page/policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>Số liệu thống kê</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>Huawei</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>Phân tích thống kê dữ liệu</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Công nghệ Phần mềm Huawei</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>thu thập nhật ký</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>bugly</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>
                Thu thập thông tin sự cố Ứng dụng để chúng tôi có thể xác định
                sự cố
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Hệ thống máy tính Tencent Thâm Quyến</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>loại chức năng</p>
            </td>
            <td>
              <p>Cài đặt ứng dụng bằng một cú nhấp chuột</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên SDK</p>
            </td>
            <td>
              <p>openinstall</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>mục đích sử dụng</p>
            </td>
            <td>
              <p>
                Giúp người dùng nhận ra bước nhảy nhanh và tải xuống và cài đặt
                Ứng dụng bằng một cú nhấp chuột
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>trường thông tin cá nhân</p>
            </td>
            <td>
              <p>Thông tin thiết bị</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chế biến</p>
            </td>
            <td>
              <p>
                Áp dụng phương pháp khử nhận dạng, mã hóa và các phương pháp
                khác để truyền và xử lý
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>phương pháp chia sẻ</p>
            </td>
            <td>
              <p>Bộ sưu tập gốc SDK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>tên chủ đề</p>
            </td>
            <td>
              <p>Công ty TNHH Công nghệ Mạng Fenmiao Thâm Quyến</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thông tin liên lạc</p>
            </td>
            <td>
              <p>cooperation@openinstall.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Chính sách bảo mật</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.openinstall.io/privacy.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.openinstall.io/privacy.html
                </a>
              </p>
            </td>
          </tr>
        </table>

        <h6>2. Danh sách các tổ chức hợp tác bên thứ ba</h6>
        <p>
          Hiện tại, chúng tôi chưa chia sẻ thông tin cá nhân của bạn với các đối
          tác bên thứ ba. Nếu trường hợp trên xảy ra, chúng tôi sẽ cập nhật danh
          sách này và xin phép và đồng ý của bạn.
        </p>

        <h6>3. Danh sách công ty trực thuộc</h6>
        <p>
          Chúng tôi tạm thời chưa chia sẻ thông tin cá nhân của bạn với các công
          ty liên kết, nếu trường hợp trên xảy ra, chúng tôi sẽ cập nhật danh
          sách này và xin phép và đồng ý của bạn.
        </p>
      </div>
    );
  }
}

export default InfoShareVi;
