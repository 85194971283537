import React from 'react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { dot } from '../../../common/scripts/dot';
import locales from '../../../utils/locales';

export class ActivityList extends React.Component {
  static defaultProps = {
    list: []
  };
  static propTypes = {
    list: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    buttonFlag: PropTypes.boolean,
    filterNum: PropTypes.number,
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { title, href, list, filterNum, buttonFlag, match } = this.props;
    const { lang } = match.params;
    return (
      <div className={styles.container}>
        <div
          className={styles.activityList_header}
          style={{ display: title === null ? 'none' : null }}
        >
          <div className={styles.activityList_title}>{title}</div>
          <Link
            target="_blank"
            to={{ pathname: href }}
            onClick={() => {
              dot({ extra: href.slice(14), id: 29 });
            }}
            style={{ display: buttonFlag ? null : 'none' }}
          >
            更多
          </Link>
        </div>

        <div className={styles.activityList}>
          {list
            .filter((item, index) => {
              if (filterNum) {
                return index < 4;
              } else {
                return item;
              }
            })
            .map((item, index) => {
              return (
                <div
                  className={styles.activityList_item}
                  key={`activityList${index}`}
                >
                  <a
                    href={`/activity/${item.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.item_wrapper}
                  >
                    <div className={styles.item_img}>
                      <img src={item.cover} alt={item.title} loading="lazy" />

                      <div className={styles.item_button}>
                        {item.activity_status === 'no_started'
                          ? locales(lang, 'notStarted')
                          : item.activity_status === 'starting'
                          ? locales(lang, 'ongoing')
                          : locales(lang, 'ended')}
                      </div>

                      <div className={styles.item_time}>
                        <i className={styles.item_time_icon} />
                        {typeof item.published_at === 'string'
                          ? item.published_at.replace(/-/g, '/').slice(0, 16)
                          : item.published_at}
                      </div>
                    </div>

                    <div className={styles.item_title_wrapper}>
                      <div
                        className={styles.item_title}
                        style={{ WebkitBoxOrient: 'vertical' }}
                      >
                        {item.title}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default withRouter(ActivityList);
