import http from '../scripts/http';

export function getVideoById(id, lang) {
  return http.get(`/video/${id}`, { lang });
}

export function videoLike(videoId) {
  return http.post(`/like`, { ltype: 'video', lid: videoId });
}

export function videoCancelLike(videoId) {
  return http.delete(`/like?ltype=video&lid=${videoId}`);
}

export function videoCollect(videoId) {
  return http.post(`/favorite`, {
    ftype: 'video',
    fid: videoId,
    direction: 'plus'
  });
}

export function videoUnfollow(videoId) {
  return http.post(`/favorite`, {
    ftype: 'video',
    fid: videoId,
    direction: 'minus'
  });
}

export function getLikeInfo(videoId) {
  return http.get(`/like-status?ltype=video&lid=${videoId}`);
}

export function getCollectInfo(videoId) {
  return http.get(`/favorite-status?ftype=video&fid=${videoId}`);
}
