import { combineReducers } from 'redux';

const marketData = (state = [], action) => {
  switch (action.type) {
    case 'SET_MARKET_DATA':
      return action.list;
    default:
      return state;
  }
};

const marketList = (state = [], action) => {
  switch (action.type) {
    case 'SET_MARKET_LIST':
      return action.list;
    default:
      return state;
  }
};

const marketIdData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_MARKET_DETAIL':
      return action.list;
    default:
      return state;
  }
};

const marketIdList = (state = [], action) => {
  switch (action.type) {
    case 'SET_MARKET_IDLIST':
      return action.list;
    default:
      return state;
  }
};

const marketDetail = (state = [], action) => {
  switch (action.type) {
    case 'SET_COIN_DETAIL':
      return action.list;
    default:
      return state;
  }
};

const marketOptional = (state = [], action) => {
  switch (action.type) {
    case 'SET_OPTIONAL':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  marketData,
  marketList,
  marketIdData,
  marketIdList,
  marketDetail,
  marketOptional
});
