import _ from 'lodash';

const newsflash = (state = {}, action) => {
  const { date, list, id, vote, flag } = action;
  let newList = state[date] || [];
  let ary = newList.filter(item => {
    return item.id === list.id;
  });

  if (ary.length === 0) {
    if (flag === 'front') {
      newList = _.concat(list, state[date] || []);
    } else {
      newList = _.concat(state[date] || [], list);
    }
  }

  const changeVoteList =
    state[date] &&
    state[date].map(item => {
      if (item && item.id === id) {
        const oldVote = item.voted_type;
        const newVote = vote;
        if (oldVote === 1 && newVote === 0) {
          item.counters.bull_vote--;
        }
        if (oldVote === 2 && newVote === 0) {
          item.counters.bad_vote--;
        }
        if (oldVote === 0 && newVote === 1) {
          item.counters.bull_vote++;
        }
        if (oldVote === 0 && newVote === 2) {
          item.counters.bad_vote++;
        }
        if (oldVote === 2 && newVote == 1) {
          item.counters.bull_vote++;
          item.counters.bad_vote--;
        }
        if (oldVote === 1 && newVote == 2) {
          item.counters.bull_vote--;
          item.counters.bad_vote++;
        }
        item.voted_type = newVote;
      }
      return item;
    });
  const changeCollection =
    state[date] &&
    state[date].map(item => {
      if (item && item.id === id) {
        item.is_favorite = !item.is_favorite;
      }
      return item;
    });

  switch (action.type) {
    case 'SET_NEWS_FLASH':
      return {
        ...state,
        [date]: newList
      };
    case 'SET_NEWSFLASH_VOTE':
      return {
        ...state,
        [date]: changeVoteList
      };
    case 'SET_NEWSFLASH_DETAIL':
      return {
        ...state,
        detail: action.detail
      };
    case 'SET_NEWSFLASH_COLLECTION':
      return {
        ...state,
        [date]: changeCollection
      };
    case 'SET_NEWSFLASH_EMPTY':
      return {};
    default:
      return state;
  }
};

export default newsflash;
