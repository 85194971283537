import { service } from '../scripts/http';

export function craeteOrg({ name, organization, intro, avatar }) {
  const data = {
    name,
    organization,
    intro,
    avatar
  };
  return service.post('/ugc/enter', data);
}

export function checkOrgStatus(id) {
  return service.get(`/ugc/detection/${id}`);
}
