import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './index.scss';
import moment from 'moment';

import Share from '../../common/share/ListShare';
import ImageView from '../../common/imageView';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';
const getTime = time => moment(time).format('HH:mm');

export class NewsFlashItem extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    cover: PropTypes.string,
    news_url: PropTypes.string,
    news_url_type: PropTypes.string,
    pin: PropTypes.oneOf([0, 1]).isRequired,
    share_data: PropTypes.object,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  renderNewsUrl() {
    let linkText;
    const { lang } = this.props.match.params;
    switch (this.props.news_url_type) {
      case 'news_url':
        linkText = locales(lang, 'orig_Link');
        break;
      case 'product_url':
        linkText = locales(lang, 'related_articles');
        break;
      default:
        linkText = locales(lang, 'related_links');
        break;
    }
    return (
      <a
        href={this.props.news_url}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className={styles.newsFlashItem_news_url}
      >
        <i className={styles.newsFlashItem_news_url_icon} />
        {linkText}
      </a>
    );
  }

  render() {
    const {
      published_at,
      title,
      id,
      description,
      news_url,
      cover,
      share_data,
      pin
    } = this.props;
    let content = description.replace(/(\r\n|\n|\r|↵)/gm, '<br />');

    return (
      <div className={styles.newsFlashItem}>
        <div className={styles.newsFlashItem_time}>{getTime(published_at)}</div>

        <div className={styles.newsFlashItem_content}>
          <a href={`/newsflash/${id}`} target={'_blank'} rel="noreferrer">
            <div
              className={styles.newsFlashItem_title}
              style={{ color: pin ? '#EE4C53' : null }}
            >
              {title}
              {pin ? <div className={styles.newsFlashItem_title_icon} /> : ''}
            </div>
            <div
              className={styles.newsFlashItem_description}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </a>
          {news_url ? this.renderNewsUrl() : ''}
          {cover ? (
            <div className={styles.newsFlashItem_cover}>
              <img
                onClick={() => ImageView.show({ src: cover, alt: title })}
                src={cover}
                alt={title}
                loading="lazy"
              />
            </div>
          ) : (
            ''
          )}

          <div
            className={
              news_url || cover ? styles.share : styles.share_description
            }
          >
            {share_data ? <Share {...share_data.default} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(NewsFlashItem));
