import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

import ImgDefaultAvatar from '../../assets/image/default-author-avatar.png';

export default class Author extends React.PureComponent {
  static propTypes = {
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    postid: PropTypes.string.isRequired,
    userPostList: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired
  };
  state = {
    previous: 0,
    fans: 0,
    post_create: 0,
    posts: []
  };
  renderPostList() {
    let { userPostList, postid } = this.props;
    return userPostList
      .filter(item => {
        return item.id != postid;
      })
      .map((item, index) => {
        if (index < 3) {
          return (
            <div className={styles.post} key={`post${item.entity_id}`}>
              <a
                href={
                  item.entity_type === 'video'
                    ? `/video/${item.entity_id}`
                    : `/post/${item.entity_id}`
                }
                style={{ WebkitBoxOrient: 'vertical' }}
              >
                {item.title}
              </a>
            </div>
          );
        }
      });
  }

  render() {
    const { avatar, name, title, id } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.container_header}>
          <a className={styles.container_left} href={`/user/${id}`}>
            <div className={styles.container_avatar}>
              <img
                src={avatar ? avatar : ImgDefaultAvatar}
                alt=""
                loading="lazy"
              />
            </div>
            <div className={styles.container_name}>{name}</div>
            <div className={styles.container_border} />
            <div className={styles.container_title}>{title}</div>
          </a>

          <a className={styles.container_button} href={`/user/${id}`} />
        </div>

        <div className={styles.container_list}>{this.renderPostList()}</div>
      </div>
    );
  }
}
