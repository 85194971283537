import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.scss';

export class SearchGlobal extends React.PureComponent {
  static propTypes = {
    showSearch: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }
  keywordsHandler(e) {
    const keywords = e.target.value;
    if (e.key === 'Enter') {
      window.location = `/search/${keywords}`;
    }
  }
  hideGlobalSearch() {
    this.props.dispatch({
      type: 'SET_SEARCH_HIDE'
    });
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.showSearch) {
      this.searchInput.current.focus();
    }
  }
  render() {
    const showStyles = this.props.showSearch ? styles.show : '';

    return (
      <div className={`${styles.container} ${showStyles}`}>
        <div className={styles.header}>
          <div className={styles.search}>
            <i className={styles['icon-search']} />
            <input
              ref={this.searchInput}
              className={styles.keywords}
              type="text"
              onKeyDown={this.keywordsHandler.bind(this)}
            />
            <i
              className={styles['icon-close']}
              onClick={this.hideGlobalSearch.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showSearch: state.global.search
  };
};

export default connect(mapStateToProps)(SearchGlobal);
