import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getCurrentUser, logout } from '../../../common/api/user';
import styles from './index.scss';

const ImgLogo =
  'https://piccdn.0daily.com/202212/21032217/ttp81gzi4mkj4dpg.png';
const Img3 = 'https://piccdn.0daily.com/202302/16100122/zp6xu4z5vigs32px.png';
const Img4 = 'https://piccdn.0daily.com/202212/02064231/xbkyj2eiarf76sgh.png';
import { dot } from '../../../common/scripts/dot';
import cookies from 'js-cookie';
import Login from '../login';
import { loginFlag } from '../../../common/scripts/login';
import locales from '../../../utils/locales';

import FatSvg from '../../../assets/image/fat/2024/fat.svg';

const NAV_LINKS = [
  // {
  //   name: 'home',
  //   href: 'javascript:;',
  //   id: 25,
  //   children: []
  // },
  {
    name: 'information',
    href: '/',
    id: 26,
    children: [
      {
        path: '/newsflash',
        id: 4,
        name: 'flash'
      },
      {
        path: '/topic',
        id: 5,
        name: 'special'
      }
      // {
      //   path: '/video',
      //   id: 6,
      //   name: '视频'
      // }
    ],
    childrenWidth: '88px',
    childrenBackground:
      'https://piccdn.0daily.com/202302/22075451/baab67q07dd10bij.png'
  },
  // {
  //   name: '数据',
  //   id: 1,
  //   href: '/marketinfo',
  //   children: [
  //     {
  //       path: '/marketinfo',
  //       id: 7,
  //       name: '行情'
  //     },
  //     {
  //       path: '/DeFi',
  //       id: 8,
  //       name: 'DeFi'
  //     }
  //   ]
  // },
  {
    name: 'activity',
    href: '/activityPage',
    id: 2
  },
  {
    name: 'navigation',
    href: '/navigation',
    icon: 'https://piccdn.0daily.com/202210/13103853/ilefba6rlx9d70rv.png'
  },
  {
    name: 'Odata',
    href: 'https://odata.info'
    // id: 2
  },
  {
    name: 'FAT 2024',
    href: '/fat2024',
    id: 26
  },
  {
    name: 'Odaily RSS/API',
    href: 'https://github.com/ODAILY',
    igronlang: true,
    attr: { rel: 'nofollow' },
    style: {
      color: '#164491'
    }
    // id: 2
  }
  // {
  //   name: '更多',
  //   id: 4,
  //   href: '/activityed2020',
  //   children: [
  //     {
  //       path: '/activityed2020',
  //       id: 10,
  //       name: 'FAT榜单'
  //     },
  //     {
  //       path: '/research',
  //       id: 12,
  //       name: '星球研究院'
  //     },
  //     {
  //       path: '/chain',
  //       id: 11,
  //       name: '产业区块链'
  //     }
  //   ],
  //   childrenBackground:
  //     'https://piccdn.0daily.com/202302/22075451/21fkhruc4x8a8xrv.png'
  // }
];

const DEFAULT_AVATAR = require('../../../assets/image/default-author-avatar.png');

class Header extends React.Component {
  static propTypes = {
    cookies: PropTypes.object,
    history: PropTypes.object,
    currentUser: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    changeSearchFlag: PropTypes.func,
    lang: PropTypes.string,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPathname: '',
      searchList: [],
      // searchFlag: 0,
      searchData: '',
      timer: 10000,
      searchFlag: false
    };
  }

  componentDidMount() {
    if (cookies.get('kr_plus_id')) {
      this.getCurrentUserData();
    }
  }

  changeSearchFlag() {
    this.props.changeSearchFlag();
  }

  logout() {
    logout().finally(() => {
      window.location.reload();
    });
  }

  renderSigned() {
    const { avatar_url, role_id } = this.props.currentUser;
    const { lang } = this.props.match.params;
    return (
      <div className={styles['signed']}>
        <div className={styles['entry']}>
          <div className={styles['avatar']}>
            <img alt="" src={avatar_url || DEFAULT_AVATAR} loading="lazy" />
          </div>
        </div>
        <div
          className={styles.menu_wrapper}
          style={{
            width: !lang || lang == 'zhtw' ? '86px' : '160px',
            left: !lang || lang == 'zhtw' ? '10px' : '-20px'
          }}
        >
          <div
            className={styles['menu']}
            style={{
              padding: !lang || lang == 'zhtw' ? '0px 5px' : '0px 10px'
            }}
          >
            <ul className={styles['menu-list']}>
              <li className={styles['menu-item']}>
                <a href="/my">{locales(lang, 'personal_center')}</a>
              </li>
              {role_id === 1 ||
              role_id === 3 ||
              role_id === 4 ||
              role_id === 6 ||
              role_id === 388 ? (
                <li className={styles['menu-item']}>
                  <a
                    target={'_blank'}
                    href={process.env.ADMIN}
                    rel="noreferrer"
                  >
                    {locales(lang, 'jump_to_backend')}
                  </a>
                </li>
              ) : (
                ''
              )}
              <li className={styles['menu-item']}>
                <a href="javascript:;" onClick={this.logout.bind(this)}>
                  {locales(lang, 'logout')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderUnsigned(lang) {
    const { dispatch } = this.props;
    return (
      <div
        className={styles['button-signin']}
        onClick={() => {
          loginFlag(dispatch, true);
        }}
      >
        {locales(lang, 'signin')}
      </div>
    );
  }

  getCurrentUserData() {
    getCurrentUser().then(data => {
      const userData = data.data;
      this.props.dispatch({
        type: 'SET_CURRENT_USER',
        user: userData
      });
    });
  }

  showGlobalSearch() {
    this.props.dispatch({
      type: 'SET_SEARCH_SHOW'
    });
  }

  goHome() {
    const { lang } = this.props.match.params;
    const url = lang ? `/${lang}/` : '/';
    location.replace(url);
  }

  goSearch() {
    let { searchData, searchList, searchFlag } = this.state;

    dot({
      id: 25,
      user_id: this.props.currentUser.id,
      extra: {
        search_content: searchData !== '' ? searchData : searchList[searchFlag]
      }
    });
    if (searchData !== '') {
      window.open(`/search/${searchData}`);
    } else {
      window.open(`/search/${searchList[searchFlag]}`);
    }
  }

  getSearchData(e) {
    this.setState({ searchData: e.target.value });
  }

  renderNavLinks(lang) {
    return NAV_LINKS.map((link, index) => {
      return (
        <div className={styles.nav_item_wrapper} key={`nav-item${index}`}>
          {link.href === '/fat2024' ? (
            <div className={styles['nav-item']}>
              <a
                href={link.href}
                target={'_blank'}
                rel="noreferrer"
                className={styles.fat2024_item}
              >
                <img className={styles.img} src={FatSvg} alt={''} />
                <div className={styles.content}>
                  <div className={styles.text}>2024</div>
                  <div className={styles.tag}>New</div>
                </div>
              </a>
            </div>
          ) : (
            <div className={styles['nav-item']}>
              <a
                style={link.style || {}}
                href={
                  link.igronlang
                    ? link.href
                    : lang
                    ? `/${lang}${link.href}`
                    : link.href
                }
                target={index === 0 ? null : '_blank'}
                rel="noreferrer"
                // onClick={() => {
                //   index === 0 && this.goHome();
                //   dot({ id: link.id, user_id: this.props.currentUser.id });
                // }}
              >
                {locales(lang, link.name)}
              </a>

              {link.children ? (
                <div
                  className={styles['nav-item-list-wrapper']}
                  // style={{
                  //   width: link.childrenWidth ? link.childrenWidth : null
                  // }}
                >
                  <div
                    className={styles['nav-item-list']}
                    style={{
                      backgroundImage: link.childrenBackground
                        ? `url(${link.childrenBackground})`
                        : null
                    }}
                  >
                    {link.children.map((item, index) => {
                      return (
                        <a
                          href={lang ? `/${lang}${item.path}` : item.path}
                          target={'_blank'}
                          className={styles['nav-item-name']}
                          key={`nav-item${index}`}
                          rel="noreferrer"
                          onClick={() => {
                            dot({
                              id: item.id,
                              user_id: this.props.currentUser.id
                            });
                          }}
                        >
                          {locales(lang, item.name)}
                        </a>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {index === NAV_LINKS.length - 1 ? (
            ''
          ) : (
            <div
              className={styles['nav_item_border']}
              style={
                lang == 'vi' || lang == 'en' || lang == 'ja' || lang == 'th'
                  ? { margin: '0 10px' }
                  : {}
              }
            />
          )}
        </div>
      );
    });
  }

  render() {
    // const { searchFlag } = this.state;
    const { currentUser } = this.props;
    const { lang: routeLang } = this.props.match.params;
    const lang = routeLang;
    return (
      <div className={styles.container}>
        <Login />
        <div className={styles.inner}>
          <div className={styles.inner_left}>
            <h1 className={styles.logo}>
              <a
                href="#"
                onClick={() => {
                  this.goHome();
                }}
              >
                <img
                  alt=""
                  className={styles.logoImg}
                  src={ImgLogo}
                  loading="lazy"
                />
              </a>
            </h1>
            <div
              className={styles['nav-list']}
              style={
                lang == 'en'
                  ? { marginLeft: '40px' }
                  : lang == 'ja'
                  ? { marginLeft: '10px' }
                  : lang == 'ko'
                  ? { marginLeft: '100px' }
                  : lang == 'vi'
                  ? { marginLeft: '10px' }
                  : lang == 'th'
                  ? { marginLeft: '90px' }
                  : { marginLeft: '60px' }
              }
            >
              {this.renderNavLinks(lang)}
            </div>
          </div>

          <div className={styles.inner_right}>
            <div
              className={styles.inner_icon_Img4}
              style={{
                marginRight:
                  lang == 'ja' || lang == 'vi' || lang == 'ko' ? '15px' : '40px'
              }}
              onClick={() => {
                this.changeSearchFlag(true);
              }}
            >
              <img src={Img4} className={styles.img4} loading="lazy" />
              {locales(lang, 'search')}
            </div>

            <div
              className={styles.inner_icon}
              style={{
                marginRight:
                  lang == 'ja' || lang == 'vi' || lang == 'ko' ? '15px' : '40px'
              }}
            >
              <div className={styles.img3_box}>
                <img src={Img3} className={styles.img3} loading="lazy" />
                {locales(lang, 'download')}
              </div>

              <div className={styles.appBox_wrapper}>
                <div
                  className={styles.appBox}
                  style={{
                    width:
                      lang == 'ja' || lang == 'vi' || lang == 'th'
                        ? '330px'
                        : '288px'
                  }}
                >
                  <div className={styles.appBox_left}>
                    <p className={styles.appBox_title}>
                      {locales(lang, 'downloadOdaily')}
                    </p>
                    <p className={styles.appBox_font}>
                      {locales(lang, 'help')}
                    </p>
                    <div className={styles.appBox_button}>
                      <a
                        className={styles.appBox_button_ios}
                        href="https://itunes.apple.com/cn/app/id1380052002?mt=8"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                      <a
                        className={styles.appBox_button_android}
                        href="https://a.app.qq.com/o/simple.jsp?pkgname=com.odaily.news"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </div>
                  </div>
                  <div className={styles.appBox_right} />
                </div>
              </div>
            </div>

            <div className={styles['account-control']}>
              {currentUser && currentUser.id
                ? this.renderSigned()
                : this.renderUnsigned(lang)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cookies: state.cookies,
    currentUser: state.user.current,
    lang: state.lang.langType
  };
};

export default withRouter(connect(mapStateToProps)(Header));
