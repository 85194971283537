exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-activityPage--container-index {\n  width: 100%;\n  background: #fff;\n  padding: 40px 0 100px; }\n  .src-pages-activityPage--container-index .src-pages-activityPage--wrapper-index {\n    width: 1200px;\n    margin: 0 auto; }\n    .src-pages-activityPage--container-index .src-pages-activityPage--wrapper-index .src-pages-activityPage--carousel-index {\n      width: 100%; }\n    .src-pages-activityPage--container-index .src-pages-activityPage--wrapper-index .src-pages-activityPage--activityPage_list_wrapper-index {\n      margin-top: 40px; }\n      .src-pages-activityPage--container-index .src-pages-activityPage--wrapper-index .src-pages-activityPage--activityPage_list_wrapper-index .src-pages-activityPage--activityPage_list-index {\n        width: 100%;\n        margin-bottom: 30px; }\n", ""]);

// exports
exports.locals = {
	"container": "src-pages-activityPage--container-index",
	"wrapper": "src-pages-activityPage--wrapper-index",
	"carousel": "src-pages-activityPage--carousel-index",
	"activityPage_list_wrapper": "src-pages-activityPage--activityPage_list_wrapper-index",
	"activityPage_list": "src-pages-activityPage--activityPage_list-index"
};