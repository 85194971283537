/**
 * 语言跨域设置通用方法
 * @param sourceuri
 * @param target
 * @returns {{}}
 */
export const languageArr = () => {
  // pm2环境 和 本地环境 process.env.LANGUAGE 值有差异
  let language_arr = [];
  const language_str = process.env.LANGUAGE;
  if (typeof language_str === 'object') {
    return language_arr;
  }
  if (language_str.startsWith('[')) {
    language_arr = eval(language_str);
  } else {
    language_arr = language_str.split(',');
  }
  return language_arr;
};

export const pathRewrite = function(sourceuri, target) {
  let ret = {};
  const arr_languages = languageArr();
  const languages = arr_languages.toString().replace(/,/g, '|');
  const languages_api_rgx = `^/(${languages})${sourceuri}`;
  ret[languages_api_rgx] = `/$1${target}`;
  return ret;
};

export const getLanguage = function(url) {
  let lang = '';
  const language = languageArr();
  // 查找当前路径下 是否有语言标识符
  for (let idx = 0; idx < language.length; idx++) {
    if (
      url.startsWith('/' + language[idx] + '/') ||
      url == '/' + language[idx]
    ) {
      lang = language[idx];
      break;
    }
  }
  return lang;
};
