import http from '../scripts/http';
import { service } from '../scripts/http';

export function getFeedsByChannelId(channelId, bId = '', perPage = 20, lang) {
  return http.get(
    `/app-front/feed-stream?feed_id=${channelId}&b_id=${bId}&per_page=${perPage}`,
    { lang: lang }
  );
}

export function getFeedsByResearch(channelId, page, perPage = 3) {
  return http.get(
    `/search-column/${channelId}?page=${page}&per_page=${perPage}`
  );
}

export function getmonographic_video() {
  return service.get(`/monographic_video`);
}
