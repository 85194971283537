import React, { Component } from 'react';
import styles from '../../index.scss';

export class ApplicationTh extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>แอปพลิเคชันอนุญาตแอปพลิเคชันและคำแนะนำการใช้งาน</h5>
        <p>
          1.
          เพื่อให้มั่นใจถึงการใช้งานฟังก์ชันและการทำงานที่ปลอดภัยและเสถียรของผลิตภัณฑ์
          ODAILYApp เราอาจสมัครหรือใช้การอนุญาตที่เกี่ยวข้องของระบบปฏิบัติการ
        </p>
        <p>
          2. เพื่อปกป้องสิทธิของคุณที่จะรับรู้
          เราจะแสดงการอนุญาตระบบปฏิบัติการที่เกี่ยวข้องซึ่งผลิตภัณฑ์อาจนำไปใช้และใช้งานผ่านรายการต่อไปนี้
          และคุณสามารถจัดการการอนุญาตที่เกี่ยวข้องตามความต้องการที่แท้จริงของคุณ
        </p>
        <p>
          3. ตามการอัปเกรดผลิตภัณฑ์
          ประเภทและวัตถุประสงค์ของแอปพลิเคชันและสิทธิ์การใช้งานอาจเปลี่ยนแปลงได้
          และเราจะปรับเปลี่ยนรายการตามการเปลี่ยนแปลงเหล่านี้โดยทันที
          เพื่อให้แน่ใจว่าคุณได้รับแจ้งเกี่ยวกับแอปพลิเคชันและการใช้สิทธิ์อย่างทันท่วงที
        </p>
        <p>
          4.
          โปรดทราบว่าสำหรับความต้องการด้านฟังก์ชันและความปลอดภัยของธุรกิจและผลิตภัณฑ์ของเรา
          เราอาจใช้ SDK ของบุคคลที่สาม
          และบุคคลที่สามเหล่านี้อาจสมัครหรือใช้การอนุญาตของระบบปฏิบัติการที่เกี่ยวข้อง
          สำหรับรายละเอียด โปรดดูส่วนที่ห้า ส่วนหนึ่งของ
          &quot;นโยบายความเป็นส่วนตัว ODAILY&quot;
          &quot;รายการแบ่งปันข้อมูล&quot; ที่จัดทำโดยบทความ;
        </p>
        <p>
          5. ในกระบวนการใช้ผลิตภัณฑ์ คุณอาจใช้หน้า H5
          หรือแอพเพล็ตที่พัฒนาโดยบุคคลที่สาม
          และปลั๊กอินหรือแอพเพล็ตเหล่านี้ที่พัฒนาโดยบุคคลที่สามอาจสมัครหรือใช้การอนุญาตระบบปฏิบัติการที่เกี่ยวข้องตามความจำเป็นสำหรับการทำงานทางธุรกิจ
        </p>
        <p>
          6. ข้อมูลต่อไปนี้ใช้กับแอป ODAILY
          และหากมีสถานการณ์หรือวัตถุประสงค์ที่เกี่ยวข้องแยกต่างหาก
          จะอธิบายไว้ในรายการต่อไปนี้
        </p>

        <h6>รายการแอปพลิเคชันอนุญาตแอปพลิเคชัน Andriod</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>ชื่อสิทธิ์</h6>
              </td>
              <td>
                <h6>คำอธิบายสิทธิ์</h6>
              </td>
              <td>
                <h6>ใช้สถานการณ์หรือวัตถุประสงค์</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>อ่านหน่วยความจำภายนอก</p>
              </td>
              <td>
                <p>ใช้สำหรับผู้ใช้อัปโหลดรูปภาพ ไฟล์ ฯลฯ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>เขียนไปยังหน่วยความจำภายนอก</p>
              </td>
              <td>
                <p>
                  ใช้สำหรับผู้ใช้ในการบันทึกรูปภาพและบันทึกข้อมูลข้อขัดข้องในเครื่อง
                  (ถ้ามี)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MOUNT_UNMOUNT_FILESYSTEMS</p>
              </td>
              <td>
                <p>เมานต์/ถอนเมาต์ระบบไฟล์ภายนอก</p>
              </td>
              <td>
                <p>ใช้สำหรับผู้ใช้อัปโหลดรูปภาพ ไฟล์ ฯลฯ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_PHONE_STATE</p>
              </td>
              <td>
                <p>อ่านสถานะโทรศัพท์</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อเข้าถึงข้อมูลประจำตัวของอุปกรณ์โทรศัพท์มือถือและข้อมูลอื่น
                  ๆ
                  การอนุญาตนี้ไม่สามารถตรวจสอบหรือรับเนื้อหาและข้อมูลการโทรของคุณ
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAMERA</p>
              </td>
              <td>
                <p>ใช้กล้อง</p>
              </td>
              <td>
                <p>ใช้สำหรับผู้ใช้ในการถ่ายภาพเมื่อเปลี่ยนอวาตาร์</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_ALERT_WINDOW</p>
              </td>
              <td>
                <p>หน้าต่างลอยระบบ</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อแสดงให้ผู้ใช้ดูที่หน้าต่างลอยในภายหลังเมื่อผู้ใช้ออกจากการเรียกดูบทความ
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_OVERLAY_WINDOW</p>
              </td>
              <td>
                <p>หน้าต่างระดับบนสุดของระบบ</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อให้ผู้ใช้รับการแจ้งเตือนแบบพุชในเวลาที่เหมาะสมเมื่อใช้แอป
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>INTERNET</p>
              </td>
              <td>
                <p>เข้าถึงเครือข่าย</p>
              </td>
              <td>
                <p>สำหรับเครือข่ายอุปกรณ์</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_NETWORK_STATE</p>
              </td>
              <td>
                <p>รับข้อมูลสถานะเครือข่าย</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อเล่นหรือหยุดวิดีโอชั่วคราวตามสถานะเครือข่าย
                  (เซลลูลาร์, wifi) เพื่อประหยัดปริมาณการใช้ข้อมูลของผู้ใช้
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_WIFI_STATE</p>
              </td>
              <td>
                <p>รับข้อมูลสถานะ Wifi ปัจจุบัน</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อเล่นหรือหยุดวิดีโอชั่วคราวตามสถานะเครือข่าย
                  (เซลลูลาร์, wifi) เพื่อประหยัดปริมาณการใช้ข้อมูลของผู้ใช้
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_WIFI_STATE</p>
              </td>
              <td>
                <p>เปลี่ยนสถานะ WiFi</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อช่วยเหลือ GPS ในการระบุตำแหน่ง
                  การอนุญาตนี้ไม่สามารถรับบัญชี wifi และรหัสผ่านของคุณได้
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_NETWORK_STATE</p>
              </td>
              <td>
                <p>อนุญาตให้โปรแกรมเปลี่ยนสถานะของเครือข่าย</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อให้บริการที่ดีขึ้นแก่ผู้ใช้เมื่อเครือข่ายไม่เสถียร
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_LOGS</p>
              </td>
              <td>
                <p>อ่าน syslog</p>
              </td>
              <td>
                <p>ใช้เพื่อรวบรวมข้อมูลบันทึกข้อผิดพลาดของแอปพลิเคชัน</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_SETTINGS</p>
              </td>
              <td>
                <p>เขียนการตั้งค่าระบบ</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อซิงโครไนซ์การแจ้งเตือนระบบ ความสว่าง
                  และการตั้งค่าอื่นๆ
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_SETTINGS</p>
              </td>
              <td>
                <p>อ่านการตั้งค่าระบบ</p>
              </td>
              <td>
                <p>
                  ใช้สำหรับผู้ใช้เพื่อเปลี่ยนการแจ้งเตือนของระบบ ความสว่าง
                  และการตั้งค่าอื่นๆ
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MODIFY_AUDIO_SETTINGS</p>
              </td>
              <td>
                <p>แก้ไขการตั้งค่าเสียง</p>
              </td>
              <td>
                <p>ใช้เพื่อปรับระดับเสียงขณะรับชมวิดีโอ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WAKE_LOCK</p>
              </td>
              <td>
                <p>อนุญาตให้ทำงานต่อไปบนหน้าจอล็อก</p>
              </td>
              <td>
                <p>เมื่อใช้เพื่อดูวิดีโอ หน้าจอจะไม่ล็อกโดยอัตโนมัติ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECEIVE_BOOT_COMPLETED</p>
              </td>
              <td>
                <p>อนุญาตให้เริ่มอัตโนมัติ</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อปรับปรุงอัตราการมาถึงของการพุชข้อความ
                  การอนุญาตนี้ไม่สามารถเปิดใช้งานโดยอัตโนมัติภายใต้เงื่อนไขใดๆ
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VIBRATE</p>
              </td>
              <td>
                <p>อนุญาตให้โปรแกรมใช้การสั่นสะเทือน</p>
              </td>
              <td>
                <p>
                  ใช้การสั่นเพื่อเตือนผู้ใช้เมื่อได้รับการผลักดันที่สำคัญหรือการโต้ตอบบางส่วน
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FOREGROUND_SERVICE</p>
              </td>
              <td>
                <p>อนุญาตให้ใช้บริการเบื้องหน้า</p>
              </td>
              <td>
                <p>
                  เมื่อใช้เพื่อเล่นเนื้อหาเสียง
                  ป้องกันการหยุดชะงักของบริการในขณะที่ผู้ใช้กำลังฟังเสียง
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECORD_AUDIO</p>
              </td>
              <td>
                <p>ใช้ไมโครโฟน</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อเข้าถึงและเปิดธุรกิจ ODAILY
                  และใช้เสียงเพื่อบันทึกโน้ตในบางสถานการณ์
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MANAGE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>อนุญาตให้เข้าถึงไฟล์ทั้งหมด</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อเข้าถึงและเปิดธุรกิจ ODAILY
                  ผู้ใช้บันทึกรูปภาพและบันทึกข้อมูลบันทึกข้อผิดพลาดในเครื่อง
                  (ถ้ามี)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FLASHLIGHT</p>
              </td>
              <td>
                <p>อนุญาตให้เข้าถึงแฟลช</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อเตือนผู้ใช้ด้วยไฟกะพริบเมื่อได้รับการผลักดันที่สำคัญ
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_INSTALL_PACKAGES</p>
              </td>
              <td>
                <p>ติดตั้งสิทธิ์จากแหล่งที่ไม่รู้จัก</p>
              </td>
              <td>
                <p>
                  สำหรับการติดตั้งอัปเดตของ ODAILYApp
                  การอนุญาตนี้ไม่สามารถติดตั้งแอปพลิเคชันอื่นได้
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECIEVE_MCS_MESSAGE</p>
              </td>
              <td>
                <p>การเข้าถึงเครือข่ายเต็มรูปแบบ</p>
              </td>
              <td>
                <p>ในการเข้าถึงเครือข่าย</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CALL_PHONE</p>
              </td>
              <td>
                <p>เริ่มต้น UI การโทรโดยไม่ต้องผ่านแป้นหมุนหมายเลข</p>
              </td>
              <td>
                <p>สำหรับการโทรออก</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_FINE_LOCATION</p>
              </td>
              <td>
                <p>ได้รับอนุญาตข้อมูลสถานที่</p>
              </td>
              <td>
                <p>เพื่อรับตำแหน่ง</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_IGNORE_BATTERY_OPTIMIZATIONS</p>
              </td>
              <td>
                <p>รับสิทธิ์การเพิ่มประสิทธิภาพแบตเตอรี่</p>
              </td>
              <td>
                <p>ใช้เพื่อเพิ่มประสิทธิภาพการใช้งานแบตเตอรี่</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_SHORTCUT</p>
              </td>
              <td>
                <p>อัปเดตสิทธิ์</p>
              </td>
              <td>
                <p>สำหรับการอัพเดทสิทธิ์</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_BADGE</p>
              </td>
              <td>
                <p>อนุญาตการแจ้งเตือนข้อความ</p>
              </td>
              <td>
                <p>สำหรับข้อความพุช</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_COUNT</p>
              </td>
              <td>
                <p>อัปเดตปริมาณการอนุญาต</p>
              </td>
              <td>
                <p>ใช้เพื่อรับจำนวนการอัพเดท</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_BADGE</p>
              </td>
              <td>
                <p>อัปเดตการแจ้งเตือนทาง SMS</p>
              </td>
              <td>
                <p>ใช้เพื่อรับเนื้อหา SMS</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_BADGE</p>
              </td>
              <td>
                <p>ใช้สิทธิ์ป้ายเดสก์ท็อป</p>
              </td>
              <td>
                <p>ใช้เพื่อแสดงข้อมูลเครื่องหมายมุม</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>GET_TASKS</p>
              </td>
              <td>
                <p>อนุญาตให้แอปพลิเคชันรับกระบวนการปัจจุบัน</p>
              </td>
              <td>
                <p>เพื่อรับกระบวนการปัจจุบัน</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH</p>
              </td>
              <td>
                <p>รับบลูทูธ</p>
              </td>
              <td>
                <p>สำหรับใช้งานบลูทูธ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH_ADMIN</p>
              </td>
              <td>
                <p>รับสิทธิ์บลูทูธ</p>
              </td>
              <td>
                <p>ใช้เพื่อขออนุญาตบลูทูธ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_COARSE_LOCATION</p>
              </td>
              <td>
                <p>รับข้อมูลตำแหน่งโดยประมาณ</p>
              </td>
              <td>
                <p>ใช้เพื่อรับข้อมูลตำแหน่ง</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MIPUSH_RECEIVE</p>
              </td>
              <td>
                <p>รับ Xiaomi Push</p>
              </td>
              <td>
                <p>ใช้เพื่อรับ Xiaomi push</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_ADDED</p>
              </td>
              <td>
                <p>ยอมรับการออกอากาศโดยปริยาย</p>
              </td>
              <td>
                <p>เพื่อรับการออกอากาศ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_CHANGED</p>
              </td>
              <td>
                <p>ฟังการเปลี่ยนแปลงแอปพลิเคชัน</p>
              </td>
              <td>
                <p>ใช้เพื่อดูการเปลี่ยนแปลงของแอปพลิเคชัน</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_INSTALL</p>
              </td>
              <td>
                <p>การติดตั้งแอปพลิเคชัน</p>
              </td>
              <td>
                <p>สำหรับติดตั้งแอพพลิเคชั่น</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_REPLACED</p>
              </td>
              <td>
                <p>การเปลี่ยนแอปพลิเคชัน</p>
              </td>
              <td>
                <p>ใช้เพื่อแทนที่แอปพลิเคชันเวอร์ชันเก่า</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RESTART_PACKAGES</p>
              </td>
              <td>
                <p>รีสตาร์ทแอปพลิเคชัน</p>
              </td>
              <td>
                <p>สำหรับการรีสตาร์ทแอปพลิเคชัน</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MESSAGE</p>
              </td>
              <td>
                <p>การเข้าถึงข้อมูล</p>
              </td>
              <td>
                <p>เพื่อรับข้อมูล</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>C2D_MESSAGE</p>
              </td>
              <td>
                <p>รับข้อความ C2D</p>
              </td>
              <td>
                <p>ใช้เพื่อรับข้อมูล C2D</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>รายการแอปพลิเคชันการอนุญาตแอป iOS</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>ชื่อสิทธิ์</h6>
              </td>
              <td>
                <h6>คำอธิบายสิทธิ์</h6>
              </td>
              <td>
                <h6>ใช้สถานการณ์หรือวัตถุประสงค์</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-CameraUsageDescription</p>
              </td>
              <td>
                <p>ใช้กล้อง</p>
              </td>
              <td>
                <p>ใช้สำหรับผู้ใช้ในการถ่ายภาพเมื่อเปลี่ยนอวาตาร์</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-MicrophoneUsageDescription</p>
              </td>
              <td>
                <p>ใช้ไมโครโฟน</p>
              </td>
              <td>
                <p>
                  ใช้เพื่อเข้าถึงและเปิดธุรกิจ ODAILY
                  และใช้เสียงเพื่อบันทึกโน้ตในบางสถานการณ์
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryAdditionsUsageDescription</p>
              </td>
              <td>
                <p>เพิ่มเนื้อหาในอัลบั้ม</p>
              </td>
              <td>
                <p>สำหรับผู้ใช้ในการบันทึกภาพ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryUsageDescription</p>
              </td>
              <td>
                <p>อ่านเนื้อหาของอัลบั้ม</p>
              </td>
              <td>
                <p>สำหรับผู้ใช้ในการอัปโหลดภาพแทนตัว</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ApplicationTh;
