export const fat2024RankData = {
    'rank1': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Animoca Brands.png',
        'name': 'Animoca Brands'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ABCDE Capital的副本.png',
        'name': 'ABCDE Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ArkStream Capital.png',
        'name': 'ArkStream Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/a16zcrypto.png',
        'name': 'a16z crypto'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Big Brain Holdings.png',
        'name': 'Big Brain Holdings'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Binance Labs.png',
        'name': 'Binance Labs'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Cypher Capital.png',
        'name': 'Cypher Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Cogitent Ventures.png',
        'name': 'Cogitent Ventures'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/DWF Labs.png',
        'name': 'DWF Labs'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Foresight Ventures.png',
        'name': 'Foresight Ventures'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/GSR.png',
        'name': 'GSR'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Hashkey Capital.png',
        'name': 'Hashkey Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/LD Capital.png',
        'name': 'LD Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/1kx.png',
        'name': ' 1Kx'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/OKX Ventures.png',
        'name': 'OKX Ventures'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Paradigm.png',
        'name': 'Paradigm'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Polychain Capital.png',
        'name': 'Polychain Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/RockTree logo 2024 (1) 1.png',
        'name': 'RockTree Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/SevenX Ventures   的副本.png',
        'name': 'SevenX Ventures'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Spartan Group.png',
        'name': 'Spartan Group'
      }
    ],
    'rank2': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Amber Group.png',
        'name': 'Amber Group'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/AscendEX.jpg',
        'name': 'AscendEX'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Binance.png',
        'name': 'Binance'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Bitget.png',
        'name': 'Bitget'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Bitmex.png',
        'name': 'BitMEX'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Bybit.png',
        'name': 'Bybit'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Bitfinex.png',
        'name': 'Bitfinex'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/BingX.png',
        'name': 'BingX'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Cobo.jpg',
        'name': 'Cobo'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Coinbase.png',
        'name': 'Coinbase'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/CoinW.png',
        'name': 'CoinW'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Hashkey Group.png',
        'name': 'Hashkey Group'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/HTX.jpg',
        'name': 'HTX'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Gate.png',
        'name': 'Gate.io'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Kucoin.png',
        'name': 'Kucoin'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Matrixport.png',
        'name': 'Matrixport'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Metastone Group.png',
        'name': 'MetaStone Group'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/OKX.png',
        'name': 'OKX'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/WOO生态.png',
        'name': 'WOO生态'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/XT.com Futures.png',
        'name': 'XT.COM'
      }
    ],
    'rank3': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Axelar Network.jpg',
        'name': 'Axelar Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Avalanche.png',
        'name': 'Avalanche'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Arbitrum.png',
        'name': 'Arbitrum'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Aptos.jpg',
        'name': 'Aptos'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Blast.jpg',
        'name': 'Blast'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Base.jpg',
        'name': 'Base'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Chainlink.png',
        'name': 'Chainlink'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Cosmos.png',
        'name': 'Cosmos'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/dYdX.png',
        'name': 'dYdX'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/deBridge.jpg',
        'name': 'deBridge'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ETH.png',
        'name': 'ETH'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Filecoin.jpg',
        'name': 'Filecoin'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Galxe.png',
        'name': 'Galxe'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/LayerZero.png',
        'name': 'LayerZero'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Movement.jpg',
        'name': 'Movement'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Manta Network.png',
        'name': 'Manta Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Metis.png',
        'name': 'Metis'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Mantle.jpg',
        'name': 'Mantle'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Moonbeam Network.jpg',
        'name': 'Moonbeam Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/StarkWare.jpg',
        'name': 'StarkWare'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Solana.jpg',
        'name': 'Solana'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Stacks.jpg',
        'name': 'Stacks'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Scroll.jpg',
        'name': 'Scroll'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Sui.png',
        'name': 'Sui'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Taiko.png',
        'name': 'Taiko'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/TRON.png',
        'name': 'Tron'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/TON.png',
        'name': 'TON'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Wormhole.jpg',
        'name': 'Wormhole'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ZKsync.jpg',
        'name': 'ZKsync'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ZetaChain.jpg',
        'name': 'ZetaChain'
      }
    ],
    'rank4': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ARPA Network.jpg',
        'name': 'ARPA Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/dappOS.jpg',
        'name': 'dappOS'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/DoraHacks.jpg',
        'name': 'DoraHacks'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/DMAIL.jpg',
        'name': 'DMAIL.AI'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/EigenLayer.jpg',
        'name': 'EigenLayer'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ether.fi.png',
        'name': 'Ether.fi'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Ethena.png',
        'name': 'Ethena'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Farcaster.jpg',
        'name': 'Farcaster'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/FSL Ecosystem.png',
        'name': 'FSL Ecosystem'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Jupiter.jpg',
        'name': 'Jupiter'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Kelp DAO .png',
        'name': 'Kelp DAO'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ListaDAO.png',
        'name': 'ListaDAO'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Magic Eden.jpg',
        'name': 'Magic Eden'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Matr1x .jpg',
        'name': 'Matr1x'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Mocaverse .jpg',
        'name': 'Mocaverse'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/MixMob.png',
        'name': 'MixMob'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Nyan Heroes.png',
        'name': 'Nyan Heroes'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Ondo Finance.jpg',
        'name': 'Ondo Finance'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Polymarket.png',
        'name': 'Polymarket'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Pendle.jpg',
        'name': 'Pendle'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Pump.fun.jpg',
        'name': 'pump.fun'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Rocket Pool.jpg',
        'name': 'Rocket Pool'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Renzo.jpg',
        'name': 'Renzo'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Skate.jpg',
        'name': 'Skate'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Story Protocol.jpg',
        'name': 'Story Protocol'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Seraph.jpg',
        'name': 'Seraph'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/UXLINK.webp',
        'name': 'UXLINK'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Xterio Chain.jpg',
        'name': 'Xterio'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Zeus Network.jpg',
        'name': 'Zeus Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Zircuit.jpg',
        'name': 'Zircuit'
      }
    ],
    'rank5': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Backpack.jpg',
        'name': 'Backpack'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/BlazeStake.jpg',
        'name': 'BlazeStake'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Drift.jpeg',
        'name': 'Drift'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/io.net.jpg',
        'name': 'io.net'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Jito.png',
        'name': 'Jito'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Jupiter .jpg',
        'name': 'Jupiter'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Kamino.png',
        'name': 'Kamino'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Meteora.jpg',
        'name': 'Meteora'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Mango Markets.png',
        'name': 'Mango Markets'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Marginfi.jpg',
        'name': 'marginfi'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Orca.jpg',
        'name': 'Orca'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Phantom.jpg',
        'name': 'Phantom'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Pyth.jpg',
        'name': 'Pyth Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Raydium.jpg',
        'name': 'Raydium'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Solayer.jpg',
        'name': 'Solayer'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Solana Name Service(SNS).jpg',
        'name': 'SNS'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Sanctum.jpg',
        'name': 'Sanctum'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Tensor .jpg',
        'name': 'Tensor'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Zeta Markets.jpg',
        'name': 'Zeus Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Zeus Network.jpg',
        'name': 'Zeta Markets'
      }
    ],
    'rank6': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/B² Network.png',
        'name': 'B² Network.'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/BOB.jpg',
        'name': 'BOB'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Bitlayer.jpg',
        'name': 'Bitlayer'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/BEVM.jpg',
        'name': 'BEVM'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Babylon.jpg',
        'name': 'Babylon'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/BounceBit.jpg',
        'name': 'BounceBit'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/BitFi.jpg',
        'name': 'BitFi'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/bitSmiley.jpg',
        'name': 'bitSmiley'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Fractal Bitcoin.jpg',
        'name': 'Fractal Bitcoin'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Lnfi Network.jpg',
        'name': 'Lnfi Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Lightning Network.png',
        'name': 'Lightning Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Merlin Chain.jpg',
        'name': 'Merlin Chain'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/MVC.jpg',
        'name': 'MVC'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Nubit.jpg',
        'name': 'Nubit'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Ordinals.png',
        'name': 'Ordinals'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Runes.jpg',
        'name': 'Runes'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Solv Protocol.jpg',
        'name': 'Solv Protocol'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Stacks.jpg',
        'name': 'Stacks'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/UniSat.jpg',
        'name': 'UniSat'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Xverse.jpg',
        'name': 'Xverse'
      }
    ],
    'rank7': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Blum.jpg',
        'name': 'Blum'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Banana Gun.jpg',
        'name': 'Banana Gun'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Catizen.jpg',
        'name': 'Catizen'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/DOGS.jpg',
        'name': 'DOGS'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Hamster Kombat.jpg',
        'name': 'Hamster Kombat'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Hipo Finance.jpg',
        'name': 'Hipo Finance'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/MomoAI.jpg',
        'name': 'MomoAI'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Notcoin.jpg',
        'name': 'Notcoin'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Pixelverse.jpg',
        'name': 'Pixelverse'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/PAAL AI.jpg',
        'name': 'PAAL AI'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Storm Trade.jpg',
        'name': 'Storm Trade'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/STON.fi.jpg',
        'name': 'STON.fi'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/SnakeTON.jpg',
        'name': 'SnakeTON'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Tonkeeper.jpg',
        'name': 'Tonkeeper'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/TonSwap.jpg',
        'name': 'TonSwap'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Tonstarter.jpg',
        'name': 'Tonstarter'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Tonhub.jpg',
        'name': 'Ton Hub'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Telegram Wallet.png',
        'name': 'Telegram Wallet'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Tonviewer.jpg',
        'name': 'Tonviewer'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/TONpad.jpg',
        'name': 'TONpad'
      }
    ],
    'rank8': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Aethir.jpg',
        'name': 'Aethir'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Arkham.jpg',
        'name': 'Arkham'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/ASI.jpg',
        'name': 'ASI'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Bittensor.jpg',
        'name': 'Bittensor'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Chainbase.jpg',
        'name': 'Chainbase'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Covalent.jpg',
        'name': 'Covalent'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Golem.jpg',
        'name': 'Golem'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Hooked Protocol.png',
        'name': 'Hooked Protocol'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/io.net的副本.jpg',
        'name': 'io.net'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/IoTex.jpg',
        'name': 'IoTeX'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/MyShell.jpg',
        'name': 'MyShell'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Near Protocol.jpg',
        'name': 'Near Protocol'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Privasea.jpg',
        'name': 'Privasea'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/QnA3.AI.png',
        'name': 'QnA3.AI'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Render Network.jpg',
        'name': 'Render Network'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Ringfence.png',
        'name': 'Ringfence'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Sahara AI.png',
        'name': 'Sahara AI'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Sentient.jpg',
        'name': 'Sentient'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Trusta Labs.jpg',
        'name': 'Trusta Labs'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105926/Worldcoin.jpg',
        'name': 'Worldcoin'
      }
    ],
    'rank9': [
      {
        'name': 'Aptos',
        'image': 'https://piccdn.0daily.com/202408/01023203/Aptos.jpg'
      },
      {
        'name': 'Arbitrum',
        'image': 'https://piccdn.0daily.com/202408/01023203/Arbitrum.png'
      },
      {
        'name': 'AlienSwap',
        'image': 'https://piccdn.0daily.com/202408/01023203/AlienSwap.jpg'
      },
      {
        'name': 'AIAChain',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1127.jpg'
      },
      {
        'name': 'ARPA',
        'image': 'https://piccdn.0daily.com/202408/01023203/ARPA.jpg'
      },
      {
        'name': 'Arweave',
        'image': 'https://piccdn.0daily.com/202307/13095332/7odxdg1fbfk5gedw.jpeg'
      },
      {
        'name': 'Bitget',
        'image': 'https://piccdn.0daily.com/202408/01023203/Bitget.png'
      },
      {
        'name': 'BounceBit ',
        'image': 'https://piccdn.0daily.com/202408/01023203/BounceBit.jpg'
      },
      {
        'name': 'Bifrost',
        'image': 'https://piccdn.0daily.com/202408/01023203/Bifrost.jpg'
      },
      {
        'name': 'BigTime',
        'image': 'https://piccdn.0daily.com/202408/01023203/bigtime.jpeg'
      },
      {
        'name': 'Babylon',
        'image': 'https://piccdn.0daily.com/202408/01023203/Babylon.jpg'
      },
      {
        'name': 'CyberConnect',
        'image': 'https://piccdn.0daily.com/202408/01023203/CyberConnect.jpg'
      },
      {
        'name': 'Chromia',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1428.jpg'
      },
      {
        'name': 'DeAgentAI',
        'image': 'https://piccdn.0daily.com/202408/01023203/DeAgentAI.jpg'
      },
      {
        'name': 'DODO',
        'image': 'https://piccdn.0daily.com/202307/18100141/khr1n2s3bkdzux26.jpg'
      },
      {
        'name': 'dappOS',
        'image': 'https://piccdn.0daily.com/202408/01023203/dappOS.jpg'
      },
      {
        'name': 'deBridge',
        'image': 'https://piccdn.0daily.com/202408/01023203/deBridge.jpg'
      },
      {
        'name': 'Econia',
        'image': 'https://piccdn.0daily.com/202408/01023203/Econia.jpg'
      },
      {
        'name': 'Galxe',
        'image': 'https://piccdn.0daily.com/202408/01023203/galxe.jpeg'
      },
      {
        'name': 'iZUMi Finance',
        'image': 'https://piccdn.0daily.com/202307/05103640/f9hf9tf0o5w66fz2.jpg'
      },
      {
        'name': 'Injective',
        'image': 'https://piccdn.0daily.com/202408/01023203/Injective.jpg'
      },
      {
        'name': 'Kaspa',
        'image': 'https://piccdn.0daily.com/202408/01023203/Kaspa.jpg'
      },
      {
        'name': 'Kava',
        'image': 'https://piccdn.0daily.com/202307/13100611/mxk5dnonn9jmur9u.jpeg'
      },
      {
        'name': 'Lista DAO',
        'image': 'https://piccdn.0daily.com/202408/01023203/ListaDAO.png'
      },
      {
        'name': 'Megabit',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1179.jpg'
      },
      {
        'name': 'Matr1x',
        'image': 'https://piccdn.0daily.com/202408/01023203/matr1x.jpeg'
      },
      {
        'name': 'Monad',
        'image': 'https://piccdn.0daily.com/202408/01023203/Monad.jpg'
      },
      {
        'name': 'Movement Labs',
        'image': 'https://piccdn.0daily.com/202408/01023203/MovementLabs.jpg'
      },
      {
        'name': 'Mina',
        'image': 'https://piccdn.0daily.com/202307/14071355/owmesrmnakojfgdy.png'
      },
      {
        'name': 'Mizu',
        'image': 'https://piccdn.0daily.com/202408/01023203/Mizu_Icon.png'
      },
      {
        'name': 'Mint Blockchain',
        'image': 'https://piccdn.0daily.com/202408/01023203/MintBlockchain.png'
      },
      {
        'name': 'Nillion',
        'image': 'https://piccdn.0daily.com/202408/01023203/Nillion.jpg'
      },
      {
        'name': 'NFTScan',
        'image': 'https://piccdn.0daily.com/202408/01023203/NFTScan.png'
      },
      {
        'name': 'Nyan Heroes',
        'image': 'https://piccdn.0daily.com/202408/01023203/NyanHeroes.png'
      },
      {
        'name': 'Owlto Finance',
        'image': 'https://piccdn.0daily.com/202408/01023203/OwltoFinance.jpg'
      },
      {
        'name': 'Pencils Protocol',
        'image': 'https://piccdn.0daily.com/202408/01023203/PencilsProtocol.jpg'
      },
      {
        'name': 'Polymarket',
        'image': 'https://piccdn.0daily.com/202408/01023203/Polymarket.jpg'
      },
      {
        'name': 'pump.fun',
        'image': 'https://piccdn.0daily.com/202408/01023203/pump.fun.jpg'
      },
      {
        'name': 'Particle Network',
        'image': 'https://piccdn.0daily.com/202408/01023203/ParticleNetwork.png'
      },
      {
        'name': 'Polyhedra Network',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1469.jpg'
      },
      {
        'name': 'Pontem',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1171.jpg'
      },
      {
        'name': 'Pixels',
        'image': 'https://piccdn.0daily.com/202408/01023203/Pixels.jpeg'
      },
      {
        'name': 'Scroll',
        'image': 'https://piccdn.0daily.com/202408/01023203/Scroll.jpg'
      },
      {
        'name': 'Somnia',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1169.jpg'
      },
      {
        'name': 'Supra',
        'image': 'https://piccdn.0daily.com/202408/01023203/Supra.png'
      },
      {
        'name': 'THENA',
        'image': 'https://piccdn.0daily.com/202408/01023203/THENA.png'
      },
      {
        'name': 'Trusta Labs',
        'image': 'https://piccdn.0daily.com/202408/01023203/TrustaLabs.jpg'
      },
      {
        'name': 'UXLINK',
        'image': 'https://piccdn.0daily.com/202408/01023203/UXLINK.jpg'
      },
      {
        'name': 'ZetaChain',
        'image': 'https://piccdn.0daily.com/202408/01023203/ZetaChain.jpg'
      },
      {
        'name': 'Zeus Network',
        'image': 'https://piccdn.0daily.com/202408/01023203/ZeusNetwork.jpg'
      }
    ],
    'rank10': [
      {
        'name': 'Adam Cochran',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221935.jpg'
      },
      {
        'name': 'Andre Cronje',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221956.jpg'
      },
      {
        'name': 'Ansem',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221954.jpg'
      },
      {
        'name': 'Arthur Hayes',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221918.jpg'
      },
      {
        'name': '币圈慈善家',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221932.jpg'
      },
      {
        'name': 'Biteye',
        'image': 'https://piccdn.0daily.com/202408/01023203/Biteye.png'
      },
      {
        'name': 'BITWU.ETH',
        'image': 'https://piccdn.0daily.com/202408/01023203/BITWU.ETH.jpg'
      },
      {
        'name': 'CG ◉ #34566',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221941.jpg'
      },
      {
        'name': 'ChrisZCT',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221938.jpg'
      },
      {
        'name': '陳威廉',
        'image': 'https://piccdn.0daily.com/202408/01023203/陳威廉.jpg'
      },
      {
        'name': 'DiscusFish',
        'image': 'https://piccdn.0daily.com/202408/01023203/DiscusFish.jpg'
      },
      {
        'name': 'Dylan LeClair',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221929.jpg'
      },
      {
        'name': '大宇',
        'image': 'https://piccdn.0daily.com/202408/01023203/大宇.jpg'
      },
      {
        'name': 'EnHeng嗯哼',
        'image': 'https://piccdn.0daily.com/202408/01023203/EnHeng嗯哼.png'
      },
      {
        'name': '翻滚的大姨妈',
        'image': 'https://piccdn.0daily.com/202408/01023203/翻滚的大姨妈.jpg'
      },
      {
        'name': 'Gigiz.eth',
        'image': 'https://piccdn.0daily.com/202408/01023203/Gigiz.eth.png'
      },
      {
        'name': '何币',
        'image': 'https://piccdn.0daily.com/202408/01023203/何币.jpg'
      },
      {
        'name': 'Haotian ',
        'image': 'https://piccdn.0daily.com/202408/01023203/202408292149379.jpg'
      },
      {
        'name': 'Haze',
        'image': 'https://piccdn.0daily.com/202408/01023203/202408292149378.jpg'
      },
      {
        'name': '@horsisy',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1454.jpg'
      },
      {
        'name': '花花研究院',
        'image': 'https://piccdn.0daily.com/202408/01023203/202408302322195.jpg'
      },
      {
        'name': 'jeromeloo.eth｜老王',
        'image': 'https://piccdn.0daily.com/202408/01023203/jeromeloo.eth老王.jpg'
      },
      {
        'name': '靖沣',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1339.jpg'
      },
      {
        'name': 'Kay Capital',
        'image': 'https://piccdn.0daily.com/202408/01023203/KayCapital.jpg'
      },
      {
        'name': 'LaserCat397.eth',
        'image': 'https://piccdn.0daily.com/202408/01023203/LaserCat397.eth.jpg'
      },
      {
        'name': 'Miles Deutscher',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221924.jpg'
      },
      {
        'name': 'Neso',
        'image': 'https://piccdn.0daily.com/202408/01023203/WechatIMG1432.jpg'
      },
      {
        'name': 'Niq Chen',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024082921493729.jpg'
      },
      {
        'name': 'OSF',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221916.jpg'
      },
      {
        'name': 'Phyrex',
        'image': 'https://piccdn.0daily.com/202408/01023203/Phyrex.jpg'
      },
      {
        'name': 'Ryanqyz',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221920.jpg'
      },
      {
        'name': 'sleepy',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024082921493712.jpg'
      },
      {
        'name': 'Susu Alpha',
        'image': 'https://piccdn.0daily.com/202408/01023203/SusuAlpha.png'
      },
      {
        'name': '梭教授说',
        'image': 'https://piccdn.0daily.com/202408/01023203/梭教授说.jpg'
      },
      {
        'name': '杀破狼 killthewolf.eth',
        'image': 'https://piccdn.0daily.com/202408/01023203/杀破狼killthewolf.eth.png'
      },
      {
        'name': 'SweetY',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024082921493716.jpg'
      },
      {
        'name': 't11s',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221911.jpg'
      },
      {
        'name': 'Web3Caff',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024082921493714.jpg'
      },
      {
        'name': 'Xing | 厂仔阿猩',
        'image': 'https://piccdn.0daily.com/202408/01023203/202408292149372.jpg'
      },
      {
        'name': '小捕手 CHAOS',
        'image': 'https://piccdn.0daily.com/202408/01023203/小捕手CHAOS.jpg'
      },
      {
        'name': '徐冲浪',
        'image': 'https://piccdn.0daily.com/202408/01023203/徐冲浪.jpg'
      },
      {
        'name': '余烬',
        'image': 'https://piccdn.0daily.com/202408/01023203/余烬.jpg'
      },
      {
        'name': '一颗小青菜。｜KFCDAO',
        'image': 'https://piccdn.0daily.com/202408/01023203/一颗小青菜KFCDAO.png'
      },
      {
        'name': 'Yash Agarwal',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221936.jpg'
      },
      {
        'name': 'Yuyue',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221910.jpg'
      },
      {
        'name': '子时',
        'image': 'https://piccdn.0daily.com/202408/01023203/子时.jpg'
      },
      {
        'name': 'ZachXBT',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024083023221930.jpg'
      },
      {
        'name': '0xWizard',
        'image': 'https://piccdn.0daily.com/202408/01023203/0xWizard.jpg'
      },
      {
        'name': '0x易经',
        'image': 'https://piccdn.0daily.com/202408/01023203/2024082921493723.jpg'
      },
      {
        'name': '0xmove',
        'image': 'https://piccdn.0daily.com/202408/01023203/0xmove.png'
      }
    ],
    'guest': [
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Mark.jpg',
        'name': 'Mark',
        'info': 'Co-Founder\nAethir'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Charles Wayn.jpg',
        'name': 'Charles Wayn',
        'info': 'Galxe & Gravity\nCo-Founder'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Steven Goldfelder.jpg',
        'name': 'Steven Goldfelder',
        'info': 'Co-Founder and CEO\nOffchain Labs'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Vugar-Usi-Zade.jpg',
        'name': 'Vugar Usi Zade',
        'info': 'Bitget\nCOO'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Ye Su.jpg',
        'name': 'Ye Su',
        'info': 'Arkstream Capital\nFounding Partner'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Quynh Ho.jpg',
        'name': 'Quynh Ho',
        'info': 'Head of \nVenture Investment\nGSR'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/BMAN.jpg',
        'name': 'BMAN',
        'info': 'ABCDE Capital\nCo-Founder'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Melody He.jpg',
        'name': 'Melody He',
        'info': 'Spartan Group\nPartner'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Louis Song .jpg',
        'name': 'Louis Song',
        'info': 'Managing Partner\nSevenX Ventures'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Diana Biggs.jpg',
        'name': 'Diana Biggs',
        'info': 'Partner\n1kx'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Amber.png',
        'name': 'Amber',
        'info': 'Co-Founder\nNo Capital'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Charles d\'Haussy.jpg',
        'name': 'Charles d\'Haussy',
        'info': 'dYdX Foundation\nCEO'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Cooper.jpg',
        'name': 'Cooper',
        'info': 'Co-Founder\nMovement'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Will.jpg',
        'name': 'Will',
        'info': 'Head of Marketing\nMovement'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Matthew Szenics.jpg',
        'name': 'Matthew Szenics',
        'info': 'ME Foundation\nDirector'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Bu Fan.jpg',
        'name': 'Bu Fan',
        'info': 'Story Protocol\nHead of IPFi'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Gal.jpg',
        'name': 'Gal',
        'info': 'deBridge\nCBDO'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Ankur N.jpg',
        'name': 'Ankur N',
        'info': 'Core Contributor\nZetaChain'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Luke.jpg',
        'name': 'Luke',
        'info': 'Head of Operations\nAIA Public Chain'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Angel.jpg',
        'name': 'Angel',
        'info': 'Co-Founder\nZircuit'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Hany Velasco.jpg',
        'name': 'Hany Velasco',
        'info': 'Head of BD\nMizu'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Chris Feng.jpg',
        'name': 'Chris Feng',
        'info': 'Chainbase\nCOO'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Joey Anthony.jpg',
        'name': 'Joey Anthony',
        'info': 'Head of Infrastructure\nMonad'
      },
      {
        'image': 'https://piccdn.0daily.com/202409/01105949/Alberto Viera.jpg',
        'name': 'Alberto Viera',
        'info': 'Head of \nDeveloper Relations\nMoonbeam'
      },
    ]
  }
;
