import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import _ from 'lodash';
import Helmet from 'react-helmet';
import update from 'immutability-helper';
import styles from './index.scss';
import wechatShare from '../../common/scripts/wechat';
import { loginFlag } from '../../common/scripts/login';

import { getHelmet } from '../../common/scripts/helmet';

import Layout from '../../components/common/layout';
import { PostItem } from '../../components/post';
import HomeLoadMore from '../../components/common/homeloadmore';
import { BasicInformation } from '../../components/my/BasicInformation';
import ChangePhone from '../../components/my/ChangePhone';
import Toast from '../../components/common/toast';
import NewLibrary from '../../components/newLibrary';

import { getCurrentUser, changePwd, getAreaData } from '../../common/api/user';
import { getCollection, getUserThird, unbindThird } from '../../common/api/my';
import {
  getLibraryList,
  getFavoriteList,
  getRecommendList
} from '../../common/api/library';

import {
  wechatLogin,
  weiboLogin,
  verificationPassword
} from '../../common/scripts/login';
import locales from '../../utils/locales';

const DEFAULT_AVATAR = require('../../assets/image/default-author-avatar.png');
const WECHATIMG =
  'https://piccdn.0daily.com/202212/16024537/jnbkaa1lkdhlb8ft.png';
const WEIBOIMG =
  'https://piccdn.0daily.com/202212/16024537/nf1mdcwhadcd9620.png';
const XIANSHI =
  'https://piccdn.0daily.com/202212/15111624/ifpln6jng42cqzbh.png';
const BUXIANSHI =
  'https://piccdn.0daily.com/202212/15111624/48h5p5p7adx1cct0.png';

const navList = {
  header: [
    { title: 'library', type: 'myCollection' },
    { title: 'account_settings', type: 'accountSettings' }
  ],
  myCollection: [
    { title: 'article', type: 'post' },
    { title: 'video', type: 'video' }
  ],
  accountSettings: [
    { title: 'basic_information', type: 'basicInformation' },
    { title: 'bind_third_party_account', type: 'weChat' },
    { title: 'change_password', type: 'password' },
    { title: 'change_phone_number', type: 'phone' }
  ]
};

export class My extends React.Component {
  static fetchData(store, match) {
    const { lang } = match.params;
    return new Promise((resolve, reject) => {
      getCurrentUser(lang)
        .then(data => {
          const userData = data.data;
          store.dispatch({
            type: 'SET_CURRENT_USER',
            user: userData
          });
          return getCollection({ type: 'post', page: 1, size: 9 }, lang);
        })
        .then(res => {
          const data = res.data;
          const posts = data.data.data;
          store.dispatch({
            type: 'SET_COLLECTION_POSTS',
            posts
          });
          return getCollection({ type: 'video', page: 1, size: 9 }, lang);
        })
        .then(res => {
          const data = res.data;
          const video = data.data.data;
          store.dispatch({
            type: 'SET_COLLECTION_VIDEO',
            video
          });
          return getUserThird(lang);
        })
        .then(data => {
          store.dispatch({
            type: 'SET_USER_THIRD',
            list: data.data.items
          });
          return getAreaData();
        })
        .then(data => {
          if (data.data) {
            store.dispatch({
              type: 'SET_LOGIN_AREA',
              list: data.data
            });
          }
          return getLibraryList(lang);
        })
        .then(data => {
          if (data.data && data.data.length) {
            store.dispatch({
              type: 'SET_LIBRARY_LIST',
              list: data.data
            });
          }

          return getFavoriteList(undefined, undefined, lang);
        })
        .then(data => {
          if (data.data && data.data.data) {
            store.dispatch({
              type: 'SET_LIBRARY_MY_FAVORITE',
              list: data.data.data
            });
          }
          return getRecommendList(undefined, undefined, lang);
        })
        .then(data => {
          if (data.data && data.data.data) {
            store.dispatch({
              type: 'SET_LIBRARY_RECOMMEND',
              list: data.data.data
            });
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static propTypes = {
    currentUser: PropTypes.object,
    postCollection: PropTypes.array.isRequired,
    videoCollection: PropTypes.array.isRequired,
    userThird: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    areaData: PropTypes.object.isRequired,
    libraryList: PropTypes.array.isRequired,
    favoriteList: PropTypes.array.isRequired,
    recommendList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      posts: {
        page: 2,
        size: 9,
        loaded: false,
        loading: false,
        count: 0
      },
      video: {
        page: 2,
        size: 9,
        loaded: false,
        loading: false,
        count: 0
      },
      headerFlag: 'myCollection',
      navFlag: 'post',
      newPasswordFlag: false,
      passwordFlag: false,
      passwordFocus: false,
      newPasswordFocus: false,
      passwordError: '',
      newPasswordError: '',
      newLibraryFlag: false
    };
  }

  getPostCollectionMore() {
    const { dispatch } = this.props;
    const { page, size } = this.state.posts;
    this.setState(
      update(this.state, {
        posts: {
          loading: {
            $set: true
          }
        }
      })
    );
    getCollection({ type: 'post', page, size }).then(res => {
      const data = res.data;
      const posts = data.data.data;
      const num = data.num;
      const loaded = !posts.length || posts.length < size ? true : false;

      this.setState(
        update(this.state, {
          posts: {
            loading: {
              $set: false
            },
            loaded: {
              $set: loaded
            },
            page: {
              $set: loaded ? page : page + 1
            },
            count: {
              $set: Number(num.post)
            }
          }
        })
      );
      dispatch({
        type: 'SET_COLLECTION_POSTS',
        posts
      });
    });
  }

  getVideoCollectionMore() {
    const { dispatch } = this.props;
    const { page, size } = this.state.video;
    this.setState(
      update(this.state, {
        video: {
          loading: {
            $set: true
          }
        }
      })
    );
    getCollection({ type: 'video', page, size }).then(res => {
      const data = res.data;
      const video = data.data.data;
      const num = data.num;
      const loaded = !video.length || video.length < size ? true : false;

      this.setState(
        update(this.state, {
          video: {
            loading: {
              $set: false
            },
            loaded: {
              $set: loaded
            },
            page: {
              $set: loaded ? page : page + 1
            },
            count: {
              $set: Number(num.post)
            }
          }
        })
      );
      dispatch({
        type: 'SET_COLLECTION_VIDEO',
        video
      });
    });
  }

  changePassword() {
    const { dispatch } = this.props;

    loginFlag(dispatch, true, 'ForgetPassword');
  }

  componentDidMount() {
    if (window.location.hash) {
      let hash = window.location.hash.slice(1);
      let headerFlag = navList.myCollection.find(item => {
        return item.type === hash;
      });

      this.setState({
        navFlag: hash,
        headerFlag: headerFlag ? 'myCollection' : 'accountSettings'
      });
    }
    wechatShare({ title: '个人中心' });
    // getLibraryList();
    // getFavoriteList();
    // getRecommendList();
  }

  changeNewLibraryFlag() {
    const { newLibraryFlag } = this.state;

    this.setState({ newLibraryFlag: !newLibraryFlag });
  }

  changeHeaderFlag(type) {
    let { headerFlag } = this.state;
    if (type === headerFlag) {
      return;
    }
    window.location.hash = navList[type][0]['type'];
    this.setState({
      headerFlag: type,
      navFlag: navList[type][0]['type']
    });
  }

  changeNavFlag(type) {
    let { navFlag } = this.state;
    if (type === navFlag) {
      return;
    }
    window.location.hash = type;
    this.setState({
      navFlag: type
    });
  }

  getUserThird() {
    const { dispatch } = this.props;
    getUserThird().then(data => {
      dispatch({
        type: 'SET_USER_THIRD',
        list: data.data.items
      });
    });
  }

  changeUnbindThird(type, bool) {
    if (bool) {
      unbindThird(type.toUpperCase()).then(data => {
        if (data.code === 0) {
          this.getUserThird();
        }
      });
    } else {
      if (type === 'wechat') {
        wechatLogin();
      } else {
        weiboLogin();
      }
    }
  }

  changePasswordFocus(bool) {
    this.setState({
      passwordFocus: bool
    });
  }

  changeNewPasswordFocus(bool) {
    this.setState({
      newPasswordFocus: bool
    });
  }

  changePasswordFlag(bool) {
    this.setState({
      passwordFlag: bool
    });
  }

  changeNewPasswordFlag(bool) {
    this.setState({
      newPasswordFlag: bool
    });
  }

  initPassword() {
    this.password.value = '';
    this.newPassword.value = '';
    this.setState({ passwordError: '', newPasswordError: '' });
  }

  sendPassword() {
    const { id } = this.props.currentUser;
    const { lang } = this.props.match.params;
    let passwordValue = this.password.value;
    let newPasswordValue = this.newPassword.value;
    let passwordError = verificationPassword(passwordValue, lang);
    let newPasswordError = verificationPassword(newPasswordValue, lang);

    this.setState({ passwordError, newPasswordError });

    if (passwordError || newPasswordError) {
      return;
    }

    let obj = {
      user_id: id,
      password: newPasswordValue,
      type: 'old',
      old_password: passwordValue
    };

    changePwd(obj)
      .then(data => {
        if (data.code === 0) {
          this.initPassword();
          Toast.done({
            msg: '修改成功'
          });
        } else {
          Toast.error({
            msg: data.msg
          });
        }
      })
      .catch(data => {
        Toast.error({
          msg: data.msg
        });
      });
  }

  renderNullData(text) {
    return (
      <div className={styles.nullData}>
        <div className={styles.nullData_img} />
        <div className={styles.nullData_text}>
          暂时还没有收藏{text}哟，快去收藏一下吧～
        </div>
      </div>
    );
  }

  renderNav() {
    const { headerFlag, navFlag } = this.state;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.my_main_nav}>
        {navList[headerFlag].map(item => {
          return (
            <div
              className={`${styles.my_main_nav_item} ${
                navFlag === item.type ? styles.my_main_nav_item_active : ''
              }`}
              key={`my_main_nav_item${item.id}`}
              onClick={() => {
                this.changeNavFlag(item.type);
              }}
            >
              {locales(lang, item.title)}
            </div>
          );
        })}
      </div>
    );
  }

  renderPost() {
    const { postCollection } = this.props;
    const state = this.state.posts;

    if (postCollection && postCollection.length) {
      return (
        <div className={styles.my_postCollection}>
          <div className={styles.my_postCollection_list}>
            {postCollection.map(item => {
              return (
                <PostItem
                  key={item.favoritable_id}
                  {...item.favoritable_data}
                />
              );
            })}
          </div>

          <div
            className={styles.homeLoadMore}
            style={{ display: postCollection.length < 9 ? 'none' : 'block' }}
          >
            <HomeLoadMore
              loading={state.loading}
              loaded={state.loaded}
              onClick={this.getPostCollectionMore.bind(this)}
            />
          </div>
        </div>
      );
    } else {
      return this.renderNullData('文章');
    }
  }

  renderVideo() {
    const { videoCollection } = this.props;
    const state = this.state.video;

    if (videoCollection && videoCollection.length) {
      return (
        <div className={styles.my_videoCollection}>
          <div className={styles.my_videoCollection_list}>
            {videoCollection.map(item => {
              return (
                <a
                  className={styles.item_wrapper}
                  href={`/video/${item.favoritable_data.id}`}
                  target={'_blank'}
                  rel="noreferrer"
                  key={`list${item.id}`}
                >
                  <div className={styles.videoItem}>
                    <div
                      className={styles.video_wrapper}
                      style={{
                        backgroundImage: `url(${item.favoritable_data.cover})`
                      }}
                    >
                      <div className={styles.video_icon} />
                    </div>
                    <div className={styles.videoItem_size}>
                      <p style={{ WebkitBoxOrient: 'vertical' }}>
                        {item.favoritable_data.title}
                      </p>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>

          <div
            className={styles.homeLoadMore}
            style={{ display: videoCollection.length < 9 ? 'none' : 'block' }}
          >
            <HomeLoadMore
              loading={state.loading}
              loaded={state.loaded}
              onClick={this.getVideoCollectionMore.bind(this)}
            />
          </div>
        </div>
      );
    } else {
      return this.renderNullData('视频');
    }
  }

  renderBasicInformation() {
    const { currentUser, dispatch } = this.props;
    return (
      <div className={styles.basicInformation}>
        <BasicInformation {...currentUser} dispatch={dispatch} />
      </div>
    );
  }

  renderWeChat() {
    const { userThird } = this.props;

    let wechatImg =
      userThird.wechat && userThird.wechat.constructor === Object
        ? userThird.wechat.headimgurl
        : WECHATIMG;
    let wechatName =
      userThird.wechat && userThird.wechat.constructor === Object
        ? userThird.wechat.name
        : 'ODAILY';
    let wechatButton =
      userThird.wechat && userThird.wechat.constructor === Object
        ? true
        : false;

    let weiboImg =
      userThird.weibo && userThird.weibo.constructor === Object
        ? userThird.weibo.headimgurl
        : WEIBOIMG;
    let weiboName =
      userThird.weibo && userThird.weibo.constructor === Object
        ? userThird.weibo.name
        : 'ODAILY';
    let weiboButton =
      userThird.weibo && userThird.weibo.constructor === Object ? true : false;

    return (
      <div className={styles.my_wechat_box}>
        <div className={styles.wechat}>
          <div
            className={styles.wechat_img}
            style={{ backgroundImage: `url(${wechatImg || WECHATIMG})` }}
          />
          <div className={styles.wechat_name}>{wechatName}</div>
          <div
            className={styles.wechat_button}
            onClick={() => {
              this.changeUnbindThird('wechat', wechatButton);
            }}
          >
            {wechatButton ? '解绑' : '绑定'}
          </div>
        </div>

        <div className={styles.wechat}>
          <div
            className={styles.wechat_img}
            style={{
              backgroundImage: `url(${weiboImg.replace('httpss', 'https')})`
            }}
          />
          <div className={styles.wechat_name}>{weiboName}</div>
          <div
            className={styles.wechat_button}
            onClick={() => {
              this.changeUnbindThird('weibo', weiboButton);
            }}
          >
            {weiboButton ? '解绑' : '绑定'}
          </div>
        </div>
      </div>
    );
  }

  renderPassword(lang) {
    const {
      passwordFlag,
      newPasswordFlag,
      passwordFocus,
      newPasswordFocus,
      passwordError,
      newPasswordError
    } = this.state;
    return (
      <div className={styles.my_password}>
        <div className={styles.my_password_wrapper}>
          <div className={styles.my_password_inp_list}>
            <div className={styles.my_password_inp_box}>
              <div className={styles.my_password_inp_text}>
                {locales(lang, 'pw_text1')}
              </div>
              <div
                className={`${styles.my_password_inp} ${
                  passwordFocus ? styles.my_password_inp_active : ''
                }`}
              >
                <input
                  type={passwordFlag ? 'text' : 'password'}
                  placeholder={locales(lang, 'pw_text3')}
                  ref={e => {
                    this.password = e;
                  }}
                  onFocus={() => {
                    this.changePasswordFocus(true);
                  }}
                  onBlur={() => {
                    this.changePasswordFocus(false);
                  }}
                />
                <div
                  className={styles.my_password_inp_icon}
                  style={{
                    backgroundImage: `url(${
                      passwordFlag ? BUXIANSHI : XIANSHI
                    })`
                  }}
                  onClick={() => {
                    this.changePasswordFlag(!passwordFlag);
                  }}
                />
              </div>
            </div>
            <div className={styles.my_password_inp_error}>{passwordError}</div>

            <div className={styles.my_password_inp_box}>
              <div className={styles.my_password_inp_text}>
                {locales(lang, 'pw_text2')}
              </div>
              <div
                className={`${styles.my_password_inp} ${
                  newPasswordFocus ? styles.my_password_inp_active : ''
                }`}
              >
                <input
                  type={newPasswordFlag ? 'text' : 'password'}
                  placeholder={locales(lang, 'pw_text3')}
                  ref={e => {
                    this.newPassword = e;
                  }}
                  onFocus={() => {
                    this.changeNewPasswordFocus(true);
                  }}
                  onBlur={() => {
                    this.changeNewPasswordFocus(false);
                  }}
                />
                <div
                  className={styles.my_password_inp_icon}
                  style={{
                    backgroundImage: `url(${
                      newPasswordFlag ? BUXIANSHI : XIANSHI
                    })`
                  }}
                  onClick={() => {
                    this.changeNewPasswordFlag(!newPasswordFlag);
                  }}
                />
              </div>
            </div>
            <div className={styles.my_password_inp_error}>
              {newPasswordError}
            </div>

            <div
              className={styles.my_password_inp_button}
              onClick={() => {
                this.sendPassword();
              }}
            >
              {locales(lang, 'save')}
            </div>
          </div>

          <div
            className={styles.my_password_button}
            onClick={() => {
              this.changePassword();
            }}
          >
            {locales(lang, 'pw_text4')}
          </div>
        </div>
      </div>
    );
  }

  renderLibraryBox(list, title, type) {
    return (
      <div className={styles.my_library}>
        <div className={styles.my_library_header}>
          <div className={styles.my_library_title}>{title}</div>
          <div className={styles.my_library_right}>
            {type === 'library' ? (
              <div
                onClick={() => {
                  this.changeNewLibraryFlag();
                }}
                className={styles.my_library_icon}
              />
            ) : (
              ''
            )}

            {list.length > 2 ? (
              <a
                href={`/libraryList?type=${type}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.my_library_button}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className={styles.my_library_list}>
          {list.slice(0, 3).map(item => {
            item = type === 'favorite' ? item.library : item;
            return (
              <a
                href={`/library/${item.id}`}
                className={styles.my_library_item}
                key={`my_library_item${item.id}`}
              >
                <div
                  className={styles.my_library_item_cover}
                  style={{ backgroundImage: `url(${item.cover})` }}
                />
                <div className={styles.my_library_item_content}>
                  <div
                    className={styles.my_library_item_text}
                    style={{ WebkitBoxOrient: 'vertical' }}
                  >
                    {item.name}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  renderLibrary() {
    const { libraryList, favoriteList, recommendList, match } = this.props;
    const { lang } = match.params;
    return (
      <div>
        {libraryList && libraryList.length ? (
          <div className={styles.my_library_wrapper}>
            {this.renderLibraryBox(
              libraryList,
              locales(lang, 'create_library'),
              'library'
            )}
          </div>
        ) : (
          ''
        )}
        {favoriteList && favoriteList.length ? (
          <div className={styles.my_library_wrapper}>
            {this.renderLibraryBox(
              favoriteList,
              locales(lang, 'save_library'),
              'favorite'
            )}
          </div>
        ) : (
          ''
        )}
        {recommendList && recommendList.length ? (
          <div className={styles.my_library_wrapper}>
            {this.renderLibraryBox(
              recommendList,
              locales(lang, 'recommended_library'),
              'recommend'
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    const { headerFlag, navFlag, newLibraryFlag } = this.state;
    const { areaData, match } = this.props;
    const {
      avatar_url,
      nickname,
      introduction,
      title,
      phone
    } = this.props.currentUser;
    const { lang } = match.params;
    let helmetData = getHelmet({
      title: locales(lang, 'personal_center'),
      twitterImage:
        'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
      ogImage:
        'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.container_wrapper}>
            {/* 个人信息 */}
            <div className={styles.my_header}>
              <div
                className={styles.my_avatar_url}
                style={{
                  backgroundImage: `url(${
                    avatar_url ? avatar_url : DEFAULT_AVATAR
                  })`
                }}
              />
              <div className={styles.my_content}>
                <div className={styles.my_nickname}>
                  {nickname}
                  {title ? <div className={styles.my_title}>{title}</div> : ''}
                </div>
                <div className={styles.my_introduction}>{introduction}</div>
              </div>
            </div>

            <div className={styles.my_main}>
              {/* 左面选项 */}
              <div className={styles.my_main_left}>
                {navList.header.map(item => {
                  return (
                    <div
                      className={`${styles.my_main_left_item} ${
                        headerFlag === item.type
                          ? styles.my_main_left_item_active
                          : ''
                      }`}
                      key={`my_main_left_item${item.title}`}
                      onClick={() => {
                        this.changeHeaderFlag(item.type);
                      }}
                    >
                      {locales(lang, item.title)}
                    </div>
                  );
                })}
              </div>

              {headerFlag === 'accountSettings' ? (
                <div className={styles.my_main_right}>
                  {/* 上面选项 */}
                  {this.renderNav()}

                  {navFlag === 'post' ? this.renderPost() : ''}
                  {navFlag === 'video' ? this.renderVideo() : ''}
                  {navFlag === 'basicInformation'
                    ? this.renderBasicInformation()
                    : ''}
                  {navFlag === 'weChat' ? this.renderWeChat() : ''}
                  {navFlag === 'password' ? this.renderPassword(lang) : ''}
                  {navFlag === 'phone' ? (
                    <ChangePhone
                      match={match}
                      phone={phone}
                      areaData={areaData}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                this.renderLibrary()
              )}
            </div>
          </div>
        </div>

        <NewLibrary
          alertFlag={newLibraryFlag}
          changeAlertFlag={this.changeNewLibraryFlag.bind(this)}
        />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.current,
    postCollection: state.my.collection.posts,
    videoCollection: state.my.collection.video,
    userThird: state.my.userThird,
    areaData: state.login.areaData,
    libraryList: state.library.list,
    favoriteList: state.library.my_favorite,
    recommendList: state.library.recommend
  };
};

export default withRouter(connect(mapStateToProps)(My));
