import React from 'react';
import styles from './index.scss';
import PropTypes from 'prop-types';
import locales from '../../../utils/locales';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
export class PostRecommend extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    type: PropTypes.string,
    articlelist: PropTypes.array,
    feedList: PropTypes.array,
    tags: PropTypes.array,
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      slug: null
    };
  }

  renderArticle() {
    let { articlelist, type } = this.props;
    let { id } = this.props;
    return articlelist
      .filter(item => {
        if (type === 'newsflash') {
          return item.id != id;
        }
        return item.id != id && item._type === 'post';
      })
      .map((item, index) => {
        if (index < 6) {
          return (
            <li key={`article${index}`} className={styles.article}>
              <a
                href={
                  type === 'newsflash'
                    ? `/newsflash/${item.id}`
                    : `/post/${item.id}`
                }
                style={{ WebkitBoxOrient: 'vertical' }}
                className={styles.article_title}
              >
                {item.title}
              </a>
            </li>
          );
        }
      });
  }

  renderFeedList() {
    let { feedList, type } = this.props;
    let { id } = this.props;
    return feedList
      .filter(item => {
        if (type === 'newsflash') {
          return item.entity_id != id;
        }
        return item.entity_id != id && item.entity_type === 'post';
      })
      .map((item, index) => {
        if (index < 6) {
          return (
            <li key={`article${index}`} className={styles.article}>
              <a
                href={
                  type === 'newsflash'
                    ? `/newsflash/${item.id}`
                    : `/post/${item.entity_id}`
                }
                style={{ WebkitBoxOrient: 'vertical' }}
                className={styles.article_title}
              >
                {item.title}
              </a>
            </li>
          );
        }
      });
  }

  render() {
    const { tags, articlelist, match } = this.props;
    const { lang } = match.params;
    let ary = [];
    if (tags && tags.length) {
      ary = this.props.tags.filter(item => {
        return !!item.slug;
      });
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {locales(lang, 'recommended_reading', { isTitle: true })}
          </div>

          {ary && ary[0] ? (
            <a className={styles.more} href={`/search/${ary[0].name}`} />
          ) : (
            ''
          )}
        </div>

        {articlelist && articlelist.length ? (
          <ul className={styles.articleList}>{this.renderArticle()}</ul>
        ) : (
          ''
        )}
        {!(articlelist && articlelist.length) ? (
          <ul className={styles.articleList}>{this.renderFeedList()}</ul>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withRouter(connect()(PostRecommend));
