import { service } from '../scripts/http';
import http from '../scripts/http';

export function getPostCount() {
  return service.get(`founds/postCount`);
}

export function getTopCommend(lang) {
  return service.get(`founds/dissertationRecommended`, { lang });
}

// export function getTopAuthor(page = 1) {
//   return service.get(`founds/authorList?page=${page}&paginate=7`);
// }
// export function getTopAuthorByUser(page = 1, user_id) {
//   return service.get(
//     `founds/authorList?user_id=${user_id}&page=${page}&paginate=7`
//   );
// }

export function getTopAuthorList(page = 1, user_id) {
  return service.get(`founds/recommend_author?user_id=${user_id}&page=${page}`);
}

export function getTopPost(value = 'week', lang) {
  return service.get(`founds/postList?type=${value}`, { lang });
}

export function getPoticList(page, lang) {
  return service.get(`found/dissertationList?paginate=20&page=${page}`, {
    lang
  });
}

export function getVideoList(page, b_id = '') {
  return http.get(
    `app-front/feed-stream?feed_id=301&b_id=${b_id}&per_page=20&page=${page}`
  );
}

export function followAuthor(data) {
  return http.post(`follow`, data);
}

export function NotFollowAuthor(fid) {
  return http.delete(`follow?fid=${fid}&ftype=user`);
}

export function getTopPostList(lang) {
  return http.get(`post/choiceness`, { lang });
}
