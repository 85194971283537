import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

export class TopItem extends React.PureComponent {
  static propTypes = {
    key: PropTypes.string,
    company: PropTypes.string,
    num: PropTypes.number,
    project: PropTypes.string
  };
  render() {
    return (
      <div className={styles.container}>
        <p>
          {this.props.num + 1}、{this.props.project}
        </p>
        <p className={styles.time}>{this.props.company}</p>
      </div>
    );
  }
}
