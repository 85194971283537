import React, { Component } from 'react';
import styles from '../../index.scss';
import PropTypes from 'prop-types';

export class PrivacyJa extends Component {
  static propTypes = {
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.content}>
        <h5>{this.props.title}</h5>
        <h6>目次</h6>
        <p>1.プロローグ</p>
        <p>2.私たちについて</p>
        <p>3.私たちはどのようにあなたの箇人情報を収集して利用しますか</p>
        <p>4.Cookieや同種の技術をどのように使っていますか</p>
        <p>5.ご個人情報をどのように処理、提供、譲渡、開示していますか</p>
        <p>6.私たちはどのようにあなたの箇人情報を保存して保護します</p>
        <p>7.個人情報はどのように管理していますか</p>
        <p>8.未成年者の個人情報の保護</p>
        <p>9.プライバシーポリシーの変更とお知らせ</p>
        <p>10.亡くなったユーザーの個人情報を保護します</p>
        <p>11.ご連絡はいかがか</p>
        <p>12.紛争解決</p>
        <h6>1.イントロ</h6>
        <p>
          本プライバシーポリシー(以下、本プライバシーポリシー)は、ODAILY(以下、本プラットフォームまたは当社)のソフトウェアおよび関連サービス(以下、ODAILYサービス)に適用されます。各条項のタイトルは、当該条項の趣旨の理解を助けるためのものであって、このプライバシーポリシーの条項の意味または解釈に影響を与えたり、制限したりするものではありません。あなたの権益を守るために、各項目の具体的な表現をよくお読みになることをお勧めします。
        </p>
        <p>
          特にご注意ください:このプライバシーポリシーはウェブサイト、クライアント、ウィジェット、および技術の発展に伴って出現した新しい形態でご提供するODAILYサービスに適用されます。当社および関連会社の製品・サービスにODAILYのサービスが利用されていても、独自のプライバシーポリシーを設けていない場合は、当該サービスの一部に適用します。当社および関連会社が提供するODAILYサービスについて別途プライバシーポリシーを設けている場合は、当該製品またはサービスに当該プライバシーポリシーを適用します。
        </p>
        <p>
          登録申請中または本プライバシーポリシーの更新時に本プライバシーポリシーに同意するをクリックする前に、本プライバシーポリシーをよくお読みになる必要があります。各項目の内容を十分ご理解いただき、慎重にお読みくださいますようお願い申し上げます。
          <b>
            あなたの箇人情報の権利に関する条項の重要な内容を太字で提示しましたので、特にご注意ください。
          </b>
        </p>
        <p>
          登録ページの案内に従って情報を記入し、本プライバシーポリシーを読んで同意し、すべての登録手続きを完了した後、または本プライバシーポリシーの更新時に同意をクリックすることは、本プライバシーポリシーを十分に読み、理解し、受け入れたことを意味します。
          <b>
            本プライバシーポリシーをお読みの上、本プライバシーポリシーまたは規約のいずれかに同意しない場合、関連するODAILYサービスおよび一部の機能を正常にご利用できない、または当社が期待するサービスの効果を達成できないことがあります。
          </b>
        </p>
        <h6>2.私たちについて</h6>
        <p>
          1.プラットフォームの運営者とODAILYサービスの提供者は海南省莫迪文化メディア有限責任公司
        </p>
        <p>
          2。関連会社は、海南省motian文化メディア有限公司、海南省motian文化メディア有限公司の制御、または海南省motian文化メディア有限公司の共同制御下にある会社、机関の将来の制御支配とは、所有権、投票権のある株式、契約、実際の運営関連、その他法に基づいて認定された方法を通じて、直接または間接的に支配対象の管理/経営に影響を及ぼす能力を持つことを指します。
        </p>
        <h6>3.どのようにあなたの箇人情報を収集し、利用しますか</h6>
        <p>
          1.ご利用中に自発的に提供された、またはODAILYサービスの必要性から発生した箇人情報を収集し、ご利用いただくためには、法令および正当、合法、必要な原則に基づきます。このプライバシーポリシーに記載されていない他の目的であなたの箇人情報を使用したい場合、または特定の目的で収集した情報を他の目的で使用したい場合は、適切な方法で速やかにお知らせし、使用前に再度同意を得ます。
        </p>
        <p>
          2.私たちが収集して使用するあなたの箇人情報のタイプは2つが含まれています:第1:ODAILYサービスのコア業務機能に必要な情報:このような情報は、このプラットフォームが正常に動作するために必要な情報であり、あなたは私たちに許可を与えて収集する必要があります。ご提供を拒否された場合、関連ODAILYサービスと一部の機能を正常に使用できなくなる可能性があります。2つ目:追加の業務機能が収集する必要があるかもしれない情報:この情報は、非コアの業務機能に必要な情報ご提供を拒否された場合、付加的な業務機能が実現できない、または私たちの意図した効果を達成することができませんが、コア業務機能の正常な使用に影響しません。
        </p>
        <p>
          3.
          <b>
            ODAILYはサービスが多く、提供するコンテンツが異なるため、コアな業務機能(収集する個人情報を含む)もODAILYサービスの内容によって異なります。具体的にはODAILYサービスが実際に提供されているものを基準とします。それに加えて、ご理解とご同意をいただき、ODAILYサービスを充実したものにしたいと考えておりますので、ODAILYサービスを技術的にも改善していきたいと考えております。個人情報を収集する機能やサービスが本プライバシーポリシーに記載されていない場合は、本プライバシーポリシーの更新、ページ案内、ポップアップ、webサイトの告知等により、収集の目的、内容、利用方法、範囲を別途ご説明し、ご自身で選択して同意する方法をご提供します。
          </b>
          この過程で、もしあなたは関連する事柄に対していかなる疑惑があるならば、このプライバシーポリシーの第11条を通過することができます。1つの提供の方式は私達に連絡して、私達はできるだけ早くあなたのために解答を作ります。
        </p>
        <p>
          4.このプライバシーポリシーにおける敏感な箇人情報とは、漏洩または不法使用の際、自然人の人格の尊厳が侵害されたり、人身、財産の安全が危険にさらされるおそれがある箇人情報を指します。
          <b>生体認証、宗教、特定の身分、医療、健康、金融口座、位置情報</b>
          <b> 14歳未満の未成年者の個人情報</b>など
          <b>
            敏感な個人情報には、身分証明書番号、生体認証情報、銀行口座番号、通信内容、健康生理情報などが含まれます。
          </b>
          など私たちが収集した敏感な個人情報は、本プライバシーポリシーにおいて、明確な識別情報として表示されています。あなたの敏感な箇人情報が漏洩、改ざんされたり、不正に使用されたりすると、あなたの身体または財産に損害を与えることがあります。あなたの敏感な箇人情報の漏洩または不正な使用を防止するために、適切な技術と組織の保護措置を講じて、あなたの情報の安全を確保するように努めています。
        </p>
        <p>
          5.通常、私たちは以下のような場面であなたの個人情報を収集・利用します。
        </p>
        <p>5.1 ODAILYサービス</p>登録とログインの状態
        <p>
          ODAILYサービスの安全性と継続的かつ安定的なサービスを提供するためには、携帯電話番号、eメールアドレスなどの基本的な登録またはログイン情報を提供し、ユーザー名とパスワードを作成する必要があります。
          <b>携帯番号、パスワードは個人情報</b>
          ,前述の情報を収集するのは関系法律法規のネット実名制の要求と履行の本プライバシー政策の要求を満たすため一部のサービスでは、閲覧・検索の基本機能のみを利用する場合、ODAILYユーザーへの登録や上記情報の提供は必要ありません。
        </p>
        <p>
          サードパーティのプラットフォームのアカウントを使用してODAILYユーザーに登録することもでき、関連する法律や規制の要件を満たすことを前提に読み取り、取得することを許可することもできます。
          <b>
            あなたが第三者のプラットフォームに公開、記録した公開情報(ニックネーム、プロフィール画像を含む)
          </b>
          ODAILYユーザーとしてのログインidを記憶するために第三者から上記の情報を取得する許可が必要上記の情報の収集はあなたの同意に基づいています。
        </p>
        <p>
          通信事業者との提携により、ODAILYの「ワンクリックログイン」機能をご利用の場合、明示的な同意を得て、事業者から携帯電話番号を送信していただくことで、迅速なログインサービスを提供することができます。登録や登録は、手動で携帯電話の番号を記入することもできます。
        </p>
        <p>
          上記の情報はあなたの同意に基づいて収集されます。これらの情報はあなたのODAILYユーザーのidを識別するためにあなたのホームページやコメント欄に表示されることがあります。他のODAILYユーザーとの交流に便利しかし、これらの情報を提供しない場合は、ODAILYサービスの基本的な機能を使用することに影響はありません。
        </p>
        <p>5.2 ODAILYサービス</p>非登録・ログインの場合
        <p>
          未登録またはログイン状態でODAILYサービスをお使いの場合、非ログインモードでは一部の機能しかご提供できません。機能の実現を保障するために、私たちは、閲覧、検索、いいね、コレクション、共有機能を実現するために、上記の情報を収集するためにあなたのデバイス情報、ネットワーク識別情報の取得をイニシアチブすることができますが、関連する法律と法規を満たすためにログ情報を保存する義務要件;
        </p>
        <p>
          上記の情報に加えて、ご利用中に、私たちは自発的に取得を申請することがあります
          <b>デバイス権限</b>
          その他の追加機能を実装します(このプライバシーポリシー本条5.9「あなたのためのセキュリティ」を参照)。
        </p>
        <p>5.3情報閲覧</p>
        <p>
          1)
          ODAILYはあなたにより興味のある情報を推薦するかもしれません。この機能を実現するために、
          <b>必要なログ情報を自動的に収集します</b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            a)あなたの操作、使用行動情報:クリック、注目、収集、検索、閲覧、共有;
          </li>
          <li>b)デバイス情報:デバイス一意識別子;</li>
          <li>
            c)ネットワーク情報:GPS情報、WLANアクセスポイント、bluetooth、および基地局などのセンサ情報によって取得された正確な地理的位置情報、およびネットワーク位置情報(基地局、IPおよびWLAN)によって取得された大まかな地理的位置情報
          </li>
        </ul>
        <p>
          2) <b>GPS位置情報は個人情報</b>
          提供を拒否された場合、GPS情報に基づいて情報をお勧めすることはありませんし、当プラットフォームの他の機能の正常な使用に影響を与えることはありません。
        </p>
        <p>
          3)私たちは、大まかな地理的位置情報に対応する都市に基づいて、あなたの地域のローカル関連情報をお見せし、お勧めします。
        </p>
        <p>
          4)上記の情報を収集するのは、関連法規のログ情報保持義務要件と本プライバシーポリシーの履行要件を満たすため
          <b>
            私たちが収集、使用する上記の情報は、非識別化処理を行っています。データ分析は、あなたのアイデンティティに直接関連しない特定のコードに対応するだけで、あなたの本当のアイデンティティには関連しません。
          </b>
        </p>
        <p>5.4リリースとインタラクティブ</p>
        <p>1)情報発表</p>
        <ul className={styles.privacy_ul}>
          <li>
            a)あなたが投稿やコメントを投稿すると、私たちは自動的にあなたの投稿を収集し、ニックネーム、プロフィール画像、投稿内容を表示します。
          </li>
          <li>
            b)あなたは画像をアップロードして、音声とビデオ機能をリリースするとき、私たちはカメラ(カメラ)、アルバム(ストレージスペース)、マイクの権限を求めます。ライセンス提供を拒否された場合、この機能を使用することはできませんが、当プラットフォームの他の機能の正常な使用には影響しません。
          </li>
          <li>
            c)公開された情報には、ユーザーや他の人の個人情報が含まれている可能性があるので注意してください。公開された情報に他の人の個人情報が含まれている場合は、アップロードする前に、法令で使用が許可されているか許可されていることを確認する必要があります。
          </li>
          <li>
            d)ライブ配信機能をご利用の際には、法律、セキュリティ要件、本プライバシーポリシーの履行要件に基づき、実名認証を行う必要があります。実名認証では、認証サービスの第三者認証機関が自発的に提供する必要があります
            <b>実名と住民登録番号の情報これらの情報は個人の敏感な情報</b>
            プラットフォームの他の機能の正常な使用に影響を与えることなく、提供を拒否することができます。これらの情報は実名認証および法律法規によって規定された用途にのみ供され、あなたの明示的な許可がなければ、他の目的には使用されません。
          </li>
          <li>
            e)上記の情報収集は、関連法令のサービス提供者に、配信アカウント、公開アカウント、ライブ配信ユーザーなどが配信したコンテンツを保持する義務要件と、本プライバシーポリシーの履行要件を満たすために行われます。
          </li>
        </ul>
        <p>2)インタラクティブ</p>
        <ul className={styles.privacy_ul}>
          <li>
            a)あなたがコンテンツをフォローしている場合、私たちはあなたがフォローしているコンテンツやアカウントを収集し、そのコンテンツやアカウントが投稿したコンテンツをお見せします。
          </li>
          <li>
            b)上記の情報を収集するのは、関連法規のログ情報保持義務要件と本プライバシーポリシーの履行要件を満たすため
          </li>
        </ul>
        <p>5.5検索します</p>
        <p>
          1)あなたが検索サービスをご利用になったとき、私たちは自働的にあなたの検索キーワード情報、ログ情報記録を収集して分析し、より関連性の高い検索結果をお見せします。
        </p>
        <p>
          2)私達はまた自働的にあなたが私達のサービスを使用する時に提供、形成または保持する情報を記録することができ(ありえ)ます、あなたが検索を使用する時に検索ワードを入力して、歴史のワードをクリックして、ヒントのワードとリンクをクリックして、あなたが運営活働の検索に参加する時に提出した情報をクリックします。あなたは上記の情報の提供を拒否する権利がありますが、その結果、特定のサービスまたは機能を利用できない、または関連するサービスが意図する効果を達成できない可能性があります。
        </p>
        <p>
          3)上記の情報を収集するのは、関連法律法規のウェブログ情報保存義務要件と本プライバシーポリシーの履行要件を満たすため効率的な検索サービスを提供するために、一部の上記情報も一時的にローカルストレージに保存し、検索結果の内容、検索履歴を表示することができます。
        </p>
        <p>
          5.6 <b>メッセージ通知</b>
        </p>
        <p>
          <b>
            ご理解と同意、製品および/またはサービスの使用中にご連絡先(連絡先)を提供するために、私たちは、ユーザーメッセージ、アイデンティティの検証、セキュリティの検証、ユーザーの使用経験の調査、議論の処理のためのアプリケーションのうちの1つまたは複数の種類の通知を送信することができます;また、上記のプロセスで収集した携帯電話番号にメールや電話で、ご興味のあるサービス、機能、イベント情報の用途をご提供することもできますが、ご安心ください。受け付けたくない場合は、携帯電話のメールで提供するキャンセル方法でキャンセルすることもできますし、直接ご連絡してキャンセルすることもできます。
          </b>
        </p>
        <p>5.7カスタマーサービス</p>
        <p>
          あなたが私達に意見のフィードバックを行うとき、あなたとの連絡を容易にしたり、あなたの問題を解決するのを助けるために、私達はあなたの名前、携帯電話番号、電子メールや他の連絡方法を提供するために進んで必要があるかもしれませんし、あなたと連絡を取るためにこれらの情報を使用することができます。もしあなたの提出したのが侵害の苦情であれば、私達はまた法律の規定に従ってあなたに提出を要求します
          <b>身分証明書、委任権、権利の証明</b>を明確にクレームの主体の資格
        </p>
        <p>5.8取引サービス</p>
        <p>
          オンライン閲覧、オンラインレッスン、ポイントショップ商品、その他の方法で当社の商品やサービスを購入された場合、取引履歴やログ情報を自動的に収集します。上記の情報収集は、関連法規のログ情報保持義務および取引情報保持義務要件を満たすために行われます。
          <b>取引履歴情報は個人情報</b>
          この情報の提供を拒否すると取引サービスを提供できなくなる可能性がありますが、ODAILYサービスのその他の機能の正常な利用には影響しません。
        </p>
        <p>5.9セキュリティを提供します</p>
        <p>
          「中華人民共和国サイバーセキュリティ法」(以下「サイバーセキュリティ法」といいます)などの法律法規の要求、サイバーセキュリティ保障の必要性及び本プライバシー政策の履行の要求を満たすために、安全で信頼できるサービスを提供し、システム運行の安定性と安全性を保障します。私たちはあなたの1)デバイス情報(デバイスのモデル、デバイスのMACアドレス、オペレーティングシステムのタイプ、クライアントバージョン、議論率、デバイス設定を含む)の収集を自発的に請求する必要があります。デバイス固有識別子(IMEI/android)
          ID/IDFA/OPENUDID/GUID、SIMカードIMSI情報2)ネットワーク識別情報(含む)
          <b>GPS位置情報</b>
          IPアドレス、通信事業者、ネットワーク環境、ソフトウェアバージョン番号、ブラウザの種類、など
          <b>ウェブサイトの閲覧履歴</b>
          、操作日誌)アカウントのリスクを判断し、システム運用の安全性を損なう行為を予防、発見、調査し、我々のルールやプロトコルに違反する違法行為を発見します。弊社はまた、上記の設備情報、ソフトウェア情報を収集し、弊社の製品やサービスシステムの問題を分析し、フローを統計し、存在する可能性があるリスクを洗い出します。上記の情報をご提供いただけない場合、当社の製品やサービスをご利用いただく際に、製品やサービスとアカウントのセキュリティを保護することはできません。
        </p>
        <p>5.10使い心地と満足度を向上させます</p>
        <p>
          ODAILYサービスの利用感と満足度を向上させるために、あなたの操作行動を自働的に収集し、それに基づいたユーザーデータの調査研究・分析を行い、サービスの最適化を図ります。
        </p>
        <p>
          5.11 <b>例外の同意を求めます</b>
        </p>
        <p>
          <b>以下の場合、個人情報の収集、使用に許可の同意を必要としません。</b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1)国家安全、国防安全に関するもの</li>
          <li>2)公共の安全、公衆衛生、重大な公共の利益に関するもの</li>
          <li>3)犯罪捜査、起訴、裁判や判決の執行などに関系するもの</li>
          <li>
            4)箇人情報の主体または他の箇人の生命、財産などの重大な合法的権益を守るためにしかしまた本人の同意を得ることが難しい;
          </li>
          <li>
            5)収集された個人情報は、個人情報の主体が自ら社会に公開するもの
          </li>
          <li>
            6)合法的に公開開示された情報からのあなたの箇人情報の収集は、合法的なニュース報道、政府の情報公開などのルート;しかし、明確にあなたの重大な利益を侵害する場合を除き、この情報を拒否または処理します。
          </li>
          <li>7)ご要望による契約締結に必要なもの</li>
          <li>
            8)提供される製品および/またはサービスの安全で安定した動作を維持するために必要な、例えば、製品および/またはサービスの故障を発見し、処分すること
          </li>
          <li>9)合法的な報道のために必要</li>
          <li>
            10)学術研究机関が公共の利益に基づいて統計をとる、または学術研究のために必要な結果を外部に提供する場合には、その結果に含まれる箇人情報を非識別化します。
          </li>
          <li>11)その他、法令で定められている場合</li>
        </ul>
        <p>
          <b>
            箇人情報、または他の情報との組み合わせでご自身を識別できない情報、直接ご連絡ができる情報は箇人情報ではありませんのでご注意ください。特定の個人と連絡が取れないような情報を他の情報と組み合わせて使用したり、個人情報と組み合わせて使用したりした場合、組み合わせて使用している間は、そのような情報は個人情報とみなされます。
          </b>
        </p>
        <p>5.12デバイス権限呼び出し</p>
        <p>
          関連するODAILYサービスの正常な実現を保証するために、私達は具体的な使用シーンに応じて必要な権限を呼び出す必要があります。
          <a href="か?application-authority" target="_blank">
            『応用権限の申請と取扱説明書』
          </a>
        </p>
        <p>
          デバイスの設定で一部または全ての権限をオフにすることができます。これにより、対応するODAILYサービスが機能しなくなったり、効果が期待できなくなったりすることがあります。
        </p>
        <p>5.13パーソナライズ推奨</p>
        <p>
          ODAILYサービスでは、パーソナライズされたコンテンツの推薦や表示には関与していません。閲覧や検索情報、行動の好みなどの情報を収集して画像分析を行うことはありません。
        </p>
        <h6>4.</h6> Cookieと同様の技術をどのように使っていますか
        <p>
          1.Cookieは、サイトが登録ユーザーを識別し、ユーザー数を計算するのに役立ちます。通常、各サイトでは、登録が完了したユーザーがログインしたかどうかを判定します。当プラットフォームは、Cookie情報の研究は、サービス/製品の品質向上およびユーザー体験の最適化のみを目的とすることを約束します。同時に、cookieに箇人情報を保存したくない場合は、ブラウザを設定することができます:「cookieを拒否する」または「cookieをウェブサイトが送信するときにお知らせします」を選択します。ODAILYサービスはCookieをサポートすることで実現されているため、Cookieをオフにする作業が完了した後、当プラットフォームへのアクセスまたは当プラットフォームのサービスへの十分なアクセスができない場合があります。Cookieを使ってしまうと、次にこのサイトに入るときに、ログイン手続きを簡単にするためにユーザー情報を保持するかどうかのヒントが得られるかもしれません(例えば、キーログイン)。
        </p>
        <p>
          2.このプラットフォームはwebを使用しています。
          beaconの方法と目的はCookieと同じで、上記の条項は参照して適用すべき
        </p>
        <h6>
          5.あなたの箇人情報をどのように処理、提供、譲渡、開示していますか
        </h6>
        <p>1.ご個人情報の処理をお願いします</p>
        <p>
          より良いODAILYサービスをご提供するために、いくつかの機能は弊社のサービスパートナー(弊社の第三者サービスサプライヤー、請負業者、代理店など)が提供する可能性があります。ODAILY向けのワンタッチログインサービス、プッシュメッセージサービス、データ分析・統計サービスを提供するパートナーが含まれますが、これらに限定されません。弊社は書面での協議、現場での監査などの方法でサービス提携先に厳格な守秘義務の遵守と有効な守秘措置を要求し、これらの情報があなたの許可を得ていない用途に使用されることを禁止します。依頼が解消された後は、サービス提携先があなたの個人情報を保存しなくなります。
        </p>
        <p>2.外部に提供します</p>
        <p>
          下記の場合を除き、当サービス提供者以外の企業、組織、個人への個人情報の提供は行いません。
        </p>
        <p>
          2.1
          法定義務の履行に必要私たちは、法律法規の規定、訴訟、紛争解決の必要性、または行政、司法机関の要求、その他の法定義務の履行の必要性、または法律の定めるその他の合法的な事由を備える場合があります。あるいは公共の利益のために新聞報道や世論の監視などの行為を行い、対外的にあなたの箇人情報を提供します。
        </p>
        <p>
          2.2
          あなたの同意または自発的な要求に基づいて:もしあなたが単独で同意または自発的に第三者にあなたの箇人情報を提供することを要求するならば、私達はあなたの同意の目的に基づいて、該当のページで適切な方法であなたの箇人情報の受信者の名前と連絡先情報を知らせます。共有サービスをご利用の場合は、第三者の共有プラットフォーム名をページに表示します。連絡先は第三者のプライバシーポリシーをご覧ください。
        </p>
        <p>
          2.3
          サービス契約の履行に必要なことに基づいて:当社のODAILYサービスの一部は、当社の関連会社と提携机関、または当社と共同で提供することができます。したがって、あなたと締結したサービス契約を履行するために、私達はあなたの箇人情報を私達の関連会社または協力机関に提供しなければなりません。我々は制定しました
          <a href="か?< info-share-list" target="_blank">
            「情報共有リスト」
          </a>
          関連会社、協力会社の状況を分かりやすく簡潔にご紹介します。「情報共有リスト」をご覧ください。
        </p>
        <p>
          1)関連会社への提供:弊社が関連会社または他の製品と提供する製品またはサービスのご利用を容易にするために、弊社の関連会社および/またはその指定するサービスプロバイダーとあなたの個人情報を共有する場合があります。私たちは必要な個人情報のみを共有し、このポリシーに明記された目的に拘束されます。もし私たちがあなたの敏感な個人情報を共有したり、関連会社が個人情報の利用や取り扱いの目的を変更したりした場合は、再度許可同意を求めます。
        </p>
        <p>
          2)私たちの協力机関への提供:完全な製品とサービスを提供するために、私たちのサービスや技術のいくつかのライセンス協力机関から提供されます。他の箇人情報処理者への提供は積極的ではなく、適法、正当、必要、特定、明確な目的のために、必要な箇人情報のみを共有しておりますのでご了承ください。他の箇人情報処理者にあなたの箇人情報を提供し、宛先の名前または名前、連絡先、処理目的、処理方法、箇人情報の種類を伝え、個別の同意を得ます。
          また、弊社はパートナー機関と厳重な秘密保持協定を締結し、このプライバシーポリシーおよびその他の関連する秘密保持ポリシーに従って、あなたの個人情報を取り扱うためのセキュリティ対策を講じることを要求します。協力機関には共有された個人情報を他の用途に使用する権利がないことを要求します
          <b>
            サードパーティが作成したウェブページを見たり、サードパーティが開発したアプリを使ったりしていると、サードパーティが自分のものを置くことがあります。
            クッキー
            または、我々の制御を受けない他の技術的手段を使用し、その使用は、本プライバシーポリシーの制約を受けません。協力機関が独自の個人情報保護ポリシーやユーザープロトコルを持っている場合もありますので、第三者の個人情報保護ポリシーやユーザープロトコルを読んで遵守することをお勧めします。
          </b>
        </p>
        <p>
          2.4
          法律の規定に基づくその他の適法事由突発的な公衆衛生上の事態に対応するため、または緊急の場合にあなたの生命と健康、財産を保護するために必要なこと、または公共の利益のために報道、世論監視などの行為を行うことなど、法律が定めるその他の正当な事由に基づいて第三者にあなたの箇人情報を提供することができます。
        </p>
        <p>3.譲渡します</p>
        <p>
          下記の場合を除き、個人情報を会社、組織、個人に譲渡することはありません。
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1)明確な同意を得た上で譲渡します。あなたの明確な同意を得た上で、私たちはあなたの個人情報を他の者に譲渡します。
          </li>
          <li>
            2)
            合併、買収、または破産清算に係る場合に譲渡します。オーデイリーの合併、買収、または破産清算に関連して個人情報の譲渡に関わる場合は、あなたの個人情報を管理する新たな会社・組織が、引き続きこのプライバシーポリシーの適用を受けることを要求し、そうでなければ、当社はその会社・組織に対し、再びあなたへの承認同意を求めることを要求します。
          </li>
        </ul>
        <p>4.公開します</p>
        <p>
          4.1開示とは、社会や不特定多数に向けて情報を発信すること不正アカウント、詐欺行為等の処罰、当選・当選者等の発表の際の脱感表示等の必要な場合を除き、個人情報の開示は行いません。開示の目的、種類(個人的に敏感な情報に関する場合は、その情報の内容についてもお知らせします)を事前にお知らせし、同意を得てから開示します。ただし、法令に別途定める場合や、プライバシーポリシーに別途定める場合を除きます。
        </p>
        <p>
          4.2開示されたあなたの箇人情報について、私たちは、開示申請を受信した後、最初に慎重に正当性、合理性、合法性を検討し、開示時と開示後に最も厳格な箇人情報セキュリティ保護措置と手段を取って保護します。
        </p>
        <p>5.サードパーティSDKにアクセスする場合</p>
        <p>
          より良いODAILYサービスの利用と利用を保証するために、当社は第三者機関と提携してSDKプラグインの形で一部の機能を提供しています。SDK技術サービス提供者は、あなたに機能やサービスを提供するために必要な権限や情報を取得します。アプリに組み込まれているSDKはすべて、その機能やサービスの実現に必要な個人情報のみを収集していることを確認しています。こちらからご覧いただけます
          <a href="か?< info-share-list" target="_blank">
            「情報共有リスト」
          </a>
        </p>
        <p>
          6.個人情報の委託処理、外部への提供、譲渡、開示の際には例外を設けます。
        </p>
        <p>
          <b>
            関連法律法規の規定により、以下の場合、あなたの箇人情報を共有、譲渡、公開する必要はありません許可の同意を得る:
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1)法律法規で定められた義務の履行に関するもの</li>
          <li>2)国家安全保障、国防安全保障に直結します。</li>
          <li>3)公共の安全、公衆衛生、重大な公共の利益に直接関系したい</li>
          <li>
            4)犯罪捜査、起訴、裁判や判決の執行などの司法や行政の執行に直接関系するもの
          </li>
          <li>
            5)あなたまたは他の箇人の生命、財産などの重大な合法的権益を守るためにしかしまた本人の同意を得ることが難しい;
          </li>
          <li>6)あなた自身が社会に公開した箇人情報</li>
          <li>
            7)合法的に公開開示された情報から箇人情報を収集して、合法的なニュース報道、政府の情報公開などのルート
          </li>
        </ul>
        <p>
          <b>
            適用される法律に基づき、技術的措置その他の必要な措置を講じて、データの受信者が特定の個人を再識別できないようにしたり、復元できないようにしたりした場合には、当該処理されたデータの処理の委託、提供、譲渡、開示は、別途の通知や同意を必要としません。
          </b>
        </p>
        <h6>6.どのようにあなたの箇人情報を保存し、保護しますか</h6>
        <p>1. </p>を格納します。
        <p>
          1.1
          場所を保存します:私達は法律法規の規定に従って、中華人民共和国内の運営の過程で収集して発生するあなたの箇人情報を中華人民共和国内で保存します。現在、我々は上記の情報を国外に転送しません。もし、我々は国外に転送する場合、厳格に中国の関連法律、監督管理政策を遵守し、関連国の規定に従って、またはあなたの同意を求めます。
        </p>
        <p>
          1.2
          保存期間:私たちは常に法律の規定に従って合理的に必要な期間内にあなたの箇人情報を保存することを約束します。期限を過ぎた場合は、個人情報の削除または匿名化を行います。
        </p>
        <p>
          1.3
          運営を停止した場合は、速やかに個人情報の収集を停止し、停止のお知らせを逐一お届けまたはお知らせするとともに、お持ちの個人情報の削除や匿名化を行います。
        </p>
        <p>2.保護します</p>
        <p>
          2.1
          弊社はあなたの個人情報の安全性を非常に重視しており、ご提供する個人情報が不正に利用されたり、公開されたり、使用されたり、修正されたり、破損されたり、紛失されたり、漏洩したりすることを防止するために、技術面および管理面を含む合理的なセキュリティ対策を講じます。今のところ我々は通過しています
          <b>国家情報安全等級保護(三級)の評価と備案</b>
        </p>
        <p>
          2.2
          業界の暗号化技術、匿名化処理、および関連する合理的で実行可能な手段を使用してあなたの個人情報を保護し、悪意のある攻撃からあなたの個人情報を保護するためのセキュリティ保護機構を使用します。
        </p>
        <p>
          2.3
          私たちは、あなたの箇人情報の安全性を保証するためのセキュリティ管理システム、データセキュリティプロセスを確立します。許可者のみがあなたの個人情報にアクセスできるよう、厳格なデータ利用とアクセスシステムを採用しています。
        </p>
        <p>
          2.4
          <b>
            このような合理的かつ効果的な対策を講じており、法律で定められた基準を遵守しているにもかかわらず、インターネット業界では、技術的な制約やさまざまな悪意のある手段が存在する可能性があり、あらゆるセキュリティ対策を講じても、常に情報が100%安全であるとは限りません。ご提供いただいた個人情報の安全性を確保できるように努めます。
          </b>
        </p>
        <p>
          2.5
          <b>
            弊社のサービスにアクセスするために使用されるシステムと通信ネットワークは、弊社の制御可能な範囲外の要因によって問題が発生する可能性があることをご理解いただきました。そのため、複雑なパスワードを使用すること、定期的にパスワードを変更すること、自分のアカウントのパスワードや個人情報を他人に公開しないことなど、個人情報の保護に積極的に取り組むことを強くお勧めします。
          </b>
        </p>
        <p>
          2.6
          私たちは、緊急対応マニュアルを作成し、ユーザー情報セキュリティイベントが発生した場合、すぐに緊急対応マニュアルを起動し、セキュリティイベントの影響と結果の拡大を防ぐために努力します。ユーザー情報セキュリティ事件(漏洩、紛失)が発生した後、私達は法律法規の要求に従って、タイムリーにお知らせします:セキュリティ事件の基本状況と可能な影響、私達が取ったあるいはこれから取る処置、あなたは自主的に防止してリスクを減らすことができる提案、あなたに対する救済措置。私達は直ちに事件の関連状況をプッシュ通知、メール、手紙、ショートメッセージ及び関連する形式であなたに知らせて、逐一知らせることが難しい時、私達は合理的で、効果的な方式を取って公告を発表します。同時に、関系監督管理部門の要求に従って、ユーザー情報セキュリティ事件の処置状況を報告します。
        </p>
        <p>
          2.7
          当プラットフォームおよびODAILYサービスを離れ、他のウェブサイト、サービス、コンテンツリソースを閲覧または使用した場合、当プラットフォームおよびODAILYサービス以外のソフトウェア、ウェブサイトから提出された箇人情報を保護する能力および直接の義務はありません。
        </p>
        <h6>7.個人情報はどのように管理されていますか</h6>
        <p>1.あなたの箇人情報を照会、修正する権利があります</p>
        <p>
          私たちはあなたの箇人情報への関心を非常に重視し、箇人情報の照会と修正に対するあなたの権利を保護するための様々な機能を提供しています。
        </p>
        <p>1.1アカウント情報を管理します</p>
        <p>
          「私の」→「アカウント管理」この機能でパスワードを変更することで、携帯電話との連携や第三者のアカウントとの連携が可能になります。
        </p>
        <p>
          「私の」→「アカウントの名刺」→「編集」プロフィール画像、ニックネーム、性別、誕生日、プロフィール、id、業界などのアカウント情報を編集資料で見て修正することができます。
        </p>
        <p>1.2コメントや記事情報を管理します</p>
        <p>
          「私の」→「個人ページ」投稿されたコメントや記事をクリックすると、投稿されたコメントや記事の内容を知ることができます。
        </p>
        <p>1.3閲覧履歴を管理します</p>
        <p>「私の」→「閲覧」閲覧履歴を見ることができます。</p>
        <p>1.4注目記録を管理します</p>
        <p>
          「私の」→
          「フォロー」でフォローリストを管理し、フォローしているユーザー、著者、トピックを見たり修正したりすることができます。
        </p>
        <p>
          「私の」→「お気に入り」お気に入りの記事や音声、動画などを管理できます。
        </p>
        <p>
          ライセンスの変更後、当プラットフォームが提供するODAILYサービスを変更します。ただし、これまでの許可に基づいて行われてきた個人情報の取り扱いに影響を及ぼすものではないことをご理解ください。
        </p>
        <p>2.個人情報を削除します</p>
        <p>
          当プラットフォームに投稿された情報の削除を支援するため、以下の方法をご提供いたします。
        </p>
        <p>2.1コメントや記事情報を削除します</p>
        <p>
          「私の」→「個人ページ」投稿されたコメントや記事をクリックすると、投稿されたコメントや記事を長押しでいつでも削除できます。しかし、あなたはすでに投稿されたコメントや文章を削除して、その公開展示を取り消すだけで、私たちはまだ法律法規に基づいて、あなたが投稿したコメントや文章の舞台裏の保存を行う必要があります。
        </p>
        <p>2.2閲覧履歴の削除</p>
        <p>「私の」→「閲覧」閲覧履歴を削除することができます。</p>
        <p>2.3フォロー記録を削除します</p>
        <p>
          「私の」→「フォロー」フォローしているユーザー、著者、テーマを削除することができます。
        </p>
        <p>
          「私の」→「お気に入り」お気に入りの記事、音声、動画、その他を削除することができます。
        </p>
        <p>
          2.4
          以下の場合、私たちは自発的にあなたの箇人情報を削除します。削除されていない場合は、メールにて個人情報の削除をお願いします。
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            (1)処理目的が達成された、達成されない、または処理目的を達成するために不要であること。
          </li>
          <li>
            (2)当社は製品またはサービスの提供を停止し、または保存期間が満了しました。
          </li>
          <li>(3)同意を撤回します;</li>
          <li>
            (4)私たちは法律、行政法規または約定に違反して箇人情報を処理します。
          </li>
          <li>(5)その他法律、行政法規で定められている場合</li>
        </ul>
        <p>
          あなたが情報を削除した後、私達はそれに従って当プラットフォームがあなたに提供するODAILYのサービスを調整します。ただし、これまでの許可に基づいて行われてきた個人情報の取り扱いに影響を及ぼすものではないことをご理解ください。
        </p>
        <p>3.あなたの箇人情報のコピーを取得します</p>
        <p>
          あなたの箇人情報のコピーを取得する必要がある場合は、にメールを送信することができます、私たちは書面の要求に基づいて私たちが収集されているあなたの箇人情報を提供します。
        </p>
        <p>4.個人情報の許可を撤回する権利があります</p>
        <p>
          デバイス本体のオペレーティングシステムで、GPSロケーション、カメラ、マイク、アルバムの電源を切ったり、許可範囲を変更したり、許可を撤回したりすることができます。許可停止後はこれらの権限に関する情報は収集しません。に応じて箇人情報を収集することを拒否します。ライセンス撤回後も、ライセンス撤回に対応したODAILYサービスの提供は継続できないことをご理解ください。
          <b>
            ただし、承認の撤回は、これまでの承認に基づく個人情報の取り扱いには影響しません。
          </b>
        </p>
        <p>5.個人情報移転権</p>
        <p>
          あなたの個人情報を他のプラットフォーム、企業、または組織に移動させる必要がある場合は、本プライバシーポリシー第11条に記載の方法でご連絡ください。私達はあなたの要求を審査して、法律の定める箇人情報の移転の前提を備えた条件の下で、私達はあなたに該当の箇人情報の移転のルートを提供します。
        </p>
        <p>6.ODAILYアカウントを解約します</p>
        <p>
          ODAILYのアカウントをログアウトする場合は、「私の」→
          「アカウント管理」→「アカウントの解約」で申し込みを行います。アカウントを解約した後はODAILYサービスの提供を停止し、法令に基づき匿名化などの方法であなたの情報を保護します。
        </p>
        <p>7.マーケティング情報とターゲティングを自律的にコントロールします</p>
        <p>
          7.1
          メッセージプッシュ-メッセージ通知を受信したくない場合は、最初にアプリをインストールするときにメッセージ通知を受信しないことができます。
          →「プッシュ管理」対応の通知機能をオフにします。
        </p>
        <p>
          7・2
          プロモーション/訪問-私たちのプロモーションショートメッセージや電話の訪問を受信したくない場合は、対応するキャンセル機能の情報を介してキャンセルすることができます、または電話の訪問を明確に拒否します。
        </p>
        <p>8.ご要望にお応えします</p>
        <p>下記の場合、法令に基づきご要望には応じられません。</p>
        <ul className={styles.privacy_ul}>
          <li>1)法律法規で定められた義務の履行に関するもの</li>
          <li>2)国家安全保障、国防安全保障に直結します。</li>
          <li>3)公共の安全、公衆衛生、重大な公共の利益に直結するもの</li>
          <li>4)刑事犯罪捜査、起訴、裁判や判決の執行などに直接関系するもの</li>
          <li>
            5)個人情報の主体に主観的悪意や権利の乱用があるという十分な証拠があります。
          </li>
          <li>
            6)箇人情報の主体または他の箇人の生命、財産などの重大な合法的権益を守るためにしかしまた本人の授権の同意を得ることが難しい;
          </li>
          <li>
            7)あなたの要求に応じてあなたまたは他の箇人、組織の正当な利益が深刻に損なわれます;
          </li>
          <li>8)営業秘密に関するもの</li>
        </ul>
        <h6>8.未成年者の個人情報保護</h6>
        <p>
          1.未成年者の個人情報の保護を非常に重視しています。関連法律法規の規定により、あなたが満18歳未満の未成年者である場合は、ODAILYサービスを利用する前に、あなたの両親または他の保護者の監護、指導の下で共同で本プライバシーポリシーを読み、同意しなければなりません。
        </p>
        <p>
          2。私たちは、国の関連法律法規の規定に基づいて未成年者の箇人情報を保護しますが、法律の許可、両親や他の保護者の明確な同意や未成年者を保護するために必要な場合は、収集、使用、保存、共有、譲渡または開示未成年者の箇人情報;保護者の同意を得ずに未成年者の個人情報を収集していることが判明した場合は、速やかに削除するようにしています。
        </p>
        <p>
          3.未成年者の保護者である場合、保護している未成年者の箇人情報についてご質問がある場合は、本プライバシーポリシー第11条に記載の方法でご連絡ください。
        </p>
        <h6>9.プライバシーポリシーの改定とお知らせ</h6>
        <p>
          1.より良いサービスをご提供するために、弊社のODAILYサービスは随時変更させていただきます。
        </p>
        <p>
          2.明示的な同意がなければ、このプライバシーポリシーに基づいてあなたが享受すべき権利を削減することはありません。通過します
          アプリ
          このプライバシーポリシーの変更をすぐに知ることができるように、クライアントのプッシュ通知、ウィンドウ形式などの合理的な方法でお知らせします。「私の」を通します
          →「さらなるサービス」→「プライバシーポリシー」このプライバシーポリシーの最新の内容をご覧ください。
        </p>
        <p>
          3.重大な変更については、状況に応じて、このプライバシーポリシーの具体的な変更内容を説明するより顕著な通知を提供します。主要な変更には以下が含まれますがこれらに限定されません。
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1)私たちのサービスモデルが大きく変わりました。箇人情報を扱う目的、扱う箇人情報の種類、箇人情報の利用方法など
          </li>
          <li>
            2)私たちの所有構造、組織構造などの面で大きな変化があります。例えば、業務調整、破産m
            & aなどによる所有者の変更など
          </li>
          <li>3)個人情報の共有、譲渡、開示の主な対象が変わります。</li>
          <li>
            4)あなたの個人情報の処理に関する権利とその行使方法が大きく変わります。
          </li>
          <li>
            5)私たちは、個人情報のセキュリティの責任部門、連絡先や苦情のチャネルの変更を処理する責任があります。
          </li>
          <li>
            6)個人情報セキュリティ影響評価報告書にリスクがあることが判明した場合
          </li>
        </ul>
        <p>
          4.改正されたプライバシーポリシーに同意しない場合は、ODAILYサービスの利用を直ちに停止する権利があります。ただし、改正されたプライバシーポリシーに同意されない場合、関連するODAILYサービスと一部の機能を完全に正常に使用できない、または当社が期待するサービスを達成できない可能性があることをご理解ください。
        </p>
        <h6>10.亡くなったユーザーの個人情報を保護しています</h6>
        <p>
          2021年11月1日より、個人情報保護法に基づく死者情報保護制度がスタートします。ODAILYユーザー(自然人のみ)が亡くなった場合、その近親者は自身の合法的かつ正当な利益のために、このプライバシーポリシー第11条が提供する連絡手段を通じて、亡くなったユーザーの個人情報に対する参照、コピー、訂正、削除などの権利を行使することができますが、亡くなったユーザーが生前に別途の手配をした場合は除きます。
        </p>
        <h6>11.どのようにご連絡くださいますか</h6>
        <p>
          個人情報保護に関するご苦情、ご提案、ご質問、またはプライバシーポリシーに関するご質問があれば、下記の方法でご連絡ください。早急に問題を検討し、ユーザーの身元を確認してから15営業日以内に回答します。
        </p>
        <p>1. までメールを送って私ども箇人情報保護担当者に連絡します。</p>
        <p>
          2.「私からの」→「ご意見のフィードバック」→「その他のご質問」でご連絡ください。
        </p>
        <h6>12.紛争解決</h6>
        <p>
          このプライバシーポリシーと私たちはあなたの箇人情報の取り扱いについての任意の論争を引き起こすため、あなたは北京市朝陽区人民法院に提訴することができます。私達の箇人情報の処理行為があなたの合法的な利益を損なうと思うならば、あなたも関系の政府部門に反映することができます。
        </p>
      </div>
    );
  }
}

export default PrivacyJa;
