import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cookies from 'js-cookie';
import styles from './index.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import Layout from '../../components/common/layout';
import HomeLoadMore from '../../components/common/homeloadmore';
import LibraryItem from '../../components/libraryItem';
import { PostItem } from '../../components/post';
import NewsFlashItem from '../../components/topic/newsFlashItem';
import NewLibrary from '../../components/newLibrary';

import { loginFlag } from '../../common/scripts/login';
import {
  getLibrary,
  getLibraryFeed,
  deleteLibrary,
  favoriteLibrary
} from '../../common/api/library';
import { getTopicById } from '../../common/api/topic';

import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

export class Library extends Component {
  static propTypes = {
    libraryFeed: PropTypes.object.isRequired,
    libraryData: PropTypes.object.isRequired,
    libraryTopic: PropTypes.array.isRequired,
    // postFavoriteList: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object
  };

  static fetchData(store, match, res) {
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise((resolve, reject) => {
      const id = match.params.id;
      if (_.isNaN(Number(id))) {
        res.redirect(postHref);
      }

      getLibraryFeed(id, undefined, undefined, lang)
        .then(data => {
          if (data.data && data.data.data) {
            let obj = {
              list: data.data.data,
              page: {
                page: data.data.current_page + 1,
                per_page: data.data.per_page,
                total: data.data.total
              }
            };
            store.dispatch({
              type: 'SET_LIBRARY_FEED',
              data: obj
            });
          }
          return getLibrary(id, lang);
          // return postFavorite('post', id);
        })
        // .then(data => {
        //   if (data.data && data.data.length) {
        //     let list = data.data
        //     store.dispatch({
        //       type: 'SET_LIBRARY_POST_FAVORITE',
        //       list
        //     });

        //     return getLibrary(id);
        //   }
        // })
        .then(data => {
          if (data.data) {
            store.dispatch({
              type: 'SET_LIBRARY_DETAIL',
              data: data.data
            });

            if (data.data.monographic_id) {
              return getTopicById(data.data.monographic_id, lang);
            } else {
              resolve();
            }
          }
        })
        .then(data => {
          if (data && data.data && data.data.list) {
            store.dispatch({
              type: 'SET_LIBRARY_TOPIC',
              data: data.data.list.layout_data
            });
            resolve();
          }
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      libraryList: props.libraryFeed.list,
      libraryPage: props.libraryFeed.page,
      deleteFlag: false,
      alertFlag: '',
      is_favorite: props.libraryData.is_favorite,
      newLibraryFlag: false,
      deleteLoading: true
    };
  }

  isLogin() {
    return cookies.get('kr_plus_id');
  }

  toLogin() {
    const { dispatch } = this.props;
    loginFlag(dispatch, true);
  }

  componentDidMount() {}

  loadMore() {
    const { libraryPage, libraryList } = this.state;
    const { page, per_page } = libraryPage;
    const { libraryData } = this.props;
    getLibraryFeed(libraryData.id, page, per_page).then(data => {
      if (data.data && data.data.data) {
        this.setState({
          libraryList: libraryList.concat(data.data.data),
          libraryPage: {
            page: data.data.current_page + 1,
            per_page: data.data.per_page,
            total: data.data.total
          }
        });
      }
    });
  }

  changeNewLibraryFlag() {
    if (this.isLogin()) {
      const { newLibraryFlag } = this.state;

      this.setState({ newLibraryFlag: !newLibraryFlag });
    } else {
      this.toLogin();
    }
  }

  changeDeleteFlag() {
    if (this.isLogin()) {
      const { deleteFlag } = this.state;

      this.setState({ deleteFlag: !deleteFlag });
    } else {
      this.toLogin();
    }
  }

  changeAlertFlag(text) {
    this.setState({ alertFlag: text });

    setTimeout(() => {
      this.setState({ alertFlag: '' });
    }, 3000);
  }

  performDeletion() {
    const { libraryData, match } = this.props;
    const { deleteLoading } = this.state;
    if (deleteLoading) {
      this.setState({ deleteLoading: false });
      deleteLibrary(libraryData.id).then(data => {
        if (data.code === 0) {
          this.changeAlertFlag('已删除');
          this.changeDeleteFlag();
          if (
            window.history.length > 1 &&
            document.referrer != window.document.href
          ) {
            location.replace(document.referrer);
          } else {
            location.pathname = '/' + match.params.lang + '/my';
          }
        }
      });
    }
  }

  changeFavorite() {
    if (this.isLogin()) {
      const { libraryData } = this.props;
      const { is_favorite } = this.state;
      favoriteLibrary(libraryData.id).then(data => {
        if (data.code === 0) {
          this.setState({ is_favorite: !is_favorite });
        }
      });
    } else {
      this.toLogin();
    }
  }

  changeLibraryList(index) {
    let { libraryList } = this.state;
    libraryList.splice(index, 1);
    this.setState({ libraryList });
  }

  renderBreadcrumb() {
    const { is_default, monographic_id } = this.props.libraryData;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.library_breadcrumb}>
        <a href="/my">{locales(lang, 'personal_center')}</a>

        <span className={styles.library_breadcrumb_icon}>＞</span>

        <span>
          {is_default
            ? locales(lang, 'my_collection')
            : monographic_id
            ? locales(lang, 'recommended_library')
            : locales(lang, 'create_library')}
        </span>
      </div>
    );
  }

  renderHeader() {
    const {
      name,
      cover,
      created_at,
      summary,
      user,
      oneself
    } = this.props.libraryData;
    const { lang } = this.props.match.params;
    const { is_favorite } = this.state;

    return (
      <div className={styles.library_header}>
        <div
          className={styles.library_header_cover}
          style={{ backgroundImage: `url(${cover})` }}
        />

        <div className={styles.library_header_name}>{name}</div>
        <div className={styles.library_header_summary}>{summary}</div>
        <div className={styles.library_header_user}>
          <div className={styles.library_header_user_left}>
            {user && user.avatar_url ? (
              <div
                className={styles.library_header_user_avatar}
                style={{ backgroundImage: `url(${user.avatar_url})` }}
              />
            ) : (
              ''
            )}

            {user && user.name ? (
              <div className={styles.library_header_user_name}>{user.name}</div>
            ) : (
              ''
            )}

            {created_at ? (
              <div className={styles.library_header_user_time}>
                {locales(lang, 'creation_time')}：{created_at.slice(0, 10)}
              </div>
            ) : (
              ''
            )}
          </div>

          {this.isLogin() && oneself ? (
            <div className={styles.library_header_user_right}>
              <div
                className={styles.library_header_button}
                onClick={() => {
                  this.changeNewLibraryFlag();
                }}
              >
                <div className={styles.library_header_button_edit_icon} />
                {locales(lang, 'edit')}
              </div>

              <div
                className={styles.library_header_button}
                onClick={() => {
                  this.changeDeleteFlag();
                }}
              >
                <div className={styles.library_header_button_delete_icon} />
                {locales(lang, 'delete')}
              </div>
            </div>
          ) : (
            <div
              className={styles.library_header_button}
              onClick={() => {
                this.changeFavorite();
              }}
            >
              <div
                className={
                  is_favorite
                    ? styles.library_header_button_collect_icon_is_favorite
                    : styles.library_header_button_collect_icon
                }
              />
              {is_favorite
                ? locales(lang, 'collected')
                : locales(lang, 'collect')}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderTopic() {
    const { libraryTopic } = this.props;

    return (
      <div className={styles.library_topic}>
        {libraryTopic.map((list, index) => {
          return (
            <div
              className={styles.library_topic_list}
              key={`library_topic_list${index}`}
              style={{
                marginTop:
                  list.childList[0].type === 'newsflash' ? '47px' : null
              }}
            >
              {list.childList && list.childList[0]
                ? list.childList[0].data.items.map((item, index) => {
                    if (list.childList[0].type === 'post') {
                      const extraction_tags = item.extraction_tags
                        ? JSON.parse(item.extraction_tags)
                        : [];
                      const props = Object.assign(item, {
                        type: 'normal',
                        entity_id: item.id
                      });

                      return (
                        <PostItem
                          key={item.entity_id}
                          {...props}
                          extraction_tags={extraction_tags}
                        />
                      );
                    } else if (list.childList[0].type === 'newsflash') {
                      return (
                        <NewsFlashItem
                          key={`library_topic_newsflashItem${index}`}
                          {...item}
                        />
                      );
                    }
                  })
                : ''}
            </div>
          );
        })}
      </div>
    );
  }

  renderAlert() {
    const { deleteFlag, alertFlag, deleteLoading } = this.state;
    const { lang } = this.props.match.params;
    return (
      <div>
        {deleteFlag ? (
          <div
            onClick={() => {
              this.changeDeleteFlag();
            }}
            className={styles.library_alert_bg}
          />
        ) : (
          ''
        )}

        {deleteFlag ? (
          <div className={styles.library_delete_box}>
            <div
              className={styles.library_delete_box_close}
              onClick={() => {
                this.changeDeleteFlag();
              }}
            />
            <div className={styles.library_delete_box_title}>
              {locales(lang, 'delete_library')}
            </div>
            <div className={styles.library_delete_box_text}>
              {locales(lang, 'confirm_delete_library')}
            </div>
            <div className={styles.library_delete_box_summary}>
              {locales(lang, 'delete_collection_folder')}
            </div>
            <div className={styles.library_delete_box_button_list}>
              <div
                className={styles.library_delete_box_button}
                onClick={() => {
                  this.changeDeleteFlag();
                }}
              >
                {locales(lang, 'cancel')}
              </div>
              <div
                className={styles.library_delete_box_button}
                onClick={() => {
                  this.performDeletion();
                }}
                style={{ background: deleteLoading ? null : '#999' }}
              >
                {deleteLoading
                  ? locales(lang, 'confirm')
                  : locales(lang, 'loading_title')}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {alertFlag ? <div className={styles.library_tip}>{alertFlag}</div> : ''}
      </div>
    );
  }

  renderNoContent() {
    const { lang } = this.props.match.params;
    return (
      <div className={styles.library_noContent}>
        <div className={styles.library_noContent_img} />
        <div className={styles.library_noContent_text}>
          {locales(lang, 'no_related_content')}
        </div>
      </div>
    );
  }

  render() {
    const { libraryData, libraryTopic, match } = this.props;
    const { is_default, monographic_id, id, oneself } = libraryData;
    const { libraryList, libraryPage, newLibraryFlag } = this.state;
    const { total, page, per_page } = libraryPage;
    const { lang } = match.params;
    let helmetData = getHelmet(
      {
        titleFlag: true,
        twitterImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
        ogImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
      },
      lang
    );
    return (
      <React.Fragment>
        {/* 管理文档头，可以通过js动态的改变标签内的内容 */}
        <Helmet {...helmetData} />
        <Layout>
          <div className={styles.library}>
            <div className={styles.library_wrapper}>
              {this.isLogin() ? this.renderBreadcrumb() : ''}

              {is_default ? (
                <div className={styles.library_is_default}>
                  <div className={styles.library_is_default_wrapper}>
                    {locales(lang, 'my_collection')}
                  </div>
                </div>
              ) : (
                this.renderHeader()
              )}

              {monographic_id ? (
                this.renderTopic()
              ) : (
                <div className={styles.library_list}>
                  {libraryList.map((item, index) => {
                    if (item.favoritable_type === 'post' && item.entity) {
                      return (
                        <div
                          className={styles.library_list_item}
                          key={`library_list_item${index}`}
                        >
                          <LibraryItem
                            {...item.entity}
                            type={item.favoritable_type}
                            share_data={
                              item.share_data ? item.share_data.default : {}
                            }
                            oneself={oneself}
                            index={index}
                            copyFunc={this.changeAlertFlag.bind(this)}
                            callBack={this.changeLibraryList.bind(this)}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              )}

              {total > (page - 1) * per_page ? (
                <div className={styles.libraryList_button}>
                  <HomeLoadMore
                    loading={false}
                    loaded={false}
                    onClick={this.loadMore.bind(this)}
                  />
                </div>
              ) : (
                ''
              )}

              {libraryList.length === 0 && libraryTopic.length === 0
                ? this.renderNoContent()
                : ''}

              {this.renderAlert()}
            </div>
          </div>
        </Layout>

        <NewLibrary
          alertFlag={newLibraryFlag}
          id={id}
          libraryData={libraryData}
          changeAlertFlag={this.changeNewLibraryFlag.bind(this)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    libraryFeed: state.library.feedData,
    libraryData: state.library.detail,
    libraryTopic: state.library.topic
    // postFavoriteList: state.library.postFavoriteList
  };
};

export default withRouter(connect(mapStateToProps)(Library));
