import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import CollectLibrary from '../collectLibrary';

import { copyText } from '../../common/scripts/copyText';
import { setFavorite, postFavorite } from '../../common/api/library';
import { withRouter } from 'react-router-dom';
import styles from './index.scss';
import locales from '../../utils/locales';

export class LibraryItem extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    oneself: PropTypes.number.isRequired,
    cover: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    share_data: PropTypes.object.isRequired,
    copyFunc: PropTypes.func,
    callBack: PropTypes.func,
    match: PropTypes.object
  };

  static defaultProps = {
    id: 0,
    cover: '',
    title: '',
    summary: '',
    share_data: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      weiboUrl: '',
      twitterUrl: '',
      postItem: {},
      alertFlag: false,
      postFavoriteList: []
    };
  }

  getUrl() {
    const { index } = this.props;
    const { title, url } = this.props.share_data;
    const shareQRCodeCanvas = document.getElementById(`qrcode${index}`);
    QRCode.toCanvas(shareQRCodeCanvas, url, { width: 52 });

    const weiboUrl = `http://service.weibo.com/share/share.php?&appkey=2523671237&content=utf-8&url=${url}&title=${title +
      ' @ODAILY '}&sourceUrl=${url}
    `;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}&via=OdailyChina`;

    this.setState({
      weiboUrl,
      twitterUrl
    });
  }

  getPostFavoriteList() {
    const { id } = this.props;
    postFavorite('post', id).then(data => {
      if (data.data && data.data.length) {
        this.setState({ postFavoriteList: data.data });
      }
    });
  }

  componentDidMount() {
    this.getUrl();
    this.getPostFavoriteList();
  }

  myCopyText() {
    const { copyFunc, share_data } = this.props;
    const { url } = share_data;

    copyText(url).then(() => {
      if (copyFunc) {
        copyFunc('复制链接成功');
      }
    });
  }

  mySetFavorite() {
    const { id, type, index, callBack } = this.props;
    const { postItem } = this.state;
    setFavorite(type, id, `${postItem.id}`).then(data => {
      if (data.code === 0) {
        this.changeAlertFlag();
        if (callBack) {
          callBack(index);
        }
      }
    });
  }

  // changeCollectFlag(postItem) {
  //   this.setState({ postItem });
  //   this.changeAlertFlag();
  // }

  changeAlertFlag() {
    const { alertFlag } = this.state;
    this.setState({ alertFlag: !alertFlag });
  }

  // renderAlert() {
  //   const { alertFlag, postItem } = this.state;
  //   return (
  //     <div>
  //       {alertFlag ? (
  //         <div
  //           onClick={() => {
  //             this.changeAlertFlag();
  //           }}
  //           className={styles.libraryItem_alert_bg}
  //         ></div>
  //       ) : (
  //         ''
  //       )}

  //       {alertFlag ? (
  //         <div className={styles.libraryItem_delete_box}>
  //           <div
  //             className={styles.libraryItem_delete_box_close}
  //             onClick={() => {
  //               this.changeAlertFlag();
  //             }}
  //           ></div>
  //           <div className={styles.libraryItem_delete_box_title}>移动确认</div>

  //           <div className={styles.libraryItem_delete_box_text}>
  //             你确认将该内容移至“<span>{postItem.name}</span>”吗？
  //           </div>

  //           <div className={styles.libraryItem_delete_box_button_list}>
  //             <div
  //               className={styles.libraryItem_delete_box_button}
  //               onClick={() => {
  //                 this.changeAlertFlag();
  //               }}
  //             >
  //               取消
  //             </div>
  //             <div
  //               className={styles.libraryItem_delete_box_button}
  //               onClick={() => {
  //                 this.mySetFavorite();
  //               }}
  //             >
  //               确认
  //             </div>
  //           </div>
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //     </div>
  //   );
  // }

  render() {
    const { id, cover, title, summary, index, oneself, match } = this.props;
    const { weiboUrl, twitterUrl, alertFlag, postFavoriteList } = this.state;
    const { lang } = match.params;
    return (
      <div className={styles.libraryItem_wrapper}>
        <div className={styles.libraryItem}>
          <div className={styles.libraryItem_left}>
            <a href={`/post/${id}`} target="_blank" rel="noopener noreferrer">
              <div className={styles.libraryItem_title}>{title}</div>
              <div
                className={styles.libraryItem_summary}
                style={{ WebkitBoxOrient: 'vertical' }}
              >
                {summary}
              </div>
            </a>

            <div className={styles.libraryItem_left_footer}>
              <div className={styles.libraryItem_share}>
                <div className={styles.libraryItem_share_icon} />
                {locales(lang, 'share')}
                <div className={styles.libraryItem_share_box}>
                  <div
                    className={styles.libraryItem_share_box_item}
                    style={{
                      width: !lang || lang == 'zhtw' ? '88px' : '140px'
                    }}
                    onClick={() => {
                      this.myCopyText();
                    }}
                  >
                    <div className={styles.libraryItem_share_box_item_icon} />
                    {locales(lang, 'copyLink')}
                  </div>

                  <a
                    href={twitterUrl}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className={styles.libraryItem_share_box_item}
                  >
                    <div
                      className={styles.libraryItem_share_box_twitter_icon}
                    />
                    Twitter
                  </a>

                  <a
                    href={weiboUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.libraryItem_share_box_item}
                  >
                    <div className={styles.libraryItem_share_box_weibo_icon} />
                    {locales(lang, 'weibo')}
                  </a>

                  <div className={styles.libraryItem_share_box_item}>
                    <div className={styles.libraryItem_share_box_weixin_icon} />
                    {locales(lang, 'scan')}
                  </div>

                  <div className={styles.libraryItem_share_box_item_img}>
                    <canvas id={`qrcode${index}`} />
                  </div>
                </div>
              </div>

              {oneself ? (
                <div
                  className={styles.libraryItem_collect}
                  onClick={() => {
                    this.changeAlertFlag();
                  }}
                >
                  <div className={styles.libraryItem_collect_icon} />
                  {locales(lang, 'mobile')}
                  {/* <div className={styles.libraryItem_collect_box}>
                    {postFavoriteList.map((item, index) => {
                      return (
                        <div
                          key={`libraryItem_collect_item${index}`}
                          className={styles.libraryItem_collect_item}
                          onClick={() => {
                            this.changeCollectFlag(item);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div> */}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <a
            href={`/post/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.libraryItem_right}
            style={{ backgroundImage: `url(${cover})` }}
          />

          {/* {this.renderAlert()} */}
        </div>
        {alertFlag ? (
          <CollectLibrary
            collectFlag={alertFlag}
            changeCollectFlag={this.changeAlertFlag.bind(this)}
            postFavoriteList={postFavoriteList}
            id={id}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withRouter(LibraryItem);
