import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import animate from 'animate.css';
import { timesAgo } from '../../../common/scripts/time';
import { dot } from '../../../common/scripts/dot';

// import _ from 'lodash';

import ImgDefaultAvatar from '../../../assets/image/default-author-avatar.png';
import { withRouter } from 'react-router-dom';

const summarySlug = summary => {
  if (summary !== undefined) {
    summary.length > 76 ? summary.substr(0, 76) + '...' : summary;
  } else {
    ('');
  }
};

class rHotPostItem extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['normal', 'small']),
    sort: PropTypes.number,
    e_id: PropTypes.number,
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    cover: PropTypes.string.isRequired,
    column: PropTypes.object,
    published_at: PropTypes.string.isRequired,
    highlightTitle: PropTypes.string,
    highlightSummary: PropTypes.string,
    currentUser: PropTypes.object,
    targetFlag: PropTypes.string,
    match: PropTypes.string
  };
  static defaultProps = {
    type: 'normal',
    user: {}
  };
  constructor(props) {
    super(props);
    this.coverImage = React.createRef();
  }
  componentDidMount() {
    if (this.coverImage.current) {
      const imgNode = this.coverImage.current;
      const fadeInStyle = animate['fadeIn'];
      const url = imgNode.dataset.src;
      const image = new Image();
      image.src = url;
      image.onload = function() {
        imgNode.src = url;
        imgNode.style.opacity = 1;
        imgNode.classList.add(fadeInStyle);
      };
    }
  }
  renderUserInfo(user) {
    const { lang } = this.props.match.params;
    return (
      <a
        className={styles['link']}
        href={lang ? `/${lang}/user/${user.id}` : `/user/${user.id}`}
      >
        <span className={styles['avatar']}>
          <img
            src={user.avatar_url || ImgDefaultAvatar}
            alt={user.name}
            loading="lazy"
          />
        </span>
        <span className={styles['name']}>{user.name}</span>
        <span className={styles['dot']}>&middot;</span>
      </a>
    );
  }
  renderHightlightTitle() {
    const markup = () => {
      return {
        __html: this.props.highlightTitle
      };
    };
    return (
      <h3 className={styles['post-title']} dangerouslySetInnerHTML={markup()} />
    );
  }
  renderHightlightSummary() {
    const markup = () => {
      return {
        __html: this.props.summary
      };
    };
    return (
      <p
        className={styles['post-summary']}
        dangerouslySetInnerHTML={markup()}
      />
    );
  }
  renderNormal() {
    const {
      e_id,
      title,
      summary,
      cover,
      column,
      user,
      published_at,
      highlightTitle,
      match
      // highlightSummary
    } = this.props;
    const { lang } = match.params;
    const postHref = lang ? `/${lang}/post` : `/post`;
    return (
      <div className={styles.container}>
        <div className={styles['post-cover']}>
          <a
            className={`${styles['cover']} ${animate.animated} ${animate.fadeIn}`}
            href={`${postHref}/${e_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ backgroundImage: `url(${cover})` }}
          >
            {column ? (
              <span className={styles['post-column']}>{column.name}</span>
            ) : null}
          </a>
        </div>
        <div className={styles['post-intro']}>
          <a
            className={styles['post-title-link']}
            href={`${postHref}/${e_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {highlightTitle ? (
              this.renderHightlightTitle()
            ) : (
              <h3 className={styles['post-title']}>{title}</h3>
            )}
          </a>
          {summary ? (
            this.renderHightlightSummary()
          ) : (
            <p className={styles['post-summary']}>{summarySlug(summary)}</p>
          )}
          <div className={styles['post-info']}>
            <div className={styles['post-poster']}>
              {user.id ? this.renderUserInfo(user) : null}
              <span className={styles['published-time']}>
                <p>{timesAgo(published_at, undefined, lang)}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderSmall() {
    const { cover, title, id, e_id, sort, targetFlag, match } = this.props;
    let postId = id ? id : e_id;
    const { lang } = match.params;
    const postHref = lang ? `/${lang}/post` : `/post`;
    return (
      <div className={styles['small-container']}>
        {sort === 1 ? (
          <div className={styles.cover}>
            <a
              href={`${postHref}/${postId}`}
              target={targetFlag ? targetFlag : '_self'}
              className={styles.cover_wrapper}
              onClick={() => {
                dot({ id: 22, user_id: this.props.currentUser.id });
              }}
            >
              <img src={cover} alt={title} loading="lazy" />
              <div className={styles.cover_title}>
                <p style={{ WebkitBoxOrient: 'vertical' }}>{title}</p>
              </div>
            </a>
          </div>
        ) : (
          <div className={styles.small_title}>
            <div className={styles[`small_index${sort}`]} />
            <a
              href={`${postHref}/${postId}`}
              target={targetFlag ? targetFlag : '_self'}
              onClick={() => {
                dot({ id: 22, user_id: this.props.currentUser.id });
              }}
              style={{ WebkitBoxOrient: 'vertical' }}
            >
              {title}
            </a>
          </div>
        )}
      </div>
    );
  }
  render() {
    if (this.props.type === 'normal') {
      return this.renderNormal();
    }
    if (this.props.type === 'small') {
      return this.renderSmall();
    }
  }
}
export const HotPostItem = withRouter(rHotPostItem);
export default rHotPostItem;
