import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

const IconArrowPrev = require('../../assets/image/icon-arrow-left.png');
const IconArrowNext = require('../../assets/image/icon-arrow-right.png');

export default class activitySlide extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    font: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      interval: 5000,
      speed: 300,
      activeIndex: 0,
      data: [...this.props.list, this.props.list[0]],
      pausedFlag: false,
      classFlag: false,
      jianFlag: false,
      previous: 0
    };
  }

  componentDidMount() {
    let { interval } = this.state;
    if (this.props.list.length > 1) {
      this.autoTimer = setInterval(this.autoMove.bind(this), interval);
    }
  }

  autoMove() {
    let { activeIndex, speed, data } = this.state;
    activeIndex++;
    this.setState({
      classFlag: false
    });
    if (activeIndex >= data.length) {
      this.setState(
        {
          activeIndex: 0,
          speed: 0
        },
        () => {
          let a = this.wrapper.offsetLeft;
          activeIndex = 1;
          this.setState({
            activeIndex,
            speed,
            a
          });
        }
      );
      return;
    }
    this.setState({
      activeIndex,
      speed
    });
  }

  focusChange(index) {
    let { speed } = this.state;
    this.setState({
      activeIndex: index,
      speed
    });
  }

  buttonChange(dir) {
    let { activeIndex, data, speed, previous } = this.state;
    let now = Date.now();
    if (now - previous < speed) {
      return;
    }
    this.setState({
      previous: now
    });
    if (dir === 'left') {
      activeIndex--;
      if (activeIndex < 0) {
        this.setState(
          {
            activeIndex: data.length - 1,
            speed: 0
          },
          () => {
            let a = this.wrapper.offsetLeft;
            this.setState({
              activeIndex: data.length - 2,
              speed,
              a
            });
          }
        );
        return;
      }
      this.setState({
        activeIndex,
        speed
      });
      return;
    }

    setTimeout(() => {
      this.autoMove();
    }, 0);
  }

  renderSlideList() {
    let { data } = this.state;
    let { width } = this.props;
    return data.map((item, index) => {
      let listUrl = `/activity/${item.id}`;
      return (
        <div
          className={styles.slide}
          key={index}
          style={{ width: `${width}px` }}
        >
          {/* <div className={styles.activity_status}>
            {item.activity_status === 'starting' ? '进行中' : '已结束'}
          </div> */}
          <a href={listUrl} target={'_blank'} rel="noreferrer">
            <img src={item.cover} alt={item.title} loading="lazy" />
            <div
              className={styles.content}
              style={{
                display:
                  item.title.replace(/(^\s*)|(\s*$)/g, '').length == 0
                    ? 'none'
                    : null
              }}
            >
              <div
                className={styles.title}
                style={{
                  fontSize: `${this.props.font}px`,
                  lineHeight: `${this.props.font + 6}px`,
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {item.title}
              </div>

              <div className={styles.time}>{item.published_at}</div>
            </div>
          </a>
        </div>
      );
    });
  }

  renderPagination() {
    let { activeIndex, jianFlag } = this.state;
    let { list } = this.props;
    activeIndex === list.length ? (activeIndex = 0) : null;
    return (
      <div
        className={styles.pagination}
        style={{ display: jianFlag ? null : 'none' }}
      >
        {list.map((item, index) => {
          return (
            <span
              className={activeIndex === index ? styles.active : null}
              key={index}
              onClick={() => {
                this.focusChange(index);
              }}
            />
          );
        })}
      </div>
    );
  }

  render() {
    let { activeIndex, speed, data, interval, jianFlag } = this.state;
    let { width, height, list } = this.props;
    return (
      <div
        className={styles.container}
        style={{ width: `${width}px`, height: `${height}px` }}
        onMouseEnter={() => {
          clearInterval(this.autoTimer);
          this.setState({
            pausedFlag: true,
            classFlag: false,
            jianFlag: true
          });
        }}
        onMouseLeave={() => {
          this.autoTimer = setInterval(this.autoMove.bind(this), interval);
          this.setState({
            pausedFlag: false,
            classFlag: true,
            jianFlag: false
          });
        }}
      >
        <div
          className={styles.wrapper}
          ref={e => (this.wrapper = e)}
          style={{
            width: data.length * width,
            left: -activeIndex * width,
            transition: `left ${speed}ms linear`
          }}
        >
          {this.renderSlideList()}
        </div>
        {list.length > 1 ? (
          <div
            className={styles.button}
            style={{ display: jianFlag ? 'block' : 'none' }}
          >
            <a
              href="javascript:;"
              className={styles.buttom_prev}
              onClick={() => {
                this.buttonChange('left');
              }}
            >
              <img src={IconArrowPrev} alt="" loading="lazy" />
            </a>
            <a
              href="javascript:;"
              className={styles.buttom_next}
              onClick={() => {
                this.buttonChange('right');
              }}
            >
              <img src={IconArrowNext} alt="" loading="lazy" />
            </a>
          </div>
        ) : (
          ''
        )}
        {list.length > 1 ? this.renderPagination() : ''}
      </div>
    );
  }
}
