import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './index.scss';
import Layout from '../../components/common/layout';
import Helmet from 'react-helmet';

import wechatShare from '../../common/scripts/wechat';
import { getNavigationList } from '../../common/api/navigation';
import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

export class Navigation extends React.Component {
  static propTypes = {
    navigationList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired
  };
  static fetchData(store, match) {
    const { lang } = match.params;
    return new Promise((resolve, reject) => {
      getNavigationList(lang)
        .then(data => {
          store.dispatch({
            type: 'SET_NAV_LIST',
            list: data.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      flagList: {}
    };
  }
  componentDidMount() {
    wechatShare({});
    this.getFlag();
  }

  getFlag() {
    const { navigationList } = this.props;
    let obj = {};

    navigationList.map((item, index) => {
      obj[`flag${index}`] = true;
    });

    this.setState({ flagList: obj });
  }

  changeFlag(index) {
    const { flagList } = this.state;
    flagList[`flag${index}`] = !flagList[`flag${index}`];
    this.setState({ flagList });
  }

  render() {
    const { navigationList, match } = this.props;
    const { flagList } = this.state;
    const { lang } = match.params;
    let helmetData = getHelmet(
      {
        title: locales(lang, 'navagationTitle')
      },
      lang
    );
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.navigation}>
            {navigationList.map((item, index) => {
              let maxH = '1000px';
              if (this[`navigation_list${index}`]) {
                maxH = this[`navigation_list${index}`].scrollHeight;
              }
              return (
                <div
                  className={styles.navigation_item}
                  key={`navigation_item${index}`}
                >
                  <div className={styles.navigation_item_header}>
                    <div className={styles.navigation_item_title_box}>
                      <div
                        className={styles.navigation_item_icon}
                        style={{ backgroundImage: `url(${item.cover})` }}
                      />
                      <div className={styles.navigation_item_title}>
                        {item.name}
                      </div>
                    </div>

                    <div
                      className={styles.navigation_item_arrow}
                      style={{
                        transform: flagList[`flag${index}`]
                          ? null
                          : `rotate(180deg)`
                      }}
                      onClick={() => {
                        this.changeFlag(index);
                      }}
                    />
                  </div>

                  <div
                    className={styles.navigation_list}
                    ref={e => {
                      this[`navigation_list${index}`] = e;
                    }}
                    style={{
                      maxHeight: flagList[`flag${index}`] ? maxH : 0
                    }}
                  >
                    {item.items.map((link, linkIndex) => {
                      return (
                        <a
                          href={
                            link.web_link.includes('http')
                              ? link.web_link
                              : 'https://' + link.web_link
                          }
                          target={`_blank`}
                          rel="noopener noreferrer"
                          className={styles.navigation_list_item}
                          key={`navigation_list_item${linkIndex}`}
                          style={{
                            alignItems: link.web_summary ? null : 'center'
                          }}
                        >
                          <div
                            className={styles.navigation_list_item_left}
                            style={{
                              backgroundImage: `url(${link.web_cover})`
                            }}
                          />

                          <div className={styles.navigation_list_item_right}>
                            <div className={styles.navigation_list_item_title}>
                              {link.web_name}
                            </div>
                            <div
                              className={styles.navigation_list_item_summary}
                              style={{ WebkitBoxOrient: 'vertical' }}
                            >
                              {link.web_summary}
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    navigationList: state.navigation.navigationList
  };
};

export default withRouter(connect(mapStateToProps)(Navigation));
