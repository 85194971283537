import React from 'react';
import styles from './index.scss';
import PropTypes from 'prop-types';

export default class AdPicture extends React.PureComponent {
  static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    url: PropTypes.string,
    img: PropTypes.string,
    icon: PropTypes.bool
  };
  constructor(props) {
    super(props);
  }
  render() {
    const { url, img, width, height, icon } = this.props;
    return (
      <div className={styles.wrapper} style={{ width, height }}>
        <a
          className={styles.down}
          href={url}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={img} loading="lazy" alt="" />
          {icon ? <div className={styles.icon}>广告</div> : ''}
        </a>
      </div>
    );
  }
}
