import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import styles from './index.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import Layout from '../../components/common/layout';
import HomeLoadMore from '../../components/common/homeloadmore';
import { SideList } from '../../components/homeSide';

import { getNewsflash } from '../../common/api/newsflash';
import { getViewpointList } from '../../common/api/viewpoint';
import { getNewPost } from '../../common/api/post';

import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

const getDate = date => {
  return moment(date).format('MM.DD');
};

export class Viewpoint extends Component {
  static fetchData(store, match) {
    const lang = match.params.lang;
    return new Promise((resolve, reject) => {
      getNewsflash({}, lang)
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_NEWS_LIST',
            list: list
          });
          return getNewPost(5, lang);
        })
        .then(data => {
          let list = data.data.data;
          store.dispatch({
            type: 'SET_POST_FEEDLIST',
            list
          });
          return getViewpointList(1, 20, lang);
        })
        .then(data => {
          if (data.data && data.data.data) {
            const list = data.data.data;
            let viewpointList = {};
            let viewpointPage = { page: 1, per_page: 10, total: 0 };

            list.map(item => {
              const date = getDate(item.created_at);
              let newList = viewpointList[date] || [];

              newList = _.concat(viewpointList[date] || [], item);

              viewpointList[date] = newList;
            });

            viewpointPage = {
              page: data.data.current_page + 1,
              per_page: data.data.per_page,
              total: data.data.total
            };
            store.dispatch({
              type: 'SET_VIEWPOINT_LIST',
              list: viewpointList
            });
            store.dispatch({
              type: 'SET_VIEWPOINT_PAGE',
              page: viewpointPage
            });
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static propTypes = {
    newsList: PropTypes.array.isRequired,
    viewpointList: PropTypes.object.isRequired,
    viewpointPage: PropTypes.object.isRequired,
    feedList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      viewpointList: props.viewpointList,
      viewpointPage: props.viewpointPage
    };
  }

  componentDidMount() {}

  loadMore() {
    const { viewpointList, viewpointPage } = this.state;
    const { page, per_page } = viewpointPage;
    getViewpointList(page, per_page).then(data => {
      if (data.data && data.data.data) {
        const list = data.data.data;

        list.map(item => {
          const date = getDate(item.created_at);
          let newList = viewpointList[date] || [];

          newList = _.concat(viewpointList[date] || [], item);

          viewpointList[date] = newList;
        });

        let page = {
          page: data.data.current_page + 1,
          per_page: data.data.per_page,
          total: data.data.total
        };
        this.setState({ viewpointList, viewpointPage: page });
      }
    });
  }

  render() {
    const { viewpointList, viewpointPage } = this.state;
    const { newsList, feedList, match } = this.props;
    const { page, per_page, total } = viewpointPage;
    const { lang } = match.params;
    let helmetData = getHelmet(
      {
        titleFlag: true,
        twitterImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
        ogImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
      },
      lang
    );
    return (
      <React.Fragment>
        {/* 管理文档头，可以通过js动态的改变标签内的内容 */}
        <Helmet {...helmetData} />
        <Layout>
          <div className={styles.viewpoint}>
            <div className={styles.viewpoint_wrapper}>
              <div className={styles.viewpoint_left}>
                <div className={styles.viewpoint_left_title}>
                  {locales(lang, 'sel_Persp')}
                </div>
                {Object.keys(viewpointList).map((date, index) => {
                  return (
                    <div
                      className={styles.viewpoint_item}
                      key={`viewpoint_item${index}`}
                    >
                      <div
                        className={styles.viewpoint_item_time}
                        style={{ margin: index === 0 ? '0 0 20px' : null }}
                      >
                        {date}
                      </div>

                      <div className={styles.viewpoint_item_list}>
                        {viewpointList[date].map((item, index) => {
                          return (
                            <div
                              className={styles.viewpoint_item_list_item}
                              key={`viewpoint_item_list_item${date}${index}`}
                            >
                              <div
                                className={
                                  styles.viewpoint_item_list_item_header
                                }
                              >
                                <div
                                  className={
                                    styles.viewpoint_item_list_item_cover
                                  }
                                  style={{
                                    backgroundImage: `url(${item.cover})`
                                  }}
                                />
                                <div
                                  className={
                                    styles.viewpoint_item_list_item_header_right
                                  }
                                >
                                  <div
                                    className={
                                      styles.viewpoint_item_list_item_name
                                    }
                                  >
                                    {item.name}
                                  </div>
                                  <div
                                    className={
                                      styles.viewpoint_item_list_item_account_number
                                    }
                                  >
                                    {item.account_number}
                                  </div>
                                </div>
                              </div>

                              <div
                                className={
                                  styles.viewpoint_item_list_item_summary
                                }
                              >
                                {item.summary}({locales(lang, 'source')}:
                                <a
                                  href={item.entity_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.source}
                                </a>
                                )
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}

                {total > (page - 1) * per_page ? (
                  <div className={styles.HomeLoadMore}>
                    <HomeLoadMore
                      loading={false}
                      loaded={false}
                      onClick={this.loadMore.bind(this)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className={styles.viewpoint_right}>
                {/* 快讯 */}
                <SideList title="7X24h快讯" newsList={newsList} />

                <div className={styles.viewpoint_right_read}>
                  <div className={styles.viewpoint_right_read_title}>
                    {locales(lang, 'recommended_reading', { isTitle: true })}
                  </div>
                  {feedList.map((item, index) => {
                    return (
                      <a
                        href={`/post/${item.entity_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.viewpoint_right_read_item}
                        key={`viewpoint_right_read_item${index}`}
                      >
                        <div
                          className={styles.viewpoint_right_read_item_text}
                          style={{ WebkitBoxOrient: 'vertical' }}
                        >
                          {item.title}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    newsList: state.home.newsList,
    viewpointList: state.viewpoint.list,
    viewpointPage: state.viewpoint.page,
    feedList: state.post.feedList
  };
};

export default withRouter(connect(mapStateToProps)(Viewpoint));
