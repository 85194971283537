import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import TextOverflow from '../textOverflow';
import { withRouter } from 'react-router-dom';
import locales from '../../utils/locales';
export class HomeViewpoint extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    match: PropTypes.object
  };

  state = {
    viewpointLineHeight: 0
  };
  componentDidMount() {
    this.handleViewpointSummary();
  }

  handleViewpointSummary() {
    let viewpointLineHeight = 0;

    if (this.viewpointSummary.currentStyle) {
      viewpointLineHeight = this.viewpointSummary.currentStyle.lineHeight;
    } else {
      viewpointLineHeight = getComputedStyle(this.viewpointSummary, null)[
        'lineHeight'
      ];
    }
    this.setState({
      viewpointLineHeight: parseInt(viewpointLineHeight)
    });
  }

  render() {
    const { list, match } = this.props;
    const { viewpointLineHeight } = this.state;
    const { lang } = match.params;
    return (
      <div className={styles.homeViewpoint}>
        <div className={styles.homeViewpoint_header}>
          <div className={styles.homeViewpoint_title}>
            {locales(lang, 'sel_Persp', { isTitle: true })}
          </div>
          <a
            className={styles.homeViewpoint_button}
            href={lang ? `/${lang}/viewpoint` : `/viewpoint`}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>

        {list.map(item => {
          return (
            <div
              className={styles.homeViewpoint_item}
              key={`homeViewpoint_item${item.id}`}
            >
              <div className={styles.homeViewpoint_item_header}>
                <div
                  className={styles.homeViewpoint_item_cover}
                  style={{ backgroundImage: `url(${item.cover})` }}
                />
                <div className={styles.homeViewpoint_item_header_right}>
                  <div className={styles.homeViewpoint_item_name}>
                    {item.name}
                  </div>
                  <div className={styles.homeViewpoint_item_account_number}>
                    {item.account_number}
                  </div>
                </div>
              </div>
              <div
                className={styles.homeViewpoint_item_summary}
                ref={e => {
                  this.viewpointSummary = e;
                }}
              >
                <TextOverflow
                  line={5}
                  height={viewpointLineHeight}
                  backgroundColor="#f5f7fb"
                  lang={lang}
                >
                  {item.summary}({locales(lang, 'source')}:
                  <a
                    href={item.entity_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.source}
                  </a>
                  )
                </TextOverflow>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(HomeViewpoint);
