import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import styles from './ListShare.scss';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';

export class Share extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    normal: PropTypes.bool.isRequired,
    newsflash: PropTypes.string,
    match: PropTypes.object
  };
  static defaultProps = {
    normal: false
  };
  state = {
    qrcode: ''
  };
  componentDidMount() {
    const props = this.props;
    QRCode.toDataURL(props.url, { width: 52 }, (error, qrcode) => {
      this.setState({ qrcode });
    });
  }
  render() {
    const props = this.props;
    const state = this.state;

    const normalShare = props.normal ? styles['share-normal'] : '';
    const share2twitter = encodeURI(
      'https://twitter.com/intent/tweet?url=' +
        props.url +
        '&text=' +
        props.title +
        '&via=OdailyChina'
    );
    const { lang } = this.props.match.params;
    return (
      <div className={`${styles.share} ${normalShare}`}>
        <div className={styles.wechat}>
          <i className={styles['icon-wechat']} />
          <div
            className={styles.qrcode}
            style={{
              width: !lang || lang == 'zhtw' ? '210px' : '232px',
              height: !lang || lang == 'zhtw' ? '91px' : '122px'
            }}
          >
            <div className={styles.qrcode_img}>
              <img src={state.qrcode} loading="lazy" />
            </div>

            <div className={styles.tips}>
              <p>{locales(lang, 'scanQRCode')}</p>
              <p>{locales(lang, 'click_screen')}</p>
              <p>{locales(lang, 'click_share')}</p>
            </div>
          </div>
        </div>

        <div className={styles.weibo}>
          <a
            href={`http://v.t.sina.com.cn/share/share.php?&appkey=2523671237&content=utf-8&url=${
              props.url
            }&title=${encodeURIComponent(
              props.title + ' @ODAILY '
            )}&sourceUrl=${props.url}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <i className={styles['icon-weibo']} />
          </a>
        </div>

        <div className={styles.twitter}>
          <a
            href={share2twitter}
            target="_blank"
            rel="nofollow noopener noreferrer"
            title={`${locales(lang, 'shareTo')} Twitter`}
          >
            <i className={styles['icon-twitter']} />
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(Share);
