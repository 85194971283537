import { combineReducers } from 'redux';

const list = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIBRARY_LIST':
      return action.list;
    default:
      return state;
  }
};

const my_favorite = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIBRARY_MY_FAVORITE':
      return action.list;
    default:
      return state;
  }
};

const recommend = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIBRARY_RECOMMEND':
      return action.list;
    default:
      return state;
  }
};

const listData = (
  state = {
    title: '',
    type: 'library',
    list: [],
    page: { page: 1, per_page: 0, total: 0 }
  },
  action
) => {
  switch (action.type) {
    case 'SET_LIBRARY_LIST_DATA':
      return action.data;
    default:
      return state;
  }
};

const feedData = (
  state = {
    list: [],
    page: { page: 1, per_page: 0, total: 0 }
  },
  action
) => {
  switch (action.type) {
    case 'SET_LIBRARY_FEED':
      return action.data;
    default:
      return state;
  }
};

const detail = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LIBRARY_DETAIL':
      return action.data;
    default:
      return state;
  }
};

const topic = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIBRARY_TOPIC':
      return action.data;
    default:
      return state;
  }
};

const postFavoriteList = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIBRARY_POST_FAVORITE':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  my_favorite,
  recommend,
  listData,
  feedData,
  detail,
  topic,
  postFavoriteList
});
