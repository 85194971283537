import React, { Component } from 'react';
import styles from '../../index.scss';
import PropTypes from 'prop-types';

export class PrivacyEn extends Component {
  static propTypes = {
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.content}>
        <h5>{this.props.title}</h5>
        <h6> Contents </h6>

        <p> one. Introduction </p>
        <p>Two. About Us </p>
        <p>Three. How we collect and use Your Personal Information </p>
        <p>Four. How we use Cookies and similar technologies </p>
        <p>
          Five. How we entrust, provide, transfer and publicly disclose your
          personal information\{' '}
        </p>
        <p>Six. How we store and protect Your Personal Information </p>
        <p>Seven. How you manage your Personal Information </p>
        <p>Eight. Protection of Minors` Personal Information </p>
        <p>Nine. Privacy Policy Changes and Notices </p>
        <p>Ten. We protect Personal information of deceased users </p>
        <p>Eleven. How to contact us </p>
        <p>Twelve. Dispute Resolution </p>

        <h6> one. Introduction </h6>
        <p>
          This ODAILY Privacy Policy (the ”Privacy Policy“) applies to ODAILY`s
          (the ”Platform” or “us“) software and related services (the “ODAILY
          Services“). Each section title is intended only to help you understand
          the purpose of this section and does not affect or limit the meaning
          or interpretation of the terms of this Privacy Policy. In order to
          protect your own rights and interests, it is recommended that you
          carefully read the specific expressions of each clause.
        </p>
        <p>
          Please note that this Privacy Policy applies to the various ODAILY
          services that we provide to you through our websites, clients,
          applets, and new forms as technology develops. If we and our
          affiliates use ODAILY services in our products or services, but there
          is no independent privacy policy, this privacy policy also applies to
          those services. Where we and our affiliates have separate privacy
          policies for the ODAILY Services they provide to you, the
          corresponding privacy policies for the respective products or services
          will apply.
        </p>
        <p>
          You should read this Privacy Policy carefully before agreeing to this
          Privacy Policy during the registration process or when this Privacy
          Policy is updated. Please read carefully and fully understand the
          terms and conditions.
          <b>
            We have highlighted the important contents of the terms concerning
            your personal information rights in bold form, please pay special
            attention to them.
          </b>
        </p>
        <p>
          When you fill in the information according to the prompts on the
          registration page, read and agree to this Privacy policy, and after
          completing all registration procedures or when this Privacy Policy is
          updated by clicking Agree, it means that you have fully read,
          understood and accepted all contents of this Privacy Policy.
          <b>
            In the process of reading this Privacy policy, if you refuse to
            agree to this Privacy Policy or any of the terms hereof, you may not
            be able to use the relevant ODAILY services and some functions
            normally, or you may not be able to achieve the service effect we
            expect.
          </b>
        </p>
        <h6> two. About Us </h6>
        <p>
          1. The operator of this platform and the provider of ODAILY service
          are Hainan Moti Culture Media Co., LTD.
        </p>
        <p>
          2. The affiliated company refers to the company or institution that
          currently or in the future controls Hainan Moti Culture and Media Co.,
          LTD., is controlled by Hainan Moti Culture and Media Co., LTD., or is
          under common control with Hainan Moti Culture and Media Co., LTD.
          Control is the ability to influence, directly or indirectly, the
          management/operation of a controlled object through ownership, voting
          shares, contracts, actual operating associations, or other legally
          recognized means.
        </p>
        <h6> three. How we collect and use Your Personal Information </h6>
        <p>
          1. We collect and use the personal information that you voluntarily
          provide in the process of using the service or that is generated due
          to the needs of ODAILY Services in accordance with laws and
          regulations and in accordance with the principles of legitimacy,
          legality and necessity. If we intend to use your personal information
          for other purposes not specified in this Privacy Policy, or use the
          information collected for other purposes based on a specific purpose,
          we will inform you in a timely and reasonable manner, and obtain your
          consent again before using.
        </p>
        <p>
          2. There are two types of your personal information that we collect
          and use: First: Information necessary for the core business functions
          of ODAILY services: Such information is necessary for the normal
          operation of the Platform, and you must authorize us to collect it. If
          you refuse to provide, you may not be able to use the relevant ODAILY
          services and some functions normally; Second: Additional business
          functions may need to collect information: This information is
          required for non-core business functions, you can choose whether to
          authorize us to collect information. If you refuse to provide, it will
          cause the additional business functions cannot be realized or cannot
          achieve the effect we intend to achieve, but it will not affect your
          normal use of the core business functions.
        </p>
        <p>
          3.
          <b>
            We need to remind you that because there are many ODAILY services,
            the content provided to you is different, so the core business
            functions (including the type of your personal information collected
            by ODAILY) will also be different according to the content of ODAILY
            services, and the specific ODaily services will be subject to the
            actual provision of the service. In addition, you understand and
            agree that we want to provide you with the ODAILY Service is
            perfect, so we will continue to improve the ODAILY Service,
            including technology, which means that we may introduce new business
            features from time to time, may need to collect new personal
            information or change the purpose or way personal information is
            used. If a function or service that needs to collect your personal
            information is not explained in this Privacy Policy, we will
            separately explain to you the purpose, content, mode of use and
            scope of the information collection by updating this Privacy Policy,
            prompts on the page, pop-ups, website announcements, etc., and
            provide you with a method of your own choice and consent, and
            collect after obtaining your express consent.
          </b>
          During this process, if you have any doubts about relevant matters,
          you can refer to the eleventh privacy Policy. One of the provided ways
          to contact us, we will answer as soon as possible for you.
        </p>
        <p>
          4. In this Privacy Policy, sensitive personal information refers to
          the personal information that, once leaked or illegally used, may
          easily lead to the violation of the personal dignity of natural
          persons or harm to the personal and property safety, including
          <b>
            {' '}
            Biometrics, religious beliefs, specific identities, medical care,
            financial accounts, whereabouts{' '}
          </b>
          Personal information about <b> minors under 14 years of age </b>, for
          example,
          <b>
            Sensitive personal information includes ID card number, personal
            biometric information, bank account number, communication content,
            health and physiological information
          </b>
          Let`s wait. The sensitive personal information we collect is displayed
          in this Privacy Policy by a prominent logo. Once your sensitive
          personal information is leaked, tampered with or illegally used, it
          will cause damage to your personal or property safety. In order to
          prevent your sensitive personal information from being leaked or
          illegally used, we have taken appropriate technical and organizational
          protection measures to ensure the security of your information.
        </p>
        <p>
          5. In general, we will collect and use your personal information in
          the following scenarios:{' '}
        </p>

        <p>5.1 ODAILY Service in Registration and Login State </p>
        <p>
          In order for us to provide you with continuous and stable services and
          to ensure the security of your use of ODAILY Services, we require you
          to proactively provide basic registration or login information,
          including mobile phone number, email address, and create your account,
          user name and password
          <b>
            {' '}
            Mobile phone number and password are personal sensitive information{' '}
          </b>
          The aforementioned information is collected to meet the network
          real-name system requirements of relevant laws and regulations and to
          fulfill the requirements of this Privacy Policy. In some individual
          services, if you only need to use the basic functions of browsing and
          search, you do not need to register as an ODAILY user and provide the
          above information.
        </p>
        <p>
          You can also register as an ODAILY user using a third-party platform
          account, and you may choose to authorize us to read and obtain it in
          accordance with relevant laws and regulations
          <b>
            {' '}
            Public information (including nicknames, avatars) posted and
            recorded by you on the third-party platform{' '}
          </b>
          . We require your authorization to obtain the above information from
          third parties in order to remember your login identity as an ODAILY
          user. The collection of the above information is based on your
          consent. After you register or log in with a third party account, your
          third party account will be bound to your ODAILY account.
        </p>
        <p>
          Based on our cooperation with communication operators, when you use
          the “one-click login” function of ODAILY, after your express consent,
          the operator will send your mobile phone number to us, so that we can
          provide you with fast login services. You can also manually enter your
          mobile number to register or log in.
        </p>
        <p>
          In the process of registration, login or subsequent use, you may also
          take the initiative to fill in or supplement your other additional
          information (your nickname, profile picture, gender, date of birth,
          identity, industry), the collection of the above information is based
          on your consent, such information may be displayed on your personal
          home page and comment section to identify your ODAILY user identity.
          Make it easy for you to interact with other ODAILY users. However, if
          you do not provide this information, it will not affect the basic
          functions of your use of the ODAILY Service.
        </p>
        <p>5.2 ODAILY Service without Registration and login </p>
        <p>
          If you are using the ODAILY Service without registering or logging in,
          we can only provide you with some features in non-logged in mode. In
          order to ensure the realization of the function, we may actively apply
          for obtaining your device information and network identification
          information, so as to realize the browsing, searching, liking,
          collecting and sharing functions. The above-mentioned information is
          collected to meet the requirements of the network log information
          retention obligations of relevant laws and regulations;
        </p>
        <p>
          In addition to the above information, in the course of your use, we
          may take the initiative to apply for obtaining your information
          <b> Device permissions </b>
          To enable additional features (see section 5.9 of this Privacy Policy,
          “To provide you with security”).
        </p>
        <p>5.3 Viewing Information </p>
        <p>
          1) ODAILY may recommend more interesting information to you, to
          achieve this function,
          <b> We may automatically collect necessary log information </b>,
          including:
        </p>

        <ul className={styles.privacy_ul}>
          <li>
            a) Behavioral information you operate and use: click, follow,
            favorite, search, browse, share;
          </li>
          <li>b) Device information: unique identifier of the device; </li>
          <li>
            c) Network information: precise geographic location information
            obtained from sensor information such as GPS information, WLAN
            access point, Bluetooth and base station, and approximate geographic
            location information obtained from network location information
            (base station, IP and WLAN);
          </li>
        </ul>

        <p>
          2) <b>GPS location is personal sensitive information </b>
          If you refuse to provide, we will not recommend information to you
          based on GPS information, and will not affect the normal use of other
          functions of the platform.
        </p>

        <p>
          3) We will show you and recommend local relevant information in your
          area based on the city corresponding to the approximate geographical
          location information.
        </p>

        <p>
          4) The aforementioned information is collected to meet the
          requirements of the network log information retention obligations of
          relevant laws and regulations and to fulfill the requirements of this
          privacy policy.
          <b>
            The above information collected and used by us is de-identified, and
            the data analysis only corresponds to a specific code that cannot be
            directly associated with your identity, and will not be associated
            with your real identity
          </b>
          .
        </p>
        <p>5.4 Publishing and Interaction </p>
        <p>1) Information release </p>
        <ul className={styles.privacy_ul}>
          <li>
            a) When you post content or comment, we will automatically collect
            information about your post and display your nickname, avatar, and
            post content.
          </li>
          <li>
            b) When you use the function of uploading pictures and publishing
            audio and video, we will ask you to actively authorize the camera
            (camera), album (storage space) and microphone permissions. If you
            refuse to authorize the provision, you will not be able to use this
            function, but it will not affect your normal use of other functions
            of the platform.
          </li>
          <li>
            c) Please note that the information you publicly release may involve
            the personal information of users or others. If the information you
            publicly release contains the personal information of others, you
            need to ensure that the use is permitted by laws and regulations or
            has been legally authorized before uploading and publishing.
          </li>
          <li>
            d) When you use the live broadcast function, you need to perform
            real-name authentication in accordance with laws and regulations,
            security requirements and the requirements of this Privacy policy.
            In the process of real-name authentication, the third-party
            certification body of the authentication service needs you to
            proactively provide it
            <b>
              {' '}
              Real name and ID number information. This information is
              personally sensitive{' '}
            </b>
            , you can refuse to provide, and will not affect the normal use of
            other functions of the platform. This information is only for
            real-name authentication and for the purposes prescribed by laws and
            regulations, and will not be used for other purposes without your
            express authorization.
          </li>
          <li>
            e) The aforementioned information is collected to meet the
            obligations of the service provider of relevant laws and regulations
            to retain the information published by the account, public account,
            live broadcast user and other information content and to fulfill the
            requirements of this Privacy policy.
          </li>
        </ul>

        <p>2) Interactive communication </p>
        <ul className={styles.privacy_ul}>
          <li>
            a) When you follow the content, we will collect the content or
            account you follow and show you the content or the content posted by
            the account.
          </li>
          <li>
            b) The aforementioned information is collected to meet the
            requirements of the network log information retention obligations of
            relevant laws and regulations and to fulfill the requirements of
            this Privacy Policy.
          </li>
        </ul>
        <p>5.5 Searching for </p>
        <p>
          1) When you use the search service, we will automatically collect your
          search keyword information and log information records for analysis in
          order to show you more relevant search results.
        </p>
        <p>
          2) We may also automatically record the information you provide, form
          or retain when using our services, such as the information you enter
          when using search terms, click history terms, prompt terms and clicked
          links, as well as the information you submit when participating in
          search operation activities. You have the right to refuse to provide
          the above information, but this may cause you to be unable to use a
          particular service or feature, or to achieve the intended effect of
          the relevant service.
        </p>
        <p>
          3) The above information is collected to meet the requirements of the
          network log information retention obligations of relevant laws and
          regulations and to fulfill the requirements of this privacy policy. In
          order to provide efficient search services, some of the above
          information will be temporarily stored in your local storage device,
          and can show you the search results and search history.
        </p>

        <p>
          5.6 <b> Message Notification </b>
        </p>
        <p>
          <b>
            You acknowledge and agree that for your contact information (contact
            number) proactively provided by you in the process of using the
            Products and/or services, we may send multiple types of
            notifications to one or more of them in our operations for user
            message notification, identity verification, security verification,
            user experience research, and dispute settlement purposes; In
            addition, we may also use the mobile phone number collected in the
            above process to provide you with commercial information of
            services, functions or activities that you may be interested in by
            SMS or telephone, but please rest assured that if you do not want to
            accept such information, you can unsubscribe through the method
            provided in the mobile phone SMS, or you can contact us directly to
            unsubscribe.
          </b>
        </p>

        <p>5.7 Customer Service </p>
        <p>
          When you give us feedback, in order to contact you or help you solve
          the problem, we may require you to proactively provide your name,
          mobile phone number, email address and other contact information, and
          may use this information to contact you. If you are submitting an
          infringement complaint, we will also require you to submit it as
          required by law
          <b> ID card, authorization, ownership certificate </b> to clarify your
          eligibility as the subject of the complaint.
        </p>

        <p>5.8 Transaction Services </p>
        <p>
          We automatically collect your transaction history and log information
          when you purchase online reading, online courses, Points Store items,
          and otherwise purchase our products or services. The aforementioned
          information is collected in order to meet the requirements of the
          relevant laws and regulations for the retention of network log
          information and transaction information retention obligations and to
          fulfill the requirements of this Privacy Policy
          <b> Transaction record information is personally sensitive </b>
          Refusal to provide this information may prevent us from providing the
          transaction services, but does not affect your normal use of other
          functions of the ODAILY Service.
        </p>
        <p>5.9 Provides you with security </p>
        <p>
          In order to meet the requirements of the Cybersecurity Law of the
          People`s Republic of China (hereinafter referred to as the
          “Cybersecurity Law”) and other laws and regulations, the needs of
          cybersecurity protection and the requirements of this Privacy policy,
          to provide you with safe and reliable services, to ensure the
          stability and security of system operation, to prevent your account
          from being stolen and your personal information from being illegally
          obtained, and to protect your account security, We need to proactively
          apply to collect your 1) device information (including device model,
          device MAC address, operating system type, client version, resolution,
          device Settings); Device unique identifier (IMEI/android
          ID/IDFA/OPENUDID/GUID, SIM card IMSI information); 2) Network
          identification information (including
          <b>GPS location information </b>, IP address, telecom operator,
          network environment, software version number, browser type,
          <b> Website browsing history </b>, operation log). To determine the
          risk of your account, and to prevent, detect, and investigate actions
          that endanger the safety of system operation, illegal or in violation
          of our rules and agreements. We will also collect your aforementioned
          device information and software information for analyzing problems
          with our products or service systems, counting traffic and identifying
          possible risks. If you do not provide the above information, we will
          not be able to protect the security of our products or services and
          your account during your use of our products or services.
        </p>

        <p>5.10 Improve user experience and satisfaction </p>
        <p>
          In order to improve your experience and satisfaction with ODAILY
          Service, we will automatically collect your operation behavior and
          conduct research and analysis of user data based on your operation
          behavior, so as to further optimize ODAILY Service.
        </p>

        <p>
          5.11 <b> Exceptions to consent </b>
        </p>
        <p>
          <b>
            Please be aware that in the following circumstances, we do not need
            to obtain your authorization to collect and use personal
            information:
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) related to national security and national defense security;{' '}
          </li>
          <li>
            2) related to public safety, public health and major public
            interests;{' '}
          </li>
          <li>
            3) relating to criminal investigation, prosecution, trial and
            enforcement of sentences;{' '}
          </li>
          <li>
            4) In order to protect the life, property and other major legitimate
            rights and interests of the personal information subject or other
            individuals, but it is difficult to get the consent of the person;
          </li>
          <li>
            5) The collected personal information is disclosed to the public by
            the personal information subject;{' '}
          </li>
          <li>
            6) Your personal information collected from legally publicly
            disclosed information, such as legal news reports, government
            information disclosure and other channels; However, unless you
            explicitly refuse or process the information to infringe on your
            major interests;
          </li>
          <li>
            7) necessary to conclude the contract according to your
            requirements;{' '}
          </li>
          <li>
            8) Necessary for the safe and stable operation of the products
            and/or services provided, such as the detection and disposal of
            faults of the products and/or services;
          </li>
          <li>9) necessary for legitimate news reporting; </li>
          <li>
            10) When an academic research institution carries out statistics or
            academic research in the public interest and provides the results of
            academic research or description to the outside world, it
            de-identifies the personal information contained in the results;
          </li>
          <li>11) Other circumstances stipulated by laws and regulations. </li>
        </ul>
        <p>
          <b>
            Please note that information that alone or in combination with other
            information does not identify you or connect you directly is not
            personal information. If we use information that alone cannot be
            linked to any particular individual in conjunction with other
            information to personally identify a natural person, or in
            conjunction with personal information, such information will be
            treated as personal information for the duration of the combined
            use.
          </b>
        </p>
        <p>5.12 Invoking Device Permission </p>
        <p>
          In order to ensure the normal implementation of relevant ODAILY
          services, we need to invoke the corresponding necessary permissions
          according to the specific usage scenario. Please see the detailed
          description of the permission invocation
          <a href="? application-authority" target="_blank">
            Application Permission Application and Usage Instructions
          </a>
          .
        </p>
        <p>
          You can choose to disable some or all of the permissions in the
          Settings of the device. As a result, the corresponding ODAILY service
          cannot be implemented or the expected effect cannot be achieved.
        </p>

        <p>5.13 Personalized Recommendation </p>
        <p>
          Please be aware that the ODAILY service does not involve the
          recommendation and display of personalized content, and we do not
          collect your browsing and search information, behavioral preferences
          and other information for portrait analysis.
        </p>

        <h6> How we use Cookies and similar technologies </h6>
        <p>
          1. Cookies can help websites identify registered users and calculate
          the number of users, and are usually used by websites to determine
          whether registered users have achieved login. The platform promises
          that the research on Cookie information is only used for the purpose
          of improving service/product quality and optimizing user experience.
          At the same time, if you do not want personal information to be kept
          in cookies, you can configure your browser: select ”Reject cookies“ or
          ”Notify you when the website sends cookies“. You hereby acknowledge
          that since the ODAILY service is implemented by supporting cookies,
          completing the operation of turning off cookies may affect your access
          to the Platform or not be able to fully access the services of the
          Platform. When you cannot help using a Cookie, you may be prompted
          whether to retain user information the next time you enter the website
          in order to simplify the login process (such as one-click login).
        </p>
        <p>
          2. The platform uses the web The methods and purposes of beacons are
          the same as those of cookies, and the above provisions shall apply
          accordingly.
        </p>

        <h6>
          {' '}
          How we entrust, provide, transfer and publicly disclose your Personal
          information{' '}
        </h6>
        <p>1. Entrust processing of your personal information </p>
        <p>
          In order to provide you with a more complete and high-quality ODAILY
          service, some functions may be provided by our service partners (such
          as our third-party service providers, contractors, agents), we will
          entrust the service partners to process certain personal information
          on our behalf. Including, but not limited to, partners who provide
          ODAILY with one-click login services, push messaging services, data
          analysis and statistical services. We will require the service partner
          to abide by the strict confidentiality obligations and take effective
          confidentiality measures through written agreements, on-site audits
          and other means, and prohibit it from using the information for
          purposes not authorized by you. After the termination of the
          entrustment relationship, the Service partner will no longer keep your
          personal information.
        </p>
        <p>2. Provide </p>
        <p>
          We will not provide your personal information to companies,
          organizations and individuals other than service providers of this
          product, except in the following cases:
        </p>
        <p>
          2.1 Necessary for the fulfilment of statutory obligations: We may, in
          accordance with the provisions of laws and regulations, the necessity
          of litigation, dispute resolution, or the requirements of
          administrative and judicial authorities in accordance with the law, as
          well as the necessity of performing other statutory obligations, or
          other legal reasons provided by the law, such as necessary to respond
          to public health emergencies or to protect your life, health and
          property safety in emergency situations, Or for the public interest to
          carry out news reports, public opinion supervision and other acts, to
          provide your personal information.
        </p>
        <p>
          2.2 Based on your consent or your own initiative: If you separately
          consent or actively ask us to provide your personal information to a
          third party, we will inform you of the name and contact information of
          the recipient of your personal information in an appropriate manner on
          the corresponding page for the purpose of your consent. If you use the
          information sharing service, we will provide the name of the
          third-party sharing platform on the page, and the specific contact
          information can be viewed in the privacy policy of the third party.
        </p>
        <p>
          2.3 Necessary for the performance of Service agreements: Some of our
          ODAILY Services may be provided to you by our affiliates and partners
          or by us jointly with them. Therefore, in order to perform the service
          agreement entered into with you, we must provide your personal
          information to our affiliates or partners. We have formulated
          <a href="? info-share-list" target="_blank">
            Information Sharing List
          </a>
          Give you a clear and concise introduction of our affiliated companies
          and cooperative institutions, view the Information Sharing List
        </p>
        <p>
          1) To our Affiliates: To facilitate your use of our products or
          services provided by our affiliates or other products, your personal
          information may be shared with our affiliates and/or their designated
          service providers. We will only share necessary personal information,
          and subject to the purposes stated in this policy, if we share your
          sensitive personal information or affiliates change the purpose of the
          use and processing of personal information, we will again seek your
          authorized consent.
        </p>

        <p>
          2) To our partner institutions: In order to provide you with perfect
          products and services, some of our services or technologies will be
          provided by authorized partner institutions. Please be aware that we
          will not take the initiative to provide your personal information to
          other personal information processors, we will only share your
          personal information for legal, legitimate, necessary, specific and
          clear purposes, and only the necessary personal information will be
          shared. We will provide your personal information to other personal
          information processors, inform you of the recipient`s name, contact
          information, processing purposes, processing methods and types of
          personal information, and obtain your individual consent. At the same
          time, we will sign strict confidentiality agreements with partner
          organizations, requiring them to strictly comply with this privacy
          policy and any other relevant confidentiality policies and take
          security measures to handle your personal information. We require that
          our partner organizations have no right to use the shared personal
          information for any other purpose.
          <b>
            When you view web pages created by third parties or use applications
            developed by third parties, these third parties may place their own
            Cookie Or use other technical means that are beyond our control and
            their use is not governed by this Privacy Policy. Partner
            organizations may have their own personal information protection
            policies and user agreements, and we recommend that you read and
            comply with the third party`s personal information protection
            policies and user agreements.
          </b>
        </p>

        <p>
          2.4 Other lawful reasons provided by law: We may provide your personal
          information to third parties based on other legal reasons stipulated
          by law, such as necessary to protect your life, health and property
          safety in response to public health emergencies or emergency
          situations, or for the public interest to carry out news reports,
          public opinion supervision and other acts, we will inform you of the
          relevant information at an appropriate time (such as after the
          emergency is eliminated).
        </p>

        <p>3. Transfer </p>
        <p>
          We will not transfer your personal information to any company,
          organization or individual, except in the following cases:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) Transfer with express consent. With your express consent, we will
            transfer your personal information to other parties.
          </li>
          <li>
            2) Transfer in connection with a merger, acquisition or bankruptcy
            liquidation. When involving the merger, acquisition or bankruptcy
            liquidation of ODAILY, such as the transfer of personal information,
            we will require the new company or organization that controls your
            personal information to continue to be bound by this privacy policy,
            otherwise we will require the company or organization to seek
            authorization from you again.
          </li>
        </ul>
        <p>4. Public disclosure </p>
        <p>
          4.1 Public disclosure refers to the behavior of releasing information
          to the society or to an unspecified group of people. We will not
          publicly disclose your personal information except for necessary
          disclosure for the need to punish illegal accounts, fraud, etc.,
          desensitization of relevant information when publishing the list of
          winners/winners, etc. If there are reasonable reasons for public
          disclosure, Before public disclosure, we will inform you of the
          purpose and type of publicly disclosed information (if it involves
          your personal sensitive information, we will also inform you of the
          content of the sensitive information involved), and disclose it
          publicly after obtaining your authorization and consent, except as
          otherwise provided by laws and regulations or as otherwise agreed in
          this Privacy Policy.
        </p>
        <p>
          4.2 For your publicly disclosed personal information, we will
          carefully review its legitimacy, rationality and legality at the first
          time after receiving the application for public disclosure, and adopt
          the strictest personal information security protection measures and
          means to protect it during and after public disclosure.
        </p>

        <p>5. Access to third-party SDKS </p>
        <p>
          In order to ensure that you can use and enjoy better ODAILY services,
          we provide some functions in the form of SDK plug-ins provided by
          third parties we cooperate with, and the SDK technical service
          provider will obtain corresponding permissions and information based
          on the requirements for providing functions or services to you. We
          monitor the security of all SDKS integrated into the App to ensure
          that they collect only the personal information necessary to perform
          their functions or services. You can check it out here
          <a href="? info-share-list" target="_blank">
            Information Sharing List.
          </a>
        </p>

        <p>
          6. Exception of prior authorization consent when entrusted processing,
          external provision, transfer and public disclosure of personal
          information
        </p>
        <p>
          <b>
            According to relevant laws and regulations, the sharing, transfer,
            and public disclosure of your personal information do not require
            your prior authorization and consent in the following circumstances:
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) related to the fulfillment of our obligations under laws and
            regulations;{' '}
          </li>
          <li>
            2) directly related to national security and national defense
            security;{' '}
          </li>
          <li>
            3) directly related to public safety, public health, and major
            public interests;{' '}
          </li>
          <li>
            4) Directly related to judicial or administrative law enforcement
            such as criminal investigation, prosecution, trial and execution of
            sentences;
          </li>
          <li>
            5) For the protection of your or other individuals` life, property
            and other major legitimate rights and interests, but it is difficult
            to get my consent;
          </li>
          <li>
            6) Personal information that you disclose to the public on your own;{' '}
          </li>
          <li>
            7) Collecting personal information from legally publicly disclosed
            information, such as legal news reports, government information
            disclosure and other channels.
          </li>
        </ul>
        <p>
          <b>
            Please be aware that, in accordance with applicable law, if we take
            technical measures and other necessary measures to process personal
            information, which makes it impossible for the data recipient to
            re-identify a specific individual and cannot be recovered, the
            entrusted processing, external provision, transfer and public
            disclosure of such data after processing do not require further
            notice to you and your consent
          </b>
        </p>
        <h6> How we store and protect Your Personal Information </h6>
        <p>1. Store </p>
        <p>
          1.1 Storage Location: We store your personal information collected and
          generated in the course of operation in the People`s Republic of China
          in accordance with laws and regulations in the People`s Republic of
          China. At present, we will not transmit the above information abroad,
          if we do so, we will strictly comply with the relevant laws and
          regulatory policies of China, and will comply with the relevant
          national regulations or seek your consent.
        </p>
        <p>
          1.2 Storage time: We promise to always store your personal information
          for as long as reasonably necessary in accordance with the law. After
          the above period, we will delete your personal information or
          anonymize your personal information.
        </p>
        <p>
          1.3 If we cease operations, we will promptly stop the activities of
          collecting your personal information, notify you of the notice of
          cessation of operations in the form of one-by-one delivery or
          announcement, and delete or anonymize your personal information held
          by us.
        </p>
        <p>2. Protection </p>
        <p>
          2.1 We attach great importance to the security of your personal
          information and will strive to take reasonable security measures
          (including technical and administrative aspects) to protect your
          personal information and prevent the personal information you provide
          from being improperly used or unauthorized access, public disclosure,
          use, modification, damage, loss or disclosure. So far, we`ve passed
          <b>
            {' '}
            National information security level protection (Level 3) assessment
            and filing.{' '}
          </b>
        </p>
        <p>
          2.2 We will use no less than industry peers encryption technology,
          anonymization processing and relevant reasonable and feasible means to
          protect your personal information, and use security protection
          mechanisms to prevent your personal information from malicious
          attacks.
        </p>
        <p>
          2.3 We will establish a special security management system and data
          security process to ensure the security of your personal information.
          We adopt strict data use and access policies to ensure that only
          authorized personnel have access to your personal information.
        </p>
        <p>
          2.4
          <b>
            Although the above reasonable and effective measures have been taken
            and the standards required by relevant laws and regulations have
            been complied with, please understand that due to technical
            limitations and possible malicious means, in the Internet industry,
            even if we do our best to strengthen security measures, it is
            impossible to always ensure 100% security of information. We will do
            our best to ensure the security of the personal information you
            provide to us.
          </b>
        </p>
        <p>
          2.5
          <b>
            You acknowledge and understand that the systems and communications
            networks you use to access our Services may be subject to problems
            due to factors beyond our control. Therefore, we strongly recommend
            that you take proactive measures to protect the security of your
            personal information, including but not limited to using complex
            passwords, changing passwords regularly, and not disclosing your
            account passwords and related personal information to others.
          </b>
        </p>
        <p>
          2.6 We will develop emergency response plans and immediately initiate
          emergency response plans when user information security incidents
          occur, and strive to prevent the impact and consequences of these
          security incidents from expanding. In the event of a user information
          security incident (leakage, loss), we will inform you in a timely
          manner in accordance with the requirements of laws and regulations:
          the basic situation and possible impact of the security incident, the
          disposal measures we have taken or will take, the suggestions you can
          independently prevent and reduce risks, and the remedial measures for
          you. We will timely inform you of the relevant events by push
          notification, email, letter, SMS and other relevant forms. If it is
          difficult to inform you one by one, we will make announcements in a
          reasonable and effective manner. At the same time, we will also report
          the disposal of user information security incidents in accordance with
          the requirements of relevant regulatory authorities.
        </p>
        <p>
          2.7 Once you leave the Platform and ODAILY Services, browse or use
          other websites, services and content resources, we will not have the
          ability and direct obligation to protect any personal information you
          submit on the platform and ODAILY Services outside the software,
          website, regardless of whether you log in, browse or use the above
          software, website based on the link or guidance of the platform.
        </p>
        <h6> How you manage Your Personal Information </h6>
        <p>
          1. You have the right to query and modify your personal information{' '}
        </p>
        <p>
          We take your concerns about personal information very seriously and
          provide a variety of features to protect your rights to access and
          modify personal information.
        </p>
        <p>1.1 Managing Account Information </p>
        <p>
          “My” → “Account Management” You can use this function to change the
          account password, the bound mobile phone number, and the bound
          third-party account.
        </p>
        <p>
          “My” → “Account Card” → “Edit” You can view and modify your account
          information in the edit profile, including profile picture, nickname,
          gender, birthday, personal profile, identity, and industry.
        </p>
        <p>1.2 Managing comments and article information </p>
        <p>
          “My” → “Personal Home” Click on a single comment or article to enter
          the content details page, you can learn about the comment or article
          you have posted.
        </p>
        <p>1.3 Managing Browsing History </p>
        <p> “My” → “Browse” You can view your browsing history. </p>
        <p>1.4 Managing Attention Records </p>
        <p>
          “Mine” → “Following” You can manage all the following list, view and
          modify the users, authors, topics you follow.
        </p>
        <p>
          {' '}
          “My” → “Favorites” You can manage your collection of articles, videos
          and more.{' '}
        </p>
        <p>
          When you change the scope of your authorization, we will adjust the
          ODAILY services provided to you by the Platform accordingly. However,
          please understand that this will not affect the processing of personal
          information previously carried out based on your authorization.
        </p>

        <p> 2.Delete your personal information </p>
        <p>
          {' '}
          We will provide the following ways to help you delete the information
          you have posted on the Platform:{' '}
        </p>
        <p>2.1 Deleting Comments and article information </p>
        <p>
          “My” → “Personal Home” Click on a single published comment or article
          to enter the content details page, and you can delete your published
          comment or article at any time by long pressing. However, if you
          delete comments or articles that have been published, you only cancel
          their public display, and we still need to store and retain the
          comments or articles that you have published in the background
          according to laws and regulations.
        </p>
        <p>2.2 Deleting Browsing History </p>
        <p> “My” → “Browse” You can delete your browsing history. </p>
        <p>2.3 Deleting a Concern Record </p>
        <p>
          {' '}
          My → Follow You can delete users, authors, and topics that you follow.{' '}
        </p>
        <p>
          {' '}
          “My” → “Favorites” You can delete your favorite articles, videos and
          other.{' '}
        </p>
        <p>
          2.4 In the following cases, we will proactively delete your personal
          information. If we do not delete your personal information, you may
          make a request to delete your personal information by sending an email
          to om@odaily.email:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            {' '}
            (1) the processing purpose has been achieved, cannot be achieved, or
            is no longer necessary to achieve the processing purpose;{' '}
          </li>
          <li>
            {' '}
            (2) We stop providing products or services, or the retention period
            has expired;{' '}
          </li>
          <li> (3) You withdraw your consent; </li>
          <li>
            {' '}
            (4) We process personal information in violation of laws,
            administrative regulations or agreements;{' '}
          </li>
          <li>
            {' '}
            (5) Other circumstances provided for by laws and administrative
            regulations.{' '}
          </li>
        </ul>
        <p>
          After you delete the information, we will adjust the ODAILY service
          provided to you by the platform accordingly. However, please
          understand that this will not affect the processing of personal
          information previously carried out based on your authorization.
        </p>
        <p>3. Obtain a copy of your personal information </p>
        <p>
          If you need a copy of your personal information, you can send an email
          to om@odaily.email and we will provide you with the personal
          information we have collected upon your written request.
        </p>

        <p>
          4. You have the right to withdraw your authorization of personal
          information{' '}
        </p>
        <p>
          You can turn off GPS geolocation, camera, microphone, photo album
          permissions, change consent, or withdraw your authorization from the
          device`s own operating system. We will no longer collect information
          related to these permissions after you turn them off. To refuse our
          collection of your personal information. You should understand that
          after you withdraw your authorization, we will not be able to continue
          to provide you with the ODAILY services corresponding to the
          withdrawal of your authorization.
          <b>
            However, your decision to withdraw your authorization will not
            affect the processing of personal information previously carried out
            based on your authorization.
          </b>
        </p>

        <p>5. Right to transfer Personal information </p>
        <p>
          If you need to transfer your personal information to another platform,
          business or organization, you can contact us through the methods
          provided in Article 11 of this Privacy Policy. We will review your
          request, and we will provide you with the means to transfer the
          relevant personal information provided that the personal information
          transfer is legally required.
        </p>

        <p>6.ODAILY Account cancellation </p>
        <p>
          If you need to close your ODAILY account, please visit the “My” →
          Submit the application in ”Account Management“ → ”Account
          Cancellation“. After you cancel your account, we will stop providing
          you with ODAILY services and protect your information through
          anonymization and other means in accordance with relevant laws and
          regulations.
        </p>

        <p>
          7. Self-management and control of marketing information and targeted
          push{' '}
        </p>
        <p>
          7.1 Push Notifications - If you do not want to receive notifications
          on the App page, you can refuse to receive notifications when you
          first install the App, or in the “My” → “Push Management“ Turn off the
          corresponding notification function.
        </p>
        <p>
          7.2 Promotion/Return Visit - If you do not wish to receive our
          promotion SMS or phone return visit, you can unsubscribe through the
          appropriate unsubscribe function in the message, or explicitly decline
          in the phone return visit.
        </p>
        <p>8. Respond to your needs </p>
        <p>
          {' '}
          In the following cases, according to the requirements of laws and
          regulations, we will not be able to respond to your request:{' '}
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) related to the fulfillment of our obligations under laws and
            regulations;{' '}
          </li>
          <li>
            2) directly related to national security and national defense
            security;{' '}
          </li>
          <li>
            3) directly related to public safety, public health, and major
            public interests;{' '}
          </li>
          <li>
            4) directly related to criminal investigation, prosecution, trial
            and enforcement of judgments;{' '}
          </li>
          <li>
            5) There is sufficient evidence that the personal information
            subject has subjective malice or abuse of rights;{' '}
          </li>
          <li>
            6) In order to protect the life, property and other major legitimate
            rights and interests of the personal information subject or other
            individuals, but it is difficult to obtain the authorization and
            consent of the person;
          </li>
          <li>
            7) Responding to your request will cause serious damage to the
            legitimate rights and interests of you or other individuals and
            organizations;
          </li>
          <li>8) involving trade secrets. </li>
        </ul>

        <h6> Protection of Minors` Personal Information </h6>
        <p>
          1. We attach great importance to the protection of minors` personal
          information. According to the provisions of relevant laws and
          regulations, if you are a minor under the age of 18, before using
          ODAILY Services, you should jointly read and agree to this Privacy
          Policy under the supervision and guidance of your parents or other
          guardians.
        </p>
        <p>
          2. We protect minors` personal information in accordance with relevant
          national laws and regulations, and will only collect, use, store,
          share, transfer or disclose minors` personal information when
          permitted by law, parents or other guardians express consent or
          necessary for the protection of minors; If we become aware that a
          minor`s personal information has been collected without prior
          verifiable guardian consent, we will try to delete the information as
          soon as possible.
        </p>
        <p>
          3. If you are the guardian of a minor, if you have any questions about
          the personal information of a minor under your custody, please contact
          us through the methods provided in Article 11 of this Privacy Policy.
        </p>

        <h6> Privacy Policy Changes and Notices </h6>
        <p>
          1. In order to provide you with better service, our ODAILY service
          will change from time to time, and this privacy Policy will also be
          adjusted accordingly.
        </p>
        <p>
          2. Without your express consent, we will not reduce your rights under
          this Privacy Policy. We will pass App Notify you by reasonable means
          such as push notification, pop-up window, etc., so that you can keep
          abreast of any changes made to this Privacy Policy. You can view the
          “My” → “More Services” → “Privacy Policy” View the latest content of
          this Privacy Policy.
        </p>
        <p>
          3. For major changes, depending on the specific circumstances, we will
          also provide a more prominent notice explaining the specific changes
          to this Privacy Policy. Significant changes include, but are not
          limited to:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) Significant changes in our service model. Such as the purpose of
            processing personal information, the type of personal information
            processed, the use of personal information, etc.;
          </li>
          <li>
            2) We have made significant changes in our ownership structure,
            organizational structure, etc. Such as business adjustment,
            bankruptcy merger and acquisition caused by the owner change;
          </li>
          <li>
            3) The main object of personal information sharing, transfer or
            public disclosure has changed;{' '}
          </li>
          <li>
            4) your rights to participate in the processing of personal
            information and the manner in which they are exercised have changed
            significantly;{' '}
          </li>
          <li>
            5) Our department responsible for handling personal information
            security, contact methods and complaint channels have changed;
          </li>
          <li>
            6) When the personal information security impact assessment report
            indicates a high risk.{' '}
          </li>
        </ul>

        <p>
          4. If you do not agree with the revised Privacy Policy, you have the
          right and shall immediately stop using the ODAILY Service. However,
          please understand that if you do not agree to the revised Privacy
          Policy, you may not be able to continue to fully and normally use the
          relevant ODAILY services and some functions, or you may not be able to
          achieve the service effect we expect.
        </p>
        <h6> ten. We protect Personal Information of deceased users </h6>
        <p>
          Starting from November 1, 2021, we will formulate a system for the
          protection of personal information of deceased persons in accordance
          with the relevant provisions of the Personal Information Protection
          Act. After the death of an ODAILY user (only natural person), his or
          her close relatives may, for their own legal and legitimate interests,
          exercise the right to access, copy, correct, delete, etc. relevant
          personal information of the deceased user through the contact
          information provided in Article 11 of this Privacy Policy, unless
          otherwise arranged before the death of the user.
        </p>

        <h6>eleven. How to contact us </h6>
        <p>
          If you have any complaints, suggestions, questions about the
          protection of personal information, or you have any questions about
          this privacy policy and need us to explain, you can contact us through
          the following ways, we will review the problem as soon as possible,
          and reply within 15 working days after verifying your user identity:
        </p>
        <p>
          1. Send an email to om@odaily.email to contact our Personal
          Information protection officer;{' '}
        </p>
        <p>
          2. You can contact us through “My” → “Feedback” → “Other questions”.{' '}
        </p>

        <h6> Dispute Resolution </h6>
        <p>
          Any dispute arising out of this Privacy Policy and our handling of
          your personal information may be brought to the Chaoyang District
          People`s Court of Beijing. If you believe that our personal
          information processing has harmed your legitimate rights and
          interests, you may also report to the relevant government departments.
        </p>
      </div>
    );
  }
}

export default PrivacyEn;
