import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import _ from 'lodash';
import Share from '../../common/share/ListShare';
import { timesAgo } from '../../../common/scripts/time';
import { getNewsflash } from '../../../common/api/newsflash';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';

const TYPE = {
  ALL: 'all',
  MY: 'my'
};

export class NewsflashWidget2 extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([TYPE.ALL, TYPE.MY]).isRequired,
    newsList: PropTypes.array.isRequired,
    match: PropTypes.object
  };
  static defaultProps = {
    type: TYPE.ALL
  };
  constructor(props) {
    super(props);
    this.showContent = this.showContent.bind(this);
    this.state = {
      list: this.props.newsList,
      showList: [],
      rotate: 0,
      numer: 1
    };
  }
  showContent(id) {
    const { showList } = this.state;
    const isShow = showList.indexOf(id);

    if (isShow !== -1) {
      this.setState({
        showList: showList.filter((_, i) => i !== isShow)
      });
    } else {
      this.setState({
        showList: [...showList, id]
      });
    }
  }
  renderTimesAgo(item) {
    return timesAgo(item.published_at);
  }
  renderList() {
    let { list } = this.state;
    return list
      .filter((item, index) => {
        return index < 5;
      })
      .map(item => {
        const showStyles =
          this.state.showList.indexOf(item.id) !== -1 ? styles.show : '';
        const pinStyles = item.pin ? styles.pin : '';
        return (
          <div key={item.id} className={styles.item}>
            <i className={styles['icon-dot']}>
              <i className={styles['icon-dot-inner2']} />
            </i>
            <h4
              className={`${styles.title} ${pinStyles}`}
              onClick={() => this.showContent(item.id)}
            >
              {item.title}
            </h4>
            <div className={`${styles.content} ${showStyles}`}>
              <p className={styles.description}>{item.description}</p>
            </div>
            <div className={styles.timeBox}>
              <p className={styles.time}>{this.renderTimesAgo(item)}</p>
              {item.share_data ? (
                <div className={styles.info}>
                  <div className={styles.share}>
                    <Share {...item.share_data.default} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      });
  }
  renderMore2Button() {
    const { lang } = this.props.match.params;
    return (
      <a href="/newsflash" className={styles['my-newsflash']}>
        {locales(lang, 'view_all')}
      </a>
    );
  }
  getNewsFlash() {
    getNewsflash({}).then(data => {
      const list = data.data.items;
      this.setState({ list });
    });
  }
  componentDidMount() {
    this.getNewsFlash();
  }
  refreshHandle() {
    const { numer } = this.state;
    this.getNewsFlash();
    this.setState({
      rotate: 360 * numer,
      numer: numer + 1
    });
  }
  render() {
    return (
      <div className={styles.container}>
        <div className={styles['bored']} />
        <div
          className={styles['refresh']}
          style={{
            transform: `rotate(${this.state.rotate}deg)`,
            transition: '1s'
          }}
          onClick={_.debounce(this.refreshHandle.bind(this), 300)}
        />
        <div className={styles['content']}>{this.renderList()}</div>
        {this.renderMore2Button()}
      </div>
    );
  }
}
export default withRouter(NewsflashWidget2);
