import React from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';

import styles from './index.scss';

export default class Sticky extends React.Component {
  static propTypes = {
    styleData: PropTypes.object,
    children: PropTypes.any
  };

  static defaultProps = {
    styleData: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      positionTop: 0
      // winTop: 0,
      // stickyEleTop: 0,
      // stickyScrollTop: 0
    };
  }

  componentDidMount() {
    const stickyEleHeight = this.stickyEle.offsetHeight;
    const winHeight = document.documentElement.clientHeight;

    if (stickyEleHeight + 20 > winHeight) {
      this.setState({ positionTop: winHeight - (stickyEleHeight + 20) });
    }

    // window.onmousewheel = _.debounce(this.handleScroll.bind(this), 0);

    // this.setState({
    //   winTop: document.documentElement.scrollTop,
    //   stickyEleTop: this.stickyEle.offsetTop
    // });
  }

  handleScroll(e) {
    let elem = e.target;

    while (elem) {
      if (
        this.stickyEle &&
        elem.className &&
        elem.className.includes(this.stickyEle.className)
      ) {
        this.stickyScroll();
        return;
      }
      elem = elem.parentNode;
    }

    this.windowScroll();
  }

  stickyScroll() {
    const { stickyScrollTop } = this.state;
    const newStickyScrollTop = this.stickyEle.scrollTop;
    const value = newStickyScrollTop - stickyScrollTop;
    this.setState({ stickyScrollTop: newStickyScrollTop });
    if (value !== 0) {
      this.setState({ winTop: document.documentElement.scrollTop + value });
      document.documentElement.scrollTop =
        document.documentElement.scrollTop + value;
    }
  }

  windowScroll() {
    const { winTop, stickyEleTop } = this.state;
    const newWinTop = document.documentElement.scrollTop;
    const value = newWinTop - winTop;
    this.setState({ winTop: newWinTop });

    if (newWinTop > stickyEleTop + 50) {
      this.stickyEle.scrollTop = this.stickyEle.scrollTop + value;
      this.setState({
        stickyScrollTop: this.stickyEle.scrollTop + value
      });
    } else {
      this.stickyEle.scrollTop = 0;
      this.setState({
        stickyScrollTop: 0
      });
    }
  }

  render() {
    const { styleData } = this.props;
    const { positionTop } = this.state;
    return (
      <div
        style={{ ...styleData, top: positionTop ? positionTop + 'px' : null }}
        className={styles.sticky}
        ref={e => {
          this.stickyEle = e;
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
