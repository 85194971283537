import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { timesAgo } from '../../../common/scripts/time';
import { getNewsflash } from '../../../common/api/newsflash';
import { dot } from '../../../common/scripts/dot';
import locales from '../../../utils/locales';

export class SideList extends React.PureComponent {
  static propTypes = {
    newsList: PropTypes.array.isRequired,
    currentUser: PropTypes.object,
    iconFlag: PropTypes.bool,
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.newsList,
      time: 30,
      animationFlag: 'paused',
      timeLoading: false
    };
  }
  getNewsFlash() {
    getNewsflash({}).then(data => {
      setTimeout(() => {
        const list = data.data.items;
        this.setState({ list, timeLoading: false, time: 30 });
        this.countDown();
      }, 980);
    });
  }
  componentDidMount() {
    this.countDown();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'hidden') {
        //切离该页面时执行
        this.setState({
          timeLoading: true,
          time: 30
        });
        clearTimeout(this.timer);
        this.timer = null;
      } else if (document.visibilityState == 'visible') {
        //切换到该页面时执行
        if (!this.timer) {
          this.countDown();
          this.setState({
            timeLoading: false,
            time: 30
          });
        }
      }
    });
  }
  countDown() {
    let { time } = this.state;
    let num = time - 1;
    this.timer = setTimeout(() => {
      if (num === 0) {
        this.setState(
          {
            timeLoading: true
          },
          () => {
            this.setState({
              animationFlag: 'paused'
            });
            this.getNewsFlash();
          }
        );
        return;
      }
      this.setState(
        {
          time: num,
          animationFlag: 'running'
        },
        () => {
          this.countDown();
        }
      );
      return;
    }, 1000);
  }
  renderList() {
    const { list } = this.state;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.newsList}>
        {list.slice(0, 5).map(item => {
          return (
            <div
              className={`${styles.newsItem} ${styles.newsItem_border}`}
              // className={`${styles.newsItem} ${
              //   item.pin ? styles.newsItem_border_pin : styles.newsItem_border
              // }`}
              key={`newsItem${item.id}`}
            >
              <div className={styles.newsTime}>
                {timesAgo(item.published_at, undefined, lang)}
              </div>
              <div className={styles.newsTitle}>
                <a
                  href={
                    lang
                      ? `/${lang}/newsflash/${item.id}`
                      : `/newsflash/${item.id}`
                  }
                  target={'_blank'}
                  rel="noreferrer"
                  style={{
                    WebkitBoxOrient: 'vertical',
                    color: item.pin ? '#EE4C53' : null
                  }}
                >
                  {/* {item.pin ? (
                    <div className={styles.newsTitle_icon}></div>
                  ) : (
                    ''
                  )} */}
                  {item.title}
                  {item.pin ? <div className={styles.newsTitle_icon2} /> : ''}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  render() {
    const { time, animationFlag, timeLoading } = this.state;
    const { iconFlag } = this.props;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.homeSide_container}>
        <div className={styles.homeSide_header}>
          <div className={styles.homeSide_header_left}>
            <div
              className={styles.homeSide_bg}
              style={{ top: iconFlag ? '-15px' : '-5px' }}
            />
            <div className={styles.homeSide_title} style={{ marginTop: '5px' }}>
              {locales(lang, 'newsflash', {
                isTitle: true,
                style: { fontSize: '18px' }
              })}
            </div>
            {iconFlag || lang == 'ja' ? (
              ''
            ) : (
              <div>
                {timeLoading ? (
                  <div className={styles.homeSide_time_loading} />
                ) : (
                  <div className={styles.homeSide_time}>
                    <div className={styles.homeSide_time_word}>
                      <span>{time}</span>
                    </div>
                    <div className={styles.homeSide_time_left_box}>
                      <div
                        className={styles.homeSide_time_left}
                        style={{ animationPlayState: animationFlag }}
                      />
                    </div>
                    <div className={styles.homeSide_time_right_box}>
                      <div
                        className={styles.homeSide_time_right}
                        style={{ animationPlayState: animationFlag }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <a
            className={styles.homeSide_button}
            href={lang ? `/${lang}/newsflash` : '/newsflash'}
            target={'block'}
            onClick={() => {
              dot({
                id: 15,
                user_id: this.props.currentUser ? this.props.currentUser.id : ''
              });
            }}
          />
        </div>
        {this.renderList()}
      </div>
    );
  }
}

export default SideList;
