import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './index.scss';

import NewLibrary from '../newLibrary';
import { setFavorite } from '../../common/api/library';
import { withRouter } from 'react-router-dom';
import locales from '../../utils/locales';

class CollectLibrary extends React.PureComponent {
  static propTypes = {
    postFavoriteList: PropTypes.array.isRequired,
    collectFlag: PropTypes.bool.isRequired,
    isCollected: PropTypes.bool,
    changeCollectFlag: PropTypes.func.isRequired,
    getCollect: PropTypes.func,
    id: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      newLibraryFlag: false,
      postFavoriteList: props.postFavoriteList,
      loading: true,
      is_default_index: '',
      oldPostFavoriteList: ''
    };
  }

  componentDidMount() {
    const { postFavoriteList, oldPostFavoriteList } = this.state;

    let is_default_index = '';
    let isCollected = false;
    postFavoriteList.map((item, index) => {
      if (item.is_favorite) {
        isCollected = true;
      }

      if (item.is_default) {
        is_default_index = index;
      }
    });

    if (!isCollected && typeof is_default_index === 'number') {
      postFavoriteList[is_default_index].is_favorite = true;
      this.setState({
        is_default_index
      });
    }

    document.body.style.overflow = 'hidden';

    if (!oldPostFavoriteList) {
      this.setState({
        oldPostFavoriteList: JSON.parse(
          JSON.stringify(this.props.postFavoriteList)
        )
      });
    }
  }

  mouseWheel(e) {
    //阻止事件冒泡和默认行为的完整兼容性代码
    e = e || window.event;
    if (e.stopPropagation) {
      //这是取消冒泡
      e.stopPropagation();
    } else {
      e.cancelBubble = true;
    }
    if (e.preventDefault) {
      //这是取消默认行为，要弄清楚取消默认行为和冒泡不是一回事
      e.preventDefault();
    } else {
      e.returnValue = false;
    }
  }

  // UNSAFE_componentWillReceiveProps(newProps) {
  //   if (newProps.collectFlag) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'auto';
  //   }
  // }

  changeNewLibraryFlag() {
    const { newLibraryFlag } = this.state;

    this.setState({ newLibraryFlag: !newLibraryFlag });
  }

  changePostFavoriteList(index) {
    let { postFavoriteList } = this.state;
    postFavoriteList[index].is_favorite = !postFavoriteList[index].is_favorite;
    this.setState({ postFavoriteList: [...postFavoriteList] });
  }

  onFinish() {
    const { postFavoriteList, loading, is_default_index } = this.state;
    const { id, changeCollectFlag, getCollect } = this.props;
    if (loading) {
      this.setState({ loading: false });
      let library_ids = '';
      postFavoriteList.map(item => {
        if (item.is_favorite) {
          library_ids =
            library_ids === ''
              ? library_ids + item.id
              : library_ids + `,${item.id}`;
        }
      });

      setFavorite('post', id, library_ids).then(data => {
        if (data.code === 0) {
          this.setState({ loading: true });
          if (getCollect) {
            getCollect();
          }
          changeCollectFlag();
          document.body.style.overflow = 'auto';
          if (library_ids === '' && typeof is_default_index === 'number') {
            postFavoriteList[is_default_index].is_favorite = true;
          }

          this.setState({
            oldPostFavoriteList: JSON.parse(JSON.stringify(postFavoriteList))
          });
        }
      });
    }
  }

  closeCollect() {
    const { changeCollectFlag } = this.props;
    let { oldPostFavoriteList } = this.state;

    changeCollectFlag();
    document.body.style.overflow = 'auto';
    this.state.postFavoriteList.map((item, index) => {
      item.is_favorite = oldPostFavoriteList[index].is_favorite;
    });
    // this.props.postFavoriteList = oldPostFavoriteList;
  }

  render() {
    const { collectFlag, match } = this.props;
    const { newLibraryFlag, postFavoriteList, loading } = this.state;
    const { lang } = match.params;
    return (
      <div>
        {collectFlag ? (
          <div
            onClick={() => {
              this.closeCollect();
            }}
            className={styles.collectLibrary_alert_bg}
          />
        ) : (
          ''
        )}

        {collectFlag ? (
          <div className={styles.collectLibrary_box}>
            <div
              className={styles.collectLibrary_box_close}
              onClick={() => {
                this.closeCollect();
              }}
            />

            <div className={styles.collectLibrary_box_title}>
              {locales(lang, 'select')}
            </div>

            <div className={styles.collectLibrary_box_text}>
              {locales(lang, 'selectToAdd')}
            </div>

            <div className={styles.collectLibrary_box_list}>
              {postFavoriteList.map((item, index) => {
                return (
                  <div
                    className={styles.collectLibrary_box_item}
                    key={`collectLibrary_box_item${index}`}
                    onClick={() => {
                      this.changePostFavoriteList(index);
                    }}
                  >
                    <div className={styles.collectLibrary_box_item_name}>
                      {item.name}
                    </div>

                    <div className={styles.collectLibrary_box_item_right}>
                      {item.is_default === 1 ? (
                        <div className={styles.collectLibrary_box_item_icon}>
                          {locales(lang, 'default')}
                        </div>
                      ) : (
                        ''
                      )}

                      <div
                        className={
                          item.is_favorite
                            ? styles.collectLibrary_box_item_check
                            : styles.collectLibrary_box_item_unCheck
                        }
                      />
                    </div>
                  </div>
                );
              })}

              <div
                className={styles.collectLibrary_box_list_button}
                onClick={() => {
                  this.changeNewLibraryFlag();
                  // changeCollectFlag();
                  document.body.style.overflow = 'auto';
                }}
              >
                <div className={styles.collectLibrary_box_list_button_icon} />
                {locales(lang, 'new_library')}
              </div>
            </div>

            <div className={styles.collectLibrary_box_button_wrapper}>
              <div
                className={styles.collectLibrary_box_button}
                onClick={() => {
                  this.onFinish();
                }}
                style={{ background: loading ? null : '#999' }}
              >
                {loading
                  ? locales(lang, 'complete')
                  : locales(lang, 'loading_title')}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        <NewLibrary
          alertFlag={newLibraryFlag}
          changeAlertFlag={this.changeNewLibraryFlag.bind(this)}
        />
      </div>
    );
  }
}

export default withRouter(connect()(CollectLibrary));
