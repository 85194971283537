import React, { Component } from 'react';
import styles from '../../index.scss';

export class ApplicationKo extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>신청 허가 신청 및 이용 안내</h5>
        <p>
          1. ODAILYApp 제품의 기능 구현과 안전하고 안정적인 운영을 보장하기 위해
          운영 체제의 관련 권한을 신청하거나 사용할 수 있습니다.
        </p>
        <p>
          2. 귀하의 알 권리를 보호하기 위해 당사는 제품이 신청하고 사용할 수
          있는 관련 운영 체제 권한을 다음 목록을 통해 표시하며 실제 필요에 따라
          관련 권한을 관리할 수 있습니다.
        </p>
        <p>
          3. 제품 업그레이드에 따라 적용 및 사용 권한의 종류와 목적이 변경될 수
          있으며, 이러한 변경 사항에 따라 권한 적용 및 사용 권한을 적시에
          알려드릴 수 있도록 신속하게 목록을 조정하겠습니다.
        </p>
        <p>
          4. 당사는 또한 당사 비즈니스 및 제품의 기능 및 보안 요구를 위해 타사
          SDK를 사용할 수 있으며 이러한 타사도 관련 운영 체제 권한을 신청하거나
          사용할 수 있음을 유의하십시오.자세한 내용은 다섯 번째 부분을
          참조하십시오. &quot;오데일리 개인정보취급방침&quot; 기사에서 제공하는
          &quot;정보공유 목록&quot;
        </p>
        <p>
          5. 제품을 사용하는 과정에서 타사에서 개발한 H5 페이지 또는 애플릿을
          사용할 수 있으며 이러한 타사에서 개발한 플러그인 또는 애플릿도
          비즈니스 기능에 필요한 관련 운영 체제 권한을 신청하거나 사용할 수
          있습니다.
        </p>
        <p>
          6. 오데일리 앱에 적용되는 사항은 다음과 같으며, 별도로 적용 가능한
          시나리오나 목적이 있는 경우 아래 목록에 기재합니다.
        </p>

        <h6>Android 애플리케이션 권한 애플리케이션 목록</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>권한 이름</h6>
              </td>
              <td>
                <h6>권한 설명</h6>
              </td>
              <td>
                <h6>시나리오 또는 목적 사용</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>외부 메모리 읽기</p>
              </td>
              <td>
                <p>사용자가 사진, 파일 등을 업로드하는 데 사용됩니다.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>외부 메모리에 쓰기</p>
              </td>
              <td>
                <p>
                  사용자가 사진을 저장하고 충돌 로그 정보를 로컬에 기록하는 데
                  사용(있는 경우)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MOUNT_UNMOUNT_FILESYSTEMS</p>
              </td>
              <td>
                <p>외부 파일 시스템 마운트/마운트 해제</p>
              </td>
              <td>
                <p>사용자가 사진, 파일 등을 업로드하는 데 사용됩니다.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_PHONE_STATE</p>
              </td>
              <td>
                <p>전화 상태 읽기</p>
              </td>
              <td>
                <p>
                  휴대전화 장치 식별 및 기타 정보에 액세스하는 데 사용되며, 이
                  권한은 통화 내용 및 정보를 모니터링하거나 얻을 수 없습니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAMERA</p>
              </td>
              <td>
                <p>카메라 사용</p>
              </td>
              <td>
                <p>사용자가 아바타를 변경할 때 사진을 찍기 위해 사용됩니다.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_ALERT_WINDOW</p>
              </td>
              <td>
                <p>시스템 부동 창</p>
              </td>
              <td>
                <p>
                  나중에 사용자가 기사 탐색을 종료할 때 플로팅 창을 보도록
                  사용자에게 표시하는 데 사용됩니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_OVERLAY_WINDOW</p>
              </td>
              <td>
                <p>시스템 최상위 창</p>
              </td>
              <td>
                <p>사용자가 앱 사용 시 푸시 알림을 적시에 수신하기 위해 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>INTERNET</p>
              </td>
              <td>
                <p>액세스 네트워크</p>
              </td>
              <td>
                <p>디바이스 네트워킹용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_NETWORK_STATE</p>
              </td>
              <td>
                <p>네트워크 상태 정보 얻기</p>
              </td>
              <td>
                <p>
                  사용자 트래픽 소모를 줄이기 위해 네트워크 상태(셀룰러,
                  와이파이)에 따라 비디오를 재생하거나 일시 중지하는 데
                  사용됩니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_WIFI_STATE</p>
              </td>
              <td>
                <p>현재 Wi-Fi 상태 정보 가져오기</p>
              </td>
              <td>
                <p>
                  사용자 트래픽 소모를 줄이기 위해 네트워크 상태(셀룰러,
                  와이파이)에 따라 비디오를 재생하거나 일시 중지하는 데
                  사용됩니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_WIFI_STATE</p>
              </td>
              <td>
                <p>WiFi 상태 변경</p>
              </td>
              <td>
                <p>
                  위치를 결정하기 위해 GPS를 지원하는 데 사용됩니다. 이 권한은
                  Wi-Fi 계정과 비밀번호를 얻을 수 없습니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_NETWORK_STATE</p>
              </td>
              <td>
                <p>프로그램이 네트워크 상태를 변경할 수 있도록 합니다.</p>
              </td>
              <td>
                <p>
                  네트워크가 불안정할 때 사용자에게 더 나은 서비스를 제공하기
                  위해 사용
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_LOGS</p>
              </td>
              <td>
                <p>시스템 로그 읽기</p>
              </td>
              <td>
                <p>애플리케이션 오류 로그 정보 수집에 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_SETTINGS</p>
              </td>
              <td>
                <p>쓰기 시스템 설정</p>
              </td>
              <td>
                <p>
                  시스템 알림, 밝기 및 기타 설정을 동기화하는 데 사용됩니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_SETTINGS</p>
              </td>
              <td>
                <p>시스템 설정 읽기</p>
              </td>
              <td>
                <p>
                  사용자가 시스템 알림, 밝기 및 기타 설정을 변경하는 데
                  사용됩니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MODIFY_AUDIO_SETTINGS</p>
              </td>
              <td>
                <p>사운드 설정 수정</p>
              </td>
              <td>
                <p>동영상 시청 중 음량 조절 시 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WAKE_LOCK</p>
              </td>
              <td>
                <p>잠금 화면에서 계속 실행 허용</p>
              </td>
              <td>
                <p>동영상 감상 시 화면이 자동으로 잠기지 않는 문제</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECEIVE_BOOT_COMPLETED</p>
              </td>
              <td>
                <p>자동 시작 허용</p>
              </td>
              <td>
                <p>
                  메시지 푸시 도착률을 개선하기 위해 사용되며, 이 권한은 어떤
                  조건에서도 자동으로 활성화될 수 없습니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VIBRATE</p>
              </td>
              <td>
                <p>프로그램이 진동을 사용하도록 허용</p>
              </td>
              <td>
                <p>
                  중요한 푸시 또는 부분적인 상호 작용을 수신할 때 진동을
                  사용하여 사용자에게 알림
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FOREGROUND_SERVICE</p>
              </td>
              <td>
                <p>포그라운드 서비스 사용 허용</p>
              </td>
              <td>
                <p>
                  오디오 콘텐츠를 재생하는 데 사용하는 경우 사용자가 오디오를
                  듣는 동안 서비스 중단을 방지합니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECORD_AUDIO</p>
              </td>
              <td>
                <p>마이크 사용</p>
              </td>
              <td>
                <p>
                  ODAILY 비즈니스에 액세스하고 여는 데 사용되며 오디오를
                  사용하여 일부 시나리오에서 메모를 녹음합니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MANAGE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>모든 파일에 대한 액세스 허용</p>
              </td>
              <td>
                <p>
                  ODAILY 비즈니스에 액세스하고 여는 데 사용되며 사용자는 사진을
                  저장하고 충돌 로그 정보를 로컬에 기록합니다(있는 경우).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FLASHLIGHT</p>
              </td>
              <td>
                <p>플래시에 대한 액세스 허용</p>
              </td>
              <td>
                <p>
                  중요한 푸시를 받았을 때 깜박이는 빛으로 사용자에게 상기시키기
                  위해 사용
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_INSTALL_PACKAGES</p>
              </td>
              <td>
                <p>출처를 알 수 없는 설치 권한</p>
              </td>
              <td>
                <p>
                  ODAILYApp의 업데이트 설치를 위해 이 권한은 다른 응용
                  프로그램을 설치할 수 없습니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECIEVE_MCS_MESSAGE</p>
              </td>
              <td>
                <p>전체 네트워크 액세스</p>
              </td>
              <td>
                <p>네트워크에 액세스하기 위해</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CALL_PHONE</p>
              </td>
              <td>
                <p>다이얼러를 거치지 않고 전화 통화 UI 시작</p>
              </td>
              <td>
                <p>전화 걸기</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_FINE_LOCATION</p>
              </td>
              <td>
                <p>위치정보 권한 취득</p>
              </td>
              <td>
                <p>위치를 얻기 위해</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_IGNORE_BATTERY_OPTIMIZATIONS</p>
              </td>
              <td>
                <p>배터리 최적화 권한 얻기</p>
              </td>
              <td>
                <p>배터리 사용 최적화에 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_SHORTCUT</p>
              </td>
              <td>
                <p>업데이트 권한</p>
              </td>
              <td>
                <p>권한 업데이트</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_BADGE</p>
              </td>
              <td>
                <p>메시지 알림 권한</p>
              </td>
              <td>
                <p>푸시 메시지용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_COUNT</p>
              </td>
              <td>
                <p>수량 권한 업데이트</p>
              </td>
              <td>
                <p>업데이트 수를 가져오는 데 사용됨</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_BADGE</p>
              </td>
              <td>
                <p>SMS 알림 업데이트</p>
              </td>
              <td>
                <p>SMS 콘텐츠를 받는 데 사용됨</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_BADGE</p>
              </td>
              <td>
                <p>데스크톱 배지 권한 적용</p>
              </td>
              <td>
                <p>모서리 표시 정보를 표시하는 데 사용됩니다.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>GET_TASKS</p>
              </td>
              <td>
                <p>애플리케이션이 현재 프로세스를 가져오도록 허용</p>
              </td>
              <td>
                <p>현재 프로세스를 얻기 위해</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH</p>
              </td>
              <td>
                <p>블루투스 받기</p>
              </td>
              <td>
                <p>블루투스 사용을 위해</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH_ADMIN</p>
              </td>
              <td>
                <p>블루투스 권한 얻기</p>
              </td>
              <td>
                <p>블루투스 권한을 얻기 위해 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_COARSE_LOCATION</p>
              </td>
              <td>
                <p>대략적인 위치 정보 얻기</p>
              </td>
              <td>
                <p>위치 정보 획득에 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MIPUSH_RECEIVE</p>
              </td>
              <td>
                <p>Xiaomi 푸시 수신</p>
              </td>
              <td>
                <p>Xiaomi 푸시 수신에 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_ADDED</p>
              </td>
              <td>
                <p>암시적 브로드캐스트 수락</p>
              </td>
              <td>
                <p>방송 수신용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_CHANGED</p>
              </td>
              <td>
                <p>애플리케이션 변경 사항 수신</p>
              </td>
              <td>
                <p>응용 프로그램 변경 사항을 보는 데 사용됨</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_INSTALL</p>
              </td>
              <td>
                <p>애플리케이션 설치</p>
              </td>
              <td>
                <p>응용 프로그램 설치</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_REPLACED</p>
              </td>
              <td>
                <p>애플리케이션 교체</p>
              </td>
              <td>
                <p>이전 버전 응용 프로그램을 대체하는 데 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RESTART_PACKAGES</p>
              </td>
              <td>
                <p>응용 프로그램 다시 시작</p>
              </td>
              <td>
                <p>응용 프로그램을 다시 시작하려면</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MESSAGE</p>
              </td>
              <td>
                <p>정보에 대한 접근</p>
              </td>
              <td>
                <p>정보를 얻기 위해</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>C2D_MESSAGE</p>
              </td>
              <td>
                <p>C2D 메시지 받기</p>
              </td>
              <td>
                <p>C2D 정보 획득에 사용</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>iOS 앱 권한 적용 목록</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>권한 이름</h6>
              </td>
              <td>
                <h6>권한 설명</h6>
              </td>
              <td>
                <h6>시나리오 또는 목적 사용</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-CameraUsageDescription</p>
              </td>
              <td>
                <p>카메라 사용</p>
              </td>
              <td>
                <p>사용자가 아바타를 변경할 때 사진을 찍기 위해 사용됩니다.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-MicrophoneUsageDescription</p>
              </td>
              <td>
                <p>마이크 사용</p>
              </td>
              <td>
                <p>
                  ODAILY 비즈니스에 액세스하고 여는 데 사용되며 오디오를
                  사용하여 일부 시나리오에서 메모를 녹음합니다.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryAdditionsUsageDescription</p>
              </td>
              <td>
                <p>앨범에 콘텐츠 추가</p>
              </td>
              <td>
                <p>사용자가 사진을 저장하려면</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryUsageDescription</p>
              </td>
              <td>
                <p>앨범 내용 읽기</p>
              </td>
              <td>
                <p>사용자가 아바타를 업로드하려면</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ApplicationKo;
