exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-search-global--container-index {\n  display: none;\n  position: fixed;\n  z-index: 200;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.4); }\n  .src-components-search-global--container-index .src-components-search-global--header-index {\n    height: 70px;\n    background-color: #fff;\n    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);\n            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1); }\n  .src-components-search-global--container-index .src-components-search-global--search-index {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    margin: 0 auto;\n    max-width: 600px;\n    padding: 24px 0 5px;\n    border-bottom: 1px solid #ccc; }\n    .src-components-search-global--container-index .src-components-search-global--search-index .src-components-search-global--icon-search-index {\n      display: inline-block;\n      width: 20px;\n      height: 20px;\n      background-repeat: no-repeat;\n      background-size: 100%;\n      background-image: url(\"https://piccdn.0daily.com/201807/02061912/tel2ms5ke7s39twi.png\"); }\n    .src-components-search-global--container-index .src-components-search-global--search-index .src-components-search-global--icon-close-index {\n      display: inline-block;\n      width: 18px;\n      height: 18px;\n      background-repeat: no-repeat;\n      background-size: 100%;\n      background-image: url(\"https://piccdn.0daily.com/201807/02074013/a5lek621d6627fbd.png\");\n      cursor: pointer; }\n    .src-components-search-global--container-index .src-components-search-global--search-index .src-components-search-global--keywords-index {\n      -ms-flex: 1 1;\n          flex: 1 1;\n      margin: 0 22px;\n      height: 26px;\n      border: none;\n      outline: none;\n      color: #333;\n      font-size: 14px; }\n\n.src-components-search-global--show-index {\n  display: block; }\n", ""]);

// exports
exports.locals = {
	"container": "src-components-search-global--container-index",
	"header": "src-components-search-global--header-index",
	"search": "src-components-search-global--search-index",
	"icon-search": "src-components-search-global--icon-search-index",
	"icon-close": "src-components-search-global--icon-close-index",
	"keywords": "src-components-search-global--keywords-index",
	"show": "src-components-search-global--show-index"
};