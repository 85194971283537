import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import styles from './index.scss';
import rankStyles from './rank.scss';
import Layout from '../../components/common/layout';
import { getHelmet } from '../../common/scripts/helmet';

const banner1Png = 'https://piccdn.0daily.com/202407/01000103/banner-1.png';
const titleBgPng = 'https://piccdn.0daily.com/202407/01000103/title-bg.png';
import fatSvg from '../../assets/image/fat/2024/over/fat.svg';
import titleBgSvg from '../../assets/image/fat/2024/over/title-bg.svg';
import titleFlagSvg from '../../assets/image/fat/2024/over/title-flag.svg';
import titleTimeBgSvg from '../../assets/image/fat/2024/over/title-time-bg.svg';
import { fat2024RankData } from './data';

const history2020Png =
  'https://piccdn.0daily.com/202407/01000103/history-2020.png';
const logosPng =
  'https://piccdn.0daily.com/202407/01000103/logos.png';

export class FatOver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let helmetData = getHelmet({
      title: '区块链游戏,区块链新闻资讯,Defi,NFT',
      description:
        '时值岁末，ODAILY将再次携手权威专家学者，开启 2024「FAT」价值时代榜单评选，用最客观的数据、最专业的眼光、最权威的视角呈现区块链行业最具价值榜单的诞生。',
      keywords: '区块链游戏,区块链新闻资讯,Defi,NFT'
    });

    function renderItem(list) {
      return (
        <div className={rankStyles.itemList}>
          {list.map((value, index) => (
            <div className={rankStyles.item} key={index}>
              <div className={rankStyles.imageBg}>
                <img
                  className={rankStyles.image}
                  alt={''}
                  src={value.image}
                ></img>
              </div>
              <div className={rankStyles.name}>
                <div className={rankStyles.text}>{value.name}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    function renderGuestItem(list) {
      return (
        <div className={rankStyles.itemList} style={{ width: '1164px', gap: '44px 54px' }}>
          {list.map((value, index) => (
            <div className={rankStyles.item} key={index} style={{ height: '223px', justifyContent: 'flex-start' }}>
              <div className={rankStyles.imageBg}>
                <img
                  className={rankStyles.image}
                  alt={''}
                  src={value.image}
                ></img>
              </div>
              <div className={rankStyles.name} style={{ marginTop: '14px', height: 'auto', whiteSpace: 'nowrap' }}>
                <div className={rankStyles.text} style={{ lineHeight: '35px', fontSize: `${value.name === 'Charles d\'Haussy' ? '19px' : '20px'}`, fontWeight: '400' }}>{value.name}</div>
              </div>
              <div className={rankStyles.info}>
                <div className={rankStyles.text}>{value.info}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <img className={styles.bannerImage} alt={''} src={banner1Png}></img>

            <div className={styles.desc}>
              市场风起云涌，但推动周期轮转、热潮奔涌的，永远都是投身于加密行业的众多建设者。为了让这些台前幕后的建设者们的声音被更多人听到，暌违三年，Odaily星球日报于 2024 年 9 月 16 日再度发起“FAT Awards
              2024 年度盛典”,来自不同赛道、不同地区、不同生态的行业参与者们为到场观众奉上了一场精彩的思想盛宴。
              <br />
              <br />
              在加密货币主流化进程日益加快的今天，Odaily星球日报始终坚守行业一线，倾听行业内中流砥柱们的声音，关注市场周期热点的多面影响，记录加密货币行业的过去与现在，就此展望去中心化网络支撑的未来世界。在经历了暴雷、阵痛、恢复、复兴等阶段性的考验之后，本轮周期的行业中坚力量已经成长为又一股颇具影响的加密新势力。
              <br />
              <br />
              本次论坛聚焦目前主流生态相关代表性项目，寻找行业当下最具创新精神的建设性力量，最终从数百个项目、机构及 KOL 中筛选出“ FAT 10 大榜单”，致敬永远向前、永远在路上的建设者们。
            </div>

            <div className={rankStyles.rankBox} style={{ marginTop: '250px' }}>
              <div className={rankStyles.rankBoxHeader} style={{ width: '447px' }}>
                <div className={rankStyles.dot}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.text}>GUESTS</div>
                </div>
                <div className={rankStyles.dot}></div>
                <img
                  className={rankStyles.headerBg}
                  alt={''}
                  src={titleBgSvg}
                  style={{ marginRight: '-350px', marginTop: '-265px' }}
                ></img>
              </div>

              {renderGuestItem(fat2024RankData.guest)}
            </div>

            <div className={rankStyles.rankBox} style={{ marginTop: '389px' }}>
              <div className={rankStyles.rankBoxHeader}>
                <div className={rankStyles.dot}></div>
                <div className={rankStyles.content}>
                  <img
                    className={rankStyles.timeBg}
                    alt={''}
                    src={titleTimeBgSvg}
                  ></img>
                  <div className={rankStyles.text}>2024</div>
                  <div className={rankStyles.fatBox}>
                    <img
                      className={rankStyles.fatBgImage}
                      alt={''}
                      src={titleBgPng}
                    ></img>
                    <img
                      className={rankStyles.fatImage}
                      alt={''}
                      src={fatSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.text}>主榜单</div>
                </div>
                <div className={rankStyles.dot}></div>
                <img
                  className={rankStyles.headerBg}
                  alt={''}
                  src={titleBgSvg}
                ></img>
              </div>

              <div
                className={rankStyles.titleBox}
                style={{ marginTop: '70px', justifyContent: 'center' }}
              >
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>20大活跃投资机构</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 20 Active Venture Capitals
                    </div>
                  </div>
                </div>
              </div>

              {renderItem(fat2024RankData.rank1)}

              <div className={rankStyles.titleBox}>
                <div className={rankStyles.line}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>20大金融服务机构</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 20 CeFi Institutions
                    </div>
                  </div>
                </div>
                <div className={rankStyles.line}></div>
              </div>

              {renderItem(fat2024RankData.rank2)}

              <div className={rankStyles.titleBox}>
                <div className={rankStyles.line}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>30大基础设施</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 30 Infrastructure Providers
                    </div>
                  </div>
                </div>
                <div className={rankStyles.line}></div>
              </div>

              {renderItem(fat2024RankData.rank3)}

              <div className={rankStyles.titleBox}>
                <div className={rankStyles.line}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>30大增量协议</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 30 Projects Driving Mass Adoption
                    </div>
                  </div>
                </div>
                <div className={rankStyles.line}></div>
              </div>

              {renderItem(fat2024RankData.rank4)}
            </div>

            <div className={rankStyles.rankBox} style={{ marginTop: '389px' }}>
              <div className={rankStyles.rankBoxHeader}>
                <div className={rankStyles.dot}></div>
                <div className={rankStyles.content}>
                  <img
                    className={rankStyles.timeBg}
                    alt={''}
                    src={titleTimeBgSvg}
                  ></img>
                  <div className={rankStyles.text}>2024</div>
                  <div className={rankStyles.fatBox}>
                    <img
                      className={rankStyles.fatBgImage}
                      alt={''}
                      src={titleBgPng}
                    ></img>
                    <img
                      className={rankStyles.fatImage}
                      alt={''}
                      src={fatSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.text}>热门生态榜</div>
                </div>
                <div className={rankStyles.dot}></div>
                <img
                  className={rankStyles.headerBg}
                  alt={''}
                  src={titleBgSvg}
                ></img>
              </div>

              <div
                className={rankStyles.titleBox}
                style={{ marginTop: '70px', justifyContent: 'center' }}
              >
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>Solana生态20大项目</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 20 Projects in Solana Ecosystem
                    </div>
                  </div>
                </div>
              </div>

              {renderItem(fat2024RankData.rank5)}

              <div className={rankStyles.titleBox}>
                <div className={rankStyles.line}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>比特币生态20大项目</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 20 Projects in Bitcoin Ecosystem
                    </div>
                  </div>
                </div>
                <div className={rankStyles.line}></div>
              </div>

              {renderItem(fat2024RankData.rank6)}

              <div className={rankStyles.titleBox}>
                <div className={rankStyles.line}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>Ton生态20大项目</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 20 Projects in TON Ecosystem
                    </div>
                  </div>
                </div>
                <div className={rankStyles.line}></div>
              </div>

              {renderItem(fat2024RankData.rank7)}

              <div className={rankStyles.titleBox}>
                <div className={rankStyles.line}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>AI+Crypto 20大项目</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 20 AI+Crypto Projects
                    </div>
                  </div>
                </div>
                <div className={rankStyles.line}></div>
              </div>

              {renderItem(fat2024RankData.rank8)}
            </div>

            <div
              className={rankStyles.rankBox}
              style={{ marginBottom: '0', marginTop: '389px' }}
            >
              <div className={rankStyles.rankBoxHeader}>
                <div className={rankStyles.dot}></div>
                <div className={rankStyles.content}>
                  <img
                    className={rankStyles.timeBg}
                    alt={''}
                    src={titleTimeBgSvg}
                  ></img>
                  <div className={rankStyles.text}>2024</div>
                  <div className={rankStyles.fatBox}>
                    <img
                      className={rankStyles.fatBgImage}
                      alt={''}
                      src={titleBgPng}
                    ></img>
                    <img
                      className={rankStyles.fatImage}
                      alt={''}
                      src={fatSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.text}>最受欢迎榜</div>
                </div>
                <div className={rankStyles.dot}></div>
                <img
                  className={rankStyles.headerBg}
                  alt={''}
                  src={titleBgSvg}
                ></img>
              </div>

              <div
                className={rankStyles.titleBox}
                style={{ marginTop: '70px', justifyContent: 'center' }}
              >
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>最受社区欢迎50大项目</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 50 Community-Favorite Projects
                    </div>
                  </div>
                </div>
              </div>

              {renderItem(fat2024RankData.rank9)}

              <div className={rankStyles.titleBox}>
                <div className={rankStyles.line}></div>
                <div className={rankStyles.content}>
                  <div className={rankStyles.line1}>
                    <div className={rankStyles.text}>最受社区欢迎50大KOL</div>
                    <img
                      className={rankStyles.flag}
                      alt={''}
                      src={titleFlagSvg}
                    ></img>
                  </div>
                  <div className={rankStyles.line2}>
                    <div className={rankStyles.text}>
                      Top 50 Community-Favorite KOLs
                    </div>
                  </div>
                </div>
                <div className={rankStyles.line}></div>
              </div>

              {renderItem(fat2024RankData.rank10)}
            </div>

            <img
              className={rankStyles.logosImage}
              alt={''}
              src={logosPng}
            />

            <a
              href={'/activityed2020'}
              target={'_blank'}
              rel='noopener noreferrer'
              className={styles.historyBox}
              style={{ marginTop: '0px' }}
            >
              <img
                className={styles.historyImg}
                alt={''}
                src={history2020Png}
              />
            </a>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(FatOver);
