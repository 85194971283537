import http from '../scripts/http';

const EXCHANGE = {
  HTX: 1 //火币
};

const BANNER_SYMBOL_LIST = [
  'btcusdt',
  'ethusdt',
  'htxusdt',
  'solusdt',
  'bnbusdt'
];

/**
 * 获取用户自选列表
 * @param exchange
 * @returns {AxiosPromise<any>}
 */
export function getOptionalList(exchange = EXCHANGE.HTX) {
  return http.get(`/newMarket/optional/list?exchange=${exchange}`);
}

/**
 * 添加自选
 * @param symbol
 * @param exchange
 * @returns {AxiosPromise<any>}
 */
export function addMarketOptional(symbol, exchange = EXCHANGE.HTX) {
  return http.post(`/newMarket/optional/add`, {
    exchange: exchange,
    symbol: symbol
  });
}

/**
 * 取消自选
 * @param symbol
 * @param exchange
 * @returns {AxiosPromise<any>}
 */
export function cancelMarketOptional(symbol, exchange = EXCHANGE.HTX) {
  return http.post(`/newMarket/optional/cancel`, {
    exchange: exchange,
    symbol: symbol
  });
}

/**
 * 获取法币汇率
 * @param name
 * @returns {AxiosPromise<any>}
 */
export function getExchangeRate(name = 'usdt_cny') {
  return http.get(`/newMarket/exchangeRate/${name}`);
}

/**
 * 根据交易对列表获取币种信息
 * @param symbols
 * @param exchange
 * @returns {AxiosPromise<any>}
 */
export function getCoinListBySymbols(
  symbols = BANNER_SYMBOL_LIST,
  exchange = EXCHANGE.HTX
) {
  return http.get(
    `/newMarket/coin/listBySymbols?exchange=${exchange}&symbols=${JSON.stringify(
      symbols
    )}`
  );
}

/**
 * 获取市值排行榜列表
 * @param exchange
 * @param page
 * @param page_size
 * @returns {AxiosPromise<any>}
 */
export function getMarketRankList(
  exchange = EXCHANGE.HTX,
  page = 1,
  page_size = 100
) {
  return http.get(
    `/newMarket/rank/marketList?exchange=${exchange}&page=${page}&page_size=${page_size}`
  );
}

/**
 * 根据排行榜类型获取排行榜列表
 * @param type
 * @param exchange
 * @param page
 * @param page_size
 * @returns {AxiosPromise<any>}
 */
export function getMarketRankListByType(
  type,
  exchange = EXCHANGE.HTX,
  page = 1,
  page_size = 100
) {
  return http.get(
    `/newMarket/rank/list?type=${type}&exchange=${exchange}&page=${page}&page_size=${page_size}`
  );
}
