import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header';
import Footer from '../indexFooter';
import Appshare from '../appshare';
import Language from '../../language/Language';
import Search from '../../../pages/searchMiddle';
import styles from './index.scss';

export default class Layout extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {
      searchFlag: false,
      pathname: ''
    };
  }

  componentDidMount() {
    this.setState({ pathname: location.pathname });
  }

  changeSearchFlag() {
    const { searchFlag } = this.state;

    this.setState({
      searchFlag: !searchFlag
    });
  }

  render() {
    const { searchFlag, pathname } = this.state;
    return (
      <React.Fragment>
        <div
          className={`${styles.search} ${
            searchFlag ? styles.search_active : styles.search_hidden
          }`}
        >
          <Search changeSearchFlag={this.changeSearchFlag.bind(this)} />
        </div>

        <div
          className={searchFlag ? styles.search_hidden : styles.search_active}
        >
          {/* 风险提示 */}
          {pathname === '/' ? (
            <div className={styles.tip2}>
              <div>
                风险提示：防范以“虚拟货币”“区块链”名义进行非法集资的风险。——银保监会等五部门
              </div>
            </div>
          ) : (
            ''
          )}
          <Header changeSearchFlag={this.changeSearchFlag.bind(this)} />
          <Appshare />
          {/* 语言切换 */}
          <Language />
          {this.props.children}
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
