import React, { Component } from 'react';
import moment from 'moment';
import styles from './index.scss';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';
import PropTypes from 'prop-types';
// const LOGO_FOOTER = require('../../../assets/image/headerLogo.png');
const LOGO_FOOTER =
  'https://piccdn.0daily.com/202212/21032206/pmvo4qv8xc1dnavd.png';

const ImgWechatQrcode =
  'https://piccdn.0daily.com/avatar/202201/06072436/yuubv960x7ru6nc4.png';

class IndexFooter extends Component {
  static propTypes = {
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.years = moment().get('years');
    this.state = {
      friendLinks: []
    };
  }

  render() {
    const lang = this.props.match.params.lang;
    const aboutsHref = lang ? `/${lang}/aboutus` : '/aboutus';
    return (
      <div className={styles['index-footer']}>
        <div className={styles.main}>
          <div className={styles.footer_top}>
            <div className={styles.wrapper}>
              <ul className={styles.about}>
                <li className={styles.footer_title}>
                  {locales(lang, 'aboutUs')}
                </li>
                <li className={styles.border} />
                <li className={styles['link-item']}>
                  <a href={`${aboutsHref}?us`} title="Odaily" rel="nofollow">
                    {locales(lang, 'aboutUs')}
                  </a>
                </li>
                <li className={styles['link-item']}>
                  <a
                    href={`${aboutsHref}?privacy`}
                    title="Odaily"
                    rel="nofollow"
                  >
                    {locales(lang, 'privacyPolicy')}
                  </a>
                </li>
                <li className={styles['link-item']}>
                  <a
                    href={`${aboutsHref}?contact`}
                    title="Odaily"
                    rel="nofollow"
                  >
                    {locales(lang, 'contactUs')}
                  </a>
                </li>
                <li className={styles['link-item']}>
                  <a
                    href={`${aboutsHref}?agreement`}
                    title="Odaily"
                    rel="nofollow"
                  >
                    {locales(lang, 'userAgreement')}
                  </a>
                </li>
                <li className={styles['link-item']}>
                  <a href={`${aboutsHref}?join`} title="Odaily" rel="nofollow">
                    {locales(lang, 'joinUs')}
                  </a>
                </li>
                <li className={styles['link-item']}>
                  <a
                    href={`${aboutsHref}?friend`}
                    title="Odaily"
                    rel="nofollow"
                  >
                    {locales(lang, 'links')}
                  </a>
                </li>
                <li className={styles['link-item']}>
                  <a
                    href={`${aboutsHref}?disclaimer`}
                    title="Odaily"
                    rel="nofollow"
                  >
                    {locales(lang, 'disclaimer')}
                  </a>
                </li>
              </ul>
              <ul className={styles.column}>
                <li className={styles.footer_title}>
                  {locales(lang, 'partners')}
                </li>
                <li className={styles.border} />
                <li className={styles['link-item']}>
                  <a
                    title="Odaily"
                    className={styles['kr']}
                    href="https://36kr.com"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  />
                </li>
                <li className={styles['link-item']}>
                  <a
                    className={styles['jingdata']}
                    title="Odaily"
                    href="https://www.jingdata.com"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  />
                </li>
                <li className={styles['link-item']}>
                  <a
                    className={styles['krspace']}
                    title="Odaily"
                    href="https://www.krspace.com"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  />
                </li>
              </ul>
              <ul className={styles.follow}>
                <li className={styles.footer_title}>
                  {locales(lang, 'followUs')}
                </li>
                <li className={styles.border} />
                <li className={styles['link-item']}>
                  <div className={styles.wechat_icon}>
                    <div className={styles['wechat-share-wrapper']}>
                      <div className={styles['wechat-share-content']}>
                        <img src={ImgWechatQrcode} loading="lazy" />
                        <p className={styles.text}>
                          {locales(lang, 'joinCommunity')}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className={styles['link-item']}>
                  <a
                    className={styles['twitter_icon']}
                    href="https://twitter.com/OdailyChina"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  />
                </li>
                <li className={styles['link-item']}>
                  <a
                    className={styles['discord_icon']}
                    href={process.env.DISCORD}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  />
                </li>
                <li className={styles['link-item']}>
                  <a
                    className={styles['telegram_icon']}
                    href="https://t.me/Odaily_CryptoPunk"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  />
                </li>
              </ul>
            </div>
            <section className={styles.qrcode}>
              <div className={styles['qrcode-item']}>
                <img
                  className={styles['qrcode-img']}
                  src={
                    'https://piccdn.0daily.com/202212/02073711/orcqk79cae3lmzw8.png'
                  }
                  alt=""
                  loading="lazy"
                />
                <div className={styles['qrcode-desc']}>
                  {locales(lang, 'appDownload')}
                </div>
              </div>
              <div className={styles['qrcode-item']}>
                <img
                  className={styles['qrcode-img']}
                  src={
                    'https://piccdn.0daily.com/202308/22032225/qtlnd2sb07r6h9ft.png'
                  }
                  alt=""
                  loading="lazy"
                />
                <div className={styles['qrcode-desc']}>
                  {locales(lang, 'publicAccount')}
                </div>
              </div>
            </section>
          </div>

          <div className={styles.footer_button}>
            <div className={styles.logo}>
              <img src={LOGO_FOOTER} alt="ODAILY" loading="lazy" />
            </div>
            <p className={styles.name}>海南省莫蒂文化传媒有限责任公司</p>
            <div className={styles.other}>
              <p>
                <a
                  href="https://beian.miit.gov.cn/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  琼ICP备 2022000863号
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(IndexFooter);
