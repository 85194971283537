import http, { passport, service } from '../scripts/http';
const querystring = require('querystring');
import $ from 'jquery';

export function getUserInfo(userId, lang) {
  return http.get(`/user/${userId}`, { lang });
}

export function getUserPosts(userId, page = 1, bId = '', perPage = 20, lang) {
  return service.get(
    `feed_stream/user/${userId}?b_id=${bId}&per_page=${perPage}&page=${page}`,
    { lang }
  );
}

export function getCurrentUser(lang) {
  return http.get('/user-current', { lang });
}

export function setUserAccountInfo(
  userId,
  { sex, birthday, introduction, job, industry }
) {
  const data = {
    sex,
    birthday,
    introduction,
    job,
    industry
  };
  return http.put(`/user/${userId}`, data);
}

export function setUserInfo({ nickName, avatar }) {
  const data = {
    nickName,
    avatar
  };
  return passport.put('/mobi-investor/user/basic', data);
}

export function upload() {
  let p = {
    bucket: 'odaily-krplus-pic',
    expiration: parseInt(
      (new Date().getTime() + 3600 * 1000 * 24 * 365) / 1000,
      10
    ),
    'save-key': '/{year}{mon}/{day}{hour}{min}{sec}/{random}{.suffix}',
    'x-gmkerl-thumb': '/rotate/auto'
  };

  const data = $.param({
    param: JSON.stringify(p),
    type: 'pic'
  });

  return passport.post('/mobi-investor/upload/form-api', data);
}

export function logout() {
  return passport.get('/logout');
}

//获取密码状态
export function getPwdStatus() {
  return passport.get('/mobi-investor/user/basic');
}

//发送验证码
export function sendMsg(params) {
  return passport.post('verify-code', params);
}

//验证验证码
export function checkCode(params) {
  let data = querystring.stringify(params);
  return passport.post('valid/verify-code', data);
}
//获取图形验证码
export function getCaptchaConfig(params) {
  return passport.get('captcha', params);
}
//更改密码
export function changePwd(params) {
  let data = querystring.stringify(params);
  return passport.post('reset_password', data);
}
//更新手机
export function updatePhone(params) {
  return passport.post('passport/v1/change_phone', params);
}

//绑定微信
export function getWecha(params) {
  return passport.get('/oauth/wechat', params);
}
//绑定微博
export function getWeibo(params) {
  return passport.get('/oauth/weibo', params);
}
//解除绑定
export function unbindWeix(params) {
  return passport.post('passport/v1/unbind-third', params);
}

export function verify_code(params) {
  return passport.post('passport/v1/verify_code', params);
}

// 登录
export function login(params) {
  return passport.post('login', params);
}
// 获取区号
export function getAreaData(lang) {
  return passport.get('phone-area', { lang });
}
// 设置密码
export function newPwd(params) {
  let data = querystring.stringify(params);

  return passport.post('set-newpwd', data);
}
// 绑定手机
export function bindPhone(params) {
  let data = querystring.stringify(params);

  return passport.post('bind', data);
}
