import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { connect } from 'react-redux';
import { timesAgo } from '../../../common/scripts/time';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';

export class NewPost extends React.Component {
  static propTypes = {
    postList: PropTypes.array.isRequired,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  renderPostList() {
    let { postList } = this.props;
    return postList
      .filter(item => {
        return item.entity_type === 'post';
      })
      .map((item, index) => {
        if (index < 5) {
          return (
            <div className={styles.post} key={index}>
              <a href={`/post/${item.entity_id}`}>
                <div className={styles.image}>
                  <img
                    src={item.cover || item.web_cover}
                    alt={item.title}
                    loading="lazy"
                  />
                </div>
                <div className={styles.content}>
                  <div
                    className={styles.post_title}
                    style={{ WebkitBoxOrient: 'vertical' }}
                  >
                    {item.title}
                  </div>
                  <div className={styles.time}>
                    {timesAgo(item.published_at)}
                  </div>
                </div>
              </a>
            </div>
          );
        }
      });
  }

  render() {
    const { lang } = this.props.match.params;
    return (
      <div className={styles.newPost}>
        <div className={styles.title}>
          {locales(lang, 'latest', { isTitle: true })}
        </div>
        <div className={styles.postList}>{this.renderPostList()}</div>
      </div>
    );
  }
}

export default withRouter(connect()(NewPost));
