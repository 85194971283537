import React from 'react';

import styles from './index.scss';
import PropTypes from 'prop-types';
import echarts from 'echarts';

export default class KlineChart extends React.Component {
  static propTypes = {
    symbol: PropTypes.string.isRequired,
    klineDataList: PropTypes.array.isRequired,
    isUp: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  static fetchData() {}

  componentDidMount() {
    let myChart = echarts.init(
      document.getElementById(`chart_symbol_${this.props.symbol}`)
    );
    const klineDataList = this.props.klineDataList;
    const min = Math.min(...klineDataList);
    const max = Math.max(...klineDataList);
    const isUp = klineDataList[klineDataList.length - 1] - klineDataList[0] > 0;
    const option = {
      grid: {
        height: '70%',
        left: '1%',
        top: '3%',
        bottom: '3%',
        containLabel: false
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0 // 强制显示所有标签
        },
        show: false // 不显示 x 轴
      },
      yAxis: {
        type: 'value',
        min: min,
        max: max,
        show: false // 不显示 y 轴
      },
      series: [
        {
          lineStyle: {
            color: isUp ? 'green' : 'red'
          },
          data: klineDataList,
          type: 'line',
          symbol: 'none'
        }
      ]
    };
    myChart.clear();
    myChart.setOption(option);
  }

  render() {
    return (
      <div
        id={`chart_symbol_${this.props.symbol}`}
        className={styles.klineChart}
      ></div>
    );
  }
}
