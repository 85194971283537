import React from 'react';

import styles from './index.scss';
import classNames from 'classnames';
import locales from '../../utils/locales';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import arrowUpImg from '../../assets/image/market/arrow-up.svg';
import arrowDownImg from '../../assets/image/market/arrow-down.svg';
import { getCoinListBySymbols } from '../../common/api/newMarket';

class HomeMarketBarComponent extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      coinMap: {}
    };
  }

  updateTimer = null;

  componentDidMount() {
    this.getCoinMap();
    this.updateTimer = setInterval(() => {
      this.getCoinMap();
    }, 10 * 1000);
  }

  componentWillUnmount() {
    this.updateTimer && clearInterval(this.updateTimer);
  }

  getCoinMap() {
    getCoinListBySymbols().then(res => {
      const coinMap = {};
      let coinList = res.data;
      for (let i = 0; i < coinList.length; i++) {
        let _item = coinList[i];
        coinMap[_item.symbol] = _item;
      }
      this.setState({
        coinMap: coinMap
      });
    });
  }

  showRate(rate) {
    const number = (rate * 100).toFixed(4);
    return (Math.round(number * 100) / 100).toLocaleString();
  }

  roundNumber(number, decimals) {
    if (!isNaN(Number(number))) {
      number = Number(number).toFixed(decimals + 2);
    }
    const factor = 10 ** decimals;
    return Math.round(number * factor) / factor;
  }

  showPriceTag(price) {
    if (isNaN(Number(price))) {
      return <span>{price}</span>;
    }
    const number = Number(price).toFixed(20);
    const strings = String(number).split('.');
    if (strings.length !== 2) {
      return <span>{this.roundNumber(number, 8)}</span>;
    }
    const string = strings[0];
    const string1 = strings[1];
    let zeroSize = 0;
    for (let i = 0; i < string1.length; i++) {
      const element = string1[i];
      if (element !== '0') {
        break;
      }
      zeroSize++;
    }
    if (zeroSize < 5) {
      return <span>{this.roundNumber(number, 8)}</span>;
    }
    let s = string1.substring(zeroSize, zeroSize + 5);
    let lastZeroSize = 0;
    for (let i = s.length - 1; i >= 0; i--) {
      const sElement = s[i];
      if (sElement !== '0') {
        continue;
      }
      lastZeroSize++;
    }
    if (lastZeroSize > 0) {
      s = s.substring(0, s.length - lastZeroSize);
    }
    return (
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end'
        }}
      >
        {string}.0
        <sub
          style={{
            transform: 'scale(0.8)',
            fontSize: '100%',
            display: 'inline-flex',
            lineHeight: '100%',
            marginBottom: '-4px'
          }}
        >
          {zeroSize}
        </sub>
        {s}
      </span>
    );
  }

  static fetchData() {}

  renderItem(label, symbol) {
    const coinMap = this.state.coinMap;
    const coinData = coinMap[symbol];
    const price = coinData && coinData.price ? coinData.price : '0';
    const type = coinData && coinData.is_up ? 'up' : 'down';
    return (
      <div className={styles.item} key={symbol}>
        <span className={styles.label}>{label}</span>
        {type === 'up' ? (
          <span className={classNames(styles.price, styles.up)}>
            {this.showPriceTag(price)}
          </span>
        ) : (
          <span className={classNames(styles.price, styles.down)}>
            {this.showPriceTag(price)}
          </span>
        )}
        {type === 'up' ? (
          <img src={arrowUpImg} alt={''} className={styles.icon} />
        ) : (
          <img src={arrowDownImg} alt={''} className={styles.icon} />
        )}
      </div>
    );
  }

  render() {
    const { lang } = this.props.match.params;
    return (
      <div className={styles.marketBar}>
        <a
          className={styles.content}
          href={lang ? `/${lang}/market` : `/market`}
          style={{ width: '100%', height: '100%' }}
        >
          <div className={styles.itemList}>
            {Object.keys(this.state.coinMap).length === 0
              ? null
              : [
                  this.renderItem('BTC', 'btcusdt'),
                  this.renderItem('ETH', 'ethusdt'),
                  this.renderItem('HTX', 'htxusdt'),
                  this.renderItem('SOL', 'solusdt'),
                  this.renderItem('BNB', 'bnbusdt')
                ]}
          </div>
          <div className={styles.detailBtn}>
            {locales(lang, 'daily_market')}
          </div>
        </a>
      </div>
    );
  }
}

export const HomeMarketBar = withRouter(HomeMarketBarComponent);
