import http from '../scripts/http';
import { service, commentsList } from '../scripts/http';
let qs = require('qs');
import MD5 from 'crypto-js/md5';

export function getActivity(lang) {
  return service.get('/scheme/group_list', { lang });
}

export function getSlide(lang) {
  return service.get('/scheme/slide_show?type=activity', { lang });
}

export function getActivityList(id, page, per_page = 16, lang) {
  return service.get(`/scheme/group/${id}?page=${page}&per_page=${per_page}`, {
    lang
  });
}

export function getActivityDetail(id, lang) {
  return http.get(`/scheme/show/${id}`, { lang });
}

export function getLive(lsid, lang) {
  return service.post(`/live/livestream_info`, { lsid, lang });
}

export function getLikeNum(param) {
  return service.post(`live/like_num`, param);
}

// export function getCommentsList(param) {
//   return service.post(`/live/comments_list`, param);
// }

export function getCommentsList(param) {
  let data = {
    ...param,
    accessKey: process.env.ACCESS_KEY
  };
  data.timestamp = parseInt(new Date().getTime() / 1000);
  let str =
    JSON.stringify(data)
      .replace(/:/g, '=')
      .replace(/[{"}]/g, '')
      .split(',')
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .join('&') + `&secretKey=${process.env.SECRET_KEY}`;
  str = MD5(str)
    .toString()
    .toUpperCase();
  data.sign = str;

  data = qs.stringify(data);

  return commentsList.post(`/api/comments-list`, data);
}

export function sendComments(param) {
  return service.post(`/live/save_comment`, param);
}

export function getActivityInfo(param) {
  return service.post(`live/activity_info`, param);
}

export function getLike(param) {
  return service.post(`live/like`, param);
}

export function getTotalNum(lsid) {
  return service.get(`/live/pv?lsid=${lsid}`);
}

export function getHomeActivity() {
  return service.get(`/scheme/home_page`);
}

export function getTime(lang) {
  return service.get(`/now_time`, { lang });
}
