import React, { Component } from 'react';
import styles from '../../index.scss';
import PropTypes from 'prop-types';

export class PrivacyKo extends Component {
  static propTypes = {
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.content}>
        <h5>{this.props.title}</h5>
        <h6>Mục lục</h6>

        <p>1. giới thiệu</p>
        <p>2. về chúng tôi</p>
        <p>3. Cách chúng tôi thu thập và sử dụng thông tin cá nhân của bạn</p>
        <p>4. Cách chúng tôi sử dụng cookie và các công nghệ tương tự</p>
        <p>
          5. Cách chúng tôi ủy thác xử lý, cung cấp bên ngoài, chuyển giao và
          tiết lộ công khai thông tin cá nhân của bạn
        </p>
        <p>6. Cách chúng tôi lưu trữ và bảo vệ thông tin cá nhân của bạn</p>
        <p>7. Cách bạn quản lý thông tin cá nhân của mình</p>
        <p>8. Bảo vệ thông tin cá nhân của trẻ vị thành niên</p>
        <p>9. Sửa đổi và Thông báo về Chính sách Quyền riêng tư</p>
        <p>
          10. Việc bảo vệ thông tin cá nhân của người dùng đã qua đời của chúng
          tôi
        </p>
        <p>11. làm thế nào để bạn liên hệ với chúng tôi</p>
        <p>12. Giải quyết tranh chấp</p>

        <h6>1. giới thiệu</h6>
        <p>
          &quot;Chính sách quyền riêng tư&quot; này (sau đây gọi là &quot;Chính
          sách quyền riêng tư này&quot;) áp dụng cho phần mềm và các dịch vụ
          liên quan (sau đây gọi là &quot;dịch vụ của ODAILY&quot;) của ODAILY
          (sau đây gọi là &quot;nền tảng&quot; hoặc &quot;chúng tôi&quot;). Các
          tiêu đề của mỗi điều khoản chỉ được sử dụng để giúp bạn hiểu ý chính
          của điều khoản và không ảnh hưởng hoặc giới hạn ý nghĩa hoặc cách giải
          thích các điều khoản của chính sách bảo mật này. Để bảo vệ quyền và
          lợi ích của mình, đề nghị bạn đọc kỹ nội dung diễn đạt cụ thể của từng
          điều khoản.
        </p>
        <p>
          Vui lòng đặc biệt chú ý: chính sách quyền riêng tư này áp dụng cho tất
          cả các dịch vụ ODAILY mà chúng tôi cung cấp cho bạn dưới dạng trang
          web, ứng dụng khách, applet và các dạng mới xuất hiện cùng với sự phát
          triển của công nghệ. Nếu dịch vụ ODAILY được sử dụng trong các sản
          phẩm hoặc dịch vụ của chúng tôi và các công ty liên kết, nhưng không
          có chính sách quyền riêng tư độc lập, thì chính sách quyền riêng tư
          này cũng áp dụng cho phần này của dịch vụ. Nếu chúng tôi và các công
          ty liên kết của chúng tôi có các chính sách quyền riêng tư riêng cho
          các dịch vụ ODAILY mà họ cung cấp cho bạn, thì các chính sách quyền
          riêng tư tương ứng sẽ áp dụng cho các sản phẩm hoặc dịch vụ tương ứng.
        </p>
        <p>
          Bạn nên đọc kỹ Chính sách quyền riêng tư này trước khi nhấp vào để
          đồng ý với Chính sách quyền riêng tư này trong quá trình đăng ký ứng
          dụng hoặc khi Chính sách quyền riêng tư này được cập nhật. Vui lòng
          đảm bảo đọc kỹ và hiểu đầy đủ nội dung của từng điều khoản,
          <b>
            Chúng tôi đã nhấn mạnh nội dung quan trọng của các điều khoản và
            điều kiện liên quan đến quyền thông tin cá nhân của bạn, vui lòng
            đặc biệt chú ý.
          </b>
        </p>
        <p>
          Khi bạn điền thông tin theo hướng dẫn trên trang đăng ký, đọc và đồng
          ý với Chính sách bảo mật này và hoàn tất mọi thủ tục đăng ký hoặc nhấp
          vào Đồng ý khi Chính sách bảo mật này được cập nhật, điều đó có nghĩa
          là bạn đã đọc, hiểu và chấp nhận toàn bộ. nội dung của Chính sách bảo
          mật này.
          <b>
            Trong quá trình đọc chính sách quyền riêng tư này, nếu bạn từ chối
            đồng ý với chính sách quyền riêng tư này hoặc bất kỳ điều khoản nào
            trong đó, điều đó có thể dẫn đến việc bạn không thể sử dụng các dịch
            vụ ODAILY có liên quan và một số chức năng một cách bình thường hoặc
            không đạt được hiệu quả dịch vụ mà chúng tôi cung cấp. hy vọng.
          </b>
        </p>
        <h6>2. về chúng tôi</h6>
        <p>
          1. Nhà điều hành nền tảng này và nhà cung cấp dịch vụ ODAILY là Hainan
          Moti Culture Media Co., Ltd.
        </p>
        <p>
          2. Các công ty liên kết là các công ty kiểm soát Hainan Moti Culture
          Media Co., Ltd., được kiểm soát bởi Hainan Province Moti Culture Media
          Co., Ltd., hoặc chịu sự kiểm soát chung với Hainan Province Moti
          Culture Media Co., Ltd. hiện tại và tương lai.cơ chế. Kiểm soát đề cập
          đến khả năng ảnh hưởng trực tiếp hoặc gián tiếp đến việc quản lý/hoạt
          động của đối tượng được kiểm soát thông qua quyền sở hữu, cổ phần biểu
          quyết, hợp đồng, hiệp hội hoạt động thực tế hoặc các phương tiện được
          pháp luật công nhận khác.
        </p>
        <h6>3. Cách chúng tôi thu thập và sử dụng thông tin cá nhân của bạn</h6>
        <p>
          1. Chúng tôi thu thập và sử dụng thông tin cá nhân do bạn tự nguyện
          cung cấp trong quá trình sử dụng dịch vụ hoặc phát sinh do nhu cầu sử
          dụng dịch vụ của ODAILY theo quy định của pháp luật và trên nguyên tắc
          chính đáng, hợp pháp và cần thiết. Nếu chúng tôi có ý định sử dụng
          thông tin cá nhân của bạn cho các mục đích khác không được chỉ định
          trong Chính sách quyền riêng tư này hoặc sử dụng thông tin được thu
          thập cho các mục đích khác dựa trên các mục đích cụ thể, chúng tôi sẽ
          thông báo cho bạn một cách kịp thời và hợp lý và nhận được sự đồng ý
          của bạn một lần nữa.
        </p>
        <p>
          2. Có hai loại thông tin cá nhân của bạn mà chúng tôi thu thập và sử
          dụng: loại thứ nhất: thông tin cần thiết cho các chức năng kinh doanh
          cốt lõi của các dịch vụ ODAILY: loại thông tin này cần thiết cho hoạt
          động bình thường của nền tảng này và bạn phải cho phép chúng tôi để
          thu thập nó. Nếu bạn từ chối cung cấp, điều đó có thể khiến bạn không
          thể sử dụng bình thường các dịch vụ ODAILY và một số chức năng; loại
          thứ hai: thông tin có thể được thu thập cho các chức năng kinh doanh
          bổ sung: thông tin này được yêu cầu cho các chức năng kinh doanh không
          cốt lõi và bạn có thể chọn có cho phép Chúng tôi thu thập hay không.
          Nếu bạn từ chối cung cấp nó, điều đó sẽ dẫn đến việc không thể nhận ra
          các chức năng kinh doanh bổ sung hoặc hiệu quả mà chúng tôi dự định
          đạt được, nhưng nó sẽ không ảnh hưởng đến việc bạn sử dụng bình thường
          các chức năng kinh doanh cốt lõi.
        </p>
        <p>
          3.
          <b>
            Chúng tôi cần nhắc bạn rằng: Vì ODAILY có nhiều dịch vụ và cung cấp
            cho bạn những nội dung khác nhau nên các chức năng kinh doanh cốt
            lõi (bao gồm loại thông tin cá nhân của bạn mà nó thu thập) cũng sẽ
            khác nhau tùy thuộc vào nội dung của dịch vụ ODAILY. việc cung cấp
            các dịch vụ ODAILY trên thực tế. Ngoài ra, bạn hiểu và đồng ý rằng
            chúng tôi hy vọng cung cấp cho bạn các dịch vụ ODAILY hoàn hảo, vì
            vậy chúng tôi sẽ tiếp tục cải thiện các dịch vụ của ODAILY, bao gồm
            cả công nghệ, điều đó có nghĩa là chúng tôi có thể thường xuyên khởi
            chạy các chức năng kinh doanh mới và có thể cần thu thập thông tin
            cá nhân mới hoặc thay đổi mục đích hoặc phương pháp sử dụng thông
            tin cá nhân. Nếu chức năng hoặc dịch vụ cần thu thập thông tin cá
            nhân của bạn không được mô tả trong Chính sách quyền riêng tư này,
            chúng tôi sẽ giải thích riêng cho bạn mục đích của việc thu thập
            thông tin này bằng cách cập nhật Chính sách quyền riêng tư này, lời
            nhắc trang, cửa sổ bật lên, thông báo trang web, v.v. . Mục đích,
            nội dung, phương pháp sử dụng và phạm vi, đồng thời cung cấp cho bạn
            cách chọn sự đồng ý của bạn và thu thập sau khi nhận được sự đồng ý
            rõ ràng của bạn.
          </b>
          Trong quá trình này, nếu bạn có bất kỳ nghi ngờ nào về các vấn đề liên
          quan, bạn có thể sử dụng Chính sách bảo mật thứ mười một này. Một
          trong những cách được cung cấp để liên hệ với chúng tôi, chúng tôi sẽ
          trả lời bạn ngay khi có thể.
        </p>
        <p>
          4. Thông tin cá nhân nhạy cảm trong chính sách bảo mật này đề cập đến
          thông tin cá nhân, một khi bị rò rỉ hoặc sử dụng trái phép, có thể dễ
          dàng khiến nhân phẩm của một thể nhân bị xâm phạm hoặc sự an toàn của
          cá nhân và tài sản bị nguy hiểm, bao gồm
          <b>
            Sinh trắc học, niềm tin tôn giáo, danh tính cụ thể, chăm sóc sức
            khỏe, tài khoản tài chính, nơi ở
          </b>
          và các thông tin khác, và
          <b>Thông tin cá nhân của trẻ vị thành niên dưới mười bốn tuổi</b>,Ví
          dụ,
          <b>
            Thông tin cá nhân nhạy cảm bao gồm số chứng minh nhân dân, thông tin
            sinh trắc học cá nhân, số tài khoản ngân hàng, nội dung liên lạc,
            thông tin sức khỏe và tâm sinh lý
          </b>
          Chờ đợi. Thông tin cá nhân nhạy cảm mà chúng tôi thu thập đã được hiển
          thị nổi bật trong chính sách bảo mật này. Khi thông tin cá nhân nhạy
          cảm của bạn bị rò rỉ, giả mạo hoặc sử dụng trái phép, nó sẽ gây ra
          thiệt hại cho sự an toàn cá nhân hoặc tài sản của bạn. Để ngăn thông
          tin cá nhân nhạy cảm của bạn bị tiết lộ hoặc sử dụng trái phép, chúng
          tôi đã thực hiện các biện pháp bảo vệ kỹ thuật và tổ chức phù hợp để
          đảm bảo an toàn cho thông tin của bạn.
        </p>
        <p>
          5. Thông thường, chúng tôi sẽ thu thập và sử dụng thông tin cá nhân
          của bạn trong các trường hợp sau:
        </p>
        <p>5.1 Dịch vụ ODAILY ở trạng thái đăng ký và đăng nhập</p>
        <p>
          Để chúng tôi cung cấp cho bạn các dịch vụ liên tục và ổn định cũng như
          đảm bảo tính bảo mật cho việc bạn sử dụng các dịch vụ ODAILY, chúng
          tôi cần bạn chủ động cung cấp thông tin đăng ký hoặc đăng nhập cơ bản,
          bao gồm số điện thoại di động, địa chỉ email và tạo tài khoản, tên
          người dùng của bạn và mật khẩu, trong
          <b>Số điện thoại di động và mật khẩu là thông tin nhạy cảm cá nhân</b>
          , thông tin nói trên được thu thập để đáp ứng các yêu cầu của hệ thống
          tên thật trên Internet trong các luật và quy định có liên quan và để
          đáp ứng các yêu cầu của Chính sách quyền riêng tư này. Trong một số
          dịch vụ riêng lẻ, nếu bạn chỉ cần sử dụng các chức năng cơ bản là
          duyệt và tìm kiếm, bạn không cần phải đăng ký làm người dùng ODAILY và
          cung cấp các thông tin trên.
        </p>
        <p>
          Bạn cũng có thể sử dụng tài khoản nền tảng của bên thứ ba để đăng ký
          làm người dùng ODAILY và bạn có thể chọn ủy quyền cho chúng tôi đọc và
          nhận
          <b>
            Thông tin công khai mà bạn xuất bản và ghi lại trên nền tảng của bên
            thứ ba (bao gồm biệt hiệu và hình đại diện)
          </b>
          . Chúng tôi cần sự cho phép của bạn để lấy thông tin trên từ bên thứ
          ba để ghi nhớ danh tính đăng nhập của bạn với tư cách là người dùng
          ODAILY. Việc thu thập thông tin trên dựa trên sự đồng ý của bạn. Sau
          khi bạn đăng ký hoặc đăng nhập bằng tài khoản bên thứ ba, thứ ba của
          bạn -tài khoản của bên sẽ được liên kết với tài khoản ODAILY để ràng
          buộc.
        </p>
        <p>
          Dựa trên sự hợp tác của chúng tôi với các nhà khai thác truyền thông,
          khi bạn sử dụng chức năng &quot;đăng nhập bằng một cú nhấp chuột&quot;
          của ODAILY, với sự đồng ý rõ ràng của bạn, nhà điều hành sẽ gửi cho
          chúng tôi số điện thoại di động của bạn để chúng tôi có thể cung cấp
          dịch vụ đăng nhập nhanh cho bạn. Bạn cũng có thể chọn tự điền số điện
          thoại di động để hoàn tất đăng ký hoặc đăng nhập.
        </p>
        <p>
          Trong quá trình đăng ký, đăng nhập hoặc sử dụng sau này, bạn cũng có
          thể chủ động điền hoặc bổ sung thêm các thông tin khác của mình (tên
          hiệu, ảnh đại diện, giới tính, ngày sinh, danh tính, ngành nghề). Nó
          có thể được hiển thị trên trang chủ cá nhân và khu vực bình luận của
          bạn để xác định danh tính người dùng ODAILY của bạn và tạo điều kiện
          cho bạn giao tiếp và tương tác với những người dùng ODAILY khác. Tuy
          nhiên, nếu bạn không cung cấp thông tin này, nó sẽ không ảnh hưởng đến
          các chức năng cơ bản của bạn khi sử dụng dịch vụ ODAILY.
        </p>
        <p>5.2 Dịch vụ ODAILY ở trạng thái không đăng ký và đăng nhập</p>
        <p>
          Nếu bạn sử dụng dịch vụ ODAILY mà không đăng ký hoặc đăng nhập, chúng
          tôi chỉ có thể cung cấp cho bạn một số chức năng ở chế độ không đăng
          nhập. Để đảm bảo thực hiện các chức năng, chúng tôi có thể chủ động áp
          dụng để lấy thông tin thiết bị và thông tin nhận dạng mạng của bạn để
          thực hiện các chức năng duyệt, tìm kiếm, thích, thu thập và chia sẻ.
          Thông tin nêu trên được thu thập để đáp ứng các yêu cầu của luật pháp
          và quy định liên quan về việc lưu giữ thông tin nhật ký mạng;
        </p>
        <p>
          Ngoài các thông tin trên, trong quá trình bạn sử dụng, chúng tôi có
          thể chủ động đăng ký để có được thông tin của bạn.
          <b>quyền thiết bị</b>Để đạt được các chức năng bổ sung khác (xem Phần
          5.9 của Chính sách quyền riêng tư này, &quot;Cung cấp bảo mật cho
          bạn&quot;).
        </p>
        <p>5.3 Duyệt thông tin</p>
        <p>
          1) ODAILY có thể đề xuất thêm thông tin thú vị cho bạn, để thực hiện
          chức năng này,
          <b>Chúng tôi có thể tự động thu thập thông tin nhật ký cần thiết</b>
          ,bao gồm:
        </p>

        <ul className={styles.privacy_ul}>
          <li>
            a) Thông tin về hành vi thao tác, sử dụng của bạn: nhấp chuột, theo
            dõi, yêu thích, tìm kiếm, duyệt, chia sẻ;
          </li>
          <li>b) Thông tin thiết bị: mã định danh duy nhất của thiết bị;</li>
          <li>
            c) Thông tin mạng: thông tin vị trí địa lý chính xác thu được từ
            thông tin cảm biến, chẳng hạn như thông tin GPS, điểm truy cập mạng
            WLAN, Bluetooth và trạm cơ sở cũng như thông tin vị trí địa lý gần
            đúng thu được thông qua thông tin vị trí mạng (trạm cơ sở, IP và
            mạng WLAN);
          </li>
        </ul>

        <p>
          2）<b>Vị trí GPS là thông tin nhạy cảm cá nhân</b>, nếu bạn từ chối
          cung cấp thông tin đó, chúng tôi sẽ không đề xuất thông tin cho bạn
          dựa trên thông tin GPS và điều đó sẽ không ảnh hưởng đến việc sử dụng
          bình thường các chức năng khác của nền tảng này.
        </p>

        <p>
          3) Chúng tôi sẽ hiển thị cho bạn và đề xuất thông tin liên quan đến
          địa phương trong khu vực của bạn dựa trên thành phố tương ứng với
          thông tin vị trí địa lý gần đúng.
        </p>

        <p>
          4) Thông tin nói trên được thu thập để đáp ứng các yêu cầu của luật và
          quy định liên quan về việc lưu giữ thông tin nhật ký web và để đáp ứng
          các yêu cầu của Chính sách quyền riêng tư này.
          <b>
            Thông tin được chúng tôi thu thập và sử dụng ở trên đã được hủy nhận
            dạng và phân tích dữ liệu chỉ tương ứng với một mã cụ thể không thể
            liên kết trực tiếp với danh tính của bạn và sẽ không được liên kết
            với danh tính thực của bạn
          </b>
          。
        </p>

        <p>5.4 Phát hành và Tương tác</p>
        <p>1) Công bố thông tin</p>
        <ul className={styles.privacy_ul}>
          <li>
            a) Khi bạn đăng nội dung hoặc bình luận, chúng tôi sẽ tự động thu
            thập thông tin bạn đăng và hiển thị biệt danh, ảnh đại diện và nội
            dung bài đăng của bạn.
          </li>
          <li>
            b) Khi bạn sử dụng các chức năng tải ảnh lên và đăng âm thanh và
            video, chúng tôi sẽ yêu cầu bạn chủ động cấp quyền cho camera (máy
            ảnh), album (dung lượng lưu trữ) và quyền của micrô. Nếu bạn từ chối
            cung cấp ủy quyền, bạn sẽ không thể sử dụng chức năng này, nhưng nó
            sẽ không ảnh hưởng đến việc bạn sử dụng bình thường các chức năng
            khác của nền tảng này.
          </li>
          <li>
            c) Xin lưu ý rằng thông tin bạn tiết lộ công khai có thể liên quan
            đến thông tin cá nhân của người dùng hoặc những người khác. Nếu
            thông tin bạn tiết lộ công khai chứa thông tin cá nhân của người
            khác, bạn cần đảm bảo rằng thông tin đó được luật pháp và quy định
            cho phép sử dụng hoặc đã được lấy trước khi tải lên và xuất bản.được
            ủy quyền hợp pháp.
          </li>
          <li>
            d) Khi bạn sử dụng chức năng phát sóng trực tiếp, bạn cần thực hiện
            xác thực tên thật theo luật pháp và quy định, yêu cầu bảo mật và
            tuân thủ chính sách quyền riêng tư này. Trong quá trình xác thực tên
            thật, cơ quan xác thực bên thứ ba của dịch vụ xác thực cần bạn cung
            cấp
            <b>
              Thông tin tên thật và số CMND. Thông tin này là thông tin nhạy cảm
              cá nhân
            </b>
            , bạn có thể từ chối cung cấp và nó sẽ không ảnh hưởng đến việc sử
            dụng bình thường các chức năng khác của nền tảng này. Thông tin này
            chỉ được sử dụng để xác thực tên thật và các mục đích theo quy định
            của pháp luật và sẽ không được sử dụng cho các mục đích khác mà
            không có sự cho phép rõ ràng của bạn.
          </li>
          <li>
            e) Thông tin nêu trên được thu thập để đáp ứng các yêu cầu về nghĩa
            vụ của nhà cung cấp dịch vụ trong việc duy trì tài khoản tiết lộ
            thông tin, tài khoản công khai, người dùng phát sóng trực tiếp, v.v.
            theo luật pháp và quy định có liên quan và để đáp ứng các yêu cầu
            của chính sách bảo mật này.
          </li>
        </ul>

        <p>2) Giao tiếp tương tác</p>
        <ul className={styles.privacy_ul}>
          <li>
            a) Khi bạn theo dõi nội dung, chúng tôi sẽ thu thập nội dung hoặc
            tài khoản bạn theo dõi và hiển thị cho bạn nội dung hoặc nội dung
            được đăng bởi tài khoản.
          </li>
          <li>
            b) Thông tin nói trên được thu thập để đáp ứng các yêu cầu của luật
            pháp và quy định liên quan về việc lưu giữ thông tin nhật ký trang
            web và để đáp ứng các yêu cầu của chính sách quyền riêng tư này.
          </li>
        </ul>

        <p>5.5 Tìm kiếm</p>
        <p>
          1) Khi bạn sử dụng dịch vụ tìm kiếm, chúng tôi sẽ tự động thu thập
          thông tin từ khóa tìm kiếm của bạn và các bản ghi thông tin nhật ký để
          phân tích nhằm cung cấp cho bạn các kết quả tìm kiếm phù hợp hơn.
        </p>
        <p>
          2) Chúng tôi cũng có thể tự động ghi lại thông tin bạn cung cấp, hình
          thành hoặc lưu giữ khi sử dụng dịch vụ của chúng tôi, chẳng hạn như
          khi bạn nhập các từ truy vấn khi sử dụng tìm kiếm, nhấp vào các từ
          lịch sử, từ gợi ý và liên kết được nhấp và khi bạn tham gia vào các
          hoạt động tìm kiếm được gửi thông tin. Bạn có quyền từ chối cung cấp
          thông tin trên, nhưng điều này sẽ dẫn đến việc bạn không thể sử dụng
          các dịch vụ hoặc chức năng cụ thể hoặc không thể đạt được hiệu quả dự
          kiến ​​của các dịch vụ có liên quan.
        </p>
        <p>
          3) Thông tin nêu trên được thu thập để đáp ứng các yêu cầu của luật và
          quy định có liên quan về việc lưu giữ thông tin nhật ký mạng và để đáp
          ứng các yêu cầu của chính sách quyền riêng tư này. Để cung cấp dịch vụ
          tìm kiếm hiệu quả, một số thông tin nêu trên thông tin cũng sẽ được
          lưu trữ tạm thời trong thiết bị lưu trữ cục bộ của bạn và Nó có thể
          hiển thị cho bạn nội dung của kết quả tìm kiếm và lịch sử tìm kiếm.
        </p>

        <p>
          5.6 <b>thông báo</b>
        </p>
        <p>
          <b>
            Bạn hiểu và đồng ý rằng đối với thông tin liên hệ (số liên lạc) mà
            bạn chủ động cung cấp trong quá trình sử dụng sản phẩm và/hoặc dịch
            vụ, chúng tôi có thể gửi nhiều loại thông báo đến một hoặc nhiều
            trong số chúng trong quá trình hoạt động nhằm mục đích nhắn tin cho
            Người dùng. thông báo, xác minh danh tính, xác minh bảo mật, nghiên
            cứu trải nghiệm người dùng và giải quyết tranh chấp; ngoài ra, chúng
            tôi cũng có thể cung cấp cho bạn các dịch vụ mà bạn có thể quan tâm
            dưới dạng tin nhắn văn bản và cuộc gọi điện thoại đến các số điện
            thoại di động được thu thập trong thông tin thương mại về quy trình,
            Chức năng hoặc sự kiện đã nói ở trên, nhưng hãy yên tâm rằng nếu bạn
            không muốn chấp nhận thông tin đó, bạn có thể hủy đăng ký thông qua
            phương thức hủy đăng ký được cung cấp trong tin nhắn văn bản trên
            điện thoại di động hoặc liên hệ trực tiếp với chúng tôi để hủy đăng
            ký.
          </b>
        </p>

        <p>5.7 Dịch vụ khách hàng</p>
        <p>
          Khi bạn gửi phản hồi cho chúng tôi, để tạo điều kiện liên hệ với bạn
          hoặc giúp bạn giải quyết vấn đề, chúng tôi có thể cần bạn chủ động
          cung cấp tên, số điện thoại di động, địa chỉ email và thông tin liên
          hệ khác của bạn và có thể sử dụng thông tin này để liên lạc với bạn .
          Nếu bạn gửi khiếu nại vi phạm bản quyền, chúng tôi cũng sẽ yêu cầu bạn
          gửi<b>CMND, giấy ủy quyền, giấy chứng nhận quyền sở hữu</b>, để làm rõ
          tư cách của bạn với tư cách là đối tượng của khiếu nại.
        </p>

        <p>5.8 Dịch vụ giao dịch</p>
        <p>
          Chúng tôi sẽ tự động thu thập hồ sơ giao dịch và thông tin nhật ký của
          bạn khi bạn mua tài liệu đọc trực tuyến, khóa học trực tuyến, sản phẩm
          trung tâm tích điểm hoặc sản phẩm hoặc dịch vụ của chúng tôi thông qua
          các phương tiện khác. Thông tin nói trên được thu thập để đáp ứng các
          yêu cầu của luật và quy định có liên quan về việc lưu giữ thông tin
          nhật ký mạng và thông tin giao dịch cũng như để đáp ứng các yêu cầu
          của chính sách quyền riêng tư này, trong đó
          <b>Thông tin hồ sơ giao dịch là thông tin nhạy cảm cá nhân</b>, việc
          từ chối cung cấp thông tin này có thể khiến chúng tôi không thể cung
          cấp dịch vụ giao dịch, nhưng điều đó sẽ không ảnh hưởng đến việc bạn
          sử dụng bình thường các chức năng khác của dịch vụ ODAILY.
        </p>

        <p>5.9 Để cung cấp cho bạn sự bảo mật</p>
        <p>
          Để đáp ứng các yêu cầu của &quot;Luật An ninh mạng của Cộng hòa Nhân
          dân Trung Hoa&quot; (sau đây gọi là &quot;Luật An ninh mạng&quot;) và
          các luật và quy định khác, nhu cầu bảo vệ an ninh mạng và đáp ứng các
          yêu cầu của luật này Chính sách bảo mật, chúng tôi sẽ cung cấp cho bạn
          các dịch vụ và bảo đảm an toàn và đáng tin cậy Để đảm bảo tính ổn định
          và bảo mật của hoạt động hệ thống, ngăn chặn tài khoản của bạn bị đánh
          cắp và thông tin cá nhân bị lấy cắp bất hợp pháp và bảo vệ an toàn cho
          tài khoản của bạn, chúng tôi cần chủ động đăng ký thu thập 1) thông
          tin thiết bị của bạn (bao gồm kiểu thiết bị, địa chỉ MAC của thiết bị,
          hệ điều hành), phiên bản máy khách, độ phân giải, cài đặt thiết bị);
          mã nhận dạng duy nhất của thiết bị (IMEI/ID
          android/IDFA/OPENUDID/GUID, thông tin IMSI của thẻ SIM) ; 2) thông tin
          nhận dạng mạng (bao gồm<b>Thông tin định vị GPS</b>, địa chỉ IP, nhà
          điều hành viễn thông, môi trường mạng, số phiên bản phần mềm, loại
          trình duyệt,<b>Lịch sử duyệt trang web</b>, nhật ký vận hành). Để đánh
          giá rủi ro đối với tài khoản của bạn và để ngăn chặn, phát hiện và
          điều tra các hành vi gây nguy hiểm cho tính bảo mật của hệ thống, là
          bất hợp pháp hoặc vi phạm các quy tắc và thỏa thuận của chúng tôi.
          Chúng tôi cũng sẽ thu thập thông tin thiết bị và thông tin phần mềm
          nói trên của bạn để phân tích các sự cố hệ thống sản phẩm hoặc dịch vụ
          của chúng tôi, thu thập số liệu thống kê lưu lượng truy cập và khắc
          phục các rủi ro có thể xảy ra. Nếu bạn không cung cấp các thông tin
          trên, chúng tôi không thể bảo vệ tính bảo mật của sản phẩm hoặc dịch
          vụ của chúng tôi và tài khoản của bạn trong quá trình bạn sử dụng sản
          phẩm hoặc dịch vụ của chúng tôi.
        </p>

        <p>5.10 Cải thiện trải nghiệm và sự hài lòng của người dùng</p>
        <p>
          Để cải thiện trải nghiệm và sự hài lòng của bạn với dịch vụ ODAILY,
          chúng tôi sẽ tự động thu thập hành vi vận hành của bạn, đồng thời tiến
          hành nghiên cứu và phân tích dữ liệu người dùng dựa trên hành vi vận
          hành của bạn, để tiếp tục tối ưu hóa dịch vụ ODAILY.
        </p>

        <p>
          5.11 <b>Ngoại lệ đối với sự đồng ý</b>
        </p>
        <p>
          <b>
            Xin lưu ý rằng chúng tôi không cần sự cho phép của bạn để thu thập
            và sử dụng thông tin cá nhân trong các trường hợp sau:
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1) Liên quan đến an ninh quốc gia, an ninh quốc phòng;</li>
          <li>
            2) Liên quan đến an ninh công cộng, sức khỏe cộng đồng và lợi ích
            công cộng lớn;
          </li>
          <li>
            3) Liên quan đến hoạt động điều tra, truy tố, xét xử, thi hành án
            hình sự;
          </li>
          <li>
            4) Để bảo vệ các quyền và lợi ích hợp pháp chính của chủ thể thông
            tin cá nhân hoặc các cá nhân khác như tính mạng và tài sản, nhưng
            khó nhận được sự đồng ý của cá nhân;
          </li>
          <li>
            5) Thông tin cá nhân được thu thập được tiết lộ cho công chúng bởi
            chính chủ thể thông tin cá nhân;
          </li>
          <li>
            6) Thông tin cá nhân của bạn được thu thập từ thông tin được tiết lộ
            hợp pháp, chẳng hạn như báo cáo tin tức pháp lý, tiết lộ thông tin
            của chính phủ và các kênh khác; ngoại trừ trường hợp bạn từ chối rõ
            ràng hoặc việc xử lý thông tin vi phạm lợi ích chính của bạn;
          </li>
          <li>7) Cần thiết phải ký hợp đồng theo yêu cầu của bạn;</li>
          <li>
            8) Cần thiết để duy trì hoạt động an toàn và ổn định của sản phẩm
            và/hoặc dịch vụ được cung cấp, chẳng hạn như phát hiện và xử lý lỗi
            của sản phẩm và/hoặc dịch vụ;
          </li>
          <li>9) Cần thiết cho việc đưa tin pháp luật;</li>
          <li>
            10) Các tổ chức nghiên cứu học thuật cần thực hiện nghiên cứu thống
            kê hoặc học thuật dựa trên lợi ích công cộng và khi cung cấp kết quả
            nghiên cứu học thuật hoặc mô tả cho thế giới bên ngoài, hãy hủy nhận
            dạng thông tin cá nhân có trong kết quả;
          </li>
          <li>11) Các trường hợp khác theo quy định của pháp luật.</li>
        </ul>
        <p>
          <b>
            Xin lưu ý rằng thông tin riêng lẻ hoặc kết hợp với thông tin khác
            không thể xác định danh tính của bạn hoặc liên hệ trực tiếp với bạn
            không phải là thông tin cá nhân. Nếu chúng tôi kết hợp thông tin
            không thể liên kết với bất kỳ cá nhân cụ thể nào với thông tin khác
            để nhận dạng cá nhân một người tự nhiên hoặc sử dụng thông tin đó
            kết hợp với thông tin cá nhân, thì thông tin đó sẽ được coi là thông
            tin cá nhân trong suốt thời gian sử dụng kết hợp.
          </b>
        </p>

        <p>5.12 Cuộc gọi quyền thiết bị</p>
        <p>
          Để đảm bảo việc triển khai bình thường các dịch vụ ODAILY liên quan,
          chúng tôi cần gọi các quyền cần thiết tương ứng theo các tình huống sử
          dụng cụ thể. Để biết hướng dẫn gọi quyền cụ thể, vui lòng tham khảo
          <a href="?application-authority" target="_blank">
            &quot;Đơn xin cấp phép ứng dụng và hướng dẫn&quot;
          </a>
          。
        </p>
        <p>
          Bạn có thể chọn tắt một số hoặc tất cả các quyền trong phần cài đặt
          của thiết bị, điều này có thể khiến các dịch vụ ODAILY liên quan tương
          ứng không được thực hiện hoặc không đạt được hiệu quả như mong đợi.
        </p>

        <p>5.13 Đề xuất được cá nhân hóa</p>
        <p>
          Xin lưu ý rằng dịch vụ ODAILY không liên quan đến đề xuất và hiển thị
          nội dung được cá nhân hóa và chúng tôi sẽ không thu thập thông tin
          duyệt và tìm kiếm, sở thích hành vi và thông tin khác của bạn để phân
          tích chân dung.
        </p>

        <h6>4. Cách chúng tôi sử dụng cookie và các công nghệ tương tự</h6>
        <p>
          1. Cookie có thể giúp các trang web xác định người dùng đã đăng ký và
          tính toán số lượng người dùng, chúng thường được các trang web sử dụng
          để xác định xem người dùng đã hoàn tất đăng ký có đăng nhập hay không.
          Nền tảng cam kết rằng các nghiên cứu về thông tin cookie chỉ được sử
          dụng cho mục đích nâng cao chất lượng dịch vụ/sản phẩm và tối ưu hóa
          trải nghiệm người dùng. Đồng thời, nếu bạn không muốn thông tin cá
          nhân của mình được lưu trong cookie, bạn có thể định cấu hình trình
          duyệt của mình: chọn &quot;Từ chối cookie&quot; hoặc &quot;Thông báo
          cho bạn khi trang web gửi cookie&quot;. Bạn xác nhận rằng, vì các dịch
          vụ của ODAILY được triển khai bằng cách hỗ trợ cookie, sau khi đóng
          cookie, quyền truy cập của bạn vào nền tảng này có thể bị ảnh hưởng
          hoặc bạn không thể nhận được đầy đủ các dịch vụ của nền tảng này. Khi
          bạn không tắt cookie, bạn có thể được nhắc: liệu có giữ lại thông tin
          người dùng vào lần tới khi bạn truy cập trang web này để đơn giản hóa
          thủ tục đăng nhập (chẳng hạn như đăng nhập bằng một cú nhấp chuột).
        </p>
        <p>
          2. Phương pháp và mục đích sử dụng bọ web trên nền tảng này cũng giống
          như mục đích của cookie và các điều khoản trên sẽ được áp dụng bằng
          cách tham khảo.
        </p>

        <h6>
          5. Cách chúng tôi ủy thác xử lý, cung cấp bên ngoài, chuyển giao và
          tiết lộ công khai thông tin cá nhân của bạn
        </h6>
        <p>1. Ủy thác xử lý thông tin cá nhân của bạn</p>
        <p>
          Để cung cấp cho bạn các dịch vụ ODAILY hoàn chỉnh và chất lượng cao
          hơn, một số chức năng có thể được cung cấp bởi các đối tác dịch vụ của
          chúng tôi (chẳng hạn như nhà cung cấp dịch vụ bên thứ ba, nhà thầu,
          đại lý của chúng tôi) và chúng tôi sẽ ủy thác cho các đối tác dịch vụ
          xử lý thông tin của bạn trên thay mặt chúng tôi. Một số thông tin cá
          nhân, bao gồm nhưng không giới hạn đối với các đối tác cung cấp ODAILY
          dịch vụ đăng nhập bằng một cú nhấp chuột, dịch vụ tin nhắn đẩy, dịch
          vụ phân tích dữ liệu và thống kê. Chúng tôi sẽ yêu cầu các đối tác
          dịch vụ tuân thủ các nghĩa vụ bảo mật nghiêm ngặt và thực hiện các
          biện pháp bảo mật hiệu quả thông qua các thỏa thuận bằng văn bản, kiểm
          toán tại chỗ, v.v. và nghiêm cấm họ sử dụng thông tin đó cho các mục
          đích không được bạn cho phép. Sau khi chấm dứt quan hệ ủy thác, đối
          tác cung cấp dịch vụ sẽ không lưu thông tin cá nhân của bạn nữa.
        </p>
        <p>2. Cung cấp bên ngoài</p>
        <p>
          Chúng tôi sẽ không cung cấp thông tin cá nhân của bạn cho các công ty,
          tổ chức, cá nhân không phải là nhà cung cấp dịch vụ của sản phẩm này,
          ngoại trừ các trường hợp sau:
        </p>
        <p>
          2.1 Cần thiết cho việc thực hiện các nghĩa vụ pháp lý: Chúng tôi có
          thể, theo các quy định của pháp luật và các quy định, sự cần thiết của
          việc kiện tụng, giải quyết tranh chấp hoặc các yêu cầu của cơ quan
          hành chính và tư pháp, cũng như sự cần thiết của việc thực hiện các
          nghĩa vụ pháp lý khác, hoặc các lý do pháp lý khác theo quy định của
          pháp luật, Nếu cần phải ứng phó với các trường hợp khẩn cấp về sức
          khỏe cộng đồng hoặc để bảo vệ tính mạng, sức khỏe và an toàn tài sản
          của bạn trong các tình huống khẩn cấp hoặc để thực hiện các báo cáo
          tin tức và giám sát dư luận vì lợi ích công cộng, hãy cung cấp thông
          tin cá nhân của bạn ra thế giới bên ngoài.
        </p>
        <p>
          2.2 Dựa trên sự đồng ý của bạn hoặc yêu cầu chủ động của bạn: Nếu bạn
          đồng ý riêng hoặc yêu cầu chúng tôi cung cấp thông tin cá nhân của bạn
          cho bên thứ ba, chúng tôi sẽ thông báo cho bạn về người nhận thông tin
          cá nhân theo cách thích hợp trên trang tương ứng dựa trên mục đích tên
          đồng ý của bạn và thông tin liên lạc. Nếu bạn sử dụng dịch vụ chia sẻ
          thông tin, chúng tôi sẽ cung cấp tên của nền tảng chia sẻ bên thứ ba
          trên trang. Để biết thông tin liên hệ cụ thể, vui lòng tham khảo chính
          sách quyền riêng tư của bên thứ ba.
        </p>
        <p>
          2.3 Cần thiết để thực hiện thỏa thuận dịch vụ: một số dịch vụ ODAILY
          của chúng tôi có thể được cung cấp cho bạn bởi các chi nhánh và tổ
          chức hợp tác của chúng tôi hoặc cùng với họ. Do đó, để thực hiện thỏa
          thuận dịch vụ với bạn, chúng tôi phải cung cấp thông tin cá nhân của
          bạn cho các công ty liên kết hoặc tổ chức hợp tác của chúng tôi. chúng
          tôi xây dựng
          <a href="?info-share-list" target="_blank">
            &quot;Danh sách chia sẻ thông tin&quot;
          </a>
          Giới thiệu rõ ràng và ngắn gọn về tình hình của các công ty liên kết
          và tổ chức hợp tác của chúng tôi với bạn và kiểm tra &quot;Danh sách
          chia sẻ thông tin&quot;
        </p>

        <p>
          1) Cung cấp cho các công ty liên kết: Để tạo điều kiện cho bạn sử dụng
          các sản phẩm hoặc dịch vụ do chúng tôi cung cấp với các công ty liên
          kết hoặc các sản phẩm khác, thông tin cá nhân của bạn có thể được chia
          sẻ với các công ty liên kết của chúng tôi và/hoặc nhà cung cấp dịch vụ
          được chỉ định của họ. Chúng tôi sẽ chỉ chia sẻ thông tin cá nhân cần
          thiết và bị ràng buộc bởi các mục đích được nêu trong chính sách này.
        </p>

        <p>
          2) Cung cấp cho các tổ chức hợp tác của chúng tôi: Để cung cấp cho bạn
          các sản phẩm và dịch vụ hoàn chỉnh, một số dịch vụ hoặc công nghệ của
          chúng tôi sẽ được cung cấp bởi các tổ chức hợp tác được ủy quyền. Vui
          lòng biết rằng chúng tôi sẽ không chủ động cung cấp thông tin cá nhân
          của bạn cho những người xử lý thông tin cá nhân khác, chúng tôi sẽ chỉ
          chia sẻ thông tin cá nhân của bạn cho các mục đích hợp pháp, chính
          đáng, cần thiết, cụ thể và rõ ràng và chỉ chia sẻ những thông tin cá
          nhân cần thiết. Chúng tôi sẽ cung cấp thông tin cá nhân của bạn cho
          những người xử lý thông tin cá nhân khác, thông báo cho bạn tên người
          nhận, thông tin liên hệ, mục đích xử lý, phương pháp xử lý và loại
          thông tin cá nhân, đồng thời nhận được sự đồng ý riêng của bạn. Đồng
          thời, chúng tôi sẽ ký các thỏa thuận bảo mật nghiêm ngặt với các tổ
          chức đối tác, yêu cầu họ xử lý thông tin cá nhân của bạn theo đúng
          Chính sách quyền riêng tư này và mọi chính sách bảo mật có liên quan
          khác và thực hiện các biện pháp bảo mật. Chúng tôi yêu cầu các tổ chức
          đối tác của chúng tôi không có quyền sử dụng thông tin cá nhân được
          chia sẻ cho bất kỳ mục đích nào khác.
          <b>
            Khi bạn xem các trang web do bên thứ ba tạo hoặc sử dụng các ứng
            dụng do bên thứ ba phát triển, các bên thứ ba này có thể đặt cookie
            của riêng họ hoặc sử dụng các phương tiện kỹ thuật khác, các phương
            tiện kỹ thuật này không thuộc quyền kiểm soát của chúng tôi và việc
            sử dụng chúng không chịu sự điều chỉnh của Ràng buộc này bởi Chính
            sách bảo mật. Các tổ chức hợp tác có thể có các chính sách bảo vệ
            thông tin cá nhân độc lập và thỏa thuận người dùng của riêng họ.
            Chúng tôi khuyên bạn nên đọc và tuân thủ các chính sách bảo vệ thông
            tin cá nhân và thỏa thuận người dùng của bên thứ ba.
          </b>
        </p>

        <p>
          2.4 Dựa trên các lý do pháp lý khác do pháp luật quy định: Chúng tôi
          có thể sử dụng các lý do pháp lý khác do pháp luật quy định, chẳng hạn
          như ứng phó với các trường hợp khẩn cấp về sức khỏe cộng đồng hoặc
          trong các tình huống khẩn cấp để bảo vệ tính mạng, sức khỏe và an toàn
          tài sản của bạn hoặc thực hiện tin tức vì lợi ích công cộng Nếu bạn
          cung cấp thông tin cá nhân của bạn cho bên thứ ba để báo cáo, giám sát
          dư luận, v.v., chúng tôi sẽ thông báo cho bạn những thông tin liên
          quan vào thời điểm thích hợp (chẳng hạn như sau khi tình huống khẩn
          cấp được loại bỏ).
        </p>

        <p>3. Chuyển khoản</p>
        <p>
          Chúng tôi sẽ không chuyển giao thông tin cá nhân của bạn cho bất kỳ
          công ty, tổ chức hay cá nhân nào, ngoại trừ các trường hợp sau:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) Chuyển giao với sự đồng ý rõ ràng. Sau khi nhận được sự đồng ý rõ
            ràng của bạn, chúng tôi sẽ chuyển thông tin cá nhân của bạn cho các
            bên khác.
          </li>
          <li>
            2) Chuyển nhượng trong trường hợp sáp nhập, mua lại hoặc thanh lý
            phá sản. Khi ODAILY tham gia vào việc sáp nhập, mua lại hoặc thanh
            lý phá sản, nếu có liên quan đến việc chuyển thông tin cá nhân,
            chúng tôi sẽ yêu cầu công ty hoặc tổ chức mới kiểm soát thông tin cá
            nhân của bạn tiếp tục bị ràng buộc bởi chính sách bảo mật này, nếu
            không chúng tôi sẽ yêu cầu công ty hoặc tổ chức đó để gia hạn cho
            bạn Yêu cầu sự đồng ý ủy quyền.
          </li>
        </ul>

        <p>4. Tiết lộ công khai</p>
        <p>
          4.1 Công bố thông tin là hành vi công bố thông tin cho xã hội hoặc các
          nhóm người không xác định. Chúng tôi sẽ không tiết lộ thông tin cá
          nhân của bạn ra công chúng, ngoại trừ việc tiết lộ cần thiết cho những
          vấn đề cần thiết như hình phạt đối với tài khoản bất hợp pháp, hành vi
          gian lận, v.v. tiết lộ công khai là cần thiết vì những lý do hợp lý,
          chúng tôi sẽ thông báo cho bạn về mục đích và loại thông tin được tiết
          lộ công khai trước khi tiết lộ công khai (nếu liên quan đến thông tin
          nhạy cảm cá nhân của bạn, chúng tôi cũng sẽ thông báo cho bạn về nội
          dung của thông tin nhạy cảm liên quan), và Sau khi nhận được sự cho
          phép và đồng ý của bạn, nó sẽ được tiết lộ công khai, trừ khi luật
          pháp và các quy định có quy định khác hoặc được thỏa thuận khác trong
          Chính sách quyền riêng tư này.
        </p>
        <p>
          4.2 Đối với việc tiết lộ công khai thông tin cá nhân của bạn, chúng
          tôi sẽ nhanh chóng và thận trọng xem xét tính hợp pháp, hợp lý và hợp
          pháp của thông tin đó sau khi nhận được đơn đăng ký tiết lộ công khai
          và thực hiện các biện pháp bảo mật thông tin cá nhân nghiêm ngặt nhất
          trong và sau khi tiết lộ công khai. để bảo vệ nó.
        </p>

        <p>5. Truy cập SDK của bên thứ ba</p>
        <p>
          Để đảm bảo rằng bạn có thể sử dụng và tận hưởng các dịch vụ ODAILY tốt
          hơn, một số chức năng chúng tôi cung cấp được cung cấp bởi các tổ chức
          bên thứ ba mà chúng tôi hợp tác dưới dạng phần bổ trợ SDK. Nhà cung
          cấp dịch vụ kỹ thuật SDK sẽ nhận được các quyền tương ứng và thông
          tin. Chúng tôi sẽ tiến hành giám sát bảo mật trên các SDK được tích
          hợp trong Ứng dụng để đảm bảo rằng các SDK này chỉ thu thập thông tin
          cá nhân cần thiết để thực hiện các chức năng hoặc dịch vụ của chúng.
          Bạn có thể bấm vào đây để xem
          <a href="?info-share-list" target="_blank">
            Danh sách kiểm tra chia sẻ thông tin.
          </a>
        </p>

        <p>
          6. Các trường hợp ngoại lệ đối với việc cho phép trước và đồng ý đối
          với việc xử lý được ủy thác, cung cấp bên ngoài, chuyển giao và tiết
          lộ công khai thông tin cá nhân
        </p>
        <p>
          <b>
            Theo các luật và quy định có liên quan, trong các trường hợp sau
            đây, việc chia sẻ, chuyển giao và tiết lộ công khai thông tin cá
            nhân của bạn không cần có sự cho phép và đồng ý trước của bạn:
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) Liên quan đến việc chúng tôi thực hiện các nghĩa vụ theo quy định
            của pháp luật và quy định;
          </li>
          <li>
            2) Liên quan trực tiếp đến an ninh quốc gia, bảo đảm quốc phòng;
          </li>
          <li>
            3) Liên quan trực tiếp đến an ninh công cộng, sức khỏe cộng đồng
            hoặc lợi ích công cộng lớn;
          </li>
          <li>
            4) Liên quan trực tiếp đến thực thi pháp luật tư pháp hoặc hành
            chính như điều tra, truy tố, xét xử và thi hành án hình sự;
          </li>
          <li>
            5) Để bảo vệ cuộc sống, tài sản và các quyền và lợi ích hợp pháp lớn
            khác của bạn hoặc cá nhân khác, nhưng rất khó để có được sự đồng ý
            của người đó;
          </li>
          <li>6) Thông tin cá nhân mà bạn tự tiết lộ cho công chúng;</li>
          <li>
            7) Thu thập thông tin cá nhân từ thông tin được tiết lộ hợp pháp,
            chẳng hạn như báo cáo tin tức pháp lý, tiết lộ thông tin của chính
            phủ và các kênh khác.
          </li>
        </ul>
        <p>
          <b>
            Xin lưu ý rằng, theo luật hiện hành, nếu chúng tôi thực hiện các
            biện pháp kỹ thuật và các biện pháp cần thiết khác để xử lý thông
            tin cá nhân, để người nhận dữ liệu không thể nhận dạng lại một cá
            nhân cụ thể và không thể khôi phục dữ liệu đó, việc xử lý ủy thác,
            cung cấp bên ngoài, chuyển giao dữ liệu được xử lý như vậy 、Việc
            tiết lộ công khai không cần thông báo cho bạn và được sự đồng ý của
            bạn
          </b>
        </p>

        <h6>6. Cách chúng tôi lưu trữ và bảo vệ thông tin cá nhân của bạn</h6>
        <p>1. lưu trữ</p>
        <p>
          1.1 Vị trí lưu trữ: Chúng tôi sẽ lưu trữ thông tin cá nhân của bạn
          được thu thập và tạo ra trong quá trình hoạt động tại Cộng hòa Nhân
          dân Trung Hoa theo luật pháp và quy định. Hiện tại, chúng tôi sẽ không
          truyền thông tin trên ra nước ngoài. Nếu chúng tôi truyền ra nước
          ngoài, chúng tôi sẽ tuân thủ nghiêm ngặt luật pháp và chính sách quản
          lý có liên quan của Trung Quốc, đồng thời sẽ tuân theo các quy định
          quốc gia có liên quan hoặc xin phép bạn.
        </p>
        <p>
          1.2 Thời gian lưu trữ: Chúng tôi cam kết sẽ luôn lưu trữ thông tin cá
          nhân của bạn trong một khoảng thời gian hợp lý và cần thiết theo quy
          định của pháp luật. Sau khoảng thời gian trên, chúng tôi sẽ xóa hoặc
          ẩn danh thông tin cá nhân của bạn.
        </p>
        <p>
          1.3 Nếu chúng tôi ngừng hoạt động, chúng tôi sẽ ngừng thu thập thông
          tin cá nhân của bạn kịp thời, thông báo cho bạn về thông báo ngừng
          hoạt động bằng cách gửi hoặc thông báo từng cái một và xóa hoặc ẩn
          danh thông tin cá nhân của bạn.
        </p>

        <p>2. Bảo vệ</p>
        <p>
          2.1 Chúng tôi rất coi trọng việc bảo mật thông tin cá nhân của bạn và
          sẽ cố gắng thực hiện các biện pháp bảo mật hợp lý (bao gồm cả khía
          cạnh kỹ thuật và quản lý) để bảo vệ thông tin cá nhân của bạn và ngăn
          thông tin cá nhân bạn cung cấp bị sử dụng hoặc truy cập trái phép mà
          không được phép, tiết lộ công khai , sử dụng, sửa đổi, hư hỏng, mất
          mát hoặc tiết lộ. Hiện tại, chúng tôi đã vượt qua
          <b>
            Đánh giá, lập hồ sơ bảo vệ cấp độ an toàn thông tin quốc gia (cấp độ
            ba).
          </b>
        </p>
        <p>
          2.2 Chúng tôi sẽ bảo vệ thông tin cá nhân của bạn bằng cách sử dụng
          công nghệ mã hóa, xử lý ẩn danh và các phương tiện hợp lý và khả thi
          có liên quan không thua kém các phương tiện trong ngành và sử dụng các
          cơ chế bảo vệ an ninh để ngăn chặn các cuộc tấn công ác ý vào thông
          tin cá nhân của bạn.
        </p>
        <p>
          2.3 Chúng tôi sẽ thiết lập một hệ thống quản lý bảo mật đặc biệt và
          quy trình bảo mật dữ liệu để đảm bảo an toàn cho thông tin cá nhân của
          bạn. Chúng tôi áp dụng các chính sách truy cập và sử dụng dữ liệu
          nghiêm ngặt để đảm bảo rằng chỉ những người được ủy quyền mới có quyền
          truy cập vào thông tin cá nhân của bạn.
        </p>
        <p>
          2.4
          <b>
            Mặc dù các biện pháp hợp lý và hiệu quả nêu trên đã được thực hiện
            và các tiêu chuẩn theo yêu cầu của luật liên quan đã được tuân thủ,
            xin vui lòng hiểu rằng do những hạn chế về kỹ thuật và các phương
            tiện độc hại có thể xảy ra, trong ngành Internet, ngay cả khi chúng
            tôi cố gắng hết sức để tăng cường an ninh không thể luôn đảm bảo an
            toàn thông tin 100% và chúng tôi sẽ cố gắng hết sức để đảm bảo an
            toàn cho thông tin cá nhân mà bạn cung cấp cho chúng tôi.
          </b>
        </p>
        <p>
          2.5
          <b>
            Bạn biết và hiểu rằng hệ thống và mạng liên lạc mà bạn sử dụng để
            truy cập các dịch vụ của chúng tôi có thể gặp sự cố do các yếu tố
            nằm ngoài tầm kiểm soát của chúng tôi. Do đó, chúng tôi thực sự
            khuyên bạn nên thực hiện các biện pháp tích cực để bảo vệ an toàn
            cho thông tin cá nhân của mình, bao gồm nhưng không giới hạn ở việc
            sử dụng mật khẩu phức tạp, thay đổi mật khẩu thường xuyên và không
            tiết lộ mật khẩu tài khoản của bạn cũng như thông tin cá nhân có
            liên quan cho người khác.
          </b>
        </p>
        <p>
          2.6 Chúng tôi sẽ lập kế hoạch ứng phó khẩn cấp và ngay lập tức kích
          hoạt các kế hoạch khẩn cấp khi xảy ra sự cố bảo mật thông tin người
          dùng, đồng thời cố gắng ngăn chặn tác động và hậu quả của những sự cố
          bảo mật này lan rộng. Khi xảy ra sự cố bảo mật thông tin người dùng
          (tiết lộ, mất mát), chúng tôi sẽ nhanh chóng thông báo cho bạn theo
          các yêu cầu của luật pháp và quy định: tình hình cơ bản và tác động có
          thể có của sự cố bảo mật, các biện pháp xử lý mà chúng tôi đã hoặc sẽ
          thực hiện, và bảo vệ chính bạn và tư vấn giảm thiểu rủi ro, biện pháp
          khắc phục cho bạn. Chúng tôi sẽ nhanh chóng thông báo cho bạn về tình
          hình liên quan đến sự kiện thông qua thông báo đẩy, email, thư, tin
          nhắn văn bản và các hình thức liên quan khác. Đồng thời, chúng tôi
          cũng sẽ báo cáo việc xử lý các sự cố về an toàn thông tin người dùng
          theo yêu cầu của các cơ quan quản lý có liên quan.
        </p>
        <p>
          2.7 Sau khi bạn rời khỏi nền tảng này và dịch vụ ODAILY, duyệt hoặc sử
          dụng các trang web, dịch vụ và tài nguyên nội dung khác, chúng tôi sẽ
          không có khả năng và nghĩa vụ trực tiếp bảo vệ bất kỳ thông tin cá
          nhân nào bạn gửi trên phần mềm và trang web ngoài nền tảng này và dịch
          vụ ODAILY, bất kể của việc bạn Đăng nhập, duyệt hoặc sử dụng phần mềm
          trên, cho dù trang web có dựa trên liên kết hoặc hướng dẫn của nền
          tảng này hay không.
        </p>

        <h6>7. Cách bạn quản lý thông tin cá nhân của mình</h6>
        <p>1. Bạn có quyền hỏi và sửa đổi thông tin cá nhân của mình</p>
        <p>
          Chúng tôi rất coi trọng mối quan tâm của bạn về thông tin cá nhân và
          cung cấp nhiều chức năng để bảo vệ quyền truy vấn và sửa đổi thông tin
          cá nhân của bạn.
        </p>
        <p>1.1 Quản lý thông tin tài khoản</p>
        <p>
          &quot;Của tôi&quot; → &quot;Quản lý tài khoản&quot; Bạn có thể sử dụng
          chức năng này để sửa đổi mật khẩu tài khoản, sửa đổi số điện thoại di
          động bị ràng buộc và liên kết tài khoản bên thứ ba.
        </p>
        <p>
          &quot;Của tôi&quot; → &quot;Thẻ tài khoản&quot; → &quot;Chỉnh
          sửa&quot; Bạn có thể xem và sửa đổi thông tin tài khoản của mình trong
          hồ sơ chỉnh sửa, bao gồm hình đại diện, biệt hiệu, giới tính, ngày
          sinh, hồ sơ, danh tính, ngành.
        </p>
        <p>1.2 Quản lý bình luận và thông tin bài viết</p>
        <p>
          &quot;Của tôi&quot; → &quot;Trang chủ cá nhân&quot; Nhấp vào một bình
          luận hoặc bài viết để vào trang chi tiết nội dung và bạn có thể tìm
          hiểu về các bình luận hoặc bài viết mà bạn đã xuất bản.
        </p>
        <p>1.3 Quản lý lịch sử duyệt web</p>
        <p>
          &quot;Của tôi&quot; → &quot;Duyệt&quot;, bạn có thể xem lịch sử duyệt
          web của mình.
        </p>
        <p>1.4 Quản lý Hồ sơ Quan ngại</p>
        <p>
          &quot;Của tôi&quot; → &quot;Theo dõi&quot; Bạn có thể quản lý tất cả
          danh sách theo dõi, xem và sửa đổi người dùng, tác giả và chủ đề mà
          bạn theo dõi.
        </p>
        <p>
          &quot;Của tôi&quot; → &quot;Ưa thích&quot; Bạn có thể quản lý các bài
          viết, âm thanh và video yêu thích của mình và những thứ khác.
        </p>
        <p>
          Sau khi bạn thay đổi phạm vi ủy quyền của mình, chúng tôi sẽ điều
          chỉnh dịch vụ ODAILY do nền tảng này cung cấp cho bạn cho phù hợp.
          Nhưng vui lòng hiểu rằng điều này sẽ không ảnh hưởng đến quá trình xử
          lý thông tin cá nhân trước đó dựa trên sự cho phép của bạn.
        </p>

        <p>2. Xóa thông tin cá nhân của bạn</p>
        <p>
          Chúng tôi sẽ cung cấp các cách sau để giúp bạn xóa thông tin bạn đã
          đăng trên nền tảng này:
        </p>
        <p>2.1 Xóa bình luận và thông tin bài viết</p>
        <p>
          &quot;Của tôi&quot; → &quot;Trang chủ cá nhân&quot; Nhấp vào một nhận
          xét hoặc bài báo đã xuất bản để vào trang chi tiết nội dung và bạn có
          thể xóa các nhận xét hoặc bài báo đã xuất bản của mình bất cứ lúc nào
          bằng cách nhấn và giữ. Tuy nhiên, nếu bạn xóa các bình luận hoặc bài
          báo đã xuất bản, bạn chỉ hủy hiển thị công khai của chúng và chúng tôi
          vẫn cần lưu trữ các bình luận hoặc bài báo mà bạn đã xuất bản ở chế độ
          nền theo luật và quy định.
        </p>
        <p>2.2 Xóa lịch sử duyệt web</p>
        <p>
          &quot;Của tôi&quot; → &quot;Đang duyệt&quot; bạn có thể xóa lịch sử
          duyệt web của mình.
        </p>
        <p>2.3 Xóa hồ sơ theo dõi</p>
        <p>
          &quot;Của tôi&quot; → &quot;Theo dõi&quot; Bạn có thể xóa người dùng,
          tác giả và chủ đề mà bạn theo dõi.
        </p>
        <p>
          &quot;Của tôi&quot; → &quot;Ưa thích&quot; Bạn có thể xóa các bài
          viết, âm thanh và video yêu thích của mình và những thứ khác.
        </p>
        <p>
          2.4 Trong các trường hợp sau, chúng tôi sẽ chủ động xóa thông tin cá
          nhân của bạn. Nếu chúng tôi chưa xóa, bạn có thể yêu cầu chúng tôi xóa
          thông tin cá nhân của bạn bằng cách gửi email đến om@odaily.email:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            (1) Đã đạt được mục đích xử lý, không thể đạt được hoặc không còn
            cần thiết để đạt được mục đích xử lý;
          </li>
          <li>
            (2) Chúng tôi ngừng cung cấp sản phẩm hoặc dịch vụ hoặc hết thời
            gian lưu giữ;
          </li>
          <li>(3) Bạn rút lại sự đồng ý của mình;</li>
          <li>
            (4) Chúng tôi xử lý thông tin cá nhân vi phạm pháp luật, quy định
            hành chính hoặc thỏa thuận;
          </li>
          <li>
            (5) Các trường hợp khác theo quy định của pháp luật và quy định hành
            chính.
          </li>
        </ul>
        <p>
          Sau khi bạn xóa thông tin, chúng tôi sẽ điều chỉnh dịch vụ ODAILY do
          nền tảng này cung cấp cho bạn cho phù hợp. Nhưng vui lòng hiểu rằng
          điều này sẽ không ảnh hưởng đến quá trình xử lý thông tin cá nhân
          trước đó dựa trên sự cho phép của bạn.
        </p>

        <p>3. Nhận bản sao thông tin cá nhân của bạn</p>
        <p>
          Nếu bạn cần lấy một bản sao thông tin cá nhân của mình, bạn có thể gửi
          email đến om@odaily.email và chúng tôi sẽ cung cấp cho bạn thông tin
          cá nhân mà chúng tôi đã thu thập theo yêu cầu bằng văn bản của bạn.
        </p>

        <p>
          4. Bạn có quyền rút lại ủy quyền của mình đối với thông tin cá nhân
        </p>
        <p>
          Bạn có thể tắt quyền định vị GPS, máy ảnh, micrô, album ảnh, thay đổi
          phạm vi đồng ý hoặc rút lại quyền của mình trong chính hệ điều hành
          của thiết bị. Sau khi đóng ủy quyền, chúng tôi sẽ không thu thập thông
          tin liên quan đến các quyền này nữa. , để từ chối chúng tôi thu thập
          thông tin cá nhân tương ứng của bạn. Bạn cần hiểu rằng sau khi bạn rút
          lại ủy quyền, chúng tôi không thể tiếp tục cung cấp cho bạn dịch vụ
          ODAILY tương ứng với việc rút lại ủy quyền.
          <b>
            Tuy nhiên, quyết định rút lại ủy quyền của bạn sẽ không ảnh hưởng
            đến quá trình xử lý thông tin cá nhân trước đó dựa trên ủy quyền của
            bạn.
          </b>
        </p>

        <p>5. Quyền chuyển thông tin cá nhân</p>
        <p>
          Nếu bạn cần chuyển thông tin cá nhân của mình sang các nền tảng, công
          ty hoặc tổ chức khác, bạn có thể liên hệ với chúng tôi thông qua các
          phương thức được cung cấp trong Điều 11 của Chính sách quyền riêng tư
          này. Chúng tôi sẽ xem xét yêu cầu của bạn và chúng tôi sẽ cung cấp cho
          bạn cách chuyển thông tin cá nhân tương ứng, tuân theo các điều kiện
          tiên quyết để chuyển thông tin cá nhân theo quy định của pháp luật.
        </p>

        <p>6. Hủy tài khoản OAILY</p>
        <p>
          Nếu bạn cần hủy tài khoản ODAILY của mình, vui lòng gửi đơn đăng ký
          trong &quot;Của tôi&quot; → &quot;Quản lý tài khoản&quot; → &quot;Hủy
          tài khoản&quot;. Sau khi hủy tài khoản, chúng tôi sẽ ngừng cung cấp
          cho bạn các dịch vụ ODAILY và bảo vệ thông tin của bạn thông qua ẩn
          danh và các phương pháp khác theo các yêu cầu liên quan của luật pháp
          và quy định.
        </p>

        <p>
          7. Quản lý và kiểm soát độc lập thông tin tiếp thị và đẩy mục tiêu
        </p>
        <p>
          7.1 Tin nhắn đẩy - Nếu bạn không muốn nhận thông báo tin nhắn trên
          trang Ứng dụng, bạn có thể từ chối nhận thông báo tin nhắn khi cài đặt
          Ứng dụng lần đầu hoặc tắt chức năng thông báo tương ứng trong
          &quot;Của tôi&quot; → &quot;Quản lý tin nhắn đẩy&quot; .
        </p>
        <p>
          7.2 Quảng cáo/lượt quay lại——Nếu bạn không muốn nhận tin nhắn văn bản
          quảng cáo hoặc lượt quay lại qua điện thoại của chúng tôi, bạn có thể
          hủy đăng ký thông qua chức năng hủy đăng ký tương ứng trong thông tin
          hoặc từ chối rõ ràng trong lần quay lại qua điện thoại.
        </p>

        <p>8. Đáp ứng nhu cầu của bạn</p>
        <p>
          Trong các trường hợp sau, chúng tôi sẽ không thể đáp ứng yêu cầu của
          bạn theo luật pháp và quy định:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) Liên quan đến việc chúng tôi thực hiện các nghĩa vụ theo quy định
            của pháp luật và quy định;
          </li>
          <li>
            2) Liên quan trực tiếp đến an ninh quốc gia, bảo đảm quốc phòng;
          </li>
          <li>
            3) Liên quan trực tiếp đến an ninh công cộng, sức khỏe cộng đồng và
            lợi ích công cộng lớn;
          </li>
          <li>
            4) Liên quan trực tiếp đến hoạt động điều tra, truy tố, xét xử và
            thi hành án hình sự;
          </li>
          <li>
            5) Có đủ bằng chứng cho thấy chủ thể thông tin cá nhân có ác ý chủ
            quan hoặc lạm dụng quyền;
          </li>
          <li>
            6) Để bảo vệ các quyền và lợi ích hợp pháp chính của chủ thể thông
            tin cá nhân hoặc các cá nhân khác như tính mạng và tài sản, nhưng
            khó có được sự ủy quyền và đồng ý của cá nhân;
          </li>
          <li>
            7) Việc trả lời yêu cầu của bạn sẽ gây thiệt hại nghiêm trọng đến
            quyền và lợi ích hợp pháp của bạn hoặc của cá nhân, tổ chức khác;
          </li>
          <li>8) Những người liên quan đến bí mật thương mại.</li>
        </ul>

        <h6>8. Bảo vệ thông tin cá nhân của trẻ vị thành niên</h6>
        <p>
          1. Chúng tôi rất coi trọng việc bảo vệ thông tin cá nhân của trẻ vị
          thành niên. Theo các luật và quy định có liên quan, nếu bạn là trẻ vị
          thành niên dưới 18 tuổi, bạn nên đọc và đồng ý với Chính sách quyền
          riêng tư này dưới sự giám sát và hướng dẫn của cha mẹ hoặc người giám
          hộ khác trước khi sử dụng các dịch vụ của ODAILY.
        </p>
        <p>
          2. Chúng tôi bảo vệ thông tin cá nhân của trẻ vị thành niên theo luật
          pháp và quy định quốc gia có liên quan và sẽ chỉ thu thập, sử dụng,
          lưu trữ, chia sẻ, chuyển giao hoặc sử dụng chúng khi luật pháp cho
          phép, với sự đồng ý rõ ràng của cha mẹ hoặc người giám hộ khác, hoặc
          khi tiết lộ thông tin cá nhân của trẻ vị thành niên; nếu chúng tôi
          thấy rằng thông tin cá nhân của trẻ vị thành niên đã được thu thập mà
          không có sự đồng ý trước của người giám hộ có thể xác minh, chúng tôi
          sẽ cố gắng xóa thông tin liên quan càng sớm càng tốt.
        </p>
        <p>
          3. Nếu bạn là người giám hộ của trẻ vị thành niên, vui lòng liên hệ
          với chúng tôi qua các phương thức quy định tại Điều 11 của Chính sách
          quyền riêng tư này khi bạn có thắc mắc về thông tin cá nhân của trẻ vị
          thành niên dưới sự giám hộ của bạn.
        </p>

        <h6>9. Sửa đổi và Thông báo về Chính sách Quyền riêng tư</h6>
        <p>
          1. Để cung cấp cho bạn các dịch vụ tốt hơn, dịch vụ ODAILY của chúng
          tôi sẽ thay đổi theo thời gian và chính sách bảo mật này cũng sẽ được
          điều chỉnh cho phù hợp.
        </p>
        <p>
          2. Nếu không có sự đồng ý rõ ràng của bạn, chúng tôi sẽ không giảm
          quyền của bạn theo Chính sách quyền riêng tư này. Chúng tôi sẽ thông
          báo cho bạn theo những cách hợp lý như thông báo đẩy ứng dụng khách,
          cửa sổ bật lên, v.v. để bạn có thể theo kịp mọi thay đổi được thực
          hiện đối với chính sách quyền riêng tư này. Bạn có thể xem nội dung
          mới nhất của Chính sách quyền riêng tư này thông qua &quot;Của
          tôi&quot; → &quot;Dịch vụ khác&quot; → &quot;Chính sách quyền riêng
          tư&quot;.
        </p>

        <p>
          3. Đối với những thay đổi lớn, tùy theo từng trường hợp cụ thể, chúng
          tôi cũng sẽ đưa ra thông báo nổi bật hơn giải thích những thay đổi cụ
          thể đối với chính sách bảo mật này. Những thay đổi quan trọng bao gồm,
          nhưng không giới hạn ở:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) Những thay đổi quan trọng đã diễn ra trong mô hình dịch vụ của
            chúng tôi. Chẳng hạn như mục đích xử lý thông tin cá nhân, loại
            thông tin cá nhân được xử lý, cách sử dụng thông tin cá nhân, v.v.;
          </li>
          <li>
            2) Chúng tôi đã trải qua những thay đổi đáng kể về cơ cấu sở hữu, cơ
            cấu tổ chức, v.v. Chẳng hạn như thay đổi chủ sở hữu do điều chỉnh
            kinh doanh, sáp nhập phá sản, v.v.;
          </li>
          <li>
            3) Đối tượng chính của việc chia sẻ, chuyển giao hoặc tiết lộ công
            khai thông tin cá nhân thay đổi;
          </li>
          <li>
            4) Có những thay đổi lớn về quyền tham gia xử lý thông tin cá nhân
            của bạn và cách thực hiện chúng;
          </li>
          <li>
            5) Những thay đổi trong bộ phận chịu trách nhiệm của chúng tôi,
            phương thức liên hệ và kênh khiếu nại chịu trách nhiệm xử lý bảo mật
            thông tin cá nhân;
          </li>
          <li>
            6) Khi báo cáo đánh giá tác động an toàn thông tin cá nhân chỉ ra
            rằng có rủi ro cao.
          </li>
        </ul>

        <p>
          4. Nếu bạn không đồng ý với Chính sách quyền riêng tư đã sửa đổi, bạn
          có quyền và nên ngừng sử dụng các dịch vụ của ODAILY ngay lập tức.
          Nhưng vui lòng hiểu rằng nếu bạn không đồng ý với chính sách quyền
          riêng tư đã sửa đổi, bạn có thể không tiếp tục sử dụng các dịch vụ
          ODAILY có liên quan và một số chức năng một cách bình thường hoặc bạn
          có thể không đạt được hiệu quả dịch vụ mà chúng tôi mong đợi.
        </p>

        <h6>
          10. Việc bảo vệ thông tin cá nhân của người dùng đã qua đời của chúng
          tôi
        </h6>
        <p>
          Từ ngày 1 tháng 11 năm 2021, chúng tôi sẽ xây dựng hệ thống bảo vệ
          thông tin cá nhân cho người đã khuất theo các quy định có liên quan
          của &quot;Luật bảo vệ thông tin cá nhân&quot;. Sau khi người dùng
          ODAILY qua đời (chỉ dành cho thể nhân), người thân của họ có thể xem,
          sao chép, sửa và xóa thông tin cá nhân có liên quan của người dùng đã
          chết thông qua thông tin liên hệ được cung cấp trong Điều 11 của Chính
          sách quyền riêng tư này vì lợi ích hợp pháp của họ và lợi ích hợp pháp
          và các quyền khác, ngoại trừ việc người dùng đã qua đời có các thỏa
          thuận khác trong suốt cuộc đời của mình.
        </p>

        <h6>11. làm thế nào để bạn liên hệ với chúng tôi</h6>
        <p>
          Nếu bạn có bất kỳ khiếu nại, đề xuất hoặc câu hỏi nào về bảo vệ thông
          tin cá nhân hoặc nếu bạn có bất kỳ câu hỏi nào về chính sách bảo mật
          này và cần chúng tôi giải thích, bạn có thể liên hệ với chúng tôi theo
          những cách sau và chúng tôi sẽ xem xét các vấn đề liên quan ngay khi
          có thể và xác minh Trả lời của bạn trong vòng mười lăm ngày làm việc
          sau khi nhận dạng người dùng:
        </p>
        <p>
          1. Gửi email đến om@daily.email để liên hệ với người phụ trách bảo vệ
          thông tin cá nhân của chúng tôi;
        </p>
        <p>
          2. Bạn có thể liên hệ với chúng tôi qua &quot;Của tôi&quot; →
          &quot;Phản hồi&quot; → &quot;Câu hỏi khác&quot;.
        </p>

        <h6>12. Giải quyết tranh chấp</h6>
        <p>
          Đối với bất kỳ tranh chấp nào phát sinh từ Chính sách quyền riêng tư
          này và việc chúng tôi xử lý thông tin cá nhân của bạn, bạn có thể kiện
          lên Tòa án nhân dân quận Triều Dương, Bắc Kinh. Nếu bạn cho rằng hành
          vi xử lý thông tin cá nhân của chúng tôi đã làm tổn hại đến quyền và
          lợi ích hợp pháp của bạn, bạn cũng có thể báo cáo với cơ quan chính
          phủ có liên quan.
        </p>
      </div>
    );
  }
}

export default PrivacyKo;
