import { combineReducers } from 'redux';

import columns from './columns';
import feeds from './feeds';
import slide from './slide';
import user from './user';
import post from './post';
import topic from './topic';
import research from './research';
import market from './market';
// import cookies from './cookies';
import newsflash from './newsflash';
import search from './search';
import global from './global';
import my from './my';
import home from './home';
import secondTag from './secondTag';
import vote from './vote';
import activity from './activity';
import carnival from './carnival';
import login from './login';
import navigation from './navigation';
import viewpoint from './viewpoint';
import library from './library';
import lang from './lang';
const rootReducer = combineReducers({
  columns,
  feeds,
  slide,
  user,
  post,
  topic,
  // cookies,
  newsflash,
  search,
  global,
  my,
  research,
  secondTag,
  home,
  market,
  vote,
  activity,
  carnival,
  login,
  navigation,
  viewpoint,
  library,
  lang
});

export default rootReducer;
