import React from 'react';
import ReactDOM from 'react-dom';
import styles from './index.scss';

class ImageView {
  id = 'image-view';
  render({ src, alt = '' }) {
    const imageMaxHeight = window.innerHeight - 40;
    return (
      <div onClick={() => this.destory()} className={styles['image-view']}>
        <img
          style={{ maxHeight: `${imageMaxHeight}px` }}
          src={src}
          alt={alt}
          loading="lazy"
        />
      </div>
    );
  }
  destory() {
    const imageViewNode = document.getElementById(this.id);
    imageViewNode.parentNode.removeChild(imageViewNode);
  }
  show({ src, alt }) {
    const root = document.getElementsByTagName('body')[0];
    const imageViewEle = this.render({ src, alt });
    const containerEle = document.createElement('div');
    containerEle.id = this.id;
    const container = root.appendChild(containerEle);
    ReactDOM.render(imageViewEle, container);
  }
}
export default new ImageView();
