import React, { Component } from 'react';
import styles from '../../index.scss';

export class InfoShareZhcn extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>信息共享清单</h5>
        <p>为使您更清晰地了解我们有关个人信息共享情况，制定下述清单：</p>
        <h6>一、 第三方SDK名单</h6>
        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>分享</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>微信</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>为用户提供第三方登录功能，帮助用户分享内容到微信</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>深圳市腾讯计算机系统有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>分享</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>新浪</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>为用户提供第三方登录功能，帮助用户分享内容到新浪</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>北京微梦创科网络技术有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>400-096-0960</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://weibo.com/signup/v5/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://weibo.com/signup/v5/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>个推</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相关资讯内容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>每日互动股份有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>support@getui.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://docs.getui.com/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.getui.com/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>极光</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相关资讯内容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>深圳市和讯华谷信息技术有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>sales@jiguang.cn</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.jiguang.cn/license/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.jiguang.cn/license/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>华为</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相关资讯内容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>华为软件技术有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>小米</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相关资讯内容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>小米移动软件有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.mi.com/support"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.mi.com/support
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://dev.mi.com/console/doc/detail?pId=1822"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://dev.mi.com/console/doc/detail?pId=1822
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>OPPO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相关资讯内容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>广东欢太科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://brand.heytap.com/privacy-feedback.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://brand.heytap.com/privacy-feedback.html
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://open.oppomobile.com/wiki/doc#id=10288"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://open.oppomobile.com/wiki/doc#id=10288
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相关资讯内容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>维沃移动通信有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>privacy@vivo.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.vivo.com.cn/about-vivo/privacy-policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>定位</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>高德</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>为用户提供所在地资讯内容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息，及开启定位权限下的GPS信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>高德软件有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>Privacy.Amap@service.autonavi.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://lbs.amap.com/pages/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://lbs.amap.com/pages/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>一键登录</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>中国移动</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>为用户提供免验证码登录服务</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息、本机号码</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>中国移动通信集团有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>10086</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="http://dev.10086.cn/docInside?contentId=10000009826805"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  http://dev.10086.cn/docInside?contentId=10000009826805
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>分享</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>帮助用户分享ODAILY内容到QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>深圳市腾讯计算机系统有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>数据统计</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>友盟</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>数据统计分析</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>友盟同欣（北京）科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>Umeng_Legal@service.umeng.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.umeng.com/page/policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.umeng.com/page/policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>数据统计</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>华为</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>数据统计分析</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>华为软件技术有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>收集日志</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>bugly</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>收集App崩溃信息，便于我们定位问题</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>深圳市腾讯计算机系统有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能类型</p>
            </td>
            <td>
              <p>一键安装App</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名称</p>
            </td>
            <td>
              <p>openinstall</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>帮助用户实现快速跳转和一键下载并安装App</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>个人信息字段</p>
            </td>
            <td>
              <p>设备信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>处理方式</p>
            </td>
            <td>
              <p>采取去标识、加密等方式进行传输和处理</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本机采集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主体名称</p>
            </td>
            <td>
              <p>深圳市分秒网络科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>联系方式</p>
            </td>
            <td>
              <p>cooperation@openinstall.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隐私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.openinstall.io/privacy.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.openinstall.io/privacy.html
                </a>
              </p>
            </td>
          </tr>
        </table>

        <h6>二、第三方合作机构名单</h6>
        <p>
          我们暂时没有和第三方合作机构共享您的个人信息，若有上述情况，我们会更新此名单并取得您的授权同意。
        </p>

        <h6>三、关联公司名单</h6>
        <p>
          我们暂时没有和关联公司共享您的个人信息，若有上述情况，我们会更新此名单并取得您的授权同意。
        </p>
      </div>
    );
  }
}

export default InfoShareZhcn;
