import http, { passport, service } from '../scripts/http';
const querystring = require('querystring');

export function getCollection({ type = 'post', page = 1, size = 9 }, lang) {
  return http.get(`/favorites?type=${type}&per_page=${size}&page=${page}`, {
    lang
  });
}

export function removeCollect({ direction = 'minus', fid, ftype }) {
  const data = {
    direction,
    fid,
    ftype
  };
  return http.post('/favorite', data);
}

export function getAttention({ type, page = 1, size = 9 }) {
  return http.get(`/follow?ftype=${type}&page=${page}&per_page=${size}`);
}

export function followUser(userId) {
  return http.post(`/follow?ftype=user&fid=${userId}`);
}

export function unfollowUser(userId) {
  return http.delete(`/follow?ftype=user&fid=${userId}`);
}

export function followColumn(columnId) {
  return http.post(`/follow?ftype=column&fid=${columnId}`);
}

export function followSecondTag(columnId) {
  return http.post(`/follow?ftype=secondary_tag&fid=${columnId}`);
}

export function unfollowSecondTag(columnId) {
  return http.delete(`/follow?ftype=secondary_tag&fid=${columnId}`);
}

export function unfollowColumn(columnId) {
  return http.delete(`/follow?ftype=column&fid=${columnId}`);
}

export function getFans({ id, page = 1, size = 9 }) {
  return http.get(`/user/${id}/fan?page=${page}&per_page${size}`);
}

export function getMyComment({ b_id = '', size = 9 }) {
  return http.get(`/my-comments?b_id=${b_id}&per_page=${size}`);
}

export function getMyReply({ b_id = '', size = 9 }) {
  return http.get(`/my-replies?b_id=${b_id}&per_page=${size}`);
}

export function getCotalComent() {
  return passport.get(`/comments`);
}

export function getUserThird(lang) {
  return service.get(`/user-infos/third`, { lang });
}

export function unbindThird(type) {
  let data = { type };
  data = querystring.stringify(data);
  return passport.post(`/passport/v1/unbind-third`, data);
}
