exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a {\n  text-decoration: none; }\n\n.src-pages-outdated--container-index {\n  margin: 0 auto;\n  padding-top: 15%;\n  max-width: 1200px;\n  text-align: center; }\n  .src-pages-outdated--container-index .src-pages-outdated--tips-index img {\n    width: 417px;\n    height: 217px; }\n  .src-pages-outdated--container-index .src-pages-outdated--tips-index .src-pages-outdated--text-index {\n    position: relative;\n    top: -24px; }\n  .src-pages-outdated--container-index .src-pages-outdated--tips-index .src-pages-outdated--outdated-text-index {\n    position: relative;\n    color: #16499d;\n    font-size: 24px;\n    line-height: 33px; }\n  .src-pages-outdated--container-index .src-pages-outdated--tips-index .src-pages-outdated--upgrade-text-index {\n    margin-top: 4px;\n    color: #16499d;\n    font-size: 18px;\n    line-height: 25px; }\n    .src-pages-outdated--container-index .src-pages-outdated--tips-index .src-pages-outdated--upgrade-text-index a {\n      color: inherit; }\n", ""]);

// exports
exports.locals = {
	"container": "src-pages-outdated--container-index",
	"tips": "src-pages-outdated--tips-index",
	"text": "src-pages-outdated--text-index",
	"outdated-text": "src-pages-outdated--outdated-text-index",
	"upgrade-text": "src-pages-outdated--upgrade-text-index"
};