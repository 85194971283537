/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Helmet from 'react-helmet';
import styles from './index.scss';

import wechatShare from '../../common/scripts/wechat';
import { getHelmet } from '../../common/scripts/helmet';

import Layout from '../../components/common/layout';
import { TopItem } from '../../components/activity';
import { withRouter } from 'react-router-dom';
import { getTopList } from '../../common/api/columns';
const btcLogo = require('../../assets/image/8btc.png');
const bibi = require('../../assets/image/bibi.png');
const coinvoice = require('../../assets/image/coinvoice.png');
const PANews = require('../../assets/image/PANews.png');
const shilian = require('../../assets/image/shilian.png');
const bolian = require('../../assets/image/bolian.png');
const xiaocong = require('../../assets/image/xiaocong.png');
const book = require('../../assets/image/book.png');
const shenlian = require('../../assets/image/shenlian.png');
const huoxing = require('../../assets/image/huoxing.png');
const huoxun = require('../../assets/image/huoxun.png');
const lieyun = require('../../assets/image/lieyun.png');
const tanlian = require('../../assets/image/tanlian.png');
const jinse = require('../../assets/image/jinse.png');
const bushou = require('../../assets/image/bushou.png');
const tuoluo = require('../../assets/image/tuoluo.png');
const basha = require('../../assets/image/top_basha.png');
const keLogo = require('../../assets/image/top_36_logo.png');
const andLogo = require('../../assets/image/antd_logo.png');
// const topicImg = require('../../assets/image/top_topic_36.png');
// const topicPin = require('../../assets/image/topic_top_pin.png');
// const big = require('../../assets/image/activity_big.png');
// const boss = require('../../assets/image/activity_boss.jpg');
const wecode1 = require('../../assets/image/weCode1.png');
const wecode2 = require('../../assets/image/weCode2.png');
// const lvdong = require('../../assets/image/qkld.png');

const friendArr1 = [
  jinse,
  btcLogo,
  xiaocong,
  book,
  huoxing,
  tuoluo,
  shenlian,
  huoxun
];
const friendArr2 = [
  tanlian,
  bolian,
  bibi,
  PANews,
  lieyun,
  shilian,
  coinvoice,
  bushou
];
const friendArr3 = [];
const friendArr4 = [keLogo, basha, andLogo];

export class ActivityDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exchange: [],
      project: [],
      flag: true,
      users: [],
      topic: {},
      sceneList: [] //36大
    };
  }

  componentDidMount() {
    getTopList().then(res => {
      this.setState({
        sceneList: res.data.scene_list
      });
    });
    wechatShare({});
  }

  renderFriend1() {
    return friendArr1.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderFriend4() {
    return friendArr4.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderFriend2() {
    return friendArr2.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderFriend3() {
    return friendArr3.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderSceneList() {
    return this.state.sceneList.map((item, index) => {
      return <TopItem key={index} {...item} num={index} />;
    });
  }
  render() {
    let helmetData = getHelmet({});
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.bg} />
          <div className={styles.wrapper}>
            <p className={styles.tab}>
              <span style={{ color: '#ffff' }}>榜单 ＞</span>{' '}
              X大公司区块链最佳落地场景
            </p>
            <div className={styles.box}>
              <p className={styles.title}>
                <span>X 大公司</span> 区块链最佳落地场景
              </p>
              {this.renderSceneList()}
              <p className={styles.btn}>
                <a href="/activityed">返回榜单</a>
              </p>
            </div>
          </div>
          {/*footer*/}
          <div className={styles.footer}>
            <div className={styles.footerBox}>
              <div className={styles.footerLeft}>
                <div className={styles.friendLeft}>
                  <h4>合作伙伴</h4>
                  <div className={styles.friendItem}>
                    {this.renderFriend4()}
                  </div>
                </div>
                <div className={styles.friendRight}>
                  <h4>合作媒体</h4>
                  <div className={styles.friendItem}>
                    {this.renderFriend1()}
                    {this.renderFriend2()}
                    {this.renderFriend3()}
                  </div>
                </div>
              </div>
              <div className={styles.footerRight}>
                <h4>商务合作</h4>
                <p>联系方式：18515575360</p>
                <p>联系方式：13811513856</p>
                <img src={wecode1} alt="" loading="lazy" />
                <img src={wecode2} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default withRouter(ActivityDetail);
