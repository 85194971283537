import { service } from './http';
const k = 'odailyc86jh20k8x';
import * as CryptoJS from 'crypto-js'; // aes加密

export function dot(obj) {
  const { id, user_id, extra } = obj;
  let time = new Date().getTime().toString();
  let key = CryptoJS.enc.Utf8.parse(k);
  let cypher = CryptoJS.AES.encrypt(time, key, { mode: CryptoJS.mode.ECB });
  let token = CryptoJS.enc.Base64.stringify(cypher.ciphertext);
  service.post('/pv_log', { id, user_id, token, extra });
}

// id,user_id(登录就传，没登录就不用传),token(加密时间戳),extra(附加信息)
// id
// 26  => '首页资讯',
// 1  => '首页数据',
// 2  => '首页活动',
// 3  => '首页更多',
// 4  => '首页资讯-快讯',
// 5  => '首页资讯-专题',
// 6  => '首页资讯-视频',
// 7  => '首页数据-行情',
// 8  => '首页数据-DeFi',
// 9  => '首页活动-加密艺术展',
// 10 => '首页更多-Fat榜单',
// 11 => '首页更多-产业区块链',
// 12 => '首页更多-星球研究院',
// 13 => '申请入驻',
// 14 => '更多-行情',
// 15 => '更多-快讯',
// 16 => '更多-活动',
// 17 => '更多-DeFi',
// 18 => '更多-专题',
// 19 => '更多-视频',
// 20 => '星球精选',
// 21 => 'Banner',
// 22 => '热门文章',
// 23 => '频道',//附加频道id  feed_id
// 24 => '信息流内',//附加内容type   entity_type
// 25 => '搜索词列表'//附加搜索内容 search_content
// 27 => '首页精选活动'
// 28 => '首页更多-精选活动'
// 29 => '活动页各分组查看更多'//附加分组id group_id
// 30 => '更多活动页各分组点击数'//附加分组id group_id
// 31 => '活动详情页推荐阅读—查看更多'
// 32 => '快讯列表自动更新开关'
// 33 => '快讯列表页'
