import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import locales from '../../utils/locales';

import styles from './index.scss';
import Layout from '../../components/common/layout';

export class NotFound extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      time: 5
    };
  }

  componentDidMount() {
    const { lang } = this.props.match.params;
    const postHref = lang ? `/${lang}/` : `/`;
    this.changeTime(postHref);
  }

  changeTime(url) {
    let { time } = this.state;
    setTimeout(() => {
      time = time - 1;
      if (time <= 0) {
        location.replace(url);
      } else {
        this.changeTime(url);
        this.setState({ time });
      }
    }, 1000);
  }

  render() {
    const { lang } = this.props.match.params;
    const { time } = this.state;
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.container_wrapper}>
            <div className={styles.error_left} />
            <div className={styles.error_right}>
              <div className={styles.error_right_img}>404</div>
              <div className={styles.error_right_title}>
                {locales(lang, 'errText1')}
              </div>
              <div className={styles.error_right_text}>
                {locales(lang, 'errText2')} <span>{time}s</span>{' '}
                {locales(lang, 'errText3')}
                <a href="/index"> {locales(lang, 'home')}</a>
              </div>
              <div className={styles.error_button_list}>
                <a href="/index" className={styles.error_button}>
                  {locales(lang, 'backHome')}
                </a>
                <a
                  href="javascript:history.back(-1)"
                  className={styles.error_button}
                >
                  {locales(lang, 'backPrev')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(connect()(NotFound));
