import React from 'react';
import ReactDOM from 'react-dom';
import styles from './index.scss';

const ICON_DONE =
  'https://piccdn.0daily.com/202212/21110756/5k1lzaaxf7ubhnuy.png';
const ICON_ERROR =
  'https://piccdn.0daily.com/202212/21110800/8563atetk6j6ioyo.png';

class Toast {
  defaultTime = 1500;
  renderDone(msg) {
    return (
      <div className={styles.toast}>
        <span className={styles.icon}>
          <img src={ICON_DONE} alt="完成" loading="lazy" />
        </span>
        <p className={styles.tips}>{msg}</p>
      </div>
    );
  }
  renderError(msg) {
    return (
      <div className={styles.toast_error}>
        <span className={styles.icon}>
          <img src={ICON_ERROR} alt="错误" loading="lazy" />
        </span>
        <p className={styles.tips_error}>{msg}</p>
      </div>
    );
  }
  clearToastTimmer(time, toastId) {
    const toastNode = document.getElementById(toastId);
    const removeNodeTimmer = setTimeout(() => {
      toastNode.parentNode.removeChild(toastNode);
      clearTimeout(removeNodeTimmer);
    }, time || this.defaultTime);
  }
  renderToast({ toastElement, closeTime }) {
    const root = document.getElementsByTagName('body')[0];
    const toastWrapperNode = document.createElement('div');
    const time = new Date().getTime();
    const toastId = `toast-${time}`;
    toastWrapperNode.id = toastId;
    ReactDOM.render(toastElement, toastWrapperNode);
    root.appendChild(toastWrapperNode);
    this.clearToastTimmer(closeTime, toastId);
  }
  error({ msg, closeTime }) {
    this.renderToast({
      toastElement: this.renderError(msg),
      closeTime
    });
  }
  done({ msg, closeTime }) {
    this.renderToast({
      toastElement: this.renderDone(msg),
      closeTime
    });
  }
}

export default new Toast();
