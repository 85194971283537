import React from 'react';
export const ABOUT_LIST = [
  {
    title: 'aboutUs',
    hash: '?us'
  },
  {
    title: 'contactUs',
    hash: '?contact'
  },
  {
    title: 'joinUs',
    hash: '?join'
  },
  {
    title: 'privacyPolicy',
    hash: '?privacy'
  },
  {
    title: 'userAgreement',
    hash: '?agreement'
  },
  {
    title: 'links',
    hash: '?friend'
  },
  {
    title: 'disclaimer',
    hash: '?disclaimer'
  }
];
export const aboutUsData = {
  en: [
    'ODAILY is committed to cutting into the content platform and becoming a blockchain industry chain resource integration service provider. Founded in early 2018 by 36Kr incubation, comprehensive coverage of news, news, depth, interpretation analysis, industry reports and other forms of content.',
    'ODAILY comprehensively covers websites, apps, public accounts, mainstream technology media, financial media and other channels, and operates over 100 highly active communities. It has long been reprinted by mainstream media such as 36Kr, Tencent Technology, and Tiger Sniff, and has released more than 5,000 professional original articles related to blockchain so far, with a total exposure of more than 300 million people across the network.',
    'At the same time, ODAILY held dozens of technical seminar salons, industry summits, resource matchmaking meetings, innovation and entrepreneurship competitions, attracting many industry leaders at home and abroad to gather, with a total of nearly 10,000 participants, and on-site and post-dissemination reached 500w+ people, such as the cooperation innovation contest with Ant Financial, and the Blockchain Forum of the Expo.'
  ],
  zhcn: [
    'ODAILY，致力于从内容平台切入，成为区块链产业链资源整合服务者。于2018年初由36Kr孵化成立，全面覆盖新闻、快讯、深度、解读分析、行业报告等内容形式。',
    'ODAILY全面覆盖网站、APP、公众号、主流科技媒体、财经媒体等渠道，并运营过百个高活跃度社群。长期被36Kr、腾讯科技、虎嗅等主流媒体转载，截止目前已发布5000',
    '同时，ODAILY举办了数十场技术研讨沙龙、行业大型峰会、资源对接会、创新创业大赛，吸引海内外众多行业领军者齐聚，参会总人次近1万，现场及后期传播触达500w+人次，如与蚂蚁金服合作创新大赛，与信通院合办数博会区块链论坛等。'
  ],
  zhtw: [
    'ODAILY，致力於從內容平臺切入，成為區塊鏈產業鏈資源整合服務者。 於2018年初由36Kr孵化成立，全面覆蓋新聞、快訊、深度、解讀分析、行業報告等內容形式。',
    'ODAILY全面覆蓋網站、APP、公眾號、主流科技媒體、財經媒體等通路，並運營過百個高活躍度社群。 長期被36Kr、騰訊科技、虎嗅等主流媒體轉載，截止目前已發佈5000+篇區塊鏈相關專業原創文章，全網曝光總量超過3億人次。',
    '同时，ODAILY举办了数十场技术研讨沙龙、行业大型峰会、资源对接会、创新创业大赛，吸引海内外众多行业领军者齐聚，参会总人次近1万，现场及后期传播触达500w+人次，如与蚂蚁金服合作创新大赛，与信通院合办数博会区块链论坛等。'
  ],
  ja: [
    'オーデイリーは、ブロックチェーン産業チェーンのリソース統合サービスとして、コンテンツプラットフォームからのアプローチに注力しています。2018年初頭に36Krが設立したもので、ニュース、速報、深読み、分析、業界レポートなどを網羅しています。',
    'ODAILYはウェブサイト、アプリ、公式アカウント、主要テクノロジーメディア、経済メディアなどを網羅しており、100以上の活発度の高いコミュニティを運営しています。長期的に36Kr、騰訊科技、虎嗅などの主流メディアに転載されており、これまでに5000件以上のブロックチェーン関連のオリジナル記事が掲載され、ネット全体の露出量は延べ3億人を超えています。以上翻译结果来自有道神经网络翻译（YNMT）· 通用场景',
    'また、ODAILYは数十回の技術セミナーサロン、業界大型サミット、資源交流会、革新・創業コンテストを開催し、国内外の多くの業界のリーダーが一堂に会しました。延べ人数は約1万人で、現場と後の伝播人数は延べ500w以上に達しました。'
  ],
  ko: [
    'odaily (오데일리)는 콘텐츠 플랫폼 (blockchain industrial chain)에서 블록 체인 자원 통합 서비스로의 진입을 목표로하고 있다.2018년 초 36kr에 의해 설립되었으며 뉴스, 속보, 심층, 해석 및 분석, 산업 리포트 등의 내용을 포괄하고 있습니다.',
    'odaily는 웹사이트, app, 공중계정, 주류 과학기술미디어, 재정경제미디어 등 채널을 포괄하고 있으며 활동도가 높은 백여개의 커뮤니티를 운영하고 있다.36kr, 텐센트 테크놀러지, 후오 (胡虎) 등 주류 미디어에 의해 장기간 전재되었으며 지금까지 5000 편 + 블록체인관련 전문 원문 글을 발표했고 전체 사이트의 노출자 수는 3억 명을 넘어섰다.',
    '동시에 odaily 수십 차례 기술 검토 살롱, 업종 대형 정상회의, 자원 DuiJieHui, 혁신 창업 대회 유치 국내 외의 많은 업종의 주도자들이 참석 총 1만 명 가까이 현장 및 후기 전파에 닿 500w + 명처럼 개미 골드 복과 협력 혁신 대회, 정통 원과 합자 경영 수 박람회 구역 별 사슬 포럼 등이 있다.'
  ],
  th: [
    'ODAILY มุ่งมั่น ที่จะเข้ามาจากแพลตฟอร์มเนื้อหา และกลายเป็นผู้ให้บริการบูรณาการของบล็อกเชนเมื่อต้นปีค.ศ. 2018 ได้รับการจัดตั้งจากการฟักตัว 36 กิโลกรัม ซึ่งครอบคลุมทั้งข่าวด่วนความลึกการวิเคราะห์การตีความรายงานอุตสาหกรรม และอื่น ๆ',
    'ODAILY ครอบคลุมเว็บไซต์แอพหมายเลขสาธารณะสื่อเทคโนโลยีกระแสหลักสื่อการเงิน และอื่น ๆ และได้ดำเนินการโซเชี่ยลมีเดีย ที่มีชีวิตชีวามากมายถูกตีพิมพ์เป็นเวลานานโดยสื่อกระแสหลัก เช่น 36 Kr , เทคโนโลยี, พยัคฆ์เสือ และการดมกลิ่นของพวกเขาในขณะนี้ มีการเผยแพร่บทความมืออาชีพ ที่เกี่ยวข้องกับบล็อกโซ่ 5000 + บทความมืออาชีพ ที่ได้รับการเปิดเผยอย่างเต็ม ที่มากกว่า 300 ล้านคน',
    'นอกจากนี้ นาย ODAILY ยังเป็นเจ้าภาพจัดงานสัมมนาด้านเทคโนโลยีหลายสิบครั้งการประชุมสุดยอดอุตสาหกรรมการประชุมสุดยอดด้านทรัพยากรการประกวดนวัตกรรมการประชุมสุดยอดด้านทรัพยากร และการประกอบกิจการเชิงนวัตกรรม ซึ่งดึงดูดผู้นำอุตสาหกรรม และอุตสาหกรรมจำนวนมากทั้งใน และต่างประเทศรวมตัวกันมีทั้งระบบสัมผัสถึง 500 วัตต์ + จำนวนผู้ประกอบการร่วมมือกับชุดมด และมดในภายหลังการประกวดนวัตกรรม และการประชุมเชิงปฏิบัติการ blockchain กับสถาบันการสื่อสาร'
  ],
  vi: [
    'ODAILY, CAM kết từ nền tảng nội dung, trở thành một dịch vụ tích hợp các nguồn lực của blockchain. Được thành lập vào đầu năm 2018 bởi 36Kr hatching, bao gồm đầy đủ các tin tức, tin tức nhanh, chiều sâu, giải thích phân tích, báo cáo công nghiệp và các hình thức nội dung khác.',
    'ODAILY cung cấp đầy đủ trang web, ứng dụng, số công cộng, truyền thông công nghệ chính, tài chính và các kênh khác, và hoạt động trên hàng trăm cộng đồng hoạt động cao. Được lưu lại bởi 36Kr, tencent công nghệ, tiger và các phương tiện truyền thông chính thống, cho đến nay đã phát hành 5000+ bài viết về lĩnh vực chain liên quan đến các chuyên gia ban đầu, tổng số tiếp xúc trực tuyến của hơn 300 triệu người.',
    'Cùng lúc đó, ODAILY tổ chức tại hội nghị thượng đỉnh về hàng tá cuộc chiến lớn áp-sa-lôm và ngành công nghiệp về công nghệ, tài nguyên DuiJieHui, cuộc thi khởi nghiệp sáng tạo, thu hút nhiều người dân ma-đi-an tề tựu trong ngành công nghiệp thân thương, cuộc họp luôn lượt gần 1 triệu trong, chạm hiện trường và sau đó LAN truyền, coletta. đã 500w + lượt, như hợp tác với bộ đồ con kiến Kim cuộc thi sáng tạo, với XinTong viện GeBan đếm nó các BoHui với diễn đàn trên chuỗi chờ.'
  ]
};
export const corporate = {
  en: 'Hainan Moti Cultural media Co., LTD',
  zhcn: '海南省莫蒂文化传媒有限责任公司',
  zhtw: '海南省莫蒂文化傳媒有限責任公司',
  ja: '海南省莫迪文化伝媒有限責任公司です',
  ko: '하이난 모티 문화 미디어 유한주식회사',
  th:
    'ในมณฑลไหปลายเมืองมณฑลไหปลายรัฐบาลเมืองมณฑลไหปลีย์ บริษัท รับผิดชอบ จำกัด',
  vi: 'Công ty TNHH công ty truyền thông văn hóa morty, tỉnh hải nam'
};

export const disclaimerData = {
  en: [
    'As a blockchain content platform, the information provided on this site does not constitute any investment advice.',
    'The articles published on this site only represent personal views and have nothing to do with the official position of ODAILY. Please treat them with caution.'
  ],
  zhcn: [
    '作为区块链内容平台，本站所提供的资讯信息不构成任何投资建议。',
    '本站所发布文章仅代表个人观点，与ODAILY官方立场无关，请广大读者用户谨慎对待。'
  ],
  zhtw: [
    '作為區塊鏈內容平臺，本站所提供的資訊資訊不構成任何投資建議。',
    '本站所發佈文章僅代表個人觀點，與ODAILY官方立場無關，請廣大讀者用戶謹慎對待。'
  ],
  ja: [
    'ブロックチェーンのコンテンツプラットフォームとして、当サイトが提供する情報は投資アドバイスにはなりません。',
    '本記事はあくまでも箇人の意見であり、ODAILYの公式立場とは関系ありません。'
  ],
  ko: [
    '블록 체인 콘텐츠 플랫폼으로서, 본 사이트가 제공하는 정보는 어떠한 투자 제안도 구성하지 않습니다.',
    '본 사이트에서 발표한 문장은 단지 개인의 관점을 대표하며 오데일리의 공식 입장과는 무관하므로, 많은 독자들과 사용들께서 신중하게 대하시기 바랍니다.'
  ],
  th: [
    'ในฐานะ ที่เป็นแพลตฟอร์มเนื้อหาบล็อกเชนข้อมูล ที่เว็บไซต์ให้ไว้ไม่ได้ทำให้คำแนะนำการลงทุนใด ๆ',
    'บทความ ที่โพสต์ในเว็บไซต์เป็นตัวแทนของมุมมองส่วนบุคคลเท่านั้น และไม่มีส่วนเกี่ยวข้องกับสถานะอย่างเป็นทางการของ ODAILY โปรดระมัดระวังผู้อ่านส่วนใหญ่'
  ],
  vi: [
    'Là một nền tảng nội dung blockchain, thông tin mà trang web này cung cấp không tạo nên bất kỳ đề nghị đầu tư.',
    'Các bài báo trên trang web này chỉ đại diện cho quan điểm cá nhân, không có gì liên quan đến quan điểm chính thức của ODAILY, hãy cẩn thận đối xử với nhiều độc giả.'
  ]
};

export const aboutJoin = {
  en: function JoinEn(title, styles) {
    return (
      <div className={styles.content}>
        <h5>{title}</h5>
        <div className={styles.join}>
          <div className={styles.join_title}>Web3.0/Crypto Author/Analyst</div>
          <p className={styles.join_text}>Job description</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Dig deep into industry information, discover new projects,
              topics and trends, and output content in line with Odaily&#39;s
              brand tone;
            </li>
            <li className={styles.join_item}>
              2. Cooperate with and support other content tasks of parallel
              departments.
            </li>
          </ul>
          <p className={styles.join_text}>you will get</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. The opportunity to grow together with the industry with the
              fastest evolution and high-frequency innovation;
            </li>
            <li className={styles.join_item}>
              2. Good working environment and atmosphere;
            </li>
            <li className={styles.join_item}>
              3. Go deep into an industry, gain insights into the nature of
              business, and improve your underlying capabilities in stages;
            </li>
          </ul>
          <p className={styles.join_text}>hope you are</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Have a certain basic understanding of Web3.0/Crypto, and have
              certain insights into a certain vertical field (such as DeFi,
              NFT);
            </li>
            <li className={styles.join_item}>
              2. Self-driven, full of curiosity to understand and analyze new
              things, and complete work goals efficiently and on time;
            </li>
            <li className={styles.join_item}>
              3. Proficient in English reading and writing, love to express,
              have a good sense of language in writing, logical, clear and
              rigorous;
            </li>
            <li className={styles.join_item}>
              4. Be good at communication, be able to grow rapidly from
              communication, and express fluently to interviewees and industry
              contacts.
            </li>
          </ul>
          <p className={styles.join_text}>bonus</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Certain data analysis ability and relevant experience;
            </li>
            <li className={styles.join_item}>
              2. Media, finance, or Crypto industry experience, network
              resources;
            </li>
            <li className={styles.join_item}>
              3. Familiar with overseas social media such as Twitter, and
              actively output opinions/speeches.
            </li>
          </ul>
        </div>
      </div>
    );
  },
  zh: function JoinZh(title, styles) {
    return (
      <div className={styles.content}>
        <h5>{title}</h5>
        <div className={styles.join}>
          <div className={styles.join_title}>Web3.0/Crypto领域作者/分析师</div>
          <p className={styles.join_text}>岗位描述</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1.
              深入挖掘行业信息，发现新项目、选题与趋势，输出符合星球日报品牌调性的内容；
            </li>
            <li className={styles.join_item}>
              2. 配合并支持平行部门的其他内容任务。
            </li>
          </ul>
          <p className={styles.join_text}>你将获得</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 与最高速进化、高频创新的行业共同成长的机遇；
            </li>
            <li className={styles.join_item}>2. 良好的工作环境和工作氛围；</li>
            <li className={styles.join_item}>
              3. 深入一个行业、洞察商业本质、分阶段提升自我的底层能力；
            </li>
          </ul>
          <p className={styles.join_text}>希望你是</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1.
              对Web3.0/Crypto有一定的基础理解，对某垂直领域（如DeFi、NFT）有一定见解；
            </li>
            <li className={styles.join_item}>
              2. 有自驱力，对了解、剖析新事物充满好奇心，高效按时完成工作目标；
            </li>
            <li className={styles.join_item}>
              3.
              英文读写过硬热爱表达，行文语感好，逻辑通顺，条理清晰，态度严谨；
            </li>
            <li className={styles.join_item}>
              4. 善于沟通，能够从交流中快速成长，对采访对象及行业人脉流畅表达。
            </li>
          </ul>
          <p className={styles.join_text}>加分项</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 一定的数据分析能力及相关经验；
            </li>
            <li className={styles.join_item}>
              2. 媒体、金融、或Crypto行业从业经历，人脉资源；
            </li>
            <li className={styles.join_item}>
              3. 熟悉Twitter等海外社交媒体，积极输出观点/言论。
            </li>
          </ul>
        </div>
      </div>
    );
  },
  zhtw: function JoinZhtw(title, styles) {
    return (
      <div className={styles.content}>
        <h5>{title}</h5>
        <div className={styles.join}>
          <div className={styles.join_title}>Web3.0/Crypto領域作者/分析師</div>
          <p className={styles.join_text}>崗位描述</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1.
              深入挖掘行業信息，發現新項目、選題與趨勢，輸出符合Odaily品牌調性的內容；
            </li>
            <li className={styles.join_item}>
              2. 配合併支持平行部門的其他內容任務。
            </li>
          </ul>
          <p className={styles.join_text}>你將獲得</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 與最高速進化、高頻創新的行業共同成長的機遇；
            </li>
            <li className={styles.join_item}>2. 良好的工作環境和工作氛圍；</li>
            <li className={styles.join_item}>
              3. 深入一個行業、洞察商業本質、分階段提升自我的底層能力；
            </li>
          </ul>
          <p className={styles.join_text}>希望你是</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1.
              對Web3.0/Crypto有一定的基礎理解，對某垂直領域（如DeFi、NFT）有一定見解；
            </li>
            <li className={styles.join_item}>
              2. 有自驅力，對了解、剖析新事物充滿好奇心，高效按時完成工作目標；
            </li>
            <li className={styles.join_item}>
              3.
              英文讀寫過硬熱愛表達，行文語感好，邏輯通順，條理清晰，態度嚴謹；
            </li>
            <li className={styles.join_item}>
              4. 善於溝通，能夠從交流中快速成長，對採訪對象及行業人脈流暢表達。
            </li>
          </ul>
          <p className={styles.join_text}>加分項</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 一定的數據分析能力及相關經驗；
            </li>
            <li className={styles.join_item}>
              2. 媒體、金融、或Crypto行業從業經歷，人脈資源；
            </li>
            <li className={styles.join_item}>
              3. 熟悉Twitter等海外社交媒體，積極輸出觀點/言論。
            </li>
          </ul>
        </div>
      </div>
    );
  },
  ja: function JoinJa(title, styles) {
    return (
      <div className={styles.content}>
        <h5>{title}</h5>
        <div className={styles.join}>
          <div className={styles.join_title}>Web3.0/暗号著者/アナリスト</div>
          <p className={styles.join_text}>仕事内容</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1.
              業界情報を深く掘り下げ、新しいプロジェクト、トピック、トレンドを発見し、Odaily
              のブランドトーンに沿ったコンテンツを出力します。
            </li>
            <li className={styles.join_item}>
              2. 並行部門の他のコンテンツタスクと協力し、サポートします。
            </li>
          </ul>
          <p className={styles.join_text}>あなたは得るでしょう</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1.
              最速の進化と高頻度のイノベーションにより、業界とともに成長する機会。
            </li>
            <li className={styles.join_item}>2. 良好な労働環境と雰囲気。</li>
            <li className={styles.join_item}>
              3.
              業界に深く入り込み、ビジネスの本質についての洞察を獲得し、基礎的な能力を段階的に向上させます。
            </li>
          </ul>
          <p className={styles.join_text}>
            あなたがそうであることを願っています
          </p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Web3.0/Crypto について一定の基本的な理解を持ち、特定の垂直分野
              (DeFi、NFT など) について一定の洞察を持っています。
            </li>
            <li className={styles.join_item}>
              2.
              自発的で、新しいことを理解して分析する好奇心に満ちており、仕事の目標を効率的かつ時間通りに完了します。
            </li>
            <li className={styles.join_item}>
              3.
              英語の読み書きに堪能で、表現することが好きで、文章の言語センスが良く、論理的で明確かつ厳格である。
            </li>
            <li className={styles.join_item}>
              4.
              コミュニケーションが上手で、コミュニケーションを通じて急速に成長し、面接対象者や業界関係者に対して流暢に表現できること。
            </li>
          </ul>
          <p className={styles.join_text}>ボーナス</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 一定のデータ分析能力と関連経験。
            </li>
            <li className={styles.join_item}>
              2. メディア、金融、または暗号業界の経験、ネットワークリソース。
            </li>
            <li className={styles.join_item}>
              3.
              Twitterなどの海外ソーシャルメディアに精通しており、積極的に意見・発言を発信している。
            </li>
          </ul>
        </div>
      </div>
    );
  },
  ko: function JoinKo(title, styles) {
    return (
      <div className={styles.content}>
        <h5>{title}</h5>
        <div className={styles.join}>
          <div className={styles.join_title}>Web3.0/Crypto 작성자/분석가</div>
          <p className={styles.join_text}>업무 설명서</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 업계 정보를 깊이 파고들어 새로운 프로젝트, 주제 및 트렌드를
              발굴하고 오데일리의 브랜드 톤에 맞는 콘텐츠를 출력합니다.
            </li>
            <li className={styles.join_item}>
              2. 병행부서의 기타 콘텐츠 업무와 협력 및 지원한다.
            </li>
          </ul>
          <p className={styles.join_text}>당신은 얻을 것이다</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 가장 빠른 진화와 고주파 혁신으로 업계와 함께 성장할 수 있는
              기회
            </li>
            <li className={styles.join_item}>2. 좋은 작업 환경과 분위기;</li>
            <li className={styles.join_item}>
              3. 산업에 깊이 들어가 비즈니스의 본질에 대한 통찰력을 얻고 기본
              역량을 단계적으로 향상시킵니다.
            </li>
          </ul>
          <p className={styles.join_text}>당신이 희망</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Web3.0/Crypto에 대한 기본적인 이해가 있고 특정 수직 분야(예:
              DeFi, NFT)에 대한 통찰력이 있습니다.
            </li>
            <li className={styles.join_item}>
              2. 자기주도적, 호기심이 왕성하여 새로운 것을 이해하고 분석하며
              업무 목표를 효율적이고 제시간에 완수한다.
            </li>
            <li className={styles.join_item}>
              3. 영어 읽기 및 쓰기에 능숙하고 표현하기를 좋아하며 작문 언어
              감각이 뛰어나고 논리적이고 명확하며 엄격합니다.
            </li>
            <li className={styles.join_item}>
              4. 의사 소통을 잘하고 의사 소통을 통해 빠르게 성장할 수 있으며
              인터뷰 대상자 및 업계 연락처에 유창하게 표현할 수 있습니다.
            </li>
          </ul>
          <p className={styles.join_text}>보너스</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. 특정 데이터 분석 능력 및 관련 경험
            </li>
            <li className={styles.join_item}>
              2. 미디어, 금융 또는 암호화 산업 경험, 네트워크 리소스
            </li>
            <li className={styles.join_item}>
              3. 트위터 등 해외 SNS에 익숙하고, 적극적으로 의견/발언을 한다.
            </li>
          </ul>
        </div>
      </div>
    );
  },
  th: function JoinTh(title, styles) {
    return (
      <div className={styles.content}>
        <h5>{title}</h5>
        <div className={styles.join}>
          <div className={styles.join_title}>
            Web3.0/ผู้เขียน Crypto/นักวิเคราะห์
          </div>
          <p className={styles.join_text}>รายละเอียดงาน</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. เจาะลึกข้อมูลอุตสาหกรรม ค้นพบโครงการใหม่ หัวข้อและแนวโน้ม
              และนำเสนอเนื้อหาที่สอดคล้องกับแบรนด์ของ Odaily
            </li>
            <li className={styles.join_item}>
              2. ร่วมมือและสนับสนุนงานเนื้อหาอื่น ๆ ของแผนกคู่ขนาน
            </li>
          </ul>
          <p className={styles.join_text}>คุณจะได้รับ</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1.
              โอกาสในการเติบโตไปพร้อมกับอุตสาหกรรมด้วยวิวัฒนาการที่เร็วที่สุดและนวัตกรรมความถี่สูง
            </li>
            <li className={styles.join_item}>
              2. สภาพแวดล้อมและบรรยากาศในการทำงานที่ดี
            </li>
            <li className={styles.join_item}>
              3. เจาะลึกเข้าไปในอุตสาหกรรม
              รับข้อมูลเชิงลึกเกี่ยวกับธรรมชาติของธุรกิจ
              และปรับปรุงความสามารถพื้นฐานของคุณในแต่ละขั้นตอน
            </li>
          </ul>
          <p className={styles.join_text}>หวังว่าคุณจะเป็น</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. มีความเข้าใจพื้นฐานบางอย่างเกี่ยวกับ Web3.0/Crypto
              และมีข้อมูลเชิงลึกบางอย่างเกี่ยวกับฟิลด์แนวตั้ง (เช่น DeFi, NFT)
            </li>
            <li className={styles.join_item}>
              2. ขับเคลื่อนตัวเอง
              เต็มไปด้วยความอยากรู้อยากเห็นเพื่อทำความเข้าใจและวิเคราะห์สิ่งใหม่ๆ
              และบรรลุเป้าหมายในการทำงานอย่างมีประสิทธิภาพและตรงเวลา
            </li>
            <li className={styles.join_item}>
              3. เชี่ยวชาญการอ่านเขียนภาษาอังกฤษ รักการแสดงออก
              มีไหวพริบในการใช้ภาษาที่ดี มีเหตุผล ชัดเจน และเคร่งครัด
            </li>
            <li className={styles.join_item}>
              4. เก่งเรื่องการสื่อสาร เติบโตอย่างรวดเร็วจากการสื่อสาร
              และแสดงออกอย่างคล่องแคล่วต่อผู้ให้สัมภาษณ์และผู้ติดต่อในอุตสาหกรรม
            </li>
          </ul>
          <p className={styles.join_text}>โบนัส</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. ความสามารถในการวิเคราะห์ข้อมูลและประสบการณ์ที่เกี่ยวข้อง
            </li>
            <li className={styles.join_item}>
              2. สื่อ การเงิน หรือประสบการณ์ในอุตสาหกรรม Crypto
              ทรัพยากรเครือข่าย
            </li>
            <li className={styles.join_item}>
              3. คุ้นเคยกับโซเชียลมีเดียในต่างประเทศ เช่น Twitter
              และแสดงความคิดเห็น/สุนทรพจน์อย่างแข็งขัน
            </li>
          </ul>
        </div>
      </div>
    );
  },
  vi: function JoinVi(title, styles) {
    return (
      <div className={styles.content}>
        <h5>{title}</h5>
        <div className={styles.join}>
          <div className={styles.join_title}>
            Web3.0/Tác giả/Nhà phân tích tiền điện tử
          </div>
          <p className={styles.join_text}>Mô tả công việc</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Tìm hiểu sâu thông tin về ngành, khám phá các dự án, chủ đề và
              xu hướng mới, đồng thời xuất ra nội dung phù hợp với tông màu
              thương hiệu của Odaily;
            </li>
            <li className={styles.join_item}>
              2. Phối hợp và hỗ trợ các công việc nội dung khác của các bộ phận
              song song.
            </li>
          </ul>
          <p className={styles.join_text}>bạn sẽ nhận được</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Cơ hội phát triển cùng với ngành có tốc độ phát triển nhanh
              nhất và đổi mới với tần suất cao;
            </li>
            <li className={styles.join_item}>
              2. Môi trường và bầu không khí làm việc tốt;
            </li>
            <li className={styles.join_item}>
              3. Đi sâu vào một ngành, hiểu sâu hơn về bản chất của hoạt động
              kinh doanh và nâng cao năng lực tiềm ẩn của bạn theo từng giai
              đoạn;
            </li>
          </ul>
          <p className={styles.join_text}>hy vọng bạn là</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Có hiểu biết cơ bản nhất định về Web3.0/Crypto và có hiểu biết
              nhất định về một lĩnh vực dọc nhất định (chẳng hạn như DeFi, NFT);
            </li>
            <li className={styles.join_item}>
              2. Tự chủ, ham hiểu biết và phân tích cái mới, hoàn thành mục tiêu
              công việc hiệu quả và đúng thời hạn;
            </li>
            <li className={styles.join_item}>
              3. Tiếng Anh đọc và viết thành thạo, thích diễn đạt, có cảm quan
              tốt về ngôn ngữ viết, logic, rõ ràng và chặt chẽ;
            </li>
            <li className={styles.join_item}>
              4. Giao tiếp tốt, có thể phát triển nhanh chóng từ giao tiếp và
              diễn đạt lưu loát với người được phỏng vấn và những người liên hệ
              trong ngành.
            </li>
          </ul>
          <p className={styles.join_text}>thưởng</p>
          <ul className={styles.join_list}>
            <li className={styles.join_item}>
              1. Khả năng phân tích dữ liệu nhất định và kinh nghiệm liên quan;
            </li>
            <li className={styles.join_item}>
              2. Kinh nghiệm về truyền thông, tài chính hoặc ngành tiền điện tử,
              tài nguyên mạng;
            </li>
            <li className={styles.join_item}>
              3. Quen thuộc với các phương tiện truyền thông xã hội ở nước ngoài
              như Twitter và tích cực đưa ra ý kiến/bài phát biểu.
            </li>
          </ul>
        </div>
      </div>
    );
  }
};

export function pageLocales(lang, data) {
  const lang_tmp = lang || 'zhcn';
  let pageData = data[lang_tmp] ? data[lang_tmp] : data['zhcn'];
  return pageData;
}
