import React from 'react';
import Helmet from 'react-helmet';
import { hot, setConfig } from 'react-hot-loader';
import routes from './routes';
import * as metadata from './metadata';
import SearchGlobal from './components/search/global';

const bodyAttributes = {
  itemtype: 'http://schema.org/WebPage'
};

const App = () => (
  <React.Fragment>
    <Helmet
      title={metadata.title}
      meta={metadata.meta}
      link={metadata.link}
      script={metadata.script}
      noscript={metadata.noscript}
      bodyAttributes={bodyAttributes}
      // titleTemplate="%s-ODAILY"
    />
    {routes}
    <SearchGlobal />
  </React.Fragment>
);

setConfig({ logLevel: 'debug' });

export default hot(module)(App);
