import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadMore from '../../components/common/homeloadmore';
import styles from './index.scss';
import Layout from '../../components/common/layout';
import Helmet from 'react-helmet';
import { getPoticList } from '../../../src/common/api/found';
import wechatShare from '../../common/scripts/wechat';
import locales from '../../utils/locales';
import { getHelmet, helment_locale } from '../../common/scripts/helmet';
import PropTypes from 'prop-types';

export class TopicList extends React.Component {
  static propTypes = {
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      page: 1,
      topicList: [],
      total: 0
    };
  }
  componentDidMount() {
    getPoticList(this.state.page).then(data => {
      this.setState({
        topicList: data.data.items,
        total: data.data.total_count,
        page: this.state.page + 1
      });
    });

    wechatShare({});
  }
  renderTopic() {
    const { topicList } = this.state;
    return topicList.map((item, index) => {
      return (
        <a
          className={styles.item_wrapper}
          href={`/topic/${item.id}`}
          target={'_blank'}
          rel="noreferrer"
          key={`list${index}`}
        >
          <div className={styles.item}>
            <div className={styles.item_img}>
              <img
                src={item.template_info.template_cover[0]}
                alt={item.title}
                loading="lazy"
              />
            </div>

            <div className={styles.item_size}>
              <p style={{ WebkitBoxOrient: 'vertical' }}>{item.title}</p>
            </div>
          </div>
        </a>
      );
    });
  }
  handleLoadMore() {
    const { topicList, total, page } = this.state;
    this.setState({
      loading: true
    });
    let num = page;
    if (num >= Math.ceil(total / 9)) {
      this.setState({
        loading: true,
        loaded: true
      });
    }
    getPoticList(num).then(data => {
      this.setState({
        topicList: [...topicList, ...data.data.items],
        total: data.data.total_count,
        loading: false,
        page: page + 1
      });
    });
  }
  render() {
    const { lang } = this.props.match.params;
    let helmetData = getHelmet(
      {
        title: `${helment_locale('title', lang)}-${locales(lang, 'special')}`,
        titleFlag: true,
        twitterImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200',
        ogImage:
          'https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200'
      },
      lang
    );

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles['container']}>
          <div className={styles.cardContainer}>
            {/* 专题列表 */}
            <div className={styles.topic_list}>{this.renderTopic()}</div>
            <div className={styles.LoadMore}>
              <LoadMore
                loading={this.state.loading}
                loaded={this.state.loaded}
                onClick={this.handleLoadMore.bind(this)}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(connect()(TopicList));
