exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a {\n  text-decoration: none; }\n\n.src-components-common-localeFont--localeFont-index-module {\n  font-size: 17px;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: bold;\n  font-style: italic;\n  text-shadow: -1px 0 #000; }\n", ""]);

// exports
exports.locals = {
	"localeFont": "src-components-common-localeFont--localeFont-index-module"
};