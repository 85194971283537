import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';
import moment from 'moment';

import styles from './index.scss';

import {
  getLive,
  getCommentsList,
  sendComments,
  getLike,
  getLikeNum,
  getTotalNum
} from '../../../common/api/activity';
import { loginFlag } from '../../../common/scripts/login';

import { allEmoji } from './emoji';

import tu1 from '../../../assets/image/敬请期待.png';
import tu2 from '../../../assets/image/查看回放.png';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';

export class ActivityLive extends Component {
  static propTypes = {
    activityDetail: PropTypes.object.isRequired,
    liveData: PropTypes.object,
    commentsList: PropTypes.object,
    now: PropTypes.number,
    activityInfo: PropTypes.object.isRequired,
    likeNum: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per_page: 50,
      time: 700,
      max: 2000,
      commentsList: props.commentsList ? [...props.commentsList.comments] : [],
      userId: cookies.get('kr_plus_id'),
      now: props.now,
      maskFlag: false,
      liveData: props.liveData,
      iframeKey: 1,
      liveFlag: true,
      likeNum: props.likeNum,
      emojiFlag: 0,
      emojiBoxFlag: false
    };
  }

  changeLike() {
    const { lsid } = this.props.activityDetail;

    getLike({ lsid }).then(data => {
      if (data.code === 0) {
        this.setState({ likeNum: data.data.items });
      }
    });
  }

  getLikeNum() {
    const { lsid } = this.props.activityDetail;

    getLikeNum({ lsid }).then(data => {
      if (data.code === 0) {
        this.setState({ likeNum: data.data.items });
      }
    });

    setTimeout(() => {
      this.getLikeNum();
    }, 1000);
  }

  getLiveData() {
    const { activityInfo, activityDetail } = this.props;
    let startTime = activityInfo.activity.startTime;
    const { live_url } = activityDetail;
    let { now, iframeKey, liveData } = this.state;
    let onOff = liveData.livestream.onOff;

    if (now >= startTime) {
      getLive(live_url).then(data => {
        if (data.code === 0) {
          let endedAt = data.data.items.livestream.endedAt;
          let startedAt = data.data.items.livestream.startedAt;
          if (iframeKey > 10 && startedAt > endedAt && endedAt !== 0) {
            this.setState({ iframeKey: 2 }, () => {
              this.changeFlag();
            });
          }
          if (onOff === 0 && data.data.items.livestream.onOff === 1) {
            this.setState({ iframeKey: 2 }, () => {
              this.changeFlag();
            });
          }

          this.setState({
            liveData: data.data.items
          });

          if (iframeKey < 4 && endedAt !== 0 && endedAt > startedAt) {
            this.setState({
              iframeKey: iframeKey + 10,
              maskFlag: true
            });
          }
        }
      });
    }
    this.liveDataTimer = setTimeout(() => {
      this.getLiveData();
    }, 10000);
  }

  changeLiveFlag() {
    this.setState({
      liveFlag: false
    });
  }

  componentDidMount() {
    this.comments_list.scrollTop = this.comments_list.scrollHeight;
    this.getCommentsList();
    this.judgeMask();
    this.getLiveData();
    this.getLikeNum();
    this.getTotalNum();
    this.iframe.onload = this.changeLiveFlag.bind(this);
  }

  getTotalNum() {
    const { lsid } = this.props.activityDetail;

    getTotalNum(lsid).then(data => {
      if (data === 0) {
        this.setState({
          totalNum: data.data.items
        });
      }
    });

    setTimeout(() => {
      this.getLikeNum();
    }, 10000);
  }

  judgeMask() {
    let { activityInfo } = this.props;
    let { now, liveData } = this.state;
    let startTime = activityInfo.activity.startTime;
    let endedAt = liveData.livestream.endedAt;
    let startedAt = liveData.livestream.startedAt;
    let onOff = liveData.livestream.onOff;

    if (activityInfo) {
      this.setState({
        maskFlag: !onOff ? true : false,
        iframeKey:
          now > endedAt && endedAt !== 0 && startedAt < endedAt ? 10 : 1
      });

      if (startTime > now) {
        this.changeTime();
      }
    }
  }

  sendComments(emoji) {
    let { userId } = this.state;
    const { lsid } = this.props.activityDetail;
    let value = this.inp.innerHTML;
    // .replace(
    //   /<\s*img[\s\S]+?(?:src=['"]([\S\s]*?)['"]\s*|alt=['"]([\S\s]*?)['"]\s*|[a-z]+=['"][\S\s]*?['"]\s*)+[\s\S]*?>/g,
    //   (a, b, c) => {
    //     if (c) {
    //       return c;
    //     }
    //     return a;
    //   }
    // );

    if (emoji) {
      value = emoji;
    }

    if (!userId || value === '') {
      return;
    }

    let obj = { lsid, details: value };
    sendComments(obj).then(data => {
      if (data.data.items.comment) {
        if (!emoji) {
          this.inp.innerHTML = '';
        }
      }
    });
  }

  getCommentsList() {
    let { page, per_page, commentsList, time, max } = this.state;
    const { lsid } = this.props.activityDetail;

    setInterval(() => {
      getCommentsList({ lsId: lsid, page, pageSize: per_page }).then(data => {
        if (data.statusCode === 200 && data.data.comments.length > 0) {
          let newAry = data.data.comments.sort((a, b) => a.id - b.id);
          let oldValue = commentsList[commentsList.length - 1];
          let newValue = newAry[newAry.length - 1];
          if (
            commentsList.length > 0 &&
            oldValue.createdAt === newValue.createdAt &&
            oldValue.username === newValue.username &&
            oldValue.content === newValue.content
          ) {
            return;
          } else {
            newAry = newAry.filter(
              item => !commentsList.some(ele => ele.id === item.id)
            );

            newAry.map(item => {
              commentsList.push(item);
              if (commentsList.length > max) {
                commentsList.shift();
              }
            });

            this.setState({ commentsList }, () => {
              this.comments_list.scrollTop = this.comments_list.scrollHeight;
            });

            newAry = null;
            oldValue = null;
            newValue = null;
          }
        }
      });
    }, time);
  }

  changeTime() {
    let { activityInfo } = this.props;
    let { now } = this.state;
    if (
      moment(activityInfo.activity.startTime * 1000).diff(
        moment(now * 1000),
        'seconds'
      ) > 0
    ) {
      setTimeout(() => {
        this.setState({ now: now + 1 });
        this.changeTime();
      }, 1000);
    } else {
      clearTimeout(this.liveDataTimer);
      this.getLiveData(true);
    }
  }

  changeFlag() {
    this.setState({ maskFlag: false });
  }

  generatePraise() {
    this.changeLike();
    const img = Math.round(Math.random() * (13 - 1) + 1);
    const animation = Math.round(Math.random() * (11 - 1) + 1);
    let praise = document.createElement('div');
    praise.className = `${styles.praise} ${styles[`praise_img${img}`]} ${
      styles[`praise_animation${animation}`]
    }`;
    praise.dataset.t = String(Date.now());
    this.praise_box.appendChild(praise);
    setTimeout(() => {
      this.praise_box.removeChild(praise);
    }, 3000);
  }

  changeEmojiBoxFlag(boolean) {
    this.setState({
      emojiBoxFlag: boolean
    });
  }

  changeEmojiFlag(index) {
    if (this.state.emojiFlag === index) {
      return;
    }
    this.setState({
      emojiFlag: index
    });
  }

  getSelection() {
    this.setState({ lastEditRange: getSelection().getRangeAt(0) });
  }

  changeInp(index) {
    let { emojiFlag } = this.state;

    this.changeEmojiBoxFlag(false);
    if (emojiFlag === 1) {
      this.sendComments(allEmoji.header[emojiFlag].list[index].name);
      return;
    }

    this.inp.focus();
    // let imgStr = `<img src=${allEmoji.header[emojiFlag].list[index].src} alt=${
    //   allEmoji.header[emojiFlag].list[index].name
    // } />`;

    // let imgStr = `<span>${
    //   allEmoji.header[emojiFlag].list[index].content
    // }</span>`;

    let imgStr = allEmoji.header[emojiFlag].list[index].content;

    this.insertimg(imgStr);
  }

  insertimg(str) {
    let { lastEditRange } = this.state;
    let selection = getSelection();
    // 判断是否有最后光标对象存在
    if (lastEditRange) {
      // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
      selection.removeAllRanges();
      selection.addRange(lastEditRange);
    }

    var range = selection.getRangeAt(0);
    // 获取光标对象的范围界定对象，一般就是textNode对象
    var frag;
    if (range.createContextualFragment) {
      frag = range.createContextualFragment(str);
    } else {
      var container = document.createElement('div');
      container.innerHTML = str;
      frag = document.createDocumentFragment();
    }
    var node = frag.lastChild;
    range.insertNode(frag);
    range.collapse(true);
    range.setStartAfter(node);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  pasteHandler(event) {
    event.preventDefault();
    var text;
    var clp = (event.originalEvent || event).clipboardData;
    // 兼容针对于opera ie等浏览器
    if (clp === undefined || clp === null) {
      text = window.clipboardData.getData('text') || '';
      if (text !== '') {
        if (window.getSelection) {
          // 针对于ie11 10 9 safari
          var newNode = document.createElement('span');
          newNode.innerHTML = text;
          window
            .getSelection()
            .getRangeAt(0)
            .insertNode(newNode);
        } else {
          // 兼容ie10 9 8 7 6 5
          document.selection.createRange().pasteHTML(text);
        }
      }
    } else {
      // 兼容chorme或hotfire
      text = clp.getData('text/plain') || '';
      if (text !== '') {
        document.execCommand('insertText', false, text);
      }
    }
  }

  replaceComments(comments) {
    return comments
      .replace(/:(\s|\S)+?:/g, a => {
        if (!allEmoji.allList[a]) {
          return a;
        }

        return `<img src=${allEmoji.allList[a].src} loading="lazy" style=width:65px;height:65px />`;
      })
      .replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, a => {
        return `<div class=${styles.emoji}>
          ${a}
        </div>`;
      });
  }

  renderTime() {
    let { activityInfo } = this.props;
    let { startTime } = activityInfo.activity;
    let { now } = this.state;

    let diff = moment(startTime * 1000).diff(moment(now * 1000), 'seconds');
    const days = Math.floor(diff / 86400);
    const hours = Math.floor((diff % 86400) / 3600);
    const minutes = Math.floor(((diff % 86400) % 3600) / 60);
    const seconds = Math.floor(((diff % 86400) % 3600) % 60);

    return `${days}天${hours}小时${minutes}分${seconds}秒`;
  }

  renderEmoji() {
    let { emojiFlag, emojiBoxFlag } = this.state;

    return (
      <div
        className={styles.emojiBox}
        style={{ display: emojiBoxFlag ? 'block' : null }}
      >
        <div className={styles.emojiHeader}>
          {allEmoji.header.map((item, index) => {
            return (
              <div
                className={styles.emojiHeader_button}
                key={`emojiHeader_button${index}`}
                style={{
                  backgroundImage: `url(${item.img})`,
                  backgroundColor: emojiFlag === index ? '#DFE6EE' : null
                }}
                onClick={() => {
                  this.changeEmojiFlag(index);
                }}
              />
            );
          })}
        </div>
        <div className={styles.emojiList}>
          <div className={styles.emojiList_wrapper}>
            {allEmoji.header[emojiFlag].list.map((item, index) => {
              if (emojiFlag === 0) {
                return (
                  <div
                    className={styles.emojiItem_small}
                    key={`emojiItem${index}`}
                    onClick={() => {
                      this.changeInp(index);
                    }}
                  >
                    {item.content}
                  </div>
                );
              }
              return (
                <div
                  className={styles.emojiItem}
                  key={`emojiItem${index}`}
                  onClick={() => {
                    this.changeInp(index);
                  }}
                >
                  <div className={styles.emojiItem_img_wrapper}>
                    <div
                      className={styles.emojiItem_img}
                      style={{ backgroundImage: `url(${item.src})` }}
                    />
                  </div>
                  <div
                    className={styles.emojiItem_name}
                    // style={{ display: item.key ? null : 'none' }}
                  >
                    {item.key}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderComments() {
    let { userId, commentsList, emojiBoxFlag } = this.state;
    const { dispatch, match } = this.props;
    const { lang } = match.params;
    return (
      <div className={styles.comments_wrapper}>
        <div className={styles.comments_header}>聊天室</div>
        <div
          className={styles.comments_list}
          ref={e => (this.comments_list = e)}
        >
          <div
            className={styles.comments_tips}
            style={{ display: commentsList.length > 0 ? 'none' : null }}
          >
            <i className={styles.comments_tips_icon} />
            还没有人发言，大家快来抢沙发吧！
          </div>
          <div className={styles.comments_list_wrapper}>
            {commentsList
              .sort((a, b) => a.id - b.id)
              .map((item, index) => {
                let contentFlag = /^({"redPacketId")(\s|\S)+("})$/.test(
                  item.content
                );

                if (contentFlag) {
                  return '';
                } else {
                  return (
                    <div
                      className={styles.comments_item}
                      style={{ color: contentFlag ? '#999' : null }}
                      key={`comments${index}`}
                    >
                      <div className={styles.avatar}>
                        <img src={item.avatar} loading="lazy" />
                      </div>

                      <div className={styles.comments_item_content}>
                        <div className={styles.comments_item_name}>
                          {item.username}:
                        </div>
                        <div
                          className={styles.comments_item_text}
                          dangerouslySetInnerHTML={{
                            __html: this.replaceComments(item.content)
                          }}
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>

        <div className={styles.comments_input}>
          {userId ? (
            <div className={styles.inp_wrapper}>
              <div
                className={styles.inp}
                ref={e => (this.inp = e)}
                onKeyDown={e => (e.keyCode === 13 ? this.sendComments() : null)}
                contentEditable
                onClick={() => {
                  this.getSelection();
                }}
                onKeyUp={() => {
                  this.getSelection();
                }}
                onPaste={e => {
                  this.pasteHandler(e);
                }}
                // placeholder="快来发表意见吧！"
              />
            </div>
          ) : (
            <div className={styles.inp_font}>
              游客状态无法输入弹幕，请
              <span
                onClick={() => {
                  loginFlag(dispatch, true);
                }}
              >
                {locales(lang, 'signin')}
              </span>
            </div>
          )}
        </div>

        <div className={styles.comments_footer}>
          <div
            className={styles.emoticon_button}
            onClick={() => {
              this.changeEmojiBoxFlag(!emojiBoxFlag);
            }}
          />

          <div
            className={`${styles.comments_button} ${
              userId ? styles.active_button : null
            }`}
            onClick={() => {
              this.sendComments();
            }}
          >
            发送
          </div>
        </div>

        {this.renderEmoji()}
      </div>
    );
  }

  render() {
    let { activityDetail, activityInfo } = this.props;
    let { title } = activityDetail;
    let { maskFlag, now, liveData, iframeKey, liveFlag, likeNum } = this.state;
    let { livestream, embedPlayerUrl } = liveData;
    let { startTime } = activityInfo.activity;
    let endedAt = livestream.endedAt;
    let startedAt = liveData.livestream.startedAt;
    let onOff = liveData.livestream.onOff;

    return (
      <div
        className={styles.live_wrapper}
        onClick={e => {
          if (
            e.target.className === styles.emoticon_button ||
            e.target.className === styles.emojiHeader ||
            e.target.className === styles.emojiHeader_button ||
            e.target.className === styles.emojiList_wrapper
          ) {
            return;
          }
          this.changeEmojiBoxFlag(false);
        }}
      >
        <div className={styles.live_left}>
          {/* 标题 */}
          <div className={styles.live_title}>{title}</div>
          {/* 时间 */}
          <div className={styles.live_startedAt}>
            {moment(parseInt(startTime) * 1000).format('YYYY-MM-DD HH:mm')}
          </div>

          <div className={styles.live_wrapper}>
            <div
              className={styles.live}
              style={{ backgroundColor: liveFlag ? '#000' : null }}
            >
              {/* 直播 */}
              <iframe
                ref={e => {
                  this.iframe = e;
                }}
                src={embedPlayerUrl}
                frameBorder="0"
                className={styles.iframe}
                scrolling="no"
                allowFullScreen={true}
                key={iframeKey}
              />

              {/* 直播遮罩层 */}
              <div
                className={styles.mask}
                style={{ display: maskFlag ? null : 'none' }}
              >
                <div className={styles.mask_wrapper}>
                  {/* 遮罩层图片 */}
                  <div
                    className={styles.mask_img}
                    style={{
                      backgroundImage: `url(${
                        startedAt < endedAt && onOff === 0 ? tu2 : tu1
                      })`
                    }}
                  >
                    {/* 遮罩层时间 */}
                    {moment(startTime * 1000).diff(
                      moment(now * 1000),
                      'seconds'
                    ) > 0 ? (
                      <p className={styles.mask_time}>
                        距离开始还有{this.renderTime()}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* 遮罩层按钮 */}
                  {startedAt < endedAt && endedAt !== 0 ? (
                    <div
                      className={styles.mask_button}
                      onClick={() => this.changeFlag()}
                    >
                      查看回放
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              {/* 点赞 */}
              <div className={styles.praise_wrapper}>
                <div
                  className={styles.praise_box}
                  ref={e => {
                    this.praise_box = e;
                  }}
                />
                <div
                  className={styles.praise_button}
                  onClick={() => {
                    this.generatePraise();
                  }}
                >
                  <div className={styles.likeNum}>
                    {likeNum > 100000
                      ? (likeNum / 10000).toFixed(1) + 'W'
                      : likeNum}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 评论 */}
        <div className={styles.live_right}>{this.renderComments()}</div>
      </div>
    );
  }
}
export default withRouter(ActivityLive);
