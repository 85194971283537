import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './pages/home';
import Download from './pages/download';
import User from './pages/user';
import Column from './pages/column';
import SecondTag from './pages/secondTag';
import Post from './pages/post';
import PostPreview from './pages/post/preview';
import Video from './pages/video';
import VideoList from './pages/videolist';
import Topic from './pages/topic';
import NewsFlash from './pages/newsflash';
import Research from './pages/research';
import NewsFlashDetail from './pages/newsflash/detail';
import TopicList from './pages/topiclist';
import Search from './pages/searchNew';
import NotFound from './pages/error/notfound';
import Aboutus from './pages/about';
import My from './pages/my';
import Outdated from './pages/outdated';
import Chain from './pages/chain';
import Activityed from './pages/activityed';
import Activityed2020 from './pages/activityed2020';
import Activityed2021 from './pages/activityed2021';
import Fat2024 from './pages/fat2024';
import ActivityedFat from './pages/activityed/fat';
import ActivityDetail from './pages/activityDetail';
import ActivityPage from './pages/activityPage';
import ActivityMore from './pages/activityMore';
import Activity from './pages/activity';
import Vote from './pages/vote';
import Menu from './pages/menu';
import Navigation from './pages/navigation';
import Viewpoint from './pages/viewpoint';
import LibraryList from './pages/library/list';
import Library from './pages/library/index';
import Fat2024vote from './pages/fat2024vote';

import TechnicalSupport from './pages/TechnicalSupport';
import { setI18nArr } from './utils/locales';
import Market from './pages/market';

const baseRoutes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/download',
    component: Download
  },
  {
    path: '/viewpoint',
    component: Viewpoint
  },
  {
    path: '/library/:id',
    component: Library
  },
  {
    path: '/libraryList',
    component: LibraryList
  },
  // { path: '/DeFi', component: DeFi },
  {
    path: '/user/:id',
    component: User
  },
  {
    path: '/Colunms/:id',
    component: Column
  },
  {
    path: '/secondtag/:id',
    component: SecondTag
  },
  {
    path: '/post/preview/:id',
    component: PostPreview
  },
  {
    path: '/post/:id',
    component: Post
  },
  {
    path: '/video/:id',
    component: Video
  },
  {
    path: '/video',
    component: VideoList
  },
  {
    path: '/topic/:id',
    component: Topic
  },
  // { path: '/marketinfo', component: MarketInfo },
  // { path: '/marketdetail', component: MarketDetail },
  // { path: '/newsflash/my', component: MyNewsflash },
  {
    path: '/newsflash/:id',
    component: NewsFlashDetail
  },
  {
    path: '/newsflash',
    component: NewsFlash
  },
  {
    path: '/research',
    component: Research
  },
  {
    path: '/search/:keywords',
    component: Search
  },
  // { path: '/searchTab', component: SearchMiddle },
  {
    path: '/topic',
    component: TopicList
  },
  // { path: '/marketdetail/:id/:name', component: MarketDetail },
  // { path: '/author', component: AuthorList },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/aboutus',
    component: Aboutus
  },
  {
    path: '/chain',
    component: Chain
  },
  {
    path: '/activityed',
    component: Activityed
  },
  {
    path: '/activityed2020',
    component: Activityed2020
  },
  {
    path: '/AcyivityVote',
    component: Activityed2021
  },
  {
    path: '/fat2024',
    component: Fat2024
  },
  {
    path: '/fat2024vote/:id',
    component: Fat2024vote
  },
  {
    path: '/ActivityedFat',
    component: ActivityedFat
  },
  {
    path: '/activityDetail',
    component: ActivityDetail
  },
  {
    path: '/my',
    component: My
  },
  {
    path: '/outdated',
    component: Outdated
  },
  {
    path: '/activitypage',
    component: ActivityPage
  },
  {
    path: '/activityMore/:id',
    component: ActivityMore
  },
  {
    path: '/activity/:id',
    component: Activity
  },
  {
    path: '/ActivityedVote/:id',
    component: Vote
  },
  {
    path: '/menu',
    component: Menu
  },
  {
    path: '/TechnicalSupport',
    component: TechnicalSupport
  },
  {
    path: '/navigation',
    component: Navigation
  },
  {
    path: '/market',
    component: Market
  }
];
// 设置国际化路由
const allRoutes = setI18nArr(baseRoutes);
const routes = (
  <Switch>
    {allRoutes.map(route => (
      <Route key={route.path} {...route} />
    ))}
  </Switch>
);
export default routes;
