import { combineReducers } from 'redux';
import _ from 'lodash';

const INIT_COLLECT_STATE = {
  posts: [],
  video: []
};

const INIT_ATTENTION_STATE = {
  users: [],
  columns: []
};

const collection = (state = INIT_COLLECT_STATE, action) => {
  const { posts, monographics, favoritable_id, video } = action;

  switch (action.type) {
    case 'SET_COLLECTION_VIDEO':
      return {
        ...state,
        video: _.concat(state.video, video)
      };
    case 'SET_COLLECTION_POSTS':
      return {
        ...state,
        posts: _.concat(state.posts, posts)
      };
    case 'DEL_COLLECTION_POSTS':
      _.remove(state.posts, collect => {
        return collect.favoritable_id === favoritable_id;
      });
      return {
        ...state,
        posts: state.posts
      };
    case 'SET_COLLECTION_MONOGRAPHICS':
      return {
        ...state,
        monographics: _.concat(state.monographics, monographics)
      };
    case 'DEL_COLLECTION_MONOGRAPHIC':
      _.remove(state.monographics, collect => {
        return collect.favoritable_id === favoritable_id;
      });
      return {
        ...state,
        monographics: state.monographics
      };
    default:
      return state;
  }
};

const attention = (state = INIT_ATTENTION_STATE, action) => {
  const { users, columns, userId, columnId } = action;

  switch (action.type) {
    case 'SET_ATTENTION_USERS':
      return {
        ...state,
        users: _.concat(state.users, users)
      };
    case 'SET_ATTENTION_COLUMNS':
      return {
        ...state,
        columns: _.concat(state.columns, columns)
      };
    case 'UNFOLLOW_ATTENTION_USER':
      state.users.map(user => {
        if (user.follow_user.id === userId) {
          user.follow_status = false;
        }
      });
      return {
        ...state,
        users: state.users.slice()
      };
    case 'FOLLOW_ATTENTION_USER':
      state.users.map(user => {
        if (user.follow_user.id === userId) {
          user.follow_status = true;
        }
      });
      return {
        ...state,
        users: state.users.slice()
      };
    case 'ATTENTION_COLUMN_UNFOLLOW':
      state.columns.map(column => {
        if (column.column.id === columnId) {
          column.follow_status = false;
        }
      });
      return {
        ...state,
        columns: state.columns.slice()
      };
    case 'ATTENTION_COLUMN_FOLLOW':
      state.columns.map(column => {
        if (column.column.id === columnId) {
          column.follow_status = true;
        }
      });
      return {
        ...state,
        columns: state.columns.slice()
      };
    default:
      return state;
  }
};

const fans = (state = [], action) => {
  const { list, userId } = action;
  switch (action.type) {
    case 'SET_MY_FANS':
      return _.concat(state, list);
    case 'MY_FANS_UNFOLLOW':
      state.map(fans => {
        if (fans.user.id === userId) {
          fans.follow_status = false;
        }
      });
      return state.slice();
    case 'MY_FANS_FOLLOW':
      state.map(fans => {
        if (fans.user.id === userId) {
          fans.follow_status = true;
        }
      });
      return state.slice();
    default:
      return state;
  }
};

const comments = (state = [], action) => {
  const { comments, id } = action;
  switch (action.type) {
    case 'SET_MY_COMMENT':
      return _.concat(state, comments);
    case 'DEL_MY_COMMENT':
      _.remove(state, comment => {
        return comment.id === id;
      });
      return state.slice();
    default:
      return state;
  }
};

const replys = (state = [], action) => {
  const { replys } = action;
  switch (action.type) {
    case 'SET_MY_REPLY':
      return _.concat(state, replys);
    default:
      return state;
  }
};

const userThird = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER_THIRD':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  collection,
  attention,
  fans,
  comments,
  replys,
  userThird
});
