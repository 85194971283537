import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styles from './index.scss';
import { getHotSearch } from '../../common/api/search';
import { getNewsflash } from '../../common/api/newsflash';
import { getTopPost } from '../../common/api/found';
import locales from '../../utils/locales';
export class SearchMiddle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searchList: '',
      hotKey: [],
      newList: [],
      postList: []
    };
  }
  static propTypes = {
    changeSearchFlag: PropTypes.func,
    match: PropTypes.object
  };

  keywordsHandler(e) {
    const { lang } = this.props.match.params;
    const keywords = e.target.value;
    if (e.key === 'Enter') {
      this.changelocalStorage(keywords);
      this.props.changeSearchFlag(false);
      this.searchInput.value = '';
      const encode_key = encodeURIComponent(keywords);
      const url = lang
        ? `/${lang}/search/${encode_key}`
        : `/search/${encode_key}`;
      window.open(url);
    }
  }

  changelocalStorage(keywords) {
    const { lang } = this.props.match.params;
    let value = localStorage.getItem('search' + lang);
    if (value) {
      value = JSON.parse(value);
      value.push(keywords);
      if (value.length > 10) {
        value.shift();
      }
      value = JSON.stringify(value);
      localStorage.setItem('search' + lang, value);
    } else {
      let ary = [];
      ary.push(keywords);
      ary = JSON.stringify(ary);
      localStorage.setItem('search' + lang, ary);
    }
  }

  deleteSearchList() {
    const { lang } = this.props.match.params;
    localStorage.removeItem('search' + lang);
    this.setState({ searchList: '' });
  }

  renderContent() {
    let { hotKey } = this.state;

    return (
      <div className={styles.list}>
        {hotKey.slice(0, 18).map(item => {
          return (
            <a
              className={styles.list_item}
              key={item.id}
              href={`/search/${item.keyword}`}
              target={'_blank'}
              rel="noreferrer"
              onClick={() => {
                this.changelocalStorage(item.keyword);
                this.props.changeSearchFlag(false);
              }}
            >
              {item.keyword}
            </a>
          );
        })}
      </div>
    );
  }

  renderSearch() {
    if (!localStorage.getItem('search')) {
      return;
    }
    let { lang } = this.props.match.params;
    let list = JSON.parse(localStorage.getItem('search' + lang));
    list = Array.from(new Set(list));
    return (
      <div className={styles.list}>
        {list.map(item => {
          return (
            <a
              className={styles.list_item}
              key={`list_item${item}`}
              href={`/search/${encodeURIComponent(item)}`}
              target={'_blank'}
              rel="noreferrer"
              onClick={() => {
                this.changelocalStorage(item);
                this.props.changeSearchFlag(false);
              }}
            >
              {item}
            </a>
          );
        })}
      </div>
    );
  }

  componentDidMount() {
    const { lang } = this.props.match.params;
    getHotSearch(lang).then(res => {
      if (res.data && res.data.items) {
        this.setState({
          hotKey: res.data.items
        });
      }
    });
    this.setState({
      searchList: localStorage.getItem('search' + lang)
    });
    this.getNew();
    this.getPost();
    // this.searchInput.current.focus();
  }

  getNew() {
    const { lang } = this.props.match.params;
    getNewsflash({}, lang).then(data => {
      if (data.data && data.data.items) {
        this.setState({ newList: data.data.items.slice(0, 3) });
      }
    });
  }

  getPost() {
    const { lang } = this.props.match.params;
    getTopPost('day', lang).then(data => {
      if (data.data && data.data.items) {
        this.setState({ postList: data.data.items.slice(0, 3) });
      }
    });
  }

  renderPost(type) {
    const { newList, postList } = this.state;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.searchPost_box}>
        <div className={styles.searchPost_box_header}>
          <div className={styles.searchPost_box_title}>
            {type === 'new'
              ? locales(lang, 'latestNews')
              : locales(lang, 'pArticles')}
          </div>

          {type === 'new' ? (
            <a
              className={styles.searchPost_box_icon}
              href="/newsflash"
              target="_blank"
              rel="noopener noreferrer"
            >
              {locales(lang, 'more')}
            </a>
          ) : (
            ''
          )}
        </div>

        {type == 'new' ? (
          <div className={styles.searchPost_box_list}>
            {newList.map((item, index) => {
              return (
                <a
                  className={styles.searchPost_box_newItem}
                  key={`searchPost_box_newItem${index}`}
                  href={`/newsflash/${item.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={styles.searchPost_box_newItem_title}
                    style={{ WebkitBoxOrient: 'vertical' }}
                  >
                    {item.title}
                  </div>
                </a>
              );
            })}
          </div>
        ) : (
          <div className={styles.searchPost_box_list}>
            {postList.map((item, index) => {
              return (
                <a
                  className={styles.searchPost_box_postItem}
                  key={`searchPost_box_postItem${index}`}
                  href={`/post/${item.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      styles[`searchPost_box_postItem_icon${index + 1}`]
                    }
                  />
                  <div
                    className={styles.searchPost_box_postItem_title}
                    style={{ WebkitBoxOrient: 'vertical' }}
                  >
                    {item.title}
                  </div>
                </a>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  render() {
    let { searchList, hotKey } = this.state;
    let { changeSearchFlag, match } = this.props;
    const { lang } = match.params;
    return (
      <div className={styles.search}>
        <div className={styles.search_wrapper}>
          <div className={styles.search_header}>
            <div className={styles.search_logo} />
            <div
              className={styles.search_icon}
              onClick={() => {
                changeSearchFlag(false);
              }}
            />
          </div>

          <div className={styles.search_main}>
            <div className={styles.search_inp}>
              <input
                className={styles.searchInput}
                type="text"
                ref={e => {
                  this.searchInput = e;
                }}
                onKeyDown={this.keywordsHandler.bind(this)}
                placeholder={locales(lang, 'enter_KW')}
              />
            </div>

            {hotKey && hotKey.length ? (
              <div className={styles.search_title}>
                {locales(lang, 'pSearch')}
              </div>
            ) : (
              ''
            )}
            {hotKey && hotKey.length ? this.renderContent() : ''}
            {searchList ? (
              <div className={styles.search_title2}>
                最近搜索
                <div
                  className={styles.search_title2_button}
                  onClick={() => {
                    this.deleteSearchList();
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {searchList ? this.renderSearch() : ''}

            <div className={styles.searchPost_box_wrapper}>
              {this.renderPost('new')}
              {this.renderPost('post')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hotKey: state.search.hotkey
  };
};

export default withRouter(connect(mapStateToProps)(SearchMiddle));
