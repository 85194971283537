exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-common-sticky--sticky-index {\n  width: 100%;\n  position: -webkit-sticky;\n  position: sticky;\n  top: 10px; }\n", ""]);

// exports
exports.locals = {
	"sticky": "src-components-common-sticky--sticky-index"
};