import http from '../scripts/http';

// 自建文库
export function getLibraryList(lang) {
  return http.get(`/library/list`, { lang });
}

// 收藏文库
export function getFavoriteList(page = 1, per_page = 5, lang) {
  return http.get(`/library/my_favorite?page=${page}&per_page=${per_page}`, {
    lang
  });
}

// 推荐文库
export function getRecommendList(page = 1, per_page = 5, lang) {
  return http.get(`/library/recommend?page=${page}&per_page=${per_page}`, {
    lang
  });
}

export function getLibrary(id, lang) {
  return http.get(`/library/show/${id}`, { lang });
}

export function getLibraryFeed(id, page = 1, per_page = 10, lang) {
  return http.get(
    `/library/show/item/${id}?page=${page}&per_page=${per_page}`,
    { lang }
  );
}

export function deleteLibrary(id) {
  return http.delete(`/library/${id}`);
}

export function favoriteLibrary(id) {
  return http.put(`/library/favorite/${id}`);
}

export function postFavorite(entity_type, entity_id) {
  return http.get(
    `/library/my_library?entity_type=${entity_type}&entity_id=${entity_id}`
  );
}

export function setFavorite(entity_type, entity_id, library_ids) {
  return http.post(`/library/add_entity`, {
    entity_type,
    entity_id,
    library_ids
  });
}

export function modifyLibrary(id, name = '', summary = '', cover = '') {
  return http.put(`/library/${id}`, {
    name,
    summary,
    cover
  });
}

export function newLibrary(name = '', summary = '', cover = '') {
  return http.post(`/library`, {
    name,
    summary,
    cover
  });
}
