// eslint-disable-next-line react/display-name
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ie from './utils/ie';

import App from './App';
import configureStore from './utils/configureStore';
import '../src/common/styles/reset.scss';
import './styles/index.scss';
import OneSignal from 'react-onesignal';
OneSignal.init({ appId: process.env.ONESIGNALID });

const store = configureStore(window.__INITIAL_STATE__);

if (ie < 10) {
  window.location.href = '/outdated';
}

window.render = function() {
  ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
};
