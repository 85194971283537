import React, { Component } from 'react';
import styles from '../../index.scss';

export class AgreementKo extends Component {
  render() {
    return (
      <div className={`${styles.content} ${styles.agreement}`}>
        <h5>오데일리미디어 이용약관</h5>
        <h6>【첫 부분 및 소개】</h6>
        <p>
          오데일리에 오신 것을 환영합니다! ODAILY 서비스(이하 &quot;본
          서비스&quot;)를 이용하기 위해서는 &quot;ODAILY 미디어 이용자 서비스
          약관&quot;(이하 &quot;본 약관&quot;)을 읽고 이를 준수해야 합니다. 각
          조항의 내용, 특히 면책조항이나 책임제한조항, 특정 서비스의 개설 또는
          이용에 관한 별도의 계약을 반드시 주의 깊게 읽고 충분히 이해하신 후
          동의 여부를 선택하시기 바랍니다. 제한 및 면책 조항은 굵은 글씨로
          주의를 기울일 것을 상기시켜 줄 수 있습니다. 본 계약 및 관련 계약의
          모든 조건을 읽고 동의하지 않으면 서비스를 사용할 권리가 없습니다. 이
          서비스에 대한 귀하의 로그인, 열람, 정보 게시 및 기타 행동은 귀하가 본
          계약을 읽고 이에 동의한 것으로 간주됩니다. 귀하가 18세 미만인 경우,
          귀하의 법적 보호자와 함께 이 약관 및 기타 위에서 언급한 계약을
          읽으십시오. 특히 미성년자의 이용 약관에 주의하십시오.
        </p>
        <h6>1. 【계약의 범위】</h6>
        <p>
          1.1 본 계약은 귀하의 ODAILY 사용에 관한 귀하와 ODAILY 간의 계약입니다.
          &quot;ODAILY&quot;는 Hainan Moti Culture Media Co., Ltd. 및 관련
          서비스의 가능한 운영 계열사를 나타냅니다. &quot;플랫폼 사용자&quot;는
          ODAILY 계정을 등록, 로그인 및 사용하는 개인 또는 조직을 말하며, 본
          계약에서 &quot;귀하&quot;로 더 지칭합니다. &quot;다른 이용자&quot;라
          함은 플랫폼 이용자 자신이 아닌 가입자 및 기타 이용자를 포함하여
          서비스와 관련된 이용자를 말합니다.
        </p>
        <p>
          1.2 이 서비스에는 ODAILY 공식 웹사이트 www.odaily.news, ODAILYAPP,
          Odaily APP, ODAILY WeChat 공개 계정 Odaily(WeChat ID: O-daily), Odaily
          WeChat 서비스 계정, ODAILYAPP 개방형 플랫폼, ODAILYAPP Weibo 등이
          포함되며 이에 국한되지 않습니다. 비즈니스 플랫폼은 커뮤니케이션
          채널이며, 오데일리는 개인 또는 기업을 위한 정보 공개 및 브랜드 홍보
          서비스를 제공합니다. 플랫폼 사용자는 ODAILY 계정을 등록한 후 ODAILY를
          통해 정보 공개, 브랜드 홍보 및 구독 사용자 댓글 관리와 같은 작업을
          수행할 수 있습니다.
        </p>
        <h6>2.【계정 등록 및 계정 권한】</h6>
        <p>
          본 서비스를 이용하기 위해서는 오다일리 계정을 등록해야 하며, 오다일리
          계정은 휴대폰 번호와 기타 정보를 바인딩하여 생성됩니다.
        </p>
        <p>
          2.2 ODAILY는 등록된 계정 정보를 검토합니다. 귀하는 귀하가 제공한 계정
          정보의 진정성, 합법성, 정확성 및 유효성에 대해 전적으로 책임을 집니다.
          오데일리 또는 제3자에게 손해를 끼친 경우 법에 따라 배상해야 합니다.
        </p>
        <p>
          2.3 오데일리는 귀하가 오데일리를 통해 게시하는 뉴스(텍스트, 사진,
          동영상, 차트 등을 포함하되 이에 국한되지 않음) 오데일리 APP, 오데일리
          위챗 공식 계정 오데일리(위챗 ID: O-daily), 오데일리 위챗 서비스를
          수정할 수 있는 권리를 가집니다. 계정, ODAILYAPP Open Platform,
          ODAILYAPP Weibo 및 기타 ODAILY 비즈니스 플랫폼이 공개됩니다. ODAILY와
          그 계열사 및 파트너는 귀하가 ODAILY를 통해 업로드하고 게시하는 모든
          콘텐츠를 사용할 수 있는 전 세계적이고 영구적이며 취소 불가능하고
          무료이며 비독점적인 권리를 가집니다.
        </p>
        <p>2.4 이 서비스를 사용하면 다음과 같은 권한을 얻을 수 있습니다.</p>
        <p>
          2.4.1 ODAILY를 통해 메시지 관리 및 구독자와 상호 작용 2.4.2 ODAILY
          계정의 구독자 수 및 메시지 클릭 수 획득 2.4.3 ODAILY의 동의에 따라
          다음과 같은 ODAILY 상업 활동을 통해 메시지를 수행할 권리가 있습니다.
          브랜딩 메시지 및/또는 유료 구독 게시. 또한 귀하는 ODAILY가 ODAILY를
          통한 귀하의 상업 활동에 대한 기술 서비스 비용을 청구할 권리가 있음에
          동의합니다.
        </p>
        <h6>3. [플랫폼 이용자의 개인정보 보호]</h6>
        <p>
          3.1 이 서비스를 신청하는 과정에서 필요한 정보를 제공해야 할 수
          있습니다. 이 정보를 진실하고 정확하며 합법적이고 유효하게 유지하고
          시기 적절한 업데이트에 주의하여 ODAILY가 시기 적절하고 효과적인 도움을
          제공할 수 있도록 하십시오. 더 나은 서비스 당신은 서비스를 제공합니다.
          관련 법률, 규정 및 정책에 따라 실제 신원 정보를 입력하십시오. 입력하신
          정보가 불완전하거나 정확하지 않을 경우 본 서비스 이용이 불가능하거나
          이용이 제한될 수 있습니다.
        </p>
        <p>
          3.2 오데일리는 플랫폼 이용자와 함께 개인정보 보호에 최선을 다하고
          있으며, 플랫폼 이용자의 개인정보 보호는 오데일리의 기본원칙입니다.
          오데일리는 귀하의 동의 없이 귀하의 개인정보를 법률 및 규정에 특별한
          규정이 없는 한 오데일리 이외의 회사, 조직 또는 개인에게 공개하지
          않습니다.
        </p>
        <p>
          3.3 귀하는 이 서비스를 통해 귀하가 알고 있거나 수신하거나 액세스할 수
          있는 다른 사용자를 포함하되 이에 국한되지 않는 모든 사람의 개인 정보를
          충분히 존중해야 하며 다른 사용자의 개인 정보를 수집, 복사, 저장, 유포
          또는 사용해서는 안 됩니다. 그로 인한 결과는 귀하가 부담해야 합니다.
        </p>
        <h6>4. [플랫폼 콘텐츠 사양]</h6>
        <p>
          4.1 이 문서에서 언급된 플랫폼 콘텐츠는 ODAILY 계정 아바타, 이름,
          플랫폼 사용자 설명 및 기타 등록 정보 또는 텍스트를 포함하되 이에
          국한되지 않고 이 서비스를 사용하는 동안 귀하가 생산, 복사, 게시 및
          유포하는 모든 콘텐츠를 의미합니다. , 음성, 사진, 메시지 보내기, 답장
          또는 자동 답장 및 관련 링크 페이지, 기타 오데일리 계정 또는 오데일리
          서비스를 이용하여 생성된 동영상, 사진, 문자 등의 콘텐츠.
        </p>
        <p>
          4.2 귀하는 본 서비스를 이용할 때 법령, 사회주의 제도, 국익, 공민의
          정당한 권익, 사회공공질서, 도덕성, 정보의 진정성 등 &quot;7가지
          기본&quot;요건을 준수해야 합니다.
        </p>
        <p>
          귀하는 이 서비스를 사용하여 다음 법률, 규정 및 정책에서 금지하는
          콘텐츠를 제작, 복사, 게시 또는 배포할 수 없습니다.
        </p>
        <p>
          (1) 헌법이 제정한 기본원칙에 반하는 것 (2) 국가안전을 위협하는 것,
          국가기밀을 누설하는 것, 국가권력을 전복시키는 것, 국민통합을 파괴하는
          것 (3) 국가의 명예와 이익을 해치는 것 (4) 민족적 원한, 민족적 차별을
          선동하는 것 (5) 국가 종교 정책을 훼손하고 사교와 봉건적 미신을
          조장하는 것 (6) 소문을 퍼뜨리고 사회 질서를 파괴하고 사회 안정을
          해치는 것 (7) 음란물, 음란물, 도박, 폭력, 살인을 퍼뜨리는 것 (8) )
          타인을 모욕하거나 비방하고 타인의 정당한 권리와 이익을 침해하는 행위
          (9) 불법 집회, 결사, 행진, 시위, 집회를 선동하여 사회 질서를 교란하는
          행위 {10} 불법 시민 단체의 명의로 활동하는 행위 (11) 법률, 규정 및
          정책에서 금지하는 기타 콘텐츠를 포함하는 정보.
        </p>
        <p>
          4.3 귀하는 ODAILY가 항상 플랫폼 사용자에게 문명화되고 건강하며
          표준화되고 질서 있는 네트워크 환경을 제공하기 위해 최선을 다하고
          있음을 이해하고 동의합니다.이 서비스를 사용하여 다음 정보를 방해하는
          생산, 복사, 출판 또는 유포할 수 없습니다. 오데일리의 정상적인 운영을
          방해하거나 다른 사용자 또는 제3자의 법률을 위반하는 경우 혜택 내용:
        </p>
        <p>
          (1) 성적 또는 성적인 암시를 포함하는 내용, (2) 광고, 희롱, 스팸, (3)
          다른 사람의 사생활, 개인 정보 또는 데이터와 관련된 내용, (4) 다른
          사람의 평판, 초상권, 지적 재산권, 상업성을 침해하는 내용(5) ) 기타
          오데일리의 정상적인 운영을 방해하고 다른 이용자 또는 제3자의 법적
          권익을 침해하는 내용을 포함하고 있습니다.
        </p>
        <h6>5. 【플랫폼 이용 규칙】</h6>
        <p>
          5.1 이 문서에서 언급된 플랫폼의 사용은 등록 및 로그인, 계정 운영 및
          프로모션, ODAILY 계정 또는 ODAILY 서비스를 사용하여 수행되는 기타
          동작을 포함하되 이에 국한되지 않고 이 서비스를 사용하여 수행하는 모든
          동작을 말합니다.
        </p>
        <p>
          5.2 귀하는 귀하의 ODAILY 계정 또는 ODAILY 서비스를 사용하여 다음
          행위를 수행할 수 없습니다.
        </p>
        <p>
          (1) 허위 정보를 제출 또는 게재하거나 타인의 명의를 도용 또는 도용하는
          행위 (2) 다른 이용자에게 주의를 강요하거나 유인하는 행위, 링크된
          페이지를 클릭하거나 정보를 공유하는 행위 (3) 사실을 날조하고 사실을
          은폐하여 오해를 불러일으키는 행위 타인을 속이거나 (4) 평판권, 초상권,
          지적재산권, 영업비밀 등 기타 법적 권리를 침해하는 행위 (5) ODAILY의
          서면 허가 없이 제3자를 홍보하기 위해 ODAILY 계정을 사용하는
          행위(포함하되 제외) 제3자 링크, 광고 등 동작을 추가하는 것으로
          제한됨), (6) ODAILY의 서면 허가 없이 이 서비스 및 관련 시스템에
          액세스하기 위해 플러그인, 플러그인 또는 기타 제3자 도구 및 서비스를
          사용합니다. ) ODAILY 계정 또는 ODAILY 서비스를 사용하여 불법 및 범죄
          행위에 가담하는 행위 (8) 이러한 행위가 상업적 목적인지 여부에 관계없이
          위의 행위와 관련된 방법 및 도구를 제작, 게시하거나 그러한 방법 및
          도구를 운영 또는 전파하는 행위 또는 ODAILY가 명시적으로 승인하지 않은
          행위.
        </p>
        <h6>6. [계정 관리]</h6>
        <p>
          6.1 ODAILY 계정의 소유권은 Hainan Moti Culture Media Co., Ltd.가
          소유하며 플랫폼 사용자는 애플리케이션 등록 절차를 완료한 후 ODAILY
          계정을 사용할 수 있는 권한을 얻습니다.이 사용 권한은 최초 신청자에게만
          속합니다. 증여, 대여, 대여, 양도 및 판매를 금지합니다. Hainan Moti
          Culture Media Co., Ltd.는 업무상의 필요로 인해 이 서비스를 종료할
          권리가 있습니다.
        </p>
        <p>
          6.2 귀하는 등록된 계정 정보와 계정 비밀번호를 안전하게 보관할 책임이
          있으며, 등록된 계정과 비밀번호 하에 행한 행위에 대한 법적 책임은
          귀하에게 있습니다. 귀하는 어떠한 경우에도 계정 또는 비밀번호 정보를
          타인에게 공개하지 않을 것에 동의합니다. 다른 사람이 귀하의 계정이나
          비밀번호를 사용하고 있다고 의심되는 경우 귀하는 즉시 Hainan Moti
          Culture Media Co., Ltd.에 알릴 것에 동의합니다.
        </p>
        <p>
          6.3 귀하는 본 계약의 조건을 준수하고 서비스를 올바르고 적절하게
          사용해야 합니다.본 계약의 조건을 위반하는 경우 Hainan Moti Culture
          Media Co., Ltd.는 귀하의 ODAILY 계정에 대한 서비스 제공을 종료할
          권리가 있습니다. 이 계약에 따라 제공합니다. 동시에 ODAILY는 언제든지
          ODAILY 계정 및 플랫폼 사용자 이름을 철회할 수 있는 권리를 보유합니다.
        </p>
        <h6>7. 【데이터 저장】</h6>
        <p>
          7.1 ODAILY는 이 서비스에서 관련 데이터의 삭제 또는 저장 실패에 대해
          책임을 지지 않습니다.
        </p>
        <p>
          7.2 ODAILY는 실제 상황에 따라 본 서비스에서 단일 플랫폼 사용자의
          데이터에 대한 최대 저장 기간을 결정하고 서버의 데이터에 대한 최대 저장
          공간을 할당할 권리가 있습니다. 필요에 따라 이 서비스에서 관련 데이터를
          백업할 수 있습니다.
        </p>
        <p>
          7.3 서비스 사용을 중단하거나 서비스가 종료 또는 취소되면 ODAILY는
          서버에서 데이터를 영구적으로 삭제할 수 있습니다. 서비스가 중지, 종료
          또는 취소된 후 ODAILY는 귀하에게 데이터를 반환할 의무가 없습니다.
        </p>
        <h6>8. 【위험 및 면책 조항】</h6>
        <p>
          8.1 귀하는 이 서비스가 플랫폼 사용자가 정보를 공유, 전파 및 획득할 수
          있는 플랫폼일 뿐이며 귀하가 콘텐츠의 진정성, 적법성, 정확성 및
          유효성을 포함하여 귀하의 등록된 계정에서 수행하는 모든 행동에 대해
          책임을 져야 함을 이해하고 이에 동의합니다. publish.sex, 본 서비스
          이용으로 인해 발생하는 결과를 부담합니다. 플랫폼 사용자는 ODAILY의
          콘텐츠를 스스로 판단해야 하며, 콘텐츠의 진정성, 적법성, 정확성 및
          유효성에 대한 의존으로 인해 발생하는 위험을 포함하여 콘텐츠 사용으로
          인해 발생하는 모든 위험을 감수해야 합니다. ODAILY는 플랫폼 사용자
          행동으로 인한 손실이나 손해에 대해 책임을 지지 않습니다.
        </p>
        <p>
          본 약관의 조항을 위반하거나 기타 부당한 방법으로 오데일리 서비스를
          이용하는 것을 발견한 경우 즉시 오데일리에 신고하거나 불만을 제기해
          주시면 법에 따라 처리하겠습니다.
        </p>
        <p>
          8.2 귀하는 비즈니스 개발 요구로 인해 ODAILY가 통지 없이 언제든지 이
          서비스의 서비스 콘텐츠 전체 또는 일부를 일방적으로 변경, 중단, 제한,
          종료 또는 취소할 수 있는 권리를 보유하며 플랫폼 사용자는 책임 위험.
        </p>
        <p>
          8.3 ODAILY가 발행한 모든 기사, 뉴스레터, 인용문, 보고서 등은 귀하를
          위한 투자 조언을 구성하지 않습니다. 귀하는 블록체인 및 디지털 통화의
          투자 위험을 완전히 인식하고 이해하며 모든 투자 문제는 귀하의 이해를
          기반으로 합니다.
        </p>
        <h6>9. 【지적 재산 진술서】</h6>
        <p>
          9.1 본 서비스에서 오데일리가 제공하는 컨텐츠(웹페이지, 텍스트, 그림,
          오디오, 비디오, 차트 등을 포함하되 이에 국한되지 않음)에 대한
          지적재산권은 오데일리에 귀속되며, 오데일리가 생성한 컨텐츠에 대한
          지적재산권은 이 서비스를 사용하는 플랫폼 사용자는 플랫폼에 속합니다.
          모든 권리는 사용자 또는 관련 권리 보유자에게 있습니다.
        </p>
        <p>
          9.2 별도의 명시가 없는 한, 오데일리가 본 서비스를 제공하는
          소프트웨어의 저작권, 특허권 및 기타 지적재산권은 오데일리가
          소유합니다.
        </p>
        <p>
          9.3 이 서비스에서 ODAILY가 사용하는 &quot;ODAILY&quot; 및 기타 상업용
          로고는 Hainan Moti Culture Media Co., Ltd.가 저작권 또는 상표권을
          소유합니다.
        </p>
        <p>
          9.4 위 내용 및 본 서비스에 포함된 기타 모든 내용에 대한 지적재산권은
          법률에 의해 보호되며, 오데일리, 플랫폼 이용자 또는 관련 권리자의 서면
          동의 없이는 누구든지 어떠한 형태로든 관련 2차적 저작물을 사용하거나
          생성할 수 없습니다.
        </p>
        <h6>X. 【법적 책임】</h6>
        <p>
          10.1 ODAILY는 플랫폼 이용자가 본 약관을 위반한 신고 또는 불만을
          타인으로부터 발견하거나 접수한 경우 언제든지 통지 없이 해당 콘텐츠를
          삭제할 권리가 있으며, 행위의 정황에 따라 위반 계정을 포함하되 이에
          국한되지 않는 처벌을 받을 수 있습니다. 경고, 일부 또는 전부 삭제 회원
          가입에 대한 페널티, 기능의 전부 또는 일부 사용 제한 또는 금지, 계정
          차단 또는 해지, 결과 발표.
        </p>
        <p>
          10.2 귀하는 오데일리가 관련 법령 또는 본 약관의 조항을 위반한 경우
          합리적인 판단에 따라 처벌하고, 법령을 위반한 자에 대해 적절한 법적
          조치를 취하고 관련 정보를 저장하여 관련 부서에 신고할 수 있는 권리가
          있음을 이해하고 이에 동의합니다. 법률 및 규정 등에 따라 플랫폼
          사용자는 이로 인해 발생하는 모든 법적 책임을 단독으로 집니다.
        </p>
        <p>
          10.3 귀하는 본 계약 또는 관련 조건의 위반으로 인해 발생하거나 발생하는
          합리적인 변호사 비용을 포함하여 제3자가 청구하는 모든 청구, 요구 또는
          손실에 대해 ODAILY 및 그 협력 회사 및 계열사에 보상해야 함을 이해하고
          이에 동의합니다. service.company의 피해로부터 보호합니다.
        </p>
        <h6>11. [약관의 효력 및 변경]</h6>
        <p>
          11.1 귀하의 서비스 사용은 본 계약을 읽고 동의한 것으로 간주됩니다.
        </p>
        <p>
          11.2 ODAILY는 필요한 경우 본 계약의 조건을 수정할 권리가 있습니다.
          최신 약관은 해당 서비스 페이지에서 확인하실 수 있습니다.
        </p>
        <p>
          11.3 본 약관이 변경된 후에도 오데일리 서비스를 계속 이용하는 경우
          개정된 약관에 동의한 것으로 봅니다. 개정된 약관에 동의하지 않으시면 본
          서비스 이용을 중단하셔야 합니다.
        </p>
        <h6>12. [기타]</h6>
        <p>12.1 본 계약의 서명 장소는 중화인민공화국 베이징 조양구입니다.</p>
        <p>
          12.2 본 계약의 성립, 발효, 이행, 해석 및 분쟁 해결은 중화인민공화국
          본토 법률(국제사법 제외)에 따릅니다.
        </p>
        <p>
          12.3 귀하와 ODAILY 사이에 분쟁이나 논란이 있는 경우 먼저 우호적인
          협상을 통해 해결해야 하며, 협상이 실패할 경우 본 계약이 체결된 장소의
          관할 인민 법원에 분쟁 또는 분쟁을 제출하는 데 동의합니다.
        </p>
        <p>
          12.4 본 계약의 모든 조항의 제목은 읽기의 편의를 위한 것이며 그
          자체로는 실질적인 의미가 없으며 본 계약의 의미 해석을 위한 근거로
          사용할 수 없습니다.
        </p>
        <p>
          12.5 본 계약의 어떤 부분이 어떤 이유로든 유효하지 않거나 시행할 수
          없는 경우에도 나머지 조건은 여전히 ​​유효하며 양 당사자를 구속합니다.
        </p>
        <p>12.6 본 계약은 2018년 5월 1일에 발효됩니다.</p>
      </div>
    );
  }
}

export default AgreementKo;
