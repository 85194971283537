import url from 'url';
import { getLanguage, languageArr } from '../../../server/languageCommon';
import axios from 'axios';
const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';
// 为baseurl拼接语言标识符
const webPath = (req, lang) => {
  const url = `${req.baseURL}`;
  const idx_host = url.indexOf('/');
  let host = '';
  if (idx_host > 1) {
    host = url.replace(/(https?:?\/\/[^/]+)\//, '$1' + lang + '/');
  } else {
    host = `${lang}${url}`;
  }
  req.baseURL = host;
  return req;
};

// 请求拦截器
const requestHandler = req => {
  // 前端语言标识符
  let lang = '';
  if (isBrowser) {
    const uri_path = url.parse(window.location.href).pathname;
    lang = getLanguage(uri_path);
  } else {
    lang = req.lang || '';
  }
  req.headers.common.s_time = new Date().getTime();
  if (languageArr().includes(lang)) {
    lang = lang ? `/${lang}` : lang;
    return Promise.resolve(webPath(req, lang));
  }
  if (process.env.NODE_ENV != 'production' || !isBrowser) {
    console.info('sssssss', req.url);
  }
  return Promise.resolve(req);
};
// 响应拦截器
const responseHandler = response => {
  if (process.env.NODE_ENV != 'production' || !isBrowser) {
    console.info(
      `当前接口为 ${
        response.config.url
      },请求花费时间为：${new Date().getTime() -
        response.config.headers.s_time}毫秒`
    );
  }
  const { status } = response;
  const { msg, code } = response.data;
  if (status >= 400 && status < 500) {
    return Promise.reject(msg);
  }
  if (code === 0 || code === 1) {
    return Promise.resolve(response.data);
  }
  return Promise.reject(response.data);
};

const errorHandler = error => {
  return Promise.reject(error);
};
const http = axios.create({
  baseURL: isBrowser
    ? '/api/pp/api'
    : `http://localhost:${process.env.PORT || 5030}/api/pp/api`
});
http.interceptors.request.use(requestHandler, errorHandler);
http.interceptors.response.use(responseHandler, errorHandler);
export default http;

export const service = axios.create({
  baseURL: isBrowser
    ? '/service'
    : `http://localhost:${process.env.PORT || 5030}/service`
});
service.interceptors.request.use(requestHandler, errorHandler);
service.interceptors.response.use(responseHandler, errorHandler);

export const passport = axios.create({
  baseURL: isBrowser
    ? `/passport`
    : `http://localhost:${process.env.PORT || 5030}/passport`
});
passport.interceptors.request.use(requestHandler, errorHandler);
passport.interceptors.response.use(responseHandler, errorHandler);

export const commentsList = axios.create({
  baseURL: isBrowser
    ? '/developer'
    : `http://localhost:${process.env.PORT || 5030}/developer`
});
commentsList.interceptors.response.use(
  response => {
    const { status } = response;
    const { msg } = response.data;
    if (status >= 400 && status < 500) {
      return Promise.reject(msg);
    }
    return Promise.resolve(response.data);
  },
  error => {
    return Promise.reject(error);
  }
);

export const wechat = axios.create({
  baseURL: isBrowser
    ? '/service'
    : `http://localhost:${process.env.PORT || 5030}/service`
});

wechat.interceptors.response.use(
  response => {
    const { status } = response;
    const { msg } = response.data;
    if (status >= 400 && status < 500) {
      return Promise.reject(msg);
    }
    return Promise.resolve(response.data);
  },
  error => {
    return Promise.reject(error);
  }
);
