exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-market-components-klineChart--klineChart-index {\n  width: 100%;\n  height: 100%;\n  cursor: pointer; }\n  .src-pages-market-components-klineChart--klineChart-index canvas {\n    cursor: pointer !important; }\n", ""]);

// exports
exports.locals = {
	"klineChart": "src-pages-market-components-klineChart--klineChart-index"
};