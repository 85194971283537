import { combineReducers } from 'redux';

const topPost = (state = [], action) => {
  switch (action.type) {
    case 'SET_TOPPOST':
      return action.list;
    default:
      return state;
  }
};

const topComment = (state = [], action) => {
  switch (action.type) {
    case 'SET_TOPCOMMEND':
      return action.list;
    default:
      return state;
  }
};

const newsList = (state = [], action) => {
  switch (action.type) {
    case 'SET_NEWS_LIST':
      return action.list;
    default:
      return state;
  }
};

const advertTop = (state = [], action) => {
  switch (action.type) {
    case 'SET_ADVERT_TOP':
      return action.list;
    default:
      return state;
  }
};

const advertMiddle = (state = [], action) => {
  switch (action.type) {
    case 'SET_ADVERT_MIDDLE':
      return action.list;
    default:
      return state;
  }
};

const advertBottom = (state = [], action) => {
  switch (action.type) {
    case 'SET_ADVERT_BOTTOM':
      return action.list;
    default:
      return state;
  }
};

const advertFeed = (state = [], action) => {
  switch (action.type) {
    case 'SET_ADVERT_FEED':
      return action.list;
    default:
      return state;
  }
};

const topPostList = (state = [{}, {}, {}], action) => {
  switch (action.type) {
    case 'SET_TOP_LIST':
      return action.list;
    default:
      return state;
  }
};

const hotNewsflashList = (state = [{}, {}, {}], action) => {
  switch (action.type) {
    case 'SET_HOT_NEWSFLASH':
      return action.list;
    default:
      return state;
  }
};

const tagList = (state = [], action) => {
  switch (action.type) {
    case 'SET_TAG_NEWSFLASH':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  topPost,
  topComment,
  newsList,
  advertTop,
  advertMiddle,
  advertBottom,
  advertFeed,
  topPostList,
  hotNewsflashList,
  tagList
});
