import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Helmet from 'react-helmet';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import { VideoDetail } from '../../components/video';
import ShareBar from '../../components/post/shareBar';
import PostRecommend from '../../components/post/recommend';
import NewAuthor from '../../components/NewAuthor';
import { HotPostItem } from '../../components/recommendPost/item';
import { RePost } from '../../components/recommendPost';
import AdPicture from '../../components/adPicture';
import Sticky from '../../components/common/sticky';
import cookies from 'js-cookie';
import { getHelmet } from '../../common/scripts/helmet';
import wechatShare from '../../common/scripts/wechat';

import {
  getVideoById,
  videoLike,
  videoCancelLike,
  videoCollect,
  videoUnfollow
} from '../../common/api/video';
import { getSecondaryList } from '../../common/api/columns';
import { getUserInfo, getUserPosts } from '../../common/api/user';
import { getAdvert } from '../../common/api/slide';
import { getTopPost } from '../../common/api/found';
import { loginFlag } from '../../common/scripts/login';

export class Video extends React.Component {
  static propTypes = {
    postDetail: PropTypes.object.isRequired,
    postUser: PropTypes.object.isRequired,
    postCounters: PropTypes.object.isRequired,
    advertBottom: PropTypes.array,
    match: PropTypes.object.isRequired,
    articleList: PropTypes.array.isRequired,
    topPost: PropTypes.array.isRequired,
    userPostList: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired
  };
  static fetchData(store, match, res) {
    let secondary_tags = [];
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      const videoId = match.params.id;
      if (_.isNaN(Number(videoId))) {
        res.redirect(postHref);
      }
      getVideoById(videoId, lang)
        .then(data => {
          const postData = _.get(data, 'data');
          const userId = _.get(postData, 'user_id');
          secondary_tags = postData.secondary_tags;
          store.dispatch({
            type: 'SET_POST_DETAIL',
            title: postData.title,
            published_at: postData.published_at,
            user: postData.user,
            summary: postData.summary,
            url: postData.url,
            secondary_tags: postData.secondary_tags,
            poster: postData.template_info.template_cover[0],
            like_status: postData.like_status,
            favorite_status: postData.favorite_status,
            id: postData.id,
            next_video: postData.next_video
          });
          store.dispatch({
            type: 'SET_POST_COUNTERS',
            counters: postData.counters
          });
          return getUserInfo(userId, lang);
        })
        .then(data => {
          const userData = _.get(data, 'data');
          store.dispatch({
            type: 'SET_POST_USER',
            id: userData.id,
            avatar: userData.avatar_url,
            name: userData.name,
            introduction: userData.introduction,
            title: userData.title,
            total: userData.counters.post_create
          });
          return getUserPosts(
            userData.id,
            undefined,
            undefined,
            undefined,
            lang
          );
        })
        .then(data => {
          const list = data.data.items.data;
          store.dispatch({
            type: 'SET_USER_POST',
            list: list
          });
          return getTopPost(undefined, lang);
        })
        .then(data => {
          const postList = data.data.items;
          store.dispatch({
            type: 'SET_TOPPOST',
            list: postList
          });
          return getAdvert('home_advertisement_bottom', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_BOTTOM',
            list: list
          });
          let slug = secondary_tags.filter(item => {
            return !!item.slug;
          });
          let slug_strs = '';
          if (Array.isArray(slug) && slug.length > 0) {
            let slug_arr = [];
            for (let key in slug) {
              slug_arr.push(slug[key]['slug']);
            }
            slug_strs = slug_arr.join(',');
            return getSecondaryList(slug_strs, 1, 10, lang);
          } else {
            resolve();
          }
        })
        .then(data => {
          if (data) {
            const list = data.data.items;
            store.dispatch({
              type: 'SET_POST_ARTICLELIST',
              list: list
            });
          }
          resolve();
        })
        .catch(error => {
          if (error.code === 22000) {
            res.redirect(postHref);
          }
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      favourCount: props.postCounters.like,
      isFavour: props.postDetail.like_status,
      isCollected: props.postDetail.favorite_status
    };
  }

  componentDidMount() {
    const { postDetail } = this.props;
    wechatShare({ title: `${postDetail.title}` });
  }

  isLogin() {
    return cookies.get('kr_plus_id');
  }

  toLogin() {
    const { dispatch } = this.props;
    loginFlag(dispatch, true);
  }

  favourHandler() {
    const postId = this.props.match.params.id;
    if (this.isLogin()) {
      if (this.state.isFavour) {
        videoCancelLike(postId).then(data => {
          if (data.code === 0) {
            this.setState({
              favourCount: Number(this.state.favourCount) - 1,
              isFavour: false
            });
          }
        });
      } else {
        videoLike(postId).then(data => {
          if (data.code === 0) {
            this.setState({
              favourCount: Number(this.state.favourCount) + 1,
              isFavour: true
            });
          }
        });
      }
    } else {
      this.toLogin();
    }
  }

  collectHandler() {
    const postId = this.props.match.params.id;
    const { isCollected } = this.state;
    if (this.isLogin()) {
      if (isCollected) {
        videoUnfollow(postId).then(data => {
          if (data.code === 0) {
            this.setState({
              isCollected: false
            });
          }
        });
      } else {
        videoCollect(postId).then(data => {
          if (data.code === 0) {
            this.setState({
              isCollected: true
            });
          }
        });
      }
    } else {
      this.toLogin();
    }
  }

  getHotPosts(value) {
    this.setState(
      {
        postType: value.value
      },
      () => {
        this.getTopPost();
      }
    );
  }

  getTopPost() {
    const { postType } = this.state;
    getTopPost(postType)
      .then(data => {
        this.props.dispatch({
          type: 'SET_TOPPOST',
          list: data.data.items
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  renderSecondary() {
    const { secondary_tags } = this.props.postDetail;

    return (
      <div className={styles.secondaryList}>
        {secondary_tags.map(item => {
          return (
            <a
              className={styles.secondaryItem}
              key={`secondaryItem${item.name}`}
              href={`/search/${item.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          );
        })}
      </div>
    );
  }

  renderShare() {
    const { postDetail } = this.props;
    let { favourCount, isFavour, isCollected } = this.state;

    return (
      <div className={styles.video_share}>
        <ShareBar
          title={postDetail.title}
          favourCount={favourCount}
          isFavour={isFavour}
          isCollected={isCollected}
          favourHandler={this.favourHandler.bind(this)}
          collectHandler={this.collectHandler.bind(this)}
        />
      </div>
    );
  }

  renderPostHot() {
    const postList = this.props.topPost;

    if (postList.length > 0) {
      return postList
        .filter((item, index) => {
          return index < 5;
        })
        .map((item, index) => {
          const props = Object.assign(item, {
            type: 'small',
            sort: index + 1
          });
          return <HotPostItem {...props} key={index} />;
        });
    }
  }

  render() {
    const {
      postDetail,
      articleList,
      postUser,
      userPostList,
      advertBottom
    } = this.props;
    const { next_video } = postDetail;

    let helmetData = getHelmet({
      title: `${postDetail.title}`,
      description: postDetail.summary
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.video}>
          <div className={styles.video_wrapper}>
            <div className={styles.video_left}>
              <VideoDetail {...postDetail} />
              <div className={styles.video_left_wrapper}>
                {postDetail && postDetail.summary ? (
                  <div className={styles.video_summary}>
                    {postDetail.summary}
                  </div>
                ) : (
                  ''
                )}

                {postDetail.secondary_tags && postDetail.secondary_tags.length
                  ? this.renderSecondary()
                  : ''}

                {this.renderShare()}
              </div>

              {articleList && articleList.length ? (
                <div className={styles.postRecommend}>
                  <PostRecommend
                    id={postDetail.id}
                    articlelist={articleList}
                    tags={postDetail.secondary_tags}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={styles.video_right}>
              <div className={styles.author}>
                <NewAuthor
                  {...postUser}
                  postid={postDetail.id}
                  userPostList={userPostList}
                />
              </div>

              <Sticky>
                <div
                  className={styles.RePost}
                  style={{
                    top:
                      advertBottom &&
                      advertBottom.length > 0 &&
                      advertBottom[0].url &&
                      advertBottom[0].cover
                        ? null
                        : '15px'
                  }}
                >
                  <RePost
                    getHotPosts={this.getHotPosts.bind(this)}
                    title="文章热榜"
                  >
                    {this.renderPostHot()}
                  </RePost>

                  {/* 下一篇 */}
                  {next_video ? (
                    <div className={styles.next_post}>
                      <div className={styles.next_post_title}>下一篇</div>

                      <div className={styles.next_post_text_wrapper}>
                        <a
                          className={styles.next_post_text}
                          href={`/video/${next_video.id}`}
                        >
                          {next_video.title}
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* 首页边栏-底部 */}
                  {advertBottom &&
                  advertBottom.length > 0 &&
                  advertBottom[0].url &&
                  advertBottom[0].cover ? (
                    <div className={styles.advertMiddle}>
                      <AdPicture
                        width="340px"
                        height="226px"
                        url={advertBottom[0].url}
                        img={advertBottom[0].cover}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Sticky>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    postDetail: state.post.detail,
    postUser: state.post.user,
    advertBottom: state.home.advertBottom,
    postCounters: state.post.counters,
    articleList: state.post.articleList,
    topPost: state.home.topPost,
    userPostList: state.post.userPostList
  };
};

export default withRouter(connect(mapStateToProps)(Video));
