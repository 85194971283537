import React from 'react';
import styles from './index.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';

export class Footer extends React.Component {
  static propTypes = {
    match: PropTypes.object
  };
  render() {
    const { lang } = this.props.match.params;
    return (
      <div className={styles.container}>
        <div className={styles.inner}>
          <ul className={styles['link-list']}>
            <li className={styles['link-item']}>
              <a className={styles.link} href="/aboutus?us">
                {locales(lang, 'aboutUs')}
              </a>
            </li>
            <li className={styles['link-item']}>
              <a className={styles.link} href="/aboutus?contact">
                {locales(lang, 'contactUs')}
              </a>
            </li>
            <li className={styles['link-item']}>
              <a className={styles.link} href="/aboutus?join">
                {locales(lang, 'joinUs')}
              </a>
            </li>
            <li className={styles['link-item']}>
              <a className={styles.link} href="/aboutus?privacy">
                {locales(lang, 'privacyPolicy')}
              </a>
            </li>
            <li className={styles['link-item']}>
              <a className={styles.link} href="/aboutus?agreement">
                {locales(lang, 'userAgreement')}
              </a>
            </li>
            <li className={styles['link-item']}>
              <a className={styles.link} href="/aboutus?friend" />
            </li>
            <li className={styles['link-item']}>
              <a className={styles.link} href="/aboutus?disclaimer">
                {locales(lang, 'disclaimer')}
              </a>
            </li>
          </ul>
          <p className={styles.copyright}>
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼ICP备 2022000863号-1{' '}
            </a>
            |{' '}
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼ICP备 2022000863号-2{' '}
            </a>
            |{' '}
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼ICP备 2022000863号-4{' '}
            </a>
            |{' '}
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010602001322"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼公网安备 46010602001322号{' '}
            </a>
            |{' '}
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010602001292"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              琼公网安备 46010602001292号
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
