import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

import styles from './index.scss';

import Layout from '../../components/common/layout';

import { getHelmet } from '../../common/scripts/helmet';

import {
  mainList,
  popularityList,
  transboundaryList,
  specialList,
  footerList
  // timerList
  // special
} from './data';
// import { getFatTable } from '../../common/api/columns';
// import { getVote } from '../../common/api/vote';

// import icon from '../../assets/image/2020榜单评选icon.png';
const wecode1 = require('../../assets/image/商务zl.png');
const wecode2 = require('../../assets/image/connie.png');
const wecode3 = require('../../assets/image/杨琳.png');

export class Fat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      specialListFlag0: false,
      specialListFlag1: false,
      popularityList: popularityList
    };
  }

  // componentDidMount() {
  //   let ary = [];
  //   getFatTable(2, 2, 0).then(data => {
  //     data.data.items.slice(0, 10).map(item => {
  //       let obj = {};
  //       obj.name = item.user_name;
  //       obj.url = item.avatar_url;
  //       ary.push(obj);
  //     });
  //     popularityList[popularityList.length - 1].imgList = ary;
  //     this.setState({
  //       popularityList
  //     });
  //   });

  //   getVote().then(data => {
  //     console.log(data);
  //     data.data.data.items.map((item, index) => {
  //       let ary = [];
  //       let num = index === 0 ? 20 : 10;
  //       item.items.slice(0, num).map(item => {
  //         let obj = {};
  //         obj.name = item.name;
  //         obj.url = item.picture_url;
  //         ary.push(obj);
  //       });
  //       popularityList[index].imgList = ary;
  //       this.setState({
  //         popularityList
  //       });
  //     });
  //   });
  // }

  changeSpecialList(flag) {
    const { specialListFlag0, specialListFlag1 } = this.state;
    if (flag === 0) {
      this.setState({
        specialListFlag0: !specialListFlag0
      });
    } else {
      this.setState({
        specialListFlag1: !specialListFlag1
      });
    }
  }

  renderMainList() {
    return (
      <ul className={styles.mainList_list}>
        {mainList.map((item, index) => {
          if (index === 1) {
            return (
              <li className={styles.mainList_item} key={`mainList${index}`}>
                <div className={styles.itemTitle}>
                  <img alt="" src={item.img} loading="lazy" />
                </div>
                <div className={styles.listTitle}>十大公链项目</div>
                <div className={styles.itemList2}>
                  {item.imgList.slice(0, 10).map(img => {
                    return (
                      <div className={styles.item2} key={`img${img.name}`}>
                        <div className={styles.itemImg2}>
                          <img alt="" src={img.url} loading="lazy" />
                        </div>
                        <div className={styles.itemName2}>{img.name}</div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.listTitle}>十大DeFi项目</div>
                <div className={styles.itemList2}>
                  {item.imgList.slice(10).map(img => {
                    return (
                      <div className={styles.item2} key={`img${img.name}`}>
                        <div className={styles.itemImg2}>
                          <img alt="" src={img.url} loading="lazy" />
                        </div>
                        <div className={styles.itemName2}>{img.name}</div>
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          } else {
            return (
              <li className={styles.mainList_item} key={`mainList${index}`}>
                <div className={styles.itemTitle}>
                  <img alt="" src={item.img} loading="lazy" />
                </div>
                <div className={styles.itemList}>
                  {item.imgList.map((img, i) => {
                    return (
                      <div className={styles.item} key={`img${i}`}>
                        <div className={styles.itemImg}>
                          <img src={img.url} loading="lazy" />
                        </div>
                        <div className={styles.itemName}>{img.name}</div>
                        <div className={styles.itemText}>{img.text}</div>
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          }
        })}
      </ul>
    );
  }

  renderPopularityList() {
    const { popularityList } = this.state;

    return (
      <ul className={styles.popularityList_list}>
        {popularityList.map((item, index) => {
          return (
            <li
              className={styles.popularityList_item}
              key={`popularityList${index}`}
            >
              <div className={styles.itemHeader}>
                <div className={styles.itemLeft}>
                  {item.title}
                  {/* <p className={styles.popularity_size}>
                    {index === 3
                      ? '将由社区投票及 “区块链KOL公信力榜单”年度数据综合决出'
                      : '将由社区投票决出'}
                  </p> */}
                  <p>{item.text}</p>
                </div>
                {/* <p className={styles.popularity_text}>
                  投票已结束，结果正在统计中……
                </p> */}
              </div>
              <div className={styles.imgList}>
                {item.imgList.map((img, index) => {
                  let crown = null;
                  switch (index) {
                    case 0:
                      crown = styles.crown1;
                      break;
                    case 1:
                      crown = styles.crown2;
                      break;
                    case 2:
                      crown = styles.crown3;
                      break;
                  }
                  return (
                    <div className={styles.imgItem} key={`imgList${index}`}>
                      <div className={styles.portrait}>
                        <div className={crown} />
                        {/* <div
                          className={styles.portrait_text}
                          style={{ display: img.url ? 'none' : null }}
                        >
                          +
                        </div> */}
                        <img src={img.url} loading="lazy" alt="" />
                      </div>
                      <div className={styles.name}>{img.name}</div>
                    </div>
                  );
                })}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  renderTransboundary(data) {
    return (
      <div className={styles.transboundaryList}>
        <div className={styles.itemTitle}>{data[0].title}</div>
        <div className={styles.itemText}>{data[0].text}</div>
        <div className={styles.transboundaryList}>
          {data[0].imgList.map((item, index) => {
            return (
              <div className={styles.imgItem} key={index}>
                <div className={styles.img}>
                  <img src={item.url} alt="" loading="lazy" />
                </div>
                <div className={styles.name}>{item.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderSpecialList(flag) {
    const { specialListFlag0, specialListFlag1 } = this.state;
    let list = [];
    if (flag === 0) {
      list = specialListFlag0 ? specialList[0] : specialList[0].slice(0, 25);
    } else {
      list = specialListFlag1 ? specialList[1] : specialList[1].slice(0, 25);
    }

    return (
      <div className={styles.specialList_list}>
        {list.map((item, index) => {
          return (
            <div
              className={styles.specialList_item}
              key={`specialList${index}`}
            >
              <div className={styles.specialList_img}>
                <img src={item.url} loading="lazy" />
              </div>
              <div className={styles.specialList_content}>
                <div className={styles.specialList_name}>{item.name}</div>
                <div className={styles.specialList_text}>{item.text}</div>
              </div>
            </div>
          );
        })}
        <div
          className={styles.specialList_button}
          onClick={() => {
            this.changeSpecialList(flag);
          }}
        >
          {flag === 0
            ? specialListFlag0
              ? '收起'
              : '展开'
            : specialListFlag1
            ? '收起'
            : '展开'}{' '}
          <div
            className={
              flag === 0
                ? specialListFlag0
                  ? styles.iconTop
                  : styles.iconBottom
                : specialListFlag1
                ? styles.iconTop
                : styles.iconBottom
            }
          />
        </div>
      </div>
    );
  }

  renderFooterList(data = {}) {
    return (
      <div className={styles.footerList}>
        <div className={styles.footer_title}>{data.title}</div>
        <div className={styles.imgList}>
          {data.items.map((item, index) => {
            return <img src={item} key={`footer_img${index}`} loading="lazy" />;
          })}
        </div>
      </div>
    );
  }

  render() {
    let helmetData = getHelmet({
      title: '区块链游戏,区块链新闻资讯,Defi,NFT',
      description:
        '时值岁末，ODAILY将再次携手权威专家学者，开启 2020「FAT」价值时代榜单评选，用最客观的数据、最专业的眼光、最权威的视角呈现区块链行业最具价值榜单的诞生。',
      keywords: '区块链游戏,区块链新闻资讯,Defi,NFT'
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* 头部 */}
            <div className={styles.herder}>
              <div className={styles.herder_img} />
              <div className={styles.header_text}>
                <p>
                  2020
                  对于区块链行业而言，注定是不平凡的一年，也是浓墨重彩的一年：
                </p>
                <p>
                  比特币减半、央行数字货币试点、去中心化金融爆发、NFT
                  加密艺术品鉴赏……
                </p>
                <p>在 2020 年的整个故事里，充满着复杂的环境和重建的认知。</p>
                <p>
                  <br />
                  而“价值”，成为所有人紧盯着的共识。
                </p>
                <p>区块链的商业价值被发掘，技术价值被肯定。</p>
                <p>
                  无论主动还是被动，区块链的价值发展和技术创新包裹着我们每一个人。
                </p>
                <p>
                  在这个价值所趋的时代，我们想要致敬不畏浪潮的王者、挖掘行业的坚守者、表彰勇于探索的创变者、寻觅贡献突出的领航者。
                </p>
                <p>
                  <br />
                  一年前，「FAT」被赋予了新的意义。
                </p>
                <p>
                  OdailyODAILY以「Finance And
                  Technology」的视角，见证了区块链的荣耀和光芒。
                </p>
                <p className={styles.colorSize}>
                  时值岁末，ODAILY将再次携手权威专家学者，开启
                  2020「FAT」价值时代榜单评选，
                </p>
                <p className={styles.colorSize}>
                  用最客观的数据、最专业的眼光、最权威的视角呈现区块链行业最具价值榜单的诞生。
                </p>
              </div>
            </div>
            {/* 主榜单 */}
            <div className={styles.mainList}>
              <div className={styles.mainList_header}>
                <div className={styles.mainList_title}>
                  <h2>年度权威主榜单</h2>
                </div>
              </div>
              {this.renderMainList()}
            </div>
            {/* 引领热度榜 */}
            <div className={styles.popularityList}>
              <div className={styles.popularityList_header}>
                <div className={styles.popularityList_title}>
                  <h2>引领热度榜</h2>
                  <p>候选征集、社区投票同步进行中...</p>
                </div>
              </div>
              {this.renderPopularityList()}
            </div>
            {/* 跨界榜 */}
            <div className={styles.transboundary}>
              <div className={styles.transboundary_wrapper}>
                <div className={styles.transboundary_title}>
                  <h2>跨界榜</h2>
                </div>
                {this.renderTransboundary(transboundaryList)}
              </div>
              <div className={styles.decorate_left} />
              <div className={styles.decorate_right} />
            </div>
            {/* 特别榜 */}
            <div className={styles.specialList}>
              <div className={styles.specialList_title}>
                <h2>特别榜</h2>
              </div>
              <div className={styles.specialList_header}>
                <div className={styles.headerTitle}>坚守价值行业OG</div>
                <div className={styles.headerText}>
                  他们在区块链世界，坚守信念，深耕行业5年以上，他们能在纷繁的环境中找到钥匙，依靠强有力的行动，不断向前开拓创新，带领行业前行。
                </div>
              </div>
              {this.renderSpecialList(0)}
              <div className={styles.specialList_header}>
                <div className={styles.headerTitle}>90后杰出从业者</div>
                <div className={styles.headerText}>
                  他们充满创意、热情和行动力，志在用最前沿的技术打破原有的商业格局和既定规则，在区块链的世界中竖立起新的旗帜。
                </div>
              </div>
              {this.renderSpecialList(1)}
              {/* {this.renderTransboundary(special)} */}
            </div>
            {/* 尾部 */}
            <div className={styles.footer}>
              <div className={styles.footer_wrapper}>
                <div className={styles.footer_left}>
                  {footerList.map(item => {
                    return this.renderFooterList(item);
                  })}
                </div>
                <div className={styles.footer_right}>
                  <div className={styles.footerTitle}>活动合作请添加微信：</div>
                  <div className={styles.ewmList}>
                    <img src={wecode1} alt="" loading="lazy" />
                    <img src={wecode2} alt="" loading="lazy" />
                  </div>
                  <div className={styles.footerTitle}>媒体及其他合作：</div>
                  <div className={styles.ewmList}>
                    <img src={wecode3} alt="" loading="lazy" />
                  </div>
                  <div className={styles.footerHref}>
                    <p>高峰论坛暨颁奖典礼报名入口：</p>
                    <a
                      href="https://www.huodongxing.com/event/9571316598300"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      https://www.huodongxing.com/event/9571316598300
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* 往期榜单 */}
            <div className={styles.activityedFag}>
              <div className={styles.activityedFag_title}>
                <h2>往期榜单</h2>
              </div>
              <div className={styles.activityedFag_img}>
                <a href="/activityedfat" target={'_blank'} rel="noreferrer" />
              </div>
            </div>
            {/* 弹出框 */}
            <div className={styles.alert}>
              <div className={styles.alertList}>
                <a href="/activityed2020" className={styles.alertItem}>
                  区块链胖榜单
                </a>
                <div className={styles.border} />
                <a href="/Activityed#3" target={'_blank'} rel="noreferrer">
                  区块链金融科技领先100强
                </a>
                <div className={styles.border} />
                <a href="/Activityed#4" target={'_blank'} rel="noreferrer">
                  Filecoin挖矿厂商榜
                </a>
                <div className={styles.border} />
                <a href="/Activityed#1" target={'_blank'} rel="noreferrer">
                  星球专栏榜单
                </a>
                <div className={styles.border} />
                <a href="/Activityed#2" target={'_blank'} rel="noreferrer">
                  区块链KOL公信榜
                </a>
                <div className={styles.border} />
                <a href="/AcyivityVote" target={'_blank'} rel="noreferrer">
                  N—POWER榜单
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default withRouter(Fat);
