import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { dot } from '../../common/scripts/dot';
import { withRouter } from 'react-router-dom';

const IconArrowPrev =
  'https://piccdn.0daily.com/202211/30062717/wh052298ue444y9j.png';
const IconArrowNext =
  'https://piccdn.0daily.com/202211/30062717/2h2krbcqp8vr9dzc.png';

export class Slide extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    font: PropTypes.number.isRequired,
    type: PropTypes.string,
    currentUser: PropTypes.object,
    match: PropTypes.object
  };
  state = {
    interval: 5000,
    speed: 300,
    activeIndex: 0,
    data: [...this.props.list, this.props.list[0]],
    pausedFlag: false,
    classFlag: false,
    jianFlag: false,
    previous: 0
  };

  componentDidMount() {
    let { interval } = this.state;
    this.autoTimer = setInterval(this.autoMove.bind(this), interval);
  }

  autoMove() {
    let { activeIndex, speed, data } = this.state;
    activeIndex++;
    this.setState({
      classFlag: false
    });
    if (activeIndex >= data.length) {
      console.info('autoMove11');
      this.setState(
        {
          activeIndex: 0,
          speed: 0
        },
        () => {
          let a = this.wrapper.offsetLeft;
          activeIndex = 1;
          this.setState({
            activeIndex,
            speed,
            a
          });
        }
      );
      return;
    }
    this.setState({
      activeIndex,
      speed
    });
  }

  focusChange(index) {
    let { speed } = this.state;
    this.setState({
      activeIndex: index,
      speed
    });
  }

  buttonChange(dir) {
    let { activeIndex, data, speed, previous } = this.state;
    let now = Date.now();
    if (now - previous < speed) {
      return;
    }
    this.setState({
      previous: now
    });
    if (dir === 'left') {
      activeIndex--;
      if (activeIndex < 0) {
        this.setState(
          {
            activeIndex: data.length - 1,
            speed: 0
          },
          () => {
            let a = this.wrapper.offsetLeft;
            this.setState({
              activeIndex: data.length - 2,
              speed,
              a
            });
          }
        );
        return;
      }
      this.setState({
        activeIndex,
        speed
      });
      return;
    }

    setTimeout(() => {
      this.autoMove();
    }, 0);
  }

  renderSlideList() {
    let { data } = this.state;
    let { width, type, match } = this.props;
    const { lang } = match.params;
    return data.map((item, index) => {
      let listUrl = item.entity_type
        ? item.url
        : lang
        ? `/${lang}/topic/${item.id}`
        : `/topic/${item.id}`;
      return (
        <div
          className={styles.slide}
          key={index}
          style={{ width: `${width}px` }}
        >
          <a
            href={listUrl}
            target={'_blank'}
            rel="noreferrer"
            onClick={() => {
              dot({ id: 21, user_id: this.props.currentUser.id });
            }}
          >
            <img
              src={
                type === 'small'
                  ? item.template_info.template_cover[0]
                  : item.cover
              }
              alt={item.title}
              loading="lazy"
            />
            {/* 判断是否需要隐藏标题 */}
            {item.hidden_title ? (
              ''
            ) : (
              <div
                className={styles.content}
                style={{
                  paddingBottom: type === 'small' ? '28px' : null,
                  display:
                    item.title.replace(/(^\s*)|(\s*$)/g, '').length == 0
                      ? 'none'
                      : null
                }}
              >
                <h4
                  className={styles.title}
                  style={{
                    fontSize: `${this.props.font}px`,
                    lineHeight: `${this.props.font + 6}px`
                  }}
                >
                  {item.title}
                </h4>
              </div>
            )}
          </a>
        </div>
      );
    });
  }

  renderPagination() {
    let { activeIndex, jianFlag } = this.state;
    let { list, type } = this.props;
    activeIndex === list.length ? (activeIndex = 0) : null;
    return (
      <div
        className={
          type === 'small' ? styles.paginationSmall : styles.pagination
        }
        style={{ display: jianFlag ? null : 'none' }}
      >
        {list.map((item, index) => {
          return (
            <span
              className={activeIndex === index ? styles.active : null}
              key={index}
              onClick={() => {
                this.focusChange(index);
              }}
            />
          );
        })}
      </div>
    );
  }

  render() {
    let { activeIndex, speed, data, interval, jianFlag } = this.state;
    let { width, height, type } = this.props;
    return (
      <div
        className={styles.container}
        style={{ width: `${width}px`, height: `${height}px` }}
        onMouseEnter={() => {
          clearInterval(this.autoTimer);
          this.setState({
            pausedFlag: true,
            classFlag: false,
            jianFlag: true
          });
        }}
        onMouseLeave={() => {
          this.autoTimer = setInterval(this.autoMove.bind(this), interval);
          this.setState({
            pausedFlag: false,
            classFlag: true,
            jianFlag: false
          });
        }}
      >
        <div
          className={styles.main}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <div
            className={styles.wrapper}
            ref={e => (this.wrapper = e)}
            style={{
              width: data.length * width,
              left: -activeIndex * width,
              transition: `left ${speed}ms linear`
            }}
          >
            {this.renderSlideList()}
          </div>
        </div>

        <div
          className={type === 'small' ? styles.small : styles.button}
          style={{ display: jianFlag ? 'block' : 'none' }}
        >
          <a
            href="javascript:;"
            className={styles.buttom_prev}
            onClick={() => {
              this.buttonChange('left');
            }}
          >
            <img src={IconArrowPrev} alt="" loading="lazy" />
          </a>
          <a
            href="javascript:;"
            className={styles.buttom_next}
            onClick={() => {
              this.buttonChange('right');
            }}
          >
            <img src={IconArrowNext} alt="" loading="lazy" />
          </a>
        </div>
        {this.renderPagination()}
      </div>
    );
  }
}

export default withRouter(Slide);
