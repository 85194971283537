import React from 'react';
import styles from './index.scss';
import PropTypes from 'prop-types';
import { dot } from '../../common/scripts/dot';
import { withRouter } from 'react-router-dom';
import locales from '../../utils/locales';

// import _ from 'lodash';
class Recommend extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { type, list } = this.props;
    const { lang } = this.props.match.params;
    const videoHref = lang ? `/${lang}/video` : '/video';
    const topicHref = lang ? `/${lang}/topic` : '/topic';
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {type === 'video'
              ? locales(lang, 'video_recommend', { isTitle: true })
              : locales(lang, 'feat_Topic', { isTitle: true })}
          </div>
          <a
            className={styles.button}
            href={type === 'video' ? videoHref : topicHref}
            target={'_blank'}
            rel="noreferrer"
            onClick={() => {
              dot({
                id: type === 'video' ? 19 : 18,
                user_id: this.props.currentUser.id
              });
            }}
          />
        </div>
        <div className={styles.list}>
          {list.map(item => {
            let img = JSON.parse(item.images)[0];
            return (
              <a
                className={styles.item_wrapper}
                href={
                  type === 'video'
                    ? `${videoHref}/${item.entity_id}`
                    : `${topicHref}/${item.entity_id}`
                }
                target={'_blank'}
                rel="noreferrer"
                key={`list${item.entity_id}`}
                style={{ height: type === 'video' ? '226px' : '254px' }}
              >
                {type === 'video' ? (
                  <div className={styles.videoItem}>
                    <div
                      className={styles.video_wrapper}
                      style={{ backgroundImage: `url(${img})` }}
                    >
                      <div className={styles.video_icon} />
                    </div>
                    <div className={styles.videoItem_size}>
                      <p style={{ WebkitBoxOrient: 'vertical' }}>
                        {item.title}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.item}>
                    <div className={styles.item_img}>
                      <img src={img} alt={item.title} loading="lazy" />
                    </div>

                    <div className={styles.item_size}>
                      <p style={{ WebkitBoxOrient: 'vertical' }}>
                        {item.title}
                      </p>
                    </div>
                  </div>
                )}
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(Recommend);
