exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body {\n  font-family: \"PingFang SC\", \"Lantinghei SC\",  \"Helvetica Neue\", \"Helvetica\", \"Arial\", \"Microsoft YaHei\"; }\n  body #src-styles--root-index {\n    position: relative; }\n", ""]);

// exports
exports.locals = {
	"root": "src-styles--root-index"
};