import http, { service } from '../scripts/http';

export function getTopicById(topicId, lang) {
  return http.get(`/monographic/${topicId}`, { lang });
}

export function getTopicCollectInfo(topicId) {
  return http.get(`/favorite-status?fid=${topicId}&ftype=monographic`);
}

export function postTopicCollect({ fid, direction }) {
  const data = {
    fid,
    direction,
    ftype: 'monographic'
  };
  return http.post('/favorite', data);
}

// export function getTopic() {
//   return service.get('/monographic')
// }

export const getTopic = () => service.get('/monographic');
