const slide = (state = [], action) => {
  switch (action.type) {
    case 'SET_SLIDE':
      return action.list;
    default:
      return state;
  }
};

export default slide;
