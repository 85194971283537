import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { dot } from '../../common/scripts/dot';
import { withRouter } from 'react-router-dom';
import locales from '../../utils/locales';

export class Tab extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    currentUser: PropTypes.object,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonFlag: true,
      scrollButtonFlag: false
    };
  }

  componentDidMount() {
    this.setState({
      buttonFlag:
        this['tabs-nav-list'].offsetWidth <
        this['tabs-nav-list-wrapper'].scrollWidth
    });
  }

  tabClick(item) {
    let { currentUser } = this.props;
    dot({ id: 23, user_id: currentUser.id, extra: { feed_id: item.id } });
  }

  navScrollLeft() {
    let scroll =
      this['tabs-nav-list'].scrollLeft + this['tabs-nav-list'].clientWidth;
    this['tabs-nav-list'].scrollTo({
      left: scroll,
      behavior: 'smooth'
    });
  }

  navScrollRight() {
    console.log('q');
    let listOffsetWidth = this['tabs-nav-list'].offsetWidth;
    let listScrollLeft = this['tabs-nav-list'].scrollLeft;

    this['tabs-nav-list'].scrollTo({
      left: listScrollLeft - listOffsetWidth,
      behavior: 'smooth'
    });
  }

  changeScrollButton() {
    let listScrollLeft = this['tabs-nav-list'].scrollLeft;
    if (listScrollLeft >= 62) {
      this.setState({
        scrollButtonFlag: true
      });
    } else {
      this.setState({
        scrollButtonFlag: false
      });
    }
  }

  render() {
    const { list, match } = this.props;
    const { buttonFlag, scrollButtonFlag } = this.state;
    const { lang } = match.params;
    return (
      <div className={styles['tabs-nav']}>
        <div
          className={
            scrollButtonFlag
              ? styles['tabs-nav-left_button_wrapper']
              : styles['tabs-nav-left']
          }
          onClick={() => {
            scrollButtonFlag ? this.navScrollRight() : '';
          }}
        >
          {/* 推荐标签 */}
          <div
            className={styles.text}
            style={{ display: scrollButtonFlag ? 'none' : 'block' }}
          >
            {locales(lang, 'rec_Tags', { isTitle: true })}
          </div>
          {/* 按钮点击 */}
          <div
            className={styles['tabs-nav-left_button']}
            style={{ width: scrollButtonFlag ? null : 0 }}
          />
        </div>

        <div
          className={styles['tabs-nav-list']}
          ref={e => {
            this['tabs-nav-list'] = e;
          }}
          onScroll={() => {
            this.changeScrollButton();
          }}
          style={{ width: scrollButtonFlag ? '712px' : null }}
        >
          <div
            className={styles['tabs-nav-list-wrapper']}
            ref={e => {
              this['tabs-nav-list-wrapper'] = e;
            }}
          >
            {list.map(item => {
              return (
                <a
                  className={styles.nav}
                  href={`/search/${item.name}`}
                  key={`nav${item.name}`}
                  rel="noreferrer"
                  target={'_blank'}
                  onClick={() => {
                    this.tabClick(item);
                  }}
                >
                  {item.name}
                </a>
              );
            })}
          </div>
        </div>

        {buttonFlag ? (
          <div className={styles['tabs-nav-right-wrapper']}>
            <div
              className={styles['tabs-nav-right']}
              onClick={() => {
                this.navScrollLeft();
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default withRouter(Tab);
