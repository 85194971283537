import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

export class TopicItem extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number,
    cover: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    highlight: PropTypes.object,
    highlightTitle: PropTypes.string
  };
  renderHighlightTitle() {
    const markup = () => {
      if (this.props.highlight.title) {
        return {
          __html: this.props.highlight.title[0]
        };
      } else {
        return {
          __html: this.props.title
        };
      }
    };
    return <h4 className={styles.title} dangerouslySetInnerHTML={markup()} />;
  }
  render() {
    const { id, cover, title } = this.props;
    return (
      <div className={styles.container}>
        <a
          href={`/topic/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.video_wrapper}
        >
          <div
            className={styles['video_cover']}
            style={{ backgroundImage: `url(${cover})` }}
          />

          <div className={styles['video_intro']}>
            <div
              className={styles['video_title']}
              dangerouslySetInnerHTML={{
                __html: title
              }}
              style={{ WebkitBoxOrient: 'vertical' }}
            />
          </div>
        </a>
      </div>
    );
  }
}
