import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadMore from '../../components/common/homeloadmore';
import styles from './index.scss';
import Layout from '../../components/common/layout';
import Helmet from 'react-helmet';
import { getVideoList } from '../../../src/common/api/found';
import wechatShare from '../../common/scripts/wechat';
import { getHelmet } from '../../common/scripts/helmet';

export class VideoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      page: 1,
      topicList: [],
      total: 0
    };
  }
  componentDidMount() {
    getVideoList(this.state.page).then(data => {
      this.setState({
        topicList: data.data.items,
        total: data.data.total_count,
        page: this.state.page + 1
      });
    });
    wechatShare({});
  }
  renderTopic() {
    const { topicList } = this.state;
    return topicList.map((item, index) => {
      return (
        <a
          className={styles.item_wrapper}
          href={`/video/${item.entity_id}`}
          target={'_blank'}
          rel="noreferrer"
          key={`list${index}`}
        >
          <div className={styles.videoItem}>
            <div
              className={styles.video_wrapper}
              style={{
                backgroundImage: `url(${item.template_info.template_cover[0]})`
              }}
            >
              <div className={styles.video_icon} />
            </div>
            <div className={styles.videoItem_size}>
              <p style={{ WebkitBoxOrient: 'vertical' }}>{item.title}</p>
            </div>
          </div>
        </a>
      );
    });
  }
  handleLoadMore() {
    const { topicList, total, page } = this.state;
    this.setState({
      loading: true
    });
    let num = page;
    if (num >= Math.ceil(total / 9)) {
      this.setState({
        loading: true,
        loaded: true
      });
    }
    getVideoList(num, topicList[topicList.length - 1].id).then(data => {
      this.setState({
        topicList: [...topicList, ...data.data.items],
        total: data.data.total_count,
        loading: false,
        page: page + 1
      });
      //   this.forceUpdate();
    });
  }
  render() {
    let helmetData = getHelmet({});
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles['container']}>
          <div className={styles.cardContainer}>
            {/* 专题列表 */}
            <div className={styles.video_list}>{this.renderTopic()}</div>
            <div className={styles.LoadMore}>
              <LoadMore
                loading={this.state.loading}
                loaded={this.state.loaded}
                onClick={this.handleLoadMore.bind(this)}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(connect()(VideoList));
