import React, { Component } from 'react';
import styles from '../../index.scss';

export class InfoShareZhtw extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>信息共享清單</h5>
        <p>為使您更清晰地了解我們有關個人信息共享情況，制定下述清單：</p>
        <h6>一、 第三方SDK名單</h6>
        <table>
          <tr>
            <td>
              <p>分享</p>
            </td>
            <td>
              <p>分享</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>微信</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>為用戶提供第三方登錄功能，幫助用戶分享內容到微信</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>深圳市騰訊計算機系統有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>分享</p>
            </td>
            <td>
              <p>分享</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>新浪</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>為用戶提供第三方登錄功能，幫助用戶分享內容到新浪</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>北京微夢創科網絡技術有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>400-096-0960</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://weibo.com/signup/v5/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://weibo.com/signup/v5/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>推送</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名稱</p>
            </td>
            <td>
              <p>使用目的</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相關資訊內容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>每日互動股份有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>support@getui.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://docs.getui.com/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.getui.com/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>推送</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名稱</p>
            </td>
            <td>
              <p>使用目的</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相關資訊內容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>深圳市和訊華谷信息技術有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>sales@jiguang.cn</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.jiguang.cn/license/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.jiguang.cn/license/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>推送</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名稱</p>
            </td>
            <td>
              <p>使用目的</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相關資訊內容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>華為軟件技術有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>推送</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>小米</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相關資訊內容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>小米移動軟件有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.mi.com/support"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.mi.com/support
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://dev.mi.com/console/doc/detail?pId=1822"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://dev.mi.com/console/doc/detail?pId=1822
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>推送</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>OPPO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相關資訊內容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>廣東歡太科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://brand.heytap.com/privacy-feedback.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://brand.heytap.com/privacy-feedback.html
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://open.oppomobile.com/wiki/doc#id=10288"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://open.oppomobile.com/wiki/doc#id=10288
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>推送</p>
            </td>
            <td>
              <p>推送</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>推送相關資訊內容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>維沃移動通信有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>privacy@vivo.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.vivo.com.cn/about-vivo/privacy-policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>定位</p>
            </td>
            <td>
              <p>定位</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>高德</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>為用戶提供所在地資訊內容</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息，及開啟定位權限下的GPS信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>高德軟件有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>Privacy.Amap@service.autonavi.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://lbs.amap.com/pages/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://lbs.amap.com/pages/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能類型</p>
            </td>
            <td>
              <p>一鍵登錄</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名稱</p>
            </td>
            <td>
              <p>使用目的</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>為用戶提供免驗證碼登錄服務</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息、本機號碼</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>中國移動通信集團有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>10086</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="http://dev.10086.cn/docInside?contentId=10000009826805"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  http://dev.10086.cn/docInside?contentId=10000009826805
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>分享</p>
            </td>
            <td>
              <p>分享</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>幫助用戶分享ODAILY內容到QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>深圳市騰訊計算機系統有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能類型</p>
            </td>
            <td>
              <p>數據統計</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>友盟</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>數據統計分析</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>友盟同欣（北京）科技有限公司</p>
            </td>
            <td>
              <p>友盟同欣（北京）科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>Umeng_Legal@service.umeng.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.umeng.com/page/policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.umeng.com/page/policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能類型</p>
            </td>
            <td>
              <p>數據統計</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK名稱</p>
            </td>
            <td>
              <p>使用目的</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>數據統計分析</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>華為軟件技術有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能類型</p>
            </td>
            <td>
              <p>收集日誌</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>bugly</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>收集App崩潰信息，便於我們定位問題</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>深圳市騰訊計算機系統有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>功能類型</p>
            </td>
            <td>
              <p>一鍵安裝App</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>openinstall</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>使用目的</p>
            </td>
            <td>
              <p>幫助用戶實現快速跳轉和一鍵下載並安裝App</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>個人信息字段</p>
            </td>
            <td>
              <p>設備信息</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>處理方式</p>
            </td>
            <td>
              <p>共享方式</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>共享方式</p>
            </td>
            <td>
              <p>SDK本機採集</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>主體名稱</p>
            </td>
            <td>
              <p>深圳市分秒網絡科技有限公司</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>聯繫方式</p>
            </td>
            <td>
              <p>cooperation@openinstall.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>隱私政策</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.openinstall.io/privacy.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.openinstall.io/privacy.html
                </a>
              </p>
            </td>
          </tr>
        </table>

        <h6>二、第三方合作機構名單</h6>
        <p>
          我們暫時沒有和第三方合作機構共享您的個人信息，若有上述情況，我們會更新此名單並取得您的授權同意。
        </p>

        <h6>三、關聯公司名單</h6>
        <p>
          我們暫時沒有和關聯公司共享您的個人信息，若有上述情況，我們會更新此名單並取得您的授權同意。
        </p>
      </div>
    );
  }
}

export default InfoShareZhtw;
