exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-common-footer--container-index {\n  background-color: #1D273D;\n  width: 100%; }\n  .src-components-common-footer--container-index .src-components-common-footer--inner-index {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    margin: 0 auto;\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n    padding: 0 20px; }\n  .src-components-common-footer--container-index .src-components-common-footer--link-list-index {\n    -ms-flex: 1 1;\n        flex: 1 1;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: row;\n        flex-direction: row;\n    margin-right: 20px;\n    list-style: none; }\n  .src-components-common-footer--container-index .src-components-common-footer--link-item-index {\n    margin: 0 10px;\n    line-height: 60px;\n    width: 56px; }\n  .src-components-common-footer--container-index .src-components-common-footer--link-index {\n    color: #A8AAB0;\n    font-size: 14px;\n    text-decoration: none; }\n  .src-components-common-footer--container-index .src-components-common-footer--link-index:hover {\n    color: #fff; }\n  .src-components-common-footer--container-index .src-components-common-footer--copyright-index {\n    color: #A8AAB0;\n    font-size: 14px;\n    line-height: 20px; }\n    .src-components-common-footer--container-index .src-components-common-footer--copyright-index a {\n      text-decoration: none;\n      color: #A8AAB0; }\n    .src-components-common-footer--container-index .src-components-common-footer--copyright-index a:hover {\n      color: #fff; }\n", ""]);

// exports
exports.locals = {
	"container": "src-components-common-footer--container-index",
	"inner": "src-components-common-footer--inner-index",
	"link-list": "src-components-common-footer--link-list-index",
	"link-item": "src-components-common-footer--link-item-index",
	"link": "src-components-common-footer--link-index",
	"copyright": "src-components-common-footer--copyright-index"
};