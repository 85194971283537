import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';
// const IconLoading = require('./images/icon-loading.png');
export class HomeLoadMore extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool,
    onClick: PropTypes.func,
    background: PropTypes.string,
    match: PropTypes.object
  };
  static defaultProps = {
    loading: true,
    loaded: false
  };
  renderLoading(lang) {
    return (
      <p className={styles.tips}>
        {/* <img src={IconLoading} alt="加载中" /> */}
        {locales(lang, 'loading')}
      </p>
    );
  }
  renderMore(lang) {
    return <p className={styles.tips}>{locales(lang, 'seeMore')}</p>;
  }
  renderLoaded(lang) {
    return <p className={styles.tips}>{locales(lang, 'nomore')}</p>;
  }
  onTrigger() {
    const props = this.props;
    if (_.isFunction(props.onClick) && !props.loaded && !props.loading) {
      props.onClick();
    }
  }
  render() {
    const props = this.props;
    const { lang: routeLang } = this.props.match.params;
    const lang = routeLang;
    if (props.loaded && !props.loading) {
      return null;
    }
    return (
      <div
        className={props.background ? styles.loadmorebg : styles.loadmore}
        style={{ background: props.background ? props.background : null }}
        onClick={this.onTrigger.bind(this)}
      >
        {props.loading && !props.loaded ? this.renderLoading(lang) : null}
        {!props.loaded && !props.loading ? this.renderMore(lang) : null}
      </div>
    );
  }
}

export default withRouter(HomeLoadMore);
