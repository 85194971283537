import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Helmet from 'react-helmet';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import { PostItem } from '../../components/post';
// import { ViderItem } from '../../components/video/feed';
import Author from '../../components/author';
import LoadMore from '../../components/common/loadmore';
import { getUserPosts, getUserInfo } from '../../common/api/user';
import { getConcern } from '../../common/api/post';
import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

export class User extends React.Component {
  static propTypes = {
    userPosts: PropTypes.array.isRequired,
    userInfo: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };
  static fetchData(store, match, res) {
    const userId = match.params.id;
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      getUserInfo(userId, lang)
        .then(data => {
          const userData = data.data;
          store.dispatch({
            type: 'SET_USER_INFO',
            avatar: userData.avatar_url,
            name: userData.name,
            introduction: userData.introduction,
            title: userData.title,
            total: userData.counters.post_create
          });
          return getUserPosts(userId, 1, undefined, undefined, lang);
        })
        .then(data => {
          store.dispatch({
            type: 'SET_USER_POSTS',
            list: data.data.items || []
          });
          resolve();
        })
        .catch(() => {
          res.redirect(postHref);
        });
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      concern: false,
      page: 2,
      userPosts: this.props.userPosts,
      postList: props.userPosts.data
    };
  }

  renderPosts() {
    if (!this.state.userPosts) {
      return null;
    }

    const data = this.state.postList;

    return data.map(post => {
      if (post.entity_type === 'post') {
        //   return <ViderItem key={post.entity_id} {...post} />;
        // } else {
        return <PostItem key={post.entity_id} {...post} />;
      }
    });
  }
  loadMore() {
    const { match, userPosts } = this.props;
    const { page, postList } = this.state;
    const userId = match.params.id;
    const lastOfUserPosts = _.last(userPosts);
    const bId = _.get(lastOfUserPosts, 'id');

    this.setState({
      loading: true
    });

    getUserPosts(userId, page, bId, undefined, match.params.lang)
      .then(data => {
        if (!data.data.items) {
          this.setState({
            loaded: true
          });
          return false;
        }
        this.setState({ userPosts: data.data.items });
        if (data.data.items.data.length) {
          data.data.items.data.map(item => {
            postList.push(item);
          });
          this.setState({
            postList,
            page: page + 1
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }
  changeConcern(concern) {
    this.setState({
      concern
    });
  }
  componentDidMount() {
    const props = this.props;

    if (props.userPosts.length < 20) {
      this.setState({
        loaded: true
      });
    }
    getConcern(props.match.params.id).then(data => {
      this.setState({
        concern: data.data.status
      });
    });
  }
  render() {
    const state = this.state;
    const { id, lang } = this.props.match.params;
    let helmetData = getHelmet({
      title: this.props.userInfo.name + ' home page',
      keywords: `${this.props.userInfo.name},${locales(
        lang,
        'helmetKeywords'
      )}`,
      twitterTitle: this.props.userInfo.name + ' home page',
      ogTitle: this.props.userInfo.name + ' home page'
    });

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles['posts-container']}>
              {this.renderPosts()}
              <LoadMore
                loading={state.loading}
                loaded={state.loaded}
                onClick={this.loadMore.bind(this)}
              />
            </div>
            <div className={styles['widgets-container']}>
              <Author
                {...this.props.userInfo}
                concern={state.concern}
                changeconcern={this.changeConcern}
                _this={this}
                id={id}
                user={'user'}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.user.info,
    userPosts: state.user.posts
  };
};

export default withRouter(connect(mapStateToProps)(User));
