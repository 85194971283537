import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Helmet from 'react-helmet';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import { PostItem } from '../../components/columnsPost';
import LoadMore from '../../components/common/homeloadmore';
import { getColumnList, newsItem } from '../../common/api/columns';
import { RePost } from '../../components/recommendPost';
import { HotPostItem } from '../../components/recommendPost/item';
import { getTopPost } from '../../common/api/found';
import wechatShare from '../../common/scripts/wechat';

import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

const DefaultImg = require('../../assets/image/column_bg.png');

export class Column extends React.Component {
  static propTypes = {
    columnPosts: PropTypes.array.isRequired,
    columnInfo: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    columnsPages: PropTypes.object.isRequired
  };
  static fetchData(store, match, res) {
    const columnId = match.params.id;
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      getColumnList(columnId, lang)
        .then(data => {
          const columnData = data.data;
          store.dispatch({
            type: 'SET_COLUMN_INFO',
            list: columnData
          });
          return newsItem(columnId, lang);
        })
        .then(data => {
          const number = Math.ceil(
            data.data.total_count / Number(data.data.page_size)
          );
          const pages = {
            total_count: data.data.total_count,
            page: data.data.page,
            page_size: data.data.page_size,
            number
          };
          store.dispatch({
            type: 'SET_COLUMN_PAGE',
            list: pages
          });
          store.dispatch({
            type: 'SET_COLUMN_POSTS',
            list: data.data.items || []
          });
          resolve();
        })
        .catch(() => {
          res.redirect(postHref);
        });
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      topPostList: []
    };
  }
  renderPosts() {
    const { lang } = this.props.match;
    if (!this.props.columnPosts) {
      return null;
    }
    return this.props.columnPosts.map((post, index) => {
      const extractionTagsStr = post.secondary_tag;
      const props = {
        ...post,
        lang,
        secondary_tag: extractionTagsStr ? JSON.parse(extractionTagsStr) : []
      };
      return (
        <React.Fragment key={index}>
          <PostItem key={post.id} {...props} />
        </React.Fragment>
      );
    });
  }
  loadMore() {
    const { dispatch, match, columnPosts, columnsPages } = this.props;
    const total = columnsPages.number;
    let page = 1;
    page = columnsPages.page;
    const columnsId = match.params.id;
    this.setState({
      loading: true
    });
    if (page < total) {
      page++;
      newsItem(columnsId, page)
        .then(data => {
          if (!data.data.items) {
            this.setState({
              loaded: true
            });
            return false;
          }
          if (data.data.items.length < 10) {
            this.setState({
              loaded: true,
              loading: false
            });
          }
          const pages = Object.assign(columnsPages, { page });
          const list = _.concat(columnPosts, data.data.items);
          dispatch({
            type: 'SET_COLUMN_POSTS',
            list
          });
          dispatch({
            type: 'SET_COLUMN_PAGE',
            list: pages
          });
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loaded: true,
        loading: false
      });
    }
  }
  getTopPost() {
    const { postType } = this.state;
    getTopPost(postType)
      .then(data => {
        this.setState({
          topPostList: data.data.items
        });
      })
      .catch(e => {
        console.log(e);
      });
  }
  renderPostHot() {
    const postList = this.state.topPostList;
    if (postList.length > 0) {
      return postList.map((item, index) => {
        const props = Object.assign(item, {
          type: 'small',
          sort: index + 1
        });
        return <HotPostItem {...props} key={index} />;
      });
    }
  }
  componentDidMount() {
    this.getTopPost();

    const props = this.props;
    if (props.columnPosts.length < 10) {
      this.setState({
        loaded: true
      });
    }
    wechatShare({});
  }
  getHotPosts(value) {
    this.setState(
      {
        postType: value.value
      },
      () => {
        this.getTopPost();
      }
    );
  }

  render() {
    const { lang } = this.props.match.params;
    let helmetData = getHelmet({}, lang);
    const state = this.state;
    const { cover, name } = this.props.columnInfo;
    const coverImg = cover ? cover : DefaultImg;
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles['posts-container']}>
            <div
              className={styles.header}
              style={{
                backgroundImage: `url(${coverImg})`,
                backgroundPosition: '50%,50%',
                backgroundSize: 'cover'
              }}
            >
              <p>{name}</p>
            </div>
            {this.renderPosts()}
            <LoadMore
              loading={state.loading}
              loaded={state.loaded}
              onClick={this.loadMore.bind(this)}
            />
          </div>
          <div className={styles['widgets-container']}>
            <RePost
              getHotPosts={this.getHotPosts.bind(this)}
              title={locales(lang, 'pArticles')}
            >
              {this.renderPostHot()}{' '}
            </RePost>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    columnInfo: state.secondTag.columnInfo,
    columnPosts: state.secondTag.posts,
    columnsPages: state.secondTag.pages
  };
};

export default withRouter(connect(mapStateToProps)(Column));
