import { combineReducers } from 'redux';

const loginFlag = (state = false, action) => {
  switch (action.type) {
    case 'SET_LOGIN_FLAG':
      return action.flag;
    default:
      return state;
  }
};

const loginType = (state = 'PasswordLogin', action) => {
  switch (action.type) {
    case 'SET_LOGIN_TYPE':
      return action.flag;
    default:
      return state;
  }
};

const areaData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LOGIN_AREA':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  loginType,
  loginFlag,
  areaData
});
