import React, { Component } from 'react';
import styles from '../../index.scss';

export class ApplicationVi extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>Cấp phép ứng dụng Ứng dụng và hướng dẫn sử dụng</h5>
        <p>
          1. Để đảm bảo thực hiện chức năng và hoạt động an toàn, ổn định của
          các sản phẩm ODAILYApp, chúng tôi có thể xin hoặc sử dụng các quyền
          liên quan của hệ điều hành;
        </p>
        <p>
          2. Để bảo vệ quyền được biết của bạn, chúng tôi sẽ hiển thị các quyền
          liên quan đến hệ điều hành mà sản phẩm có thể đăng ký và sử dụng thông
          qua danh sách sau và bạn có thể quản lý các quyền liên quan theo nhu
          cầu thực tế của mình;
        </p>
        <p>
          3. Theo các bản nâng cấp sản phẩm, các loại và mục đích của quyền ứng
          dụng và sử dụng có thể thay đổi và chúng tôi sẽ nhanh chóng điều chỉnh
          danh sách theo những thay đổi này để đảm bảo rằng bạn được thông báo
          kịp thời về ứng dụng và quyền sử dụng;
        </p>
        <p>
          4. Xin lưu ý rằng chúng tôi cũng có thể sử dụng SDK của bên thứ ba cho
          các nhu cầu về chức năng và bảo mật của doanh nghiệp và sản phẩm của
          chúng tôi, đồng thời các bên thứ ba này cũng có thể đăng ký hoặc sử
          dụng các quyền của hệ điều hành có liên quan. Để biết chi tiết, vui
          lòng tham khảo phần thứ năm của &quot;ODAILY Chính sách quyền riêng
          tư&quot; &quot;Danh sách chia sẻ thông tin&quot; được cung cấp bởi bài
          viết;
        </p>
        <p>
          5. Trong quá trình sử dụng sản phẩm, bạn có thể sử dụng các trang H5
          hoặc applet do bên thứ ba phát triển và các plug-in hoặc applet do bên
          thứ ba phát triển này cũng có thể xin hoặc sử dụng các quyền của hệ
          điều hành có liên quan khi cần thiết cho các chức năng kinh doanh;
        </p>
        <p>
          6. Những điều sau áp dụng cho Ứng dụng ODAILY và nếu có một kịch bản
          hoặc mục đích áp dụng riêng, nó sẽ được mô tả trong danh sách sau.
        </p>

        <h6>Danh sách ứng dụng cho phép ứng dụng Android</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>tên quyền</h6>
              </td>
              <td>
                <h6>Mô tả quyền</h6>
              </td>
              <td>
                <h6>Sử dụng kịch bản hoặc mục đích</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>đọc bộ nhớ ngoài</p>
              </td>
              <td>
                <p>Được sử dụng để người dùng tải lên hình ảnh, tệp, v.v.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>ghi vào bộ nhớ ngoài</p>
              </td>
              <td>
                <p>
                  Được sử dụng để người dùng lưu ảnh và ghi lại thông tin nhật
                  ký sự cố cục bộ (nếu có)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MOUNT_UNMOUNT_FILESYSTEMS</p>
              </td>
              <td>
                <p>Gắn kết/ngắt kết nối hệ thống tập tin bên ngoài</p>
              </td>
              <td>
                <p>Được sử dụng để người dùng tải lên hình ảnh, tệp, v.v.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_PHONE_STATE</p>
              </td>
              <td>
                <p>đọc trạng thái điện thoại</p>
              </td>
              <td>
                <p>
                  Nó được sử dụng để truy cập nhận dạng thiết bị điện thoại di
                  động và các thông tin khác. Quyền này không thể theo dõi hoặc
                  lấy bất kỳ nội dung và thông tin cuộc gọi nào của bạn
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAMERA</p>
              </td>
              <td>
                <p>sử dụng máy ảnh</p>
              </td>
              <td>
                <p>
                  Nó được sử dụng để người dùng chụp ảnh khi thay đổi ảnh đại
                  diện
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_ALERT_WINDOW</p>
              </td>
              <td>
                <p>Cửa sổ nổi hệ thống</p>
              </td>
              <td>
                <p>
                  Nó được sử dụng để hiển thị cho người dùng xem cửa sổ nổi sau
                  khi người dùng thoát khỏi trình duyệt bài viết
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_OVERLAY_WINDOW</p>
              </td>
              <td>
                <p>cửa sổ cấp cao nhất của hệ thống</p>
              </td>
              <td>
                <p>
                  Dùng để người dùng nhận thông báo đẩy kịp thời khi sử dụng ứng
                  dụng
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>INTERNET</p>
              </td>
              <td>
                <p>Truy cập mạng</p>
              </td>
              <td>
                <p>Đối với mạng thiết bị</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_NETWORK_STATE</p>
              </td>
              <td>
                <p>Nhận thông tin trạng thái mạng</p>
              </td>
              <td>
                <p>
                  Nó được sử dụng để phát hoặc tạm dừng video theo trạng thái
                  mạng (di động, wifi) để tiết kiệm mức tiêu thụ lưu lượng của
                  người dùng
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_WIFI_STATE</p>
              </td>
              <td>
                <p>Nhận thông tin trạng thái Wifi hiện tại</p>
              </td>
              <td>
                <p>
                  Nó được sử dụng để phát hoặc tạm dừng video theo trạng thái
                  mạng (di động, wifi) để tiết kiệm mức tiêu thụ lưu lượng của
                  người dùng
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_WIFI_STATE</p>
              </td>
              <td>
                <p>Thay đổi trạng thái WiFi</p>
              </td>
              <td>
                <p>
                  Dùng để hỗ trợ GPS xác định vị trí, quyền này không lấy được
                  tài khoản và mật khẩu wifi của bạn
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_NETWORK_STATE</p>
              </td>
              <td>
                <p>Cho phép một chương trình thay đổi trạng thái của mạng</p>
              </td>
              <td>
                <p>
                  Được sử dụng để cung cấp cho người dùng dịch vụ tốt hơn khi
                  mạng không ổn định
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_LOGS</p>
              </td>
              <td>
                <p>đọc nhật ký hệ thống</p>
              </td>
              <td>
                <p>Được sử dụng để thu thập thông tin nhật ký lỗi ứng dụng</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_SETTINGS</p>
              </td>
              <td>
                <p>ghi cài đặt hệ thống</p>
              </td>
              <td>
                <p>
                  Được sử dụng để đồng bộ hóa thông báo hệ thống, độ sáng và các
                  cài đặt khác
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_SETTINGS</p>
              </td>
              <td>
                <p>đọc cài đặt hệ thống</p>
              </td>
              <td>
                <p>
                  Được sử dụng để người dùng thay đổi thông báo hệ thống, độ
                  sáng và các cài đặt khác
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MODIFY_AUDIO_SETTINGS</p>
              </td>
              <td>
                <p>sửa đổi cài đặt âm thanh</p>
              </td>
              <td>
                <p>Dùng để điều chỉnh âm lượng khi xem video</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WAKE_LOCK</p>
              </td>
              <td>
                <p>Cho phép tiếp tục chạy trên màn hình khóa</p>
              </td>
              <td>
                <p>Khi dùng để xem video, màn hình không tự khóa</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECEIVE_BOOT_COMPLETED</p>
              </td>
              <td>
                <p>Cho phép tự khởi động</p>
              </td>
              <td>
                <p>
                  Được sử dụng để cải thiện tỷ lệ đến của tin nhắn đẩy, quyền
                  này không thể được kích hoạt tự động trong bất kỳ điều kiện
                  nào
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VIBRATE</p>
              </td>
              <td>
                <p>Cho phép chương trình sử dụng rung</p>
              </td>
              <td>
                <p>
                  Sử dụng tính năng rung để nhắc nhở người dùng khi nhận được
                  các lần đẩy quan trọng hoặc tương tác một phần
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FOREGROUND_SERVICE</p>
              </td>
              <td>
                <p>Cho phép sử dụng các dịch vụ tiền cảnh</p>
              </td>
              <td>
                <p>
                  Khi được sử dụng để phát nội dung âm thanh, ngăn gián đoạn
                  dịch vụ trong khi người dùng đang nghe âm thanh
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECORD_AUDIO</p>
              </td>
              <td>
                <p>sử dụng micrô</p>
              </td>
              <td>
                <p>
                  Được sử dụng để truy cập và mở doanh nghiệp ODAILY và sử dụng
                  âm thanh để ghi lại ghi chú trong một số tình huống
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MANAGE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>Cho phép truy cập vào tất cả các tệp</p>
              </td>
              <td>
                <p>
                  Được sử dụng để truy cập và mở nghiệp vụ ODAILY, người dùng
                  lưu ảnh và ghi lại thông tin nhật ký sự cố cục bộ (nếu có)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FLASHLIGHT</p>
              </td>
              <td>
                <p>Cho phép truy cập flash</p>
              </td>
              <td>
                <p>
                  Được sử dụng để nhắc nhở người dùng bằng đèn nhấp nháy khi
                  nhận được một cú đẩy quan trọng
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_INSTALL_PACKAGES</p>
              </td>
              <td>
                <p>Cài đặt quyền từ các nguồn không xác định</p>
              </td>
              <td>
                <p>
                  Đối với cài đặt cập nhật của ODAILYApp, quyền này không thể
                  cài đặt các ứng dụng khác
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECIEVE_MCS_MESSAGE</p>
              </td>
              <td>
                <p>truy cập mạng đầy đủ</p>
              </td>
              <td>
                <p>để truy cập mạng</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CALL_PHONE</p>
              </td>
              <td>
                <p>
                  Bắt đầu giao diện người dùng cuộc gọi điện thoại mà không cần
                  thông qua trình quay số
                </p>
              </td>
              <td>
                <p>để quay số</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_FINE_LOCATION</p>
              </td>
              <td>
                <p>Nhận quyền thông tin vị trí</p>
              </td>
              <td>
                <p>để có được vị trí</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_IGNORE_BATTERY_OPTIMIZATIONS</p>
              </td>
              <td>
                <p>Nhận quyền tối ưu hóa pin</p>
              </td>
              <td>
                <p>Được sử dụng để tối ưu hóa việc sử dụng pin</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_SHORTCUT</p>
              </td>
              <td>
                <p>cập nhật quyền</p>
              </td>
              <td>
                <p>để cập nhật quyền</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_BADGE</p>
              </td>
              <td>
                <p>Quyền thông báo tin nhắn</p>
              </td>
              <td>
                <p>cho tin nhắn đẩy</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_COUNT</p>
              </td>
              <td>
                <p>Cập nhật số lượng cho phép</p>
              </td>
              <td>
                <p>Được sử dụng để lấy số lần cập nhật</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_BADGE</p>
              </td>
              <td>
                <p>Cập nhật thông báo SMS</p>
              </td>
              <td>
                <p>Được sử dụng để nhận nội dung SMS</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_BADGE</p>
              </td>
              <td>
                <p>Áp dụng quyền huy hiệu máy tính để bàn</p>
              </td>
              <td>
                <p>Được sử dụng để hiển thị thông tin đánh dấu góc</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>GET_TASKS</p>
              </td>
              <td>
                <p>Cho phép ứng dụng nhận quy trình hiện tại</p>
              </td>
              <td>
                <p>để có được quá trình hiện tại</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH</p>
              </td>
              <td>
                <p>nhận bluetooth</p>
              </td>
              <td>
                <p>để sử dụng bluetooth</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH_ADMIN</p>
              </td>
              <td>
                <p>Nhận quyền bluetooth</p>
              </td>
              <td>
                <p>Được sử dụng để xin phép bluetooth</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_COARSE_LOCATION</p>
              </td>
              <td>
                <p>Nhận thông tin vị trí gần đúng</p>
              </td>
              <td>
                <p>Được sử dụng để có được thông tin vị trí</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MIPUSH_RECEIVE</p>
              </td>
              <td>
                <p>Nhận Đẩy Xiaomi</p>
              </td>
              <td>
                <p>Dùng để nhận cục đẩy Xiaomi</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_ADDED</p>
              </td>
              <td>
                <p>chấp nhận phát sóng ngầm</p>
              </td>
              <td>
                <p>để nhận các chương trình phát sóng</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_CHANGED</p>
              </td>
              <td>
                <p>Lắng nghe những thay đổi của ứng dụng</p>
              </td>
              <td>
                <p>Được sử dụng để xem các thay đổi của ứng dụng</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_INSTALL</p>
              </td>
              <td>
                <p>cài đặt ứng dụng</p>
              </td>
              <td>
                <p>để cài đặt ứng dụng</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_REPLACED</p>
              </td>
              <td>
                <p>ứng dụng thay thế</p>
              </td>
              <td>
                <p>Dùng để thay thế các ứng dụng phiên bản cũ</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RESTART_PACKAGES</p>
              </td>
              <td>
                <p>khởi động lại ứng dụng</p>
              </td>
              <td>
                <p>để khởi động lại ứng dụng</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MESSAGE</p>
              </td>
              <td>
                <p>tiếp cận thông tin</p>
              </td>
              <td>
                <p>để lấy thông tin</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>C2D_MESSAGE</p>
              </td>
              <td>
                <p>Nhận tin nhắn C2D</p>
              </td>
              <td>
                <p>Được sử dụng để lấy thông tin C2D</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>Danh sách ứng dụng cấp phép ứng dụng iOS</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>tên quyền</h6>
              </td>
              <td>
                <h6>Mô tả quyền</h6>
              </td>
              <td>
                <h6>Sử dụng kịch bản hoặc mục đích</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-CameraUsageDescription</p>
              </td>
              <td>
                <p>sử dụng máy ảnh</p>
              </td>
              <td>
                <p>
                  Nó được sử dụng để người dùng chụp ảnh khi thay đổi ảnh đại
                  diện
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-MicrophoneUsageDescription</p>
              </td>
              <td>
                <p>sử dụng micrô</p>
              </td>
              <td>
                <p>
                  Được sử dụng để truy cập và mở doanh nghiệp ODAILY và sử dụng
                  âm thanh để ghi lại ghi chú trong một số tình huống
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryAdditionsUsageDescription</p>
              </td>
              <td>
                <p>Thêm nội dung vào album</p>
              </td>
              <td>
                <p>Để người dùng lưu ảnh</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryUsageDescription</p>
              </td>
              <td>
                <p>Đọc nội dung của album</p>
              </td>
              <td>
                <p>Để người dùng tải lên hình đại diện</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ApplicationVi;
