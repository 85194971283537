import { service } from '../scripts/http';
// import axios from 'axios';

export function getVote() {
  return service.get(`vote_rank/index`);
}

export function getVoteRank(vote_id, vote_item_id) {
  return service.post(`vote_rank/vote`, {
    vote_id: vote_id,
    vote_item_id: vote_item_id
  });
}

// export function getVoteRank(vote_id, vote_item_id) {
//   return axios.post(`https://service.odaily.news/api/vote_rank/vote`, {
//     vote_id: vote_id,
//     vote_item_id: vote_item_id
//   });
// }

// export function getVoteRank(vote_id, vote_item_id) {
//   return axios.post(`http://test01service.odaily.site/api/vote_rank/vote`, {
//     vote_id: vote_id,
//     vote_item_id: vote_item_id
//   });
// }

// export function getVote() {
//   return axios.get('https://service.odaily.news/api/vote_rank/index');
// }

// export function getVote() {
//   return axios.get('http://test01service.odaily.site/api/vote_rank/index');
// }
