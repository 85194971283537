import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import styles from './detail.scss';

import Layout from '../../components/common/layout';
import ImageView from '../../components/common/imageView';
import Share from '../../components/newsflash/share';
import PostRecommend from '../../components/post/recommend';
import PostHot from '../../components/post/postHot';
import AdPicture from '../../components/adPicture';
import { SideList } from '../../components/homeSide';
import Sticky from '../../components/common/sticky';
import DownLoad from '../../components/download';

import { timesAgo } from '../../common/scripts/time';
import {
  getNewsflash,
  getNewsflashDetail,
  getHotNewsflash,
  getTagNewsflash
} from '../../common/api/newsflash';
import { getNewPost, addShare } from '../../common/api/post';
import { getAdvert } from '../../common/api/slide';
import { getTopPost } from '../../common/api/found';
import { getHelmet } from '../../common/scripts/helmet';
import wechatShare from '../../common/scripts/wechat';
import locales from '../../utils/locales';

export class NewsflashDetail extends React.Component {
  static propTypes = {
    advertBottom: PropTypes.array,
    detail: PropTypes.object.isRequired,
    feedList: PropTypes.array.isRequired,
    tagList: PropTypes.array.isRequired,
    postList: PropTypes.array.isRequired,
    articleList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    hotNewsflashList: PropTypes.array
  };
  static fetchData(store, match, res) {
    const { id, lang } = match.params;
    const postHref = lang ? `/${lang}/404` : `/404`;
    const source = match.query.source;
    let secondary_tag = [];
    return new Promise(resolve => {
      getNewsflashDetail(id, lang)
        .then(data => {
          const detail = data.data;
          store.dispatch({
            type: 'SET_NEWSFLASH_DETAIL',
            detail
          });
          secondary_tag = detail.secondary_tag
            ? JSON.parse(detail.secondary_tag)
            : [];
          return getAdvert('home_advertisement_bottom', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_BOTTOM',
            list: list
          });
          return getNewPost(6, lang);
        })
        .then(data => {
          let list = data.data.data;
          store.dispatch({
            type: 'SET_POST_FEEDLIST',
            list
          });
          return getTopPost('week', lang);
        })
        .then(data => {
          const postList = data.data.items;
          store.dispatch({
            type: 'SET_TOPPOST',
            list: postList
          });
          return getNewsflash({ bId: '', perPage: 10 }, lang);
        })
        .then(data => {
          if (data) {
            const list = data.data.items;
            store.dispatch({
              type: 'SET_POST_ARTICLELIST',
              list: list
            });
          }
          return getHotNewsflash(lang);
        })
        .then(data => {
          const list = data.data;
          store.dispatch({
            type: 'SET_HOT_NEWSFLASH',
            list: list
          });

          if (secondary_tag && secondary_tag[0] && secondary_tag[0].slug) {
            return getTagNewsflash(secondary_tag[0].slug, lang);
          }
          resolve();
        })
        .then(data => {
          if (data && data.data && data.data.items) {
            let list = data.data.items.filter(item => {
              return id != item.id;
            });
            store.dispatch({
              type: 'SET_TAG_NEWSFLASH',
              list: list
            });
          }

          resolve();
        })
        .catch(() => {
          res.redirect(postHref);
        });

      if (source === 'share') {
        addShare(id, 'newsflash');
      }
    });
  }
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { detail } = this.props;
    wechatShare({ title: detail.title, desc: detail.description });
  }

  markup() {
    const { detail } = this.props;
    let content2 = detail.description.replace(/(\r\n|\n|\r|↵)/gm, '<br />');

    return { __html: content2 };
  }

  renderNewsUrl() {
    let linkText;
    const { detail, match } = this.props;
    const { lang } = match.params;
    switch (detail.news_url_type) {
      case 'news_url':
        linkText = locales(lang, 'orig_Link');
        break;
      case 'product_url':
        linkText = locales(lang, 'related_articles');
        break;
      default:
        linkText = locales(lang, 'related_links');
        break;
    }
    return (
      <a
        href={detail.news_url}
        rel="noopener noreferrer nofollow"
        target={'_blank'}
        className={styles.news_url}
      >
        {linkText}
      </a>
    );
  }

  renderCover() {
    const { cover, title } = this.props.detail;
    return (
      <div className={styles['cover']}>
        <img
          onClick={() => ImageView.show({ src: cover, alt: title })}
          src={cover}
          alt={title}
          loading="lazy"
        />
      </div>
    );
  }

  renderSecondary() {
    const { secondary_tag } = this.props.detail;

    let secondary = secondary_tag ? JSON.parse(secondary_tag) : [];

    return (
      <div className={styles.secondaryList}>
        {secondary.map(item => {
          return (
            <a
              className={styles.secondaryItem}
              key={`secondaryItem${item.name}`}
              href={`/search/${item.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      detail,
      feedList,
      advertBottom,
      postList,
      hotNewsflashList,
      tagList,
      articleList,
      match
    } = this.props;

    let desc = '';
    let { lang } = match.params;
    const b = detail.description.replace(/(\r\n|\n|\r|↵)/gm, '<br />');
    desc =
      b.split('<br />')[0] === '' ? b.split('<br />')[1] : b.split('<br />')[0];

    let helmetData = getHelmet({
      title: `${detail.title}_${locales(lang, 'flash')}`,
      description: `${detail.title}_${desc}`,
      keywords: `${detail.title},${locales(lang, 'helmetKeywords')}`,
      twitterTitle: `${detail.share_data.default.title}`,
      twitterDescription: `${detail.share_data.default.description}`,
      ogTitle: `${detail.share_data.default.title}`,
      ogDescription: `${detail.share_data.default.description}`,
      ogUrl: `${process.env.ODAILY}${match.url}`
    });

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.container_wrapper}>
            <div className={styles.container_left}>
              {/* 内容 */}
              <div className={styles.container_main}>
                <div className={styles.newsflash_title}>{detail.title}</div>

                <div className={styles.newsflash_time}>
                  {timesAgo(detail.published_at, undefined, lang)}
                </div>

                <div
                  className={styles.newsflash_description}
                  dangerouslySetInnerHTML={this.markup()}
                />

                {detail.news_url ? this.renderNewsUrl() : null}

                {detail.cover ? this.renderCover() : null}

                {detail.secondary_tag ? this.renderSecondary() : null}
              </div>

              {/* 分享 */}
              <div className={styles['share-bar']}>
                <Share title={detail.title} />
              </div>

              <div className={styles.newsflash_border} />

              {/* 推荐阅读 */}
              {(feedList && feedList.length) || (tagList && tagList.length) ? (
                <div className={styles.postRecommend}>
                  <PostRecommend
                    id={detail.id + ''}
                    articlelist={tagList}
                    feedList={feedList}
                    tags={
                      detail.secondary_tag
                        ? JSON.parse(detail.secondary_tag)
                        : null
                    }
                  />
                </div>
              ) : (
                ''
              )}

              {postList && postList.length ? (
                <div className={styles.postRecommend}>
                  <PostHot id={detail.id + ''} articlelist={postList} />
                </div>
              ) : (
                ''
              )}
            </div>

            <div className={styles.container_right}>
              <Sticky>
                <div className={styles.RePost}>
                  <SideList
                    title="7X24h快讯"
                    newsList={articleList}
                    // currentUser={currentUser}
                    iconFlag={true}
                  />

                  {hotNewsflashList && hotNewsflashList.length ? (
                    <div className={styles.hotNewsflash}>
                      <div className={styles.hotNewsflash_header}>
                        <div className={styles.hotNewsflash_title}>
                          {locales(lang, 'kx_hot', { isTitle: true })}
                        </div>
                      </div>
                      {hotNewsflashList.map((item, index) => {
                        return (
                          <a
                            className={styles.hotNewsflash_item}
                            key={`hotNewsflash_item${index}`}
                            href={`/newsflash/${item.id}`}
                            target={'_blank'}
                            rel="noreferrer"
                          >
                            <div
                              className={
                                styles[`hotNewsflash_item_icon${index}`]
                              }
                            />
                            <div className={styles[`hotNewsflash_item_title`]}>
                              {item.title}
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}

                  {/* 首页边栏-底部 */}
                  {advertBottom &&
                  advertBottom.length > 0 &&
                  advertBottom[0].url &&
                  advertBottom[0].cover ? (
                    <div className={styles.advertMiddle}>
                      <AdPicture
                        width="340px"
                        height="226px"
                        url={advertBottom[0].url}
                        img={advertBottom[0].cover}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  <DownLoad />
                </div>
              </Sticky>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    advertBottom: state.home.advertBottom,
    detail: state.newsflash.detail,
    feedList: state.post.feedList,
    postList: state.home.topPost,
    hotNewsflashList: state.home.hotNewsflashList,
    tagList: state.home.tagList,
    articleList: state.post.articleList
  };
};

export default withRouter(connect(mapStateToProps)(NewsflashDetail));
