import React from 'react';
import styles from './index.scss';
import locales from '../../../utils/locales';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const ImgAppQrcode =
  'https://piccdn.0daily.com/202212/02071729/hq1uctzpwdyas16v.png';
const wecode2 =
  'https://piccdn.0daily.com/202212/02073313/n38zo2eckiajkarj.png';
const wecode1 =
  'https://piccdn.0daily.com/202212/02073318/whh64xdbamdlspu2.png';

// import $ from 'jquery';

export class ShareApp extends React.Component {
  state = {
    show: false
  };
  static propTypes = {
    match: PropTypes.object
  };
  handleScrollTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  }
  componentDidMount() {
    let timer = setInterval(() => {
      clearInterval(timer);
      window.addEventListener('scroll', () => {
        let scrollTop = window.pageYOffset;
        if (scrollTop > 100) {
          this.setState({
            show: true
          });
        } else {
          this.setState({
            show: false
          });
        }
      });
    }, 300);
  }
  render() {
    const { show } = this.state;
    const { lang } = this.props.match.params;
    return (
      <React.Fragment>
        <div style={{ bottom: '38px' }} className={styles.container}>
          <div className={styles.app_wrapper}>
            <div className={styles.app_icon} />
            <div className={styles['app-share-wrapper']}>
              <div className={styles['app-share-content']}>
                <img src={ImgAppQrcode} loading="lazy" />
                <p className={styles.text}>{locales(lang, 'appDownload')}</p>
              </div>
            </div>
          </div>
          <div className={styles.wechat_wrapper}>
            <div className={styles.wechat_icon} />
            <div className={styles['wechat-share-wrapper']}>
              <div className={styles['wechat-share-content']}>
                <a
                  className={styles.twitter_box}
                  href="https://twitter.com/OdailyChina"
                  target={'_blank'}
                  rel="nofollow noopener noreferrer"
                >
                  <div className={styles.twitter_box_img} />
                  <div className={styles.twitter_text}>Twitter Official</div>
                </a>
                <a
                  className={styles.discord_box}
                  href={process.env.DISCORD}
                  target={'_blank'}
                  rel="nofollow noopener noreferrer"
                >
                  <div className={styles.discord_box_img} />
                  <div className={styles.discord_text}>Discord Official</div>
                </a>
                <a
                  className={styles.telegram_box}
                  href="https://t.me/Odaily_CryptoPunk"
                  target={'_blank'}
                  rel="nofollow noopener noreferrer"
                >
                  <div className={styles.telegram_box_img} />
                  <div className={styles.telegram_text}>Telegram Chat</div>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.merchant_wrapper}>
            <div className={styles.merchant_icon} />
            <div className={styles['merchant-share-wrapper']}>
              <div className={styles['merchant-share']}>
                <div className={styles['merchant-share-content']}>
                  <img src={wecode1} loading="lazy" />
                  <p className={styles.text}>{locales(lang, 'bus_Coop')}</p>
                </div>
                <div className={styles['merchant-share-content']}>
                  <img src={wecode2} loading="lazy" />
                  <p className={styles.text}>{locales(lang, 'bus_Coop')}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.gotop_wrapper}
            style={{ display: show ? 'block' : 'none' }}
            onClick={this.handleScrollTop.bind(this)}
          >
            <div className={styles.gotop_icon} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ShareApp);
