exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-common-layout--tip2-index {\n  width: 100%;\n  height: 32px;\n  background: #DFE6EE; }\n  .src-components-common-layout--tip2-index div {\n    width: 1200px;\n    margin: 0 auto;\n    font-size: 12px;\n    font-weight: 400;\n    color: #333333;\n    line-height: 32px; }\n\n.src-components-common-layout--search-index {\n  width: 100vw;\n  min-height: 100vh;\n  background: #fff;\n  z-index: 10; }\n\n.src-components-common-layout--search_hidden-index {\n  visibility: hidden;\n  opacity: 0;\n  z-index: -1;\n  -webkit-transition: opacity 0.7s,visibility 0.7s;\n  transition: opacity 0.7s,visibility 0.7s;\n  min-height: 0;\n  height: 0;\n  overflow: hidden; }\n\n.src-components-common-layout--search_active-index {\n  visibility: visible;\n  opacity: 1;\n  -webkit-transition: opacity 0.7s, visibility 0.7s;\n  transition: opacity 0.7s, visibility 0.7s; }\n", ""]);

// exports
exports.locals = {
	"tip2": "src-components-common-layout--tip2-index",
	"search": "src-components-common-layout--search-index",
	"search_hidden": "src-components-common-layout--search_hidden-index",
	"search_active": "src-components-common-layout--search_active-index"
};