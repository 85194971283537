import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import locales from '../../utils/locales';

export default class TextOverflow extends React.Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
    height: PropTypes.number.isRequired,
    line: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowMore: false,
      scrollHeight: 0
    };
  }

  componentDidMount() {
    this.changeTextOverflowFlag();
  }

  UNSAFE_componentWillReceiveProps() {
    this.changeTextOverflowFlag();
  }

  changeIsShowMore(bool) {
    const { textOverflowFlag } = this.state;
    this.setState({ isShowMore: bool });
    if (textOverflowFlag && !bool) {
      this.setState({ textOverflowFlag: false });

      setTimeout(() => {
        this.setState({ textOverflowFlag: true });
      }, 400);
    }
  }

  changeTextOverflowFlag() {
    const { height } = this.props;
    const { offsetHeight, scrollHeight } = this.textOverflowDom;
    this.setState({ scrollHeight: scrollHeight + height });
    if (offsetHeight < scrollHeight) {
      this.setState({ textOverflowFlag: true });
    } else {
      this.setState({ textOverflowFlag: false });
    }
  }

  render() {
    const { children, backgroundColor, line, height, lang } = this.props;
    const { isShowMore, textOverflowFlag, scrollHeight } = this.state;
    return (
      <div
        className={styles.textOverflow}
        style={{
          maxHeight: isShowMore ? `${scrollHeight}px` : `${line * height}px`
        }}
        ref={e => {
          this.textOverflowDom = e;
        }}
        onClick={() => {
          this.changeIsShowMore(!isShowMore);
        }}
      >
        {children}

        <span
          className={styles.textOverflow_button}
          style={{ display: isShowMore && textOverflowFlag ? null : 'none' }}
          // onClick={() => {
          //   this.changeIsShowMore(false);
          // }}
        >
          {locales(lang, 'retracta')}
        </span>

        {!isShowMore && textOverflowFlag ? (
          <span
            className={styles.textOverflow_button_bg}
            style={{ backgroundColor }}
          >
            ...
            <span
              className={styles.textOverflow_button}
              // onClick={() => {
              //   this.changeIsShowMore(true);
              // }}
            >
              {locales(lang, 'expand')}
            </span>
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }
}
