import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { followAuthor, NotFollowAuthor } from '../../common/api/found';
import { getUserInfo, getUserPosts } from '../../common/api/user';
import cookies from 'js-cookie';

import { loginFlag } from '../../common/scripts/login';

import ImgDefaultAvatar from '../../assets/image/default-author-avatar.png';
import locales from '../../utils/locales';

export class Author extends React.PureComponent {
  static propTypes = {
    avatar: PropTypes.string,
    introduction: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    id: PropTypes.number,
    postid: PropTypes.string,
    concern: PropTypes.boolean,
    changeconcern: PropTypes.func,
    _this: PropTypes.object,
    user: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      previous: 0,
      fans: 0,
      post_create: 0,
      posts: []
    };
  }

  isLogin() {
    return cookies.get('kr_plus_id');
  }

  concernAuthor() {
    let { concern, changeconcern, _this, id, dispatch } = this.props;
    if (this.isLogin()) {
      if (concern) {
        NotFollowAuthor(id).then(data => {
          changeconcern.bind(_this)(data.data.status);
        });
      } else {
        followAuthor({ fid: id, ftype: 'user' }).then(data => {
          changeconcern.bind(_this)(data.data.status);
        });
      }
    } else {
      loginFlag(dispatch, true);
    }
  }

  renderPostList() {
    let { posts } = this.state;
    return posts.map((item, index) => {
      if (index < 3) {
        return (
          <div className={styles.post} key={item.entity_id}>
            <a
              href={
                item.entity_type === 'video'
                  ? `/video/${item.entity_id}`
                  : `/post/${item.entity_id}`
              }
            >
              {item.title}
            </a>
          </div>
        );
      }
    });
  }

  componentDidMount() {
    let { id, postid, match } = this.props;
    const lang = match.params.lang;
    getUserPosts(id, undefined, 10, undefined, lang).then(data => {
      this.setState({
        posts: data.data.items.data.filter(item => {
          return item.id != postid;
        })
      });
    });
    if (!id) {
      window.location.pathname.replace(/\/user\/(\S+)/, (url, userId) => {
        id = userId;
      });
    }
    getUserInfo(id, lang).then(data => {
      this.setState({
        fans: data.data.counters.fan,
        post_create: data.data.counters.post_create
      });
    });
  }

  render() {
    const { avatar, name, introduction, id, user, title, match } = this.props;
    let { post_create } = this.state;
    const { lang } = match.params;
    return (
      <div className={styles.container}>
        <div className={styles['user-info']}>
          <div className={styles['user-avatar']}>
            <a href={`/user/${id}`}>
              <img src={avatar || ImgDefaultAvatar} alt={name} loading="lazy" />
            </a>
          </div>

          <div className={styles['user-name']}>
            <a href={`/user/${id}`} className={styles['user_name_wrapper']}>
              <div className={styles['user_name_name']}>{name}</div>
              <div className={styles['user_name_border']} />
              <div className={styles['user_name_title']}>{title}</div>
            </a>
            <div className={styles.post_create}>{`${post_create}${locales(
              lang,
              'article'
            )}`}</div>
          </div>

          {/* <div
            className={concern ? styles.endConcern : styles.concern}
            onClick={() => {
              let now = new Date();
              if (now - previous >= 500) {
                this.concernAuthor();
                this.setState({
                  previous: now
                });
              }
            }}
          >
            {concern ? '已关注' : '+关注'}
          </div> */}
        </div>

        {introduction ? (
          <p className={styles['user-intro']}>{introduction}</p>
        ) : (
          ''
        )}
        <div style={{ display: user === 'user' ? 'none' : 'block' }}>
          {/* <div style={{ display: user === 'user' || posts === null ? 'none' : 'block' }}> */}
          <div className={styles.postList}>
            {this.renderPostList()}
            <a href={`/user/${id}`} className={styles.buttonPost}>
              更多文章
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(Author));
