import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
export class LocaleFont extends Component {
  static propTypes = {
    children: PropTypes.string,
    style: PropTypes.object
  };
  render() {
    const { children, style } = this.props;
    return (
      <span className={styles.localeFont} style={style}>
        {children}
      </span>
    );
  }
}

export default withRouter(LocaleFont);
