import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';
import styles from './index.scss';
import Toast from '../../../components/common/toast';

import {
  wechatLogin,
  weiboLogin,
  verificationPassword,
  verificationVerify,
  verificationPhone
} from '../../../common/scripts/login';
import {
  login,
  getAreaData,
  getCaptchaConfig,
  sendMsg,
  newPwd,
  checkCode,
  getCurrentUser,
  changePwd,
  bindPhone
} from '../../../common/api/user';

const XIANSHI =
  'https://piccdn.0daily.com/avatar/202204/14081703/836slyrlxcbmy0ho.png';
const BUXIANSHI =
  'https://piccdn.0daily.com/avatar/202204/14081703/1lxljp7bws10r6vr.png';
const info = {
  en: 'Login successful',
  zhtw: '登录成功',
  ja: 'ログインできました',
  ko: '로그인 성공',
  vi: 'Đăng nhập thành công',
  th: 'ล็อกอินเรียบร้อยแล้ว'
};

// import cookies from 'js-cookie';

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    loginType: PropTypes.string.isRequired,
    loginFlag: PropTypes.bool.isRequired,
    areaData: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    match: PropTypes.object
  };
  state = {
    phoneError: '',
    passwordFlag: false,
    passwordError: '',
    verifyError: '',
    areaFlag: false,
    areaValue: 'zh-CN',
    waitingSeconds: 60,
    waiting: false,
    phoneValue: '',
    verifyValue: '',
    passwordValue: '',
    areaRule: null,
    theRequest: {},
    areaCode: '86',
    checkbox1: false,
    checkbox2: false
  };

  getAreaData() {
    const { dispatch } = this.props;
    const { lang } = this.props.match.params;
    getAreaData(lang).then(data => {
      if (data.data) {
        dispatch({
          type: 'SET_LOGIN_AREA',
          list: data.data
        });
        this.setState({
          areaRule: data.data['zh-CN'].rule,
          areaCode: data.data['zh-CN'].code
        });
      }
    });
  }

  initLogin() {
    this.setState({
      phoneError: '',
      passwordFlag: false,
      passwordError: '',
      verifyError: '',
      areaFlag: false,
      waitingSeconds: 60,
      waiting: false,
      phoneValue: '',
      verifyValue: '',
      passwordValue: ''
    });
    if (this.phone) {
      this.phone.value = '';
    }

    if (this.password && this.password.value !== '') {
      this.password.value = '';
    }

    if (this.verify) {
      this.verify.value = '';
    }
  }

  countdown() {
    let { waitingSeconds } = this.state;

    if (waitingSeconds - 1 > 0) {
      this.setState({
        waitingSeconds: waitingSeconds - 1
      });
      setTimeout(() => {
        this.countdown();
      }, 1000);
    } else {
      this.setState({
        waiting: false,
        waitingSeconds: 60
      });
    }
  }

  getCaptchaConfig() {
    getCaptchaConfig().then(res => {
      if (res.code === 0) {
        const config = res.data;
        const isBrowser =
          typeof window !== 'undefined' &&
          typeof window.document !== 'undefined';
        if (isBrowser) {
          window.initGeetest(
            {
              gt: config.geetest_gt,
              challenge: config.geetest_challenge,
              product: 'bind'
            },
            this.captchaHandler.bind(this)
          );
        }
      }
    });
  }

  captchaHandler(captchaObj) {
    this.captchaObj = captchaObj;
    captchaObj.onSuccess(() => {
      const { phoneValue, areaCode } = this.state;
      this.setState({ waiting: true });
      this.countdown();
      sendMsg(
        Object.assign(
          {},
          {
            phone: phoneValue,
            area: areaCode
          },
          this.captchaObj.getValidate()
        )
      )
        .then(res => {
          if (res.code !== 0) {
            Toast.error({
              msg: res.msg
            });
            this.setState({
              waiting: false,
              waitingSeconds: 60
            });
          }
        })
        .catch(res => {
          Toast.error({
            msg: res.msg
          });
          this.setState({
            waiting: false,
            waitingSeconds: 60
          });
        });
    });
  }

  getSearch() {
    const { location } = this.props;
    var url = decodeURIComponent(location.search)
      .substr(1)
      .split('&');
    var theRequest = new Object();
    url.forEach(function(item) {
      theRequest[item.split('=')[0]] = item.split('=')[1];
    });

    if (theRequest && theRequest.type === '2') {
      this.setState({ theRequest });
      this.changeLoginFlag(true);
      this.changeLoginType('BindPhone');
    }
  }

  componentDidMount() {
    // 获取区号
    this.getAreaData();
    // 验证码
    this.getCaptchaConfig();
    // 是否绑定手机号
    this.getSearch();

    document.addEventListener('click', e => {
      if (
        this.input_box_wrapper &&
        !this.input_box_wrapper.contains(e.target)
      ) {
        this.changeAreaFlag(false);
      }
    });
  }

  changeLoginFlag(bool) {
    const { dispatch } = this.props;
    dispatch({
      type: 'SET_LOGIN_FLAG',
      flag: bool
    });
  }

  changeLoginType(str) {
    const { dispatch } = this.props;
    dispatch({
      type: 'SET_LOGIN_TYPE',
      flag: str
    });
  }

  changeError(data) {
    switch (data.code) {
      case 10010:
        this.setState({
          phoneError: data.msg || data.message
        });
        break;
      case 11000:
        this.setState({
          passwordError: data.msg || data.message
        });
        break;
      case 10001:
        this.setState({
          verifyError: data.msg || data.message
        });
        break;
      default:
        Toast.error({
          msg: data.msg || data.message
        });
        break;
    }
  }

  clickClose() {
    const { loginType } = this.props;
    if (loginType === 'SetPassword') {
      location.reload();
    }
    this.changeLoginFlag(false);
    this.changeLoginType('PasswordLogin');
    this.initLogin();
  }

  changePasswordFlag(bool) {
    this.setState({
      passwordFlag: bool
    });
  }

  clickChoice(flag) {
    this.changeLoginType(flag);
    this.initLogin();
  }

  clickLogin() {
    const { lang } = this.props.match.params;
    const { phoneValue, passwordValue, areaRule } = this.state;
    let passwordError = verificationPassword(passwordValue, lang);

    let phoneError = verificationPhone(phoneValue, areaRule, lang);
    this.setState({ phoneError, passwordError });
    if (phoneError || passwordError) {
      return;
    }

    let obj = { phone: phoneValue, password: passwordValue };
    login(obj)
      .then(data => {
        if (data.code === 0) {
          this.clickClose();
          Toast.done({
            msg: lang ? info[lang] : '登录成功'
          });
          location.reload();
        } else {
          this.changeError(data);
        }
      })
      .catch(data => {
        this.changeError(data);
      });
  }

  changeAreaFlag(bool) {
    this.setState({ areaFlag: bool });
  }

  changeAreaValue(val) {
    const { areaData } = this.props;
    this.setState({ areaValue: val });
    if (areaData && areaData[val]) {
      this.setState({
        areaRule: areaData[val].rule,
        areaCode: areaData[val].code
      });
    }
    this.changeAreaFlag(false);
  }

  sendCode() {
    let { waiting, phoneValue, areaRule } = this.state;
    const { lang } = this.props.match.params;
    let phoneError = verificationPhone(phoneValue, areaRule, lang);
    this.setState({ phoneError });
    if (phoneError) {
      return;
    }

    // 判断是不是点了checkbox
    if (lang == 'en') {
      if (!this.state.checkbox1) {
        Toast.error({
          msg: 'please agree to receive text messages'
        });
        return;
      }
      if (!this.state.checkbox2) {
        Toast.error({
          msg: 'please agree Service teams and Privacy policy'
        });
        return;
      }
    }

    if (!waiting) {
      this.captchaObj.verify();
    }
  }

  verifyLogin() {
    const { lang } = this.props.match.params;
    const { phoneValue, verifyValue, areaRule } = this.state;
    let verifyError = verificationVerify(verifyValue, lang);
    let phoneError = verificationPhone(phoneValue, areaRule, lang);
    this.setState({ phoneError, verifyError });
    if (phoneError || verifyError) {
      return;
    }

    let obj = { phone: phoneValue, valid_code: verifyValue };
    login(obj)
      .then(data => {
        if (data.code === 0) {
          if (data.data.password) {
            this.clickClose();
            Toast.done({
              msg: lang ? info[lang] : '登录成功'
            });
            location.reload();
          } else {
            Toast.done({
              msg: lang ? info[lang] : '登录成功'
            });
            this.changeLoginType('SetPassword');
            this.initLogin();
          }
        } else {
          this.changeError(data);
        }
      })
      .catch(data => {
        this.changeError(data);
      });
  }

  bindPhone() {
    const { lang } = this.props.match.params;
    const { phoneValue, verifyValue, areaRule, theRequest } = this.state;
    const { provider, third } = theRequest;
    let verifyError = verificationVerify(verifyValue, lang);
    let phoneError = verificationPhone(phoneValue, areaRule, lang);
    this.setState({ phoneError, verifyError });
    if (phoneError || verifyError) {
      return;
    }

    let obj = {
      phone: phoneValue,
      valid_code: verifyValue,
      type: provider,
      third
    };
    bindPhone(obj)
      .then(data => {
        if (data.code === 0) {
          if (data.data.password) {
            this.clickClose();
            Toast.done({
              msg: lang ? info[lang] : '登录成功'
            });
            location.reload();
          } else {
            Toast.done({
              msg: lang ? info[lang] : '登录成功'
            });
            this.changeLoginType('SetPassword');
            this.initLogin();
          }
        } else {
          this.changeError(data);
        }
      })
      .catch(data => {
        this.changeError(data);
      });
  }

  setNewPwd() {
    const { passwordValue } = this.state;
    const { dispatch } = this.props;
    const { lang } = this.props.match.params;
    let passwordError = verificationPassword(passwordValue, lang);
    this.setState({ passwordError });

    if (passwordError) {
      return;
    }
    getCurrentUser()
      .then(data => {
        if (data.code === 0) {
          const userData = data.data;
          dispatch({
            type: 'SET_CURRENT_USER',
            user: userData
          });
          return changePwd({ user_id: userData.id, password: passwordValue });
        } else {
          this.changeError(data);
          return;
        }
      })
      .then(data => {
        if (data.code === 0) {
          this.clickClose();
          Toast.done({
            msg: locales(lang, 'set_succ')
          });
        } else {
          this.changeError(data);
          return;
        }
      })
      .catch(data => {
        this.changeError(data);
      });
  }

  changePassword() {
    const { phoneValue, verifyValue, passwordValue, areaRule } = this.state;
    const lang = this.props.match.params;
    let passwordError = verificationPassword(passwordValue, lang);
    let verifyError = verificationVerify(verifyValue, lang);
    let phoneError = verificationPhone(phoneValue, areaRule, lang);

    this.setState({ phoneError, verifyError, passwordError });

    if (phoneError || verifyError || passwordError) {
      return;
    }
    let codeData = { account: phoneValue, verify_code: verifyValue };
    let newPwdData = { account: phoneValue, password: passwordValue };
    checkCode(codeData)
      .then(data => {
        if (data.code === 0) {
          return newPwd(newPwdData);
        } else {
          Toast.error({
            msg: data.message
          });
          return;
        }
      })
      .then(data => {
        if (data.code === 0) {
          Toast.done({
            msg: locales(lang, 'loginUpdateSuccess')
          });
          location.reload();
        } else {
          this.changeError(data);
          return;
        }
      })
      .catch(data => {
        this.changeError(data);
      });
  }

  changeValue(e, type) {
    switch (type) {
      case 'phone':
        this.setState({ phoneValue: e.target.value });
        break;
      case 'verify':
        this.setState({ verifyValue: e.target.value });
        break;
      case 'password':
        this.setState({ passwordValue: e.target.value });
        break;
    }
  }

  renderArea() {
    const { areaData } = this.props;
    const { areaFlag } = this.state;
    return (
      <div
        className={styles.area_list_box}
        style={{
          zIndex: areaFlag ? null : -1,
          transition: areaFlag
            ? 'z-index 0.1s linear'
            : 'z-index 0.1s linear 0.5s'
        }}
      >
        <div
          className={styles.area_list}
          style={{ transform: areaFlag ? null : `translate(0,-110%)` }}
        >
          <div className={styles.area_list_wrapper}>
            {Object.keys(areaData).map(item => {
              return (
                <div
                  className={styles.area_item}
                  key={`area_item${areaData[item].code}`}
                  onClick={() => {
                    this.changeAreaValue(item);
                  }}
                >
                  {areaData[item].name}(+{areaData[item].code})
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderPassword() {
    const { passwordFlag, passwordError } = this.state;
    const { lang } = this.props.match.params;
    return (
      <React.Fragment>
        <div
          className={`${styles.login_box_input_box_password} ${
            passwordError ? styles.login_box_input_box_border : ''
          }`}
        >
          <div className={styles.login_box_input_password}>
            <input
              type={passwordFlag ? 'text' : 'password'}
              placeholder={locales(lang, 'enterPwd')}
              ref={e => {
                this.password = e;
              }}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  return false;
                }
              }}
              onChange={e => {
                this.changeValue(e, 'password');
              }}
              autoComplete="off"
            />
          </div>
          <div
            className={styles.login_box_input_icon}
            style={{
              backgroundImage: `url(${passwordFlag ? BUXIANSHI : XIANSHI})`
            }}
            onClick={() => {
              this.changePasswordFlag(!passwordFlag);
            }}
          />
        </div>
        <div className={styles.login_box_input_box_error}>{passwordError}</div>
      </React.Fragment>
    );
  }

  renderVerification() {
    const { verifyError, waiting, waitingSeconds, phoneValue } = this.state;
    const { lang } = this.props.match.params;
    return (
      <React.Fragment>
        <div className={`${styles.login_box_input_box_verify}`}>
          <div
            className={`${styles.login_box_input_verify} ${
              verifyError ? styles.login_box_input_box_verify_border : ''
            }`}
          >
            <input
              type="text"
              placeholder={locales(lang, 'enterVerif')}
              ref={e => {
                this.verify = e;
              }}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  return false;
                }
              }}
              disabled={phoneValue ? false : true}
              onChange={e => {
                this.changeValue(e, 'verify');
              }}
            />
          </div>
          <div
            className={
              waiting
                ? styles.login_verify_button_waiting
                : styles.login_verify_button
            }
            onClick={() => {
              phoneValue ? this.sendCode() : '';
            }}
            style={{
              background: phoneValue && !waiting ? '#333' : null
            }}
          >
            {waiting ? `${waitingSeconds}S后重发` : locales(lang, 'sendVerif')}
          </div>
        </div>
        <div className={styles.login_box_input_box_error}>{verifyError}</div>
      </React.Fragment>
    );
  }

  renderPhone() {
    const { phoneError, areaFlag, areaValue, phoneValue } = this.state;
    const { lang } = this.props.match.params;
    const { areaData } = this.props;

    return (
      <React.Fragment>
        <div
          className={`${styles.login_box_input_box_phone} ${
            phoneError ? styles.login_box_input_box_border : ''
          }`}
        >
          <div
            className={styles.input_box_wrapper}
            ref={e => {
              this.input_box_wrapper = e;
            }}
          >
            <div
              className={styles.login_box_areaCode}
              onClick={() => {
                this.changeAreaFlag(!areaFlag);
              }}
              style={{ color: phoneValue ? '#333' : null }}
            >
              {areaData && areaData[areaValue]
                ? `+${areaData[areaValue].code}`
                : +86}
            </div>
            <div
              className={styles.login_box_arrow}
              onClick={() => {
                this.changeAreaFlag(!areaFlag);
              }}
              style={{ transform: areaFlag ? `rotate(180deg)` : null }}
            />
            {this.renderArea()}
          </div>

          <div className={styles.login_box_input_phone}>
            <input
              type="text"
              placeholder={locales(lang, 'enterPhone')}
              ref={e => {
                this.phone = e;
              }}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  return false;
                }
              }}
              onChange={e => {
                this.changeValue(e, 'phone');
              }}
            />
          </div>
        </div>
        <div className={styles.login_box_input_box_error}>{phoneError}</div>
      </React.Fragment>
    );
  }

  renderPasswordLogin() {
    const { lang } = this.props.match.params;
    const { passwordValue, phoneValue } = this.state;
    let buttonFlag = passwordValue && phoneValue;

    return (
      <div className={styles.login_box_content}>
        <div className={styles.login_box_title}>
          {locales(lang, 'pwdLogin')}
        </div>
        <div className={styles.login_box_input_list}>
          {/* 手机号 */}
          {this.renderPhone()}
          {/* 密码 */}
          {this.renderPassword()}

          <div className={styles.login_box_choice_list}>
            <div
              className={styles.login_box_choice}
              onClick={() => {
                this.clickChoice('VerificationLogin');
              }}
            >
              {locales(lang, 'verifLogin')}
            </div>
            <div
              className={styles.login_box_choice}
              onClick={() => {
                this.clickChoice('ForgetPassword');
              }}
            >
              {locales(lang, 'forgotPwd')}
            </div>
          </div>

          <div
            className={styles.login_box_button}
            onClick={() => {
              buttonFlag ? this.clickLogin(lang) : '';
            }}
            style={{ background: buttonFlag ? null : '#D4D6D9' }}
          >
            {locales(lang, 'signin')}
          </div>

          <div className={styles.login_box_text}>
            {locales(lang, 'otherLogin')}
          </div>
          <div className={styles.login_button_list}>
            <div
              className={styles.login_button_wechat}
              onClick={() => {
                wechatLogin();
              }}
            />
            <div
              className={styles.login_button_weibo}
              onClick={() => {
                weiboLogin();
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderVerificationLogin() {
    const { verifyValue, phoneValue } = this.state;
    const { lang } = this.props.match.params;
    let buttonFlag = verifyValue && phoneValue;
    return (
      <div className={styles.login_box_content}>
        <div className={styles.login_box_title}>
          {locales(lang, 'verifLogin')}
        </div>
        <div className={styles.login_box_input_list}>
          {/* 手机号 */}
          {this.renderPhone()}
          {/* 验证码 */}
          {this.renderVerification()}

          <div className={styles.login_box_choice_list}>
            <div
              className={styles.login_box_choice}
              onClick={() => {
                this.clickChoice('PasswordLogin');
              }}
            >
              {locales(lang, 'acc_Login')}
            </div>
          </div>

          <div
            className={styles.login_box_button}
            onClick={() => {
              buttonFlag ? this.verifyLogin(lang) : '';
            }}
            style={{ background: buttonFlag ? null : '#D4D6D9' }}
          >
            {locales(lang, 'signin')}
          </div>
          {lang == 'en' ? (
            <div>
              <div
                className={styles.login_box_text}
                style={{ display: 'inline-flex' }}
              >
                <input
                  type={'checkbox'}
                  checked={this.checkbox1}
                  onChange={e => {
                    this.setState({
                      checkbox1: e.currentTarget.checked
                    });
                  }}
                />
                I agree to receive text messages
              </div>
              <div
                className={styles.login_box_text}
                style={{ display: 'inline-flex' }}
              >
                <input
                  type={'checkbox'}
                  checked={this.checkbox2}
                  onChange={e => {
                    this.setState({
                      checkbox2: e.currentTarget.checked
                    });
                  }}
                />
                Read and agree
                <a
                  href="/aboutus?agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;Service teams&nbsp;
                </a>{' '}
                and{' '}
                <a
                  href="/aboutus?privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;Privacy policy&nbsp;
                </a>
              </div>
              <div
                className={styles.login_box_text}
                style={{ textAlign: 'left' }}
              >
                Message and data rates may apply. Enter your phone number to
                receive one time passcode message to your phone from[Beijing
                Planet Node Media Culture Co., Ltd]. Msg freq may vary Reply
                &rdquo;HELP&rdquo; for help, Reply &rdquo;STOP&rdquo; to cancel.
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.login_box_text}>
                {locales(lang, 'autoLogin')}
              </div>
              <div className={styles.login_box_text}>
                {locales(lang, 'agree')}
                <a
                  href="/aboutus?agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {locales(lang, 'user_Agre')}
                </a>
                <a
                  href="/aboutus?privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {locales(lang, 'privacy')}
                </a>
              </div>
              <div className={styles.login_box_text}>
                {locales(lang, 'otherLogin')}
              </div>
              <div className={styles.login_button_list}>
                <div
                  className={styles.login_button_wechat}
                  onClick={() => {
                    wechatLogin();
                  }}
                />
                <div
                  className={styles.login_button_weibo}
                  onClick={() => {
                    weiboLogin();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderBindPhone() {
    const { verifyValue, phoneValue } = this.state;
    const { lang } = this.props.match.params;
    let buttonFlag = verifyValue && phoneValue;
    return (
      <div className={styles.login_box_content}>
        <div className={styles.login_box_title}>
          {locales(lang, 'link_phone_number')}
        </div>
        <div className={styles.login_box_input_list}>
          {/* 手机号 */}
          {this.renderPhone()}
          {/* 验证码 */}
          {this.renderVerification()}

          <div
            className={styles.login_box_button}
            onClick={() => {
              buttonFlag ? this.bindPhone(lang) : '';
            }}
            style={{ background: buttonFlag ? null : '#D4D6D9' }}
          >
            {locales(lang, 'save')}
          </div>
        </div>
      </div>
    );
  }

  renderForgetPassword() {
    const { verifyValue, phoneValue, passwordValue } = this.state;
    const { lang } = this.props.match.params;
    let buttonFlag = verifyValue && phoneValue && passwordValue;
    return (
      <div className={styles.login_box_content}>
        <div className={styles.login_box_title}>
          {locales(lang, 'reset_Pwd')}
        </div>
        <div className={styles.login_box_input_list}>
          {/* 手机号 */}
          {this.renderPhone()}
          {/* 验证码 */}
          {this.renderVerification()}
          {/* 密码 */}
          {this.renderPassword()}

          <div
            className={styles.login_box_button}
            onClick={() => {
              buttonFlag ? this.changePassword() : '';
            }}
            style={{ background: buttonFlag ? null : '#D4D6D9' }}
          >
            {locales(lang, 'save')}
          </div>
        </div>
      </div>
    );
  }

  renderSetPassword() {
    const { passwordValue } = this.state;
    const { lang } = this.props.match.params;
    let buttonFlag = passwordValue;
    return (
      <div className={styles.login_box_content}>
        <div className={styles.login_box_title}>{locales(lang, 'set_pw')}</div>
        <div className={styles.login_box_input_list}>
          {/* 密码 */}
          {this.renderPassword()}

          <div
            className={styles.login_box_button}
            onClick={() => {
              buttonFlag ? this.setNewPwd(lang) : '';
            }}
            style={{ background: buttonFlag ? null : '#D4D6D9' }}
          >
            {locales(lang, 'save')}
          </div>
        </div>
      </div>
    );
  }

  renderBox() {
    const { loginType } = this.props;
    switch (loginType) {
      case 'PasswordLogin':
        return this.renderPasswordLogin();
      case 'VerificationLogin':
        return this.renderVerificationLogin();
      case 'ForgetPassword':
        return this.renderForgetPassword();
      case 'SetPassword':
        return this.renderSetPassword();
      case 'BindPhone':
        return this.renderBindPhone();
    }
  }

  render() {
    const { loginFlag } = this.props;
    return (
      <div
        className={styles.container}
        style={{
          zIndex: loginFlag ? null : -1,
          transition: loginFlag ? 'z-index 0.1s' : null
        }}
      >
        <div
          className={styles.container_wrapper}
          style={{ display: loginFlag ? null : 'none' }}
          onClick={() => {
            this.clickClose();
          }}
        />

        <div
          className={`${styles.login_box_wrapper} ${
            loginFlag ? styles.login_box_active : styles.login_box_hidden
          }`}
        >
          <div className={styles.login_box}>
            <div
              className={styles.login_box_close}
              onClick={() => {
                this.clickClose();
              }}
            />
            <div className={styles.login_box_header} />
            {this.renderBox()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginType: state.login.loginType,
    loginFlag: state.login.loginFlag,
    areaData: state.login.areaData,
    currentUser: state.user.current
  };
};

export default withRouter(connect(mapStateToProps)(Login));
