import React, { Component } from 'react';
import styles from '../../index.scss';
import PropTypes from 'prop-types';

export class PrivacyKo extends Component {
  static propTypes = {
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.content}>
        <h5>{this.props.title}</h5>
        <h6>목차</h6>

        <p>1. 소개</p>
        <p>2. 회사 소개</p>
        <p>3. 귀하의 개인 정보를 수집하고 사용하는 방법</p>
        <p>4. 당사가 쿠키 및 유사 기술을 사용하는 방법</p>
        <p>5. 개인정보의 처리위탁, 외부제공, 이전 및 공개</p>
        <p>6. 귀하의 개인 정보를 저장하고 보호하는 방법</p>
        <p>7. 개인 정보 관리 방법</p>
        <p>8. 미성년자의 개인정보 보호</p>
        <p>9. 개인 정보 보호 정책에 대한 개정 및 통지</p>
        <p>10. 사망한 사용자의 개인 정보 보호</p>
        <p>11. 당신은 어떻게 저희에게 연락합니까</p>
        <p>12. 분쟁 해결</p>

        <h6>1. 소개</h6>
        <p>
          본 &quot;오데일리 개인정보 보호정책&quot;(이하 &quot;본 개인정보
          보호정책&quot;)은 오데일리(이하 &quot;플랫폼&quot; 또는
          &quot;당사&quot;라 함)의 소프트웨어 및 관련 서비스(이하 &quot;오데일리
          서비스&quot;라 함)에 적용됩니다. 각 조항의 제목은 조항의 요지를
          이해하는 데 도움을 주기 위해서만 사용되며, 본 개인 정보 보호 정책
          조항의 의미나 해석에 영향을 미치거나 제한하지 않습니다. 자신의 권리와
          이익을 보호하기 위해 각 조항의 구체적인 표현을 주의 깊게 읽을 것을
          권장합니다.
        </p>
        <p>
          특별한 주의를 기울이십시오: 이 개인 정보 보호 정책은 웹 사이트,
          클라이언트, 애플릿 및 기술 개발과 함께 등장하는 새로운 형태로 귀하에게
          제공하는 모든 ODAILY 서비스에 적용됩니다. 오데일리 서비스가 당사 및
          제휴사의 제품이나 서비스에 이용되나 별도의 개인정보취급방침이 없는
          경우에는 이 부분의 서비스에도 본 개인정보취급방침이 적용됩니다. 당사
          및 당사 계열사가 귀하에게 제공하는 오데일리 서비스에 대해 별도의
          개인정보 보호정책이 있는 경우 해당 제품 또는 서비스에 해당 개인정보
          보호정책이 적용됩니다.
        </p>
        <p>
          귀하는 응용 프로그램 등록 과정에서 또는 이 개인정보 보호정책이
          업데이트될 때 이 개인정보 보호정책에 동의하기 위해 클릭하기 전에 이
          개인정보 보호정책을 주의 깊게 읽어야 합니다. 각 조항의 내용을 주의
          깊게 읽고 충분히 이해하시기 바랍니다.
          <b>
            귀하의 개인정보 권리와 관련하여 약관의 중요한 내용을 강조하였으니
            각별한 주의를 기울여 주시기 바랍니다.
          </b>
        </p>
        <p>
          등록 페이지의 안내에 따라 정보를 입력하고 본 개인 정보 보호 정책을
          읽고 동의한 후 모든 등록 절차를 완료하거나 본 개인 정보 보호 정책이
          업데이트되었을 때 동의를 클릭하면 전체 내용을 완전히 읽고 이해하고
          동의한 것으로 간주됩니다. 이 개인 정보 보호 정책의 내용.
          <b>
            이 개인 정보 보호 정책을 읽는 과정에서 이 개인 정보 보호 정책 또는
            그 조건에 동의하지 않으면 해당 오데일리 서비스 및 일부 기능을
            정상적으로 사용할 수 없거나 서비스 효과를 얻지 못할 수 있습니다.
            예상되는.
          </b>
        </p>
        <h6>2. 회사 소개</h6>
        <p>
          1. 이 플랫폼의 운영자 및 ODAILY 서비스 제공자는 Hainan Moti Culture
          Media Co., Ltd.입니다.
        </p>
        <p>
          2. 계열사는 Hainan Moti Culture Media Co., Ltd.를 통제하거나 Hainan
          Province Moti Culture Media Co., Ltd.가 통제하거나 Hainan Province
          Moti Culture Media Co., Ltd.와 공동 통제하에 있는 회사를 의미합니다.
          현재와 ​​미래 메커니즘. 제어는 소유권, 의결권, 계약, 실제 운영 협회
          또는 기타 법적으로 인정되는 수단을 통해 제어 대상의 관리/운영에
          직간접적으로 영향을 미치는 능력을 의미합니다.
        </p>
        <h6>3. 귀하의 개인 정보를 수집하고 사용하는 방법</h6>
        <p>
          1. 당사는 귀하가 서비스 이용 과정에서 자발적으로 제공하거나 오데일리
          서비스의 필요에 따라 생성된 개인정보를 법령에 의거하고 적법성 및
          필요성의 원칙에 따라 수집 및 이용합니다. 본 개인정보 처리방침에
          명시되지 않은 다른 목적으로 귀하의 개인정보를 이용하거나 특정 목적에
          따라 다른 목적으로 수집된 정보를 이용하려는 경우, 적시에 합리적인
          방법으로 귀하에게 통지하고 귀하의 동의를 다시 얻습니다.
        </p>
        <p>
          2. 당사가 수집하고 사용하는 귀하의 개인정보에는 두 가지 유형이
          있습니다. 첫 번째 유형: ODAILY 서비스의 핵심 비즈니스 기능에 필요한
          정보: 이 유형의 정보는 이 플랫폼의 정상적인 운영에 필요하며 귀하는
          당사를 승인해야 합니다. 그것을 수집합니다. 제공을 거부할 경우 해당
          오데일리 서비스 및 일부 기능의 정상적인 이용이 불가능할 수 있으며, 두
          번째 유형은 추가적인 업무 기능을 위해 수집할 수 있는 정보로 비핵심
          업무 기능을 위해 필요한 정보이며, 귀하는 당사가 수집하도록 승인할지
          여부를 선택할 수 있습니다. 귀하가 제공을 거부하는 경우 추가 비즈니스
          기능 또는 달성하려는 효과를 실현할 수 없지만 핵심 비즈니스 기능의
          정상적인 사용에는 영향을 미치지 않습니다.
        </p>
        <p>
          3.
          <b>
            오데일리는 많은 서비스를 제공하고 다양한 콘텐츠를 제공하기 때문에
            오데일리 서비스의 콘텐츠에 따라 핵심 비즈니스 기능(수집하는 개인
            정보 유형 포함)도 다를 수 있습니다. ODAILY 서비스의 실제 제공. 또한
            완벽한 ODAILY 서비스를 제공하기를 희망하므로 기술을 포함한 ODAILY
            서비스를 지속적으로 개선할 것이므로 새로운 비즈니스 기능을 자주
            출시하고 새로운 개인 정보를 수집하거나 변경해야 할 수 있음을
            이해하고 동의합니다. 개인정보의 이용 목적 또는 방법 본
            개인정보취급방침에 귀하의 개인정보를 수집해야 하는 기능이나 서비스가
            명시되어 있지 않은 경우에는 본 개인정보취급방침, 페이지 안내,
            팝업창, 웹사이트 공지사항 등을 업데이트하여 개인정보 수집목적을
            별도로 설명하겠습니다. .목적, 내용, 이용방법 및 범위에 대한 동의를
            선택하는 방법을 제공하며, 귀하의 명시적 동의를 얻은 후 수집합니다.
          </b>
          이 과정에서 관련 문제에 대해 의문이 있는 경우 이 개인 정보 보호 정책
          열한 번째를 사용할 수 있습니다. 저희에게 연락하기 위해 제공된 방법 중
          하나로, 가능한 한 빨리 답변해 드리겠습니다.
        </p>
        <p>
          4. 본 개인정보취급방침에서 민감한 개인정보란 외부로 유출되거나
          부정하게 이용될 경우 자연인의 존엄성을 침해하거나 인신 및 재산의
          안전을 위협할 우려가 있는 개인정보를 말하며, 여기에는 다음이
          포함됩니다.
          <b>생체 인식, 종교적 신념, 특정 신원, 건강 관리, 금융 계좌, 소재지</b>
          기타 정보 및<b>만 14세 미만 미성년자의 개인정보</b>,예를 들어,
          <b>
            민감한 개인정보에는 주민등록번호, 개인생체정보, 은행계좌번호,
            통신내용, 건강 및 생리정보 등이 포함됩니다.
          </b>
          기다리다. 당사가 수집하는 민감한 개인 정보는 이 개인 정보 보호 정책에
          눈에 띄게 표시되었습니다. 귀하의 민감한 개인정보가 유출, 변조 또는
          불법적으로 사용되면 귀하의 인명 및 재산의 안전을 해칠 수 있습니다.
          귀하의 민감한 개인정보가 유출되거나 부정하게 이용되는 것을 방지하기
          위하여 적절한 기술적, 조직적 보호조치를 취하여 귀하의 정보를 안전하게
          보호하고 있습니다.
        </p>
        <p>5. 일반적으로 다음과 같은 경우에 개인정보를 수집 및 이용합니다.</p>
        <p>5.1 회원가입 및 로그인 상태에서의 ODAILY 서비스</p>
        <p>
          지속적이고 안정적인 서비스를 제공하고 오데일리 서비스 이용의 보안을
          보장하기 위해 휴대폰 번호, 이메일 주소, 계정 생성, 사용자 이름을
          포함한 기본 가입 또는 로그인 정보를 적극적으로 제공해야 합니다. 및
          비밀번호<b>휴대폰 번호와 비밀번호는 개인의 민감한 정보입니다.</b>,
          관련법령의 인터넷실명제 요구사항 및 본 개인정보취급방침의 요구사항을
          준수하기 위하여 상기 정보를 수집합니다. 일부 개별 서비스의 경우
          브라우징 및 검색의 기본 기능만 사용하면 되는 경우 오데일리 사용자로
          등록하고 위 정보를 제공할 필요가 없습니다.
        </p>
        <p>
          또한 타사 플랫폼 계정을 사용하여 ODAILY 사용자로 등록할 수 있으며,
          당사가 읽고 얻을 수 있는 권한을 부여할 수 있습니다.
          <b>
            귀하가 제3자 플랫폼에 게시하고 기록하는 공개 정보(닉네임 및 아바타
            포함)
          </b>
          . ODAILY 사용자로서 귀하의 로그인 ID를 기억하기 위해 제3자로부터 위
          정보를 얻으려면 귀하의 승인이 필요합니다.위 정보의 수집은 귀하의
          동의를 기반으로 합니다.제3자 계정으로 등록하거나 로그인한 후 귀하의
          제3자는 -파티 계정은 바인딩을 위해 ODAILY 계정과 연결됩니다.
        </p>
        <p>
          통신 사업자와의 협력을 바탕으로 오데일리의 &quot;원클릭 로그인&quot;
          기능을 사용하실 때, 고객님의 명시적인 동의 하에 통신 사업자는 빠른
          로그인 서비스를 제공할 수 있도록 고객님의 휴대폰 번호를 보내드립니다.
          등록 또는 로그인을 완료하기 위해 휴대폰 번호를 수동으로 입력하도록
          선택할 수도 있습니다.
        </p>
        <p>
          귀하는 회원가입, 로그인 또는 후속 이용 과정에서 귀하의 기타 추가
          정보(닉네임, 아바타, 성별, 생년월일, 신원, 업종)를 적극적으로
          기입하거나 보완할 수 있습니다. ODAILY 사용자 신원을 식별하고 다른
          ODAILY 사용자와의 커뮤니케이션 및 상호 작용을 용이하게 하기 위해 개인
          홈페이지 및 댓글 영역에 표시될 수 있습니다. 다만, 해당 정보를 제공하지
          않으셔도 오데일리 서비스 이용에 있어 기본적인 기능에는 지장이
          없습니다.
        </p>
        <p>5.2 미가입 및 로그인 상태의 ODAILY 서비스</p>
        <p>
          회원가입이나 로그인 없이 오데일리 서비스를 이용하는 경우 비로그인
          모드에서만 일부 기능을 제공할 수 있습니다. 기능 실현을 보장하기 위해
          브라우징, 검색, 좋아요, 수집 및 공유 기능을 실현하기 위해 귀하의 장치
          정보 및 네트워크 식별 정보를 얻기 위해 신청하는 주도권을 가질 수
          있습니다. 네트워크 로그 정보 보존에 대한 관련 법률 및 규정의 요구
          사항을 충족합니다.
        </p>
        <p>
          위의 정보 외에도 귀하가 사용하는 동안 귀하의 획득을 위해 적극적으로
          신청할 수 있습니다.<b>장치 권한</b>기타 추가 기능을 달성하기 위해(본
          개인정보 보호정책의 섹션 5.9, &quot;귀하에게 보안 제공&quot; 참조).
        </p>
        <p>5.3 정보 검색</p>
        <p>
          1) ODAILY는 이 기능을 실현하기 위해 귀하에게 더 흥미로운 정보를 추천할
          수 있습니다.<b>필요한 로그 정보를 자동으로 수집할 수 있습니다.</b>
          ,포함하다:
        </p>

        <ul className={styles.privacy_ul}>
          <li>
            a) 귀하의 작업 및 사용 행동 정보: 클릭, 팔로우, 즐겨찾기, 검색,
            찾아보기, 공유
          </li>
          <li>b) 장비 정보: 장비의 고유 식별 코드;</li>
          <li>
            c) 네트워크 정보: GPS 정보, WLAN 액세스 포인트, 블루투스, 기지국
            등의 센서 정보에서 얻은 정확한 지리적 위치 정보와 네트워크 위치
            정보(기지국, IP, WLAN)에서 얻은 대략적인 지리적 위치 정보
          </li>
        </ul>

        <p>
          2）<b>GPS 위치는 개인의 민감한 정보입니다.</b>, 제공을 거부하는 경우
          GPS 정보를 기반으로 정보를 추천하지 않으며 본 플랫폼의 다른 기능의
          정상적인 사용에 영향을 미치지 않습니다.
        </p>

        <p>
          3) 대략적인 지리적 위치 정보에 해당하는 도시를 기준으로 해당 지역의
          지역 관련 정보를 보여주고 추천해 드립니다.
        </p>

        <p>
          4) 위의 정보는 웹 로그 정보 보존에 관한 관련 법률 및 규정의 요구
          사항을 충족하고 본 개인 정보 보호 정책의 요구 사항을 충족하기 위해
          수집됩니다.
          <b>
            당사가 수집하고 사용하는 위에서 언급한 정보는 비식별 처리되었으며
            데이터 분석은 귀하의 신원과 직접 연결될 수 없는 특정 코드에만
            해당하며 실제 신원과 연결되지 않습니다.
          </b>
          。
        </p>

        <p>5.4 릴리스 및 상호 작용</p>
        <p>1) 정보 공개</p>
        <ul className={styles.privacy_ul}>
          <li>
            a) 귀하가 콘텐츠나 댓글을 게시할 때 귀하가 게시한 정보를 자동으로
            수집하여 귀하의 닉네임, 프로필 사진 및 게시 내용을 표시합니다.
          </li>
          <li>
            b) 사진 업로드 및 오디오 및 비디오 게시 기능을 사용할 때
            카메라(카메라), 앨범(저장공간), 마이크 권한에 대한 능동적 승인을
            요청합니다. 승인을 거부하면 이 기능을 사용할 수 없지만 이 플랫폼의
            다른 기능을 정상적으로 사용하는 데 영향을 미치지 않습니다.
          </li>
          <li>
            c) 공개적으로 공개하는 정보에는 이용자 또는 타인의 개인정보가 포함될
            수 있음을 유의하시고, 공개적으로 공개하는 정보가 타인의 개인정보를
            포함하는 경우 법령에 의해 이용이 허용되거나 취득되었는지 확인해야
            합니다. 업로드 및 게시하기 전에 법적으로 승인되었습니다.
          </li>
          <li>
            d) 라이브 방송 기능을 사용할 때 법률 및 규정, 보안 요구 사항 및 본
            개인 정보 보호 정책 이행에 따라 실명 인증을 수행해야 합니다.
            실명인증 과정에서 본인인증 서비스의 제3자 인증기관에서 제공을 필요로
            하는 경우
            <b>실명 및 ID 번호 정보. 이 정보는 개인의 민감한 정보입니다.</b>,
            귀하는 제공을 거부할 수 있으며 본 플랫폼의 다른 기능의 정상적인
            사용에 영향을 미치지 않습니다. 이 정보는 실명인증 및 법령에서 정한
            목적을 위해서만 사용되며, 귀하의 명시적인 승인 없이는 다른 목적으로
            사용되지 않습니다.
          </li>
          <li>
            e) 상기 정보는 관련 법령에 따른 정보유출계정, 공개계정, 생방송
            이용자 등 서비스 제공자의 보유 의무사항과 본 개인정보처리방침의
            요구사항을 충족하기 위해 수집합니다.
          </li>
        </ul>

        <p>2) 양방향 커뮤니케이션</p>
        <ul className={styles.privacy_ul}>
          <li>
            a) 귀하가 콘텐츠를 팔로우하면 귀하가 팔로우하는 콘텐츠 또는 계정을
            수집하고 해당 콘텐츠 또는 해당 계정에서 게시한 콘텐츠를 보여줍니다.
          </li>
          <li>
            b) 웹 로그 정보 보존에 관한 관련 법률 및 규정의 요구 사항을 충족하고
            본 개인 정보 보호 정책의 요구 사항을 충족하기 위해 상기 정보를
            수집합니다.
          </li>
        </ul>

        <p>5.5 검색</p>
        <p>
          1) 검색 서비스 이용 시 보다 관련성 높은 검색 결과를 제공하기 위해
          분석을 위한 검색 키워드 정보 및 로그 정보 기록을 자동으로 수집합니다.
        </p>
        <p>
          2) 또한 검색을 사용할 때 검색어를 입력하거나 기록 단어, 프롬프트 단어
          및 클릭한 링크를 클릭하거나 제출된 검색 작업에 참여하는 경우와 같이
          서비스를 사용할 때 제공, 형성 또는 보유하는 정보를 자동으로 기록할 수
          있습니다. 정보. 귀하는 위 정보 제공을 거부할 권리가 있지만, 이로 인해
          특정 서비스 또는 기능을 사용할 수 없거나 관련 서비스의 의도된 효과를
          달성할 수 없습니다.
        </p>
        <p>
          3) 위와 같은 정보는 네트워크 로그 정보 보유에 관한 관련법령의 요구사항
          및 본 개인정보취급방침의 요구사항을 충족하기 위해 수집되며, 효율적인
          검색 서비스 제공을 위해 위의 일부 정보를 수집합니다. 정보는 로컬 저장
          장치에도 임시 저장되며 검색 결과 및 검색 기록의 내용을 표시할 수
          있습니다.
        </p>

        <p>
          5.6 <b>공고</b>
        </p>
        <p>
          <b>
            귀하는 제품 및/또는 서비스를 사용하는 과정에서 귀하가 적극적으로
            제공한 귀하의 연락처 정보(연락처 번호)에 대해 사용자 메시지 목적으로
            운영 중에 하나 이상의 여러 유형의 알림을 보낼 수 있음을 이해하고
            동의합니다. 알림, 본인 확인, 보안 확인, 사용자 경험 조사 및 분쟁
            해결 목적 외에도 당사는 귀하가 관심을 가질 만한 서비스를 제공할 수
            있습니다. 상기 처리, 기능 또는 이벤트 광고성 정보에 관한 정보를
            제공하나, 수신을 원하지 않는 경우에는 휴대폰 문자 메시지에 제공된
            수신거부 방법을 통해 수신거부를 하거나, 직접 연락하여 수신거부를
            하실 수 있으니 안심하시기 바랍니다.
          </b>
        </p>

        <p>5.7 고객 서비스</p>
        <p>
          귀하가 피드백을 제공할 때 귀하에게 연락하거나 문제를 해결하는 데
          도움을 주기 위해 귀하의 이름, 휴대폰 번호, 이메일 주소 및 기타 연락처
          정보를 적극적으로 제공해야 할 수 있으며 이 정보를 사용하여 귀하와
          연락할 수 있습니다. . 귀하가 저작권 침해 신고서를 제출하는 경우 당사는
          또한 귀하에게<b>신분증, 위임장, 소유권 증명서</b>, 불만 사항의
          대상으로서 귀하의 자격을 명확히 하기 위해.
        </p>

        <p>5.8 거래 서비스</p>
        <p>
          귀하가 온라인 독서, 온라인 강좌, 포인트몰 상품 또는 기타 수단을 통해
          당사의 상품이나 서비스를 구매하는 경우 귀하의 거래 기록 및 로그 정보를
          자동으로 수집합니다. 상기 정보는 네트워크 로그 정보 및 거래 정보
          보존에 관한 관련 법령의 요구 사항과 본 개인 정보 보호 정책의 요구
          사항을 충족하기 위해 수집되며, 그 중
          <b>거래기록정보는 개인의 민감한 정보입니다</b>, 이 정보 제공을
          거부하면 거래 서비스를 제공할 수 없지만 Odaily 서비스의 다른 기능을
          정상적으로 사용하는 데 영향을 미치지 않습니다.
        </p>

        <p>5.9 보안을 제공하기 위해</p>
        <p>
          &quot;중화인민공화국 네트워크 보안법&quot;(이하 &quot;네트워크
          보안법&quot;) 및 기타 법률 및 규정의 요구 사항을 충족하기 위해
          네트워크 보안 보호의 필요성과 이 요구 사항의 이행 개인정보취급방침은
          귀하에게 안전하고 믿을 수 있는 서비스를 제공하고 보장하겠습니다.
          시스템 운영의 안정성과 보안성을 확보하고, 귀하의 계정이 도난당하거나
          개인정보가 불법적으로 취득되는 것을 방지하고, 귀하의 계정 보안을
          보호하기 위해 귀하의 계정에 대한 적극적인 적용이 필요합니다. 1) 기기
          정보(기기 모델, 기기 MAC 주소, 운영 체제 포함) 유형, 클라이언트 버전,
          해상도, 기기 설정), 기기 고유 식별 코드(IMEI/안드로이드
          ID/IDFA/OPENUDID/GUID, SIM 카드 IMSI 정보) 수집 ; 2) 네트워크
          아이덴티티 정보(포함)<b>GPS 위치 정보</b>, IP 주소, 통신 사업자,
          네트워크 환경, 소프트웨어 버전 번호, 브라우저 유형,
          <b>웹사이트 검색 기록</b>, 작업 로그). 계정의 위험을 판단하고 시스템
          보안을 위협하거나 불법적이거나 규칙 및 계약을 위반하는 행위를 방지,
          발견 및 조사하기 위해. 또한 제품 또는 서비스 시스템 문제를 분석하고
          트래픽 통계를 수집하며 가능한 위험을 해결하기 위해 앞서 언급한 장치
          정보 및 소프트웨어 정보를 수집합니다. 위의 정보를 제공하지 않으면 제품
          또는 서비스를 사용하는 동안 제품 또는 서비스 및 계정의 보안을 보호할
          수 없습니다.
        </p>

        <p>5.10 사용자 경험 및 만족도 향상</p>
        <p>
          ODAILY 서비스에 대한 귀하의 경험과 만족도를 향상시키기 위해 귀하의
          운영 행동을 자동으로 수집하고 귀하의 운영 행동을 기반으로 사용자
          데이터에 대한 연구 및 분석을 수행하여 ODAILY 서비스를 더욱
          최적화합니다.
        </p>

        <p>
          5.11 <b>동의 예외</b>
        </p>
        <p>
          <b>
            다음과 같은 상황에서는 개인 정보를 수집하고 사용하기 위해 귀하의
            승인이 필요하지 않습니다.
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1) 국가안보 및 국방안보와 관련된 것</li>
          <li>2) 공공 안전, 공중 보건 및 주요 공익과 관련된 내용</li>
          <li>3) 범죄 수사, 기소, 재판 및 판결 집행에 관한 사항</li>
          <li>
            4) 개인정보주체 또는 다른 개인의 생명, 재산 등 주요한 법적 권익을
            보호하기 위하여 해당 개인의 동의를 받는 것이 곤란한 경우
          </li>
          <li>5) 수집된 개인정보는 개인정보주체 본인이 직접 공개합니다.</li>
          <li>
            6) 귀하의 개인정보는 법률 뉴스 보도, 정부 정보 공개 및 기타 채널과
            같은 합법적으로 공개된 정보에서 수집됩니다.
          </li>
          <li>7) 귀하의 요청에 따른 계약 체결에 필요한 경우</li>
          <li>
            8) 제품 및 서비스의 장애 발견 및 처리 등 제공되는 제품 및 서비스의
            안전하고 안정적인 운영을 유지하기 위해 필요한 경우
          </li>
          <li>9) 법적인 뉴스 보도를 위해 필요합니다.</li>
          <li>
            10) 학술연구기관이 공익을 기반으로 통계 또는 학술연구를 수행하기
            위하여 필요한 경우로서 학술연구 또는 기술결과를 외부에 제공하는
            경우에는 그 결과에 포함된 개인정보를 비식별화하여야 한다.
          </li>
          <li>11) 기타 법령에서 정하는 경우</li>
        </ul>
        <p>
          <b>
            단독으로 또는 다른 정보와 결합하여 귀하를 식별할 수 없거나 귀하에게
            직접 연락할 수 없는 정보는 개인정보가 아님을 유의하십시오. 단독으로
            특정 개인과 연결될 수 없는 정보를 다른 정보와 결합하여 자연인을
            개인적으로 식별하거나 개인정보와 결합하여 사용하는 경우 해당 정보는
            결합된 사용 기간 동안 개인정보로 간주됩니다.
          </b>
        </p>

        <p>5.12 장치 권한 호출</p>
        <p>
          관련된 ODAILY 서비스의 정상적인 구현을 보장하기 위해 특정 사용
          시나리오에 따라 해당 필수 권한을 호출해야 합니다.특정 권한 호출 지침은
          다음을 참조하십시오.
          <a href="?application-authority" target="_blank">
            &quot;신청 허가 신청 및 안내&quot;
          </a>
          。
        </p>
        <p>
          장치 설정에서 일부 또는 모든 권한을 해제하도록 선택할 수 있으며 이로
          인해 해당 관련 ODAILY 서비스가 실현되지 않거나 예상한 효과를 얻지 못할
          수 있습니다.
        </p>

        <p>5.13 개인화 추천</p>
        <p>
          ODAILY 서비스는 개인화 된 콘텐츠의 추천 및 표시를 포함하지 않으며 세로
          분석을 위해 귀하의 브라우징 및 검색 정보, 행동 선호도 및 기타 정보를
          수집하지 않습니다.
        </p>

        <h6>4. 당사가 쿠키 및 유사 기술을 사용하는 방법</h6>
        <p>
          1. 쿠키는 웹사이트가 등록된 사용자를 식별하고 사용자 수를 계산하는 데
          도움이 되며 일반적으로 웹사이트에서 등록을 완료한 사용자의 로그인
          여부를 확인하는 데 사용됩니다. 플랫폼은 쿠키 정보에 대한 연구는
          서비스/제품 품질을 개선하고 사용자 경험을 최적화하는 목적으로만
          사용됨을 약속합니다. 동시에 개인 정보가 쿠키에 저장되는 것을 원하지
          않으면 브라우저를 구성할 수 있습니다. &quot;쿠키 거부&quot; 또는
          &quot;웹사이트에서 쿠키를 보낼 때 알림&quot;을 ​​선택하십시오. 귀하는
          쿠키를 지원하여 ODAILY 서비스가 구현되기 때문에 쿠키를 닫은 후에 이
          플랫폼에 대한 액세스가 영향을 받거나 이 플랫폼의 서비스를 완전히 얻지
          못할 수 있음을 확인합니다. 쿠키를 비활성화하지 않으면 로그인 절차(예:
          원 클릭 로그인)를 단순화하기 위해 다음에 이 웹 사이트에 들어갈 때
          사용자 정보를 유지할지 여부를 묻는 메시지가 표시될 수 있습니다.
        </p>
        <p>
          2. 본 플랫폼에서 웹비콘을 사용하는 방법 및 목적은 쿠키와 동일하며 위의
          약관을 참조하여 적용합니다.
        </p>

        <h6>5. 개인정보의 처리위탁, 외부제공, 이전 및 공개</h6>
        <p>1. 개인정보 처리위탁</p>
        <p>
          보다 완벽하고 고품질의 ODAILY 서비스를 제공하기 위해 일부 기능은
          당사의 서비스 파트너(예: 제3자 서비스 제공자, 계약자, 대리인)에서
          제공할 수 있으며, 당사는 서비스 파트너에게 귀하의 정보를 처리하도록
          위탁합니다. ODAILY에 원클릭 로그인 서비스, 푸시 메시지 서비스, 데이터
          분석 및 통계 서비스를 제공하는 파트너를 포함하되 이에 국한되지 않는
          특정 개인 정보. 당사는 서비스 파트너에게 엄격한 비밀 유지 의무를
          준수하도록 요구하고 서면 계약, 현장 감사 등을 통해 효과적인 비밀 유지
          조치를 취하고 이러한 정보를 귀하가 승인하지 않은 목적으로 사용하는
          것을 금지합니다. 위탁 관계가 종료된 후에는 서비스 파트너가 귀하의 개인
          정보를 더 이상 저장하지 않습니다.
        </p>
        <p>2. 외부 제공</p>
        <p>
          당사는 다음과 같은 경우를 제외하고 귀하의 개인정보를 본 제품의 서비스
          제공자가 아닌 회사, 단체 및 개인에게 제공하지 않습니다.
        </p>
        <p>
          2.1 법적 의무 이행에 필요한 경우: 당사는 법률 및 규정의 규정에 따라
          소송, 분쟁 해결 또는 행정 및 사법 당국의 요구 사항 및 기타 법적 의무
          이행의 필요성에 따라 다음을 수행할 수 있습니다. 또는 기타 법률에서
          정한 법적 사유, 공중 보건 긴급 상황에 대응하거나 긴급 상황에서 귀하의
          생명, 건강 및 재산 안전을 보호하거나 공익을 위해 뉴스 보도 및 여론
          감독을 구현하기 위해 필요한 경우 귀하의 개인 정보를 제공하십시오. 외부
          세계로.
        </p>
        <p>
          2.2 귀하의 동의 또는 귀하의 주도적 요청에 의거: 귀하가 별도로
          동의하거나 귀하의 개인정보를 제3자에게 제공하도록 요청하는 경우,
          당사는 목적에 따라 해당 페이지에서 개인정보를 적절하게 제공받는 자를
          알려드립니다. 귀하의 동의 이름 및 연락처 정보. 정보 공유 서비스를
          이용하는 경우 해당 페이지에 제3자 공유 플랫폼의 이름을 제공하며,
          구체적인 연락처 정보는 제3자 개인 정보 보호 정책을 참조하십시오.
        </p>
        <p>
          2.3 서비스 계약 이행에 필요: 당사의 ODAILY 서비스 중 일부는 당사
          계열사 및 협력 기관 또는 그들과 공동으로 귀하에게 제공될 수 있습니다.
          따라서 귀하와 서비스 계약을 이행하기 위해서는 귀하의 개인정보를 당사의
          제휴사 또는 협력기관에 제공해야 합니다. 우리는 공식화
          <a href="?info-share-list" target="_blank">
            &quot;정보 공유 목록&quot;
          </a>
          제휴사 및 협력기관의 상황을 명확하고 간결하게 소개하고, &quot;정보공유
          리스트&quot;를 확인합니다.
        </p>

        <p>
          1) 제휴사 제공: 당사가 제휴사 또는 기타 제품과 함께 제공하는 제품 또는
          서비스의 사용을 용이하게 하기 위해 귀하의 개인 정보를 당사의 제휴사
          및/또는 지정된 서비스 제공업체와 공유할 수 있습니다. 당사는 반드시
          필요한 개인정보만을 공유하며, 본 정책에서 명시한 목적을 준수하며,
          귀하의 민감한 개인정보를 공유하거나 제휴사가 개인정보의 이용 및 처리
          목적을 변경하는 경우 다시 한 번 귀하의 승인을 요청할 것입니다.
        </p>

        <p>
          2) 당사 협력 기관에 제공: 완전한 제품 및 서비스를 제공하기 위해 당사의
          일부 서비스 또는 기술은 공인 협력 기관에서 제공됩니다. 당사는 귀하의
          개인 정보를 다른 개인 정보 처리자에게 적극적으로 제공하지 않으며,
          귀하의 개인 정보를 합법적이고 적법하며 필요하고 구체적이고 명확한
          목적으로만 공유하고 필요한 개인 정보만 공유함을 알아두십시오. 귀하의
          개인정보를 타 개인정보처리자에게 제공하고, 제공받는 자의 성명, 연락처,
          처리목적, 처리방법 및 개인정보의 종류를 귀하에게 알리고 귀하의 별도
          동의를 받도록 하겠습니다. 동시에 당사는 파트너 조직과 엄격한 기밀 유지
          계약을 체결하여 본 개인 정보 보호 정책 및 기타 관련 기밀 정책에 따라
          귀하의 개인 정보를 엄격하게 처리하고 보안 조치를 취하도록 요구합니다.
          우리는 파트너 기관이 다른 목적으로 공유된 개인 정보를 사용할 권리가
          없도록 요구합니다.
          <b>
            귀하가 제3자가 만든 웹 페이지를 보거나 제3자가 개발한 애플리케이션을
            사용하는 경우 이러한 제3자는 자체 쿠키를 설치하거나 다른 기술적
            수단을 사용할 수 있으며 이러한 기술적 수단은 당사의 통제를 받지
            않으며 이들의 사용은 본 약관에 의해 규율되지 않습니다. 개인 정보
            보호 정책. 협력기관은 자체적으로 독자적인 개인정보보호정책 및
            이용약관을 두고 있을 수 있으며, 제3자의 개인정보보호정책 및
            이용약관을 반드시 읽고 준수하실 것을 권장합니다.
          </b>
        </p>

        <p>
          2.4 법률에 규정된 기타 법적 사유에 근거: 당사는 공중 보건 긴급 상황
          또는 긴급 상황에 대응하여 귀하의 생명, 건강 및 재산 안전을 보호하거나
          공익을 위해 뉴스를 구현하는 등 법률에 규정된 기타 법적 사유를 사용할
          수 있습니다. 신고, 공론화 감독 등을 위해 귀하의 개인정보를 제3자에게
          제공하는 경우, 적절한 시점(긴급상황 해소 후 등)에 관련 정보를
          알려드립니다.
        </p>

        <p>3. 양도</p>
        <p>
          당사는 다음과 같은 경우를 제외하고 귀하의 개인 정보를 회사, 조직 또는
          개인에게 이전하지 않습니다.
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) 명시적인 동의를 받아 전송합니다. 귀하의 명시적인 동의를 얻은 후
            당사는 귀하의 개인 정보를 다른 당사자에게 전송합니다.
          </li>
          <li>
            2) 합병, 인수 또는 파산 청산의 경우 양도. ODAILY가 합병, 인수 또는
            파산 청산에 관여할 때 개인 정보 이전이 관련된 경우 귀하의 개인
            정보를 관리하는 새로운 회사 또는 조직이 이 개인 정보 보호 정책을
            계속 준수하도록 요구합니다. 그렇지 않으면 회사 또는 조직에
            요구합니다. 귀하에게 갱신하려면 승인 동의를 요청하십시오.
          </li>
        </ul>

        <p>4. 공시</p>
        <p>
          4.1 공시란 사회 또는 불특정 다수에게 정보를 공개하는 행위를 말합니다.
          불법계정, 부정계정에 대한 처벌 등 필요한 사항을 공개하고 당첨자/당첨자
          명단 발표 시 관련 정보를 둔감하게 표시하는 경우를 제외하고는 귀하의
          개인정보를 공개하지 않습니다. 합리적인 사유로 공시가 필요한 경우에는
          공시 전에 공개된 정보의 목적과 유형을 알려드립니다(개인적으로 민감한
          정보인 경우에는 관련된 민감한 정보의 내용도 알려드립니다). 귀하의
          승인과 동의를 얻은 후, 법률 및 규정에 달리 규정되거나 본 개인정보
          보호정책에서 달리 합의된 경우를 제외하고 공개됩니다.
        </p>
        <p>
          4.2 귀하의 개인정보 공개에 대해서는 공개 신청을 접수한 후 신속하고
          신중하게 적법성, 합리성, 적법성을 검토하고 공개 전후에 가장 엄격한
          개인정보 보안조치를 강구하겠습니다. 그것을 보호하기 위해.
        </p>

        <p>5. 타사 SDK에 대한 액세스</p>
        <p>
          귀하가 더 나은 ODAILY 서비스를 이용하고 즐길 수 있도록 하기 위해
          당사가 제공하는 일부 기능은 SDK 플러그인의 형태로 당사와 협력하는
          제3자 조직에서 제공되며 SDK 기술 서비스 제공자는 해당 권한을 얻습니다.
          그리고 정보. 당사는 앱에 통합된 SDK에 대해 보안 모니터링을 수행하여
          이러한 SDK가 기능 또는 서비스 구현에 필요한 개인 정보만 수집하도록 할
          것입니다. 여기를 클릭하시면 보실 수 있습니다
          <a href="?info-share-list" target="_blank">
            정보 공유 체크리스트.
          </a>
        </p>

        <p>
          6. 개인정보의 위탁처리, 외부제공, 이전, 공개에 대한 사전승인 및 동의의
          예외
        </p>
        <p>
          <b>
            관련 법령에 따라 다음과 같은 경우에는 귀하의 개인정보를 공유, 이전
            및 공개하는 데 귀하의 사전 승인 및 동의가 필요하지 않습니다.
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1) 법률 및 규정에 명시된 의무 이행과 관련하여</li>
          <li>2) 국가안보 및 국방안보와 직접적으로 관련된 것</li>
          <li>
            3) 공공 안전, 공중 보건 또는 주요 공익과 직접적으로 관련된 내용
          </li>
          <li>
            4) 범죄 수사, 기소, 재판 및 판결 집행과 같은 사법 또는 행정 법
            집행과 직접적으로 관련된 것;
          </li>
          <li>
            5) 본인 또는 기타 개인의 생명, 재산, 기타 주요 법적 권익을 보호하기
            위하여 본인의 동의를 얻는 것이 곤란한 경우
          </li>
          <li>6) 귀하가 직접 공개한 개인정보</li>
          <li>
            7) 법률 뉴스 보도, 정부 정보 공개 및 기타 채널과 같은 합법적으로
            공개된 정보로부터 개인 정보를 수집합니다.
          </li>
        </ul>
        <p>
          <b>
            관계법령에 따라 개인정보를 처리함에 있어 개인정보를 처리하는데
            필요한 기술적 조치 기타 필요한 조치를 하여 정보주체가 특정 개인을
            재식별하거나 복원할 수 없도록 하는 경우에는 위탁처리, 외부제공,
            이전, 그러한 처리된 데이터 、공개는 귀하에게 통지하고 귀하의 동의를
            얻을 필요가 없습니다.
          </b>
        </p>

        <h6>6. 귀하의 개인 정보를 저장하고 보호하는 방법</h6>
        <p>1. 보관</p>
        <p>
          1.1 저장 위치: 당사는 법률 및 규정에 따라 중화인민공화국에서 운영하는
          동안 수집 및 생성된 귀하의 개인 정보를 중화인민공화국에 저장합니다.
          현재 위 정보를 해외로 전송하지 않으며, 해외로 전송하는 경우 중국 관련
          법률 및 규제 정책을 엄격히 준수하고 관련 국가 규정을 따르거나 귀하의
          동의를 구합니다.
        </p>
        <p>
          1.2 보관기간 : 고객님의 개인정보는 법령에 따른 합리적이고 필요한 기간
          내에서 항상 보관할 것을 약속드립니다. 위 기간이 지나면 귀하의
          개인정보를 삭제하거나 익명화합니다.
        </p>
        <p>
          1.3 운영을 중단할 경우 적시에 귀하의 개인정보 수집을 중단하고,
          운영중지 안내를 개별적으로 전달 또는 공지사항으로 안내하며, 보유하고
          있는 귀하의 개인정보를 삭제 또는 익명화 처리합니다.
        </p>

        <p>2. 보호</p>
        <p>
          2.1 당사는 귀하의 개인정보의 보안을 매우 중요시하며, 귀하의 개인정보를
          보호하고 귀하가 제공한 개인정보가 무단으로 이용되거나 접근, 공개되지
          않도록 합리적인 보안조치(기술적 및 관리적 측면 포함)를 강구하도록
          노력하겠습니다. , 사용, 수정, 손상, 손실 또는 공개. 현재 우리는
          통과했습니다.<b>국가 정보 보안 수준 보호(3단계) 평가 및 제출.</b>
        </p>
        <p>
          2.2 암호화 기술, 익명화 처리 및 관련 업계 수준의 합리적이고 실행
          가능한 수단을 사용하여 귀하의 개인 정보를 보호하고 보안 보호
          메커니즘을 사용하여 귀하의 개인 정보에 대한 악의적인 공격을
          방지합니다.
        </p>
        <p>
          2.3 당사는 귀하의 개인정보의 보안을 확보하기 위해 특별한 보안 관리
          시스템 및 데이터 보안 프로세스를 수립합니다. 당사는 엄격한 데이터 사용
          및 액세스 정책을 채택하여 승인된 직원만 귀하의 개인 정보에 액세스할 수
          있도록 합니다.
        </p>
        <p>
          2.4
          <b>
            위에서 언급한 합리적이고 효과적인 조치를 취하고 관계법령에서
            요구하는 기준을 준수하였으나, 인터넷 산업의 기술적 한계와 악의적인
            수단으로 인해 보안을 강화하기 위해 최선을 다하더라도 정보의 안전성을
            항상 100% 보장하는 것은 불가능하며, 제공해주신 개인정보의 안전성
            확보에 최선을 다하겠습니다.
          </b>
        </p>
        <p>
          2.5
          <b>
            귀하는 당사 서비스에 액세스하기 위해 사용하는 시스템 및 통신
            네트워크에 당사가 통제할 수 없는 요인으로 인해 문제가 있을 수 있음을
            알고 이해합니다. 따라서 복잡한 비밀번호의 사용, 주기적인 비밀번호
            변경, 계정 비밀번호 및 이와 관련된 개인정보를 타인에게 공개하지 않는
            등 개인정보 보안을 위한 적극적인 조치를 취할 것을 강력히 권고합니다.
          </b>
        </p>
        <p>
          2.6 비상대응계획을 수립하고, 이용자 정보보안사고 발생 시 즉시
          비상대책을 발동하여, 이러한 보안사고의 영향과 피해가 확대되지 않도록
          노력하겠습니다. 이용자의 정보보안 사고(유출, 분실)가 발생하면 법령에서
          요구하는 바에 따라 보안사고의 기본적 상황과 영향, 현재까지 취하였거나
          취할 예정인 처리조치, 위험 감소에 대한 귀하 자신의 보호 및 조언,
          귀하를 위한 구제책. 이벤트 관련 상황을 푸시알림, 이메일, 서신, 문자
          메시지 및 기타 관련 양식을 통해 신속하게 안내해 드리며, 일일이
          알려드리기 어려운 경우에는 합리적이고 효과적인 방법으로 공지하도록
          하겠습니다. 동시에 관련 규제 당국의 요구 사항에 따라 사용자 정보 보안
          사고 처리를 보고할 것입니다.
        </p>
        <p>
          2.7 귀하가 이 플랫폼 및 ODAILY 서비스를 종료하고 다른 웹사이트, 서비스
          및 콘텐츠 리소스를 탐색하거나 사용하면 당사는 귀하가 이 플랫폼 및
          ODAILY 서비스 이외의 소프트웨어 및 웹사이트에 제출한 개인 정보를
          보호할 능력과 직접적인 의무가 없습니다. 웹 사이트가 이 플랫폼의 링크
          또는 안내를 기반으로 하는지 여부에 관계없이 로그인, 브라우징 또는 위의
          소프트웨어 사용.
        </p>

        <h6>7. 개인 정보 관리 방법</h6>
        <p>
          1. 귀하는 귀하의 개인정보를 조회하고 수정할 수 있는 권리가 있습니다.
        </p>
        <p>
          당사는 귀하의 개인정보에 대한 관심을 매우 중요하게 여기며 귀하의
          개인정보 조회 및 수정 권리를 보호하기 위한 다양한 기능을 제공하고
          있습니다.
        </p>
        <p>1.1 계정 정보 관리</p>
        <p>
          &quot;내&quot; → &quot;계정 관리&quot; 계정 비밀번호 수정, 연동된
          휴대폰 번호 수정, 제3자 계정 연동 등을 할 수 있는 기능입니다.
        </p>
        <p>
          &quot;내&quot; → &quot;계정 카드&quot; → &quot;수정&quot; 프로필
          수정에서 아바타, 닉네임, 성별, 생일, 프로필, 신원, 업종 등 계정 정보를
          보고 수정할 수 있습니다.
        </p>
        <p>1.2 댓글 및 기사 정보 관리</p>
        <p>
          &quot;내&quot; → &quot;개인 홈페이지&quot; 댓글이나 글 하나를 클릭하면
          콘텐츠 상세 페이지로 들어가며, 자신이 올린 댓글이나 글에 대해 알 수
          있습니다.
        </p>
        <p>1.3 검색 기록 관리</p>
        <p>
          &quot;내&quot; → &quot;찾아보기&quot;에서 검색 기록을 볼 수 있습니다.
        </p>
        <p>1.4 우려사항 기록 관리</p>
        <p>
          &quot;내&quot; → &quot;팔로우&quot; 모든 감시 목록을 관리하고
          팔로우하는 사용자, 작성자 및 주제를 보고 수정할 수 있습니다.
        </p>
        <p>
          &quot;내&quot; → &quot;즐겨찾기&quot; 즐겨찾는 기사, 오디오, 비디오
          등을 관리할 수 있습니다.
        </p>
        <p>
          승인 범위를 변경하면 그에 따라 이 플랫폼에서 제공하는 ODAILY 서비스를
          조정합니다. 그러나 귀하의 승인에 따라 이전에 처리된 개인 정보에는
          영향을 미치지 않음을 이해하십시오.
        </p>

        <p>2. 개인정보의 삭제</p>
        <p>
          귀하가 이 플랫폼에 게시한 정보를 삭제할 수 있도록 다음과 같은 방법을
          제공합니다.
        </p>
        <p>2.1 댓글 및 기사 정보 삭제</p>
        <p>
          &quot;내&quot;→&quot;개인 홈페이지&quot; 게시된 단일 댓글 또는 기사를
          클릭하여 콘텐츠 상세 페이지로 들어가고, 게시된 댓글 또는 기사를 길게
          눌러 언제든지 삭제할 수 있습니다. 그러나 게시된 댓글이나 기사를
          삭제하면 공개 표시만 취소되며 법률 및 규정에 따라 게시한 댓글이나
          기사는 백그라운드에서 계속 저장해야 합니다.
        </p>
        <p>2.2 인터넷 사용 기록 삭제</p>
        <p>
          &quot;마이&quot; → &quot;브라우징&quot;에서 방문 기록을 삭제할 수
          있습니다.
        </p>
        <p>2.3 팔로우 기록 삭제</p>
        <p>
          &quot;내&quot; → &quot;팔로우&quot; 팔로우하는 사용자, 작성자, 주제를
          삭제할 수 있습니다.
        </p>
        <p>
          &quot;내&quot; → &quot;즐겨찾기&quot; 즐겨찾는 기사, 오디오, 비디오
          등을 삭제할 수 있습니다.
        </p>
        <p>
          2.4 다음의 경우에는 귀하의 개인정보를 적극적으로 삭제합니다. 당사가
          삭제하지 않은 경우 om@odaily.email로 이메일을 보내 개인 정보 삭제를
          요청할 수 있습니다.
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            (1) 처리 목적이 달성되었거나, 달성할 수 없거나, 처리 목적을 달성하는
            데 더 이상 필요하지 않은 경우
          </li>
          <li>
            (2) 제품 또는 서비스 제공을 중단하거나 보유 기간이 만료된 경우
          </li>
          <li>(3) 귀하는 귀하의 동의를 철회합니다.</li>
          <li>
            (4) 법률, 행정 규정 또는 계약을 위반하여 개인 정보를 처리합니다.
          </li>
          <li>(5) 법률, 행정법규가 규정하는 기타 상황.</li>
        </ul>
        <p>
          정보를 삭제하면 그에 따라 이 플랫폼에서 제공하는 ODAILY 서비스가
          조정됩니다. 그러나 귀하의 승인에 따라 이전에 처리된 개인 정보에는
          영향을 미치지 않음을 이해하십시오.
        </p>

        <p>3. 개인정보 사본 취득</p>
        <p>
          귀하의 개인 정보 사본이 필요한 경우 om@odaily.email로 이메일을 보낼 수
          있으며 귀하의 서면 요청에 따라 수집한 귀하의 개인 정보를 제공할
          것입니다.
        </p>

        <p>4. 귀하는 개인정보에 대한 동의를 철회할 권리가 있습니다.</p>
        <p>
          장치 자체의 운영 체제에서 GPS 위치, 카메라, 마이크, 사진 앨범 권한을
          해제하거나 동의 범위를 변경하거나 권한을 철회할 수 있습니다. 승인을
          종료한 후에는 이러한 권한과 관련된 정보를 더 이상 수집하지 않습니다. ,
          귀하의 해당 개인 정보 수집을 거부합니다. 승인 철회 후에는 승인 철회에
          해당하는 오데일리 서비스를 계속 제공할 수 없음을 이해하셔야 합니다.
          <b>
            그러나 귀하의 승인 철회 결정은 귀하의 승인에 기반한 이전의 개인 정보
            처리에 영향을 미치지 않습니다.
          </b>
        </p>

        <p>5. 개인정보 이전 권리</p>
        <p>
          귀하의 개인 정보를 다른 플랫폼, 회사 또는 조직으로 이전해야 하는 경우
          본 개인 정보 보호 정책의 11조에 제공된 방법을 통해 당사에 연락할 수
          있습니다. 당사는 귀하의 요청을 검토하고, 법률에서 규정한 개인정보
          이전의 전제 조건에 따라 해당 개인정보를 이전할 수 있는 방법을 귀하에게
          제공할 것입니다.
        </p>

        <p>6. 오데일리 계정 해지</p>
        <p>
          오데일리 계정을 해지해야 하는 경우 &quot;마이&quot; → &quot;계정
          관리&quot; → &quot;계정 해지&quot;에서 신청을 제출해주세요. 계정을
          해지한 후에는 오데일리 서비스 제공을 중단하고 관련 법률 및 규정의 요구
          사항에 따라 익명화 및 기타 방법을 통해 귀하의 정보를 보호합니다.
        </p>

        <p>7. 마케팅 정보 및 타겟 푸시의 독립적인 관리 및 제어</p>
        <p>
          7.1 메시지 푸시 - 앱 페이지에서 메시지 알림 수신을 원하지 않는 경우 앱
          최초 설치 시 메시지 알림 수신을 거부하거나 &quot;내&quot; → &quot;푸시
          관리&quot;에서 해당 알림 기능을 해제할 수 있습니다. .
        </p>
        <p>
          7.2 판촉/재방문——당사의 판촉 문자 메시지나 전화 재방문을 원하지 않는
          경우 정보 내 해당 수신 거부 기능을 통해 수신을 거부하거나 전화 재방문
          시 명시적으로 거부할 수 있습니다.
        </p>

        <p>8. 귀하의 요구에 대한 응답</p>
        <p>
          다음 상황에서는 법률 및 규정의 요구 사항에 따라 귀하의 요청에 응답할
          수 없습니다.
        </p>
        <ul className={styles.privacy_ul}>
          <li>1) 법률 및 규정에 명시된 의무 이행과 관련하여</li>
          <li>2) 국가안보 및 국방안보와 직접적으로 관련된 것</li>
          <li>3) 공공 안전, 공중 보건 및 주요 공익과 직접적으로 관련된 것;</li>
          <li>4) 범죄 수사, 기소, 재판 및 판결 집행과 직접 관련된 것</li>
          <li>
            5) 개인정보주체가 주관적 악의가 있거나 권리를 남용한다는 충분한
            증거가 있을 것
          </li>
          <li>
            6) 개인정보주체 또는 다른 개인의 생명, 재산 등 주요한 법적 권익을
            보호하기 위하여 해당 개인의 승낙 및 동의를 받는 것이 곤란한 경우
          </li>
          <li>
            7) 귀하의 요청에 응답하는 것은 귀하 또는 다른 개인 또는 조직의
            정당한 권익에 심각한 피해를 줄 수 있습니다.
          </li>
          <li>8) 영업상의 비밀에 관한 것.</li>
        </ul>

        <h6>8. 미성년자의 개인정보 보호</h6>
        <p>
          1. 미성년자의 개인정보 보호를 매우 중요하게 생각합니다. 관련 법령에
          따라 만 18세 미만의 미성년자는 오데일리 서비스를 이용하기 전에 부모
          또는 다른 보호자의 감독과 지도하에 본 개인정보취급방침을 읽고 동의해야
          합니다.
        </p>
        <p>
          2. 당사는 미성년자의 개인정보를 해당 국가의 관련법령에 따라 보호하며,
          법률이 허용하거나 부모 또는 기타 보호자가 명시적으로 동의하거나
          미성년자의 개인정보를 수집, 이용, 저장, 공유, 이전 또는 보호
          미성년자의 개인정보를 공개하며, 확인 가능한 보호자의 사전 동의 없이
          미성년자의 개인정보가 수집된 사실이 확인될 경우 해당 정보를 최대한
          빨리 삭제하도록 노력하겠습니다.
        </p>
        <p>
          3. 귀하가 미성년후견인의 경우, 귀하의 후견인 미성년자의 개인정보에
          대해 궁금한 사항이 있을 경우 본 개인정보 처리방침 제11조에 제시된
          방법으로 문의하시기 바랍니다.
        </p>

        <h6>9. 개인 정보 보호 정책에 대한 개정 및 통지</h6>
        <p>
          1. 더 나은 서비스를 제공하기 위해 오데일리 서비스는 수시로 변경되며,
          이에 따라 본 개인정보취급방침도 변경됩니다.
        </p>
        <p>
          2. 귀하의 명시적인 동의 없이는 본 개인 정보 보호 정책에 따른 귀하의
          권리를 축소하지 않습니다. 본 개인정보취급방침의 변경사항을 최신 정보로
          안내할 수 있도록 앱 클라이언트 푸시알림, 팝업창 등 합리적인 방법으로
          알려드립니다. 본 개인정보처리방침의 최신 내용은 &quot;마이&quot; →
          &quot;서비스 더보기&quot; → &quot;개인정보처리방침&quot;을 통하여
          확인하실 수 있습니다.
        </p>

        <p>
          3. 주요 변경 사항의 경우 특정 상황에 따라 본 개인 정보 보호 정책의
          특정 변경 사항을 설명하는 더 눈에 띄는 공지를 제공합니다. 중요한 변경
          사항은 다음을 포함하지만 이에 국한되지 않습니다.
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) 서비스 모델이 크게 변경되었습니다. 개인정보의 처리목적, 처리하는
            개인정보의 종류, 개인정보의 이용방법 등
          </li>
          <li>
            2) 소유구조, 조직구조 등에서 상당한 변화를 겪었습니다. 사업조정,
            도산합병 등으로 인한 소유자 변경 등
          </li>
          <li>3) 개인정보의 공유, 이전 또는 공개의 주된 대상 변경</li>
          <li>
            4) 개인정보 처리에 관한 귀하의 권리와 그 행사방법에 중대한 변경이
            있습니다.
          </li>
          <li>5) 개인정보보호 담당부서, 연락방법 및 불만처리 채널의 변경</li>
          <li>
            6) 개인정보보호 영향평가서에서 위험성이 높은 것으로 나타난 경우
          </li>
        </ul>

        <p>
          4. 개정된 개인정보취급방침에 동의하지 않는 경우, 귀하는 오데일리
          서비스 이용을 즉시 중단할 권리가 있으며 이를 철회해야 합니다. 단,
          개정된 개인정보취급방침에 동의하지 않으실 경우 관련 오데일리 서비스 및
          일부 기능을 정상적으로 이용하실 수 없거나 당사가 기대하는 서비스
          효과를 얻지 못할 수 있음을 양해해 주시기 바랍니다.
        </p>

        <h6>10. 사망한 사용자의 개인 정보 보호</h6>
        <p>
          2021년 11월 1일부터 &quot;개인정보 보호법&quot; 관련 조항에 의거
          고인에 대한 개인정보 보호 체계를 수립하겠습니다. 오데일리
          이용자(자연인에 한함)가 사망한 후, 그의 가까운 친족이 본
          개인정보취급방침 제11조에 기재된 연락처를 통하여 사망한 이용자의 관련
          개인정보를 열람, 복사, 정정, 삭제할 수 있습니다. 정당한 이익 및 기타
          권리, 단 사망한 사용자가 평생 동안 다른 계약을 맺은 경우는 예외입니다.
        </p>

        <h6>1. 당신은 어떻게 저희에게 연락합니까</h6>
        <p>
          개인 정보 보호에 대한 불만, 제안 또는 질문이 있거나 이 개인 정보 보호
          정책에 대해 질문이 있고 설명이 필요한 경우 다음 방법으로 문의할 수
          있으며 관련 문제를 최대한 빨리 검토하겠습니다. 가능하고 사용자 식별 후
          영업일 기준 15일 이내에 답장을 확인하십시오.
        </p>
        <p>1. om@odaily.email로 개인정보 보호담당자에게 이메일 보내기</p>
        <p>
          2. &quot;My&quot; → &quot;Feedback&quot; → &quot;Other
          Questions&quot;를 통해 문의하실 수 있습니다.
        </p>

        <h6>12. 분쟁 해결</h6>
        <p>
          본 개인 정보 보호 정책과 귀하의 개인 정보 처리로 인해 발생하는 모든
          분쟁에 대해 귀하는 베이징 조양구 인민 법원에 소송을 제기할 수
          있습니다. 당사의 개인정보 처리행위가 귀하의 정당한 권익을 침해했다고
          생각되시면 관련 정부부처에 신고하실 수도 있습니다.
        </p>
      </div>
    );
  }
}

export default PrivacyKo;
