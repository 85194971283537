import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { timesAgo } from '../../../common/scripts/time';

export class NewsflashWidget3 extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired
  };
  state = {
    showList: []
  };
  constructor(props) {
    super(props);
    this.showContent = this.showContent.bind(this);
  }
  showContent(id) {
    const { showList } = this.state;
    const isShow = showList.indexOf(id);

    if (isShow !== -1) {
      this.setState({
        showList: showList.filter((_, i) => i !== isShow)
      });
    } else {
      this.setState({
        showList: [...showList, id]
      });
    }
  }
  renderTimesAgo(item) {
    return timesAgo(item.published_at);
  }
  renderList() {
    const arry = this.props.list.slice(0, 3);
    return arry.map(item => {
      const showStyles =
        this.state.showList.indexOf(item.id) !== -1 ? styles.show : '';
      const markup = () => {
        return {
          __html: item.highlight.title[0]
        };
      };
      return (
        <div key={item.id} className={styles.item}>
          <i className={styles['icon-dot']} />
          <i className={styles['icon-dot-inner']} />
          <h4
            className={styles.title}
            onClick={() => this.showContent(item.id)}
            dangerouslySetInnerHTML={markup()}
          />
          <div className={`${styles.content} ${showStyles}`}>
            <p className={styles.description}>{item.description}</p>
          </div>
          <div className={styles.timeBox}>
            <p className={styles.time}>{this.renderTimesAgo(item)}</p>
          </div>
        </div>
      );
    });
  }
  render() {
    return (
      <div className={styles.container}>
        <div className={styles['content']}>{this.renderList()}</div>
      </div>
    );
  }
}
