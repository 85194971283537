import React, { Component } from 'react';
import styles from '../../index.scss';

export class InfoShareEn extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>Information Sharing Checklist</h5>
        <p>
          In order to give you a clearer understanding of our sharing of
          personal information, the following list has been formulated:
        </p>
        <h6>1. Third-party SDK list</h6>
        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>share</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>WeChat</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>
                Provide users with a third-party login function to help users
                share content to WeChat
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Shenzhen Tencent Computer System Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>share</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>Sina</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>
                Provide users with a third-party login function to help users
                share content to Sina
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Beijing Weimeng Chuangke Network Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>400-096-0960</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://weibo.com/signup/v5/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://weibo.com/signup/v5/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>to push</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>push</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Push relevant information content</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Daily Interactive Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>support@getui.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://docs.getui.com/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.getui.com/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>to push</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>aurora</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Push relevant information content</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Shenzhen Hexun Huagu Information Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>sales@jiguang.cn</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.jiguang.cn/license/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.jiguang.cn/license/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>to push</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>Huawei</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Push relevant information content</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Huawei Software Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>to push</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>Millet</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Push relevant information content</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Xiaomi Mobile Software Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.mi.com/support"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.mi.com/support
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://dev.mi.com/console/doc/detail?pId=1822"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://dev.mi.com/console/doc/detail?pId=1822
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>to push</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>OPPO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Push relevant information content</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Guangdong Huantai Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>
                <a
                  href="https://brand.heytap.com/privacy-feedback.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://brand.heytap.com/privacy-feedback.html
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://open.oppomobile.com/wiki/doc#id=10288"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://open.oppomobile.com/wiki/doc#id=10288
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>to push</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Push relevant information content</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Vivo Mobile Communication Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>privacy@vivo.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.vivo.com.cn/about-vivo/privacy-policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>position</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>Gold</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Provide users with local information content</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>
                Device information, and enable GPS information under the
                location permission
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Gaode Software Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>Privacy.Amap@service.autonavi.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://lbs.amap.com/pages/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://lbs.amap.com/pages/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>One-click login</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>China Mobile</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Provide users with verification code-free login services</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device information, local number</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>China Mobile Communications Group Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>10086</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="http://dev.10086.cn/docInside?contentId=10000009826805"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  http://dev.10086.cn/docInside?contentId=10000009826805
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>share</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Help users share ODAILY content to QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Shenzhen Tencent Computer System Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>Statistics</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>Allies</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Data Statistical Analysis</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Youmeng Tongxin (Beijing) Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>Umeng_Legal@service.umeng.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.umeng.com/page/policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.umeng.com/page/policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>Statistics</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>Huawei</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>Data Statistical Analysis</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Huawei Software Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>collect logs</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>bugly</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>
                Collect App crash information, so that we can locate the problem
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Shenzhen Tencent Computer System Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>Function type</p>
            </td>
            <td>
              <p>One-click installation of App</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK name</p>
            </td>
            <td>
              <p>openinstall</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>purpose of usage</p>
            </td>
            <td>
              <p>
                Help users realize fast jump and one-click download and install
                App
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>personal information field</p>
            </td>
            <td>
              <p>Device Information</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>processing method</p>
            </td>
            <td>
              <p>
                Adopt de-identification, encryption and other methods for
                transmission and processing
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>sharing method</p>
            </td>
            <td>
              <p>SDK native collection</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>subject name</p>
            </td>
            <td>
              <p>Shenzhen Fenmiao Network Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact information</p>
            </td>
            <td>
              <p>cooperation@openinstall.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Privacy Policy</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.openinstall.io/privacy.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.openinstall.io/privacy.html
                </a>
              </p>
            </td>
          </tr>
        </table>

        <h6>2. List of third-party cooperative institutions</h6>
        <p>
          We have not shared your personal information with third-party partners
          for the time being. If the above situation occurs, we will update this
          list and obtain your authorization and consent.
        </p>

        <h6>3. List of affiliated companies</h6>
        <p>
          We have not shared your personal information with affiliated companies
          for the time being. If the above situation occurs, we will update this
          list and obtain your authorization and consent.
        </p>
      </div>
    );
  }
}

export default InfoShareEn;
