import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import { getFriendLink, getAbout_us } from '../../common/api/friendLink';
import wechatShare from '../../common/scripts/wechat';
import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';
import {
  ABOUT_LIST,
  aboutJoin,
  aboutUsData,
  corporate,
  disclaimerData,
  pageLocales
} from './lang';

import PrivacyZhcn from './components/privacy/PrivacyZhcn';
import PrivacyEn from './components/privacy/PrivacyEn';
import PrivacyZhtw from './components/privacy/PrivacyZhtw';
import PrivacyJa from './components/privacy/PrivacyJa';
import PrivacyKo from './components/privacy/PrivacyKo';
import PrivacyTh from './components/privacy/PrivacyTh';
import PrivacyVi from './components/privacy/PrivacyVi';
import AgreementZhcn from './components/agreement/AgreementZhcn';
import AgreementZhtw from './components/agreement/AgreementZhtw';
import AgreementEn from './components/agreement/AgreementEn';
import AgreementVi from './components/agreement/AgreementVi';
import AgreementKo from './components/agreement/AgreementKo';
import AgreementJa from './components/agreement/AgreementJa';
import AgreementTh from './components/agreement/AgreementTh';
import ApplicationZhcn from './components/application/ApplicationZhcn';
import ApplicationZhtw from './components/application/ApplicationZhtw';
import ApplicationEn from './components/application/ApplicationEn';
import ApplicationVi from './components/application/ApplicationVi';
import ApplicationKo from './components/application/ApplicationKo';
import ApplicationJa from './components/application/ApplicationJa';
import ApplicationTh from './components/application/ApplicationTh';
import InfoShareZhcn from './components/infoShare/InfoShareZhcn';
import InfoShareZhtw from './components/infoShare/InfoShareZhtw';
import InfoShareEn from './components/infoShare/InfoShareEn';
import InfoShareVi from './components/infoShare/InfoShareVi';
import InfoShareTh from './components/infoShare/InfoShareTh';
import InfoShareKo from './components/infoShare/InfoShareKo';
import InfoShareJa from './components/infoShare/InfoShareJa';

export class About extends React.Component {
  static propTypes = {
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.tabHandler = this.tabHandler.bind(this);
    this.state = {
      currentTab: '?us',
      friendLinks: [],
      friendItems: [],
      contactData: []
    };
  }
  tabHandler(currentTab) {
    this.setState({
      currentTab
    });
  }
  componentDidMount() {
    const { lang } = this.props.match.params;
    const hash = window.location.search;
    if (hash) {
      this.setState({
        currentTab: hash
      });
    }
    getFriendLink().then(data => {
      if (data.data && data.data.length) {
        this.setState({
          friendLinks: data.data
        });
      }
    });
    getAbout_us(lang).then(data => {
      if (data.data && data.data.items) {
        this.setState({
          contactData: data.data.items
        });
      }
    });
    wechatShare({ title: '关于我们' });
  }

  renderTabBar(lang) {
    const listNode = ABOUT_LIST.map((item, key) => {
      const activeStyles =
        item.hash === this.state.currentTab ? styles.active : '';
      return (
        <li
          key={key}
          onClick={() => this.tabHandler(item.hash)}
          className={`${styles.tabItem} ${activeStyles}`}
        >
          {locales(lang, item.title)}
        </li>
      );
    });
    return <ul className={styles.tabBar}>{listNode}</ul>;
  }

  renderUs(lang) {
    const data = pageLocales(lang, aboutUsData);
    return (
      <div className={styles.content}>
        <h5> {locales(lang, 'aboutUs')}</h5>
        {data.map((item, key) => (
          <article key={key}>{item}</article>
        ))}
      </div>
    );
  }

  renderDisclaimer(lang) {
    const data = pageLocales(lang, disclaimerData);
    return (
      <div className={styles.content}>
        <h5>{locales(lang, 'disclaimer')}</h5>
        {data.map((item, key) => (
          <article key={key}>{item}</article>
        ))}
      </div>
    );
  }

  renderFriendItem() {
    return (
      <div className={styles['link-group']}>
        {this.state.friendLinks.map((item, key) => {
          return (
            <a
              className={`${styles['link-item']}`}
              key={key}
              title="Odaily"
              href={item.link_url}
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              {item.link_name}
            </a>
          );
        })}
      </div>
    );
  }

  renderFriend(lang) {
    return (
      <div className={styles.content}>
        <h5> {locales(lang, 'links')}</h5>
        {this.renderFriendItem()}
      </div>
    );
  }

  renderContact(lang) {
    const { contactData } = this.state;
    const compName = pageLocales(lang, corporate);
    return (
      <div className={styles.content}>
        <h5> {locales(lang, 'contactUs')}</h5>
        <article>{compName}</article>
        {contactData.map((item, index) => {
          return (
            <article key={`contact${index}`}>
              {item.about_us_key}:{item.about_us_value}
            </article>
          );
        })}
      </div>
    );
  }

  renderJoin(lang) {
    const langtmp = lang || 'zh';
    return aboutJoin[langtmp](locales(lang, 'joinUs'), styles);
  }

  renderPrivacy(lang) {
    const item = locales(lang, 'privacyPolicy');
    switch (lang) {
      case 'en':
        return <PrivacyEn title={item} />;
      case 'zhcn':
        return <PrivacyZhcn title={item} />;
      case 'zhtw':
        return <PrivacyZhtw title={item} />;
      case 'ja':
        return <PrivacyJa title={item} />;
      case 'ko':
        return <PrivacyKo title={item} />;
      case 'th':
        return <PrivacyTh title={item} />;
      case 'vi':
        return <PrivacyVi title={item} />;
      default:
        return <PrivacyZhcn title={item} />;
    }
  }

  renderAgreement(lang) {
    switch (lang) {
      case 'en':
        return <AgreementEn />;
      case 'zhcn':
        return <AgreementZhcn />;
      case 'zhtw':
        return <AgreementZhtw />;
      case 'ja':
        return <AgreementJa />;
      case 'ko':
        return <AgreementKo />;
      case 'th':
        return <AgreementTh />;
      case 'vi':
        return <AgreementVi />;
      default:
        return <AgreementZhcn />;
    }
  }

  renderApplication(lang) {
    switch (lang) {
      case 'en':
        return <ApplicationEn />;
      case 'zhcn':
        return <ApplicationZhcn />;
      case 'zhtw':
        return <ApplicationZhtw />;
      case 'ja':
        return <ApplicationJa />;
      case 'ko':
        return <ApplicationKo />;
      case 'th':
        return <ApplicationTh />;
      case 'vi':
        return <ApplicationVi />;
      default:
        return <ApplicationZhcn />;
    }
  }

  renderInfoShare(lang) {
    switch (lang) {
      case 'en':
        return <InfoShareEn />;
      case 'zhcn':
        return <InfoShareZhcn />;
      case 'zhtw':
        return <InfoShareZhtw />;
      case 'ja':
        return <InfoShareJa />;
      case 'ko':
        return <InfoShareKo />;
      case 'th':
        return <InfoShareTh />;
      case 'vi':
        return <InfoShareVi />;
      default:
        return <InfoShareZhcn />;
    }
  }
  renderContent(lang) {
    const { currentTab } = this.state;
    switch (currentTab) {
      case '?us':
        return this.renderUs(lang);
      case '?contact':
        return this.renderContact(lang);
      case '?join':
        return this.renderJoin(lang);
      case '?privacy':
        return this.renderPrivacy(lang);
      case '?agreement':
        return this.renderAgreement(lang);
      case '?friend':
        return this.renderFriend(lang);
      case '?disclaimer':
        return this.renderDisclaimer(lang);
      case '?application-authority':
        return this.renderApplication(lang);
      case '?info-share-list':
        return this.renderInfoShare(lang);
      default:
        break;
    }
  }
  render() {
    const { lang } = this.props.match.params;
    let helmetData = getHelmet({ title: locales(lang, 'aboutUs') });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.cover} />
          <div className={styles.inner}>
            {/* 左侧导航 */}
            {this.renderTabBar(lang)}
            {/* 内容 */}
            {this.renderContent(lang)}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(About);
