import React, { Component } from 'react';
import styles from '../../index.scss';

export class AgreementVi extends Component {
  render() {
    return (
      <div className={`${styles.content} ${styles.agreement}`}>
        <h5>Thỏa thuận dịch vụ người dùng phương tiện ODAILY</h5>
        <h6>【Phần đầu tiên và giới thiệu】</h6>
        <p>
          Chào mừng đến với ODAILY! Để sử dụng dịch vụ ODAILY (sau đây gọi là
          &quot;dịch vụ này&quot;), bạn nên đọc và tuân theo &quot;Thỏa thuận
          dịch vụ người dùng phương tiện của ODAILY&quot; (sau đây gọi là
          &quot;thỏa thuận này&quot;). Vui lòng đảm bảo đọc kỹ và hiểu đầy đủ
          nội dung của từng điều khoản, đặc biệt là các điều khoản về miễn trừ
          hoặc giới hạn trách nhiệm, cũng như các thỏa thuận riêng về việc mở
          hoặc sử dụng một dịch vụ nhất định rồi lựa chọn chấp nhận hay không.
          Các hạn chế và tuyên bố từ chối trách nhiệm có thể nhắc nhở bạn chú ý
          ở dạng in đậm. Trừ khi bạn đã đọc và chấp nhận tất cả các điều khoản
          của thỏa thuận này và các thỏa thuận liên quan, bạn không có quyền sử
          dụng dịch vụ. Việc đăng nhập, xem, đăng thông tin và các hành vi khác
          của bạn trên dịch vụ này sẽ được coi là bạn đã đọc và đồng ý bị ràng
          buộc bởi thỏa thuận này. Nếu bạn dưới 18 tuổi, vui lòng đọc thỏa thuận
          này và các thỏa thuận nêu trên khác cùng với người giám hộ hợp pháp
          của bạn và đặc biệt chú ý đến các điều khoản sử dụng dành cho trẻ vị
          thành niên.
        </p>
        <h6>1. 【Phạm vi thỏa thuận】</h6>
        <p>
          1.1 Thỏa thuận này là thỏa thuận giữa bạn và ODAILY liên quan đến việc
          bạn sử dụng ODAILY. &quot;ODAILY&quot; đề cập đến các chi nhánh hoạt
          động có thể có của Hainan Moti Culture Media Co., Ltd. và các dịch vụ
          liên quan của nó. &quot;Người dùng nền tảng&quot; đề cập đến một cá
          nhân hoặc tổ chức đăng ký, đăng nhập và sử dụng tài khoản ODAILY và
          được gọi nhiều hơn là &quot;bạn&quot; trong thỏa thuận này.
          &quot;Người dùng khác&quot; đề cập đến người dùng liên quan đến dịch
          vụ, bao gồm người đăng ký và người dùng khác không phải là người dùng
          nền tảng.
        </p>
        <p>
          1.2 Dịch vụ này bao gồm nhưng không giới hạn ở trang web chính thức
          của ODAILY www.odaily.news, ODAILYAPP, Odaily APP, tài khoản công cộng
          ODAILY WeChat Odaily (WeChat ID: O-daily), tài khoản dịch vụ WeChat
          Odaily, nền tảng mở ODAILYAPP, ODAILYAPP Weibo, v.v. .Nền tảng kinh
          doanh là một kênh truyền thông, ODAILY cung cấp dịch vụ công bố thông
          tin, quảng bá thương hiệu cho các cá nhân, doanh nghiệp. Sau khi người
          dùng nền tảng đăng ký tài khoản ODAILY, anh ta có thể thực hiện các
          hoạt động như tiết lộ thông tin, quảng bá thương hiệu và quản lý nhận
          xét của người dùng đăng ký thông qua ODAILY.
        </p>
        <h6>2.【Đăng ký tài khoản và quyền tài khoản】</h6>
        <p>
          Bạn cần đăng ký tài khoản ODAILY trước khi sử dụng dịch vụ này và tài
          khoản ODAILY được tạo bằng cách liên kết số điện thoại di động và các
          thông tin khác.
        </p>
        <p>
          2.2 ODAILY sẽ xem xét thông tin tài khoản đã đăng ký của bạn. Bạn phải
          tự chịu trách nhiệm về tính xác thực, hợp pháp, chính xác và hợp lệ
          của thông tin tài khoản mà bạn cung cấp. Nếu gây thiệt hại cho ODAILY
          hoặc bên thứ ba, bạn phải bồi thường theo quy định của pháp luật.
        </p>
        <p>
          2.3 ODAILY có quyền sửa đổi tin tức bạn xuất bản thông qua ODAILY (bao
          gồm nhưng không giới hạn ở văn bản, hình ảnh, video, biểu đồ, v.v.)
          Odaily APP, Tài khoản chính thức của ODAILY WeChat Odaily (WeChat ID:
          O-daily), Dịch vụ WeChat của Odaily Tài khoản, Nền tảng mở ODAILYAPP,
          ODAILYAPP Weibo và các nền tảng kinh doanh ODAILY khác được đưa ra
          công chúng. ODAILY và các chi nhánh cũng như đối tác của mình có quyền
          sử dụng toàn cầu, vĩnh viễn, không thể thu hồi, miễn phí và không độc
          quyền đối với bất kỳ nội dung nào bạn tải lên và xuất bản thông qua
          ODAILY.
        </p>
        <p>
          2.4 Bằng cách sử dụng dịch vụ này, bạn có thể có được các quyền sau:
        </p>
        <p>
          2.4.1 Quản lý tin nhắn và tương tác với người đăng ký thông qua
          ODAILY; 2.4.2 Lấy số lượng người đăng ký và số lần nhấp vào tin nhắn
          của tài khoản ODAILY của bạn; 2.4.3 Với sự đồng ý của ODAILY, bạn có
          quyền thực hiện tin nhắn thông qua ODAILY Các hoạt động thương mại như
          đăng thông điệp thương hiệu và/hoặc đăng ký trả phí. Bạn cũng đồng ý
          rằng ODAILY có quyền tính phí dịch vụ kỹ thuật cho các hoạt động
          thương mại của bạn thông qua ODAILY.
        </p>
        <h6>3. [Bảo vệ thông tin cá nhân của người dùng nền tảng]</h6>
        <p>
          3.1 Trong quá trình đăng ký sử dụng dịch vụ này, bạn có thể cần cung
          cấp một số thông tin cần thiết, vui lòng đảm bảo các thông tin này là
          trung thực, chính xác, hợp pháp, hợp lệ và chú ý cập nhật kịp thời để
          ODAILY có thể trợ giúp kịp thời và hiệu quả cho bạn, hoặc phục vụ bạn
          tốt hơn Bạn cung cấp dịch vụ. Theo luật, quy định và chính sách có
          liên quan, vui lòng điền thông tin nhận dạng thực của bạn. Nếu thông
          tin bạn điền không đầy đủ hoặc không chính xác, bạn có thể không sử
          dụng được dịch vụ này hoặc bị hạn chế trong quá trình sử dụng.
        </p>
        <p>
          3.2 ODAILY cam kết bảo vệ thông tin cá nhân cùng với người dùng nền
          tảng và bảo vệ thông tin cá nhân của người dùng nền tảng là nguyên tắc
          cơ bản của ODAILY. Nếu không có sự đồng ý của bạn, ODAILY sẽ không
          tiết lộ thông tin cá nhân của bạn cho bất kỳ công ty, tổ chức hoặc cá
          nhân nào ngoài ODAILY, trừ khi pháp luật và các quy định có quy định
          khác.
        </p>
        <p>
          3.3 Bạn phải hoàn toàn tôn trọng thông tin cá nhân của bất kỳ ai, bao
          gồm nhưng không giới hạn ở những người dùng khác mà bạn biết, nhận
          hoặc có quyền truy cập thông qua dịch vụ này và bạn không nên thu
          thập, sao chép, lưu trữ, phổ biến hoặc sử dụng thông tin cá nhân của
          Người dùng khác, nếu không, hậu quả phát sinh sẽ do bạn gánh chịu.
        </p>
        <h6>4. [Thông số kỹ thuật nội dung nền tảng]</h6>
        <p>
          4.1 Nội dung nền tảng được đề cập trong bài viết này đề cập đến mọi
          nội dung do bạn tạo, sao chép, xuất bản và phổ biến trong quá trình sử
          dụng dịch vụ này, bao gồm nhưng không giới hạn ở hình đại diện, tên,
          mô tả người dùng nền tảng và thông tin đăng ký khác của tài khoản
          ODAILY, hoặc văn bản , giọng nói, hình ảnh, Gửi, trả lời hoặc tin nhắn
          tự động trả lời và các trang liên kết có liên quan, cũng như nội dung
          khác được tạo bằng cách sử dụng tài khoản ODAILY hoặc dịch vụ ODAILY,
          chẳng hạn như video, hình ảnh và văn bản.
        </p>
        <p>
          4.2 Khi sử dụng dịch vụ này, bạn phải tuân thủ các yêu cầu của
          &quot;bảy điểm mấu chốt&quot; như luật pháp và các quy định, hệ thống
          xã hội chủ nghĩa, lợi ích quốc gia, quyền và lợi ích hợp pháp của công
          dân, trật tự công cộng xã hội, đạo đức và tính xác thực của thông tin.
        </p>
        <p>
          Bạn không được sử dụng dịch vụ này để sản xuất, sao chép, xuất bản
          hoặc phổ biến nội dung bị cấm theo các luật, quy định và chính sách
          sau:
        </p>
        <p>
          (1) Đi ngược lại các nguyên tắc cơ bản do Hiến pháp quy định; (2) Gây
          nguy hiểm cho an ninh quốc gia, làm lộ bí mật nhà nước, lật đổ quyền
          lực nhà nước, phá hoại đoàn kết dân tộc; (3) Làm tổn hại đến danh dự
          và lợi ích quốc gia; (4) Kích động hận thù dân tộc, phân biệt đối xử
          dân tộc , phá hoại khối đại đoàn kết dân tộc; (5) Phá hoại chính sách
          tôn giáo của quốc gia, cổ vũ các tà giáo, mê tín phong kiến; (6) Tung
          tin đồn thất thiệt, gây rối trật tự xã hội và phá hoại ổn định xã hội;
          (7) Truyền bá tục tĩu, khiêu dâm, cờ bạc, bạo lực, giết người (8) )
          Xúc phạm hoặc vu khống người khác, xâm phạm quyền và lợi ích hợp pháp
          của người khác; (9) Kích động tụ tập, lập hội, diễu hành, biểu tình,
          tụ tập trái pháp luật để gây rối trật tự xã hội; {10} Hoạt động nhân
          danh tổ chức dân sự trái pháp luật; (11) Thông tin chứa nội dung khác
          bị cấm theo luật, quy định và chính sách.
        </p>
        <p>
          4.3 Bạn hiểu và đồng ý rằng ODAILY luôn cam kết cung cấp cho người
          dùng nền tảng một môi trường mạng văn minh, lành mạnh, chuẩn hóa và có
          trật tự. hoạt động bình thường của ODAILY hoặc vi phạm pháp luật của
          người dùng khác hoặc bên thứ ba.
        </p>
        <p>
          (1) Chứa bất kỳ nội dung tình dục hoặc khêu gợi tình dục nào; (2)
          Quảng cáo, quấy rối, spam; (3) Liên quan đến quyền riêng tư, thông tin
          cá nhân hoặc dữ liệu của người khác; (4) Xâm phạm quyền danh dự, quyền
          hình ảnh, quyền sở hữu trí tuệ của người khác, thương mại (5) Chứa
          thông tin khác cản trở hoạt động bình thường của ODAILY và vi phạm
          quyền và lợi ích hợp pháp của người dùng hoặc bên thứ ba khác.
        </p>
        <h6>5. 【Quy tắc sử dụng nền tảng】</h6>
        <p>
          5.1 Việc sử dụng nền tảng được đề cập trong bài viết này đề cập đến
          bất kỳ hành vi nào bạn thực hiện bằng cách sử dụng dịch vụ này, bao
          gồm nhưng không giới hạn ở việc đăng ký và đăng nhập, vận hành và
          quảng cáo tài khoản cũng như các hành vi khác được thực hiện bằng cách
          sử dụng tài khoản ODAILY hoặc các dịch vụ ODAILY của bạn.
        </p>
        <p>
          5.2 Bạn không được sử dụng tài khoản ODAILY hoặc các dịch vụ ODAILY
          của mình để thực hiện các hành vi sau:
        </p>
        <p>
          (1) Gửi hoặc công bố thông tin sai lệch hoặc mạo danh hoặc sử dụng tên
          của người khác; (2) Ép buộc hoặc xúi giục người dùng khác chú ý, nhấp
          vào các trang được liên kết hoặc chia sẻ thông tin; (3) Bịa đặt và che
          giấu sự thật để đánh lừa hoặc lừa dối người khác; (4) Xâm phạm các
          quyền hợp pháp khác như quyền danh tiếng, quyền hình ảnh, quyền sở hữu
          trí tuệ, bí mật thương mại, v.v.; (5) Sử dụng tài khoản của ODAILY để
          quảng bá cho bên thứ ba mà không được sự cho phép bằng văn bản của
          ODAILY (bao gồm nhưng không giới hạn ở việc thêm các liên kết, quảng
          cáo, v.v. của bên thứ ba); (6) sử dụng phần bổ trợ, phần bổ trợ hoặc
          các công cụ và dịch vụ khác của bên thứ ba để truy cập dịch vụ này và
          các hệ thống liên quan mà không có sự cho phép bằng văn bản của
          ODAILY; (7 ) sử dụng tài khoản ODAILY hoặc dịch vụ ODAILY để tham gia
          vào bất kỳ hoạt động bất hợp pháp và tội phạm nào; Hoặc các hành vi
          không được ODAILY cho phép rõ ràng.
        </p>
        <h6>6. [Quản lý tài khoản]</h6>
        <p>
          6.1 Quyền sở hữu tài khoản ODAILY thuộc sở hữu của Công ty TNHH Truyền
          thông Văn hóa Moti Hải Nam. Sau khi người dùng nền tảng hoàn tất thủ
          tục đăng ký ứng dụng, họ sẽ có quyền sử dụng tài khoản ODAILY. Quyền
          sử dụng này chỉ thuộc về người đăng ký ban đầu người đăng ký. Việc
          tặng, mượn, cho thuê và chuyển nhượng đều bị cấm hoặc bán. Công ty
          TNHH Truyền thông Văn hóa Moti Hải Nam có quyền chấm dứt dịch vụ này
          do nhu cầu kinh doanh.
        </p>
        <p>
          6.2 Bạn có trách nhiệm bảo mật đúng cách thông tin tài khoản đã đăng
          ký và mật khẩu tài khoản, đồng thời phải chịu trách nhiệm trước pháp
          luật về hành vi sử dụng tài khoản và mật khẩu đã đăng ký. Bạn đồng ý
          không tiết lộ thông tin tài khoản, mật khẩu cho người khác trong bất
          kỳ trường hợp nào. Khi bạn nghi ngờ rằng những người khác đang sử dụng
          tài khoản hoặc mật khẩu của mình, bạn đồng ý thông báo ngay cho Hainan
          Moti Culture Media Co., Ltd.
        </p>
        <p>
          6.3 Bạn nên tuân thủ các điều khoản của thỏa thuận này và sử dụng dịch
          vụ một cách chính xác và phù hợp. theo thỏa thuận này. Đồng thời,
          ODAILY có quyền thu hồi tài khoản ODAILY và tên người dùng nền tảng
          bất cứ lúc nào.
        </p>
        <h6>7. 【Lưu trữ dữ liệu】</h6>
        <p>
          7.1 ODAILY không chịu trách nhiệm về việc xóa hoặc lưu trữ lỗi dữ liệu
          liên quan của bạn trong dịch vụ này.
        </p>
        <p>
          7.2 ODAILY có quyền xác định thời gian lưu trữ tối đa cho dữ liệu của
          một người dùng nền tảng duy nhất trong dịch vụ này theo tình hình thực
          tế và phân bổ không gian lưu trữ tối đa cho dữ liệu trên máy chủ. Bạn
          có thể sao lưu dữ liệu liên quan trong dịch vụ này theo nhu cầu của
          riêng bạn.
        </p>
        <p>
          7.3 Nếu bạn ngừng sử dụng dịch vụ hoặc dịch vụ bị chấm dứt hoặc hủy
          bỏ, ODAILY có thể xóa vĩnh viễn dữ liệu của bạn khỏi máy chủ. Sau khi
          dịch vụ bị ngừng, chấm dứt hoặc hủy bỏ, ODAILY không có nghĩa vụ phải
          trả lại bất kỳ dữ liệu nào cho bạn.
        </p>
        <h6>8. 【Rủi ro và Tuyên bố miễn trừ trách nhiệm】</h6>
        <p>
          8.1 Bạn hiểu và đồng ý rằng dịch vụ này chỉ là một nền tảng để người
          dùng nền tảng chia sẻ, phổ biến và thu thập thông tin và bạn phải chịu
          trách nhiệm về mọi hành động trong tài khoản đã đăng ký của mình, bao
          gồm tính xác thực, hợp pháp, chính xác và hợp lệ của nội dung bạn
          đăng.sex, và chịu các kết quả phát sinh từ việc sử dụng dịch vụ này.
          Người dùng nền tảng nên tự mình đánh giá nội dung trong ODAILY và chịu
          mọi rủi ro phát sinh từ việc sử dụng nội dung, bao gồm các rủi ro phát
          sinh từ việc tin tưởng vào tính xác thực, hợp pháp, chính xác và hợp
          lệ của nội dung. ODAILY không thể và sẽ không chịu trách nhiệm pháp lý
          đối với những tổn thất hoặc thiệt hại do hành động của người dùng nền
          tảng gây ra.
        </p>
        <p>
          Nếu bạn phát hiện bất kỳ ai vi phạm các quy định của thỏa thuận này
          hoặc sử dụng dịch vụ của ODAILY theo những cách không phù hợp khác,
          vui lòng báo cáo hoặc khiếu nại ngay lập tức với ODAILY, và chúng tôi
          sẽ xử lý theo pháp luật.
        </p>
        <p>
          8.2 Bạn hiểu và đồng ý rằng do nhu cầu phát triển kinh doanh, ODAILY
          có quyền đơn phương thay đổi, đình chỉ, giới hạn, chấm dứt hoặc hủy bỏ
          tất cả hoặc một phần nội dung dịch vụ của dịch vụ này bất cứ lúc nào
          mà không cần thông báo trước và người dùng nền tảng sẽ chịu trách
          nhiệm rủi ro trách nhiệm.
        </p>
        <p>
          8.3 Bất kỳ bài báo, bản tin, trích dẫn, báo cáo, v.v. nào do ODAILY
          xuất bản không phải là lời khuyên đầu tư dành cho bạn. Bạn nhận thức
          đầy đủ và hiểu các rủi ro đầu tư của chuỗi khối và tiền kỹ thuật số,
          và tất cả các vấn đề đầu tư đều dựa trên sự hiểu biết của chính bạn.
        </p>
        <h6>9. 【Tuyên bố về sở hữu trí tuệ】</h6>
        <p>
          9.1 Quyền sở hữu trí tuệ đối với nội dung do ODAILY cung cấp trong
          dịch vụ này (bao gồm nhưng không giới hạn ở các trang web, văn bản,
          hình ảnh, âm thanh, video, biểu đồ, v.v.) thuộc về ODAILY và quyền sở
          hữu trí tuệ đối với nội dung được tạo ra bởi người dùng nền tảng trong
          việc sử dụng dịch vụ này thuộc về nền tảng Tất cả các quyền được bảo
          lưu bởi người dùng hoặc chủ sở hữu quyền liên quan.
        </p>
        <p>
          9.2 Trừ khi có quy định cụ thể khác, bản quyền, quyền sáng chế và các
          quyền sở hữu trí tuệ khác của phần mềm mà ODAILY cung cấp dịch vụ này
          thuộc sở hữu của ODAILY.
        </p>
        <p>
          9.3 &quot;ODAILY&quot; và các logo thương mại khác được ODAILY sử dụng
          trong dịch vụ này, bản quyền hoặc quyền thương hiệu thuộc sở hữu của
          Hainan Moti Culture Media Co., Ltd.
        </p>
        <p>
          9.4 Quyền sở hữu trí tuệ đối với nội dung trên và bất kỳ nội dung nào
          khác có trong dịch vụ này được pháp luật bảo vệ. Nếu không có sự cho
          phép bằng văn bản của ODAILY, người dùng nền tảng hoặc người có nghĩa
          vụ liên quan, không ai có thể sử dụng hoặc tạo các sản phẩm phái sinh
          có liên quan dưới mọi hình thức.
        </p>
        <h6>X. 【Trách nhiệm pháp lý】</h6>
        <p>
          10.1 Nếu ODAILY phát hiện hoặc nhận được báo cáo hoặc khiếu nại từ
          người khác rằng người dùng nền tảng vi phạm thỏa thuận này, ODAILY có
          quyền xóa nội dung có liên quan bất cứ lúc nào mà không cần thông báo
          và tùy thuộc vào hoàn cảnh của hành vi, trừng phạt tài khoản vi phạm
          bao gồm nhưng không giới hạn để cảnh báo, xóa một phần hoặc tất cả các
          Hình phạt đối với người dùng đăng ký, hạn chế hoặc cấm sử dụng tất cả
          hoặc một phần chức năng, cấm hoặc hủy tài khoản và thông báo kết quả.
        </p>
        <p>
          10.2 Bạn hiểu và đồng ý rằng ODAILY có quyền trừng phạt các vi phạm
          pháp luật và quy định có liên quan hoặc các quy định của thỏa thuận
          này dựa trên phán quyết hợp lý, thực hiện các hành động pháp lý thích
          hợp đối với bất kỳ ai vi phạm luật pháp và quy định, đồng thời lưu trữ
          thông tin liên quan để báo cáo cho các bộ phận liên quan theo luật
          pháp và quy định, v.v., người dùng nền tảng sẽ tự chịu mọi trách nhiệm
          pháp lý phát sinh từ đó.
        </p>
        <p>
          10.3 Bạn hiểu và đồng ý rằng bạn sẽ bồi thường cho ODAILY và các công
          ty hợp tác cũng như các chi nhánh của nó đối với bất kỳ khiếu nại, yêu
          cầu hoặc tổn thất nào do bất kỳ bên thứ ba nào yêu cầu, bao gồm phí
          luật sư hợp lý, gây ra hoặc phát sinh do bạn vi phạm các quy định của
          Thỏa thuận này hoặc các điều khoản có liên quan của dịch vụ.công ty và
          bảo vệ nó khỏi bị tổn hại.
        </p>
        <h6>11. [Hiệu lực và những thay đổi của Thỏa thuận]</h6>
        <p>
          11.1 Việc sử dụng dịch vụ của bạn được coi là đã đọc và đồng ý bị ràng
          buộc bởi thỏa thuận này.
        </p>
        <p>
          11.2 ODAILY có quyền sửa đổi các điều khoản của thỏa thuận này khi cần
          thiết. Bạn có thể kiểm tra phiên bản mới nhất của các điều khoản thỏa
          thuận trên trang dịch vụ liên quan.
        </p>
        <p>
          11.3 Sau khi các điều khoản của thỏa thuận này được thay đổi, nếu bạn
          tiếp tục sử dụng các dịch vụ của ODAILY, bạn được coi là đã chấp nhận
          thỏa thuận sửa đổi. Nếu bạn không chấp nhận thỏa thuận sửa đổi, bạn
          nên ngừng sử dụng dịch vụ này.
        </p>
        <h6>12. [Khác]</h6>
        <p>
          12.1 Nơi ký kết thỏa thuận này là Quận Triều Dương, Bắc Kinh, Cộng hòa
          Nhân dân Trung Hoa.
        </p>
        <p>
          12.2 Việc thiết lập, có hiệu lực, thực hiện, giải thích và giải quyết
          tranh chấp của thỏa thuận này sẽ được điều chỉnh bởi luật pháp của đại
          lục Cộng hòa Nhân dân Trung Hoa (không bao gồm xung đột pháp luật).
        </p>
        <p>
          12.3 Nếu có bất kỳ tranh chấp hoặc tranh chấp nào giữa bạn và ODAILY,
          trước tiên nên giải quyết thông qua thương lượng hữu nghị; nếu thương
          lượng không thành, bạn đồng ý đưa tranh chấp hoặc tranh chấp ra tòa án
          nhân dân có thẩm quyền nơi ký kết thỏa thuận này.
        </p>
        <p>
          12.4 Tiêu đề của tất cả các điều khoản trong thỏa thuận này chỉ nhằm
          mục đích thuận tiện cho việc đọc, bản thân chúng không có ý nghĩa thực
          tế và không thể được sử dụng làm cơ sở để giải thích ý nghĩa của thỏa
          thuận này.
        </p>
        <p>
          12.5 Bất kể phần nào của các điều khoản của thỏa thuận này là không
          hợp lệ hoặc không thể thực thi vì bất kỳ lý do gì, phần còn lại của
          các điều khoản vẫn có hiệu lực và ràng buộc đối với cả hai bên.
        </p>
        <p>12.6 Thỏa thuận này sẽ có hiệu lực vào ngày 1 tháng 5 năm 2018.</p>
      </div>
    );
  }
}

export default AgreementVi;
