import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import styles from './index.scss';
import locales from '../../../utils/locales';
import { withRouter } from 'react-router-dom';

export class ShareBar extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    secondary: PropTypes.array.isRequired,
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      weiboShareUrl: '',
      TwitterUrl: ''
    };
  }

  handleScrollTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    const { title } = this.props;
    const url = window.location.origin + window.location.pathname;

    const shareQRCodeCanvas = document.getElementById('share-qrcode');
    QRCode.toCanvas(shareQRCodeCanvas, url, { width: 52 });

    const weiboShareUrl = `http://v.t.sina.com.cn/share/share.php?&appkey=2523671237&content=utf-8&url=${url}&title=${title +
      ' @星球日报Odaily '}&sourceUrl=${url}
    `;
    const TwitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}&via=OdailyChina`;

    this.setState({
      weiboShareUrl,
      TwitterUrl
    });
  }

  renderSecondary() {
    const { secondary } = this.props;
    return secondary
      .filter(item => {
        return item.slug;
      })
      .map((item, index) => {
        return (
          <a
            key={`tag${index}`}
            className={styles['tag']}
            href={`/search/${item.name}`}
            rel="noopener noreferrer"
          >
            {item.name}
          </a>
        );
      });
  }

  render() {
    const { weiboShareUrl, TwitterUrl } = this.state;
    const { lang } = this.props.match.params;
    const share2twitter = encodeURI(TwitterUrl);
    return (
      <div className={styles.container}>
        {/* 微信 */}
        <div className={styles.button}>
          <div className={styles.qrcode_icon} />
          <div
            className={styles.qrcode}
            style={{
              width: !lang || lang == 'zhtw' ? '160px' : '220px'
            }}
          >
            <div className={styles.inner}>
              <div className={styles.qrcode_img}>
                <canvas id="share-qrcode" />
              </div>

              <div className={styles.tips}>
                <p>{locales(lang, 'scan')}</p>
                <span>{locales(lang, 'share_article')}</span>
              </div>
            </div>
          </div>
        </div>
        {/* 微博 */}
        <div className={styles.button}>
          <a
            href={weiboShareUrl}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <div className={styles.weibo_icon} />
          </a>
        </div>
        {/* 推特 */}
        <div className={styles.button}>
          <a
            href={share2twitter}
            target="_blank"
            rel="nofollow noopener noreferrer"
            title={`${locales(lang, 'shareTo')} Twitter`}
          >
            <div className={styles.twitter_icon} />
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(ShareBar));
