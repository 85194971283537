import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { timesAgo } from '../../../common/scripts/time';
import { dot } from '../../../common/scripts/dot';
import { withRouter } from 'react-router-dom';

// import animate from 'animate.css';

export class NewsItem extends React.PureComponent {
  static propTypes = {
    entity_id: PropTypes.string,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    newsflash_image: PropTypes.string,
    user_id: PropTypes.number,
    currentUser: PropTypes.object,
    entity_type: PropTypes.string,
    summary: PropTypes.string,
    description: PropTypes.string,
    secondary_tag: PropTypes.string,
    is_top: PropTypes.number,
    match: PropTypes.object
  };

  renderSecondaryTag(secondary_tag) {
    try {
      secondary_tag
        ? JSON.parse(secondary_tag).map(item => {
            return (
              <a
                className={styles.secondary_tag_item}
                href={`/search/${item.name}`}
                target={'_blank'}
                rel="noopener noreferrer"
                key={`secondary_tag_item${item.name}`}
              >
                {item.name}
              </a>
            );
          })
        : '';
    } catch (e) {
      return '';
    }
  }

  render() {
    const {
      entity_id,
      id,
      currentUser,
      entity_type,
      title,
      summary,
      published_at,
      secondary_tag,
      description,
      is_top,
      match
    } = this.props;
    const { lang } = match.params;
    return (
      <div className={styles.news_item}>
        <a
          href={`/newsflash/${entity_id ? entity_id : id}`}
          target={'_blank'}
          rel="noreferrer"
          onClick={() => {
            dot({
              id: 24,
              user_id: currentUser.id,
              extra: { type: entity_type }
            });
          }}
          className={styles.news_item_wrapper}
        >
          <div className={styles.news_item_title}>{title}</div>
          <div className={styles.news_item_summary}>
            {summary ? summary : description}
          </div>
          <div className={styles.news_item_footer}>
            {is_top ? (
              <div className={styles.isTop} />
            ) : (
              <div className={styles['other']}>
                {timesAgo(published_at, undefined, lang)}
              </div>
            )}
            {this.renderSecondaryTag(secondary_tag)}
          </div>
        </a>
      </div>
    );
  }
}
export default withRouter(NewsItem);
