import http, { service } from '../scripts/http';
import qs from 'querystring';

export function getNewsflash(
  { bId = '', perPage = 10, is_import = false },
  lang
) {
  const query = qs.stringify({
    b_id: bId,
    per_page: perPage,
    is_import: `${is_import}`
  });
  return http.get(`/info-flow/newsflash_columns/newsflashes?${query}`, {
    lang
  });
}

export function getMyNewsFlash({ page = 1 }, lang) {
  return service.get(`/ugc/my-newsflashes?page=${page}`, { lang });
}

export function postNewsflashVote(id, type) {
  const data = {
    entity_id: id,
    type: type
  };
  return http.post(`/newsflash_vote`, data);
}

export function getNewsflashDetail(id, lang) {
  return http.get(`/newsflash/${id}`, { lang });
}

export function postNewsFlashCollect(fid, direction) {
  const data = {
    fid,
    direction,
    ftype: 'newsflash'
  };
  return http.post('/favorite', data);
}

export function createNewsflash({
  title,
  description,
  cover,
  news_url_type,
  news_url
}) {
  const data = {
    title,
    description,
    cover,
    news_url_type,
    news_url
  };
  return service.post('/ugc/news-flashes', data);
}

export function getHotNewsflash(lang) {
  return http.get('/hot/newsflash', { lang });
}

export function getTagNewsflash(slug, lang) {
  return http.get(`/tag_newsflash?slug=${slug}`, { lang });
}
