// import { combineReducers } from 'redux';

import { combineReducers } from 'redux';

const searchFeeds = (state = {}, action) => {
  const { list } = action;

  switch (action.type) {
    case 'SET_FEEDS_SEARCH':
      return {
        ...state,
        list
      };
    default:
      return state;
  }
};

const talkFeeds = (state = {}, action) => {
  const { list } = action;

  switch (action.type) {
    case 'SET_FEEDS_TALK':
      return {
        ...state,
        list
      };
    default:
      return state;
  }
};

export default combineReducers({ searchFeeds, talkFeeds });
