import { combineReducers } from 'redux';

const list = (state = [], action) => {
  switch (action.type) {
    case 'SET_USER_DEBRIS':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({ list });
