import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import styles from './index.scss';

import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from '../../components/common/layout';

import { getVote, getVoteRank } from '../../common/api/vote';
import { popularityList } from '../activityed2021/data';

import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

export class Vote extends React.Component {
  static propTypes = {
    voteList: PropTypes.array,
    match: PropTypes.object.isRequired
  };
  static fetchData(store, match, res) {
    return new Promise(resolve => {
      getVote()
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_VOTE_LIST',
            list
          });
          resolve();
        })
        .catch(() => {
          const lang = match.params.lang;
          const postHref = lang ? `/${lang}/404` : `/404`;
          res.redirect(postHref);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      voteList: { items: [] },
      alertIndex: 0,
      alertFlag: false,
      vote_rank: [],
      vote_num: 5,
      id: this.props.match.params.id,
      remain_total: 10,
      addNumFlag: false
    };
  }

  getVoteList() {
    const { id } = this.state;
    getVote().then(data => {
      // let data = datas.data;
      if (data.code === 0) {
        let ary = data.data.items.filter(item => {
          return item.id + '' === id;
        });
        this.setState({
          vote_num: ary[0].surplus_vote,
          voteList: ary[0] || { items: [] },
          remain_total: ary[0].vote_num
        });
      }
    });
  }

  componentDidMount() {
    this.getVoteList();
  }

  changeAlert(index) {
    if (index !== undefined) {
      this.setState({
        alertFlag: true,
        alertIndex: index
      });
    } else {
      this.setState({
        alertFlag: false
      });
    }
  }

  changeVote(index) {
    const { vote_rank, id, voteList } = this.state;
    const vote_item_id = voteList.items[index].id;
    let newVoteList = voteList;
    newVoteList.items[index].poll = Number(newVoteList.items[index].poll) + 1;

    getVoteRank(id, vote_item_id).then(data => {
      // let data = datas.data;
      // console.log('data : ', data);
      if (data.code === 0) {
        vote_rank.push(index);
        this.setState(
          {
            // vote_num: data.data.items.remain_total,
            vote_num: 0,
            vote_rank,
            addNumFlag: true,
            voteList: newVoteList
          },
          () => {
            setTimeout(() => {
              this.setState({ addNumFlag: false });
            }, 1500);
          }
        );
      }
    });
  }

  getHref(title) {
    let ary = popularityList.filter(item => {
      let title2 = item.id;
      return title2 === title;
    });
    if (ary[0]) {
      return ary[0].href;
    }
  }

  renderVoteList() {
    const { voteList, vote_rank, vote_num } = this.state;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.voteList}>
        {voteList.items
          ? voteList.items.map((item, index) => {
              let crown = null;
              switch (index) {
                case 0:
                  crown = styles.crown1;
                  break;
                case 1:
                  crown = styles.crown2;
                  break;
                case 2:
                  crown = styles.crown3;
                  break;
              }
              let buttonFlag =
                vote_rank.indexOf(index) === -1 && vote_num > 0 ? true : false;
              return (
                <div className={styles.voteItem} key={`vote${index}`}>
                  <div className={styles.picture}>
                    <img src={item.picture_url} loading="lazy" />
                    <div className={crown} />
                  </div>
                  <div className={styles.name}>{item.name}</div>
                  <div className={styles.poll}>
                    {locales(lang, 'current_votes')}：{item.poll}
                  </div>
                  <div
                    className={
                      buttonFlag ? styles.voteButton : styles.voteButton2
                    }
                    onClick={() => {
                      this.changeAlert(index);
                    }}
                  >
                    {vote_rank.indexOf(index) === -1
                      ? locales(lang, 'go_to_vote')
                      : locales(lang, 'voted')}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  }

  renderAlert() {
    const {
      alertIndex,
      voteList,
      alertFlag,
      vote_rank,
      vote_num,
      addNumFlag
    } = this.state;
    const { lang } = this.props.match.params;
    const data = voteList.items ? voteList.items[alertIndex] : null;
    let buttonFlag =
      vote_rank.indexOf(alertIndex) === -1 && vote_num > 0 ? true : false;
    if (data) {
      return (
        <div
          className={styles.alert}
          style={{ display: alertFlag ? 'block' : 'none' }}
          onClick={() => {
            this.changeAlert();
          }}
        >
          <div
            className={styles.alertBox}
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            <div className={styles.alertBox_wrapper}>
              <div className={styles.alertLeft}>
                <img src={data.picture_url} loading="lazy" />
              </div>
              <div className={styles.alertRight}>
                <div className={styles.name}>{data.name}</div>
                <div className={styles.poll}>
                  {locales(lang, 'current_votes')}：{data.poll}
                </div>
                <div className={styles.border} />
                <div className={styles.introduction}>{data.introduction}</div>
              </div>
            </div>
            <div
              className={addNumFlag ? styles.addNum : null}
              style={{ display: addNumFlag ? null : 'none' }}
            >
              +1
            </div>
            <div
              className={buttonFlag ? styles.alertButton : styles.alertButton2}
              onClick={() => {
                buttonFlag ? this.changeVote(alertIndex) : null;
              }}
            >
              {buttonFlag ? locales(lang, 'vote') : locales(lang, 'voted')}
            </div>
            <div
              className={styles.close}
              onClick={() => {
                this.changeAlert();
              }}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    let helmetData = getHelmet({
      title: `2021「N—POWER」榜单`,
      description:
        'ODAILY区块链榜单频道，提供最受社区欢迎的区块链项目，聚焦区块链年度榜单，包含区块链人物榜、事件榜、项目榜，为您奉献年度最具价值榜单。',
      keywords:
        '链游,元宇宙,nft,比特币,区块链,btc,eth,狗狗币,空投,web3钱包,ai,web3新闻媒体,web3资讯,区块链新闻,区块链技术'
    });
    const { voteList, remain_total } = this.state;

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.header_img} />
            <div className={styles.header_nav}>
              <div className={styles.header_button}>
                <a href="/AcyivityVote">＜ 返回榜单</a>
              </div>
            </div>
          </div>
          <div className={styles.main}>
            <div className={styles.decorate_left} />
            <div className={styles.decorate_right} />
            <div className={styles.main_wrapper}>
              <div className={styles.title}>{voteList.title}评选</div>
              {this.renderVoteList()}
              <div className={styles.header_button}>
                <a href="/AcyivityVote">＜ 返回榜单</a>
                <a href={this.getHref(voteList.title)}>去参评＞</a>
              </div>
              <div className={styles.footer}>
                <p>
                  投票截止：2021-11-1
                  <br />
                  投票说明：各IP每日拥有{remain_total}
                  次投票机会，单次投票各榜单可投1票。
                </p>
              </div>
            </div>
          </div>
          {this.renderAlert()}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    voteList: state.vote.list
  };
};

export default withRouter(connect(mapStateToProps)(Vote));
