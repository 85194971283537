import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { timesAgo } from '../../../common/scripts/time';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';

const TransformHtml = html => {
  const changeImgDesc = html
    ? html.replace(/img-desc/g, styles['img-desc'])
    : '';
  return changeImgDesc;
};

export class PostDetail extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    summary: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    source_type: PropTypes.string.isRequired,
    source_urls: PropTypes.string,
    secondary: PropTypes.array,
    type: PropTypes.string,
    odataProject: PropTypes.array,
    odataOrganization: PropTypes.array,
    match: PropTypes.object
  };

  static defaultProps = {
    odataProject: [],
    odataOrganization: []
  };

  content = React.createRef();

  replaceSpecial(content) {
    const { odataProject, odataOrganization, match } = this.props;
    const { lang } = match.params;
    return content.replace(
      /<span class="specialFont">(.+?)<\/span>/g,
      (a, b) => {
        let href = '';

        let data = odataProject.find(item => {
          let bool = b === item.en_name;
          if (bool) {
            href =
              process.env.ODATA +
              `/project/${item.id}?item_id=${
                item.items && item.items.length ? item.items[0]['id'] : ''
              }`;
          }
          return bool;
        });

        if (!data) {
          data = odataOrganization.find(item => {
            let bool = b === item.en_name;
            if (bool) {
              href = process.env.ODATA + `/institution/${item.id}`;
            }
            return bool;
          });
        }

        if (data) {
          return `<span class="${styles.specialFont}">
            ${data.en_name}
            <span class="${styles.specialFont_box}">
              <span class="${styles.specialFont_box_header}">
                <span class="${
                  styles.specialFont_box_cover
                }" style="background-image:url(${data.cover})"></span>

                <span class="${styles.specialFont_box_title}">
                  ${data.en_name}
                </span>
              </span>

              ${
                data.summary
                  ? `<span class=${styles.specialFont_box_summary} style="-webkit-box-orient:vertical">${data.summary}
              </span>`
                  : ''
              }

              <object><a class=${
                styles.specialFont_box_button
              } href="${href}" target="_blank" rel="noopener noreferrer">${locales(
            lang,
            'seeMore'
          )}</a></object>
            </span>
          </span>`;
        } else {
          return b;
        }
      }
    );
  }

  renderMarkUp() {
    let { content, type } = this.props;
    const markup = () => {
      return {
        __html: this.replaceSpecial(TransformHtml(content))
      };
    };
    return (
      <div className={styles.post_content_wrapper}>
        <div className={type === 'preview' ? styles.post_preview : ''} />
        <div
          ref={this.content}
          className={styles['post-content']}
          dangerouslySetInnerHTML={markup()}
        />
      </div>
    );
  }

  renderSourceOrigin(name) {
    return (
      <p>
        Original article by {name}. For reprinting/content cooperation/seeking
        reports, please contact report@odaily.email ; Illegal reprints must be
        prosecuted by law.
      </p>
    );
  }

  renderSourceTranslation(links) {
    const link = JSON.parse(links)[0];
    return (
      <p>
        This article is translated from
        <a>{link}</a>. If reproduced, please indicate the source.
      </p>
    );
  }

  renderSourceContribution() {
    return (
      <p>
        This article is from a contribution and does not represent the position
        of Odaily. If reproduced, please indicate the source.
      </p>
    );
  }

  renderSourceReference(links) {
    const linksArr = JSON.parse(links);
    const linksElements = linksArr.map((link, index) => {
      return <a key={index}>{link}</a>;
    });
    return <p>本文参考了多个信息来源：{linksElements}，如若转载请注明出处。</p>;
  }

  renderAnnounce() {
    const { source_type, source_urls, user } = this.props;
    const isOriginal = source_type === 'original' ? true : false;
    const isTranslation = source_type === 'translation' ? true : false;
    const isContribution = source_type === 'contribution' ? true : false;
    const isReference = source_type === 'reference' ? true : false;
    return (
      <div className={styles['post-source']}>
        {isOriginal ? this.renderSourceOrigin(user ? user.name : '') : null}
        {isTranslation ? this.renderSourceTranslation(source_urls) : null}
        {isContribution ? this.renderSourceContribution() : null}
        {isReference ? this.renderSourceReference(source_urls) : null}
      </div>
    );
  }

  renderRiskTip() {
    const { match } = this.props;
    const { lang } = match.params;
    return (
      <div className={styles['post-source']}>
        {`${locales(lang, 'odaily_reminder')}${locales(
          lang,
          'please_establish_correct_concepts'
        )}`}
      </div>
    );
  }
  componentDidMount() {
    const contentEl = this.content.current;
    const $entityList = $(contentEl).find('iframe.entity-iframe');
    $entityList &&
      $entityList.each((index, entity) => {
        const $entity = $(entity);
        const type = $entity.data('type');
        const src = $entity.attr('src');
        const poster = $entity.attr('poster');

        if (type === 'video') {
          $entity.parent().replaceWith(`
          <p>
            <video controls poster=${poster} src=${src} />
          </p>
        `);
        }
      });

    let $aList = $(this.content.current).find('a');
    $aList &&
      $aList.each((index, aItem) => {
        aItem.target = '_blank';
      });
  }

  renderSecondary() {
    const { secondary } = this.props;

    if (secondary) {
      return (
        <div className={styles.secondaryList}>
          {secondary && secondary.length
            ? secondary.map(item => {
                return (
                  <a
                    className={styles.secondaryItem}
                    key={`secondaryItem${item.name}`}
                    href={`/search/${item.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                );
              })
            : ''}
        </div>
      );
    }
  }

  render() {
    const { title, summary, published_at, match } = this.props;
    const { lang } = match.params;
    return (
      <div className={styles.container}>
        {/* 标题 */}
        <h1 itemProp="headline" className={styles['post-title']}>
          {title}
        </h1>

        {/* 时间 */}
        <div itemProp="dateCreated" className={styles['post-time']}>
          {timesAgo(published_at, undefined, lang)}
        </div>

        {/* 概要 */}
        <div itemProp="alternativeHeadline" className={styles['post-summary']}>
          {summary}
        </div>

        {/* 正文 */}
        {this.renderMarkUp()}

        <div className={styles.post_join}>
          <div className={styles.post_join_title}>
            {locales(lang, 'welc_Odaily')}
          </div>

          <div className={styles.post_join_item}>
            {locales(lang, 'telegram_SG')}
            <a
              href="https://t.me/Odaily_News"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://t.me/Odaily_News
            </a>
          </div>
          <div className={styles.post_join_item}>
            {locales(lang, 'telegram_CG')}
            <a
              href="https://t.me/Odaily_CryptoPunk"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://t.me/Odaily_CryptoPunk
            </a>
          </div>
          <div className={styles.post_join_item}>
            {locales(lang, 'twitter')}
            <a
              href="https://twitter.com/OdailyChina"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://twitter.com/OdailyChina
            </a>
          </div>
        </div>

        {/* 提醒 */}
        <div className={styles.warn}>
          {this.renderAnnounce()}
          {this.renderRiskTip()}
        </div>

        {this.renderSecondary()}
      </div>
    );
  }
}
export default withRouter(PostDetail);
