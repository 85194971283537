import { combineReducers } from 'redux';

const columnInfo = (state = {}, action) => {
  switch (action.type) {
    case 'SET_COLUMN_INFO':
      return action.list;
    default:
      return state;
  }
};

const posts = (state = [], action) => {
  switch (action.type) {
    case 'SET_COLUMN_POSTS':
      return action.list;
    default:
      return state;
  }
};
const classic = (state = [], action) => {
  switch (action.type) {
    case 'SET_CLASSIC_POSTS':
      return action.list;
    default:
      return state;
  }
};
const pages = (state = {}, action) => {
  switch (action.type) {
    case 'SET_COLUMN_PAGE':
      return action.list;
    default:
      return state;
  }
};

const pages_classic = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CLASSIC_PAGE':
      return action.list;
    default:
      return state;
  }
};

const newsList = (state = [], action) => {
  switch (action.type) {
    case 'SET_NEWS_LIST':
      return action.list;
    default:
      return state;
  }
};

const secondaryNewsflashe = (state = [], action) => {
  switch (action.type) {
    case 'SET_SECONDARY_NEWSFLASHE':
      return action.list;
    default:
      return state;
  }
};

const newsflashePage = (state = {}, action) => {
  switch (action.type) {
    case 'SET_NEWSFLASHE_PAGE':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  columnInfo,
  posts,
  pages,
  newsList,
  classic,
  pages_classic,
  secondaryNewsflashe,
  newsflashePage
});
