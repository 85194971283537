import React from 'react';
import styles from './index.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import locales from '../../utils/locales';
const ImgOutdated = require('./images/outdated.png');

export class Outdated extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object
  };
  render() {
    const { lang } = this.props.match.params;
    return (
      <div className={styles.container}>
        <div className={styles.tips}>
          <img src={ImgOutdated} alt="浏览器升级" loading="lazy" />
          <div className={styles.text}>
            <p className={styles['outdated-text']}>
              {locales(lang, 'your_browser_version_is_too_low')}
            </p>
            <p className={styles['upgrade-text']}>
              {locales(lang, 'upgrade_your_ie_browser')}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Outdated);
