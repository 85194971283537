const translations = {
  en: {
    text1: 'Current phone number',
    text2: 'New phone number',
    text3: 'Enter your new phone number',
    text4: 'Enter verification code',
    text5: 'Send verification code',
    text6: 'Repeat after',
    text7: 'next',
    text8: 'save',
    text9: 'The verification code has been sent, please check it',
    text10: 'Modified successfully'
  },
  zhcn: {
    text1: '当前手机号',
    text2: '新手机号',
    text3: '输入新手机号',
    text4: '输入验证码',
    text5: '发送验证码',
    text6: '后重发',
    text7: '下一步',
    text8: '保存',
    text9: '验证码已发送，请注意查收',
    text10: '修改成功'
  },
  zhtw: {
    text1: '當前手機號',
    text2: '新手機號',
    text3: '輸入新手機號',
    text4: '輸入驗證碼',
    text5: '發送驗證碼',
    text6: '後重發',
    text7: '下一步',
    text8: '保存',
    text9: '驗證碼已發送，請注意查收',
    text10: '修改成功'
  },
  ja: {
    text1: '現在の携帯番号です',
    text2: '新しい携帯番号です',
    text3: '新しい携帯番号を入力します',
    text4: 'captchaを入力します',
    text5: 'captchaを送ります',
    text6: '後で再送します',
    text7: '次のステップです',
    text8: '保存します',
    text9: 'captchaを送信しました,ご確認ください',
    text10: '修正成功です'
  },
  ko: {
    text1: '현재 핸드폰 번호',
    text2: '새 핸드폰 번호',
    text3: '새 휴대전화 번호를 입력하다',
    text4: '인증 코드 입력',
    text5: '인증 코드 보내기',
    text6: '나중에 다시 보내기',
    text7: '다음 단계',
    text8: '저장',
    text9: '인증번호가 전송되었으니 확인하시기 바랍니다',
    text10: '성공적으로 수정했습니다'
  },
  th: {
    text1: 'หมายเลขโทรศัพท์ปัจจุบัน',
    text2: 'หมายเลขโทรศัพท์ใหม่',
    text3: 'ป้อนหมายเลขโทรศัพท์ใหม่',
    text4: 'ป้อนรหัสการตรวจสอบ',
    text5: 'ส่งรหัสการตรวจสอบ',
    text6: 'หลังจากนั้น ก็ทำใหม่',
    text7: 'ขั้นต่อไป',
    text8: 'บันทึก',
    text9: 'ส่งแคปช่าเรียบร้อยแล้วโปรดตรวจสอบ',
    text10: 'แก้ไขเรียบร้อยแล้ว',
    text11: 'รหัสผ่านปัจจุบัน',
    text12: 'รหัสผ่านใหม่',
    text13: 'กรุณาใส่รหัส 8-16 บิตเพื่อให้มีตัวอักษร และตัวเลข',
    text14: 'ลืมรหัสผ่าน'
  },
  vi: {
    text1: 'Số điện thoại hiện tại',
    text2: 'Số điện thoại mới',
    text3: 'Nhập số điện thoại mới',
    text4: 'Nhập mã xác minh',
    text5: 'Gửi mã xác minh',
    text6: 'Sau khi một lần nữa',
    text7: 'Bước tiếp theo',
    text8: 'Bảo tồn',
    text9: 'Captcha đã được gửi, xin vui lòng kiểm tra',
    text10: 'Sửa đổi thành công'
  }
};

function locales(lang, name) {
  // 如果语言代码不存在，则默认使用中文
  const langCode = translations[lang] ? lang : 'zhcn';
  // 如果指定的键名不存在，则返回本身
  const translation = translations[langCode][name] || name;
  return translation;
}
export default locales;
