/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Helmet from 'react-helmet';
import styles from './fat.scss';

import { getHelmet } from '../../common/scripts/helmet';

import Layout from '../../components/common/layout';
import { withRouter } from 'react-router-dom';
import { getTopList } from '../../common/api/columns';
import { getTopicById } from '../../common/api/topic';
import { TopItem } from '../../components/activity';
import PropTypes from 'prop-types';
import locales from '../../utils/locales';
const btcLogo = require('../../assets/image/8btc.png');
const bibi = require('../../assets/image/bibi.png');
const coinvoice = require('../../assets/image/coinvoice.png');
const PANews = require('../../assets/image/PANews.png');
const shilian = require('../../assets/image/shilian.png');
const bolian = require('../../assets/image/bolian.png');
const xiaocong = require('../../assets/image/xiaocong.png');
const book = require('../../assets/image/book.png');
const shenlian = require('../../assets/image/shenlian.png');
const huoxing = require('../../assets/image/huoxing.png');
const huoxun = require('../../assets/image/huoxun.png');
const lieyun = require('../../assets/image/lieyun.png');
const tanlian = require('../../assets/image/tanlian.png');
const jinse = require('../../assets/image/jinse.png');
const bushou = require('../../assets/image/bushou.png');
const tuoluo = require('../../assets/image/tuoluo.png');
const basha = require('../../assets/image/top_basha.png');
const keLogo = require('../../assets/image/top_36_logo.png');
const andLogo = require('../../assets/image/antd_logo.png');
const wecode1 = require('../../assets/image/weCode1.png');
const wecode2 = require('../../assets/image/weCode2.png');
// const lvdong = require('../../assets/image/qkld.png');

const friendArr1 = [
  jinse,
  btcLogo,
  xiaocong,
  book,
  huoxing,
  tuoluo,
  shenlian,
  huoxun
];
const friendArr2 = [
  tanlian,
  bolian,
  bibi,
  PANews,
  lieyun,
  shilian,
  coinvoice,
  bushou
];
const friendArr3 = [];
const friendArr4 = [keLogo, basha, andLogo];

export class Fat extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      exchange: [],
      project: [],
      userList: [],
      userlist2: [],
      flag: true,
      users: [],
      topic: {},
      figureList: [], //5个人物
      eventList: [], //10个事件
      projectedList: [], //投票后，
      charityList: [], //芭莎投票
      sceneList: [], //36大
      organizationList: [], //机构
      industryList: [], //品牌
      uniList: [],
      uniflag: 0,
      financial_list: []
    };
  }

  componentDidMount() {
    // 643
    getTopicById('643').then(data => {
      this.setState({
        topic: data.data
      });
    });
    getTopList().then(res => {
      this.setState({
        figureList: res.data.figure_list,
        eventList: res.data.event_list,
        projectedList: res.data.project_list,
        charityList: res.data.charity_list,
        sceneList: res.data.scene_list,
        organizationList: res.data.organization_list,
        industryList: res.data.industry_list,
        exchange: res.data.exchange,
        project: res.data.project,
        userList: res.data.entrepreneur.slice(0, 20),
        users: res.data.entrepreneur.slice(0, 20),
        userlist2: res.data.entrepreneur,
        uniList: res.data.charity_list,
        financial_list: res.data.financial_list
      });
    });
  }

  renderFriend1() {
    return friendArr1.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderFriend4() {
    return friendArr4.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderFriend2() {
    return friendArr2.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderFriend3() {
    return friendArr3.map((item, index) => {
      return <img key={index} src={item} alt="" loading="lazy" />;
    });
  }
  renderProject() {
    return this.state.project.map((item, index) => {
      return (
        <div key={index} className={styles.voteItem}>
          <div className={styles.avatorBox}>
            <img src={item.icon} alt="" loading="lazy" />
          </div>
          <p className={styles.name}>{item.name}</p>
        </div>
      );
    });
  }
  renderExchange() {
    const { lang } = this.props.match.params;
    return this.state.exchange.map((item, index) => {
      return (
        <div key={index} className={styles.voteItem}>
          <div className={styles.avatorBox}>
            <img src={item.icon} alt="" loading="lazy" />
          </div>
          <p className={styles.name}>{item.name}</p>
          <p className={styles.count}>
            {locales(lang, 'current_votes')}：{item.votes}
          </p>
        </div>
      );
    });
  }
  renderUserItem() {
    return this.state.users.map((item, index) => {
      return (
        <div key={index} className={styles.userItem}>
          <img src={item.icon} alt="" loading="lazy" />
          <div className={styles.mask}>
            <h4>{item.name}</h4>
            <p>{item.company}</p>
            <p>{item.title}</p>
          </div>
        </div>
      );
    });
  }
  renderObj() {
    return this.state.projectedList.map((item, index) => {
      return (
        <div className={styles.coinItem} key={index}>
          <img src={item.icon} alt="" loading="lazy" />
          <div className={styles.itemRight}>
            <p>
              {index + 1}、{item.project}
            </p>
            <p className={styles.desc}>{item.des}</p>
          </div>
        </div>
      );
    });
  }
  renderfigure() {
    return this.state.figureList.map((item, index) => {
      return (
        <div key={index} className={styles.peopleItem}>
          <img src={item.icon} alt="" loading="lazy" />
          <p>{item.name}</p>
          <p className={styles.desc}>{item.title}</p>
        </div>
      );
    });
  }
  renderfigure2() {
    return this.state.financial_list.map((item, index) => {
      return (
        <div key={index} className={styles.peopleItem}>
          <img src={item.icon} alt="" loading="lazy" />
          <p>{item.name}</p>
        </div>
      );
    });
  }
  renderEvent() {
    return this.state.eventList.map((item, index) => {
      return (
        <div key={index} className={styles.objItem}>
          <p>
            {index + 1}、{item.event}
          </p>
          <p className={styles.time}>{item.time}</p>
        </div>
      );
    });
  }
  changeBtn() {
    const list1 = this.state.userList;
    const list2 = this.state.userlist2;
    const flag = this.state.flag;

    this.setState(
      {
        flag: !flag
      },
      () => {
        if (this.state.flag) {
          this.setState({
            users: list1
          });
        } else {
          this.setState({
            users: list2
          });
        }
      }
    );
  }
  renderUnilist() {
    return this.state.uniList.map((item, index) => {
      return <TopItem {...item} num={index} key={index} />;
    });
  }
  returnOrganization() {
    return this.state.organizationList.map((item, index) => {
      return (
        <div className={styles.item} key={index}>
          <img src={item.icon} alt="" loading="lazy" />
          <p>{item.org}</p>
        </div>
      );
    });
  }
  returnIndustry() {
    return this.state.industryList.map((item, index) => {
      return (
        <div className={styles.item} key={index}>
          <img src={item.icon} alt="" loading="lazy" />
          <p>{item.name}</p>
        </div>
      );
    });
  }
  render() {
    let helmetData = getHelmet({
      title: '区块链榜单_行业区块链数据,行业区块链榜单',
      description:
        'ODAILY区块链榜单频道，提供最受社区欢迎的区块链项目，聚焦区块链年度榜单，包含区块链人物榜、事件榜、项目榜，为您奉献年度最具价值榜单。'
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div>
            <div className={styles.wrapper}>
              <div className={styles.postbg} />
              <h2 className={styles.topTitle}>
                回望2019，注定是区块链不平凡的一年。
              </h2>
              <h2 className={styles.topTitle}>
                年初，我们在寒冬中蛰伏；年中，我们在突破中探索；年尾，我们在颠覆中沉淀。
              </h2>
              <h2 className={styles.topTitle}>
                十多年前，区块链诞生于一串代码，
              </h2>
              <h2 className={styles.topTitle}>
                它关乎一小部分人的造富梦想；却更关乎着一大群人的极客精神；
              </h2>
              <h2 className={styles.topTitle}>
                十多年后，区块链被赋予了跨越时代的意义，从无序到有序，从边缘到主流，从互联网纪元到区块链纪元；
              </h2>
              <h2 className={styles.topTitle}>
                ODAILY想邀请您，一起去见证这个时代新技术、新领袖、新格局的诞生；
              </h2>
              <h2 className={styles.topTitle}>
                我们坚信，那些影响现在的人，正在改变未来！
              </h2>
              <h2 className={styles.topTitle}>
                破碎时的力挽狂澜，停滞时的坚守不渝，颠覆时的摧枯拉朽，都将共同构筑成历史的节点。
              </h2>
              <p className={styles.wrapDesc}>
                即日，2019年度 Odaily FAT Blockchain Award 榜单评选正式揭晓
              </p>
              <p className={styles.wrapDesc}>
                我们将为您奉献年度最具价值榜单，为每一个区块链参与者带来最优质的人脉、最深度的洞察和最出圈的共赢
              </p>
              <p className={styles.wrapDesc2}>
                除事件榜按时间、热度榜按得票数外，榜单排名不分先后。
              </p>
              {/* 头以下，联合榜单以上 */}
              <div className={styles.topWrapper}>
                {/*年度人物榜*/}
                <div className={styles.people}>
                  <div className={styles.peopleCover} />
                  <div className={styles.peopleBox}>{this.renderfigure()}</div>
                </div>
                {/* 年度事件榜*/}
                <div className={styles.objBox}>
                  <div className={styles.bojBg} />
                  {/*<div className={styles.objItem}>*/}
                  {/*<p>1、Libra官网上线，白皮书发布。</p>*/}
                  {/*<p className={styles.time}>2019/06/18</p>*/}
                  {/*</div>*/}
                  {this.renderEvent()}
                </div>
                {/*年度项目榜*/}
                <div className={styles.coinBox}>
                  <div className={styles.coinBg} />
                  <div className={styles.coins}>{this.renderObj()}</div>
                </div>
                {/*引领热度榜*/}
                <div className={styles.votes}>
                  <div className={styles.hotPost}>
                    <div className={styles.rankNav}>
                      <div className={styles.navLeft}>
                        <h3>引领热度榜</h3>
                      </div>
                    </div>
                  </div>
                  <h4>最受社区欢迎区块链项目</h4>
                  <p className={styles.voteDesc}>
                    区块链的共识强弱由谁决定？答案当然是社区。只有集中众人之能量，项目才能走向远方。
                  </p>
                  <p className={styles.voteDesc}>
                    在这个世界，拥护即为共识，热度就是实力。
                  </p>
                  {/* 图片 */}
                  <div className={styles.voteList}>{this.renderProject()}</div>
                  {/* 年度加密金融服务 */}
                  <div className={styles.jinrongList}>
                    <div className={styles.jinrongIcon} />
                    <div className={styles.jinrongUl}>
                      {this.renderfigure2()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*联合榜单*/}
            <div className={styles.unionWrapper}>
              <div className={styles.hotPost}>
                <div className={styles.rankNav}>
                  <div className={styles.navLeft}>
                    <h3>联合榜单</h3>
                  </div>
                </div>
                <p
                  onClick={() => {
                    this.setState({
                      uniflag: 0,
                      uniList: this.state.charityList
                    });
                  }}
                  style={{
                    background:
                      this.state.uniflag === 0 ? 'rgba(40,40,40,1)' : ''
                  }}
                  className={styles.tab1}
                >
                  X 芭莎 区块链慈善焦点事件
                </p>
                <p
                  onClick={() => {
                    this.setState({
                      uniflag: 1,
                      uniList: this.state.sceneList.slice(0, 10)
                    });
                  }}
                  style={{
                    background:
                      this.state.uniflag === 1 ? 'rgba(40,40,40,1)' : ''
                  }}
                  className={styles.tab2}
                >
                  X 大公司 区块链最佳落地场景
                </p>
                {/* 联合榜单内容 */}
                <div className={styles.unionTopic}>
                  <div className={styles.postbox}>
                    {this.renderUnilist()}
                    {this.state.uniflag ? (
                      <p className={styles.more}>
                        <a href="/activityDetail">查看更多 ＞</a>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/*特别榜*/}
            <div className={styles.userWrapper}>
              <div className={styles.userBox}>
                <div className={styles.hotPost}>
                  <div className={styles.rankNav}>
                    <div className={styles.navLeft}>
                      <h3>特别榜</h3>
                    </div>
                  </div>
                </div>
                {/*机构*/}
                <div className={styles.bestBox}>
                  <h3>最具前瞻力区块链投资机构 </h3>
                  <p className={styles.title}>
                    他们是区块链的全能者，他们比项目方更懂趋势，比生态更懂技术、比媒体更懂营销，比所有人更理解长期陪跑的价值，更他们拥有着笃定现实的能力与着眼未来的能力。
                  </p>
                  <div className={styles.items}>
                    {this.returnOrganization()}
                  </div>
                </div>
                {/*矿业*/}
                <div className={styles.bestBox}>
                  <h3>最具价值矿业品牌</h3>
                  <p className={styles.title}>
                    矿业是区块链行业的中流砥柱，和实体资产所在，也将是整个行业真正“第一股”诞生的领域。今天，“挖矿”已经成为一个“流程化”、“产业化”、“金融化”的专业领域，越来越多专业资金和人才跑步入场，我们将评选出推动“挖矿新纪元”的十大矿业品牌。
                  </p>
                  <div className={styles.items}>{this.returnIndustry()}</div>
                </div>
                <h4>90位90后杰出从业者</h4>
                <p className={styles.speDesc}>
                  几乎是一夜之间，区块链重回主流视野。区块链的竞争法则正在发生质的改变。醉心于制造热潮的风口主义者和拘泥于传统的跟随者已经不能满足这个时代的要求。
                </p>
                <p className={styles.speDesc}>
                  {' '}
                  区块链的新时代呼唤着新领袖出现，他们能在纷繁环境中找到钥匙，依靠强有力的行动，带领公司构建中国区块链新标杆。
                </p>
                {/*90个人物*/}
                <div className={`${styles['users']}`}>
                  {this.renderUserItem()}
                </div>
                <p className={styles.btn} onClick={this.changeBtn.bind(this)}>
                  {this.state.flag ? '展开' : '收起'}{' '}
                  <span
                    className={
                      this.state.flag ? `${styles['down']}` : `${styles['up']}`
                    }
                  />
                </p>
              </div>
            </div>
            {/* topic入口 */}
            <div className={styles.lastTopicbox}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundImage: `url(${this.state.topic.cover})` }}
                className={styles.lastTopic}
                href="/topic/643"
              >
                <div className={styles.mask} />
                <div className={styles.content}>
                  <h4 className={styles.title}>{this.state.topic.title}</h4>
                  <p className={styles.summary}>{this.state.topic.summary}</p>
                </div>
              </a>
            </div>
          </div>

          {/*footer*/}
          <div className={styles.footer}>
            <div className={styles.footerBox}>
              <div className={styles.footerLeft}>
                <div className={styles.friendLeft}>
                  <h4>合作伙伴</h4>
                  <div className={styles.friendItem}>
                    {this.renderFriend4()}
                  </div>
                </div>
                <div className={styles.friendRight}>
                  <h4>合作媒体</h4>
                  <div className={styles.friendItem}>
                    {this.renderFriend1()}
                    {this.renderFriend2()}
                    {this.renderFriend3()}
                  </div>
                </div>
              </div>
              <div className={styles.footerRight}>
                <h4>商务合作</h4>
                <p>联系方式：13811513856</p>
                <p>联系方式：18515575360</p>
                <img src={wecode1} alt="" loading="lazy" />
                <img src={wecode2} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default withRouter(Fat);
