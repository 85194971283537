import { combineReducers } from 'redux';

const keywords = (state = '', action) => {
  switch (action.type) {
    case 'SET_SEARCH_KEYWORDS':
      return action.keywords;
    default:
      return state;
  }
};

const hotkey = (state = [], action) => {
  switch (action.type) {
    case 'SET_HOT_KEY':
      return action.list;
    default:
      return state;
  }
};

const post = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEARCH_POST':
      return action.list;
    default:
      return state;
  }
};

const total = (
  state = {
    monographic: { total_count: 0 },
    post: { total_count: 0 },
    newsflash: { total_count: 0 },
    user: { total_count: 0 },
    video: { total_count: 0 }
  },
  action
) => {
  switch (action.type) {
    case 'SET_SEARCH_TOTAL':
      return action.list;
    default:
      return state;
  }
};
const newsflash = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEARCH_NEWSFLASH':
      return action.list;
    default:
      return state;
  }
};
const video = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEARCH_VIDEO':
      return action.list;
    default:
      return state;
  }
};
const user = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEARCH_USER':
      return action.list;
    default:
      return state;
  }
};
const monographic = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEARCH_MONOGRAPHIC':
      return action.list;
    default:
      return state;
  }
};
const pages = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SEARCH_PAGES':
      return action.data;
    default:
      return state;
  }
};

const projectList = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEARCH_PROJECT_LIST':
      return action.data;
    default:
      return state;
  }
};

const projectPage = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SEARCH_PROJECT_PAGE':
      return action.page;
    default:
      return state;
  }
};

const institutionList = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEARCH_INSTITUTION_LIST':
      return action.data;
    default:
      return state;
  }
};

const institutionPage = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SEARCH_INSTITUTION_PAGE':
      return action.page;
    default:
      return state;
  }
};

export default combineReducers({
  post,
  newsflash,
  video,
  user,
  keywords,
  pages,
  monographic,
  total,
  hotkey,
  projectList,
  projectPage,
  institutionList,
  institutionPage
});
