import React, { Component } from 'react';
import styles from '../../index.scss';

export class InfoShareKo extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>정보 공유 체크리스트</h5>
        <p>
          당사의 개인 정보 공유에 대한 명확한 이해를 돕기 위해 다음 목록을
          공식화했습니다.
        </p>
        <h6>1. 타사 SDK 목록</h6>
        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>공유하다</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>위챗</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>
                사용자가 WeChat에 콘텐츠를 공유할 수 있도록 타사 로그인 기능을
                사용자에게 제공합니다.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목명</p>
            </td>
            <td>
              <p>심천 Tencent 컴퓨터 시스템 Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>공유하다</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>시나</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>
                사용자가 Sina에 콘텐츠를 공유할 수 있도록 타사 로그인 기능을
                사용자에게 제공
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목명</p>
            </td>
            <td>
              <p>북경 Weimen Chuangke Network Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>400-096-0960</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://weibo.com/signup/v5/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://weibo.com/signup/v5/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>밀어</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>푸시</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>푸시 관련 정보 콘텐츠</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>(주)데일리인터랙티브</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>support@getui.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://docs.getui.com/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.getui.com/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>밀어</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>오로라</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>푸시 관련 정보 콘텐츠</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>심천 Hexun Huagu 정보 기술 Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>sales@jiguang.cn</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.jiguang.cn/license/privacy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.jiguang.cn/license/privacy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>밀어</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>화웨이</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>푸시 관련 정보 콘텐츠</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>화웨이 소프트웨어 기술 유한 회사</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>밀어</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>기장</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>푸시 관련 정보 콘텐츠</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>샤오미 모바일 소프트웨어 주식회사</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.mi.com/support"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.mi.com/support
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://dev.mi.com/console/doc/detail?pId=1822"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://dev.mi.com/console/doc/detail?pId=1822
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>밀어</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>OPPO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>푸시 관련 정보 콘텐츠</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>광동 Huantai 기술 Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>
                <a
                  href="https://brand.heytap.com/privacy-feedback.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://brand.heytap.com/privacy-feedback.html
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://open.oppomobile.com/wiki/doc#id=10288"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://open.oppomobile.com/wiki/doc#id=10288
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>밀어</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>vivo</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>푸시 관련 정보 콘텐츠</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>비보모바일커뮤니케이션㈜</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>privacy@vivo.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.vivo.com.cn/about-vivo/privacy-policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>위치</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>금</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>사용자에게 지역 정보 콘텐츠 제공</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>기기 정보, 위치 권한 아래 GPS 정보 활성화</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>Gaode 소프트웨어 Co., 주식 회사</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>Privacy.Amap@service.autonavi.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://lbs.amap.com/pages/privacy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://lbs.amap.com/pages/privacy/
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>원클릭 로그인</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>차이나 모바일</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>사용자에게 인증코드 없는 로그인 서비스 제공</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>기기 정보, 지역 번호</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>중국이동통신그룹유한공사</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>10086</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="http://dev.10086.cn/docInside?contentId=10000009826805"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  http://dev.10086.cn/docInside?contentId=10000009826805
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>공유하다</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>QQ</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>사용자가 ODAILY 콘텐츠를 QQ에 공유하도록 지원</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>심천 Tencent 컴퓨터 시스템 Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>통계</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>동맹국</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>데이터 통계 분석</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목명</p>
            </td>
            <td>
              <p>Youmeng Tongxin (Beijing) Technology Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>Umeng_Legal@service.umeng.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.umeng.com/page/policy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.umeng.com/page/policy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>통계</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>화웨이</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>데이터 통계 분석</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>화웨이 소프트웨어 기술 유한 회사</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>
                <a
                  href="https://consumer.huawei.com/cn/legal/privacy-questions/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://consumer.huawei.com/cn/legal/privacy-questions/
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://developer.huawei.com/consumer/cn/devservice/term"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://developer.huawei.com/consumer/cn/devservice/term
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>로그 수집</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>bugly</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>문제를 찾을 수 있도록 앱 충돌 정보를 수집합니다.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목 이름</p>
            </td>
            <td>
              <p>심천 Tencent 컴퓨터 시스템 Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>Dataprivacy@tencent.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://privacy.qq.com/policy/tencent-privacypolicy"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://privacy.qq.com/policy/tencent-privacypolicy
                </a>
              </p>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td>
              <p>기능 유형</p>
            </td>
            <td>
              <p>앱의 원 클릭 설치</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SDK 이름</p>
            </td>
            <td>
              <p>openinstall</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>사용 목적</p>
            </td>
            <td>
              <p>
                사용자가 빠른 점프와 원클릭 다운로드 및 앱 설치를 실현할 수
                있도록 지원
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 필드</p>
            </td>
            <td>
              <p>장치 정보</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>처리 방법</p>
            </td>
            <td>
              <p>비식별화, 암호화 및 기타 전송 및 처리 방법 채택</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>공유 방식</p>
            </td>
            <td>
              <p>SDK 네이티브 컬렉션</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>과목명</p>
            </td>
            <td>
              <p>심천 Fenmiao 네트워크 기술 Co., Ltd.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>연락처 정보</p>
            </td>
            <td>
              <p>cooperation@openinstall.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>개인 정보 정책</p>
            </td>
            <td>
              <p>
                <a
                  href="https://www.openinstall.io/privacy.html"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://www.openinstall.io/privacy.html
                </a>
              </p>
            </td>
          </tr>
        </table>

        <h6>2. 제3자 협력기관 목록</h6>
        <p>
          당사는 당분간 귀하의 개인 정보를 제3자 파트너와 공유하지 않으며, 위와
          같은 상황이 발생하면 이 목록을 업데이트하고 귀하의 승인 및 동의를 얻을
          것입니다.
        </p>

        <h6>3. 계열사 목록</h6>
        <p>
          당사는 귀하의 개인 정보를 당분간 제휴사와 공유하지 않으며, 위와 같은
          상황이 발생할 경우 이 목록을 업데이트하고 귀하의 승인 및 동의를 받을
          것입니다.
        </p>
      </div>
    );
  }
}

export default InfoShareKo;
