// eslint-disable-next-line react/display-name
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Helmet from 'react-helmet';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import { PostDetail } from '../../components/post';
import Share from '../../components/newsflash/share';
import PostRecommend from '../../components/post/recommend';
import NewAuthor from '../../components/NewAuthor';
import AdPicture from '../../components/adPicture';
import { RePost } from '../../components/recommendPost';
import { HotPostItem } from '../../components/recommendPost/item';
import Sticky from '../../components/common/sticky';
import DownLoad from '../../components/download';

import { getHelmet } from '../../common/scripts/helmet';
// import wechatShare from '../../common/scripts/wechat';

import {
  getPostPreviewById
  // secondaryTag
  // getSecondaryTagList
} from '../../common/api/post';
import { getUserInfo, getUserPosts } from '../../common/api/user';
import { getSecondaryList } from '../../common/api/columns';
import { getAdvert } from '../../common/api/slide';
import { getTopPost } from '../../common/api/found';
import { getFeedsByChannelId } from '../../common/api/feeds';

export class PostPreview extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    postDetail: PropTypes.object.isRequired,
    postUser: PropTypes.object.isRequired,
    postCounters: PropTypes.object.isRequired,
    userPostList: PropTypes.array.isRequired,
    articleList: PropTypes.array.isRequired,
    feedList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    advertBottom: PropTypes.array,
    advertFeed: PropTypes.array,
    topPost: PropTypes.array.isRequired
  };

  static fetchData(store, match, res) {
    return new Promise(resolve => {
      const postId = match.params.id;
      let userId = '';
      let secondary_tag = [];
      const lang = match.params.lang;
      const postHref = lang ? `/${lang}/404` : `/404`;
      getPostPreviewById(postId, lang)
        .then(data => {
          const postData = _.get(data, 'data');
          userId = _.get(postData, 'user_id');
          store.dispatch({
            type: 'SET_POST_DETAIL',
            title: postData.title,
            cover: postData.cover,
            published_at: postData.updated_at,
            user: postData.user,
            summary: postData.summary,
            content: postData.content,
            extraction_tags: postData.extraction_tags,
            source_type: postData.source_type,
            source_urls: postData.source_urls,
            secondary_tag: postData.secondary_tag
              ? JSON.parse(postData.secondary_tag)
              : []
          });

          secondary_tag = postData.secondary_tag
            ? JSON.parse(postData.secondary_tag)
            : [];
          return getUserInfo(userId, lang);
        })
        .then(data => {
          const userData = _.get(data, 'data');
          store.dispatch({
            type: 'SET_POST_USER',
            id: userData.id,
            avatar: userData.avatar_url,
            name: userData.name,
            introduction: userData.introduction,
            title: userData.title,
            total: userData.counters.post_create
          });
          // resolve();
          return getAdvert('home_advertisement_bottom', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_BOTTOM',
            list: list
          });
          return getAdvert('home_advertisement_feed', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_FEED',
            list: list
          });
          return getTopPost();
        })
        .then(data => {
          const postList = data.data.items;
          store.dispatch({
            type: 'SET_TOPPOST',
            list: postList
          });
          return getUserPosts(userId, undefined, undefined, undefined, lang);
        })
        .then(data => {
          const list = data.data.items.data;
          store.dispatch({
            type: 'SET_USER_POST',
            list: list
          });
          return getFeedsByChannelId(280, '', 20, lang);
        })
        .then(data => {
          let list = data.data.items.filter(item => {
            return item.entity_type === 'post';
          });
          store.dispatch({
            type: 'SET_POST_FEEDLIST',
            list
          });

          let slug = secondary_tag.filter(item => {
            return !!item.slug;
          });
          let slug_strs = '';
          if (Array.isArray(slug) && slug.length > 0) {
            let slug_arr = [];
            for (let key in slug) {
              slug_arr.push(slug[key]['slug']);
            }
            slug_strs = slug_arr.join(',');
            return getSecondaryList(slug_strs, 1, 10, lang);
          } else {
            resolve();
          }
        })
        .then(data => {
          if (data) {
            const list = data.data.items.filter(item => {
              return item.id != postId;
            });
            store.dispatch({
              type: 'SET_POST_ARTICLELIST',
              list: list
            });
          }
          resolve();
        })
        .catch(error => {
          if (error.code === 22000) {
            res.redirect(postHref);
          }
        });
    });
  }

  static defaultProps = {
    advertFeed: [],
    advertBottom: []
  };

  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { postDetail } = this.props;
    let slug = postDetail.secondary_tag.filter(item => {
      return !!item.slug;
    });
    let slug_strs = '';
    if (Array.isArray(slug) && slug.length > 0) {
      let slug_arr = [];
      for (let key in slug) {
        slug_arr.push(slug[key]['slug']);
      }
      slug_strs = slug_arr.join(',');
      getSecondaryList(slug_strs, 1, 10);
    }
  }

  getHotPosts(value) {
    this.setState(
      {
        postType: value.value
      },
      () => {
        this.getTopPost();
      }
    );
  }

  getTopPost() {
    const { postType } = this.state;
    getTopPost(postType)
      .then(data => {
        this.props.dispatch({
          type: 'SET_TOPPOST',
          list: data.data.items
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  renderShareBar() {
    const { postDetail } = this.props;
    // let { favourCount, isFavour, isCollected } = this.state;

    return (
      <div className={styles['share-bar']}>
        <Share title={postDetail.title} />
      </div>
    );
  }

  renderPostHot() {
    const postList = this.props.topPost;

    if (postList.length > 0) {
      return postList
        .filter((item, index) => {
          return index < 5;
        })
        .map((item, index) => {
          const props = Object.assign(item, {
            type: 'small',
            sort: index + 1
          });
          return <HotPostItem {...props} key={index} />;
        });
    }
  }

  render() {
    const {
      postDetail,
      advertFeed,
      articleList,
      postUser,
      userPostList,
      advertBottom,
      feedList,
      match
    } = this.props;
    let helmetData = getHelmet({
      title: `${postDetail.title}`,
      description: `${postDetail.title}_${postDetail.summary}`,
      keywords: `${postDetail.title},区块链项目,区块链开发`,
      twitterTitle: `${postDetail.title}`,
      twitterDescription: `${postDetail.summary}`,
      ogTitle: `${postDetail.title}`,
      ogDescription: `${postDetail.summary}`,
      ogUrl: `${process.env.ODAILY}${match.url}`
    });
    const postId = this.props.match.params.id;

    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.container_wrapper}>
            <div className={styles['container_left']}>
              <PostDetail
                {...postDetail}
                secondary={postDetail.secondary_tag}
                type="preview"
              />

              {/* 广告 */}
              {advertFeed &&
              advertFeed.length > 0 &&
              advertFeed[0].url &&
              advertFeed[0].cover ? (
                <div className={styles.advertFeed}>
                  <AdPicture
                    width="840px"
                    height="105px"
                    url={advertFeed[0].url}
                    img={advertFeed[0].cover}
                  />
                </div>
              ) : (
                ''
              )}

              <div
                className={styles.share}
                // style={{ position: 'sticky', bottom: show ? '0px' : '-66px' }}
              >
                {this.renderShareBar()}
              </div>

              <div className={styles.container_left_border} />

              {(articleList && articleList.length) ||
              (feedList && feedList.length) ? (
                <div className={styles.postRecommend}>
                  <PostRecommend
                    id={postId}
                    articlelist={articleList}
                    feedList={feedList}
                    tags={postDetail.secondary_tag}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={styles['container_right']}>
              <div className={styles.author}>
                <NewAuthor
                  {...postUser}
                  postid={postId}
                  userPostList={userPostList}
                />
              </div>

              {/* 文章热榜 */}
              <Sticky styleData={{ marginTop: '20px' }}>
                <div
                  className={styles.RePost}
                  style={{
                    top:
                      advertBottom &&
                      advertBottom.length > 0 &&
                      advertBottom[0].url &&
                      advertBottom[0].cover
                        ? null
                        : '15px'
                  }}
                >
                  <RePost
                    getHotPosts={this.getHotPosts.bind(this)}
                    title="文章热榜"
                  >
                    {this.renderPostHot()}
                  </RePost>
                </div>

                {/* 首页边栏-底部 */}
                {advertBottom &&
                advertBottom.length > 0 &&
                advertBottom[0].url &&
                advertBottom[0].cover ? (
                  <div className={styles.advertMiddle}>
                    <AdPicture
                      width="340px"
                      height="226px"
                      url={advertBottom[0].url}
                      img={advertBottom[0].cover}
                    />
                  </div>
                ) : (
                  ''
                )}

                <DownLoad />
              </Sticky>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    postDetail: state.post.detail,
    postUser: state.post.user,
    postCounters: state.post.counters,
    postSecondaryTag: state.post.secondaryTag,
    userPostList: state.post.userPostList,
    articleList: state.post.articleList,
    feedList: state.post.feedList,
    advertBottom: state.home.advertBottom,
    advertFeed: state.home.advertFeed,
    topPost: state.home.topPost
  };
};

export default withRouter(connect(mapStateToProps)(PostPreview));
