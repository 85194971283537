import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import animate from 'animate.css';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';
export class RePost extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    right: PropTypes.element,
    children: PropTypes.any,
    getHotPosts: PropTypes.func.isRequired,
    match: PropTypes.object
  };
  state = {
    list: [
      { id: 1, name: 'daily', value: 'day' },
      { id: 2, name: 'weekly', value: 'week' }
    ],
    current: 2,
    status: 'fadeIn'
  };
  tabClick(item) {
    this.setState(
      {
        current: item.id
      },
      () => {
        this.props.getHotPosts(item);
      }
    );
  }
  renderTab() {
    const lang = this.props.match.params.lang;
    return this.state.list.map(item => {
      const active = this.state.current === item.id ? styles['active'] : '';

      return (
        <div
          key={item.id}
          className={`${styles['tabs-tab']} ${active}`}
          onClick={() => this.tabClick(item)}
        >
          <p className={styles['tab-text']}>{locales(lang, item.name)}</p>
        </div>
      );
    });
  }
  handleAnimationEnd = () => {
    this.setState({
      status: ''
    });
  };
  render() {
    const lang = this.props.match.params.lang;
    return (
      <div className={styles.container}>
        <header className={styles.header}>
          <p className={styles.title}>
            {locales(lang, 'pArticles', { isTitle: true })}
          </p>
          <div className={styles.headerBox}>{this.renderTab()}</div>
        </header>
        <section
          className={`${animate.animated} ${animate[this.state.status]}`}
          onAnimationEnd={this.handleAnimationEnd}
        >
          {this.props.children}
        </section>
      </div>
    );
  }
}
export default withRouter(RePost);
