// import { combineReducers } from 'redux';

const feeds = (state = {}, action) => {
  const { channelId, list } = action;

  switch (action.type) {
    case 'SET_FEEDS':
      return {
        ...state,
        [channelId]: list
      };
    default:
      return state;
  }
};

export default feeds;
