import { combineReducers } from 'redux';

const voteList = (state = {}, action) => {
  switch (action.type) {
    case 'SET_VOTE_LIST':
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

const activityList = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_LIST':
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

const slideList = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_SLIDE':
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

export default combineReducers({ voteList, activityList, slideList });
