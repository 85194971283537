import React from 'react';
import Helmet from 'react-helmet';
import styles from './index.scss';
import Layout from '../../components/common/layout';
import PropTypes from 'prop-types';
import { getHelmet } from '../../common/scripts/helmet';
import { withRouter } from 'react-router-dom';
import locales from '../../utils/locales';

export class TechnicalSupport extends React.Component {
  static propTypes = {
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let helmetData = getHelmet();
    const { lang } = this.props.match.params;
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.TechnicalSupport}>
          <div className={styles['post-title']}>
            ODAILY - {locales(lang, 'technical_support')}
          </div>
          <div className={styles['post-content']}>
            <p>
              <strong>{locales(lang, 'dear_friends')}：</strong>
              <br />
            </p>
            <p>
              <strong>
                &nbsp; &nbsp; &nbsp;
                {locales(lang, 'if_you_have_any_questions_about_odaily')}
              </strong>
            </p>
            <p>
              &nbsp; &nbsp; &nbsp; &nbsp;{' '}
              <strong>{locales(lang, 'service_hours')}：</strong>10:00 -&nbsp;
              19:00 CST
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>
                {locales(lang, 'contact_number')}
                <span>：</span>
              </strong>
              <span>&nbsp;010-84700825&nbsp; &nbsp;&nbsp;</span>
            </p>
            <p>
              <span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <strong> {locales(lang, 'contact_email')}[Email]：</strong>{' '}
                tech_master@odaily.email 、<span>om@odaily.email</span>
              </span>
            </p>
            <p>
              <span>
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; <strong>微信[WeChat]：</strong>
                  <span>Odaily2018</span>
                </span>
              </span>
            </p>
            <p>
              <span>
                &nbsp; &nbsp; &nbsp; &nbsp; <strong>QQ :</strong>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  target="_blank"
                  href="http://wpa.qq.com/msgrd?v=3&uin=792963711&site=qq&menu=yes"
                  rel="noopener noreferrer"
                >
                  <img
                    className={styles.qqimg}
                    border="0"
                    src="http://wpa.qq.com/pa?p=2:792963711:52"
                    alt="点击这里给我发消息"
                    title="点击这里给我发消息"
                    loading="lazy"
                  />
                </a>
              </span>
            </p>
            <p>
              <span>
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; <strong>Discord:&nbsp;</strong>{' '}
                  &nbsp;<a href={process.env.DISCORD}>{process.env.DISCORD}</a>{' '}
                </span>
              </span>
            </p>
            <p>
              <span>
                <br />
              </span>
            </p>
            <p>
              <strong>关于网站[About site]</strong>：
            </p>
            <p>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <strong>应用权限[Application permission]：</strong>&nbsp;
              &nbsp;&nbsp;
              <a href="https://www.odaily.news/aboutus?application-authority">
                https://www.odaily.news/aboutus?application-authority
              </a>{' '}
            </p>
            <p>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <strong>&nbsp; 隐私条款[Privacy clause]：</strong>&nbsp;
              &nbsp;&nbsp;
              <a href="https://www.odaily.news/aboutus?privacy">
                https://www.odaily.news/aboutus?privacy
              </a>{' '}
            </p>
            <p>
              <br />
            </p>
            <p>
              <img
                src="https://piccdn.0daily.com/202204/12034004/4case99k0g5z34hm.png"
                data-img-size-val="370,800"
                loading="lazy"
              />
            </p>
            <p>
              <img
                src="https://piccdn.0daily.com/202204/12034005/es0knnfmwz25aqmn.png"
                data-img-size-val="370,800"
                loading="lazy"
              />
            </p>
            <p>
              <img
                src="https://piccdn.0daily.com/202204/12034004/a1lm1k1hwuw5fejf.png"
                data-img-size-val="370,800"
                loading="lazy"
              />
            </p>
            <p>
              <img
                src="https://piccdn.0daily.com/202204/12034005/xe2kym4dxh9ky9ym.png"
                data-img-size-val="370,800"
                loading="lazy"
              />
            </p>
            <p>
              <img
                src="https://piccdn.0daily.com/202204/12034007/8hatadk8ymo6ut3h.png"
                data-img-size-val="370,800"
                loading="lazy"
              />
            </p>
            <p>
              <img
                src="https://piccdn.0daily.com/202204/12034007/oa0qnwaf80z1ej8s.png"
                data-img-size-val="370,800"
                loading="lazy"
              />
            </p>
            <p>
              <img
                src="https://piccdn.0daily.com/202204/12034005/j0yi14xypxu5zkon.png"
                data-img-size-val="370,800"
                loading="lazy"
              />
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(TechnicalSupport);
