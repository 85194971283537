import { isFunction } from 'lodash';
import LocaleFont from '../components/common/localeFont';
import React from 'react';

const translations = {
  en: {
    timeHour: 'hours ago',
    timeJust: 'just',
    timeMinites: 'minutes ago',
    newsflashTitle:
      'Blockchain 7 x 24 News_Blockchain Project News_Metaverse_web3-odaily',
    helmetKeywords:
      'Chain games, metaverse, nft, bitcoin, blockchain, btc, eth, Dogecoin, airdrop, web3 wallet, ai, web3 news media, web3 information, blockchain news, blockchain technology',
    navagationTitle: 'Web3 comprehensive tool navigation',
    loginUpdateSuccess: 'update completed',
    loginEmptyPhoneTip: 'The mobile phone number cannot be empty',
    loginRightPhoneNOTip: 'please enter the correct mobile phone number',
    loginEmptyVerifyCodeTip: 'the verification code cannot be empty',
    loginErrorVerifyCodeTip:
      'the verification code is wrong,please re-enter the password cannot be empty',
    enterPwd1:
      'please enter a 6-16-digit password and special characters cannot be entered',
    loginEmptyPasswordTip: 'please re-enter the password cannot be empty',
    information: 'Information',
    flash: 'Flash',
    special: 'Column',
    activity: 'Event',
    navigation: 'Navigation',
    search: 'Search',
    download: 'Download App',
    signin: 'Sign in',
    seeMore: 'See More',
    nomore: 'No More',
    loading: 'Loading...',
    daily: 'Daily',
    weekly: 'Weekly',
    verifLogin: 'Captcha login',
    verification: 'Verif',
    account: 'Account Login',
    sendVerif: 'Send code',
    downloadOdaily: 'Download Odaily app',
    autoLogin: 'Auto-login after phone verification',
    agree: 'Agree to terms and privacy policy',
    otherLogin: 'Other options',
    pwdLogin: 'Password login',
    forgotPwd: 'Forgot password?',
    save: 'Save',
    enterPwd: '6-16 Character Password',
    enterPhone: 'Enter phone number',
    enterVerif: 'Enter verification',
    pSearch: 'Popular searches',
    pArticles: 'Must Read',
    latestNews: 'Latest news',
    selected: 'Selected activities',
    more: 'More',
    help: 'Entering Web3.0 ahead of Others',
    aboutUs: 'About Us',
    privacyPolicy: 'Privacy Policy',
    contactUs: 'Contact Us',
    userAgreement: 'User Agreement',
    joinUs: 'Join Us',
    links: 'Links',
    disclaimer: 'Disclaimer',
    partners: 'Partners',
    followUs: 'Follow Us',
    joinCommunity: 'Join community',
    allActivities: 'All Activities',
    appDownload: 'Download',
    publicAccount: 'WeChat Account',
    rec_Topics: 'Recommended Topics',
    bus_Coop: 'Business Cooperation',
    live_Cons: 'Live Consultation',
    acc_Login: 'Login with Account and Password',
    user_Agre: '《User Service Agreement》',
    privacy: '《Privacy Policy》',
    enter_KW: 'Please enter keywords',
    ended: 'Ended',
    planet: 'Editor’s Picks',
    rec_Tags: 'Tags',
    feat_Topic: 'Topic',
    sel_Persp: 'KOL’s Views',
    next_Art: 'Next Article',
    newsflash: '7×24h Newsflash',
    page_Rfr: 'Page refreshes every 30 seconds after enabling',
    orig_Link: 'Original Article Link',
    reset_Pwd: 'Reset Password',
    load_More: 'Load More',
    welc_Odaily: 'Welcome to join the official community of Odaily',
    telegram_SG: 'Telegram Subscription Group：',
    telegram_CG: 'Telegram Communication Group：',
    twitter: 'Official Twitter Account：',
    scan: 'Scan with WeChat',
    view_all: 'View All',
    source: 'Source',
    related_articles: 'Related Articles',
    related_links: 'Related Links',
    share_article: 'Share this Article',
    article: 'Article',
    event_intro: 'Event Introduction',
    featured_articles: 'Featured Articles',
    all_articles: 'All Articles',
    recommended_reading: 'Latest News',
    recommended_news: 'Trendy News',
    link_phone_number: 'Link Phone Number',
    library: 'Library',
    account_settings: 'Account Settings',
    video: 'Video',
    video_recommend: 'Video',
    personal_center: 'Personal Center',
    jump_to_backend: 'Jump to Backend',
    logout: 'Logout',
    my_collection: 'My Collection',
    recommended_library: 'Recommended Library',
    create_library: 'Create Library',
    new_library: 'New Library',
    delete_library: 'Delete Library',
    library_name: 'Library Name',
    library_cover: 'Library Cover',
    library_Intro: 'Library introduction',
    optional: 'Optional',
    complete: 'Complete',
    basic_information: 'Basic Information',
    bind_third_party_account: 'Bind Third-Party Account',
    change_password: 'Change Password',
    change_phone_number: 'Change Phone Number',
    no_related_content: 'No related content',
    edit: 'Edit',
    creation_time: 'Creation Time',
    collected: 'Collected',
    collect: 'Collect',
    delete: 'Delete',
    cancel: 'Cancel',
    loading_title: 'Loading',
    confirm: 'Confirm',
    enter_1_20_characters: 'Please enter 1-20 characters',
    confirm_delete_library: 'Are you sure you want to delete this library?',
    delete_collection_folder:
      'Deleting the collection folder will also delete the content inside',
    odaily_explore_real_blockchain: 'ODAILY-Explore the Real Blockchain',
    explore_real_blockchain: 'Explore the Real Blockchain',
    share_successfully: 'Share Successfully',
    your_browser_version_is_too_low: 'Your browser version is too low',
    upgrade_your_ie_browser:
      'To have a better operating experience, please upgrade your IE browser',
    technical_support: 'Technical Support',
    hello: 'Hello',
    if_you_have_any_questions_about_odaily:
      'If you have any questions about ODAILY, please contact our staff during the following service hours. We will solve your problem as soon as possible and reply to you. Thank you for your support.',
    dear_friends: 'Dear Friends',
    service_hours: 'Service Hours',
    contact_number: 'Contact Number',
    contact_email: 'Contact Email',
    wechat: 'WeChat',
    more_activities: 'More Activities',
    all_activities: 'All Activities',
    event_description: 'Event Description',
    current_votes: 'Current Votes',
    vote: 'Vote',
    go_to_vote: 'Go to Vote',
    voted: 'Voted',
    disclaimer_title:
      'Disclaimer: This article comes from a submission and does not represent the stand of ODAILY. If reproduced, please indicate the source.',
    odaily_reminder: 'ODAILY Reminder:',
    please_establish_correct_concepts:
      'Please establish correct concepts about money and investment, rationally look at blockchain, and effectively improve risk awareness; if you find any clues of illegal activities, you can actively report to relevant departments.',
    latest: 'Latest Articles',
    share: 'Share',
    copyLink: 'Copy Link',
    weibo: 'Weibo',
    mobile: 'Mobile',
    save_library: 'Save to Library',
    edit_library: 'Edit Library',
    select: 'Select Library',
    selectToAdd: 'Please select the library you want to add',
    default: 'Default',
    krExclusive: '36Kr Exclusive Strategic Blockchain Media',
    upgrade: 'Upgrade',
    scanQRCode: 'Scan QR Code on WeChat',
    click_screen: 'Click the screen',
    click_share: 'Click the "share" button in the upper right corner',
    shareTo: 'Share to',
    notStarted: 'Not started',
    ongoing: 'Ongoing',
    featured: 'Featured',
    all: 'All',
    kx_hot: 'Hot Topic',
    author: 'Author',
    expand: 'Expand',
    retracta: 'Retracta',
    pw_text1: 'Current password',
    pw_text2: 'New password',
    pw_text3:
      'Please enter an 8-16 digit password, including letters and numbers',
    pw_text4: 'Forgot password',
    set_pw: 'Set a password',
    set_succ: 'Successful setting',
    backHome: 'Back to home page',
    backPrev: 'Return to upper level',
    home: 'Home page',
    errText1: 'Oops, the page you are looking for is missing!',
    errText2: 'Go to other pages!',
    errText3: 'After the automatic jump',
    noResult: function NoResult(keywords) {
      return (
        <div>
          Sorry, no results found for {keywords}. <br />
          Please try a different search term.
        </div>
      );
    },
    project: 'Project',
    institution: 'Institution',
    results: function Result(num) {
      return <div>{num} results found</div>;
    },
    daily_market: 'Market Trends',
    market_page_add_success: 'Add Success',
    market_page_cancel_success: 'Cancel Success',
    unit_wan: 'M',
    unit_yi: 'B',
    market_page_tabbar_auto: 'Favorites',
    market_page_tabbar_market: 'Spot',
    market_page_rank_up: 'Change',
    market_page_rank_new: 'Newest',
    market_page_rank_deal: 'Volume',
    market_page_table_title_symbol: 'Pair',
    market_page_table_title_price: 'Last Price',
    market_page_table_title_up_rate: 'Change',
    market_page_table_title_rate: 'Change',
    market_page_table_title_low_price: 'Low',
    market_page_table_title_high_price: 'High',
    market_page_table_title_vol_24h: '24h Vol',
    market_page_table_title_market_value: 'Market',
    market_page_table_title_kline_7d: 'Last 7d',
    market_page_table_title_vol: 'Vol'
  },
  zhcn: {
    timeHour: '小时前',
    timeMinites: '分钟前',
    timeJust: '刚刚',
    newsflashTitle: '区块链技术_元宇宙新闻_web3资讯-odaily',
    helmetKeywords:
      '链游,元宇宙,nft,比特币,区块链,btc,eth,狗狗币,空投,web3钱包,ai,web3新闻媒体,web3资讯,区块链新闻,区块链技术',
    navagationTitle: 'Web3综合工具导航',
    loginUpdateSuccess: '更新成功',
    loginEmptyPhoneTip: '手机号不为空',
    loginRightPhoneNOTip: '请输入正确的手机号',
    loginEmptyVerifyCodeTip: '验证码不能为空',
    loginErrorVerifyCodeTip: '验证码错误，请重新输入',
    enterPwd1: '请输入6-16位密码，不能输入特殊字符',
    loginEmptyPasswordTip: '密码不能为空',
    information: '资讯',
    flash: '快讯',
    special: '专题',
    activity: '活动',
    navigation: '导航',
    search: '搜索',
    download: '下载App',
    signin: '登录',
    seeMore: '查看更多',
    nomore: '没有更多了',
    loading: '正在努力加载',
    daily: '日榜',
    weekly: '周榜',
    expand: '展开',
    retracta: '收起',
    verifLogin: '验证码登录',
    verification: '验证码',
    account: '账号登录',
    sendVerif: '发送验证码',
    downloadOdaily: '下载Odaily星球日报app',
    autoLogin: '未注册手机验证后自动登录',
    agree: '注册即代表我已阅读并同意',
    otherLogin: '其他登录方式',
    pwdLogin: '密码登录',
    forgotPwd: '忘记密码？',
    save: '保存',
    enterPwd: '请输入6-16位密码',
    enterPhone: '请输入手机号',
    enterVerif: '请输入验证码',
    pSearch: '热门搜索',
    pArticles: '文章热榜',
    latestNews: '最新快讯',
    more: '更多',
    backHome: '返回首页',
    backPrev: '返回上一级',
    home: '首页',
    errText1: '糟糕，您访问的页面失联啦！',
    errText2: '去其他页面转转吧！',
    errText3: '后自动跳转到',
    help: '让一部分人先读懂 Web3.0',
    aboutUs: '关于我们',
    privacyPolicy: '隐私条款',
    contactUs: '联系我们',
    userAgreement: '用户协议',
    joinUs: '加入我们',
    links: '友情链接',
    disclaimer: '免责声明',
    partners: '合作伙伴',
    followUs: '关注我们',
    joinCommunity: '加入社群',
    allActivities: '全部活动',
    appDownload: 'APP下载',
    publicAccount: '公众号',
    rec_Topics: '推荐专题',
    bus_Coop: '商务合作',
    live_Cons: '直播咨询',
    acc_Login: '账号密码登录',
    user_Agre: '《用户服务协议》',
    privacy: '《隐私政策》',
    enter_KW: '请输入关键词',
    ended: '已结束',
    planet: '星球精选',
    rec_Tags: '推荐标签',
    feat_Topic: '专题推荐',
    sel_Persp: '精选观点',
    next_Art: '下一篇',
    newsflash: '7×24h快讯',
    page_Rfr: '开启后每30s页面自动刷新',
    orig_Link: '原文链接',
    reset_Pwd: '重置密码',
    load_More: '加载更多',
    welc_Odaily: '欢迎加入Odaily官方社群',
    telegram_SG: 'Telegram订阅群：',
    telegram_CG: 'Telegram交流群：',
    twitter: 'Twitter官方账号：',
    scan: '微信扫一扫',
    view_all: '查看全部',
    source: '来源',
    kx_hot: '快讯热榜',
    related_articles: '相关文章',
    related_links: '相关链接',
    share_article: '分享此文章',
    article: '文章',
    event_intro: '活动介绍',
    featured_articles: '精选文章',
    all_articles: '全部文章',
    recommended_reading: '推荐阅读',
    recommended_news: '文章热榜',
    link_phone_number: '关联手机号',
    library: '文库',
    account_settings: '账号设置',
    video: '视频',
    video_recommend: '视频推荐',
    personal_center: '个人中心',
    jump_to_backend: '跳转后台',
    logout: '退出登录',
    my_collection: '我的收藏',
    recommended_library: '推荐文库',
    create_library: '自建文库',
    new_library: '新建文库',
    delete_library: '删除文库',
    library_name: '文库名称',
    library_cover: '文库封面',
    library_Intro: '文库简介',
    optional: '选填',
    complete: '完成',
    basic_information: '基本资料',
    bind_third_party_account: '绑定第三方帐户',
    change_password: '修改密码',
    change_phone_number: '修改手机号码',
    no_related_content: '没有相关内容~',
    edit: '编辑',
    creation_time: '创建时间',
    collected: '已收藏',
    collect: '收藏',
    delete: '删除',
    cancel: '取消',
    loading_title: '正在加载',
    confirm: '确认',
    enter_1_20_characters: '请输入1-20个文字',
    confirm_delete_library: '您确认要删除此文库吗？',
    delete_collection_folder: '删除收藏夹后，里面收藏的内容也一并删除',
    odaily_explore_real_blockchain: 'ODAILY-探索真实区块链',
    explore_real_blockchain: '探索真实区块链',
    share_successfully: '分享成功',
    your_browser_version_is_too_low: '您的浏览器版本过低',
    upgrade_your_ie_browser: '为了更好的操作体验，需要升级您的IE浏览器',
    technical_support: '技术支持',
    hello: '您好，',
    if_you_have_any_questions_about_odaily:
      '如果您有任何关于ODAILY的问题请在以下时间联系我们的工作人员，我们将尽快解决您的问题并给予回复，感谢您的支持',
    dear_friends: '亲爱的朋友',
    service_hours: '服务时间',
    contact_number: '联系电话',
    contact_email: '联系邮箱',
    wechat: '微信',
    more_activities: '更多活动',
    all_activities: '全部活动',
    event_description: '活动描述',
    selected: '精选活动',
    current_votes: '当前票数',
    vote: '投票',
    go_to_vote: '去投票',
    voted: '已投票',
    disclaimer_title:
      '免责声明：本文来自投稿，不代表Odaily立场。如若转载请注明出处。',
    odaily_reminder: 'ODAILY提醒：',
    please_establish_correct_concepts:
      '请广大读者树立正确的货币观念和投资理念，理性看待区块链，切实提高风险意识；对发现的违法犯罪线索，可积极向有关部门举报反映。',
    latest: '最新文章',
    share: '分享',
    copyLink: '复制链接',
    weibo: '新浪微博',
    mobile: '移动',
    save_library: '收藏文库',
    edit_library: '编辑文库',
    select: '选择文库',
    selectToAdd: '请选择您想要添加的文库',
    default: '默认',
    krExclusive: '36氪独家战略合作区块链媒体',
    upgrade: '升级',
    scanQRCode: '打开微信"扫一扫"',
    click_screen: '打开网页后点击屏幕',
    click_share: '右上角"分享"按钮',
    shareTo: '分享到',
    notStarted: '未开始',
    ongoing: '活动中',
    featured: '推荐专栏',
    all: '所有',
    author: '作者',
    pw_text1: '当前密码',
    pw_text2: '新密码',
    pw_text3: '请输入8-16位密码，需包含字母和数字',
    pw_text4: '忘记密码',
    set_pw: '设置密码',
    set_succ: '设置成功',
    noResult: function NoResult(keywords) {
      return (
        <div>
          很抱歉，没有找到 “{keywords}” 相关结果。
          <br />
          换个词搜索吧～
        </div>
      );
    },
    project: '项目',
    institution: '机构',
    results: function Result(num) {
      return <div>共{num}个结果</div>;
    },
    daily_market: '查看行情',
    market_page_add_success: '添加成功',
    market_page_cancel_success: '取消成功',
    unit_wan: '万',
    unit_yi: '亿',
    market_page_tabbar_auto: '自选',
    market_page_tabbar_market: '现货市场',
    market_page_rank_up: '涨幅榜',
    market_page_rank_new: '新币榜',
    market_page_rank_deal: '成交额榜',
    market_page_table_title_symbol: '交易对',
    market_page_table_title_price: '最新价',
    market_page_table_title_up_rate: '涨幅',
    market_page_table_title_rate: '涨跌幅',
    market_page_table_title_low_price: '最低价',
    market_page_table_title_high_price: '最高价',
    market_page_table_title_vol_24h: '24H成交额',
    market_page_table_title_market_value: '市值',
    market_page_table_title_kline_7d: '7天走势',
    market_page_table_title_vol: '成交额'
  },
  zhtw: {
    timeHour: '小時前',
    timeMinites: '分鐘前',
    timeJust: '剛剛',
    newsflashTitle:
      '區塊鏈7 x 24新聞資訊_區塊鏈項目快訊_元宇宙_web3資訊-odaily',
    helmetKeywords:
      '鏈遊,元宇宙,nft,比特幣,區塊鏈,btc,eth,狗狗幣,空投,web3錢包,ai,web3新聞媒體,web3資訊,區塊鏈新聞,區塊鏈技術',
    navagationTitle: 'Web3綜合工具導航',
    loginUpdateSuccess: '更新成功',
    loginEmptyPhoneTip: '手機號不能為空',
    loginRightPhoneNOTip: '請輸入正確的手機號',
    loginEmptyVerifyCodeTip: '驗證碼不能為空',
    loginErrorVerifyCodeTip: '驗證碼錯誤，請重新輸入',
    enterPwd1: '請輸入6-16位密碼，不能輸入特殊字符',
    loginEmptyPasswordTip: '密碼不能為空',
    information: '資訊',
    flash: '快訊',
    kx_hot: '快訊熱榜',
    special: '專題',
    activity: '活動',
    navigation: '導航',
    search: '搜尋',
    expand: '展開',
    retracta: '收起',
    download: '下載App',
    signin: '登錄',
    seeMore: '查看更多',
    nomore: '沒有更多了',
    loading: '正在努力載入',
    daily: '日榜',
    weekly: '週榜',
    verifLogin: '驗證碼登錄',
    verification: '驗證碼',
    account: '帳號登入',
    sendVerif: '發送驗證碼',
    downloadOdaily: '下載Odaily星球日報app',
    autoLogin: '未註冊手機驗證後自動登入',
    agree: '註冊即代表我已閱讀並同意',
    otherLogin: '其他登入方式',
    pwdLogin: '密碼登錄',
    forgotPwd: '忘記密碼？',
    save: '儲存',
    enterPwd: '请输入6-16位密碼',
    enterPhone: '請輸入手機號碼',
    enterVerif: '請輸入驗證碼',
    pSearch: '熱門搜尋',
    pArticles: '文章熱榜',
    latestNews: '最新快訊',
    more: '更多',
    help: '讓一部分人先讀懂Web3.0',
    aboutUs: '關於我們',
    privacyPolicy: '隱私條款',
    contactUs: '聯繫我們',
    userAgreement: '用戶協議',
    joinUs: '加入我們',
    links: '友情連結',
    disclaimer: '免責聲明',
    partners: '合作夥伴',
    selected: '精選活動',
    followUs: '關注我們',
    joinCommunity: '加入社群',
    allActivities: '全部活動',
    appDownload: 'APP下載',
    publicAccount: '公眾號',
    rec_Topics: '推薦專題',
    bus_Coop: '商務合作',
    live_Cons: '直播諮詢',
    acc_Login: '帳號密碼登錄',
    user_Agre: '《用戶服務協議》',
    privacy: '《隱私政策》',
    enter_KW: '請輸入關鍵詞',
    ended: '已結束',
    planet: '星球精選',
    rec_Tags: '推薦標籤',
    feat_Topic: '專題推薦',
    sel_Persp: '精選觀點',
    next_Art: '下一篇',
    newsflash: '7×24h快訊',
    page_Rfr: '開啟後每30秒頁面刷新',
    orig_Link: '原文鏈接',
    reset_Pwd: '重置密碼',
    load_More: '加載更多',
    welc_Odaily: '歡迎加入Odaily官方社群',
    telegram_SG: 'Telegram訂閱群：',
    telegram_CG: 'Telegram交流群：',
    twitter: 'Twitter官方帳號：',
    scan: '微信掃一掃',
    view_all: '查看全部',
    source: '來源',
    related_articles: '相關文章',
    related_links: '相關鏈接',
    share_article: '分享此文章',
    article: '文章',
    event_intro: '活動介紹',
    featured_articles: '精選文章',
    all_articles: '全部文章',
    recommended_reading: '推薦閱讀',
    recommended_news: '文章熱榜',
    link_phone_number: '關聯手機號碼',
    library: '文庫',
    account_settings: '帳號設置',
    video: '視頻',
    video_recommend: '視頻推薦',
    personal_center: '個人中心',
    jump_to_backend: '跳轉後台',
    logout: '退出登錄',
    my_collection: '我的收藏',
    recommended_library: '推薦文庫',
    create_library: '創建文庫',
    new_library: '新建文庫',
    delete_library: '刪除文庫',
    library_name: '文庫名稱',
    library_cover: '文庫封面',
    library_Intro: '文庫簡介',
    optional: '選填',
    complete: '完成',
    backHome: '返回首頁',
    backPrev: '返回上一級',
    home: '首頁',
    errText1: '糟糕，您訪問的頁面失聯啦！',
    errText2: '去其他頁面轉轉吧！',
    errText3: '後自動跳轉到',
    basic_information: '基本資料',
    bind_third_party_account: '綁定第三方帳戶',
    change_password: '修改密碼',
    change_phone_number: '修改手機號碼',
    no_related_content: '沒有相關內容~',
    edit: '編輯',
    creation_time: '創建時間',
    collected: '已收藏',
    collect: '收藏',
    delete: '刪除',
    cancel: '取消',
    loading_title: '正在加載',
    confirm: '確認',
    enter_1_20_characters: '請輸入1-20個文字',
    confirm_delete_library: '您確認要刪除此文庫嗎？',
    delete_collection_folder: '刪除收藏夾後，裡面收藏的內容也一併刪除',
    odaily_explore_real_blockchain: 'ODAILY-探索真實區塊鏈',
    explore_real_blockchain: '探索真實區塊鏈',
    share_successfully: '分享成功',
    your_browser_version_is_too_low: '您的瀏覽器版本過低',
    upgrade_your_ie_browser: '為了更好的操作體驗，需要升級您的IE瀏覽器',
    technical_support: '技術支持',
    hello: '您好，',
    if_you_have_any_questions_about_odaily:
      '如果您有任何關於ODAILY的問題請在以下時間聯繫我們的工作人員，我們將盡快解決您的問題並給予回復，感謝您的支持',
    dear_friends: '親愛的朋友',
    service_hours: '服務時間',
    contact_number: '聯繫電話',
    contact_email: '聯繫郵箱',
    wechat: '微信',
    more_activities: '更多活動',
    all_activities: '全部活動',
    event_description: '活動描述',
    current_votes: '當前票數',
    vote: '投票',
    go_to_vote: '去投票',
    voted: '已投票',
    disclaimer_title:
      '免責聲明：本文來自投稿，不代表Odaily立場。如若轉載請註明出處。',
    odaily_reminder: 'ODAILY提醒：',
    please_establish_correct_concepts:
      '請廣大讀者樹立正確的貨幣觀念和投資理念，理性看待區塊鏈，切實提高風險意識；對發現的違法犯罪線索，可積極向有關部門舉報反映。',
    latest: '最新文章',
    share: '分享',
    copyLink: '複製連結',
    weibo: '新浪微博',
    mobile: '移動',
    save_library: '收藏文庫',
    edit_library: '編輯文庫',
    select: '選擇文庫',
    selectToAdd: '請選擇您想要添加的文庫',
    default: '默認',
    krExclusive: '36氪獨家戰略合作區塊鏈媒體',
    upgrade: '升級',
    scanQRCode: '打開微信"掃一掃"',
    click_screen: '打開網頁後點擊荧幕',
    click_share: '右上角"分享"按鈕',
    shareTo: '分享到',
    notStarted: '未開始',
    ongoing: '活動中',
    featured: '推薦專欄',
    all: '所有',
    author: '作者',
    pw_text1: '當前密碼',
    pw_text2: '新密碼',
    pw_text3: '請輸入8-16比特密碼，需包含字母和數位',
    pw_text4: '忘記密碼',
    set_pw: '設置密碼',
    set_succ: '設定成功',
    noResult: function NoResult(keywords) {
      return (
        <div>
          很抱歉，沒有找到 {keywords} 相關結果。
          <br />
          換個詞搜尋吧～
        </div>
      );
    },
    project: '項目',
    institution: '機構',
    results: function Result(num) {
      return <div>共{num}個結果</div>;
    },
    daily_market: '查看行情',
    market_page_add_success: '添加成功',
    market_page_cancel_success: '取消成功',
    unit_wan: '萬',
    unit_yi: '億',
    market_page_tabbar_auto: '自選',
    market_page_tabbar_market: '現貨市場',
    market_page_rank_up: '漲幅榜',
    market_page_rank_new: '新幣榜',
    market_page_rank_deal: '成交額榜',
    market_page_table_title_symbol: '交易對',
    market_page_table_title_price: '最新價',
    market_page_table_title_up_rate: '漲幅',
    market_page_table_title_rate: '漲跌幅',
    market_page_table_title_low_price: '最低價',
    market_page_table_title_high_price: '最高價',
    market_page_table_title_vol_24h: '24H成交額',
    market_page_table_title_market_value: '市值',
    market_page_table_title_kline_7d: '7天走勢',
    market_page_table_title_vol: '成交額'
  },
  ja: {
    timeHour: '時間前',
    timeMinites: '数分前',
    timeJust: 'ただ',
    newsflashTitle:
      'ブロックチェーン 7 x 24 ニュース_ブロックチェーン プロジェクト ニュース_メタバース_web3-odaily',
    helmetKeywords:
      'チェーン ゲーム、メタバース、nft、ビットコイン、ブロックチェーン、btc、eth、ドージコイン、エアドロップ、web3 ウォレット、ai、web3 ニュース メディア、web3 情報、ブロックチェーン ニュース、ブロックチェーン テクノロジー',
    navagationTitle: 'Web3総合ツールナビゲーション',
    loginUpdateSuccess: 'アップデートが完了しました',
    loginEmptyPhoneTip: '電話番号が空ではありません',
    loginRightPhoneNOTip: 'しい携帯電話番号を入力してください',
    loginEmptyVerifyCodeTip: '認コードを空にすることはできません',
    loginErrorVerifyCodeTip: '認コードが間違っています。再入力してください',
    enterPwd1:
      ' 6～ 16 桁のパスワードを入力してください、特殊文字は使用できません',
    loginEmptyPasswordTip: 'スワードを空にすることはできません',
    information: '情報',
    flash: 'フラッシュ',
    special: '特集',
    expand: '展開します',
    retracta: 'しまいます',
    activity: 'イベント',
    navigation: 'ナビゲーション',
    search: '検索',
    kx_hot: 'ホットトピック',
    download: 'App のダウンロード',
    signin: 'ログインする',
    seeMore: 'さらに見る',
    nomore: 'これ以上はありません',
    loading: '読み込み中',
    daily: '日間',
    weekly: '週間',
    backHome: 'トップページに戻ります',
    backPrev: '1段上に戻ります',
    home: 'トップページです',
    errText1: '大変です,あなたが訪問したページが音信不通になっています!',
    errText2: '他のページを回ってみます!',
    errText3: '自動的に遷移します',
    verifLogin: 'キャプチャログイン',
    verification: '認証コード',
    selected: '選りすぐりのイベント',
    account: 'アカウントログイン',
    sendVerif: '認証コードの送信',
    downloadOdaily: 'Odaily Appのダウンロード',
    autoLogin: '未登録の場合は、携帯電話番号認証後に自動ログインされます',
    agree: '登録することで、私は読み、同意したことになります',
    otherLogin: '他のログイン方法',
    pwdLogin: 'パスワードログイン',
    forgotPwd: 'パスワードを忘れましたか？',
    save: '保存する',
    enterPwd: '6〜16文字のパスワード',
    enterPhone: '携帯番号',
    enterVerif: '認証コード',
    pSearch: '人気検索ワード',
    pArticles: '必読',
    latestNews: '最新ニュース',
    more: 'もっと見る',
    help: '一部の人にWeb 3.0を先に読んでもらう',
    aboutUs: '私たちについて',
    privacyPolicy: 'プライバシーポリシー',
    contactUs: 'お問い合わせ',
    userAgreement: 'ユーザーサービス契約書',
    joinUs: '私たちに参加する',
    links: 'リンク',
    disclaimer: '免責事項',
    partners: 'パートナー',
    followUs: '私たちをフォローする',
    joinCommunity: 'コミュニティに参加する',
    allActivities: 'すべてのアクティビティ',
    appDownload: 'ダウンロード',
    publicAccount: 'WeChat Account',
    rec_Topics: 'おすすめの専門トピック',
    bus_Coop: 'ビジネス協力',
    live_Cons: 'ライブ相談',
    acc_Login: 'アカウントとパスワードでログイン',
    user_Agre: '「ユーザーサービス契約書」',
    privacy: '「プライバシーポリシー」',
    enter_KW: 'キーワードを入力してください',
    planet: '編集者の選択',
    rec_Tags: 'おすすめタグ',
    feat_Topic: '専門テーマおすすめ',
    sel_Persp: 'KOLの見解',
    next_Art: '次の記事',
    newsflash: '速報',
    page_Rfr: '有効化後、30秒ごとにページが更新されます',
    orig_Link: '元記事リンク',
    reset_Pwd: 'パスワードをリセットする',
    load_More: 'もっと読み込む',
    welc_Odaily: 'Odaily公式コミュニティへようこそ',
    telegram_SG: 'Telegramサブスクリプショングループ：',
    telegram_CG: 'Telegramコミュニケーショングループ：',
    twitter: '公式Twitterアカウント：',
    scan: 'WeChatでスキャン',
    view_all: 'すべてを表示',
    source: '出典',
    related_articles: '関連記事',
    related_links: '関連リンク',
    share_article: 'この記事をシェア',
    article: '記事',
    event_intro: 'イベント紹介',
    featured_articles: 'おすすめの記事',
    all_articles: 'すべての記事',
    recommended_reading: 'おすすめの読み物',
    recommended_news: '必読',
    link_phone_number: '電話番号をリンク',
    library: 'ライブラリ',
    account_settings: 'アカウント設定',
    video: 'ビデオ',
    video_recommend: 'ビデオ推薦',
    personal_center: '個人センター',
    jump_to_backend: 'バックエンドにジャンプ',
    logout: 'ログアウト',
    my_collection: 'マイコレクション',
    recommended_library: 'おすすめのライブラリ',
    create_library: 'ライブラリを作成する',
    new_library: '新しいライブラリ',
    delete_library: 'ライブラリを削除する',
    library_name: 'ライブラリ名',
    library_cover: 'ライブラリカバー',
    library_Intro: '文庫プロフィール',
    optional: 'オプション',
    complete: '完成です',
    basic_information: '基本情報',
    bind_third_party_account: 'サードパーティのアカウントをバインドする',
    change_password: 'パスワードを変更する',
    change_phone_number: '電話番号を変更する',
    no_related_content: '関連コンテンツはありません',
    edit: '編集する',
    creation_time: '作成時間',
    collected: '収集済み',
    collect: '収集する',
    delete: '削除する',
    cancel: 'キャンセル',
    loading_title: 'ロード中',
    confirm: '確認',
    enter_1_20_characters: '1〜20文字を入力してください',
    confirm_delete_library: 'このライブラリを削除してもよろしいですか？',
    delete_collection_folder:
      'コレクションフォルダを削除すると、中にあるコンテンツも一緒に削除されます',
    odaily_explore_real_blockchain: 'ODAILY-リアルブロックチェーンの探求',
    explore_real_blockchain: 'リアルブロックチェーンの探求',
    share_successfully: '共有に成功しました',
    your_browser_version_is_too_low: 'お使いのブラウザのバージョンが古すぎます',
    upgrade_your_ie_browser:
      'より良い操作体験のために、IEブラウザをアップグレードする必要があります',
    technical_support: '技術サポート',
    hello: 'こんにちは、',
    if_you_have_any_questions_about_odaily:
      'ODAILYに関するご質問がある場合は、以下の時間帯にスタッフにお問い合わせください。問題を解決し、返信します。ご協力ありがとうございます。',
    dear_friends: '親愛なる友人へ',
    service_hours: 'サービス時間',
    contact_number: '連絡先電話番号',
    contact_email: '連絡先メールアドレス',
    wechat: 'WeChat',
    more_activities: '他のアクティビティ',
    all_activities: 'すべてのアクティビティ',
    event_description: 'イベントの説明',
    current_votes: '現在の投票数',
    vote: '投票する',
    go_to_vote: '投票に行く',
    voted: '投票済み',
    disclaimer_title:
      '免責事項：この記事は投稿から来ています。Odailyの立場を代表するものではありません。転載する場合は、出典を明示してください。',
    odaily_reminder: 'ODAILYリマインダー：',
    please_establish_correct_concepts:
      '広大な読者の皆様には、正しい通貨観念と投資観念を持ち、ブロックチェーンについて理性的に考え、リスク意識を高めるようお願いいたします。また、違法行為に関する情報を発見した場合には、積極的に関係機関に通報してください。',
    latest: '最新記事',
    share: '共有',
    copyLink: 'リンクをコピー',
    weibo: '新浪微博',
    mobile: 'モバイル',
    save_library: '文庫に保存',
    edit_library: '文庫を編集',
    select: '文庫を選択',
    selectToAdd: '追加する文庫を選択してください',
    default: 'デフォルト',
    krExclusive: '36Kr独占戦略的なブロックチェーンメディア',
    upgrade: 'アップグレード',
    scanQRCode: 'WeChatでQRコードをスキャンする',
    click_screen: '画面をタップしてください',
    click_share: '右上の「シェア」ボタンをタップしてください',
    shareTo: '共有',
    ended: '終了',
    notStarted: '開始前',
    ongoing: '進行中',
    featured: 'おすすめコラム',
    all: 'すべて',
    author: '著者',
    pw_text1: '現在のパスワードです',
    pw_text2: '新しいパスワードです',
    pw_text3: '8桁から16桁の数字を入力してください。',
    pw_text4: 'パスワードを忘れました',
    set_pw: 'パスワードを設定します',
    set_succ: 'セットアップ成功です',
    noResult: function NoResult(keywords) {
      return (
        <div>
          「{keywords}」に関連する結果が見つかりませんでした。
          <br />
          別のキーワードで検索してみてください。
        </div>
      );
    },
    project: 'プロジェクト',
    institution: '機関',
    results: function Result(num) {
      return <div>{num}件の結果があります</div>;
    },
    daily_market: 'Market Trends',
    market_page_add_success: 'Add Success',
    market_page_cancel_success: 'Cancel Success',
    unit_wan: 'M',
    unit_yi: 'B',
    market_page_tabbar_auto: 'Favorites',
    market_page_tabbar_market: 'Spot',
    market_page_rank_up: 'Change',
    market_page_rank_new: 'Newest',
    market_page_rank_deal: 'Volume',
    market_page_table_title_symbol: 'Pair',
    market_page_table_title_price: 'Last Price',
    market_page_table_title_up_rate: 'Change',
    market_page_table_title_rate: 'Change',
    market_page_table_title_low_price: 'Low',
    market_page_table_title_high_price: 'High',
    market_page_table_title_vol_24h: '24h Vol',
    market_page_table_title_market_value: 'Market',
    market_page_table_title_kline_7d: 'Last 7d',
    market_page_table_title_vol: 'Vol'
  },
  ko: {
    timeHour: '한 시간 전에',
    timeMinites: '몇분 전에',
    timeJust: '단지',
    newsflashTitle:
      '블록체인 7 x 24 News_Blockchain 프로젝트 News_Metaverse_web3-odaily',
    helmetKeywords:
      '체인 게임, 메타버스, nft, 비트코인, 블록체인, btc, eth, Dogecoin, 에어드롭, web3 지갑, ai, web3 뉴스 미디어, web3 정보, 블록체인 뉴스, 블록체인 기술',
    navagationTitle: 'Web3 종합 도구 탐색',
    loginUpdateSuccess: '업데이트 완료',
    loginEmptyPhoneTip: '전화번호가 비어 있지 않습니다.',
    loginRightPhoneNOTip: '올바른 전화번호를 입력하세요',
    loginEmptyVerifyCodeTip: '인증 코드는 비워둘 수 없습니다.',
    loginErrorVerifyCodeTip: '인증 코드 오류, 다시 입력하세요.',
    enterPwd1: '6-16자리 비밀번호를 입력하세요. 특수문자는 허용되지 않습니다.',
    loginEmptyPasswordTip: '비밀번호는 비워둘 수 없습니다.',
    information: '정보',
    flash: '빠른 소식',
    special: '전문',
    activity: '이벤트',
    navigation: '내비게이션',
    expand: '벌',
    retracta: '접',
    search: '검색',
    kx_hot: '핫 토픽',
    download: '앱 다운로드',
    signin: '로그인',
    seeMore: '더 보기',
    nomore: '더 이상 없음',
    loading: '로딩 중',
    daily: '일간',
    weekly: '주간',
    selected: '특선 이벤트',
    verifLogin: '캡차 로그인',
    verification: '인증 코드',
    backHome: '홈 페이지로 돌아가기',
    backPrev: '한 단계 위로 돌아가다',
    home: '첫 페이지',
    errText1: '야단났네, 당신이 방문한 페이지가 두절되었어!',
    errText2: '다른 페이지로 가 봐!',
    errText3: '다음에 자동으로 넘어갑니다',
    account: '계정 로그인',
    sendVerif: '인증 코드 전송',
    downloadOdaily: 'Odaily App 다운로드',
    autoLogin: '미등록 시, 휴대폰 인증 후 자동 로그인',
    agree: '등록함으로써, 나는 읽고 동의한 것으로 간주합니다',
    otherLogin: '다른 로그인 방법',
    pwdLogin: '비밀번호 로그인',
    forgotPwd: '비밀번호를 잊으셨나요?',
    save: '저장',
    enterPwd: '6-16자리 비밀번호',
    enterPhone: '핸드폰 번호',
    enterVerif: '인증 코드',
    pSearch: '인기 검색어',
    pArticles: '꼭 읽어야 할',
    latestNews: '최신 뉴스',
    more: '더 보기',
    help: '일부분이 먼저 Web3.0을 읽게 하기',
    aboutUs: '우리에 대해',
    privacyPolicy: '개인 정보 보호 정책',
    contactUs: '연락처',
    userAgreement: '이용 약관',
    joinUs: '우리와 함께 하세요',
    links: '우정 링크',
    disclaimer: '면책 조항',
    partners: '파트너',
    followUs: '팔로우하기',
    joinCommunity: '커뮤니티 가입하기',
    allActivities: '모든 활동',
    appDownload: '다운로드',
    publicAccount: 'WeChat Account',
    rec_Topics: '추천 전문 주제',
    bus_Coop: '비즈니스 협력',
    live_Cons: '라이브 상담',
    acc_Login: '계정과 비밀번호로 로그인',
    user_Agre: '사용자 서비스 계약',
    privacy: '개인정보 보호정책',
    enter_KW: '검색어를 입력하세요',
    ended: '종료됨',
    planet: '편집자의 선택',
    rec_Tags: '추천 태그',
    feat_Topic: '주제',
    sel_Persp: 'KOL의 견해',
    next_Art: '다음 글',
    newsflash: '빠른 뉴스',
    page_Rfr: '활성화 후 30초마다 페이지 새로고침',
    orig_Link: '원문 링크',
    reset_Pwd: '비밀번호 재설정',
    load_More: '더 보기',
    welc_Odaily: 'Odaily 공식 커뮤니티에 오신 것을 환영합니다',
    telegram_SG: 'Telegram 구독 그룹：',
    telegram_CG: 'Telegram 커뮤니케이션 그룹：',
    twitter: '공식 Twitter 계정：',
    scan: 'WeChat로 스캔',
    view_all: '모두 보기',
    source: '출처',
    related_articles: '관련 기사',
    related_links: '관련 링크',
    share_article: '이 기사 공유하기',
    article: '기사',
    event_intro: '이벤트 소개',
    featured_articles: '추천 기사',
    all_articles: '모든 기사',
    recommended_reading: '추천 독서',
    recommended_news: '꼭 읽어야 할',
    link_phone_number: '전화번호 연결',
    library: '도서관',
    account_settings: '계정 설정',
    video: '비디오',
    video_recommend: '비디오 추천',
    personal_center: '개인 센터',
    jump_to_backend: '백엔드로 이동',
    logout: '로그아웃',
    my_collection: '내 컬렉션',
    recommended_library: '추천 도서관',
    create_library: '도서관 만들기',
    new_library: '새로운 도서관',
    delete_library: '도서관 삭제',
    library_name: '도서관 이름',
    library_cover: '도서관 커버',
    library_Intro: '문고 소개',
    optional: '선택사항',
    complete: '완료',
    basic_information: '기본 정보',
    bind_third_party_account: '제 3자 계정 바인딩',
    change_password: '비밀번호 변경',
    change_phone_number: '전화번호 변경',
    no_related_content: '관련 컨텐츠가 없습니다.',
    edit: '편집',
    creation_time: '생성 시간',
    collected: '수집됨',
    collect: '수집',
    delete: '삭제',
    cancel: '취소',
    loading_title: '로딩 중',
    confirm: '확인',
    enter_1_20_characters: '1-20자를 입력하세요',
    confirm_delete_library: '이 도서관을 삭제하시겠습니까?',
    delete_collection_folder:
      '컬렉션 폴더를 삭제하면 안에 있는 콘텐츠도 함께 삭제됩니다.',
    odaily_explore_real_blockchain: 'ODAILY-진정한 블록체인 탐구',
    explore_real_blockchain: '진정한 블록체인 탐구',
    share_successfully: '공유 완료',
    your_browser_version_is_too_low: '사용 중인 브라우저 버전이 너무 낮습니다.',
    upgrade_your_ie_browser:
      '더 나은 작동 경험을 위해 IE 브라우저를 업그레이드해야 합니다.',
    technical_support: '기술 지원',
    hello: '안녕하세요,',
    if_you_have_any_questions_about_odaily:
      'ODAILY에 관한 질문이 있으시면 아래 시간에 스태프에게 문의해 주세요. 문제를 해결하고 회신드리겠습니다. 협조해 주셔서 감사합니다.',
    dear_friends: '친애하는 친구 여러분께',
    service_hours: '서비스 시간',
    contact_number: '연락처 전화번호',
    contact_email: '연락처 이메일',
    wechat: 'WeChat',
    more_activities: '더 많은 활동',
    all_activities: '모든 활동',
    event_description: '이벤트 설명',
    current_votes: '현재 투표수',
    vote: '투표',
    go_to_vote: '투표하러 가기',
    voted: '투표함',
    disclaimer_title:
      '면책 조항: 이 기사는 투고에서 온 것이며, Odaily의 입장을 대표하는 것은 아닙니다. 전재 시 출처를 명시해 주시기 바랍니다.',
    odaily_reminder: 'ODAILY 알림:',
    please_establish_correct_concepts:
      '광대한 독자 여러분께는 올바른 화폐 개념과 투자 개념을 가지고 블록체인에 대해 이성적으로 생각하고 위험의식을 높이시기 바랍니다. 또한국어로 번역하면 다음과 같습니다.',
    latest: '최신 기사',
    share: '공유',
    copyLink: '링크 복사',
    weibo: '웨이보',
    mobile: '모바일',
    save_library: '문고에 저장',
    edit_library: '문고 편집',
    select: '문고 선택',
    selectToAdd: '추가할 문고를 선택하세요',
    default: '기본',
    krExclusive: '36Kr 독점 전략적 블록체인 미디어',
    upgrade: '업그레이드',
    scanQRCode: 'WeChat에서 QR 코드를 스캔하십시오',
    click_screen: '화면을 탭하세요',
    click_share: '오른쪽 상단의 "공유" 버튼을 탭하세요',
    shareTo: '공유',
    notStarted: '시작 전',
    ongoing: '진행 중',
    featured: '추천 전문',
    all: '모두',
    author: '작성자',
    pw_text1: '현재 비밀번호',
    pw_text2: '새 비밀번호',
    pw_text3: '8~16자리 비밀번호를 문자와 숫자를 포함하여 입력하세요',
    pw_text4: '비밀번호를 잊어버리다',
    set_pw: '비밀번호 설정',
    set_succ: '설정 성공',
    noResult: function NoResult(keywords) {
      return (
        <div>
          {keywords}에 대한 검색 결과가 없습니다.
          <br /> 다른 검색어로 다시 검색해보세요.
        </div>
      );
    },
    project: '프로젝트',
    institution: '기관',
    results: function Result(num) {
      return <div>{num}개의 결과</div>;
    },
    daily_market: 'Market Trends',
    market_page_add_success: 'Add Success',
    market_page_cancel_success: 'Cancel Success',
    unit_wan: 'M',
    unit_yi: 'B',
    market_page_tabbar_auto: 'Favorites',
    market_page_tabbar_market: 'Spot',
    market_page_rank_up: 'Change',
    market_page_rank_new: 'Newest',
    market_page_rank_deal: 'Volume',
    market_page_table_title_symbol: 'Pair',
    market_page_table_title_price: 'Last Price',
    market_page_table_title_up_rate: 'Change',
    market_page_table_title_rate: 'Change',
    market_page_table_title_low_price: 'Low',
    market_page_table_title_high_price: 'High',
    market_page_table_title_vol_24h: '24h Vol',
    market_page_table_title_market_value: 'Market',
    market_page_table_title_kline_7d: 'Last 7d',
    market_page_table_title_vol: 'Vol'
  },
  th: {
    timeHour: 'ชั่วโมงที่ผ่านมา',
    timeMinites: 'นาทีที่แล้ว',
    timeJust: 'แค่',
    newsflashTitle:
      'Blockchain 7 x 24 News_Blockchain Project News_Metaverse_web3-odaily',
    helmetKeywords:
      'เกมเชน, metaverse, nft, bitcoin, blockchain, btc, eth, Dogecoin, airdrop, กระเป๋าเงิน web3, ai, สื่อข่าว web3, ข้อมูล web3, ข่าว blockchain, เทคโนโลยี blockchain',
    navagationTitle: 'การนำทางเครื่องมือที่ครอบคลุมของ Web3',
    loginUpdateSuccess: 'อัปเดตเสร็จแล้ว',
    loginEmptyPhoneTip: 'หมายเลขโทรศัพท์ไม่ว่างเปล่า',
    loginRightPhoneNOTip: 'กรุณาป้อนหมายเลขโทรศัพท์ที่ถูกต้อง',
    loginEmptyVerifyCodeTip: 'รหัสยืนยันต้องไม่ว่างเปล่า',
    loginErrorVerifyCodeTip: 'รหัสยืนยันผิดพลาด กรุณาป้อนอีกครั้ง',
    enterPwd1: 'โปรดป้อนรหัสผ่าน 6-16 หลัก ห้ามใช้อักขระพิเศษ',
    loginEmptyPasswordTip: 'รหัสผ่านต้องไม่ว่างเปล่า',
    information: 'ข่าวสาร',
    flash: 'ข่าวด่วน',
    special: 'สารคดี',
    activity: 'กิจกรรม',
    expand: 'ขยาย',
    retracta: 'หยุดได้แล้ว',
    navigation: 'การนำทาง',
    search: 'ค้นหา',
    download: 'ดาวน์โหลดแอป',
    signin: 'เข้าสู่ระบบ',
    kx_hot: 'ประเด็นร้อน',
    seeMore: 'ดูเพิ่มเติม',
    nomore: 'ไม่มีอีกแล้ว',
    loading: 'กำลังโหลด',
    selected: 'กิจกรรม ที่เลือกไว้',
    daily: 'วัน',
    weekly: 'สัปดาห์',
    backHome: 'กลับไปยังหน้าแรก',
    backPrev: 'กลับไป ที่ระดับ 1',
    home: 'หน้าแรก',
    errText1: 'หน้า ที่เข้าชมของคุณหายไป!',
    errText2: 'ไปรอบ ๆ หน้าอื่น!',
    errText3: 'กระโดดอัตโนมัติไปยัง',
    verifLogin: 'ล็อกอินแบบ captcha',
    verification: 'รหัสยืนยัน',
    account: 'เข้าสู่ระบบบัญชี',
    sendVerif: 'ส่งรหัสยืนยัน',
    downloadOdaily: 'ดาวน์โหลดแอป Odaily ดาวน์โหลด',
    autoLogin:
      'หากยังไม่ได้ลงทะเบียน จะเข้าสู่ระบบโดยอัตโนมัติหลังการตรวจสอบยืนยันด้วยหมายเลขโทรศัพท์มือถือ',
    agree: 'การลงทะเบียนหมายความว่าฉันได้อ่านและยินยอมแล้ว',
    otherLogin: 'วิธีการเข้าสู่ระบบอื่นๆ',
    pwdLogin: 'ล็อกอินด้วยรหัสผ่าน',
    forgotPwd: 'ลืมรหัสผ่าน?',
    save: 'บันทึก',
    enterPwd: 'รหัสผ่าน 6-16 ตัวอักษร',
    enterPhone: 'หมายเลขโทรศัพท์มือถือ',
    enterVerif: 'รหัสยืนยัน',
    pSearch: 'คำค้นหายอดนิยม',
    pArticles: 'ต้องอ่าน',
    latestNews: 'ข่าวล่าสุด',
    more: 'ดูเพิ่มเติม',
    help: 'ช่วยให้บางคนเข้าใจเรื่อง Web3.0 ได้ก่อน',
    aboutUs: 'เกี่ยวกับเรา',
    privacyPolicy: 'นโยบายความเป็นส่วนตัว',
    contactUs: 'ติดต่อเรา',
    userAgreement: 'ข้อตกลงการให้บริการของผู้ใช้',
    joinUs: 'เข้าร่วมกับเรา',
    links: 'ลิงก์ที่เกี่ยวข้อง',
    disclaimer: 'ข้อจำกัดความรับผิดชอบ',
    partners: 'พาร์ทเนอร์',
    followUs: 'ติดตามเรา',
    joinCommunity: 'เข้าร่วมชุมชน',
    allActivities: 'กิจกรรมทั้งหมด',
    appDownload: 'ดาวน์โหลด',
    publicAccount: 'WeChat Account',
    rec_Topics: 'หัวข้อแนะนำ',
    bus_Coop: 'การร่วมมือทางธุรกิจ',
    live_Cons: 'การให้คำปรึกษาสด',
    acc_Login: 'เข้าสู่ระบบด้วยบัญชีและรหัสผ่าน',
    user_Agre: 'ข้อตกลงบริการผู้ใช้',
    privacy: 'นโยบายความเป็นส่วนตัว',
    enter_KW: 'โปรดป้อนคำสำคัญ',
    ended: 'จบแล้ว',
    planet: 'ตัวเลือกของบรรณาธิการ',
    rec_Tags: 'แท็กแนะนำ',
    feat_Topic: 'หัวข้อ',
    sel_Persp: 'มุมมองของ KOL',
    next_Art: 'บทความถัดไป',
    newsflash: 'ข่าวด่วน',
    page_Rfr: 'รีเฟรชหน้าเว็บทุก 30 วินาทีหลังจากเปิดใช้งาน',
    orig_Link: 'ลิงก์บทความต้นฉบับ',
    reset_Pwd: 'รีเซ็ตรหัสผ่าน',
    load_More: 'โหลดเพิ่มเติม',
    welc_Odaily: 'ยินดีต้อนรับเข้าร่วมชุมชนออนไลน์ของ Odaily',
    telegram_SG: 'กลุ่มสมัครสมาชิก Telegram：',
    telegram_CG: 'กลุ่มสื่อสาร Telegram：',
    twitter: 'บัญชีทวิตเตอร์อย่างเป็นทางการ：',
    scan: 'สแกนด้วย WeChat',
    view_all: 'ดูทั้งหมด',
    source: 'ที่มา',
    related_articles: 'บทความที่เกี่ยวข้อง',
    related_links: 'ลิงก์ที่เกี่ยวข้อง',
    share_article: 'แบ่งปันบทความนี้',
    article: 'บทความ',
    event_intro: 'รายละเอียดกิจกรรม',
    featured_articles: 'บทความแนะนำ',
    all_articles: 'บทความทั้งหมด',
    recommended_reading: 'การอ่านแนะนำ',
    recommended_news: 'การอ่านที่จำเป็น',
    link_phone_number: 'เชื่อมโยงหมายเลขโทรศัพท์มือถือ',
    library: 'ห้องสมุด',
    account_settings: 'การตั้งค่าบัญชีผู้ใช้',
    video: 'วิดีโอ',
    video_recommend: 'วิดีโอแนะนำ',
    personal_center: 'ศูนย์ส่วนบุคคล',
    jump_to_backend: 'ไปยังหลังบ้าน',
    logout: 'ออกจากระบบ',
    my_collection: 'คอลเลกชันของฉัน',
    recommended_library: 'ห้องสมุดแนะนำ',
    create_library: 'สร้างห้องสมุด',
    new_library: 'ห้องสมุดใหม่',
    delete_library: 'ลบห้องสมุด',
    library_name: 'ชื่อห้องสมุด',
    library_cover: 'ปกห้องสมุด',
    library_Intro: 'รายละเอียดของคลังสื่อ',
    optional: 'ตัวเลือก',
    complete: 'เสร็จสิ้น',
    basic_information: 'ข้อมูลพื้นฐาน',
    bind_third_party_account: 'ผูกบัญชีของบุคคลที่สาม',
    change_password: 'เปลี่ยนรหัสผ่าน',
    change_phone_number: 'เปลี่ยนหมายเลขโทรศัพท์มือถือ',
    no_related_content: 'ไม่มีเนื้อหาที่เกี่ยวข้อง~',
    edit: 'แก้ไข',
    creation_time: 'เวลาสร้าง',
    collected: 'รวบรวมแล้ว',
    collect: 'รวบรวม',
    delete: 'ลบ',
    cancel: 'ยกเลิก',
    loading_title: 'กำลังโหลด',
    confirm: 'ยืนยัน',
    enter_1_20_characters: 'กรุณากรอก 1-20 ตัวอักษร',
    confirm_delete_library: 'คุณแน่ใจว่าต้องการลบห้องสมุดนี้หรือไม่?',
    delete_collection_folder:
      'การลบโฟลเดอร์คอลเลกชันจะลบเนื้อหาที่รวบรวมไว้ด้วย',
    odaily_explore_real_blockchain: 'ODAILY - สำรวจบล็อกเชนที่แท้จริง',
    explore_real_blockchain: 'สำรวจบล็อกเชนที่แท้จริง',
    share_successfully: 'แบ่งปันสำเร็จ',
    your_browser_version_is_too_low: 'เวอร์ชันเบราว์เซอร์ของคุณต่ำเกินไป',
    upgrade_your_ie_browser:
      'เพื่อให้การใช้งานดีขึ้น โปรดอัปเกรดเบราว์เซอร์ IE ของคุณ',
    technical_support: 'สนับสนุนทางเทคนิค',
    hello: 'สวัสดีค่ะ',
    if_you_have_any_questions_about_odaily:
      'หากคุณมีคำถามใดเกี่ยวกับ ODAILY โปรดติดต่อเจ้าหน้าที่ของเราในเวลาดังต่อไปนี้ ขอบคุณที่ให้การสนับสนุน',
    dear_friends: 'เพื่อนที่รัก',
    service_hours: 'เวลาให้บริการ',
    contact_number: 'หมายเลขติดต่อ',
    contact_email: 'อีเมลติดต่อ',
    wechat: 'WeChat',
    more_activities: 'กิจกรรมเพิ่มเติม',
    all_activities: 'กิจกรรมทั้งหมด',
    event_description: 'คำอธิบายกิจกรรม',
    current_votes: 'จำนวนโหวตปัจจุบัน',
    vote: 'โหวต',
    go_to_vote: 'ไปโหวต',
    voted: 'โหวตแล้ว',
    disclaimer_title:
      'การประกาศการยกเว้นความรับผิดชอบ: บทความนี้มาจากการส่งมอบ และไม่แสดงถึงความเห็นของ ODAILY หากมีการนำไปเผยแพร่โปรดแจ้งที่แหล่งอ้างอิง',
    odaily_reminder: 'ODAILY จำเป็นต้องเตือน:',
    please_establish_correct_concepts:
      'โปรดกำหนดความเข้าใจที่ถูกต้องเกี่ยวกับสกุลเงินและแนวคิดการลงทุน มองวิวัฒนาการของเทคโนโลยีบล็อกเชนอย่างมีเหตุผล และเพิ่มความตระหนักในความเสี่ยง หากคุณพบข้อผิดพลาดตามกฎหมายโปรดแจ้งเจ้าหน้าที่สายด่วนด่วนโดยไม่ติดต่อผู้ถือหุ้นของ ODAILY',
    latest: 'บทความล่าสุด',
    share: 'แบ่งปัน',
    copyLink: 'คัดลอกลิงก์',
    weibo: 'Weibo',
    mobile: 'โมบาย',
    save_library: 'บันทึกในคลังเอกสาร',
    edit_library: 'แก้ไขคลังเอกสาร',
    select: 'เลือกคลังเอกสาร',
    selectToAdd: 'โปรดเลือกคลังเอกสารที่คุณต้องการเพิ่ม',
    default: 'ค่าเริ่มต้น',
    krExclusive: 'สื่อบล็อกเชนเชิงกลยุทธ์มีความเป็นเอก',
    upgrade: 'อัพเกรด',
    scanQRCode: 'สแกนรหัส QR ด้วย WeChat',
    click_screen: 'คลิกที่หน้าจอ',
    click_share: 'คลิกที่ปุ่ม "แชร์" ที่มุมขวาบน',
    shareTo: 'แบ่งปัน',
    notStarted: 'ยังไม่เริ่มต้น',
    ongoing: 'กำลังดำเนินการ',
    featured: 'คอลัมน์แนะนำ',
    all: 'ทั้งหมด',
    author: 'ผู้เขียน',
    pw_text1: 'รหัสผ่านปัจจุบัน',
    pw_text2: 'รหัสผ่านใหม่',
    pw_text3: 'กรุณาใส่รหัส 8-16 บิตเพื่อให้มีตัวอักษร และตัวเลข',
    pw_text4: 'ลืมรหัสผ่าน',
    set_pw: 'ตั้งรหัสผ่าน',
    set_succ: 'ตั้งค่าเรียบร้อยแล้ว',
    noResult: function NoResult(keywords) {
      return (
        <div>
          ขออภัยไม่พบผลลัพธ์ที่เกี่ยวข้องกับ {keywords} <br />
          ลองค้นหาด้วยคำใหม่อีกครั้ง
        </div>
      );
    },
    project: 'โครงการ',
    institution: 'สถาบัน',
    results: function Result(num) {
      return <div>พบ {num} รายการ</div>;
    },
    daily_market: 'Market Trends',
    market_page_add_success: 'Add Success',
    market_page_cancel_success: 'Cancel Success',
    unit_wan: 'M',
    unit_yi: 'B',
    market_page_tabbar_auto: 'Favorites',
    market_page_tabbar_market: 'Spot',
    market_page_rank_up: 'Change',
    market_page_rank_new: 'Newest',
    market_page_rank_deal: 'Volume',
    market_page_table_title_symbol: 'Pair',
    market_page_table_title_price: 'Last Price',
    market_page_table_title_up_rate: 'Change',
    market_page_table_title_rate: 'Change',
    market_page_table_title_low_price: 'Low',
    market_page_table_title_high_price: 'High',
    market_page_table_title_vol_24h: '24h Vol',
    market_page_table_title_market_value: 'Market',
    market_page_table_title_kline_7d: 'Last 7d',
    market_page_table_title_vol: 'Vol'
  },
  vi: {
    timeHour: 'Một giờ trước',
    timeMinites: 'Vài phút trước',
    timeJust: 'chỉ',
    newsflashTitle:
      'Tin tức chuỗi khối 7 x 24_Tin tức dự án chuỗi khối_Metaverse_web3-odaily',
    helmetKeywords:
      'Trò chơi chuỗi, metaverse, nft, bitcoin, chuỗi khối, btc, eth, Dogecoin, airdrop, ví web3, ai, phương tiện tin tức web3, thông tin web3, tin tức chuỗi khối, công nghệ chuỗi khối',
    navagationTitle: 'Điều hướng công cụ toàn diện Web3',
    loginUpdateSuccess: 'hoàn thành cập nhật',
    loginEmptyPhoneTip: 'Số điện thoại không trống',
    loginRightPhoneNOTip: 'Vui lòng nhập đúng số điện thoại',
    loginEmptyVerifyCodeTip: 'Mã xác minh không được để trống',
    loginErrorVerifyCodeTip: 'Lỗi mã xác minh, vui lòng nhập lại',
    enterPwd1:
      'Vui lòng nhập mật khẩu gồm 6-16 chữ số, không cho phép ký tự đặc biệt',
    loginEmptyPasswordTip: 'Mật khẩu không được để trống',
    information: 'Thông tin',
    flash: 'Tin nhanh',
    special: 'Chuyên đề',
    activity: 'Hoạt động',
    expand: 'Mở rộng',
    retracta: 'Tài hùng',
    navigation: 'Điều hướng',
    search: 'Tìm kiếm',
    kx_hot: 'Chủ đề nóng',
    download: 'Tải ứng dụng',
    signin: 'Đăng nhập',
    seeMore: 'Xem thêm',
    selected: 'Sự kiện nổi bật',
    nomore: 'Không còn nữa',
    loading: 'Đang tải',
    daily: 'ngày',
    weekly: 'tuần',
    backHome: 'Trở về trang chủ',
    backPrev: 'Trở về cấp trên',
    home: 'Trang đầu',
    errText1: 'Xấu, bạn truy cập vào trang bị mất!',
    errText2: 'Đi đến các trang khác và đi!',
    errText3: 'Tự động nhảy vào',
    verifLogin: 'Đăng nhập bằng mã xác thực',
    verification: 'Mã xác thực',
    account: 'Đăng nhập tài khoản',
    sendVerif: 'Gửi mã xác thực',
    downloadOdaily: 'Tải ứng dụng Daily Star',
    autoLogin: 'Đăng nhập tự động sau khi xác nhận số điện thoại chưa đăng ký',
    agree: 'Đăng ký đồng nghĩa với việc tôi đã đọc và đồng ý',
    otherLogin: 'Đăng nhập bằng phương thức khác',
    pwdLogin: 'Đăng nhập bằng mật khẩu',
    forgotPwd: 'Quên mật khẩu?',
    save: 'Lưu',
    enterPwd: 'Mật khẩu từ 6-16 ký tự',
    enterPhone: 'Nhập số điện thoại',
    enterVerif: 'Nhập mã xác thực',
    pSearch: 'Tìm kiếm phổ biến',
    pArticles: 'Phải đọc',
    latestNews: 'Tin nhanh mới nhất',
    more: 'Thêm',
    help: 'Giúp một số người đọc hiểu Web3.0 trước',
    aboutUs: 'Về chúng tôi',
    privacyPolicy: 'Chính sách bảo mật',
    contactUs: 'Liên hệ chúng tôi',
    userAgreement: 'Thỏa thuận người dùng',
    joinUs: 'Tham gia với chúng tôi',
    links: 'Liên kết bạn bè',
    disclaimer: 'Tuyên bố từ chối trách nhiệm',
    partners: 'Đối tác',
    followUs: 'Theo dõi chúng tôi',
    joinCommunity: 'Tham gia cộng đồng',
    allActivities: 'Tất cả hoạt động',
    appDownload: 'Tải xuống',
    publicAccount: 'WeChat Account',
    rec_Topics: 'Chuyên đề được đề xuất',
    bus_Coop: 'Hợp tác kinh doanh',
    live_Cons: 'Tư vấn trực tiếp',
    acc_Login: 'Đăng nhập tài khoản/mật khẩu',
    user_Agre: '《Thỏa thuận dịch vụ người dùng》',
    privacy: '《Chính sách bảo mật》',
    enter_KW: 'Nhập từ khóa',
    ended: 'Đã kết thúc',
    planet: 'Lựa chọn của người biên tập',
    rec_Tags: 'Thẻ được đề xuất',
    feat_Topic: 'Đề tài',
    sel_Persp: 'Lượt xem của KOL',
    next_Art: 'Bài viết tiếp theo',
    newsflash: 'tin tức nhanh',
    page_Rfr: 'Mở để tự động làm mới trang mỗi 30 giây',
    orig_Link: 'Liên kết bài viết gốc',
    reset_Pwd: 'Đặt lại mật khẩu',
    load_More: 'Tải thêm',
    welc_Odaily: 'Chào mừng đến với cộng đồng chính thức của Daily',
    telegram_SG: 'Nhóm đăng ký Telegram：',
    telegram_CG: 'Nhóm trò chuyện Telegram：',
    twitter: 'Tài khoản Twitter chính thức：',
    scan: 'สแกนด้วย WeChat',
    view_all: 'Xem tất cả',
    source: 'Nguồn',
    related_articles: 'Bài viết liên quan',
    related_links: 'Liên kết liên quan',
    share_article: 'Chia sẻ bài viết này',
    article: 'Bài viết',
    event_intro: 'Chi tiết sự kiện',
    featured_articles: 'Bài viết nổi bật',
    all_articles: 'Tất cả bài viết',
    recommended_reading: 'Đọc nhiều nhất',
    recommended_news: 'yêu cầu đọc',
    link_phone_number: 'Liên kết số điện thoại',
    library: 'Thư viện',
    account_settings: 'Cài đặt tài khoản',
    video: 'Video',
    video_recommend: 'Video giới thiệu',
    personal_center: 'Trung tâm cá nhân',
    jump_to_backend: 'Chuyển đến giao diện quản trị',
    logout: 'Đăng xuất',
    my_collection: 'Bộ sưu tập của tôi',
    recommended_library: 'Thư viện đề xuất',
    create_library: 'Tạo thư viện',
    new_library: 'Thư viện mới',
    delete_library: 'Xóa thư viện',
    library_name: 'Tên thư viện',
    library_cover: 'Ảnh bìa thư viện',
    library_Intro: 'Hồ sơ lưu trữ',
    optional: 'Tùy chọn',
    complete: 'Hoàn thành',
    basic_information: 'Thông tin cơ bản',
    bind_third_party_account: 'Liên kết tài khoản bên thứ ba',
    change_password: 'Thay đổi mật khẩu',
    change_phone_number: 'Thay đổi số điện thoại',
    no_related_content: 'Không có nội dung liên quan',
    edit: 'Chỉnh sửa',
    creation_time: 'Thời gian tạo',
    collected: 'Đã thu thập',
    collect: 'Thu thập',
    delete: 'Xóa',
    cancel: 'Hủy',
    loading_title: 'Đang tải...',
    confirm: 'Xác nhận',
    enter_1_20_characters: 'Vui lòng nhập từ 1 đến 20 ký tự',
    confirm_delete_library: 'Bạn có chắc muốn xóa thư viện này không?',
    delete_collection_folder:
      'Xóa thư mục bộ sưu tập sẽ xóa cả nội dung đã thu thập',
    odaily_explore_real_blockchain: 'ODAILY - Khám phá blockchain thực tế',
    explore_real_blockchain: 'Khám phá blockchain thực tế',
    share_successfully: 'Chia sẻ thành công',
    your_browser_version_is_too_low: 'Phiên bản trình duyệt của bạn quá thấp',
    upgrade_your_ie_browser:
      'Để sử dụng tốt hơn, vui lòng nâng cấp trình duyệt IE của bạn',
    technical_support: 'Hỗ trợ kỹ thuật',
    hello: 'Xin chào',
    if_you_have_any_questions_about_odaily:
      'Nếu bạn có bất kỳ câu hỏi nào về ODAILY, vui lòng liên hệ với nhân viên của chúng tôi trong thời gian sau đây. Cảm ơn bạn đã hỗ trợ.',
    dear_friends: 'Bạn thân mến',
    service_hours: 'Thời gian hoạt động',
    contact_number: 'Số liên lạc',
    contact_email: 'Email liên lạc',
    wechat: 'WeChat',
    more_activities: 'Nhiều hoạt động hơn',
    all_activities: 'Tất cả hoạt động',
    event_description: 'Mô tả sự kiện',
    current_votes: 'Số phiếu bầu hiện tại',
    vote: 'Bình chọn',
    go_to_vote: 'Đi đến bình chọn',
    voted: 'Đã bình chọn',
    disclaimer_title:
      'Tuyên bố miễn trừ trách nhiệm: bài viết này được chuyển tải và không thể hiện quan điểm của ODAILY. Nếu có sự phát hành, vui lòng thông báo tại nguồn gốc',
    odaily_reminder: 'ODAILY cần nhắc nhở:',
    please_establish_correct_concepts:
      'Vui lòng xác định đúng khái niệm về tiềnQuét bằng WeChat',
    latest: 'Bài viết mới nhất',
    share: 'Chia sẻ',
    copyLink: 'Sao chép liên kết',
    weibo: 'Weibo',
    mobile: 'Di động',
    save_library: 'Lưu vào thư viện',
    edit_library: 'Chỉnh sửa thư viện',
    select: 'Chọn thư viện',
    selectToAdd: 'Vui lòng chọn thư viện mà bạn muốn thêm',
    default: 'Mặc định',
    krExclusive:
      'Phương tiện truyền thông blockchain chiến lược độc quyền của 36Kr',
    upgrade: 'Nâng cấp',
    scanQRCode: 'Quét mã QR trên WeChat',
    click_screen: 'Nhấp vào màn hình',
    click_share: 'Nhấp vào nút "chia sẻ" ở góc trên bên phải',
    shareTo: 'Chia sẻ',
    notStarted: 'Chưa bắt đầu',
    ongoing: 'Đang diễn ra',
    featured: 'Chuyên mục đề xuất',
    all: 'Tất cả',
    author: 'Tác giả',
    pw_text1: 'Mật khẩu hiện tại',
    pw_text2: 'Mật khẩu mới',
    pw_text3: 'Hãy nhập mật khẩu 8-16 ký tự và số',
    pw_text4: 'Quên mật khẩu',
    set_pw: 'Thiết lập mật khẩu',
    set_succ: 'Thiết lập thành công',
    noResult: function NoResult(keywords) {
      return (
        <div>
          Xin lỗi, không tìm thấy kết quả liên quan đến {keywords}. <br />
          Hãy thử tìm kiếm với từ khóa khác.
        </div>
      );
    },
    project: 'Dự án',
    institution: 'Tổ chức',
    results: function Result(num) {
      return <div>Tìm thấy {num} kết quả</div>;
    },
    daily_market: 'Market Trends',
    market_page_add_success: 'Add Success',
    market_page_cancel_success: 'Cancel Success',
    unit_wan: 'M',
    unit_yi: 'B',
    market_page_tabbar_auto: 'Favorites',
    market_page_tabbar_market: 'Spot',
    market_page_rank_up: 'Change',
    market_page_rank_new: 'Newest',
    market_page_rank_deal: 'Volume',
    market_page_table_title_symbol: 'Pair',
    market_page_table_title_price: 'Last Price',
    market_page_table_title_up_rate: 'Change',
    market_page_table_title_rate: 'Change',
    market_page_table_title_low_price: 'Low',
    market_page_table_title_high_price: 'High',
    market_page_table_title_vol_24h: '24h Vol',
    market_page_table_title_market_value: 'Market',
    market_page_table_title_kline_7d: 'Last 7d',
    market_page_table_title_vol: 'Vol'
  }
};

function locales(lang, name, options = {}) {
  // 如果语言代码不存在，则默认使用中文
  const langCode = translations[lang] ? lang : 'zhcn';
  // 如果指定的键名不存在，则返回本身
  const translation = translations[langCode][name] || name;
  // 对象值是否为函数
  if (isFunction(translation)) {
    return translation(options.content || '');
  }
  // 当不为简体中文和英文时 且 isTitle为true则采用另一种字体
  if (langCode && options.isTitle) {
    return <LocaleFont style={options.style}>{translation}</LocaleFont>;
  }
  return translation;
}

// 不需要国际化的路由
export const noI18nPath = [
  '/help/protocol',
  '/help/privacy',
  // '/aboutus',
  '/download',
  '/chain',
  '/menu',
  '/activityed',
  '/Activityed2020',
  '/AcyivityVote',
  '/ActivityedFat',
  '/activityDetail',
  '/ActivityedVote/:id',
  '/fat2024vote/:id',
  '/fat2024'
];

// 设置国际化路由
export function setI18nArr(routes) {
  let routeArr = [];
  routes.forEach(item => {
    const { path, ...other } = item;
    // 跳过不需要的国际化路由
    if (~noI18nPath.includes(path)) {
      routeArr.push({
        path: `/:lang${path}`,
        ...other
      });
    }
  });
  let router_ret = [...routes, ...routeArr];
  return router_ret;
}

export default locales;
