import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

// import Select from 'react-select';
// import _ from 'lodash';

import Toast from '../../../components/common/toast';

import {
  getCaptchaConfig,
  sendMsg,
  verify_code,
  updatePhone
} from '../../../common/api/user';
import {
  verificationVerify,
  verificationPhone
} from '../../../common/scripts/login';

import locales from './lang';

export default class ChangePhone extends React.PureComponent {
  static propTypes = {
    phone: PropTypes.string.isRequired,
    areaData: PropTypes.object.isRequired,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneFlag: true,
      phoneVerifyFocus: false,
      newPhoneFocus: false,
      waitingSeconds: 60,
      waiting: false,
      verifyError: '',
      phoneError: '',
      areaFlag: false,
      areaValue: 'zh-CN',
      areaRule:
        '/^(\\+?0?86\\-?)?[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][2,5-7])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/'
    };
  }

  componentDidMount() {
    this.getCaptchaConfig();
  }

  initPhone() {
    this.setState({
      waitingSeconds: 60,
      waiting: false,
      verifyError: '',
      phoneError: ''
    });
    this.phoneVerify.value = '';
    this.newPhone.value = '';
  }

  countdown() {
    let { waitingSeconds } = this.state;

    if (waitingSeconds - 1 > 0) {
      this.setState({
        waitingSeconds: waitingSeconds - 1
      });
      setTimeout(() => {
        this.countdown();
      }, 1000);
    } else {
      this.setState({
        waiting: false,
        waitingSeconds: 60
      });
    }
  }

  getCaptchaConfig() {
    getCaptchaConfig().then(res => {
      if (res.code === 0) {
        const config = res.data;
        window.initGeetest(
          {
            gt: config.geetest_gt,
            challenge: config.geetest_challenge,
            product: 'bind'
          },
          this.captchaHandler.bind(this)
        );
      }
    });
  }

  captchaHandler(captchaObj) {
    this.captchaObj = captchaObj;

    captchaObj.onSuccess(() => {
      const { phone } = this.props;
      const { phoneFlag } = this.state;
      const { lang } = this.props.match.params;

      let newPhoneValue = this.newPhone ? this.newPhone.value : '';
      let phoneValue = phoneFlag ? phone : newPhoneValue;
      this.setState({ waiting: true });
      this.countdown();
      sendMsg(
        Object.assign(
          {},
          {
            phone: phoneValue
          },
          this.captchaObj.getValidate()
        )
      )
        .then(res => {
          if (res.code === 0) {
            Toast.done({
              msg: locales(lang, 'text9')
            });
          } else {
            Toast.error({
              msg: res.msg
            });
            this.setState({
              waiting: false,
              waitingSeconds: 60
            });
          }
        })
        .catch(res => {
          Toast.error({
            msg: res.msg
          });
          this.setState({
            waiting: false,
            waitingSeconds: 60
          });
        });
    });
  }

  changePhoneVerifyFocus(bool) {
    this.setState({
      phoneVerifyFocus: bool
    });
  }

  changeNewPhoneFocus(bool) {
    this.setState({
      newPhoneFocus: bool
    });
  }

  sendCode() {
    let { waiting, phoneFlag, areaRule } = this.state;
    const { lang } = this.props.match.params;
    let newPhoneValue = this.newPhone ? this.newPhone.value : '';
    if (!phoneFlag) {
      let phoneError = verificationPhone(newPhoneValue, areaRule, lang);
      this.setState({ phoneError });
      if (phoneError) {
        return;
      }
    }
    if (!waiting) {
      this.captchaObj.verify();
    }
  }

  next() {
    let phoneVerifyValue = this.phoneVerify.value;
    let { lang } = this.props.match.params;
    let verifyError = verificationVerify(phoneVerifyValue, lang);
    this.setState({ verifyError });
    if (verifyError) {
      return;
    }
    let obj = { code: phoneVerifyValue };

    verify_code(obj)
      .then(data => {
        if (data.code === 0) {
          this.setState({
            phoneFlag: false
          });
          this.initPhone();
        } else {
          Toast.error({
            msg: data.msg
          });
        }
      })
      .catch(data => {
        Toast.error({
          msg: data.msg
        });
      });
  }

  save() {
    let { areaRule } = this.state;
    const { lang } = this.props.match.params;
    let phoneVerifyValue = this.phoneVerify.value;
    let newPhoneValue = this.newPhone ? this.newPhone.value : '';
    let verifyError = verificationVerify(phoneVerifyValue, lang);
    let phoneError = verificationPhone(newPhoneValue, areaRule, lang);
    this.setState({ verifyError, phoneError });
    if (verifyError || phoneError) {
      return;
    }

    let obj = { new_phone: newPhoneValue, new_code: phoneVerifyValue };
    updatePhone(obj)
      .then(data => {
        if (data.code === 0) {
          this.setState({ phoneFlag: false });
          this.initPhone();
          Toast.done({
            msg: locales(lang, 'text10')
          });
        } else {
          Toast.error({
            msg: data.msg
          });
        }
      })
      .catch(data => {
        Toast.error({
          msg: data.msg
        });
      });
  }

  changeAreaFlag(bool) {
    this.setState({ areaFlag: bool });
  }

  changeAreaValue(val) {
    const { areaData } = this.props;
    this.setState({ areaValue: val });
    if (areaData && areaData[val]) {
      this.setState({ areaRule: areaData[val].rule });
    }
    this.changeAreaFlag(false);
  }

  renderPhone(lang) {
    const { phone } = this.props;
    return (
      <div className={styles.my_phone_inp_box}>
        <div className={styles.my_phone_inp_text}>{locales(lang, 'text1')}</div>
        <div className={styles.my_phone_inp_text}>
          {phone.slice(0, 3)}xxxx{phone.slice(7)}
        </div>
      </div>
    );
  }

  renderArea() {
    const { areaData } = this.props;
    const { areaFlag } = this.state;
    return (
      <div
        className={styles.area_list_box}
        style={{
          zIndex: areaFlag ? null : -1,
          transition: areaFlag
            ? 'z-index 0.1s linear'
            : 'z-index 0.1s linear 0.5s'
        }}
      >
        <div
          className={styles.area_list}
          style={{ transform: areaFlag ? null : `translate(0,-110%)` }}
        >
          <div className={styles.area_list_wrapper}>
            {Object.keys(areaData).map(item => {
              return (
                <div
                  className={styles.area_item}
                  key={`area_item${areaData[item].code}`}
                  onClick={() => {
                    this.changeAreaValue(item);
                  }}
                >
                  {areaData[item].name}(+{areaData[item].code})
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderNewPhone(lang) {
    let { newPhoneFocus, areaFlag, areaValue } = this.state;
    let { areaData } = this.props;
    return (
      <div className={styles.my_phone_inp_box}>
        <div className={styles.my_phone_inp_text}>
          {locales(lang, 'text2')}&#12288;
        </div>
        <div
          className={`${styles.my_phone_inp} ${
            newPhoneFocus ? styles.my_phone_inp_active : ''
          }`}
        >
          <div
            className={styles.login_box_areaCode}
            onClick={() => {
              this.changeAreaFlag(!areaFlag);
            }}
          >
            {areaData && areaData[areaValue]
              ? `+${areaData[areaValue].code}`
              : +86}
          </div>
          <div
            className={styles.login_box_arrow}
            onClick={() => {
              this.changeAreaFlag(!areaFlag);
            }}
            style={{ transform: areaFlag ? `rotate(180deg)` : null }}
          />
          {this.renderArea()}
          <input
            type="text"
            placeholder={locales(lang, 'text3')}
            ref={e => {
              this.newPhone = e;
            }}
            onFocus={() => {
              this.changeNewPhoneFocus(true);
            }}
            onBlur={() => {
              this.changeNewPhoneFocus(false);
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      phoneVerifyFocus,
      waiting,
      waitingSeconds,
      verifyError,
      phoneError,
      phoneFlag
    } = this.state;
    const { lang } = this.props.match.params;
    return (
      <div className={styles.my_phone}>
        {phoneFlag ? this.renderPhone(lang) : this.renderNewPhone(lang)}
        <div className={styles.my_phone_inp_error}>{phoneError}</div>

        <div className={styles.my_phone_inp_box}>
          <div className={styles.my_phone_inp_text}>
            {locales(lang, 'text4')}
          </div>

          <div
            className={`${styles.my_phone_inp_verify} ${
              phoneVerifyFocus ? styles.my_phone_inp_active : ''
            }`}
          >
            <input
              type="text"
              placeholder={locales(lang, 'text4')}
              ref={e => {
                this.phoneVerify = e;
              }}
              onFocus={() => {
                this.changePhoneVerifyFocus(true);
              }}
              onBlur={() => {
                this.changePhoneVerifyFocus(false);
              }}
            />
          </div>

          <div
            className={`${
              waiting
                ? styles.my_phone_verify_button_waiting
                : styles.my_phone_verify_button
            }`}
            onClick={() => {
              this.sendCode();
            }}
          >
            {waiting
              ? `${waitingSeconds}S ${locales(lang, 'text6')}`
              : locales(lang, 'text5')}
          </div>
        </div>
        <div className={styles.my_phone_inp_error}>{verifyError}</div>
        <div
          className={styles.my_phone_button}
          onClick={() => {
            phoneFlag ? this.next() : this.save(lang);
          }}
        >
          {phoneFlag ? locales(lang, 'text7') : locales(lang, 'text8')}
        </div>
      </div>
    );
  }
}
