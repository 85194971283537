import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

import styles from './index.scss';

import Layout from '../../components/common/layout';

import { footerList, mainList } from './data';
import { getVoteDate } from '../../common/api/columns';

import { getHelmet } from '../../common/scripts/helmet';

export class Fat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }

  componentDidMount() {
    // let ary = [];
    getVoteDate().then(data => {
      if (data.data.items) {
        this.setState({ list: data.data.items.slice(4) });
      }
    });
  }

  renderMain() {
    return (
      <div className={styles.main}>
        <div className={styles.main_title} />
        {mainList.map((item, index) => {
          return (
            <div className={styles.main_item} key={`main_item${index}`}>
              <div className={styles.main_item_title}>{item.title}</div>
              <div className={styles.main_item_text}>{item.text}</div>
              <div className={styles.main_item_list2}>
                {item.list.map((item, index) => {
                  return (
                    <div
                      className={styles.main_item_item}
                      key={`main_item_img${index}`}
                    >
                      <div
                        className={styles.main_item_img}
                        style={{ backgroundImage: `url(${item.img})` }}
                      />
                      <div className={styles.main_item_name}>{item.title}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderList() {
    let { list } = this.state;
    return (
      <div className={styles.list}>
        <div className={styles.list_title} />
        <div className={styles.list_main}>
          {list.map((item, i) => {
            return (
              <div className={styles.list_item} key={`list_item${i}`}>
                <div className={styles.main_item} key={`main_item${i}`}>
                  <div className={styles.main_item_title}>{item.title}</div>
                  <div className={styles.main_item_text}>
                    {item.introduction}
                  </div>

                  <div className={styles.list_wrapper}>
                    {item.items &&
                      item.items
                        .filter((item, index) => {
                          if (i === 0) {
                            return index < 10;
                          } else {
                            return index < 20;
                          }
                        })
                        .sort((a, b) => {
                          return a.name
                            .slice(0, 1)
                            .localeCompare(b.name.slice(0, 1), 'zh');
                        })
                        .map((item, index) => {
                          return (
                            <div
                              className={styles.list_items}
                              key={`list_items${index}`}
                            >
                              <div
                                className={
                                  i === 0
                                    ? styles.img_border
                                    : styles.img_border2
                                }
                              >
                                <div
                                  className={styles.img}
                                  style={{
                                    backgroundImage: `url(${item.picture_url})`
                                  }}
                                />
                              </div>
                              <div className={styles.list_name}>
                                {item.name}
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className={styles.footer}>
        {footerList.map((item, index) => {
          return (
            <div className={styles.footer_item} key={`footer_item${index}`}>
              <div className={styles.footer_title}>{item.title}</div>
              <div
                className={styles.footer_list}
                style={{ backgroundImage: `url(${item.img})` }}
              />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let helmetData = getHelmet({
      title: '2021「N—POWER」榜单',
      description:
        'ODAILY区块链榜单频道，提供最受社区欢迎的区块链项目，聚焦区块链年度榜单，包含区块链人物榜、事件榜、项目榜，为您奉献年度最具价值榜单。',
      keywords: '区块链榜单,行业区块链数据,行业区块链榜单'
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.header_img} />
          <div className={styles.wrapper}>
            <div className={styles.banner}>
              2021 年，在 NFT 的风口效应之下，链游、加密艺术、IP
              赋能等细分领域创新不断，元宇宙更是成为行业的终极愿景。NFT
              的诸多应用场景不断破圈、走向主流，越来越多新的优质项目涌现，也让众多投资者回聚焦生态价值，共同推动NFT市场高速发展。
              <br />
              <br />
              因此，ODAILY联合36kr决定开启 Odaily 2021 FAT 特别企划
              —「NFT榜单」评选，表彰NFT赛道里抢先布局的投资者、不断突破的创新项目、聚合流量的前沿平台、引领风潮的意见领袖，为价值创作者加冕。
            </div>

            {this.renderList()}
            {this.renderMain()}
            {this.renderFooter()}
          </div>

          {/* 弹出框 */}
          <div className={styles.alert}>
            <div className={styles.alertList}>
              <a href="/activityed2020" target={'_blank'} rel="noreferrer">
                区块链胖榜单
              </a>
              <div className={styles.border} />
              <a href="/Activityed#3" target={'_blank'} rel="noreferrer">
                区块链金融科技领先100强
              </a>
              <div className={styles.border} />
              <a href="/Activityed#4" target={'_blank'} rel="noreferrer">
                Filecoin挖矿厂商榜
              </a>
              <div className={styles.border} />
              <a href="/Activityed#1" target={'_blank'} rel="noreferrer">
                星球专栏榜单
              </a>
              <div className={styles.border} />
              <a href="/Activityed#2" target={'_blank'} rel="noreferrer">
                区块链KOL公信榜
              </a>
              <div className={styles.border} />
              <a href="/AcyivityVote" className={styles.alertItem}>
                N—POWER榜单
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Fat);
