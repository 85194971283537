import { combineReducers } from 'redux';

const detail = (state = {}, action) => {
  switch (action.type) {
    case 'SET_POST_DETAIL':
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

const secondary = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_SECONDARY':
      return action.list;
    default:
      return state;
  }
};

const articleList = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_ARTICLELIST':
      return action.list;
    default:
      return state;
  }
};

const feedList = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_FEEDLIST':
      return action.list;
    default:
      return state;
  }
};

const userPostList = (state = [], action) => {
  switch (action.type) {
    case 'SET_USER_POST':
      return action.list;
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case 'SET_POST_USER':
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

const comments = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_COMMENT':
      return action.list;
    case 'POST_DELETE_COMMENT':
      state.splice(action.index, 1);
      return [...state];
    default:
      return state;
  }
};

const counters = (state = {}, action) => {
  switch (action.type) {
    case 'SET_POST_COUNTERS':
      return action.counters;
    default:
      return state;
  }
};

// const secondaryTag = (state = [], action) => {
//   switch (action.type) {
//     case 'SET_POST_SECONDARYTAG':
//       return action.secondaryTag;
//     default:
//       return state;
//   }
// };

const postList = (state = [], action) => {
  switch (action.type) {
    case 'SET_NEWSFLASH_POSTLIST':
      return action.list;
    default:
      return state;
  }
};

const viewpointList = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_VIEWPOINT':
      return action.list;
    default:
      return state;
  }
};

const postFavoriteList = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_FAVORITE_LIST':
      return action.list;
    default:
      return state;
  }
};

const odataProject = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_ODATA_PROJECT':
      return action.list;
    default:
      return state;
  }
};

const odataOrganization = (state = [], action) => {
  switch (action.type) {
    case 'SET_POST_ODATA_ORGANIZATION':
      return action.list;
    default:
      return state;
  }
};

export default combineReducers({
  detail,
  user,
  comments,
  counters,
  // secondaryTag,
  secondary,
  articleList,
  userPostList,
  postList,
  feedList,
  viewpointList,
  postFavoriteList,
  odataProject,
  odataOrganization
});
