import React, { Component } from 'react';
import styles from '../../index.scss';
import PropTypes from 'prop-types';

export class PrivacyZhtw extends Component {
  static propTypes = {
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.content}>
        <h5>{this.props.title}</h5>
        <h6>一．引言</h6>

        <p>一．引言</p>
        <p>二．關於我們</p>
        <p>三．我們如何收集和使用您的個人信息</p>
        <p>四．我們如何使用Cookie和同類技術</p>
        <p>五．我們如何委託處理、對外提供、轉讓、公開披露您的個人信息</p>
        <p>六．我們如何存儲和保護您的個人信息</p>
        <p>七．您如何管理您的個人信息</p>
        <p>八．未成年人的個人信息保護</p>
        <p>九．隱私政策的修訂和通知</p>
        <p>十．我們對去世用戶的個人信息保護</p>
        <p>十一．您如何联系我們</p>
        <p>一．引言</p>

        <h6>一．引言</h6>
        <p>
          本《ODAILY隱私政策》（以下簡稱“本隱私政策”）適用於ODAILY（以下簡稱“本平台”或“我們”）的軟件及相關服務（以下簡稱“ODAILY服務”）。各條款標題僅為幫助您理解該條款表達的主旨之用，不影響或限製本隱私政策條款的含義或解釋。為維護您的自身權益，建議您仔細閱讀各條款具體表述。
        </p>
        <p>
          請您特別注意：本隱私政策適用於我們以網站、客戶端、小程序以及隨技術發展出現的新形態向您提供的各項ODAILY服務。如我們及關聯公司的產品或服務中使用了ODAILY服務，但未設獨立隱私權政策的，則本隱私政策同樣適用於該部分服務。我們及關聯公司就其向您提供的ODAILY服務單獨設有隱私權政策的，則相應產品或服務適用相應隱私權政策。
        </p>
        <p>
          您在申請註冊流程中或在本隱私政策更新時點擊同意本隱私政策之前，應當認真閱讀本隱私政策。請您務必審慎閱讀、充分理解各條款內容，
          <b>
            有關您個人信息權益的條款重要內容我們已用加粗形式提示，請特別關注。
          </b>
        </p>
        <p>
          當您按照註冊頁面提示填寫信息、閱讀並同意本隱私政策且完成全部註冊程序後或在本隱私政策更新時點擊同意，即表示您已充分閱讀、理解並接受本隱私政策的全部內容。
          <b>
            閱讀本隱私政策的過程中，如果您拒絕同意本隱私政策或其中任何條款約定，將可能導致您將無法正常使用相關ODAILY服務和部分功能，或者無法達到我們預期到達的服務效果。
          </b>
        </p>
        <h6>二．關於我們</h6>
        <p>
          1.本平台的運營者及ODAILY服務的提供者均為海南省莫蒂文化傳媒有限責任公司。
        </p>
        <p>
          2.關聯公司是指在現在、將來控制海南省莫蒂文化傳媒有限責任公司、受海南省莫蒂文化傳媒有限責任公司控製或與海南省莫蒂文化傳媒有限責任公司處於共同控制下的公司、機構。控制指通過所有權、有投票權的股份、合同、實際運營關聯或其他被依法認定的方式直接或間接地擁有影響被控制對像管理/經營的能力。
        </p>
        <h6>三．我們如何收集和使用您的個人信息</h6>
        <p>
          1.我們依據法律法規以及遵循正當、合法、必要的原則而收集和使用您在使用服務過程中主動提供或由於ODAILY服務需要而產生的個人信息。如果我們欲將您的個人信息用於本隱私政策未載明的其它用途，或基於特定目的將收集而來的信息用於其他目的，我們會及時以合理的方式向您告知，並在使用前再次徵得您的同意。
        </p>
        <p>
          2.我們收集和使用的您的個人信息類型包括兩種：第一種：ODAILY服務的核心業務功能所必需的信息：此類信息為本平台正常運行的必備信息，您須授權我們收集。如您拒絕提供，可能將導致您將無法正常使用相關ODAILY服務和部分功能；第二種：附加業務功能可能需要收集的信息：此信息為非核心業務功能所需的信息，您可以選擇是否授權我們收集。如您拒絕提供，將導致附加業務功能無法實現或無法達到我們擬達到的效果，但不影響您對核心業務功能的正常使用。
        </p>
        <p>
          3.
          <b>
            我們需要特別提醒您的是：由於ODAILY服務較多，為您提供的內容也不同，因此核心業務功能（包括其收集的您的個人信息類型）也會因ODAILY服務的內容不同而有所區別，具體以ODAILY服務實際提供為準。除此之外，您理解並同意，我們希望提供給您的ODAILY服務是完善的，所以我們會不斷改進ODAILY服務，包括技術，這意味著我們可能會經常推出新的業務功能，可能需要收集新的個人信息或變更個人信息使用目的或方式。如果某一需要收集您的個人信息的功能或服務未能在本隱私政策中予以說明的，我們會通過更新本隱私政策、頁面提示、彈窗、網站公告等方式另行向您說明該信息收集的目的、內容、使用方式和範圍，並為您提供自主選擇同意的方式，且在徵得您明示同意後收集。
          </b>
          在此過程中，如果您對相關事宜有任何疑惑的，可以通過本隱私政策第十一．一條提供的方式聯繫我們，我們會盡快為您作出解答。
        </p>
        <p>
          4.本隱私政策中敏感個人信息是指一旦洩露或者非法使用，容易導致自然人的人格尊嚴受到侵害或者人身、財產安全受到危害的個人信息，包括
          <b>生物識別、宗教信仰、特定身份、醫療健康、金融賬戶、行踪軌跡</b>
          等信息，以及<b>不滿十四周歲未成年人的個人信息</b>，例如，
          <b>
            敏感個人信息包括身份證件號碼、個人生物識別信息、銀行帳號、通信內容、健康生理信息
          </b>
          等。我們收集的敏感個人信息已在本隱私政策中通過顯著標識進行展示。一旦您的敏感個人信息被洩露、篡改或被非法使用，將會對您的人身或財產安全造成損害。為防止您的敏感個人信息被洩露或被非法使用，我們已採取適當的技術和組織保護措施，盡力保障您的信息安全。
        </p>
        <p>5. 通常情況下，我們會在以下場景中收集和使用您的個人信息：</p>
        <p>5.1 註冊及登錄狀態下ODAILY服務</p>
        <p>
          為便於我們為您提供持續穩定的服務，並保障您使用ODAILY服務的安全性，我們需要您主動提供基本註冊或登錄信息，包括手機號碼、電子郵箱地址，並創建您的帳號、用戶名和密碼，其中
          <b>手機號碼、密碼屬於個人敏感信息</b>
          ，收集前述信息是為了滿足相關法律法規的網絡實名制要求以及履行本隱私政策的要求。在部分單項服務中，如果您僅需使用瀏覽、搜索基本功能，您不需要註冊成為ODAILY用戶及提供上述信息。
        </p>
        <p>
          您也可以使用第三方平台賬號註冊ODAILY用戶，可選擇授權我們在符合相關法律法規要求的前提下讀取並獲得
          <b>您在該第三方平台上公佈、記錄的公開信息（包括暱稱、頭像）</b>
          。我們需要您授權從第三方獲取上述信息是為了記住您作為ODAILY用戶的登錄身份，收集前述信息是基於您的同意，在您使用第三方賬號註冊或登錄後，您的第三方賬號將會與ODAILY賬號進行綁定。
        </p>
        <p>
          基於我們與通信運營商的合作，當您使用ODAILY“一鍵登錄”功能時，經過您的明示同意，運營商會將您的手機號碼發送給我們，便於我們為您提供快捷的登錄服務。您也可以自主選擇手動填寫手機號碼的方式完成註冊或登錄。
        </p>
        <p>
          您在註冊、登錄或後續使用過程中，也可以主動填寫或補充您的其他額外信息（您的暱稱、頭像、性別、出生日期、身份、行業），收集前述信息是基於您的同意，這些信息可能會在您的個人主頁和評論區展示，用以標識您的ODAILY用戶身份，方便您和其他ODAILY用戶交流互動。但如果您不提供這些信息，並不會影響您使用ODAILY服務的基本功能。
        </p>
        <p>5.2 非註冊及登錄狀態下ODAILY服務</p>
        <p>
          如果您在未註冊或未登錄狀態下使用ODAILY服務，非登錄模式下我們僅能為您提供部分功能。為保障功能實現，我們可能會主動申請獲取您的設備信息、網絡標識信息，以便實現瀏覽、搜索、點贊、收藏、分享功能，收集前述信息是為了滿足相關法律法規的網絡日誌信息留存義務要求；
        </p>
        <p>
          除上述信息外，在您使用過程中，我們可能會主動申請獲取您的
          <b>設備權限</b>
          以實現其他附加功能（見本隱私政策本條第5.9款“為您提供安全保障”)。
        </p>
        <p>5.3 資訊瀏覽</p>
        <p>
          1） ODAILY可能會向您推薦更感興趣的信息，為實現這一功能，
          <b>我們可能會自動收集必要的日誌信息</b>，包括：
        </p>

        <ul className={styles.privacy_ul}>
          <li>
            a）您操作、使用的行為信息：點擊、關注、收藏、搜索、瀏覽、分享；
          </li>
          <li>b）設備信息：設備唯一識別碼；</li>
          <li>
            c）網絡信息：GPS信息、WLAN接入點、藍牙和基站等傳感器信息獲取到的精確地理位置信息、以及通過網絡位置信息（基站、IP及WLAN）獲取的大致地理位置信息；
          </li>
        </ul>

        <p>
          2）<b>GPS地理位置是個人敏感信息</b>
          ，若您拒絕提供，我們將不會根據GPS信息向您推薦信息，且不會影響本平台其他功能的正常使用。
        </p>

        <p>
          3）我們會基於大致地理位置信息對應的城市向您展示、推薦您所在地區的本地相關信息。
        </p>

        <p>
          4）收集前述信息是為了滿足相關法律法規的網絡日誌信息留存義務要求和履行本隱私政策的要求，
          <b>
            我們收集、使用的上述信息進行了去標識化處理，數據分析僅對應特定的、無法直接關聯您身份的編碼，不會與您的真實身份相關聯
          </b>
          。
        </p>

        <p>5.4 發布與互動</p>
        <p>1）信息發布</p>
        <ul className={styles.privacy_ul}>
          <li>
            a）您發佈內容或評論時，我們將自動收集您發布的信息，並展示您的暱稱、頭像、發佈內容。
          </li>
          <li>
            b）您使用上傳圖片、發布音視頻功能時，我們會請求您主動授權攝像頭（相機）、相冊（存儲空間）、麥克風權限。您如果拒絕授權提供，將無法使用此功能，但不影響您正常使用本平台的其他功能。
          </li>
          <li>
            c）請注意，您公開發布的信息中可能會涉及用戶或他人的個人信息，若您公開發布的信息中包含他人個人信息的，在上傳發布之前，您需確保為法律法規允許使用或已獲得合法授權。
          </li>
          <li>
            d）您在使用開通直播功能時，根據法律法規、安全保障要求和履行本隱私政策的要求，需要進行實名認證。在實名認證的過程中，認證服務的第三方認證機構需要您主動提供
            <b>真實姓名及身份證號碼信息。這些信息是個人敏感信息</b>
            ，您可以拒絕提供，且不會影響本平台其他功能的正常使用。這些信息僅供實名認證及法律法規所規定的用途，未經您明示授權，不會用作其他目的。
          </li>
          <li>
            e）收集前述信息是為了滿足相關法律法規的服務提供方留存信息發布賬號、公眾賬號、直播用戶等發布的信息內容的義務要求和履行本隱私政策的要求。
          </li>
        </ul>

        <p>2）互動交流</p>
        <ul className={styles.privacy_ul}>
          <li>
            a）您關注內容時，我們會收集您關注的內容或賬號，並向您展示您該內容或該賬號發佈內容。
          </li>
          <li>5.5 搜索</li>
        </ul>

        <p>5.5 搜索</p>
        <p>
          1）您使用搜索服務時，我們會自動收集您的搜索關鍵字信息、日誌信息記錄進行分析以便向您展現相關程度更高的搜索結果。
        </p>
        <p>
          2）我們還可能會自動記錄您在使用我們服務時提供、形成或留存的信息，如您在使用搜索時輸入查詢詞,點擊歷史詞、提示詞和點擊的鏈接,以及您參與搜索運營活動時提交的信息。您有權拒絕提供上述信息,但這將導致您可能無法使用特定服務或功能,或者無法達到相關服務擬達到的效果。
        </p>
        <p>消息通知</p>

        <p>
          5.6 <b>消息通知</b>
        </p>
        <p>
          <b>
            您知悉並同意，對於您在使用產品及/或服務的過程中主動提供的您的聯繫方式（聯繫電話），我們在運營中可能會向其中的一種或多種發送多類通知，用於用戶消息告知、身份驗證、安全驗證、用戶使用體驗調研、爭議處理用途；此外，我們也可能會向前述過程中收集的手機號碼以短信、電話的方式，為您提供您可能感興趣的服務、功能或活動商業性信息的用途，但請您放心，如您不願接受這些信息，您可以通過手機短信中提供的退訂方式進行退訂，也可以直接與我們聯繫進行退訂。
          </b>
        </p>

        <p>5.7 客戶服務</p>
        <p>
          當您向我們進行意見反饋時，為了方便與您聯繫或幫助您解決問題，我們可能需要您主動提供姓名、手機號碼、電子郵箱及其他聯繫方式，並可能使用這些信息以與您取得聯繫。如果您提交的是侵權投訴，我們還會依據法律規定要求您提交
          <b>身份證、授權委託、權屬證明</b>，以明確您作為投訴主體的資格。
        </p>

        <p>5.8 交易服務</p>
        <p>
          當您購買在線閱讀、在線課程、積分商城商品以及通過其他方式購買我們的產品或服務時，我們將自動收集您的交易記錄和日誌信息。收集前述信息是為了滿足相關法律法規的網絡日誌信息留存義務和交易信息留存義務要求和履行本隱私政策的要求，其中
          <b>交易記錄信息是個人敏感信息</b>
          ，拒絕提供這信息可能會使我們無法提供交易服務，但不影響您正常使用ODAILY服務的其他功能。
        </p>

        <p>5.9 為您提供安全保障</p>
        <p>
          GPS定位信息<b>GPS定位信息</b>
          、IP地址、電信運營商、網絡環境、軟件版本號、瀏覽器類型、
          <b>網站瀏覽記錄</b>
          、操作日誌）。來判斷您的賬號風險，以及預防、發現、調查危害系統運行安全、非法或違反我們規則、協議的行為。我們也會收集您的前述設備信息、軟件信息用於對我們產品或服務系統問題進行分析、統計流量並排查可能存在的風險。如果您不提供上述信息，我們則無法在您使用我們的產品或服務的過程中對產品或服務及您的賬號安全進行保護。
        </p>

        <p>5.10 提高使用感受和滿意度</p>
        <p>
          為提高您對ODAILY服務使用感受和滿意度，我們將自動收集您的操作行為，並基於您的操作行為對用戶數據進行調查研究和分析，從而進一步優化ODAILY服務。
        </p>

        <p>
          5.11 <b>徵得同意的例外</b>
        </p>
        <p>
          <b>
            請您知悉，以下情形中，我們收集、使用個人信息無需徵得您的授權同意：
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1）與國家安全、國防安全有關的；</li>
          <li>2）與公共安全、公共衛生、重大公共利益有關的；</li>
          <li>3）與犯罪偵查、起訴、審判和判決執行等有關的；</li>
          <li>
            4）出於維護個人信息主體或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；
          </li>
          <li>5）所收集的個人信息是個人信息主體自行向社會公眾公開的；</li>
          <li>
            6）從合法公開披露的信息中收集的您的個人信息的，如合法的新聞報導、政府信息公開等渠道；但是您明確拒絕或者處理該信息侵害您重大利益的除外；
          </li>
          <li>7）根據您的要求籤訂合同所必需的；</li>
          <li>
            8）用於維護所提供的產品與/或服務的安全穩定運行所必需的，例如發現、處置產品與/或服務的故障；
          </li>
          <li>9）為合法的新聞報導所必需的；</li>
          <li>
            10）學術研究機構基於公共利益開展統計或學術研究所必要，且對外提供學術研究或描述的結果時，對結果中所包含的個人信息進行去標識化處理的；
          </li>
          <li>11）法律法規規定的其他情形。</li>
        </ul>
        <p>
          <b>
            請注意，單獨或與其他信息相結合無法識別您的身份或者與您直接建立聯繫的信息，不屬於個人信息。如果我們將單獨無法與任何特定個人建立聯繫的信息與其他信息結合用於識別自然人個人身份，或者將其與個人信息結合使用，則在結合使用期間，此類信息將被視為個人信息。
          </b>
        </p>

        <p>5.12 設備權限調用</p>
        <p>
          為確保相關ODAILY服務的正常實現，我們需要根據具體的使用場景調用對應的必要權限，具體的權限調用說明請見
          <a href="?application-authority" target="_blank">
            《應用權限申請與使用說明》
          </a>
          。
        </p>
        <p>
          您可以在設備的設置中選擇關閉部分或者全部權限，這可能導致對應的相關ODAILY服務無法實現或者無法達到預期效果。
        </p>

        <p>5.13 個性化推薦</p>
        <p>
          請知悉，ODAILY服務不涉及個性化內容的推薦與展示，我們不會收集您的瀏覽及搜索信息、行為偏好等信息進行畫像分析。
        </p>

        <h6>四．我們如何使用Cookie和同類技術</h6>
        <p>
          1.Cookie可以幫助網站辨認註冊用戶，計算用戶數量，通常被各網站用來判定完成註冊的用戶是否已經實現登錄。本平台承諾，對Cookie信息的研究僅用於提升服務/產品質量及優化用戶體驗之目的。同時，如不希望個人信息保留在cookie中，您可以對瀏覽器進行配置：選擇“拒絕Cookie”或“當網站發送Cookie時通知您”。您在此確認，鑑於ODAILY服務是通過支持Cookie來是實現的，完成關閉Cookie的操作後，可能會影響到您訪問本平台或不能充分取得本平台的服務。當您不禁用Cookie時，可能會得到提示：是否在下一次進入此網站時保留用戶信息以便簡化登錄手續（如一鍵登錄）。
        </p>
        <p>
          2.本平台使用web
          beacon的方法和目的與Cookie是相同的，上述條款應參照適用。
        </p>

        <h6>五．我們如何委託處理、對外提供、轉讓、公開披露您的個人信息</h6>
        <p>1.委託處理您的個人信息</p>
        <p>
          為了向您提供更完善、優質的ODAILY服務，某些功能可能由我們的服務合作方提供（如我們的第三方服務供應商、承包商、代理），我們會委託服務合作方代表我們處理您的某些個人信息，包括但不限於為ODAILY提供一鍵登錄服務、推送消息服務、數據分析與統計服務的合作夥伴。我們會通過書面協議、現場審計等方式要求服務合作方遵守嚴格的保密義務及採取有效的保密措施，禁止其將這些信息用於未經您授權的用途。委託關係解除後，服務合作方將不再保存您的個人信息。
        </p>
        <p>2.對外提供</p>
        <p>
          我們不會向本產品服務提供者以外的公司、組織和個人提供您的個人信息，但以下情況除外：
        </p>
        <p>
          2.1
          履行法定義務所必需：我們可能會根據法律法規規定、訴訟、爭議解決的必要，或按行政、司法機關依法提出的要求，以及其他法定義務履行的必需，或具備法律規定的其他合法事由，如為應對突發公共衛生事件或者緊急情況下為保護您的生命健康和財產安全所必需，或者為公共利益實施新聞報導、輿論監督等行為，對外提供您的個人信息。
        </p>
        <p>
          2.2
          基於您的同意或您主動提出要求：如您單獨同意或主動要求我們向第三方提供您的個人信息的，我們將基於您同意的目的，在相應頁面中以適當方式告知您個人信息接收方的名稱和聯繫信息。如您使用信息分享服務的，我們會在頁面提供第三方分享平台名稱，具體聯繫方式可查看第三方隱私政策。
        </p>
        <p>
          2.3
          基於履行服務協議所必需：我們的部分ODAILY服務可能是我們的關聯公司和合作機構或我們與其共同向您提供。因此，為履行與您訂立的服務協議，我們必須將您的個人信息提供給我們的關聯公司或合作機構。我們制定了
          <a href="?info-share-list" target="_blank">
            《信息共享清單》
          </a>
          向您清晰、簡潔地介紹我們關聯公司、合作機構的情況，查看《信息共享清單》
        </p>

        <p>
          1）向關聯公司提供：為便於在您使用我們與關聯公司或其他產品提供的產品或服務，您的個人信息可能會與我們的關聯公司和/或其指定的服務提供商共享。我們只會共享必要的個人信息，且受本政策中所聲明目的的約束，如果我們共享您的敏感個人信息或關聯公司改變個人信息的使用及處理目的，將再次徵求您的授權同意。
        </p>

        <p>
          2）向我們的合作機構提供：為了向您提供完善的產品和服務，我們的某些服務或技術將由授權合作機構提供。請您知悉，我們不會主動向其他個人信息處理者提供您的個人信息，我們僅會出於合法、正當、必要、特定、明確的目的共享您的個人信息，並且只會共享必要的個人信息。我們將向其他個人信息處理者提供您的個人信息，向您告知接收方的名稱或者姓名、聯繫方式、處理目的、處理方式和個人信息的種類，並取得您的單獨同意。同時，我們會與合作機構簽署嚴格的保密協定，要求他們嚴格按照本隱私政策以及其他任何相關的保密政策並採取安全措施來處理您的個人信息。我們要求我們的合作機構無權將共享的個人信息用於任何其他用途。
          <b>
            當您查看第三方創建的網頁或使用第三方開發的應用程序時，這些第三方可能會放置他們自己的Cookie
            或使用其他技術手段，這些技術手段不受我們的控制，且它們的使用不受本隱私政策的約束。合作機構可能有其獨立的個人信息保護政策和用戶協議，我們建議您閱讀並遵守第三方的個人信息保護政策及用戶協議。
          </b>
        </p>

        <p>
          2.4
          基於法律規定的其他合法事由：我們可能會基於法律規定的其他合法事由，如為應對突發公共衛生事件或者緊急情況下為保護您的生命健康和財產安全所必需，或者為公共利益實施新聞報導、輿論監督等行為，向第三方提供您的個人信息的，我們會在適當時機（如緊急情況消除後）向您告知有關信息。
        </p>

        <p>3.轉讓</p>
        <p>
          我們不會將您的個人信息轉讓給任何公司、組織和個人，但以下情況除外：
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1）在獲取明確同意的情況下轉讓。獲得您的明確同意後，我們會向其他方轉讓您的個人信息。
          </li>
          <li>
            2）
            在涉及合併、收購或破產清算時轉讓。在涉及ODAILY合併、收購或破產清算時，如涉及到個人信息轉讓，我們會要求新的控制您個人信息的公司、組織繼續受本隱私政策的約束，否則我們將要求該公司、組織重新向您徵求授權同意。
          </li>
        </ul>

        <p>4.公開披露</p>
        <p>
          4.1公開披露是指向社會或不特定人群發布信息的行為。除了因需要對違規賬號、欺詐行為等進行處罰、公佈中獎/獲勝者等名單時脫敏展示相關信息等必要事宜而進行的必要披露外，我們不會對您的個人信息進行公開披露，如具備合理事由確需公開披露的，我們會在公開披露前向您告知公開披露的信息的目的、類型（如涉及您的個人敏感信息的，我們還會向您告知涉及的敏感信息的內容），並在徵得您的授權同意後再公開披露，但法律法規另有規定的或本隱私政策另有約定的除外。
        </p>
        <p>
          4.2對於公開披露的您的個人信息，我們會在收到公開披露申請後第一時間且審慎審查其正當性、合理性、合法性，並在公開披露時和公開披露後採取最嚴格個人信息安全保護措施和手段對其進行保護。
        </p>

        <p>5.接入第三方SDK情況</p>
        <p>
          為保障您能夠使用和享受更好的ODAILY服務，我們提供部分功能由我們合作的第三方機構以SDK插件的形式提供，SDK技術服務提供方會基於向您提供功能或服務之必須獲取相應權限及信息。我們會對App中集成的SDK均進行安全監測，確保這些SDK僅收集了為實現其功能或服務所必需的個人信息。您可以點擊此處查看
          <a href="?info-share-list" target="_blank">
            《信息共享清單》。
          </a>
        </p>

        <p>
          6.委託處理、對外提供、轉讓、公開披露個人信息時事先徵得授權同意的例外
        </p>
        <p>
          <b>
            根據相關法律法規規定，以下情形中，共享、轉讓、公開披露您的個人信息無需事先徵得您的授權同意：
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1）與我們履行法律法規規定的義務相關的；</li>
          <li>2）與國家安全、國防安全直接相關的；</li>
          <li>3）與公共安全、公共衛生、重大公共利益直接想關的；</li>
          <li>
            4）與犯罪偵查、起訴、審判和判決執行等司法或行政執法直接相關的；
          </li>
          <li>
            5）出於維護您或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；
          </li>
          <li>6）您自行向社會公眾公開的個人信息；</li>
          <li>
            7）從合法公開披露的信息中收集個人信息的，如合法的新聞報導、政府信息公開等渠道。
          </li>
        </ul>
        <p>
          <b>
            請知悉，根據適用的法律，若我們對個人信息採取技術措施和其他必要措施進行處理，使得數據接收方無法重新識別特定個人且不能複原，則此類處理後數據的委託處理、對外提供、轉讓、公開披露無需另行向您通知並徵得您的同意
          </b>
        </p>

        <h6>六．我們如何存儲和保護您的個人信息</h6>
        <p>1.存儲</p>
        <p>
          1.1
          存儲地點：我們依照法律法規的規定，將在中華人民共和國境內運營過程中收集和產生的您的個人信息存儲於中華人民共和國境內。目前，我們不會將上述信息傳輸至境外，如果我們向境外傳輸，會嚴格遵守中國的相關法律、監管政策，並會遵循相關國家規定或者徵求您的同意。
        </p>
        <p>
          1.2
          存儲時間：我們承諾始終按照法律的規定在合理必要期限內在存儲您個人信息。超出上述期限後，我們將刪除您的個人信息或對您的個人信息進行匿名化處理。
        </p>
        <p>
          1.3
          如我們停止運營，我們將及時停止收集您個人信息的活動，將停止運營的通知以逐一送達或公告的形式通知您，並對所持有的您的個人信息進行刪除或匿名化處理。
        </p>

        <p>2.保護</p>
        <p>
          2.1
          我們非常重視您個人信息的安全，將努力採取合理的安全措施（包括技術方面和管理方面）來保護您的個人信息，防止您提供的個人信息被不當使用或未經授權的情況下被訪問、公開披露、使用、修改、損壞、丟失或洩漏。目前，我們已經通過了
          <b>國家信息安全等級保護（三級）的測評和備案。</b>
        </p>
        <p>
          2.2
          我們會使用不低於行業同行的加密技術、匿名化處理及相關合理可行的手段保護您的個人信息，並使用安全保護機制防止您的個人信息遭到惡意攻擊。
        </p>
        <p>
          2.3
          我們會建立專門的安全管理制度、數據安全流程保障您的個人信息安全。我們採取嚴格的數據使用和訪問制度，確保只有授權人員才可訪問您的個人信息。
        </p>
        <p>
          2.4
          <b>
            儘管已經採取了上述合理有效措施，並已經遵守了相關法律規定要求的標準，但請您理解，由於技術的限制以及可能存在的各種惡意手段，在互聯網行業，即便竭盡所能加強安全措施，也不可能始終保證信息百分之百的安全，我們將盡力確保您提供給我們的個人信息的安全性。
          </b>
        </p>
        <p>
          2.5
          <b>
            您知悉並理解，您接入我們的服務所用的系統和通訊網絡，有可能因我們可控範圍外的因素而出現問題。因此，我們強烈建議您採取積極措施保護個人信息的安全，包括但不限於使用複雜密碼、定期修改密碼、不將自己的賬號密碼及相關個人信息透露給他人。
          </b>
        </p>
        <p>
          2.6
          我們會制定應急處理預案，並在發生用戶信息安全事件時立即啟動應急預案，努力阻止這些安全事件的影響和後果擴大。一旦發生用戶信息安全事件（洩露、丟失）後，我們將按照法律法規的要求，及時向您告知：安全事件的基本情況和可能的影響、我們已經採取或將要採取的處置措施、您可自主防範和降低風險的建議、對您的補救措施。我們將及時將事件相關情況以推送通知、郵件、信函、短信及相關形式告知您，難以逐一告知時，我們會採取合理、有效的方式發佈公告。同時，我們還將按照相關監管部門要求，上報用戶信息安全事件的處置情況。
        </p>
        <p>
          2.7
          您一旦離開本平台及ODAILY服務，瀏覽或使用其他網站、服務及內容資源，我們將沒有能力和直接義務保護您在本平台及ODAILY服務之外的軟件、網站提交的任何個人信息，無論您登錄、瀏覽或使用上述軟件、網站是否基於本平台的鏈接或引導。
        </p>

        <h6>七．您如何管理您的個人信息</h6>
        <p>1.您有權查詢、修改您的個人信息</p>
        <p>
          我們非常重視您對個人信息的關注，提供了多種功能以保護您對於個人信息查詢和修改的權利。
        </p>
        <p>1.1 管理賬號信息</p>
        <p>
          “我的”→“賬號管理”您可通過這一功能修改賬號密碼，修改綁定手機號、綁定第三方賬號。
        </p>
        <p>
          “我的”→“賬戶名片”→“編輯”您可以在編輯資料中查看和修改您的賬號信息，包括頭像、暱稱、性別、生日、個人簡介、身份、所屬行業。
        </p>
        <p>1.2 管理評論與文章信息</p>
        <p>
          “我的”→“個人主頁”點擊單條發表的評論或文章進入內容詳情頁，您可以了解您發布的評論或文章情況。
        </p>
        <p>1.3 管理瀏覽記錄</p>
        <p>“我的” → “瀏覽”您可以查看您的瀏覽記錄。</p>
        <p>1.4 管理關註記錄</p>
        <p>
          “我的” →
          “關注”您可以管理所有關注列表，查看及修改您關注的用戶、作者、主題。
        </p>
        <p>“我的” → “收藏”您可以管理您收藏的文章、音視頻及其他。</p>
        <p>
          您變更您的授權範圍後，我們將據此調整本平台向您提供的ODAILY服務。但請您理解，這不會影響此前基於您的授權而開展的個人信息處理。
        </p>

        <p>2.刪除您的個人信息</p>
        <p>我們將提供以下方式幫助您刪除在您在本平台上發布的信息：</p>
        <p>2.1 刪除評論與文章信息</p>
        <p>
          “我的”→“個人主頁”點擊單條發表的評論或文章進入內容詳情頁，並可以隨時通過長按的方式刪除您已發布的評論或文章。但您刪除已發布的評論或文章，僅是取消其公開展示，我們仍需要依據法律法規對您曾經發布的評論或文章進行後台的存儲保留。
        </p>
        <p>2.2 刪除瀏覽記錄</p>
        <p>“我的” → “瀏覽”您可以刪除您的瀏覽記錄。</p>
        <p>2.3 刪除關註記錄</p>
        <p>“我的” → “關注”您可以刪除您關注的用戶、作者、主題。</p>
        <p>“我的” → “收藏”您可以刪除您收藏的文章、音視頻及其他。</p>
        <p>
          2.4
          在以下情形中，我們將主動刪除您的個人信息。如我們未刪除的，您可通過發送郵件至om@odaily.email向我們提出刪除個人信息的請求：
        </p>
        <ul className={styles.privacy_ul}>
          <li>（1）處理目的已實現、無法實現或者為實現處理目的不再必要；</li>
          <li>（3）您撤回同意；</li>
          <li>（3）您撤回同意；</li>
          <li>（4）我們違反法律、行政法規或者違反約定處理個人信息；</li>
          <li>（5）法律、行政法規規定的其他情形。</li>
        </ul>
        <p>
          您刪除信息後，我們將據此調整本平台向您提供的ODAILY服務。但請您理解，這不會影響此前基於您的授權而開展的個人信息處理。
        </p>

        <p>3.獲取您的個人信息副本</p>
        <p>
          如您需要獲取您的個人信息副本，您可發送郵件至om@odaily.email，我們將根據您的書面請求向您提供我們已收集的您的個人信息。
        </p>

        <p>4.您有權撤回您對個人信息的授權</p>
        <p>
          您可以在設備本身操作系統中，關閉GPS地理位置、攝像頭、麥克風、相冊權限、改變同意範圍或撤回您的授權。關閉授權後我們將不再收集與這些權限相關信息。
          ，從而拒絕我們收集您相應的個人信息。您需理解，當您撤回授權後，我們無法繼續為您提供撤回授權所對應的ODAILY服務。
          <b>
            但您撤回授權的決定，不會影響此前基於您的授權而開展的個人信息處理。
          </b>
        </p>

        <p>5.個人信息轉移權</p>
        <p>
          若您需要將您的個人信息轉移至其他平台、企業或組織，您可以通過本隱私政策第十一條提供的方式聯繫我們。我們將對您的請求進行審核，在具備法律規定的個人信息轉移前提的條件下，我們將為您提供轉移相應個人信息的途徑。
        </p>

        <p>6.ODAILY賬號註銷</p>
        <p>
          如果您需要註銷您的ODAILY賬號，請在“我的” →
          “賬號管理”→“註銷賬號”中提交申請。在註銷賬號之後，我們將停止為您提供ODAILY服務，並依據法律法規相關要求通過匿名化等方式保護您的信息。
        </p>

        <p>7.自主管理控制營銷信息及定向推送</p>
        <p>
          7.1
          消息推送——如您不希望收到App頁面上的消息通知，可以在首次安裝App時拒絕接收消息通知，或者在“我的”
          → “推送管理”關掉對應的通知功能。
        </p>
        <p>
          7.2
          推廣/回訪——如您不希望接收我們的推廣短信或電話回訪，可通過信息中相應的退訂功能進行退訂，或者在電話回訪中明確表示拒絕。
        </p>

        <p>8.響應您的需求</p>
        <p>在以下情形中，按照法律法規要求，我們將無法響應您的請求：</p>
        <ul className={styles.privacy_ul}>
          <li>1）與我們履行法律法規規定的義務相關的；</li>
          <li>2）與國家安全、國防安全直接相關的；</li>
          <li>3）與公共安全、公共衛生、重大公共利益直接相關的；</li>
          <li>4）與刑事犯罪偵查、起訴、審判和執行判決等直接相關的；</li>
          <li>5）有充分證據表明個人信息主體存在主觀惡意或濫用權利的；</li>
          <li>
            6）出於維護個人信息主體或其他個人的生命、財產等重大合法權益但又很難得到本人授權同意的；
          </li>
          <li>
            7）響應您的請求將導致您或其他個人、組織的合法權益受到嚴重損害的；
          </li>
          <li>8）涉及商業秘密的。</li>
        </ul>

        <h6>八．未成年人的個人信息保護</h6>
        <p>
          1.我們非常重視對未成年個人信息的保護。根據相關法律法規的規定，若您是未滿18週歲的未成年人，在使用ODAILY服務前，應在您的父母或其他監護人監護、指導下共同閱讀並同意本隱私政策。
        </p>
        <p>
          2.我們根據國家相關法律法規的規定保護未成年人的個人信息，只會在法律允許、父母或其他監護人明確同意或保護未成年人所必要的情況下收集、使用、儲存、共享、轉讓或披露未成年人的個人信息；如果我們發現在未事先獲得可證實的監護人同意的情況下收集了未成年人的個人信息，則會設法盡快刪除相關信息。
        </p>
        <p>
          3.若您是未成年人的監護人，當您對您所監護的未成年人的個人信息有相關疑問時，請通過本隱私政策第十一條提供的方式聯繫我們。
        </p>

        <h6>九．隱私政策的修訂和通知</h6>
        <p>
          1.為給您提供更好的服務，我們的ODAILY服務將不時變化，本隱私政策也將隨之調整。
        </p>
        <p>
          2.未經您明確同意，我們不會削減您按照本隱私政策所應享有的權利。我們會通過App
          客戶端推送通知、彈窗形式等合理方式通知您，以便您能及時了解本隱私政策所做的任何變更。您可通過“我的”
          → “更多服務”→ “隱私政策”查看本隱私政策的最新內容。
        </p>

        <p>
          3.對於重大變更，視具體情況我們還會提供更為顯著的通知說明本隱私政策的具體變更內容。重大變更包括但不限於：
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1）我們的服務模式發生重大變化。如處理個人信息的目的、處理的個人信息類型、個人信息的使用方式等；
          </li>
          <li>
            2）我們在所有權結構、組織架構等方面發生重大變化。如業務調整、破產併購等引起的所有者變更等；
          </li>
          <li>3）個人信息共享、轉讓或公開披露的主要對象發生變化；</li>
          <li>4）您參與個人信息處理方面的權利及其行使方式發生重大變化；</li>
          <li>
            5）我們負責處理個人信息安全的責任部門、聯絡方式及投訴渠道發生變化；
          </li>
          <li>6）個人信息安全影響評估報告表明存在高風險時。</li>
        </ul>

        <p>
          4.若您不同意修改後的隱私政策，您有權並應立即停止使用ODAILY服務。但請您理解，如您不同意修改後的隱私政策，將可能導致您將無法繼續完整正常使用相關ODAILY服務和部分功能，或者無法達到我們預期到達的服務效果。
        </p>

        <h6>十．我們對去世用戶的個人信息保護</h6>
        <p>
          我們從2021年11月1日起，根據《個人信息保護法》的相關規定制訂死者個人信息保護製度。
          ODAILY用戶（僅限自然人）去世後，其近親屬為了自身的合法、正當利益，可以通過本隱私政策第十一條提供的聯繫方式，對去世用戶的相關個人信息行使查閱、複製、更正、刪除等權利，但是去世用戶生前另有安排的除外。
        </p>

        <h6>十一．您如何联系我們</h6>
        <p>
          如您對個人信息保護問題有任何投訴、建議、疑問，或您對本隱私政策有任何疑問需要我們進行解釋說明，您可以通過以下方式聯繫我們，我們將盡快審核所涉問題，並在驗證您的用戶身份後的十五個工作日內予以答复：
        </p>
        <p>1.發送郵件至om@odaily.email與我們個人信息保護負責人聯繫；</p>
        <p>2.您可通過“我的” → “意見反饋”→ “其他問題”與我們聯繫。</p>

        <h6>十二．爭議解決</h6>
        <p>
          因本隱私政策以及我們處理您個人信息事宜引起的任何爭議，您可訴至北京市朝陽區人民法院。如果您認為我們的個人信息處理行為損害了您的合法權益，您也可向有關政府部門進行反映。
        </p>
      </div>
    );
  }
}

export default PrivacyZhtw;
