import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { withRouter } from 'react-router-dom';
import locales from '../../utils/locales';

export class DownLoad extends React.PureComponent {
  static propTypes = {
    marginTop: PropTypes.string,
    match: PropTypes.object
  };
  render() {
    const { marginTop } = this.props;
    const { lang } = this.props.match.params;
    return (
      <div
        className={styles.downLoad}
        style={{
          marginTop: marginTop ? marginTop : null,
          height: lang != 'th' ? '133px' : '150px'
        }}
      >
        <div className={styles.downLoad_box}>
          <div className={styles.downLoad_box_left}>
            <div
              className={styles.downLoad_box_title}
              style={{ lineHeight: lang != 'th' ? '20px' : '24px' }}
            >
              {locales(lang, 'downloadOdaily')}
            </div>
            <div
              className={styles.downLoad_box_text}
              style={{ margin: lang != 'th' ? '20px 0' : '10px 0' }}
            >
              {locales(lang, 'help')}
            </div>
            <div className={styles.downLoad_box_button}>
              <a
                className={styles.downLoad_button_ios}
                href="https://itunes.apple.com/cn/app/id1380052002?mt=8"
                target="_blank"
                rel="noopener noreferrer"
              />

              <a
                className={styles.downLoad_button_android}
                href="https://a.app.qq.com/o/simple.jsp?pkgname=com.odaily.news"
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          </div>
          <div className={styles.downLoad_box_right} />
        </div>
      </div>
    );
  }
}

export default withRouter(DownLoad);
