exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-column--container-index {\n  display: -ms-flexbox;\n  display: flex;\n  width: 1200px;\n  min-height: 80vh;\n  margin: 0 auto;\n  padding: 20px 0; }\n  .src-pages-column--container-index .src-pages-column--posts-container-index {\n    padding-right: 30px;\n    width: 840px; }\n    .src-pages-column--container-index .src-pages-column--posts-container-index .src-pages-column--header-index {\n      width: 820px;\n      margin-left: 20px;\n      height: 180px;\n      margin-bottom: 8px; }\n      .src-pages-column--container-index .src-pages-column--posts-container-index .src-pages-column--header-index p {\n        font-family: PingFangSC-Medium;\n        font-size: 26px;\n        color: #FFFFFF;\n        text-align: center;\n        line-height: 180px;\n        letter-spacing: 12px; }\n    .src-pages-column--container-index .src-pages-column--posts-container-index .src-pages-column--feed-loading-more-index {\n      display: -ms-flexbox;\n      display: flex;\n      -ms-flex-pack: center;\n          justify-content: center;\n      -ms-flex-align: center;\n          align-items: center;\n      margin: 60px auto;\n      width: 400px;\n      height: 40px;\n      font-size: 14px;\n      color: #697f9c;\n      cursor: pointer;\n      opacity: .7;\n      border: 1px solid #697f9c;\n      border-radius: 100px; }\n  .src-pages-column--container-index .src-pages-column--widgets-container-index {\n    -ms-flex: 1 1;\n        flex: 1 1; }\n", ""]);

// exports
exports.locals = {
	"container": "src-pages-column--container-index",
	"posts-container": "src-pages-column--posts-container-index",
	"header": "src-pages-column--header-index",
	"feed-loading-more": "src-pages-column--feed-loading-more-index",
	"widgets-container": "src-pages-column--widgets-container-index"
};