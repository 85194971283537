import http from '../scripts/http';
import { service } from '../scripts/http';

export function getColumns(lang) {
  return http.get('secondary_tag/recommend', { lang: lang });
}

export function feedSecondLevel(colunmId) {
  return service.get(`/focus/index?type=feed_second_level&feed_id=${colunmId}`);
}

export function feedSecondLevelByUser(colunmId, user_id) {
  return service.get(
    `/focus/index?type=feed_second_level&feed_id=${colunmId}&user_id=${user_id}`
  );
}

export function getColumnList(colunmId, lang) {
  return http.get(`/column/${colunmId}`, { lang });
}

export function newsItem(colunmId, page, perPage = 10, lang) {
  return service.get(`/columns/${colunmId}?page=${page}&paginate=${perPage}`, {
    lang
  });
}

export function getSecondaryTag(slug, lang) {
  return service.get(`/secondaryTag/?slug=${slug}`, { lang });
}

export function getSecondaryClassicList(id, page, perPage = 10, lang) {
  return http.get(
    `/classic_post?secondary_tag_id=${id}&page=${page}&paginate=${perPage}`,
    { lang }
  );
}

export function getSecondaryList(slug, page, perPage = 10, lang) {
  return http.get(
    `/secondaryTagPosts?slug=${slug}&page=${page}&paginate=${perPage}`,
    { lang }
  );
}

export function getSecondaryNewsflasheList(
  secondary_tag_id,
  page,
  perPage = 10,
  lang
) {
  return http.get(
    `/secondary_tag/newsflashe?secondary_tag_id=${secondary_tag_id}&page=${page}&per_page=${perPage}`,
    { lang }
  );
}

export function getTopList() {
  return service.get(`/leaderboard`);
}

export function getVoteDate() {
  return service.get(`/vote_rank/index`);
}

export function getFatDate(fat) {
  return service.get(`/fat_list/period_list?list_type=${fat}`);
}

export function getFatTable(fat, type, date, num = 20) {
  return service.get(
    `/fat_list?list_type=${fat}&user_type=${type}&rand_period=${date}&limit=${num}`
  );
}

export function getImg(list_type, user_type, rand_period) {
  return service.get(
    `/fat_list/image?list_type=${list_type}&user_type=${user_type}&rand_period=${rand_period}`
  );
}
