import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import animate from 'animate.css';

export class ResearchItem extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    column_id: PropTypes.number.isRequired,
    pdf_url: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.coverImage = React.createRef();
  }
  componentDidMount() {
    if (this.coverImage.current) {
      const imgNode = this.coverImage.current;
      const fadeInStyle = animate['fadeIn'];
      const url = imgNode.dataset.src;
      const image = new Image();
      image.src = url;
      image.onload = function() {
        imgNode.src = url;
        imgNode.style.opacity = 1;
        imgNode.classList.add(fadeInStyle);
      };
    }
  }
  renderNormal() {
    const { id, cover, title, summary, column_id, pdf_url } = this.props;
    return (
      <div key={id} className={styles['itemBox']}>
        <a
          className={`${styles['cover']} ${animate.animated} ${animate.fadeIn}`}
          href={`/post/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundImage: `url(${cover})` }}
        />
        <a href={`/post/${id}`} target="_blank" rel="noopener noreferrer">
          <p className={styles['itemTitle']}>{title}</p>
          <p className={styles['itemDescribe']}>{summary}</p>
        </a>
        {pdf_url && pdf_url !== '' ? (
          <div className={styles['itemLoad']}>
            {column_id == '249' ? (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={pdf_url}
                  download={pdf_url}
                >
                  下载PDF
                </a>
              </p>
            ) : (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={pdf_url}
                  download={pdf_url}
                >
                  查看原图
                </a>
              </p>
            )}
          </div>
        ) : null}
        <div className={styles['itemRead']}>
          <p>
            <a target="_blank" rel="noopener noreferrer" href={`/post/${id}`}>
              在线阅读
            </a>
          </p>
        </div>
      </div>
    );
  }
  render() {
    return this.renderNormal();
  }
}
