import moment from 'moment';
import locales from '../../utils/locales';

const minute = 1000 * 60;
const hour = minute * 60;
// const day = hour * 24;

export function timesAgo(hisTime, nowTime, lang) {
  hisTime = moment(hisTime, 'YYYY-MM-DD HH:mm').valueOf();
  const now =
    nowTime ||
    moment()
      .utcOffset(8)
      .valueOf();
  const diffValue = now - hisTime;

  const diffHour = diffValue / hour;
  const diffMinute = diffValue / minute;
  // const diffDay = diffValue / day;

  // if (diffDay >= 1 && diffDay <= 2) {
  //   return '昨日';
  // }

  if (diffHour >= 1 && diffHour < 24) {
    return `${Math.round(diffHour)}${locales(lang, 'timeHour')}`;
  }
  if (diffMinute >= 1 && diffMinute < 60) {
    return `${Math.round(diffMinute)}${locales(lang, 'timeMinites')}`;
  }
  if (diffMinute < 1) {
    return `${locales(lang, 'timeJust')}`;
  }

  return moment
    .utc(hisTime)
    .local()
    .format('YYYY-MM-DD');
}

export function timeagone(hisTime) {
  hisTime = moment(hisTime, 'YYYY/MM/DD').valueOf();
  let result = '';
  // const isToday = moment().isSame(moment(hisTime), 'day');
  // if (isToday) {
  //   // result = moment(hisTime).format('HH:mm:ss');
  //   result = moment(hisTime).format('YYYY-MM-DD');
  // } else {
  //   result = moment(hisTime).format('YYYY-MM-DD');
  // }
  result = moment(hisTime).format('YYYY-MM-DD');
  return result;
}
