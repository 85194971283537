import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import locales from '../../../utils/locales';

const IconLoading = require('./images/icon-loading.png');

export class LoadMore extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool,
    onClick: PropTypes.func,
    match: PropTypes.object
  };
  static defaultProps = {
    loading: true,
    loaded: false
  };
  renderLoading() {
    const { lang } = this.props.match.params;
    return (
      <p className={styles.tips}>
        <img src={IconLoading} alt="加载中" loading="lazy" />
        {locales(lang, 'loading')}
      </p>
    );
  }
  renderMore() {
    const { lang } = this.props.match.params;
    return <p className={styles.tips}>{locales(lang, 'load_More')}</p>;
  }
  renderLoaded() {
    const { lang } = this.props.match.params;
    return <p className={styles.tips}>{locales(lang, 'nomore')}</p>;
  }
  onTrigger() {
    const props = this.props;
    if (_.isFunction(props.onClick) && !props.loaded && !props.loading) {
      props.onClick();
    }
  }
  render() {
    const props = this.props;
    if (props.loaded && !props.loading) {
      return null;
    }
    return (
      <div className={styles.loadmore} onClick={this.onTrigger.bind(this)}>
        {props.loading && !props.loaded ? this.renderLoading() : null}
        {!props.loaded && !props.loading ? this.renderMore() : null}
      </div>
    );
  }
}

export default withRouter(LoadMore);
