import { wechat } from './http';

const defaultTitle = 'ODAILY-探索真实区块链';
const defaultDesc = '探索真实区块链';
const defaultImgUrl = `https://piccdn.0daily.com/202205/07035137/s29xjxxva9nzvyd5.png!200`;
const defaultSuccess = () => console.log('分享成功');

function getToken(link) {
  let encodeLink = encodeURIComponent(link);
  return wechat.get(`/wechat/jssdk?url=${encodeLink}`);
}
const wechatShare = ({
  title = defaultTitle,
  imgUrl = defaultImgUrl,
  desc = defaultDesc,
  success = defaultSuccess
}) => {
  let link = location.href.split('#')[0];
  const wx = require('weixin-js-sdk');
  getToken(link).then(
    data => {
      wx.config({
        debug: false,
        jsApiList: data.jsApiList,
        appId: data.appId,
        nonceStr: data.nonceStr,
        timestamp: data.timestamp,
        signature: data.signature
      });
      wx.ready(() => {
        wx.onMenuShareTimeline({ title, link, imgUrl, desc, success });
        wx.onMenuShareAppMessage({ title, link, imgUrl, desc, success });
      });
    },
    () => {
      console.error(`get wechat share config failed`);
    }
  );
};

export default wechatShare;
