exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-user--container-index {\n  width: 100%;\n  background: #fff; }\n  .src-pages-user--container-index .src-pages-user--wrapper-index {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    width: 1200px;\n    min-height: 80vh;\n    margin: 0 auto;\n    padding: 30px 0; }\n    .src-pages-user--container-index .src-pages-user--wrapper-index .src-pages-user--posts-container-index {\n      width: 840px; }\n      .src-pages-user--container-index .src-pages-user--wrapper-index .src-pages-user--posts-container-index .src-pages-user--feed-loading-more-index {\n        display: -ms-flexbox;\n        display: flex;\n        -ms-flex-pack: center;\n            justify-content: center;\n        -ms-flex-align: center;\n            align-items: center;\n        margin: 60px auto;\n        width: 400px;\n        height: 40px;\n        font-size: 14px;\n        color: #697f9c;\n        cursor: pointer;\n        opacity: .7;\n        border: 1px solid #697f9c;\n        border-radius: 100px; }\n    .src-pages-user--container-index .src-pages-user--wrapper-index .src-pages-user--widgets-container-index {\n      width: 340px;\n      height: 100%;\n      position: -webkit-sticky;\n      position: sticky;\n      top: 41px; }\n", ""]);

// exports
exports.locals = {
	"container": "src-pages-user--container-index",
	"wrapper": "src-pages-user--wrapper-index",
	"posts-container": "src-pages-user--posts-container-index",
	"feed-loading-more": "src-pages-user--feed-loading-more-index",
	"widgets-container": "src-pages-user--widgets-container-index"
};