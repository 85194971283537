exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-download--container-index {\n  background-color: #fbfbfb; }\n  .src-pages-download--container-index .src-pages-download--content-index {\n    margin: 20px auto 44px;\n    width: 900px;\n    height: 600px; }\n  .src-pages-download--container-index .src-pages-download--preview-container-index {\n    position: relative; }\n  .src-pages-download--container-index .src-pages-download--preview-left-img-index {\n    position: absolute;\n    width: 470px; }\n  .src-pages-download--container-index .src-pages-download--preview-right-img-index {\n    position: absolute;\n    top: 255px;\n    left: 397px;\n    width: 259px; }\n  .src-pages-download--container-index .src-pages-download--preview-iphone-img-index {\n    position: absolute;\n    top: 62px;\n    left: 117px;\n    width: 400px;\n    height: 500px; }\n  .src-pages-download--container-index .src-pages-download--desc-container-index {\n    position: relative;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    -ms-flex-align: center;\n        align-items: center;\n    top: 100px;\n    left: 656px;\n    width: 320px; }\n  .src-pages-download--container-index .src-pages-download--download-logo-index {\n    width: 202px; }\n  .src-pages-download--container-index .src-pages-download--download-text-index {\n    margin: 19px 0 34px;\n    font-size: 24px;\n    font-weight: bold;\n    color: #1c499e; }\n  .src-pages-download--container-index .src-pages-download--download-button-index {\n    margin-bottom: 18px;\n    width: 165px; }\n  .src-pages-download--container-index .src-pages-download--download-qrcode-index {\n    margin-top: 15px;\n    width: 98px; }\n", ""]);

// exports
exports.locals = {
	"container": "src-pages-download--container-index",
	"content": "src-pages-download--content-index",
	"preview-container": "src-pages-download--preview-container-index",
	"preview-left-img": "src-pages-download--preview-left-img-index",
	"preview-right-img": "src-pages-download--preview-right-img-index",
	"preview-iphone-img": "src-pages-download--preview-iphone-img-index",
	"desc-container": "src-pages-download--desc-container-index",
	"download-logo": "src-pages-download--download-logo-index",
	"download-text": "src-pages-download--download-text-index",
	"download-button": "src-pages-download--download-button-index",
	"download-qrcode": "src-pages-download--download-qrcode-index"
};