import React, { Component } from 'react';
import styles from '../../index.scss';
import PropTypes from 'prop-types';

export class PrivacyZhcn extends Component {
  static propTypes = {
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.content}>
        <h5>{this.props.title}</h5>
        <h6>สารบัญ</h6>

        <p>1. การแนะนำ</p>
        <p>2. เกี่ยวกับเรา</p>
        <p>3. วิธีที่เรารวบรวมและใช้ข้อมูลส่วนบุคคลของคุณ</p>
        <p>4. วิธีที่เราใช้คุกกี้และเทคโนโลยีที่คล้ายกัน</p>
        <p>
          5. วิธีที่เราไว้วางใจในการประมวลผล การจัดหาภายนอก การถ่ายโอน
          และการเปิดเผยข้อมูลส่วนบุคคลของคุณสู่สาธารณะ
        </p>
        <p>6. วิธีที่เราจัดเก็บและปกป้องข้อมูลส่วนบุคคลของคุณ</p>
        <p>7. คุณจัดการข้อมูลส่วนบุคคลของคุณอย่างไร</p>
        <p>8. การคุ้มครองข้อมูลส่วนบุคคลของผู้เยาว์</p>
        <p>9. การแก้ไขและประกาศนโยบายความเป็นส่วนตัว</p>
        <p>10. การคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้ที่เสียชีวิต</p>
        <p>11. คุณติดต่อเราได้อย่างไร</p>
        <p>12 การระงับข้อพิพาท</p>

        <h6>1. การแนะนำ</h6>
        <p>
          &quot;นโยบายความเป็นส่วนตัวของ ODAILY&quot; นี้ (ต่อไปนี้จะเรียกว่า
          &quot;นโยบายความเป็นส่วนตัวนี้&quot;)
          ใช้กับซอฟต์แวร์และบริการที่เกี่ยวข้อง (ต่อไปนี้จะเรียกว่า
          &quot;บริการของ ODAILY&quot;) ของ ODAILY (ต่อไปนี้จะเรียกว่า
          &quot;แพลตฟอร์ม&quot; หรือ &quot;พวกเรา&quot;)
          ส่วนหัวของแต่ละข้อจะใช้เพื่อช่วยให้คุณเข้าใจสาระสำคัญของข้อเท่านั้น
          และไม่ส่งผลกระทบหรือจำกัดความหมายหรือการตีความของข้อในนโยบายความเป็นส่วนตัวนี้
          เพื่อปกป้องสิทธิและผลประโยชน์ของคุณเอง
          ขอแนะนำให้คุณอ่านสำนวนเฉพาะของแต่ละข้ออย่างระมัดระวัง
        </p>
        <p>
          โปรดให้ความสนใจเป็นพิเศษ: นโยบายความเป็นส่วนตัวนี้ใช้กับบริการ ODAILY
          ทั้งหมดที่เราให้บริการแก่คุณในรูปแบบของเว็บไซต์ ลูกค้า แอพเพล็ต
          และรูปแบบใหม่ที่เกิดขึ้นพร้อมกับการพัฒนาทางเทคโนโลยี หากมีการใช้บริการ
          ODAILY ในผลิตภัณฑ์หรือบริการของเราและบริษัทในเครือ
          แต่ไม่มีนโยบายความเป็นส่วนตัวที่เป็นอิสระ
          นโยบายความเป็นส่วนตัวนี้จะใช้กับส่วนนี้ของบริการด้วย
          หากเราและบริษัทในเครือของเรามีนโยบายความเป็นส่วนตัวแยกต่างหากสำหรับบริการ
          ODAILY ที่ให้บริการแก่คุณ
          นโยบายความเป็นส่วนตัวที่เกี่ยวข้องจะใช้กับผลิตภัณฑ์หรือบริการที่เกี่ยวข้อง
        </p>
        <p>
          คุณควรอ่านนโยบายความเป็นส่วนตัวนี้อย่างรอบคอบก่อนที่จะคลิกเพื่อยอมรับนโยบายความเป็นส่วนตัวนี้ในระหว่างขั้นตอนการลงทะเบียนแอปพลิเคชันหรือเมื่อมีการปรับปรุงนโยบายความเป็นส่วนตัวนี้
          โปรดอ่านอย่างละเอียดและเข้าใจเนื้อหาของแต่ละข้อ
          <b>
            เราได้เน้นเนื้อหาสำคัญของข้อกำหนดและเงื่อนไขเกี่ยวกับสิทธิ์ในข้อมูลส่วนบุคคลของคุณ
            โปรดให้ความสนใจเป็นพิเศษ
          </b>
        </p>
        <p>
          เมื่อคุณกรอกข้อมูลตามคำแนะนำในหน้าการลงทะเบียน
          อ่านและยอมรับนโยบายความเป็นส่วนตัวนี้และดำเนินการตามขั้นตอนการลงทะเบียนทั้งหมด
          หรือคลิก ตกลง เมื่อมีการปรับปรุงนโยบายความเป็นส่วนตัวนี้
          แสดงว่าคุณได้อ่าน ทำความเข้าใจ และยอมรับทั้งหมดแล้ว
          เนื้อหาของนโยบายความเป็นส่วนตัวนี้
          <b>
            ในกระบวนการอ่านนโยบายความเป็นส่วนตัวนี้
            หากคุณปฏิเสธที่จะยอมรับนโยบายความเป็นส่วนตัวนี้หรือข้อกำหนดใดๆ
            อาจส่งผลให้คุณไม่สามารถใช้บริการ ODAILY
            ที่เกี่ยวข้องและฟังก์ชันบางอย่างได้ตามปกติ
            หรือล้มเหลวในการให้บริการตามที่เรากำหนด ที่คาดหวัง.
          </b>
        </p>
        <h6>2. เกี่ยวกับเรา</h6>
        <p>
          1. ผู้ดำเนินการแพลตฟอร์มนี้และผู้ให้บริการ ODAILY คือ Hainan Moti
          Culture Media Co., Ltd.
        </p>
        <p>
          2. บริษัทในเครือ หมายถึง บริษัทที่ควบคุม Hainan Moti Culture Media
          Co., Ltd. ซึ่งควบคุมโดย Hainan Province Moti Culture Media Co., Ltd.
          หรืออยู่ภายใต้การควบคุมร่วมกับ Hainan Province Moti Culture Media Co.,
          Ltd. ใน ปัจจุบันและอนาคต กลไก
          การควบคุมหมายถึงความสามารถในการมีอิทธิพลโดยตรงหรือโดยอ้อมต่อการจัดการ/การดำเนินงานของวัตถุที่ถูกควบคุมผ่านความเป็นเจ้าของ
          หุ้นที่มีสิทธิออกเสียง สัญญา สมาคมการปฏิบัติงานจริง
          หรือวิธีการอื่นที่ได้รับการยอมรับทางกฎหมาย
        </p>
        <h6>3. วิธีที่เรารวบรวมและใช้ข้อมูลส่วนบุคคลของคุณ</h6>
        <p>
          1.
          เรารวบรวมและใช้ข้อมูลส่วนบุคคลที่คุณให้โดยสมัครใจในกระบวนการใช้บริการหรือสร้างขึ้นเนื่องจากความต้องการของบริการ
          ODAILY ตามกฎหมายและข้อบังคับและปฏิบัติตามหลักความถูกต้อง
          ความชอบด้วยกฎหมาย และความจำเป็น
          หากเราตั้งใจที่จะใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์อื่นที่ไม่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
          หรือใช้ข้อมูลที่รวบรวมเพื่อวัตถุประสงค์อื่นตามวัตถุประสงค์เฉพาะ
          เราจะแจ้งให้คุณทราบในเวลาที่เหมาะสมและทันท่วงที
          และขอความยินยอมจากคุณอีกครั้ง
        </p>
        <p>
          2. ข้อมูลส่วนบุคคลของคุณที่เรารวบรวมและใช้มีอยู่สองประเภท: ประเภทแรก:
          ข้อมูลที่จำเป็นสำหรับฟังก์ชันธุรกิจหลักของบริการ ODAILY:
          ข้อมูลประเภทนี้จำเป็นสำหรับการทำงานปกติของแพลตฟอร์มนี้
          และคุณต้องอนุญาตเรา เพื่อรวบรวมมัน หากคุณปฏิเสธที่จะให้
          อาจทำให้คุณไม่สามารถใช้บริการ ODAILY
          ที่เกี่ยวข้องและฟังก์ชันบางอย่างได้ตามปกติ ประเภทที่สอง:
          ข้อมูลที่อาจรวบรวมสำหรับฟังก์ชันทางธุรกิจเพิ่มเติม:
          ข้อมูลนี้จำเป็นสำหรับฟังก์ชันที่ไม่ใช่ธุรกิจหลัก และ
          คุณสามารถเลือกได้ว่าจะอนุญาตหรือไม่ เรารวบรวม
          หากคุณปฏิเสธที่จะให้ข้อมูลดังกล่าว
          จะส่งผลให้ไม่สามารถรับรู้ถึงฟังก์ชันทางธุรกิจเพิ่มเติมหรือผลกระทบที่เราตั้งใจจะบรรลุผลได้
          แต่จะไม่ส่งผลกระทบต่อการใช้งานฟังก์ชันธุรกิจหลักตามปกติของคุณ
        </p>
        <p>
          3.
          <b>
            เราจำเป็นต้องเตือนคุณว่า: เนื่องจาก ODAILY
            มีบริการมากมายและให้เนื้อหาที่แตกต่างกันแก่คุณ หน้าที่หลัก ๆ
            ของธุรกิจ (รวมถึงประเภทของข้อมูลส่วนบุคคลของคุณที่เก็บรวบรวม)
            ก็จะแตกต่างกันไปตามเนื้อหาของบริการ ODAILY รายละเอียดขึ้นอยู่กับ
            การให้บริการ ODAILY ที่เกิดขึ้นจริง นอกจากนี้
            คุณเข้าใจและยอมรับว่าเราหวังว่าจะให้บริการ ODAILY
            ที่สมบูรณ์แบบแก่คุณ ดังนั้น เราจะปรับปรุงบริการ ODAILY ต่อไป
            รวมถึงเทคโนโลยี ซึ่งหมายความว่าเราอาจเปิดตัวฟังก์ชันทางธุรกิจใหม่ๆ
            บ่อยครั้ง และอาจจำเป็นต้องรวบรวมข้อมูลส่วนบุคคลใหม่หรือเปลี่ยนแปลง
            วัตถุประสงค์หรือวิธีการใช้ข้อมูลส่วนบุคคล
            หากฟังก์ชันหรือบริการที่ต้องรวบรวมข้อมูลส่วนบุคคลของคุณไม่ได้อธิบายไว้ในนโยบายความเป็นส่วนตัวนี้
            เราจะอธิบายให้คุณทราบวัตถุประสงค์ของการรวบรวมข้อมูลนี้แยกต่างหากโดยการอัปเดตนโยบายความเป็นส่วนตัวนี้
            ข้อความแจ้งหน้า หน้าต่างป๊อปอัป การประกาศบนเว็บไซต์ ฯลฯ วัตถุประสงค์
            เนื้อหา วิธีการใช้ และขอบเขต
            และให้ทางเลือกแก่คุณในการเลือกความยินยอมของคุณ และรวบรวม
            หลังจากได้รับความยินยอมโดยชัดแจ้งจากคุณ
          </b>
          ในระหว่างขั้นตอนนี้ หากคุณมีข้อสงสัยใดๆ เกี่ยวกับเรื่องที่เกี่ยวข้อง
          คุณสามารถใช้นโยบายความเป็นส่วนตัวฉบับที่ 11 นี้ หนึ่งในวิธีติดต่อเรา
          เราจะตอบคุณโดยเร็วที่สุด
        </p>
        <p>
          4.
          ข้อมูลส่วนบุคคลที่ละเอียดอ่อนในนโยบายความเป็นส่วนตัวนี้หมายถึงข้อมูลส่วนบุคคลที่เมื่อรั่วไหลหรือนำไปใช้ในทางที่ผิดกฎหมายแล้ว
          อาจทำให้ศักดิ์ศรีส่วนบุคคลของบุคคลธรรมดาถูกละเมิดหรือความปลอดภัยส่วนบุคคลและทรัพย์สินตกอยู่ในอันตรายได้อย่างง่ายดาย
          รวมถึง
          <b>
            ชีวมาตร ความเชื่อทางศาสนา อัตลักษณ์เฉพาะ การดูแลสุขภาพ บัญชีการเงิน
            ที่อยู่
          </b>
          และข้อมูลอื่น ๆ และ
          <b>ข้อมูลส่วนบุคคลของผู้เยาว์ที่มีอายุต่ำกว่าสิบสี่ปี</b>
          ,ตัวอย่างเช่น,
          <b>
            ข้อมูลส่วนบุคคลที่ละเอียดอ่อน ได้แก่ หมายเลขบัตรประจำตัวประชาชน
            ข้อมูลไบโอเมตริกซ์ส่วนบุคคล หมายเลขบัญชีธนาคาร เนื้อหาการสื่อสาร
            ข้อมูลด้านสุขภาพและสรีรวิทยา
          </b>
          รอ.
          ข้อมูลส่วนบุคคลที่ละเอียดอ่อนที่เรารวบรวมได้แสดงไว้อย่างชัดเจนในนโยบายความเป็นส่วนตัวนี้
          เมื่อข้อมูลส่วนบุคคลที่ละเอียดอ่อนของคุณรั่วไหล ดัดแปลง
          หรือใช้อย่างผิดกฎหมาย
          จะสร้างความเสียหายต่อความปลอดภัยส่วนบุคคลหรือทรัพย์สินของคุณ
          เพื่อป้องกันไม่ให้ข้อมูลส่วนบุคคลที่ละเอียดอ่อนของคุณถูกเปิดเผยหรือนำไปใช้อย่างผิดกฎหมาย
          เราได้ใช้มาตรการป้องกันทางเทคนิคและองค์กรที่เหมาะสมเพื่อให้มั่นใจถึงความปลอดภัยของข้อมูลของคุณ
        </p>
        <p>
          5. โดยปกติ เราจะรวบรวมและใช้ข้อมูลส่วนบุคคลของคุณในสถานการณ์ต่อไปนี้:
        </p>
        <p>5.1 บริการ ODAILY ภายใต้การลงทะเบียนและสถานะการเข้าสู่ระบบ</p>
        <p>
          เพื่อให้เราสามารถให้บริการที่ต่อเนื่องและมีเสถียรภาพแก่คุณ
          และเพื่อความปลอดภัยในการใช้บริการ ODAILY ของคุณ
          เราต้องการให้คุณให้ข้อมูลการลงทะเบียนขั้นพื้นฐานหรือการเข้าสู่ระบบ
          รวมถึงหมายเลขโทรศัพท์มือถือ ที่อยู่อีเมล และสร้างบัญชี
          ชื่อผู้ใช้ของคุณ และรหัสผ่านใน
          <b>
            หมายเลขโทรศัพท์มือถือและรหัสผ่านเป็นข้อมูลที่ละเอียดอ่อนส่วนบุคคล
          </b>
          ข้อมูลดังกล่าวถูกรวบรวมเพื่อให้เป็นไปตามข้อกำหนดของระบบชื่อจริงทางอินเทอร์เน็ตในกฎหมายและข้อบังคับที่เกี่ยวข้อง
          และเพื่อให้เป็นไปตามข้อกำหนดของนโยบายความเป็นส่วนตัวนี้
          ในบริการเฉพาะบางอย่าง
          หากคุณต้องการใช้ฟังก์ชันพื้นฐานในการเรียกดูและค้นหาเท่านั้น
          คุณไม่จำเป็นต้องลงทะเบียนเป็นผู้ใช้ ODAILY และให้ข้อมูลข้างต้น
        </p>
        <p>
          คุณยังสามารถใช้บัญชีแพลตฟอร์มของบุคคลที่สามเพื่อลงทะเบียนเป็นผู้ใช้
          ODAILY และคุณสามารถเลือกที่จะอนุญาตให้เราอ่านและรับ
          <b>
            ข้อมูลสาธารณะที่คุณเผยแพร่และบันทึกบนแพลตฟอร์มของบุคคลที่สาม
            (รวมถึงชื่อเล่นและภาพแทนตัว)
          </b>
          .
          เราต้องการสิทธิ์ของคุณในการรับข้อมูลข้างต้นจากบุคคลที่สามเพื่อจดจำข้อมูลประจำตัวในการเข้าสู่ระบบของคุณในฐานะผู้ใช้
          ODAILY การรวบรวมข้อมูลข้างต้นขึ้นอยู่กับความยินยอมของคุณ
          หลังจากที่คุณลงทะเบียนหรือเข้าสู่ระบบด้วยบัญชีบุคคลที่สาม
          บุคคลที่สามของคุณ บัญชี -party จะเชื่อมโยงกับบัญชี ODAILY
          สำหรับการผูกมัด
        </p>
        <p>
          จากความร่วมมือของเรากับผู้ให้บริการด้านการสื่อสาร เมื่อคุณใช้ฟังก์ชัน
          &quot;เข้าสู่ระบบด้วยคลิกเดียว&quot; ของ ODAILY
          โดยได้รับความยินยอมอย่างชัดแจ้งจากคุณ
          ผู้ให้บริการจะส่งหมายเลขโทรศัพท์มือถือของคุณมาให้เรา
          เพื่อให้เราสามารถให้บริการเข้าสู่ระบบที่รวดเร็วแก่คุณได้
          คุณยังสามารถเลือกที่จะกรอกหมายเลขโทรศัพท์มือถือด้วยตนเองเพื่อทำการลงทะเบียนหรือเข้าสู่ระบบให้เสร็จสมบูรณ์
        </p>
        <p>
          ในระหว่างขั้นตอนการลงทะเบียน การเข้าสู่ระบบ หรือการใช้งานในภายหลัง
          คุณยังสามารถกรอกหรือเสริมข้อมูลเพิ่มเติมอื่นๆ ของคุณ (ชื่อเล่น
          รูปแทนตัว เพศ วันเกิด ตัวตน อุตสาหกรรม)
          การรวบรวมข้อมูลข้างต้นขึ้นอยู่กับคุณ ความยินยอม
          มันอาจแสดงบนหน้าแรกส่วนบุคคลของคุณและพื้นที่แสดงความคิดเห็นเพื่อระบุตัวตนผู้ใช้
          ODAILY ของคุณและอำนวยความสะดวกในการสื่อสารและการโต้ตอบของคุณกับผู้ใช้
          ODAILY รายอื่น อย่างไรก็ตาม หากคุณไม่ได้ให้ข้อมูลนี้
          จะไม่ส่งผลกระทบต่อการทำงานพื้นฐานของคุณในการใช้บริการ ODAILY
        </p>
        <p>5.2 บริการ ODAILY ภายใต้สถานะไม่ลงทะเบียนและเข้าสู่ระบบ</p>
        <p>
          หากคุณใช้บริการ ODAILY โดยไม่ได้ลงทะเบียนหรือเข้าสู่ระบบ
          เราสามารถให้ฟังก์ชันบางอย่างแก่คุณในโหมดไม่ต้องเข้าสู่ระบบเท่านั้น
          เราอาจใช้ความคิดริเริ่มในการขอรับข้อมูลอุปกรณ์และข้อมูลระบุเครือข่ายของคุณเพื่อให้ใช้งานฟังก์ชั่นการเรียกดู
          ค้นหา ชอบ รวบรวม และแบ่งปัน ข้อมูลที่กล่าวถึงข้างต้นจะถูกรวบรวมเพื่อ
          เป็นไปตามข้อกำหนดของกฎหมายและข้อบังคับที่เกี่ยวข้องในการเก็บรักษาข้อมูลบันทึกเครือข่าย
        </p>
        <p>
          นอกเหนือจากข้อมูลข้างต้นแล้ว ในระหว่างการใช้งานของคุณ
          เราอาจสมัครขอรับข้อมูลของคุณอย่างแข็งขัน<b>สิทธิ์ของอุปกรณ์</b>
          เพื่อให้บรรลุหน้าที่เพิ่มเติมอื่นๆ (ดูหัวข้อ 5.9
          ของนโยบายความเป็นส่วนตัวนี้ &quot;ให้ความปลอดภัยแก่คุณ&quot;)
        </p>
        <p>5.3 การสืบค้นข้อมูล</p>
        <p>
          1) ODAILY อาจแนะนำข้อมูลที่น่าสนใจเพิ่มเติมให้กับคุณ
          เพื่อให้ใช้งานฟังก์ชั่นนี้ได้
          <b>เราอาจรวบรวมข้อมูลบันทึกที่จำเป็นโดยอัตโนมัติ</b>,รวม:
        </p>

        <ul className={styles.privacy_ul}>
          <li>
            ก) ข้อมูลพฤติกรรมการทำงานและการใช้งานของคุณ: คลิก ติดตาม ชื่นชอบ
            ค้นหา เรียกดู แบ่งปัน;
          </li>
          <li>b) ข้อมูลอุปกรณ์: รหัสประจำตัวเฉพาะของอุปกรณ์;</li>
          <li>
            c) ข้อมูลเครือข่าย:
            ข้อมูลตำแหน่งทางภูมิศาสตร์ที่แม่นยำซึ่งได้รับจากข้อมูลเซ็นเซอร์ เช่น
            ข้อมูล GPS, จุดเชื่อมต่อ WLAN, Bluetooth และสถานีฐาน
            และข้อมูลตำแหน่งทางภูมิศาสตร์โดยประมาณที่ได้รับผ่านข้อมูลตำแหน่งเครือข่าย
            (สถานีฐาน, IP และ WLAN)
          </li>
        </ul>

        <p>
          2）<b>ตำแหน่ง GPS เป็นข้อมูลที่ละเอียดอ่อนส่วนบุคคล</b>
          หากคุณปฏิเสธที่จะให้ข้อมูล เราจะไม่แนะนำข้อมูลให้คุณโดยอิงตามข้อมูล
          GPS และจะไม่ส่งผลกระทบต่อการใช้งานปกติของฟังก์ชันอื่นๆ ของแพลตฟอร์มนี้
        </p>

        <p>
          3)
          เราจะแสดงและแนะนำข้อมูลที่เกี่ยวข้องกับท้องถิ่นในพื้นที่ของคุณตามเมืองที่สอดคล้องกับข้อมูลตำแหน่งทางภูมิศาสตร์โดยประมาณ
        </p>

        <p>
          4)
          ข้อมูลดังกล่าวถูกรวบรวมเพื่อให้เป็นไปตามข้อกำหนดของกฎหมายและข้อบังคับที่เกี่ยวข้องสำหรับการเก็บรักษาข้อมูลบันทึกการใช้เว็บและเพื่อให้เป็นไปตามข้อกำหนดของนโยบายความเป็นส่วนตัวนี้
          <b>
            ข้อมูลที่กล่าวถึงข้างต้นที่เรารวบรวมและใช้นั้นไม่ได้ระบุตัวตน
            และการวิเคราะห์ข้อมูลจะสอดคล้องกับรหัสเฉพาะที่ไม่สามารถเชื่อมโยงโดยตรงกับตัวตนของคุณได้
            และจะไม่เชื่อมโยงกับตัวตนที่แท้จริงของคุณ
          </b>
          。
        </p>

        <p>5.4 การปล่อยและการโต้ตอบ</p>
        <p>1) การเผยแพร่ข้อมูล</p>
        <ul className={styles.privacy_ul}>
          <li>
            ก) เมื่อคุณโพสต์เนื้อหาหรือแสดงความคิดเห็น
            เราจะรวบรวมข้อมูลที่คุณโพสต์โดยอัตโนมัติ และแสดงชื่อเล่น รูปโปรไฟล์
            และเนื้อหาที่โพสต์
          </li>
          <li>
            b) เมื่อคุณใช้ฟังก์ชันอัปโหลดรูปภาพและโพสต์เสียงและวิดีโอ
            เราจะขอให้คุณอนุญาตกล้อง (กล้อง) อัลบั้ม (พื้นที่จัดเก็บ)
            และสิทธิ์ไมโครโฟน หากคุณปฏิเสธที่จะให้สิทธิ์
            คุณจะไม่สามารถใช้ฟังก์ชันนี้ได้ แต่จะไม่ส่งผลต่อการใช้ฟังก์ชันอื่นๆ
            ตามปกติของแพลตฟอร์มนี้
          </li>
          <li>
            ค)
            โปรดทราบว่าข้อมูลที่คุณเปิดเผยต่อสาธารณะอาจเกี่ยวข้องกับข้อมูลส่วนบุคคลของผู้ใช้หรือของผู้อื่น
            หากข้อมูลที่คุณเผยแพร่ต่อสาธารณะมีข้อมูลส่วนบุคคลของผู้อื่น
            คุณต้องแน่ใจว่าข้อมูลนั้นได้รับอนุญาตให้ใช้ตามกฎหมายและข้อบังคับหรือได้รับมา
            ก่อนอัพโหลดและเผยแพร่โดยได้รับอนุญาตตามกฎหมาย
          </li>
          <li>
            d) เมื่อคุณใช้ฟังก์ชั่นถ่ายทอดสด
            คุณต้องดำเนินการตรวจสอบชื่อจริงตามกฎหมายและข้อบังคับ
            ข้อกำหนดด้านความปลอดภัย และการปฏิบัติตามนโยบายความเป็นส่วนตัวนี้
            ในกระบวนการตรวจสอบชื่อจริง
            หน่วยงานตรวจสอบบุคคลที่สามของบริการรับรองความถูกต้องต้องการให้คุณระบุ
            <b>
              ข้อมูลชื่อจริงและเลขประจำตัวประชาชน.
              ข้อมูลนี้เป็นข้อมูลที่ละเอียดอ่อนส่วนบุคคล
            </b>
            คุณสามารถปฏิเสธที่จะให้บริการได้
            และจะไม่ส่งผลกระทบต่อการใช้งานฟังก์ชันอื่นๆ ตามปกติของแพลตฟอร์มนี้
            ข้อมูลนี้ใช้สำหรับการตรวจสอบชื่อจริงและวัตถุประสงค์ที่กำหนดโดยกฎหมายและข้อบังคับเท่านั้น
            และจะไม่ถูกนำไปใช้เพื่อวัตถุประสงค์อื่นโดยไม่ได้รับอนุญาตอย่างชัดแจ้งจากคุณ
          </li>
          <li>
            จ)
            ข้อมูลดังกล่าวข้างต้นถูกรวบรวมเพื่อให้เป็นไปตามข้อกำหนดภาระผูกพันของผู้ให้บริการในการเก็บรักษาบัญชีเผยแพร่ข้อมูล
            บัญชีสาธารณะ ผู้ใช้ถ่ายทอดสด ฯลฯ ตามกฎหมายและข้อบังคับที่เกี่ยวข้อง
            และเพื่อให้เป็นไปตามข้อกำหนดของนโยบายความเป็นส่วนตัวนี้
          </li>
        </ul>

        <p>2) การสื่อสารแบบโต้ตอบ</p>
        <ul className={styles.privacy_ul}>
          <li>
            ก) เมื่อคุณติดตามเนื้อหา เราจะรวบรวมเนื้อหาหรือบัญชีที่คุณติดตาม
            และแสดงให้คุณเห็นเนื้อหาหรือเนื้อหาที่โพสต์โดยบัญชีนั้น
          </li>
          <li>
            b)
            ข้อมูลดังกล่าวถูกรวบรวมเพื่อให้เป็นไปตามข้อกำหนดของกฎหมายและข้อบังคับที่เกี่ยวข้องสำหรับการเก็บรักษาข้อมูลบันทึกการใช้เว็บและเพื่อให้เป็นไปตามข้อกำหนดของนโยบายความเป็นส่วนตัวนี้
          </li>
        </ul>

        <p>5.5 ค้นหา</p>
        <p>
          1) เมื่อคุณใช้บริการค้นหา
          เราจะรวบรวมข้อมูลคำค้นหาของคุณโดยอัตโนมัติและบันทึกข้อมูลบันทึกสำหรับการวิเคราะห์เพื่อนำเสนอผลการค้นหาที่เกี่ยวข้องมากขึ้นแก่คุณ
        </p>
        <p>
          2) นอกจากนี้ เรายังอาจบันทึกข้อมูลที่คุณให้ สร้าง
          หรือเก็บรักษาโดยอัตโนมัติเมื่อใช้บริการของเรา เช่น
          เมื่อคุณป้อนคำค้นหาเมื่อใช้การค้นหา คลิกที่คำในอดีต
          คำที่แจ้งและคลิกลิงก์ และเมื่อคุณเข้าร่วมในการดำเนินการค้นหาที่ส่งมา
          ข้อมูล. คุณมีสิทธิ์ที่จะปฏิเสธที่จะให้ข้อมูลข้างต้น
          แต่จะส่งผลให้คุณไม่สามารถใช้บริการหรือฟังก์ชันเฉพาะ
          หรือไม่สามารถบรรลุผลตามที่ตั้งใจไว้ของบริการที่เกี่ยวข้องได้
        </p>
        <p>
          3)
          ข้อมูลดังกล่าวข้างต้นถูกรวบรวมเพื่อให้เป็นไปตามข้อกำหนดของกฎหมายและข้อบังคับที่เกี่ยวข้องในการเก็บรักษาข้อมูลบันทึกเครือข่ายและเพื่อให้เป็นไปตามข้อกำหนดของนโยบายความเป็นส่วนตัวนี้
          เพื่อให้บริการค้นหาที่มีประสิทธิภาพ ข้อมูลบางส่วนที่กล่าวถึงข้างต้น
          ข้อมูลจะถูกเก็บไว้ชั่วคราวในอุปกรณ์จัดเก็บข้อมูลภายในเครื่องของคุณ
          และสามารถแสดงเนื้อหาของผลการค้นหาและประวัติการค้นหาให้คุณได้เห็น
        </p>

        <p>
          5.6 <b>การแจ้งเตือน</b>
        </p>
        <p>
          <b>
            คุณเข้าใจและตกลงว่าสำหรับข้อมูลติดต่อของคุณ (หมายเลขติดต่อ)
            ที่คุณให้ไว้ในระหว่างกระบวนการใช้ผลิตภัณฑ์และ/หรือบริการ
            เราอาจส่งการแจ้งเตือนหลายประเภทไปยังพวกเขาอย่างน้อยหนึ่งรายการระหว่างการดำเนินการเพื่อจุดประสงค์ในการส่งข้อความถึงผู้ใช้
            การแจ้งเตือน การยืนยันตัวตน การยืนยันความปลอดภัย
            การวิจัยประสบการณ์ผู้ใช้ และจุดประสงค์ในการระงับข้อพิพาท นอกจากนี้
            เรายังอาจให้บริการที่คุณอาจสนใจในรูปแบบของข้อความและการโทรไปยังหมายเลขโทรศัพท์มือถือที่รวบรวมไว้ใน
            กระบวนการ ข้อมูลเชิงพาณิชย์ของฟังก์ชันหรือเหตุการณ์ข้างต้น
            แต่โปรดวางใจได้ว่าหากคุณไม่ต้องการรับข้อมูลดังกล่าว
            คุณสามารถยกเลิกการสมัครรับข้อมูลผ่านวิธีการยกเลิกการสมัครที่ให้ไว้ในข้อความโทรศัพท์มือถือ
            หรือติดต่อเราโดยตรงเพื่อยกเลิกการสมัคร
          </b>
        </p>

        <p>5.7 ฝ่ายบริการลูกค้า</p>
        <p>
          เมื่อคุณให้คำติชมแก่เรา
          เพื่ออำนวยความสะดวกในการติดต่อคุณหรือช่วยคุณแก้ปัญหา
          เราอาจต้องการให้คุณระบุชื่อ หมายเลขโทรศัพท์มือถือ ที่อยู่อีเมล
          และข้อมูลติดต่ออื่นๆ และอาจใช้ข้อมูลนี้เพื่อติดต่อกับคุณ .
          หากคุณส่งคำร้องเรียนเกี่ยวกับการละเมิดลิขสิทธิ์ เราจะขอให้คุณส่ง
          <b>บัตรประชาชน, หนังสือมอบอำนาจ, หนังสือรับรองความเป็นเจ้าของ</b>
          เพื่อชี้แจงสิทธิ์ของคุณในฐานะหัวข้อของการร้องเรียน
        </p>

        <p>5.8 บริการธุรกรรม</p>
        <p>
          เราจะรวบรวมบันทึกการทำธุรกรรมและข้อมูลบันทึกของคุณโดยอัตโนมัติเมื่อคุณซื้อหนังสือออนไลน์
          คอร์สออนไลน์ ผลิตภัณฑ์แต้มห้างสรรพสินค้า
          หรือผลิตภัณฑ์หรือบริการของเราด้วยวิธีอื่น
          ข้อมูลดังกล่าวถูกรวบรวมเพื่อให้เป็นไปตามข้อกำหนดของกฎหมายและข้อบังคับที่เกี่ยวข้องสำหรับการเก็บรักษาข้อมูลบันทึกเครือข่ายและข้อมูลธุรกรรม
          และเพื่อให้เป็นไปตามข้อกำหนดของนโยบายความเป็นส่วนตัวนี้ ซึ่งรวมถึง
          <b>ข้อมูลบันทึกการทำธุรกรรมเป็นข้อมูลที่ละเอียดอ่อนส่วนบุคคล</b>
          การปฏิเสธที่จะให้ข้อมูลนี้อาจทำให้เราไม่สามารถให้บริการธุรกรรมได้
          แต่จะไม่ส่งผลกระทบต่อการใช้งานฟังก์ชั่นอื่น ๆ ของบริการ ODAILY
          ตามปกติของคุณ
        </p>

        <p>5.9 เพื่อให้คุณได้รับความปลอดภัย</p>
        <p>
          เพื่อให้เป็นไปตามข้อกำหนดของ
          &quot;กฎหมายความปลอดภัยเครือข่ายของสาธารณรัฐประชาชนจีน&quot;
          (ต่อไปนี้จะเรียกว่า &quot;กฎหมายความปลอดภัยเครือข่าย&quot;)
          และกฎหมายและข้อบังคับอื่น ๆ
          ความต้องการการป้องกันความปลอดภัยของเครือข่ายและการปฏิบัติตามข้อกำหนดนี้
          นโยบายความเป็นส่วนตัว
          เราจะให้บริการและการรับประกันที่ปลอดภัยและเชื่อถือได้แก่คุณ
          เพื่อให้มั่นใจถึงเสถียรภาพและความปลอดภัยของการทำงานของระบบ
          ป้องกันไม่ให้บัญชีของคุณถูกขโมยและข้อมูลส่วนบุคคลจากการถูกได้มาอย่างผิดกฎหมาย
          และปกป้องความปลอดภัยของบัญชีของคุณ เราจำเป็นต้องสมัครอย่างแข็งขันกับ
          รวบรวม 1) ข้อมูลอุปกรณ์ของคุณ (รวมถึงรุ่นอุปกรณ์ ที่อยู่ MAC
          ของอุปกรณ์ ระบบปฏิบัติการ) ประเภท เวอร์ชันไคลเอนต์ ความละเอียด
          การตั้งค่าอุปกรณ์) รหัสประจำตัวเฉพาะของอุปกรณ์ (IMEI/android
          ID/IDFA/OPENUDID/GUID ข้อมูล SIM การ์ด IMSI) ; 2)
          ข้อมูลประจำตัวของเครือข่าย (รวมถึง<b>ข้อมูลตำแหน่ง GPS</b>, ที่อยู่
          IP, ผู้ให้บริการโทรคมนาคม, สภาพแวดล้อมเครือข่าย,
          หมายเลขรุ่นของซอฟต์แวร์, ประเภทเบราว์เซอร์,
          <b>ประวัติการเข้าชมเว็บไซต์</b>, บันทึกการทำงาน).
          เพื่อตัดสินความเสี่ยงของบัญชีของคุณ และเพื่อป้องกัน ค้นพบ
          และตรวจสอบพฤติกรรมที่เป็นอันตรายต่อความปลอดภัยของระบบ ผิดกฎหมาย
          หรือละเมิดกฎและข้อตกลงของเรา นอกจากนี้
          เราจะรวบรวมข้อมูลอุปกรณ์และข้อมูลซอฟต์แวร์ดังกล่าวของคุณเพื่อวิเคราะห์ผลิตภัณฑ์หรือปัญหาระบบบริการของเรา
          รวบรวมสถิติการเข้าชม และแก้ไขปัญหาความเสี่ยงที่อาจเกิดขึ้น
          หากคุณไม่ให้ข้อมูลข้างต้น
          เราจะไม่สามารถปกป้องความปลอดภัยของผลิตภัณฑ์หรือบริการของเราและบัญชีของคุณระหว่างที่คุณใช้ผลิตภัณฑ์หรือบริการของเรา
        </p>

        <p>5.10 ปรับปรุงประสบการณ์ผู้ใช้และความพึงพอใจ</p>
        <p>
          เพื่อปรับปรุงประสบการณ์และความพึงพอใจของคุณที่มีต่อบริการ ODAILY
          เราจะรวบรวมพฤติกรรมการทำงานของคุณโดยอัตโนมัติ
          และดำเนินการวิจัยและวิเคราะห์ข้อมูลผู้ใช้ตามพฤติกรรมการทำงานของคุณ
          เพื่อเพิ่มประสิทธิภาพบริการ ODAILY ให้ดียิ่งขึ้น
        </p>

        <p>
          5.11 <b>ข้อยกเว้นในการยินยอม</b>
        </p>
        <p>
          <b>
            โปรดทราบว่าเราไม่จำเป็นต้องได้รับอนุญาตจากคุณในการรวบรวมและใช้ข้อมูลส่วนบุคคลในสถานการณ์ต่อไปนี้:
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) เกี่ยวข้องกับความมั่นคงของชาติและความมั่นคงในการป้องกันประเทศ
          </li>
          <li>
            2) เกี่ยวข้องกับความมั่นคงสาธารณะ การสาธารณสุข
            และผลประโยชน์สาธารณะที่สำคัญ;
          </li>
          <li>
            3) เกี่ยวกับการสืบสวนคดีอาญา การฟ้องคดี การพิจารณาคดีและการบังคับคดี
          </li>
          <li>
            4)
            เพื่อปกป้องสิทธิและผลประโยชน์ทางกฎหมายที่สำคัญของเจ้าของข้อมูลส่วนบุคคลหรือบุคคลอื่น
            เช่น ชีวิตและทรัพย์สิน
            แต่เป็นการยากที่จะได้รับความยินยอมจากบุคคลนั้น
          </li>
          <li>
            5)
            ข้อมูลส่วนบุคคลที่เก็บรวบรวมจะถูกเปิดเผยต่อสาธารณะโดยเจ้าของข้อมูลส่วนบุคคลเอง
          </li>
          <li>
            6) ข้อมูลส่วนบุคคลของคุณถูกรวบรวมจากข้อมูลที่เปิดเผยตามกฎหมาย เช่น
            รายงานข่าวทางกฎหมาย การเปิดเผยข้อมูลของรัฐบาล และช่องทางอื่น ๆ
            ยกเว้นว่าคุณปฏิเสธอย่างชัดเจนหรือการประมวลผลข้อมูลที่ละเมิดผลประโยชน์หลักของคุณ
          </li>
          <li>7) จำเป็นต้องลงนามในสัญญาตามคำขอของคุณ</li>
          <li>
            8)
            จำเป็นสำหรับการรักษาการทำงานที่ปลอดภัยและมีเสถียรภาพของผลิตภัณฑ์และ/หรือบริการที่มีให้
            เช่น การค้นหาและจัดการกับความล้มเหลวของผลิตภัณฑ์และ/หรือบริการ
          </li>
          <li>9) จำเป็นสำหรับการรายงานข่าวทางกฎหมาย</li>
          <li>
            10)
            สถาบันการวิจัยเชิงวิชาการจำเป็นต้องดำเนินการวิจัยเชิงสถิติหรือเชิงวิชาการตามความสนใจของสาธารณะ
            และเมื่อให้ผลการวิจัยเชิงวิชาการหรือคำอธิบายแก่โลกภายนอก
            ให้ยกเลิกการระบุข้อมูลส่วนบุคคลที่อยู่ในผลลัพธ์
          </li>
          <li>11) กรณีอื่น ๆ ที่กำหนดโดยกฎหมายและข้อบังคับ</li>
        </ul>
        <p>
          <b>
            โปรดทราบว่าข้อมูลที่เพียงลำพังหรือร่วมกับข้อมูลอื่นไม่สามารถระบุตัวคุณหรือติดต่อคุณได้โดยตรงนั้นไม่ใช่ข้อมูลส่วนบุคคล
            หากเรารวมข้อมูลที่ไม่สามารถเชื่อมโยงกับบุคคลใดบุคคลหนึ่งโดยเฉพาะกับข้อมูลอื่นเพื่อระบุตัวบุคคลธรรมดา
            หรือใช้ร่วมกับข้อมูลส่วนบุคคล
            ข้อมูลดังกล่าวจะถือเป็นข้อมูลส่วนบุคคลตลอดระยะเวลาการใช้งานรวมกัน
          </b>
        </p>

        <p>5.12 การเรียกอนุญาตอุปกรณ์</p>
        <p>
          เพื่อให้แน่ใจว่าการใช้งานบริการ ODAILY ที่เกี่ยวข้องเป็นไปอย่างปกติ
          เราจำเป็นต้องเรียกใช้การอนุญาตที่จำเป็นที่เกี่ยวข้องตามสถานการณ์การใช้งานเฉพาะ
          สำหรับคำแนะนำการเรียกสิทธิ์เฉพาะ โปรดดูที่
          <a href="?application-authority" target="_blank">
            “ใบสมัครขออนุญาตและคำแนะนำ”
          </a>
          。
        </p>
        <p>
          คุณสามารถเลือกที่จะปิดการอนุญาตบางส่วนหรือทั้งหมดได้ในการตั้งค่าของอุปกรณ์
          ซึ่งอาจทำให้บริการ ODAILY
          ที่เกี่ยวข้องที่เกี่ยวข้องล้มเหลวในการรับรู้หรือไม่บรรลุผลตามที่คาดไว้
        </p>

        <p>5.13 คำแนะนำส่วนบุคคล</p>
        <p>
          โปรดทราบว่าบริการ ODAILY
          ไม่เกี่ยวข้องกับการแนะนำและการแสดงเนื้อหาส่วนบุคคล
          และเราจะไม่รวบรวมข้อมูลการท่องเว็บและการค้นหา การตั้งค่าพฤติกรรม
          และข้อมูลอื่น ๆ ของคุณสำหรับการวิเคราะห์ในแนวตั้ง
        </p>

        <h6>4. วิธีที่เราใช้คุกกี้และเทคโนโลยีที่คล้ายกัน</h6>
        <p>
          1.
          คุกกี้สามารถช่วยให้เว็บไซต์ระบุผู้ใช้ที่ลงทะเบียนและคำนวณจำนวนผู้ใช้
          โดยปกติแล้ว
          เว็บไซต์จะใช้คุกกี้เพื่อระบุว่าผู้ใช้ที่ลงทะเบียนเสร็จสมบูรณ์แล้วได้เข้าสู่ระบบหรือไม่
          แพลตฟอร์มนี้สัญญาว่าการวิจัยข้อมูลคุกกี้จะใช้เพื่อวัตถุประสงค์ในการปรับปรุงคุณภาพบริการ/ผลิตภัณฑ์และปรับปรุงประสบการณ์ผู้ใช้เท่านั้น
          ในขณะเดียวกัน
          หากคุณไม่ต้องการให้ข้อมูลส่วนบุคคลของคุณถูกเก็บไว้ในคุกกี้
          คุณสามารถกำหนดค่าเบราว์เซอร์ของคุณ: เลือก &quot;ปฏิเสธคุกกี้&quot;
          หรือ &quot;แจ้งให้คุณทราบเมื่อเว็บไซต์ส่งคุกกี้&quot; คุณขอยืนยันว่า
          เนื่องจากบริการ ODAILY ถูกใช้งานโดยการสนับสนุนคุกกี้ หลังจากปิดคุกกี้
          การเข้าถึงแพลตฟอร์มนี้ของคุณอาจได้รับผลกระทบหรือคุณอาจไม่สามารถรับบริการของแพลตฟอร์มนี้ได้อย่างเต็มที่
          เมื่อคุณไม่ปิดใช้งานคุกกี้ คุณอาจได้รับแจ้ง:
          จะเก็บข้อมูลผู้ใช้ในครั้งต่อไปที่คุณเข้าสู่เว็บไซต์นี้หรือไม่
          เพื่อให้ขั้นตอนการเข้าสู่ระบบง่ายขึ้น (เช่น
          การเข้าสู่ระบบด้วยคลิกเดียว)
        </p>
        <p>
          2.
          วิธีการและวัตถุประสงค์ของการใช้เว็บบีคอนบนแพลตฟอร์มนี้เหมือนกับของคุกกี้
          และข้อกำหนดข้างต้นจะมีผลบังคับใช้โดยการอ้างอิง
        </p>

        <h6>
          5. วิธีที่เราไว้วางใจในการประมวลผล การจัดหาภายนอก การถ่ายโอน
          และการเปิดเผยข้อมูลส่วนบุคคลของคุณสู่สาธารณะ
        </h6>
        <p>1. มอบหมายการประมวลผลข้อมูลส่วนบุคคลของคุณ</p>
        <p>
          เพื่อให้บริการ ODAILY ที่สมบูรณ์และมีคุณภาพสูงยิ่งขึ้นแก่คุณ
          พันธมิตรบริการของเราอาจจัดหาฟังก์ชันบางอย่าง (เช่น
          ผู้ให้บริการบุคคลที่สาม ผู้รับเหมา ตัวแทน)
          และเราจะมอบหมายให้พันธมิตรบริการจัดการข้อมูลของคุณบน ในนามของเรา
          ข้อมูลส่วนบุคคลบางอย่างรวมถึงแต่ไม่จำกัดเฉพาะพันธมิตรที่ให้บริการ
          ODAILY ด้วยบริการเข้าสู่ระบบแบบคลิกเดียว บริการข้อความ Push
          บริการวิเคราะห์ข้อมูลและสถิติ
          เราจะกำหนดให้พันธมิตรผู้ให้บริการปฏิบัติตามข้อผูกพันในการรักษาความลับที่เข้มงวด
          และใช้มาตรการการรักษาความลับที่มีประสิทธิภาพผ่านข้อตกลงที่เป็นลายลักษณ์อักษร
          การตรวจสอบในสถานที่ ฯลฯ
          และห้ามไม่ให้พวกเขาใช้ข้อมูลดังกล่าวเพื่อวัตถุประสงค์ที่คุณไม่ได้รับอนุญาต
          หลังจากยุติความสัมพันธ์ในการมอบความไว้วางใจแล้ว
          พันธมิตรบริการจะไม่บันทึกข้อมูลส่วนตัวของคุณอีกต่อไป
        </p>
        <p>2. จัดหาภายนอก</p>
        <p>
          เราจะไม่ให้ข้อมูลส่วนบุคคลของคุณแก่บริษัท องค์กร
          และบุคคลอื่นนอกเหนือจากผู้ให้บริการของผลิตภัณฑ์นี้
          ยกเว้นในกรณีต่อไปนี้:
        </p>
        <p>
          2.1 ความจำเป็นในการปฏิบัติตามข้อผูกพันทางกฎหมาย:
          ตามบทบัญญัติของกฎหมายและระเบียบข้อบังคับ ความจำเป็นในการดำเนินคดี
          การระงับข้อพิพาท หรือข้อกำหนดของหน่วยงานปกครองและตุลาการ
          ตลอดจนความจำเป็นในการปฏิบัติตามข้อผูกพันทางกฎหมายอื่นๆ
          หรือเหตุผลทางกฎหมายอื่น ๆ ที่กำหนดโดยกฎหมาย
          หากจำเป็นต้องตอบสนองต่อเหตุฉุกเฉินด้านสาธารณสุขหรือเพื่อปกป้องชีวิต
          สุขภาพ และความปลอดภัยในทรัพย์สินของคุณในสถานการณ์ฉุกเฉิน
          หรือเพื่อดำเนินการรายงานข่าวและการกำกับดูแลความคิดเห็นสาธารณะเพื่อประโยชน์สาธารณะ
          ให้ข้อมูลส่วนบุคคลของคุณ สู่โลกภายนอก
        </p>
        <p>
          2.2 ตามความยินยอมของคุณหรือคำขอริเริ่มของคุณ:
          หากคุณตกลงแยกต่างหากหรือขอให้เราให้ข้อมูลส่วนบุคคลของคุณแก่บุคคลที่สาม
          เราจะแจ้งให้คุณทราบถึงผู้รับข้อมูลส่วนบุคคลในลักษณะที่เหมาะสมในหน้าที่เกี่ยวข้องตามวัตถุประสงค์
          ชื่อยินยอมและข้อมูลติดต่อของคุณ หากคุณใช้บริการแชร์ข้อมูล
          เราจะระบุชื่อแพลตฟอร์มการแชร์ของบุคคลที่สามในหน้านี้
          สำหรับข้อมูลการติดต่อที่เฉพาะเจาะจง
          โปรดดูนโยบายความเป็นส่วนตัวของบุคคลที่สาม
        </p>
        <p>
          2.3 จำเป็นสำหรับการปฏิบัติตามข้อตกลงในการให้บริการ: บริการ ODAILY
          บางอย่างของเราอาจให้บริการแก่คุณโดยบริษัทในเครือและสถาบันความร่วมมือของเราหรือร่วมกับพวกเขา
          ดังนั้นเพื่อดำเนินการตามข้อตกลงในการให้บริการกับคุณ
          เราต้องให้ข้อมูลส่วนบุคคลของคุณแก่บริษัทในเครือหรือสถาบันความร่วมมือของเรา
          เรากำหนด
          <a href="?info-share-list" target="_blank">
            “รายการแบ่งปันข้อมูล”
          </a>
          แนะนำสถานการณ์ของบริษัทในเครือและสถาบันความร่วมมือของเราให้คุณทราบอย่างชัดเจนและรัดกุม
          และตรวจสอบ &quot;รายการแบ่งปันข้อมูล&quot;
        </p>

        <p>
          1) ข้อกำหนดสำหรับบริษัทในเครือ:
          เพื่ออำนวยความสะดวกให้คุณใช้ผลิตภัณฑ์หรือบริการที่เรามอบให้กับบริษัทในเครือหรือผลิตภัณฑ์อื่นๆ
          ข้อมูลส่วนบุคคลของคุณอาจถูกแบ่งปันกับบริษัทในเครือของเราและ/หรือผู้ให้บริการที่กำหนด
          เราจะแบ่งปันข้อมูลส่วนบุคคลที่จำเป็นและผูกพันตามวัตถุประสงค์ที่ระบุไว้ในนโยบายนี้เท่านั้น
          หากเราแบ่งปันข้อมูลส่วนบุคคลที่ละเอียดอ่อนของคุณหรือบริษัทในเครือเปลี่ยนวัตถุประสงค์การใช้และการประมวลผลข้อมูลส่วนบุคคล
          เราจะขออนุญาตจากคุณอีกครั้ง
        </p>

        <p>
          2) จัดหาให้กับสถาบันสหกรณ์ของเรา:
          เพื่อมอบผลิตภัณฑ์และบริการที่สมบูรณ์แก่คุณ
          บริการหรือเทคโนโลยีบางอย่างของเราจะจัดหาโดยสถาบันสหกรณ์ที่ได้รับอนุญาต
          โปรดทราบว่าเราจะไม่ให้ข้อมูลส่วนบุคคลของคุณแก่ผู้ประมวลผลข้อมูลส่วนบุคคลรายอื่นในเชิงรุก
          เราจะแบ่งปันข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ทางกฎหมาย
          ถูกต้องตามกฎหมาย จำเป็น เฉพาะเจาะจงและชัดเจนเท่านั้น
          และแบ่งปันเฉพาะข้อมูลส่วนบุคคลที่จำเป็นเท่านั้น
          เราจะให้ข้อมูลส่วนบุคคลของคุณแก่ผู้ประมวลผลข้อมูลส่วนบุคคลอื่น ๆ
          แจ้งชื่อผู้รับ ข้อมูลติดต่อ วัตถุประสงค์ในการประมวลผล วิธีการประมวลผล
          และประเภทของข้อมูลส่วนบุคคล และขอความยินยอมแยกต่างหากจากคุณ
          ในเวลาเดียวกัน
          เราจะลงนามในข้อตกลงการรักษาความลับที่เข้มงวดกับองค์กรพันธมิตร
          โดยกำหนดให้พวกเขาประมวลผลข้อมูลส่วนบุคคลของคุณโดยเคร่งครัดตามนโยบายความเป็นส่วนตัวนี้และนโยบายการรักษาความลับอื่น
          ๆ ที่เกี่ยวข้อง และใช้มาตรการรักษาความปลอดภัย
          เรากำหนดให้สถาบันพันธมิตรของเราไม่มีสิทธิ์ใช้ข้อมูลส่วนบุคคลที่แบ่งปันเพื่อวัตถุประสงค์อื่นใด
          <b>
            เมื่อคุณดูหน้าเว็บที่สร้างโดยบุคคลที่สามหรือใช้แอปพลิเคชันที่พัฒนาโดยบุคคลที่สาม
            บุคคลที่สามเหล่านี้อาจวางคุกกี้ของตนเองหรือใช้วิธีการทางเทคนิคอื่นๆ
            วิธีการทางเทคนิคเหล่านี้ไม่ได้อยู่ภายใต้การควบคุมของเรา
            และการใช้งานไม่ได้อยู่ภายใต้ขอบเขตนี้ นโยบายความเป็นส่วนตัว
            สถาบันที่ร่วมมือกันอาจมีนโยบายคุ้มครองข้อมูลส่วนบุคคลและข้อตกลงของผู้ใช้ที่เป็นอิสระ
            เราขอแนะนำให้คุณอ่านและปฏิบัติตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลและข้อตกลงผู้ใช้ของบุคคลที่สาม
          </b>
        </p>

        <p>
          2.4 ตามเหตุผลทางกฎหมายอื่นๆ ที่กำหนดโดยกฎหมาย:
          เราอาจใช้เหตุผลทางกฎหมายอื่นๆ ที่กำหนดโดยกฎหมาย เช่น
          การตอบสนองต่อเหตุฉุกเฉินด้านสาธารณสุขหรือในสถานการณ์ฉุกเฉินเพื่อปกป้องชีวิต
          สุขภาพ และความปลอดภัยในทรัพย์สินของคุณ
          หรือการนำข่าวไปใช้เพื่อประโยชน์สาธารณะ หากคุณ
          ให้ข้อมูลส่วนบุคคลของคุณกับบุคคลที่สามเพื่อการรายงาน
          การกำกับดูแลความคิดเห็นสาธารณะ ฯลฯ
          เราจะแจ้งข้อมูลที่เกี่ยวข้องให้คุณทราบในเวลาที่เหมาะสม (เช่น
          หลังจากสถานการณ์ฉุกเฉินยุติลง)
        </p>

        <p>3. การโอน</p>
        <p>
          เราจะไม่ถ่ายโอนข้อมูลส่วนบุคคลของคุณไปยังบริษัท องค์กร หรือบุคคลใดๆ
          ยกเว้นในกรณีต่อไปนี้:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) โอนด้วยความยินยอมอย่างชัดแจ้ง
            หลังจากได้รับความยินยอมอย่างชัดแจ้งจากคุณ
            เราจะโอนข้อมูลส่วนบุคคลของคุณไปยังบุคคลอื่น
          </li>
          <li>
            2) การโอนในกรณีควบรวมกิจการ การซื้อกิจการหรือการชำระบัญชีล้มละลาย
            เมื่อ ODAILY เกี่ยวข้องกับการควบรวมกิจการ การเข้าซื้อกิจการ
            หรือการชำระบัญชีจากการล้มละลาย
            หากเกี่ยวข้องกับการถ่ายโอนข้อมูลส่วนบุคคล
            เราจะกำหนดให้บริษัทหรือองค์กรใหม่ที่ควบคุมข้อมูลส่วนบุคคลของคุณยังคงผูกพันตามนโยบายความเป็นส่วนตัวนี้
            มิฉะนั้น เราจะกำหนดให้บริษัทหรือองค์กร เพื่อต่ออายุให้คุณ
            ขอความยินยอมอนุญาต
          </li>
        </ul>

        <p>4. การเปิดเผยต่อสาธารณะ</p>
        <p>
          4.1 การเปิดเผยต่อสาธารณะ หมายถึง
          พฤติกรรมการเผยแพร่ข้อมูลต่อสังคมหรือกลุ่มบุคคลที่ไม่ระบุรายละเอียด
          เราจะไม่เปิดเผยข้อมูลส่วนบุคคลของคุณต่อสาธารณะ
          ยกเว้นการเปิดเผยที่จำเป็นในเรื่องที่จำเป็น เช่น
          บทลงโทษสำหรับบัญชีที่ผิดกฎหมาย พฤติกรรมการฉ้อโกง ฯลฯ
          และลดการเปิดเผยข้อมูลที่เกี่ยวข้องเมื่อประกาศรายชื่อผู้ชนะ/ผู้ชนะ ฯลฯ
          หาก การเปิดเผยต่อสาธารณะเป็นสิ่งจำเป็นด้วยเหตุผลที่สมเหตุสมผล
          เราจะแจ้งให้คุณทราบถึงวัตถุประสงค์และประเภทของข้อมูลที่เปิดเผยต่อสาธารณะก่อนการเปิดเผยต่อสาธารณะ
          (หากเกี่ยวข้องกับข้อมูลที่ละเอียดอ่อนส่วนบุคคลของคุณ
          เราจะแจ้งให้คุณทราบถึงเนื้อหาของข้อมูลที่ละเอียดอ่อนที่เกี่ยวข้องด้วย)
          และหลังจากได้รับการอนุญาตและความยินยอมจากคุณ
          ข้อมูลจะถูกเปิดเผยต่อสาธารณะ
          เว้นแต่กฎหมายและระเบียบข้อบังคับกำหนดไว้เป็นอย่างอื่นหรือตกลงเป็นอย่างอื่นในนโยบายความเป็นส่วนตัวนี้
        </p>
        <p>
          4.2 สำหรับการเปิดเผยข้อมูลส่วนบุคคลของคุณต่อสาธารณะ
          เราจะตรวจสอบความถูกต้อง เหตุผล
          และความชอบด้วยกฎหมายทันทีและรอบคอบหลังจากได้รับคำขอให้เปิดเผยต่อสาธารณะ
          และจะใช้มาตรการรักษาความปลอดภัยข้อมูลส่วนบุคคลที่เข้มงวดที่สุดในระหว่างและหลังการเปิดเผยต่อสาธารณะ
          มาตรการและวิธีการป้องกัน เพื่อปกป้องมัน
        </p>

        <p>5. การเข้าถึง SDK ของบุคคลที่สาม</p>
        <p>
          เพื่อให้แน่ใจว่าคุณสามารถใช้และเพลิดเพลินกับบริการ ODAILY
          ที่ดียิ่งขึ้น
          ฟังก์ชันบางอย่างที่เรามอบให้นั้นจัดทำโดยองค์กรบุคคลที่สามที่เราร่วมมือด้วยในรูปแบบของปลั๊กอิน
          SDK ผู้ให้บริการด้านเทคนิคของ SDK จะได้รับสิทธิ์ที่สอดคล้องกัน
          และข้อมูล เราจะดำเนินการตรวจสอบความปลอดภัยบน SDK
          ที่รวมอยู่ในแอพเพื่อให้แน่ใจว่า SDK
          เหล่านี้รวบรวมเฉพาะข้อมูลส่วนบุคคลที่จำเป็นต่อการตระหนักถึงหน้าที่หรือบริการของพวกเขา
          คุณสามารถคลิกที่นี่เพื่อดู
          <a href="?info-share-list" target="_blank">
            รายการตรวจสอบการแบ่งปันข้อมูล
          </a>
        </p>

        <p>
          6.
          ข้อยกเว้นของการอนุญาตล่วงหน้าและความยินยอมสำหรับการประมวลผลที่ได้รับมอบหมาย
          การจัดหาภายนอก การถ่ายโอน และการเปิดเผยข้อมูลส่วนบุคคลต่อสาธารณะ
        </p>
        <p>
          <b>
            ตามกฎหมายและข้อบังคับที่เกี่ยวข้อง ในสถานการณ์ต่อไปนี้ การแบ่งปัน
            การถ่ายโอน
            และการเปิดเผยข้อมูลส่วนบุคคลของคุณสู่สาธารณะไม่จำเป็นต้องได้รับอนุญาตและยินยอมล่วงหน้าจากคุณ:
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1)
            ที่เกี่ยวข้องกับการปฏิบัติตามข้อผูกพันที่กำหนดโดยกฎหมายและข้อบังคับ;
          </li>
          <li>
            2)
            เกี่ยวข้องโดยตรงกับความมั่นคงของชาติและความมั่นคงในการป้องกันประเทศ;
          </li>
          <li>
            3) เกี่ยวข้องโดยตรงกับความมั่นคงสาธารณะ สาธารณสุข
            หรือผลประโยชน์สาธารณะที่สำคัญ
          </li>
          <li>
            4) เกี่ยวข้องโดยตรงกับการบังคับใช้กฎหมายของฝ่ายตุลาการหรือฝ่ายบริหาร
            เช่น การสืบสวนคดีอาญา การฟ้องร้อง
            การพิจารณาคดีและการดำเนินการตามคำพิพากษา
          </li>
          <li>
            5) เพื่อปกป้องชีวิตส่วนตัวของคุณ ทรัพย์สิน
            และสิทธิและผลประโยชน์ทางกฎหมายที่สำคัญอื่นๆ ของคุณ
            แต่เป็นการยากที่จะได้รับความยินยอมจากบุคคลนั้น
          </li>
          <li>6) ข้อมูลส่วนบุคคลที่ท่านเปิดเผยต่อสาธารณะด้วยตัวท่านเอง;</li>
          <li>
            7) รวบรวมข้อมูลส่วนบุคคลจากข้อมูลที่เปิดเผยตามกฎหมาย เช่น
            รายงานข่าวทางกฎหมาย การเปิดเผยข้อมูลของทางราชการ และช่องทางอื่นๆ
          </li>
        </ul>
        <p>
          <b>
            โปรดทราบว่า ตามกฎหมายที่ใช้บังคับ
            หากเราใช้มาตรการทางเทคนิคและมาตรการที่จำเป็นอื่นๆ
            ในการประมวลผลข้อมูลส่วนบุคคล
            จนทำให้ผู้รับข้อมูลไม่สามารถระบุตัวบุคคลที่เฉพาะเจาะจงอีกครั้งและไม่สามารถกู้คืนได้
            การประมวลผลที่ได้รับมอบอำนาจ การจัดหาภายนอก การถ่ายโอน
            ข้อมูลที่ประมวลผลดังกล่าว、การเปิดเผยต่อสาธารณะไม่จำเป็นต้องแจ้งให้คุณทราบและได้รับความยินยอมจากคุณ
          </b>
        </p>

        <h6>6. วิธีที่เราจัดเก็บและปกป้องข้อมูลส่วนบุคคลของคุณ</h6>
        <p>1. การจัดเก็บ</p>
        <p>
          1.1 สถานที่จัดเก็บ:
          เราจะจัดเก็บข้อมูลส่วนบุคคลของคุณที่รวบรวมและสร้างขึ้นระหว่างการดำเนินงานในสาธารณรัฐประชาชนจีนในสาธารณรัฐประชาชนจีนตามกฎหมายและข้อบังคับ
          ในปัจจุบัน เราจะไม่ส่งข้อมูลข้างต้นไปยังต่างประเทศ
          หากเราส่งไปต่างประเทศ
          เราจะปฏิบัติตามกฎหมายและนโยบายการกำกับดูแลที่เกี่ยวข้องของจีนอย่างเคร่งครัด
          และจะปฏิบัติตามกฎระเบียบของประเทศที่เกี่ยวข้องหรือขอความยินยอมจากคุณ
        </p>
        <p>
          1.2 ระยะเวลาในการจัดเก็บ:
          เราสัญญาว่าจะจัดเก็บข้อมูลส่วนบุคคลของคุณภายในระยะเวลาที่เหมาะสมและจำเป็นตามกฎหมาย
          หลังจากช่วงเวลาข้างต้น เราจะลบหรือไม่เปิดเผยข้อมูลส่วนบุคคลของคุณ
        </p>
        <p>
          1.3 หากเราหยุดดำเนินการ
          เราจะหยุดรวบรวมข้อมูลส่วนบุคคลของคุณในเวลาที่เหมาะสม
          แจ้งให้คุณทราบถึงการหยุดดำเนินการโดยการจัดส่งหรือประกาศทีละรายการ
          และลบหรือปกปิดข้อมูลส่วนบุคคลของคุณที่เก็บไว้
        </p>

        <p>2. การป้องกัน</p>
        <p>
          2.1
          เราให้ความสำคัญอย่างยิ่งต่อการรักษาความปลอดภัยข้อมูลส่วนบุคคลของคุณและจะพยายามใช้มาตรการรักษาความปลอดภัยที่สมเหตุสมผล
          (รวมถึงด้านเทคนิคและการจัดการ)
          เพื่อปกป้องข้อมูลส่วนบุคคลของคุณและป้องกันไม่ให้ข้อมูลส่วนบุคคลที่คุณให้ไว้ถูกนำไปใช้หรือเข้าถึงอย่างไม่เหมาะสมโดยไม่ได้รับอนุญาต
          การเปิดเผยต่อสาธารณะ ใช้ ดัดแปลง ทำให้เสียหาย สูญหาย หรือเปิดเผย
          ปัจจุบันเราได้ผ่านไปแล้ว
          <b>
            การประเมินและการจัดเก็บระดับการป้องกันความปลอดภัยของข้อมูลระดับชาติ
            (ระดับที่สาม)
          </b>
        </p>
        <p>
          2.2 เราจะปกป้องข้อมูลส่วนบุคคลของคุณโดยใช้เทคโนโลยีการเข้ารหัส
          การประมวลผลแบบไม่เปิดเผยชื่อ
          และวิธีที่สมเหตุสมผลและเป็นไปได้ที่เกี่ยวข้อง
          ซึ่งไม่ด้อยกว่าข้อมูลในอุตสาหกรรม
          และใช้กลไกการป้องกันความปลอดภัยเพื่อป้องกันการโจมตีที่เป็นอันตรายต่อข้อมูลส่วนบุคคลของคุณ
        </p>
        <p>
          2.3
          เราจะสร้างระบบการจัดการความปลอดภัยพิเศษและกระบวนการรักษาความปลอดภัยของข้อมูลเพื่อให้มั่นใจในความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
          เราใช้นโยบายการเข้าถึงและการเข้าถึงข้อมูลที่เข้มงวดเพื่อให้แน่ใจว่าเฉพาะบุคลากรที่ได้รับอนุญาตเท่านั้นที่สามารถเข้าถึงข้อมูลส่วนบุคคลของคุณได้
        </p>
        <p>
          2.4
          <b>
            แม้ว่าจะมีการใช้มาตรการที่สมเหตุสมผลและมีประสิทธิภาพดังกล่าวข้างต้นและปฏิบัติตามมาตรฐานที่กำหนดโดยกฎหมายที่เกี่ยวข้องแล้ว
            โปรดเข้าใจว่าเนื่องจากข้อจำกัดทางเทคนิคและวิธีการที่เป็นอันตรายในอุตสาหกรรมอินเทอร์เน็ต
            แม้ว่าเราจะพยายามอย่างดีที่สุดเพื่อเสริมความปลอดภัย มาตรการ
            เป็นไปไม่ได้ที่จะรับประกันความปลอดภัยของข้อมูล 100% เสมอไป
            และเราจะพยายามอย่างดีที่สุดเพื่อรับรองความปลอดภัยของข้อมูลส่วนบุคคลที่คุณให้กับเรา
          </b>
        </p>
        <p>
          2.5
          <b>
            คุณทราบและเข้าใจว่าระบบและเครือข่ายการสื่อสารที่คุณใช้เพื่อเข้าถึงบริการของเราอาจมีปัญหาเนื่องจากปัจจัยที่อยู่นอกเหนือการควบคุมของเรา
            ดังนั้น
            เราขอแนะนำอย่างยิ่งให้คุณใช้มาตรการที่แข็งขันเพื่อปกป้องความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
            รวมถึงแต่ไม่จำกัดเฉพาะการใช้รหัสผ่านที่ซับซ้อน
            เปลี่ยนรหัสผ่านเป็นประจำ
            และไม่เปิดเผยรหัสผ่านบัญชีและข้อมูลส่วนตัวที่เกี่ยวข้องแก่ผู้อื่น
          </b>
        </p>
        <p>
          2.6 เราจะกำหนดแผนรับมือเหตุฉุกเฉิน
          และเปิดใช้งานแผนฉุกเฉินทันทีเมื่อเหตุการณ์ด้านความปลอดภัยข้อมูลของผู้ใช้เกิดขึ้น
          และพยายามป้องกันผลกระทบและผลที่ตามมาของเหตุการณ์ด้านความปลอดภัยเหล่านี้ไม่ให้ขยายวงกว้างออกไป
          เมื่อเกิดเหตุการณ์ความปลอดภัยของข้อมูลผู้ใช้ (การเปิดเผย การสูญหาย)
          เราจะแจ้งให้คุณทราบโดยทันทีตามข้อกำหนดของกฎหมายและระเบียบข้อบังคับ:
          สถานการณ์พื้นฐานและผลกระทบที่อาจเกิดขึ้นจากเหตุการณ์ด้านความปลอดภัย
          มาตรการกำจัดที่เราดำเนินการหรือจะดำเนินการ และ
          การป้องกันของคุณเองและคำแนะนำในการลดความเสี่ยงและการแก้ไขสำหรับคุณ
          เราจะแจ้งให้คุณทราบโดยทันทีเกี่ยวกับสถานการณ์ที่เกี่ยวข้องกับกิจกรรมผ่านการแจ้งเตือนแบบพุช
          อีเมล จดหมาย ข้อความ และรูปแบบอื่น ๆ ที่เกี่ยวข้อง
          เมื่อเป็นเรื่องยากที่จะแจ้งให้คุณทราบทีละรายการ
          เราจะใช้วิธีการที่สมเหตุสมผลและมีประสิทธิภาพในการออกประกาศ
          ในขณะเดียวกัน
          เราจะรายงานการจัดการเหตุการณ์ความปลอดภัยของข้อมูลผู้ใช้ตามข้อกำหนดของหน่วยงานกำกับดูแลที่เกี่ยวข้อง
        </p>
        <p>
          2.7 เมื่อคุณออกจากแพลตฟอร์มนี้และบริการ ODAILY แล้ว
          เรียกดูหรือใช้เว็บไซต์ บริการ และทรัพยากรเนื้อหาอื่น ๆ
          เราจะไม่มีความสามารถและภาระผูกพันโดยตรงในการปกป้องข้อมูลส่วนบุคคลใด ๆ
          ที่คุณส่งบนซอฟต์แวร์และเว็บไซต์อื่นนอกเหนือจากแพลตฟอร์มนี้และบริการ
          ODAILY โดยไม่คำนึงถึง ของการเข้าสู่ระบบ การเรียกดู
          หรือการใช้ซอฟต์แวร์ข้างต้นของคุณ
          ไม่ว่าเว็บไซต์จะอิงตามลิงก์หรือคำแนะนำของแพลตฟอร์มนี้ก็ตาม
        </p>

        <h6>7. คุณจัดการข้อมูลส่วนบุคคลของคุณอย่างไร</h6>
        <p>1. คุณมีสิทธิ์สอบถามและแก้ไขข้อมูลส่วนบุคคลของคุณ</p>
        <p>
          เราให้ความสำคัญอย่างยิ่งกับข้อกังวลของคุณเกี่ยวกับข้อมูลส่วนบุคคล
          และมอบฟังก์ชันที่หลากหลายเพื่อปกป้องสิทธิ์ของคุณในการสอบถามและแก้ไขข้อมูลส่วนบุคคล
        </p>
        <p>1.1 จัดการข้อมูลบัญชี</p>
        <p>
          &quot;ของฉัน&quot; → &quot;การจัดการบัญชี&quot;
          คุณสามารถใช้ฟังก์ชันนี้เพื่อแก้ไขรหัสผ่านบัญชี
          แก้ไขหมายเลขโทรศัพท์มือถือที่ผูกไว้ และผูกบัญชีบุคคลที่สาม
        </p>
        <p>
          &quot;ของฉัน&quot; → &quot;บัตรบัญชี&quot; → &quot;แก้ไข&quot;
          คุณสามารถดูและแก้ไขข้อมูลบัญชีของคุณในโปรไฟล์แก้ไข รวมถึงภาพแทนตัว
          ชื่อเล่น เพศ วันเกิด โปรไฟล์ ตัวตน อุตสาหกรรม
        </p>
        <p>1.2 จัดการความคิดเห็นและข้อมูลบทความ</p>
        <p>
          &quot;ของฉัน&quot; → &quot;หน้าแรกส่วนตัว&quot;
          คลิกความคิดเห็นหรือบทความเดียวเพื่อเข้าสู่หน้ารายละเอียดเนื้อหา
          และคุณสามารถเรียนรู้เกี่ยวกับความคิดเห็นหรือบทความที่คุณเผยแพร่
        </p>
        <p>1.3 จัดการประวัติการเข้าชม</p>
        <p>
          &quot;ของฉัน&quot; → &quot;เรียกดู&quot;
          คุณสามารถดูประวัติการเข้าชมของคุณได้
        </p>
        <p>1.4 จัดการบันทึกข้อกังวล</p>
        <p>
          &quot;ของฉัน&quot; → &quot;ติดตาม&quot;
          คุณสามารถจัดการรายการเฝ้าดูทั้งหมด ดูและแก้ไขผู้ใช้ ผู้เขียน
          และหัวข้อที่คุณติดตาม
        </p>
        <p>
          &quot;ของฉัน&quot; → &quot;รายการโปรด&quot; คุณสามารถจัดการบทความ
          เสียงและวิดีโอที่คุณชื่นชอบ และอื่นๆ ได้
        </p>
        <p>
          หลังจากที่คุณเปลี่ยนขอบเขตการอนุญาต เราจะปรับบริการ ODAILY
          ที่แพลตฟอร์มนี้มอบให้คุณตามนั้น
          แต่โปรดเข้าใจว่าสิ่งนี้จะไม่ส่งผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลก่อนหน้านี้ตามการอนุญาตของคุณ
        </p>

        <p>2. การลบข้อมูลส่วนบุคคลของคุณ</p>
        <p>เราจะมีวิธีต่อไปนี้เพื่อช่วยคุณลบข้อมูลที่คุณโพสต์บนแพลตฟอร์มนี้:</p>
        <p>2.1 ลบความคิดเห็นและข้อมูลบทความ</p>
        <p>
          &quot;ของฉัน&quot;→&quot;หน้าแรกส่วนตัว&quot;
          คลิกที่ความคิดเห็นหรือบทความที่เผยแพร่รายการเดียวเพื่อเข้าสู่หน้ารายละเอียดเนื้อหา
          และคุณสามารถลบความคิดเห็นหรือบทความที่เผยแพร่เมื่อใดก็ได้โดยการกดค้าง
          อย่างไรก็ตาม หากคุณลบความคิดเห็นหรือบทความที่เผยแพร่
          คุณเพียงแค่ยกเลิกการแสดงต่อสาธารณะเท่านั้น
          และเรายังจำเป็นต้องจัดเก็บความคิดเห็นหรือบทความที่คุณเผยแพร่ในพื้นหลังตามกฎหมายและระเบียบข้อบังคับ
        </p>
        <p>2.2 ลบประวัติการเรียกดู</p>
        <p>
          &quot;ของฉัน&quot; → &quot;การเรียกดู&quot;
          คุณสามารถลบประวัติการเข้าชมของคุณได้
        </p>
        <p>2.3 ลบบันทึกการติดตาม</p>
        <p>
          &quot;ของฉัน&quot; → &quot;ติดตาม&quot; คุณสามารถลบผู้ใช้ ผู้เขียน
          และหัวข้อที่คุณติดตามได้
        </p>
        <p>
          &quot;ของฉัน&quot; → &quot;รายการโปรด&quot; คุณสามารถลบบทความ
          เสียงและวิดีโอ และอื่นๆ ที่คุณชื่นชอบได้
        </p>
        <p>
          2.4 ในสถานการณ์ต่อไปนี้ เราจะดำเนินการลบข้อมูลส่วนบุคคลของคุณ
          หากเรายังไม่ได้ลบ
          คุณสามารถขอให้เราลบข้อมูลส่วนบุคคลของคุณโดยส่งอีเมลไปที่
          om@odaily.email:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            (1) บรรลุวัตถุประสงค์ของการประมวลผลแล้ว
            ไม่สามารถทำได้หรือไม่มีความจำเป็นอีกต่อไปในการบรรลุวัตถุประสงค์ของการประมวลผล
          </li>
          <li>
            (2) เราหยุดให้บริการผลิตภัณฑ์หรือบริการ
            หรือระยะเวลาการเก็บรักษาหมดอายุแล้ว
          </li>
          <li>(3) คุณถอนความยินยอมของคุณ;</li>
          <li>
            (4) เราประมวลผลข้อมูลส่วนบุคคลโดยละเมิดกฎหมาย
            ข้อบังคับการบริหารหรือข้อตกลง;
          </li>
          <li>(5) กรณีอื่น ๆ ที่กำหนดโดยกฎหมายและระเบียบบริหาร</li>
        </ul>
        <p>
          หลังจากที่คุณลบข้อมูล เราจะปรับบริการ ODAILY
          ที่แพลตฟอร์มนี้มอบให้คุณตามนั้น
          แต่โปรดเข้าใจว่าสิ่งนี้จะไม่ส่งผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลก่อนหน้านี้ตามการอนุญาตของคุณ
        </p>

        <p>3. การรับสำเนาข้อมูลส่วนบุคคลของคุณ</p>
        <p>
          หากคุณต้องการสำเนาข้อมูลส่วนบุคคลของคุณ คุณสามารถส่งอีเมลไปที่
          om@odaily.email
          และเราจะให้ข้อมูลส่วนบุคคลของคุณที่เรารวบรวมไว้ตามคำขอที่เป็นลายลักษณ์อักษรของคุณ
        </p>

        <p>4. คุณมีสิทธิ์ที่จะถอนการอนุญาตของคุณสำหรับข้อมูลส่วนบุคคล</p>
        <p>
          คุณสามารถปิดตำแหน่ง GPS, กล้อง, ไมโครโฟน, การอนุญาตอัลบั้มภาพ,
          เปลี่ยนขอบเขตการยินยอมหรือถอนการอนุญาตของคุณในระบบปฏิบัติการของอุปกรณ์เอง
          หลังจากปิดการอนุญาตแล้ว
          เราจะไม่รวบรวมข้อมูลที่เกี่ยวข้องกับการอนุญาตเหล่านี้อีกต่อไป
          เพื่อปฏิเสธให้เรารวบรวมข้อมูลส่วนบุคคลที่เกี่ยวข้องของคุณ
          คุณต้องเข้าใจว่าหลังจากที่คุณเพิกถอนการอนุญาต เราจะไม่สามารถให้บริการ
          ODAILY แก่คุณต่อไปได้ซึ่งสอดคล้องกับการถอนการอนุญาต
          <b>
            อย่างไรก็ตาม
            การตัดสินใจเพิกถอนการอนุญาตของคุณจะไม่ส่งผลต่อการประมวลผลข้อมูลส่วนบุคคลตามการอนุญาตของคุณก่อนหน้านี้
          </b>
        </p>

        <p>5. สิทธิในการถ่ายโอนข้อมูลส่วนบุคคล</p>
        <p>
          หากคุณต้องการถ่ายโอนข้อมูลส่วนบุคคลของคุณไปยังแพลตฟอร์ม บริษัท
          หรือองค์กรอื่น คุณสามารถติดต่อเราผ่านวิธีการที่ระบุไว้ในข้อ 11
          ของนโยบายความเป็นส่วนตัวนี้ เราจะตรวจสอบคำขอของคุณ
          และเราจะจัดหาวิธีการโอนข้อมูลส่วนบุคคลที่เกี่ยวข้องให้คุณ
          โดยขึ้นอยู่กับข้อกำหนดเบื้องต้นสำหรับการโอนข้อมูลส่วนบุคคลที่กฎหมายกำหนด
        </p>

        <p>6. การยกเลิกบัญชี ODAILY</p>
        <p>
          หากคุณต้องการยกเลิกบัญชี ODAILY โปรดส่งใบสมัครใน &quot;ของฉัน&quot; →
          &quot;การจัดการบัญชี&quot; → &quot;ยกเลิกบัญชี&quot;
          หลังจากยกเลิกบัญชี เราจะหยุดให้บริการ ODAILY แก่คุณ
          และปกป้องข้อมูลของคุณด้วยการไม่เปิดเผยชื่อและวิธีการอื่น ๆ
          ตามข้อกำหนดของกฎหมายและข้อบังคับที่เกี่ยวข้อง
        </p>

        <p>
          7. การจัดการและการควบคุมข้อมูลการตลาดและการผลักดันเป้าหมายอย่างอิสระ
        </p>
        <p>
          7.1 การพุชข้อความ - หากคุณไม่ต้องการรับการแจ้งเตือนข้อความบนหน้าแอป
          คุณสามารถปฏิเสธที่จะรับข้อความแจ้งเตือนเมื่อติดตั้งแอปเป็นครั้งแรก
          หรือปิดฟังก์ชันการแจ้งเตือนที่เกี่ยวข้องใน &quot;ของฉัน&quot; →
          &quot;การจัดการการพุช&quot; .
        </p>
        <p>
          7.2
          การส่งเสริมการขาย/การกลับมาเยี่ยมชม——หากคุณไม่ต้องการรับข้อความส่งเสริมการขายหรือการกลับมาเยี่ยมทางโทรศัพท์ของเรา
          คุณสามารถยกเลิกการสมัครผ่านฟังก์ชันยกเลิกการสมัครที่เกี่ยวข้องในข้อมูล
          หรือปฏิเสธอย่างชัดแจ้งในการกลับมาเยี่ยมทางโทรศัพท์
        </p>

        <p>8. ตอบสนองความต้องการของคุณ</p>
        <p>
          ในสถานการณ์ต่อไปนี้
          เราจะไม่สามารถตอบสนองต่อคำขอของคุณตามกฎหมายและระเบียบข้อบังคับ:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1)
            ที่เกี่ยวข้องกับการปฏิบัติตามข้อผูกพันที่กำหนดโดยกฎหมายและข้อบังคับ;
          </li>
          <li>
            2)
            เกี่ยวข้องโดยตรงกับความมั่นคงของชาติและความมั่นคงในการป้องกันประเทศ;
          </li>
          <li>
            3) เกี่ยวข้องโดยตรงกับความมั่นคงสาธารณะ สาธารณสุข
            และผลประโยชน์สาธารณะที่สำคัญ
          </li>
          <li>
            4) เกี่ยวข้องโดยตรงกับการสืบสวนคดีอาญา การฟ้องร้อง
            การพิจารณาคดีและการดำเนินการตามคำพิพากษา;
          </li>
          <li>
            5)
            มีหลักฐานเพียงพอว่าเจ้าของข้อมูลส่วนบุคคลมีความประสงค์ร้ายหรือละเมิดสิทธิ;
          </li>
          <li>
            6)
            เพื่อปกป้องสิทธิและผลประโยชน์ทางกฎหมายที่สำคัญของเจ้าของข้อมูลส่วนบุคคลหรือบุคคลอื่น
            เช่น ชีวิตและทรัพย์สิน
            แต่เป็นการยากที่จะได้รับการอนุญาตและความยินยอมจากบุคคลนั้น
          </li>
          <li>
            7)
            การตอบสนองต่อคำขอของคุณจะสร้างความเสียหายอย่างร้ายแรงต่อสิทธิ์และผลประโยชน์โดยชอบด้วยกฎหมายของคุณหรือบุคคลหรือองค์กรอื่น
            ๆ
          </li>
          <li>8) ผู้ที่เกี่ยวข้องกับความลับทางการค้า</li>
        </ul>

        <h6>8. การคุ้มครองข้อมูลส่วนบุคคลของผู้เยาว์</h6>
        <p>
          1. เราให้ความสำคัญอย่างยิ่งต่อการคุ้มครองข้อมูลส่วนบุคคลของผู้เยาว์
          ตามกฎหมายและข้อบังคับที่เกี่ยวข้อง หากคุณเป็นผู้เยาว์ที่มีอายุต่ำกว่า
          18 ปี
          คุณควรอ่านและยอมรับนโยบายความเป็นส่วนตัวนี้ภายใต้การดูแลและคำแนะนำของพ่อแม่หรือผู้ปกครองคนอื่นๆ
          ก่อนใช้บริการ ODAILY
        </p>
        <p>
          2.
          เราปกป้องข้อมูลส่วนบุคคลของผู้เยาว์ตามกฎหมายและข้อบังคับของประเทศที่เกี่ยวข้อง
          และจะรวบรวม ใช้ จัดเก็บ แบ่งปัน โอน หรือใช้เมื่อกฎหมายอนุญาต
          โดยได้รับความยินยอมอย่างชัดแจ้งจากพ่อแม่หรือผู้ปกครองคนอื่นๆ หรือเมื่อ
          จำเป็นต้องปกป้องผู้เยาว์ เปิดเผยข้อมูลส่วนบุคคลของผู้เยาว์
          หากเราพบว่ามีการรวบรวมข้อมูลส่วนบุคคลของผู้เยาว์โดยไม่ได้รับความยินยอมล่วงหน้าจากผู้ปกครองที่ตรวจสอบได้
          เราจะพยายามลบข้อมูลที่เกี่ยวข้องโดยเร็วที่สุด
        </p>
        <p>
          3. หากคุณเป็นผู้ปกครองของผู้เยาว์
          โปรดติดต่อเราผ่านวิธีการที่ระบุไว้ในข้อ 11 ของนโยบายความเป็นส่วนตัวนี้
          เมื่อคุณมีคำถามเกี่ยวกับข้อมูลส่วนบุคคลของผู้เยาว์ภายใต้การปกครองของคุณ
        </p>

        <h6>9. การแก้ไขและประกาศนโยบายความเป็นส่วนตัว</h6>
        <p>
          1. เพื่อให้บริการที่ดีขึ้นแก่คุณ บริการ ODAILY
          ของเราจะเปลี่ยนแปลงเป็นครั้งคราว
          และนโยบายความเป็นส่วนตัวนี้จะถูกปรับเปลี่ยนตามไปด้วย
        </p>
        <p>
          2. หากไม่ได้รับความยินยอมอย่างชัดแจ้งจากคุณ
          เราจะไม่ลดสิทธิ์ของคุณภายใต้นโยบายความเป็นส่วนตัวนี้
          เราจะแจ้งให้คุณทราบด้วยวิธีที่สมเหตุสมผล เช่น
          การแจ้งเตือนแบบพุชของไคลเอนต์แอพ หน้าต่างป๊อปอัป เป็นต้น
          เพื่อให้คุณสามารถติดตามการเปลี่ยนแปลงใดๆ
          ที่เกิดขึ้นกับนโยบายความเป็นส่วนตัวนี้
          คุณสามารถดูเนื้อหาล่าสุดของนโยบายความเป็นส่วนตัวนี้ผ่าน
          &quot;ของฉัน&quot; → &quot;บริการเพิ่มเติม&quot; →
          &quot;นโยบายความเป็นส่วนตัว&quot;
        </p>

        <p>
          3. สำหรับการเปลี่ยนแปลงที่สำคัญ ขึ้นอยู่กับสถานการณ์เฉพาะ
          เราจะจัดทำประกาศที่ชัดเจนยิ่งขึ้นเพื่ออธิบายการเปลี่ยนแปลงเฉพาะในนโยบายความเป็นส่วนตัวนี้
          การเปลี่ยนแปลงที่สำคัญรวมถึงแต่ไม่จำกัดเพียง:
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1) มีการเปลี่ยนแปลงที่สำคัญในรูปแบบบริการของเรา เช่น
            วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล
            ประเภทของข้อมูลส่วนบุคคลที่ประมวลผล วิธีการใช้ข้อมูลส่วนบุคคล
            เป็นต้น
          </li>
          <li>
            2) เรามีการเปลี่ยนแปลงที่สำคัญในโครงสร้างความเป็นเจ้าของ
            โครงสร้างองค์กร ฯลฯ เช่น
            การเปลี่ยนแปลงเจ้าของที่เกิดจากการปรับธุรกิจ
            การควบรวมกิจการที่ล้มละลาย เป็นต้น;
          </li>
          <li>
            3) วัตถุประสงค์หลักของการเปลี่ยนแปลงการแบ่งปัน ถ่ายโอน
            หรือเปิดเผยข้อมูลส่วนบุคคลต่อสาธารณะ;
          </li>
          <li>
            4)
            มีการเปลี่ยนแปลงที่สำคัญในสิทธิ์ของคุณในการเข้าร่วมในการประมวลผลข้อมูลส่วนบุคคลและวิธีการใช้
          </li>
          <li>
            5) การเปลี่ยนแปลงในหน่วยงานที่รับผิดชอบ วิธีการติดต่อ
            และช่องทางการร้องเรียนที่รับผิดชอบในการจัดการความปลอดภัยของข้อมูลส่วนบุคคล
          </li>
          <li>
            6)
            เมื่อรายงานการประเมินผลกระทบด้านความปลอดภัยข้อมูลส่วนบุคคลระบุว่ามีความเสี่ยงสูง
          </li>
        </ul>

        <p>
          4. หากคุณไม่ยอมรับนโยบายความเป็นส่วนตัวฉบับแก้ไข
          คุณมีสิทธิ์และควรหยุดใช้บริการ ODAILY ทันที
          แต่โปรดเข้าใจว่าหากคุณไม่ยอมรับนโยบายความเป็นส่วนตัวฉบับแก้ไข
          คุณอาจไม่สามารถใช้บริการ ODAILY
          ที่เกี่ยวข้องและฟังก์ชันบางอย่างได้ตามปกติ
          หรือคุณอาจไม่สามารถบรรลุผลบริการตามที่เราคาดไว้ได้
        </p>

        <h6>10. การคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้ที่เสียชีวิต</h6>
        <p>
          ตั้งแต่วันที่ 1 พฤศจิกายน 2021
          เราจะกำหนดระบบคุ้มครองข้อมูลส่วนบุคคลสำหรับผู้เสียชีวิตตามข้อกำหนดที่เกี่ยวข้องของ
          &quot;กฎหมายคุ้มครองข้อมูลส่วนบุคคล&quot; หลังจากผู้ใช้ ODAILY
          เสียชีวิต (สำหรับบุคคลธรรมดาเท่านั้น) ญาติสนิทของเขาสามารถดู คัดลอก
          แก้ไข
          และลบข้อมูลส่วนบุคคลที่เกี่ยวข้องของผู้ใช้ที่เสียชีวิตผ่านข้อมูลการติดต่อที่ระบุไว้ในข้อ
          11 ของนโยบายความเป็นส่วนตัวนี้เพื่อกฎหมายของพวกเขาเอง
          และผลประโยชน์โดยชอบด้วยกฎหมาย และสิทธิอื่น ๆ
          ยกเว้นว่าผู้ใช้ที่เสียชีวิตมีการเตรียมการอื่น ๆ ก่อนเสียชีวิต
        </p>

        <h6>11. คุณติดต่อเราได้อย่างไร</h6>
        <p>
          หากคุณมีข้อร้องเรียน ข้อเสนอแนะ
          หรือคำถามเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
          หรือหากคุณมีคำถามเกี่ยวกับนโยบายความเป็นส่วนตัวนี้และต้องการให้เราอธิบาย
          คุณสามารถติดต่อเราได้ตามช่องทางต่อไปนี้
          และเราจะตรวจสอบปัญหาที่เกี่ยวข้องโดยเร็วที่สุด
          เป็นไปได้และยืนยันการตอบกลับของคุณภายในสิบห้าวันทำการหลังจากระบุผู้ใช้:
        </p>
        <p>
          1. ส่งอีเมลไปที่ om@odaily.email
          เพื่อติดต่อเจ้าหน้าที่ของเราที่รับผิดชอบด้านการคุ้มครองข้อมูลส่วนบุคคล
        </p>
        <p>
          2. คุณสามารถติดต่อเราผ่าน &quot;ของฉัน&quot; → &quot;คำติชม&quot; →
          &quot;คำถามอื่นๆ&quot;
        </p>

        <h6>12. การระงับข้อพิพาท</h6>
        <p>
          สำหรับข้อพิพาทใดๆ
          ที่เกิดขึ้นจากนโยบายความเป็นส่วนตัวนี้และการจัดการข้อมูลส่วนบุคคลของคุณ
          คุณสามารถฟ้องร้องต่อศาลประชาชนในเขตเฉาหยาง กรุงปักกิ่ง
          หากคุณคิดว่าพฤติกรรมการประมวลผลข้อมูลส่วนบุคคลของเราได้ทำลายสิทธิ์และผลประโยชน์โดยชอบด้วยกฎหมายของคุณ
          คุณสามารถรายงานไปยังหน่วยงานรัฐบาลที่เกี่ยวข้องได้
        </p>
      </div>
    );
  }
}

export default PrivacyZhcn;
