/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Helmet from 'react-helmet';
import styles from './index.scss';

import { getTopic } from '../../common/api/topic';

import { getHelmet } from '../../common/scripts/helmet';

import Circle from '../../components/chain/circle';
import myadvance1 from '../../assets/image/myadvance1.png';
import myadvance2 from '../../assets/image/myadvance2.png';
import myadvance3 from '../../assets/image/myadvance3.png';
import myadvance4 from '../../assets/image/myadvance4.png';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import Appshare from '../../components/common/appshare';
import { withRouter } from 'react-router-dom';
import yinziHover from '../../assets/image/yinzi_hover.png';
import yinzi from '../../assets/image/yinzi.png';
import zixun from '../../assets/image/zixun.png';
import zixunHover from '../../assets/image/zixun_hover.png';
import baodao from '../../assets/image/baodao.png';
import baodaoHover from '../../assets/image/baodao_hover.png';
import cehua from '../../assets/image/cehua.png';
import cehuaHover from '../../assets/image/cehua_hover.png';
const wecode1 = require('../../assets/image/weCode1.png');
const wecode2 = require('../../assets/image/weCode2.png');
import sanliu from '../../assets/image/chain/360.png';
import baidu from '../../assets/image/chain/baidu.png';
import bian from '../../assets/image/chain/bian.png';
import chaoji from '../../assets/image/chain/chaoji.png';
import huada from '../../assets/image/chain/huada.png';
import jianan from '../../assets/image/chain/jianan.png';
import jingdong from '../../assets/image/chain/jingdong.png';
import jinrong from '../../assets/image/chain/jinrong.png';
import jinshan from '../../assets/image/chain/jinshan.png';
import mayi from '../../assets/image/chain/mayi.png';
import qingsong from '../../assets/image/chain/qingsong.png';
import qulian from '../../assets/image/chain/qulian.png';
import rujia from '../../assets/image/chain/rujia.png';
import tengxun from '../../assets/image/chain/tengxun.png';
import wangxin from '../../assets/image/chain/wangxin.png';
import wanxiang from '../../assets/image/chain/wanxiang.png';
import weizhong from '../../assets/image/chain/weizhong.png';
import zhongan from '../../assets/image/chain/zhongan.png';

const mydata = [
  {
    title: '招商引资',
    count1: '10+',
    text1: '核心省份',
    count2: '20+',
    text2: '落地活动',
    count3: '100+',
    text3: '场景赋能',
    desc:
      '案例：参与青岛链湾、工信部区块链产业等相关项目调研；为杭州西湖区区块链产业园组织招商引资研讨会等。'
  },
  {
    title: '智库咨询',
    count1: '50+',
    text1: '机构支持',
    count2: '300+',
    text2: '行业专家',
    count3: '400+',
    text3: '项目参与',
    desc:
      '案例：承办蚂蚁金服的链创·未来创新大赛项目，负责提供和筛选有潜力与蚂蚁金服BaaS平台合作开发应用的中小企业项目库、与长达9个月的多场活动策划、宣发、落地，于云栖大会路演角逐冠军等。'
  },
  {
    title: '媒体报道',
    count1: '500+',
    text1: '报道项目',
    count2: '5000+',
    text2: '原创文章',
    count3: '3亿',
    text3: '覆盖读者',
    desc:
      '案例：深度专访报道蚂蚁金服、趣链科技、腾讯区块链等上百个区块链+应用落地企业，梳理跟进506个区块链信息服务备案项目等。'
  },
  {
    title: '市场策划',
    count1: '50+',
    text1: '影响力活动',
    count2: '7000+',
    text2: '人次参与',
    count3: '3亿',
    text3: '覆盖人群',
    desc:
      '案例：承办2019中国国际大数据产业博览会区块链高端对话与技术发展论坛，负责所有策划设计、内容宣发、嘉宾邀请、项目落地等。'
  }
];

export class Chain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sevice: mydata[0],
      myImage: {
        image1: yinziHover,
        image2: zixun,
        image3: baodao,
        image4: cehua
      },
      count: 1,
      city: [
        { name: '陕西', top: 346, left: 448 },
        { name: '山东', top: 346, left: 630 },
        { name: '四川', top: 413, left: 366 },
        { name: '江苏', top: 409, left: 662 },
        { name: '重庆', top: 435, left: 457 },
        { name: '浙江', top: 465, left: 671 },
        { name: '湖南', top: 489, left: 508 },
        { name: '江西', top: 486, left: 588 },
        { name: '贵州', top: 514, left: 457 },
        { name: '福建', top: 527, left: 629 },
        { name: '广东', top: 558, left: 571 },
        { name: '海南', top: 630, left: 522 }
      ],
      topics: [],
      ICons: [
        tengxun,
        baidu,
        bian,
        mayi,
        sanliu,
        chaoji,
        huada,
        jianan,
        jingdong,
        jinrong,
        jinshan,
        qingsong,
        qulian,
        rujia,
        wangxin,
        wanxiang,
        weizhong,
        zhongan
      ]
    };
  }
  renderCitys() {
    return this.state.city.map((item, index) => {
      return <Circle data={item} key={index} />;
    });
  }
  handleChange = value => {
    let newImage = {};
    if (value == 1) {
      newImage = {
        image1: yinziHover,
        image2: zixun,
        image3: baodao,
        image4: cehua
      };
      this.setState({
        myImage: newImage
      });
    } else if (value == 2) {
      newImage = {
        image1: yinzi,
        image2: zixunHover,
        image3: baodao,
        image4: cehua
      };
      this.setState({
        myImage: newImage
      });
    } else if (value == 3) {
      newImage = {
        image1: yinzi,
        image2: zixun,
        image3: baodaoHover,
        image4: cehua
      };
      this.setState({
        myImage: newImage
      });
    } else if (value == 4) {
      newImage = {
        image1: yinzi,
        image2: zixun,
        image3: baodao,
        image4: cehuaHover
      };
      this.setState({
        myImage: newImage
      });
    }

    this.setState({
      sevice: mydata[value - 1],
      count: value
    });
  };
  componentDidMount() {
    getTopic().then(data => {
      this.setState({
        topics: data.data.items
      });
    });
  }
  renderTopic() {
    const { topics } = this.state;
    return topics.map(item => {
      return (
        <div className={styles.caseItem} key={item.id}>
          <img
            src={item.template_info['template_cover'][0]}
            alt=""
            loading="lazy"
          />
          <p className={styles.caseTitle}>{item.title}</p>
          <div className={styles.caseBtn}>
            <p>
              <a href={`https://www.odaily.news/topic/${item.id}`}>
                进入专题 ＞{' '}
              </a>
            </p>
          </div>
        </div>
      );
    });
  }
  render() {
    const { sevice, myImage, count } = this.state;
    let helmetData = getHelmet({
      title: '区块链技术,区块链游戏,区块链新闻资讯',
      description:
        '与36氪集团达成独家战略合作，具备其他区块链媒体无法相比的大公司、 上市公司、互联网创投、开发者、学术资源积累，能够为行业输送增量流量，面向全球区块链公司，提供一站式综合服务。',
      keywords:
        '链游,元宇宙,nft,比特币,区块链,btc,eth,狗狗币,空投,web3钱包,ai,web3新闻媒体,web3资讯,区块链新闻,区块链技术'
    });
    return (
      <React.Fragment>
        <Helmet {...helmetData} />
        <Header />
        <Appshare />
        <div className={styles.container}>
          {/* 头部 */}
          <div className={styles.banner}>
            <div className={styles.bannerContent}>
              <p className={styles.title}>
                最具公信力一站式区块链资源整合服务平台{' '}
              </p>
              <a
                className={styles.linkBtn}
                href="http://e13sy937pabkzxl7.mike/rEJYt8c"
                rel="noopener noreferrer"
                target="_blank"
              >
                <p>我能「提供」区块链技术</p>
              </a>
              <a
                className={styles.linkBtn}
                href=" http://e13sy937pabkzxl7.mikecrm.com/peqesG0"
                rel="noopener noreferrer"
                target="_blank"
              >
                <p>我想「应用」区块链场景</p>
              </a>
            </div>
          </div>
          {/* 我们的服务 */}
          <div className={styles.myService}>
            <div className={styles.header}>
              <p>我们的服务</p>
            </div>
            <div className={styles.iconbox}>
              <div
                className={`${styles.iconItem} ${
                  count === 1 ? styles['item-active'] : null
                }`}
                onClick={() => {
                  this.handleChange(1);
                }}
              >
                <img
                  alt=""
                  className={styles.iconImage}
                  src={myImage.image1}
                  loading="lazy"
                />
                <p>招商引资</p>
              </div>
              <div
                className={`${styles.iconItem} ${
                  count === 2 ? styles['item-active'] : null
                }`}
                onClick={() => {
                  this.handleChange(2);
                }}
              >
                <img
                  alt=""
                  className={styles.iconImage}
                  src={myImage.image2}
                  loading="lazy"
                />
                <p>智库咨询</p>
              </div>
              <div
                className={`${styles.iconItem} ${
                  count === 3 ? styles['item-active'] : null
                }`}
                onClick={() => {
                  this.handleChange(3);
                }}
              >
                <img
                  alt=""
                  className={styles.iconImage}
                  src={myImage.image3}
                  loading="lazy"
                />
                <p>媒体报道</p>
              </div>
              <div
                className={`${styles.iconItem} ${
                  count === 4 ? styles['item-active'] : null
                }`}
                onClick={() => {
                  this.handleChange(4);
                }}
              >
                <img
                  alt=""
                  className={styles.iconImage}
                  src={myImage.image4}
                  loading="lazy"
                />
                <p>市场策划</p>
              </div>
            </div>
            <div className={styles.iconContent}>
              <h4>{sevice.title}</h4>
              <div className={styles.iconBox}>
                <div className={styles.iconItem}>
                  <p>{sevice.count1}</p>
                  <p>{sevice.text1}</p>
                </div>
                <div className={styles.line} />
                <div className={styles.iconItem}>
                  <p>{sevice.count2}</p>
                  <p>{sevice.text2}</p>
                </div>
                <div className={styles.line} />
                <div className={styles.iconItem}>
                  <p>{sevice.count3}</p>
                  <p>{sevice.text3}</p>
                </div>
              </div>
              <p className={styles.iconDesc}>{sevice.desc}</p>
            </div>
          </div>
          {/* 我们的优势 */}
          <div className={styles.myGood}>
            <div className={styles.header}>
              <p>我们的优势</p>
            </div>
            <p className={styles.desc}>
              与36氪集团（36氪传媒、氪空间、鲸准、MinIPO）达成独家战略合作，具备其他区块链媒体无法相比的大公司、
              上市公司、互联网创投、开发者、学术资源积累，能够为行业输送增量流量，面向全球区块链公司，提供一站式综合服务。
            </p>
            <div className={styles.itemBox}>
              <div className={styles.item}>
                <img alt="" src={myadvance1} loading="lazy" />
                <p>400+</p>
                <p>政产学研专家库</p>
              </div>
              <div className={styles.item}>
                <img alt="" src={myadvance2} loading="lazy" />
                <p>10+</p>
                <p>服务政府</p>
              </div>
              <div className={styles.item}>
                <img alt="" src={myadvance3} loading="lazy" />
                <p>500+</p>
                <p>优质项目库</p>
              </div>
              <div className={styles.item}>
                <img alt="" src={myadvance4} loading="lazy" />
                <p>100+</p>
                <p>落地案例</p>
              </div>
            </div>
          </div>
          {/* 地方资源 */}
          <div className={styles.myCity}>
            <h4>地方资源丰富，十二大核心省份深入合作 </h4>
            <div className={styles.cityContent}>
              <ul>
                <li>山东</li>
                <li>江苏</li>
                <li>江西</li>
                <li>浙江</li>
                <li>福建</li>
                <li>广东</li>
                <li>湖南</li>
                <li>重庆</li>
                <li>陕西</li>
                <li>四川</li>
                <li>海南</li>
                <li>贵州</li>
              </ul>
              <div className={styles.map}>{this.renderCitys()}</div>
            </div>
          </div>
          {/* 应用案例 */}
          <div className={styles.myDemo}>
            <h4>应用案例成熟，丰富的区块链+产业落地场景 </h4>
            <div className={styles.demoContent}>
              <a
                href="https://www.odaily.news/post/5137013"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demoItem}
              >
                <div className={`${styles['demoBg1']} ${styles['demoicon']}`} />
                <h5>物流链</h5>
                <p>溯源商品保证真实性，节省清关流程。</p>
              </a>
              <a
                href="https://www.odaily.news/post/5143317"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demoItem}
              >
                <div className={`${styles['demoBg2']} ${styles['demoicon']}`} />
                <h5>供应链金融</h5>
                <p>
                  解决传统供应链金融“三难”问题，提升供应链金融在B2B市场的渗透率。
                </p>
              </a>
              <a
                href="https://www.odaily.news/post/5137093"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demoItem}
              >
                <div className={`${styles['demoBg3']} ${styles['demoicon']}`} />
                <h5>版权保护 </h5>
                <p>
                  相比于传统版权登记，链上确权速度更快成本更低且无时间空间限制。
                </p>
              </a>
              <a
                href="https://www.odaily.news/post/5142996"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demoItem}
              >
                <div className={`${styles['demoBg4']} ${styles['demoicon']}`} />
                <h5>区块链+身份认证</h5>
                <p>个人征信数据等上链流通，便利生活方方面。</p>
              </a>
              <a
                href="https://www.odaily.news/post/5143311"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demoItem}
              >
                <div className={`${styles['demoBg5']} ${styles['demoicon']}`} />
                <h5>区块链+司法取证 </h5>
                <p>
                  降低电子证据存证成本、增强电子证据的真实性，从而提高诉讼效率。
                </p>
              </a>
              <a
                href="https://www.odaily.news/post/5133231"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demoItem}
              >
                <div className={`${styles['demoBg6']} ${styles['demoicon']}`} />
                <h5>区块链+税务 </h5>
                <p>
                  无纸化智能税务管理，流程可控；为传统世界中一票多报、虚报虚抵、真假难验等痛点提供解决方案。
                </p>
              </a>
              <a
                href="https://www.odaily.news/post/5137474"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demoItem}
              >
                <div className={`${styles['demoBg7']} ${styles['demoicon']}`} />
                <h5>区块链+智慧城市 </h5>
                <p>电子政务，普惠金融，智慧医疗，科创服务。</p>
              </a>
            </div>
          </div>
          {/* 合作伙伴 */}
          <div className={styles.myParner}>
            <h4>合作伙伴</h4>
            <div className={styles.parnerContent}>
              {this.state.ICons.map((item, index) => {
                return (
                  <img
                    alt=""
                    className={styles.parnerItem}
                    src={item}
                    key={index}
                    loading="lazy"
                  />
                );
              })}
            </div>
          </div>

          <div className={styles.myCase}>
            <div className={styles.header}>
              <p>精选案例</p>
            </div>
            <div className={styles.caseContent}>{this.renderTopic()}</div>
          </div>
          {/* 尾部 */}
          <div className={styles.footer}>
            <div className={styles.footContent}>
              <div className={styles.footLeft}>
                <p className={styles.text}>找政府、找企业、找场景、找曝光</p>
                <a
                  href="http://e13sy937pabkzxl7.mikecrm.com/kUWtiAI"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.register}
                >
                  <p>申请入驻</p>
                </a>
              </div>
              <div className={styles.footRight}>
                <h4>联系我们</h4>
                <p>电话/微信：13811513856</p>
                <p>电话/微信：18515575360</p>
                <img src={wecode1} alt="" loading="lazy" />
                <img src={wecode2} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withRouter(Chain);
