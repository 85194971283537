export const title = '区块链技术_元宇宙新闻_web3资讯-odaily';

export const meta = [
  {
    charset: 'UTF-8'
  },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
  },
  {
    httpEquiv: 'X-UA-Compatible',
    content: 'IE=edge'
  },
  {
    name: 'description',
    content: '区块链新闻资讯_区块链技术应用_元宇宙_web3'
  },
  {
    name: 'keywords',
    content: '区块链新闻资讯_区块链技术应用_元宇宙_web3'
  }
];

export const link = [
  {
    rel: 'shortcut icon',
    href: `${process.env.PUBLIC_URL}/favicon.ico`
  }
];

export const script = [];

export const noscript = [];
