import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styles from './index.scss';

import Carousel from '../../components/activityPage/Carousel';
import ActivityList from '../../components/activityPage/list';
import HomeLoadMore from '../../components/common/homeloadmore';
import wechatShare from '../../common/scripts/wechat';

import { getHelmet } from '../../common/scripts/helmet';

import {
  getActivity,
  getSlide,
  getActivityList
} from '../../common/api/activity';

import { dot } from '../../common/scripts/dot';
import Layout from '../../components/common/layout';

export class ActivityMore extends Component {
  static propTypes = {
    activityList: PropTypes.array.isRequired,
    slideList: PropTypes.array.isRequired,
    slideMore: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  static fetchData(store, match, res) {
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      let id = match.params.id;
      getActivity(lang)
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_LIST',
            list
          });
          return getSlide(lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_SLIDE',
            list
          });
          return getActivityList(id, 1, 20, lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_MORE',
            list
          });
          resolve();
        })
        .catch(() => {
          res.redirect(postHref);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 2,
      per_page: 20,
      slideMore: props.slideMore.data,
      loading: false,
      loaded: false,
      total: props.slideMore.total,
      id: this.props.match.params.id,
      activityList: [{ title: '全部', id: 8 }, ...props.activityList]
    };
  }

  componentDidMount() {
    wechatShare({});
  }

  changeId(id) {
    let { per_page } = this.state;

    getActivityList(id, 1, per_page).then(data => {
      if (data.code === 0) {
        this.setState({
          slideMore: data.data.items.data,
          total: data.data.items.total,
          id,
          page: 2,
          loading: false,
          loaded: false
        });
      }
    });
  }

  changeSlideMore() {
    let { page, slideMore, per_page, total, id } = this.state;
    this.setState({
      loading: true
    });

    getActivityList(id, page, per_page).then(data => {
      if (data.code === 0) {
        let num = page + 1;
        let list = data.data.items.data;

        list.map(item => {
          slideMore.push(item);
        });

        this.setState({
          slideMore,
          page: num,
          loading: false
        });

        if (slideMore.length >= total) {
          this.setState({
            loading: false,
            loaded: true
          });
        }
      }
    });
  }

  renderHeader() {
    const { activityList, id } = this.state;
    return (
      <div className={styles.activity_nav}>
        {activityList.map((item, index) => {
          return (
            <div
              key={`header${index}`}
              className={`${styles.activity_tab} ${
                item.id === Number(id) ? styles.active : null
              }`}
              onClick={() => {
                this.changeId(item.id);
                dot({ id: 30, extra: item.id });
              }}
            >
              {item.title}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let { lang } = this.props.match.params;
    let helmetData = getHelmet(
      {
        title: 'web3,元宇宙,区块链技术'
      },
      lang
    );
    const { slideList } = this.props;
    const { slideMore, loading, loaded, per_page } = this.state;
    return (
      <React.Fragment>
        <Layout>
          {/* 管理文档头，可以通过js动态的改变标签内的内容 */}
          <Helmet {...helmetData} />
          {/* 右下角固定 */}
          <div className={styles.container}>
            <div className={styles.wrapper}>
              {/* 轮播图 */}
              {slideList.length > 0 ? (
                <div className={styles.carousel}>
                  <Carousel list={slideList} width={919} height={420} />
                </div>
              ) : (
                ''
              )}

              {/* 活动nav */}
              {this.renderHeader()}

              {/* 活动列表 */}
              <div className={styles.activityPage_list}>
                <ActivityList
                  title={null}
                  href=":;javascript"
                  list={slideMore}
                />
              </div>
              <div
                className={styles.HomeLoadMore}
                style={{ display: slideMore.length < per_page ? 'none' : null }}
              >
                <HomeLoadMore
                  loading={loading}
                  loaded={loaded}
                  onClick={this.changeSlideMore.bind(this)}
                />
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    activityList: state.activity.list,
    slideList: state.activity.slide,
    slideMore: state.activity.more
  };
};

export default withRouter(connect(mapStateToProps)(ActivityMore));
