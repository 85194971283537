import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.scss';

import ReactAvatarEditor from 'react-avatar-editor';

import { upload } from '../../common/api/user';
import { modifyLibrary, newLibrary } from '../../common/api/library';
import locales from '../../utils/locales';
import { withRouter } from 'react-router-dom';

export class NewLibrary extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number,
    libraryData: PropTypes.object,
    alertFlag: PropTypes.bool.isRequired,
    changeAlertFlag: PropTypes.func.isRequired,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      nameValue: '',
      summaryValue: '',
      nameError: '',
      imgUrl: '',
      summaryError: '',
      error: '',
      loading: true,
      editorImg: '',
      editorImgFlag: false,
      scale: 1
    };
  }

  componentDidMount() {
    const { libraryData } = this.props;
    if (libraryData) {
      this.getData();
    }
  }

  getData() {
    const { libraryData } = this.props;

    this.setState({
      nameValue: libraryData.name,
      summaryValue: libraryData.summary ? libraryData.summary : '',
      imgUrl: libraryData.cover
    });
  }

  uploadButtonHandler() {
    const fileInput = this.fileInput;
    fileInput.click();
  }

  convertBase64UrlToBlob(urlData) {
    let bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte

    // 处理异常,将ascii码小于0的转换为大于0
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/png' });
  }

  fileUploadHandler() {
    upload().then(data => {
      const { policy, signature } = data.data;
      const file = this.convertBase64UrlToBlob(
        this.editorImgDom.getImageScaledToCanvas().toDataURL()
      );
      const xhr = new XMLHttpRequest();
      const formdata = new FormData();
      formdata.append('file', file);
      formdata.append('policy', policy);
      formdata.append('signature', signature);
      xhr.open('POST', 'https://v0.api.upyun.com/odaily-krplus-pic', true);
      xhr.send(formdata);
      xhr.addEventListener(
        'load',
        ({ target }) => {
          const resp = JSON.parse(target.response);
          this.setState({
            imgUrl: `https://piccdn.0daily.com/${resp.url}`
          });
          this.changeEditorImgFlag(false);
        },
        false
      );
    });
  }

  uploadImg() {
    this.changeEditorImgFlag(true);
    this.setState({ editorImg: this.fileInput.files[0] });
  }

  getName() {
    if (this.library_inp) {
      const value = this.library_inp.value;

      this.setState({ nameValue: value });

      this.judgeName(value);
    }
  }

  judgeName(value) {
    switch (true) {
      case value.length === 0:
        this.setState({ nameError: '文库名称不能为空' });
        return false;
      case value.length > 20:
        this.setState({ nameError: '文库名称最大字数为20' });
        return false;
      default:
        this.setState({ nameError: '' });
        return true;
    }
  }

  getSummary() {
    if (this.library_tex) {
      const value = this.library_tex.value;

      this.setState({ summaryValue: value });
      this.judgeSummary(value);
    }
  }

  judgeSummary(value) {
    switch (true) {
      case value.length > 170:
        this.setState({ summaryError: '文库简介最大字数为170' });
        return false;
      default:
        this.setState({ summaryError: '' });
        return true;
    }
  }

  changeError(text) {
    this.setState({ error: text });

    setTimeout(() => {
      this.setState({ error: '' });
    }, 3000);
  }

  changeEditorImgFlag(bool) {
    this.setState({ editorImgFlag: bool });
  }

  handleScale(e) {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  }

  onFinish() {
    const { summaryValue, imgUrl, nameValue, loading } = this.state;
    const { id, changeAlertFlag } = this.props;
    if (loading) {
      this.setState({
        loading: false
      });
      if (this.judgeName(nameValue) && this.judgeSummary(summaryValue)) {
        if (id) {
          modifyLibrary(id, nameValue, summaryValue, imgUrl)
            .then(() => {
              this.changeError('修改成功');
              changeAlertFlag();
              location.reload();
              this.setState({
                loading: true
              });
            })
            .catch(data => {
              this.changeError(data.message || data.msg);
              this.setState({
                loading: true
              });
            });
        } else {
          newLibrary(nameValue, summaryValue, imgUrl)
            .then(() => {
              this.changeError('创建成功');
              changeAlertFlag();
              location.reload();
              this.setState({
                loading: true
              });
            })
            .catch(data => {
              this.changeError(data.message || data.msg);
              this.setState({
                loading: true
              });
            });
        }
      }
    }
  }

  render() {
    const { alertFlag, changeAlertFlag, id, match } = this.props;
    const {
      nameError,
      imgUrl,
      summaryValue,
      summaryError,
      error,
      nameValue,
      loading,
      editorImg,
      editorImgFlag,
      scale
    } = this.state;
    const { lang } = match.params;
    return (
      <div>
        {alertFlag ? (
          <div
            onClick={() => {
              changeAlertFlag();
              this.changeEditorImgFlag(false);
            }}
            className={styles.newLibrary_alert_bg}
          />
        ) : (
          ''
        )}

        {alertFlag ? (
          <div className={styles.newLibrary_box}>
            <div
              className={styles.newLibrary_box_close}
              onClick={() => {
                changeAlertFlag();
              }}
            />

            <div className={styles.newLibrary_box_title}>
              {id
                ? locales(lang, 'edit_library')
                : locales(lang, 'new_library')}
            </div>

            <div className={styles.newLibrary_box_list}>
              <div className={styles.newLibrary_box_item}>
                <div className={styles.newLibrary_box_item_title}>
                  <div className={styles.newLibrary_box_item_title_icon}>*</div>
                  {locales(lang, 'library_name')}
                </div>

                <div
                  className={styles.newLibrary_box_inp}
                  style={{ borderColor: nameError ? '#EE4C53' : null }}
                >
                  <input
                    ref={e => {
                      this.library_inp = e;
                    }}
                    onChange={this.getName.bind(this)}
                    type="text"
                    placeholder={locales(lang, 'enter_1_20_characters')}
                    value={nameValue}
                  />
                </div>

                <div className={styles.newLibrary_box_error}>{nameError}</div>
              </div>

              <div className={styles.newLibrary_box_item}>
                <div className={styles.newLibrary_box_item_title}>
                  {`${locales(lang, 'library_cover')}（${locales(
                    lang,
                    'optional'
                  )}）`}
                </div>

                <div
                  className={styles.newLibrary_box_img_wrapper}
                  onClick={this.uploadButtonHandler.bind(this)}
                >
                  {imgUrl ? (
                    <div
                      className={styles.newLibrary_box_img}
                      style={{ backgroundImage: `url(${imgUrl})` }}
                    />
                  ) : (
                    <div className={styles.newLibrary_box_file} />
                  )}

                  <input
                    className={styles.fileInput_dom}
                    type="file"
                    accept="image/png,image/jpeg"
                    ref={e => {
                      this.fileInput = e;
                    }}
                    onChange={() => {
                      this.uploadImg();
                    }}
                  />
                </div>
              </div>

              <div className={styles.newLibrary_box_item}>
                <div className={styles.newLibrary_box_item_title}>
                  {locales(lang, 'library_Intro')}
                </div>

                <div
                  className={styles.newLibrary_box_textarea}
                  style={{ borderColor: summaryError ? '#EE4C53' : null }}
                >
                  <textarea
                    ref={e => {
                      this.library_tex = e;
                    }}
                    onChange={this.getSummary.bind(this)}
                    value={summaryValue}
                  />
                  <span className={styles.newLibrary_box_textarea_num}>
                    {summaryValue.length}/170
                  </span>
                </div>

                <div className={styles.newLibrary_box_error}>
                  {summaryError}
                </div>
              </div>
            </div>

            <div className={styles.newLibrary_box_button_wrapper}>
              <div
                className={styles.newLibrary_box_button}
                onClick={() => {
                  this.onFinish();
                }}
                style={{ background: loading ? null : '#999' }}
              >
                {loading
                  ? locales(lang, 'complete')
                  : locales(lang, 'loading_title')}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {alertFlag && editorImgFlag ? (
          <div className={styles.editorImg_box}>
            <div
              className={styles.editorImg_box_close}
              onClick={() => {
                this.changeEditorImgFlag(false);
              }}
            />
            <div className={styles.editorImg_box_title}>裁剪图片</div>
            <div className={styles.editorImg_box_list}>
              <div className={styles.editorImg_box_img}>
                <ReactAvatarEditor
                  ref={e => {
                    this.editorImgDom = e;
                  }}
                  width={438}
                  height={292}
                  border={50}
                  color={[233, 233, 233, 0.6]} // RGBA
                  scale={scale}
                  rotate={0}
                  image={editorImg}
                  crossOrigin="anonymous"
                />
              </div>

              <div className={styles.editorImg_box_scale}>
                <span className={styles.editorImg_box_scale_title}>缩放:</span>
                <input
                  name="scale"
                  type="range"
                  onChange={e => {
                    this.handleScale(e);
                  }}
                  min="1"
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
              </div>

              <div className={styles.editorImg_box_button_wrapper}>
                <div
                  className={styles.editorImg_box_button}
                  onClick={() => {
                    this.fileUploadHandler();
                  }}
                >
                  {locales(lang, 'complete')}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {error ? <div className={styles.newLibrary_error}>{error}</div> : ''}
      </div>
    );
  }
}
export default withRouter(NewLibrary);
