import Cookies from 'js-cookie';
import { combineReducers } from 'redux';
const langType = (state = Cookies.get('lang') || '', action) => {
  switch (action.type) {
    case 'SET_LANG_TYPE':
      return action.lang;
    default:
      return state;
  }
};

export default combineReducers({
  langType
});
