import { combineReducers } from 'redux';

const search = (state = false, action) => {
  switch (action.type) {
    case 'SET_SEARCH_SHOW':
      return true;
    case 'SET_SEARCH_HIDE':
      return false;
    default:
      return state;
  }
};

export default combineReducers({ search });
