import React, { Component } from 'react';
import styles from '../../index.scss';

export class AgreementTh extends Component {
  render() {
    return (
      <div className={`${styles.content} ${styles.agreement}`}>
        <h5>ข้อตกลงบริการผู้ใช้สื่อ ODAILY</h5>
        <h6>【ส่วนแรกและบทนำ】</h6>
        <p>
          ยินดีต้อนรับสู่ ODAILY! ในการใช้บริการ ODAILY (ต่อไปนี้เรียกว่า
          &quot;บริการนี้&quot;) คุณควรอ่านและปฏิบัติตาม
          &quot;ข้อตกลงบริการผู้ใช้ ODAILY Media&quot; (ต่อไปนี้จะเรียกว่า
          &quot;ข้อตกลงนี้&quot;)
          โปรดอ่านอย่างละเอียดและทำความเข้าใจเนื้อหาของแต่ละข้อ
          โดยเฉพาะข้อข้อยกเว้นหรือข้อจำกัดความรับผิด
          ตลอดจนข้อตกลงแยกต่างหากสำหรับการเปิดหรือใช้บริการบางอย่าง
          และเลือกที่จะยอมรับหรือไม่
          ข้อจำกัดและข้อจำกัดความรับผิดชอบอาจเตือนให้คุณใส่ใจในรูปแบบตัวหนา
          เว้นแต่ว่าคุณได้อ่านและยอมรับข้อกำหนดทั้งหมดของข้อตกลงนี้และข้อตกลงที่เกี่ยวข้อง
          คุณไม่มีสิทธิ์ใช้บริการ การเข้าสู่ระบบ การดู การโพสต์ข้อมูล
          และพฤติกรรมอื่น ๆ
          ในบริการนี้จะถือว่าคุณได้อ่านและตกลงที่จะผูกพันตามข้อตกลงนี้
          หากคุณอายุต่ำกว่า 18 ปี โปรดอ่านข้อตกลงนี้และข้อตกลงอื่นๆ
          ที่กล่าวถึงข้างต้นพร้อมกับผู้ปกครองตามกฎหมายของคุณ
          และให้ความสนใจเป็นพิเศษกับเงื่อนไขการใช้งานสำหรับผู้เยาว์
        </p>
        <h6>1. 【ขอบเขตข้อตกลง】</h6>
        <p>
          1.1 ข้อตกลงนี้เป็นข้อตกลงระหว่างคุณและ ODAILY เกี่ยวกับการใช้ ODAILY
          ของคุณ &quot;ODAILY&quot; หมายถึงบริษัทในเครือที่ดำเนินการได้ของ
          Hainan Moti Culture Media Co., Ltd. และบริการที่เกี่ยวข้อง
          &quot;ผู้ใช้แพลตฟอร์ม&quot; หมายถึงบุคคลหรือองค์กรที่ลงทะเบียน
          เข้าสู่ระบบ และใช้บัญชี ODAILY และจะเรียกเพิ่มเติมว่า &quot;คุณ&quot;
          ในข้อตกลงนี้ &quot;ผู้ใช้รายอื่น&quot;
          หมายถึงผู้ใช้ที่เกี่ยวข้องกับบริการ
          รวมถึงสมาชิกและผู้ใช้รายอื่นที่ไม่ใช่ผู้ใช้แพลตฟอร์มเอง
        </p>
        <p>
          1.2 บริการนี้รวมถึงแต่ไม่จำกัดเพียงเว็บไซต์อย่างเป็นทางการของ ODAILY
          www.odaily.news, ODAILYAPP, Odaily APP, ODAILY WeChat บัญชีสาธารณะ
          Odaily (WeChat ID: O-daily), บัญชีบริการ Odaily WeChat, แพลตฟอร์มเปิด
          ODAILYAPP, ODAILYAPP Weibo เป็นต้น
          แพลตฟอร์มธุรกิจเป็นช่องทางการสื่อสาร และ ODAILY
          ให้บริการเผยแพร่ข้อมูลและส่งเสริมแบรนด์สำหรับบุคคลหรือองค์กร
          หลังจากที่ผู้ใช้แพลตฟอร์มลงทะเบียนบัญชี ODAILY แล้ว
          เขาสามารถดำเนินการต่างๆ เช่น การเผยแพร่ข้อมูล การโปรโมตแบรนด์
          และการจัดการความคิดเห็นของผู้ใช้ที่สมัครสมาชิกผ่าน ODAILY
        </p>
        <h6>2.【การลงทะเบียนบัญชีและการอนุญาตบัญชี】</h6>
        <p>
          คุณต้องลงทะเบียนบัญชี ODAILY ก่อนใช้บริการนี้ และบัญชี ODAILY
          จะถูกสร้างขึ้นโดยการผูกหมายเลขโทรศัพท์มือถือและข้อมูลอื่นๆ
        </p>
        <p>
          2.2 ODAILY จะตรวจสอบข้อมูลบัญชีที่ลงทะเบียนของคุณ
          คุณจะต้องรับผิดชอบแต่เพียงผู้เดียวสำหรับความถูกต้อง ความชอบด้วยกฎหมาย
          ความถูกต้อง และความถูกต้องของข้อมูลบัญชีที่คุณให้ไว้ หากความเสียหายใด
          ๆ เกิดขึ้นกับ ODAILY หรือบุคคลที่สาม คุณจะต้องชดใช้ตามกฎหมาย
        </p>
        <p>
          2.3 ODAILY มีสิทธิ์แก้ไขข่าวที่คุณเผยแพร่ผ่าน ODAILY
          (รวมถึงแต่ไม่จำกัดเฉพาะข้อความ รูปภาพ วิดีโอ แผนภูมิ ฯลฯ) Odaily APP,
          ODAILY WeChat Official Account Odaily (WeChat ID: O-daily), Odaily
          WeChat Service บัญชี, แพลตฟอร์มเปิด ODAILYAPP, ODAILYAPP Weibo
          และแพลตฟอร์มธุรกิจอื่นๆ ของ ODAILY ถูกผลักดันสู่สาธารณะ ODAILY
          และบริษัทในเครือและพันธมิตรมีสิทธิ์ทั่วโลก ถาวร เพิกถอนไม่ได้ ฟรี
          และไม่ผูกขาดในการใช้เนื้อหาใด ๆ ที่คุณอัปโหลดและเผยแพร่ผ่าน ODAILY
        </p>
        <p>2.4 เมื่อใช้บริการนี้ คุณจะได้รับสิทธิ์ดังต่อไปนี้:</p>
        <p>
          2.4.1 จัดการข้อความและโต้ตอบกับสมาชิกผ่าน ODAILY; 2.4.2
          รับจำนวนสมาชิกและการคลิกข้อความของบัญชี ODAILY ของคุณ 2.4.3
          ด้วยความยินยอมของ ODAILY
          คุณมีสิทธิ์ที่จะดำเนินการส่งข้อความผ่านกิจกรรมเชิงพาณิชย์ของ ODAILY
          เช่น การโพสต์ข้อความสร้างแบรนด์และ/หรือการสมัครสมาชิกแบบชำระเงิน
          คุณตกลงด้วยว่า ODAILY
          มีสิทธิ์เรียกเก็บค่าบริการทางเทคนิคสำหรับกิจกรรมเชิงพาณิชย์ของคุณผ่าน
          ODAILY
        </p>
        <h6>3. [การคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้แพลตฟอร์ม]</h6>
        <p>
          3.1 ในระหว่างขั้นตอนการสมัครบริการนี้
          คุณอาจต้องให้ข้อมูลที่จำเป็นบางอย่าง โปรดรักษาข้อมูลนี้ให้เป็นความจริง
          ถูกต้อง ถูกกฎหมาย ถูกต้อง และให้ความสำคัญกับการอัปเดตอย่างทันท่วงที
          เพื่อให้ ODAILY สามารถให้ความช่วยเหลือคุณได้ทันท่วงทีและมีประสิทธิภาพ
          หรือ ให้บริการคุณได้ดียิ่งขึ้น คุณให้บริการ ตามกฎหมาย ข้อบังคับ
          และนโยบายที่เกี่ยวข้อง โปรดกรอกข้อมูลระบุตัวตนที่แท้จริงของคุณ
          หากข้อมูลที่คุณกรอกไม่ครบถ้วนหรือไม่ถูกต้อง
          คุณอาจใช้บริการนี้ไม่ได้หรือถูกจำกัดการใช้งาน
        </p>
        <p>
          3.2 ODAILY มุ่งมั่นที่จะปกป้องข้อมูลส่วนบุคคลร่วมกับผู้ใช้แพลตฟอร์ม
          และการปกป้องข้อมูลส่วนบุคคลของผู้ใช้แพลตฟอร์มเป็นหลักการพื้นฐานของ
          ODAILY โดยไม่ได้รับความยินยอมจากคุณ ODAILY
          จะไม่เปิดเผยข้อมูลส่วนบุคคลของคุณแก่บริษัท องค์กร
          หรือบุคคลอื่นนอกเหนือจาก ODAILY
          เว้นแต่กฎหมายและข้อบังคับจะกำหนดไว้เป็นอย่างอื่น
        </p>
        <p>
          3.3 คุณควรเคารพข้อมูลส่วนบุคคลของใครก็ตาม
          รวมถึงแต่ไม่จำกัดเพียงผู้ใช้รายอื่นที่คุณรู้จัก ได้รับ
          หรือเข้าถึงผ่านบริการนี้ และคุณไม่ควรรวบรวม คัดลอก จัดเก็บ เผยแพร่
          หรือใช้ข้อมูลส่วนบุคคลของผู้ใช้รายอื่น มิฉะนั้น
          ผลที่ตามมาจะตกเป็นของคุณ
        </p>
        <h6>4. [ข้อมูลจำเพาะของเนื้อหาแพลตฟอร์ม]</h6>
        <p>
          4.1 เนื้อหาของแพลตฟอร์มที่กล่าวถึงในบทความนี้หมายถึงเนื้อหาใดๆ
          ที่คุณผลิต คัดลอก เผยแพร่ และเผยแพร่ในระหว่างการใช้บริการนี้
          ซึ่งรวมถึงแต่ไม่จำกัดเพียงภาพแทนตัวของบัญชี ODAILY ชื่อ
          คำอธิบายผู้ใช้แพลตฟอร์ม และข้อมูลการลงทะเบียนอื่นๆ หรือข้อความ เสียง
          รูปภาพ การส่ง การตอบกลับหรือการตอบกลับข้อความอัตโนมัติ
          และหน้าลิงก์ที่เกี่ยวข้อง ตลอดจนเนื้อหาอื่นๆ ที่สร้างขึ้นโดยใช้บัญชี
          ODAILY หรือบริการของ ODAILY เช่น วิดีโอ รูปภาพ และข้อความ
        </p>
        <p>
          4.2 เมื่อใช้บริการนี้ คุณต้องปฏิบัติตามข้อกำหนด
          &quot;เจ็ดบรรทัดล่างสุด&quot; เช่น กฎหมายและข้อบังคับ ระบบสังคมนิยม
          ผลประโยชน์ของชาติ สิทธิและผลประโยชน์โดยชอบด้วยกฎหมายของพลเมือง
          ความสงบเรียบร้อยทางสังคม ศีลธรรม และความถูกต้องของข้อมูล
        </p>
        <p>
          คุณไม่สามารถใช้บริการนี้เพื่อผลิต คัดลอก เผยแพร่
          หรือเผยแพร่เนื้อหาต้องห้ามตามกฎหมาย ข้อบังคับ และนโยบายต่อไปนี้:
        </p>
        <p>
          (1) ต่อต้านหลักการพื้นฐานที่กำหนดโดยรัฐธรรมนูญ (2)
          เป็นอันตรายต่อความมั่นคงของชาติ, ความลับของรัฐรั่วไหล,
          ล้มล้างอำนาจรัฐ, และบ่อนทำลายเอกภาพของชาติ (3)
          ทำลายเกียรติและผลประโยชน์ของชาติ (4) ยุยงให้เกลียดชังชาติพันธุ์,
          การเลือกปฏิบัติทางชาติพันธุ์ บั่นทอนเอกภาพของชาติ (5)
          บั่นทอนนโยบายศาสนาของชาติ ส่งเสริมลัทธิ และไสยศาสตร์ศักดินา (6)
          แพร่ข่าวลือ ทำลายระเบียบสังคมและบ่อนทำลายความมั่นคงของสังคม (7)
          เผยแพร่สิ่งลามกอนาจาร ภาพอนาจาร การพนัน ความรุนแรง และการฆาตกรรม (8 )
          ดูหมิ่นหรือให้ร้ายผู้อื่น
          ละเมิดสิทธิและผลประโยชน์โดยชอบด้วยกฎหมายของผู้อื่น (9)
          ยุยงให้มีการชุมนุม รวมกลุ่ม ขบวน การเดินขบวน
          การชุมนุมเพื่อก่อกวนความสงบเรียบร้อยของสังคม {10}{' '}
          กิจกรรมในนามขององค์กรพลเรือนที่ผิดกฎหมาย (11) ข้อมูลที่มีเนื้อหาอื่น ๆ
          ที่กฎหมาย ข้อบังคับ และนโยบายห้ามไว้
        </p>
        <p>
          4.3 คุณเข้าใจและตกลงว่า ODAILY
          มุ่งมั่นที่จะให้บริการผู้ใช้แพลตฟอร์มด้วยสภาพแวดล้อมเครือข่ายที่มีอารยธรรม
          มีสุขภาพดี มีมาตรฐาน และเป็นระเบียบเรียบร้อย
          คุณไม่ได้รับอนุญาตให้ใช้บริการนี้เพื่อผลิต คัดลอก เผยแพร่
          หรือเผยแพร่ข้อมูลต่อไปนี้ที่รบกวน การทำงานปกติของ ODAILY
          หรือฝ่าฝืนกฎหมายของผู้ใช้รายอื่นหรือบุคคลที่สามเนื้อหาของผลประโยชน์:
        </p>
        <p>
          (1) มีเนื้อหาทางเพศหรือมีการชี้นำทางเพศ (2) การโฆษณา การล่วงละเมิด
          สแปม (3) เกี่ยวข้องกับความเป็นส่วนตัว ข้อมูลส่วนตัว
          หรือข้อมูลของผู้อื่น (4) การละเมิดสิทธิ์ในชื่อเสียงของผู้อื่น
          สิทธิ์รูปบุคคล สิทธิ์ในทรัพย์สินทางปัญญา เชิงพาณิชย์ (5)
          มีข้อมูลอื่นที่รบกวนการทำงานปกติของ ODAILY
          และละเมิดสิทธิ์ทางกฎหมายและผลประโยชน์ของผู้ใช้รายอื่นหรือบุคคลที่สาม
        </p>
        <h6>5. 【กฎการใช้แพลตฟอร์ม】</h6>
        <p>
          5.1 การใช้แพลตฟอร์มที่กล่าวถึงในบทความนี้หมายถึงพฤติกรรมใดๆ
          ที่คุณดำเนินการโดยใช้บริการนี้
          รวมถึงแต่ไม่จำกัดเพียงการลงทะเบียนและการเข้าสู่ระบบ
          การดำเนินการบัญชีและการส่งเสริมการขาย และพฤติกรรมอื่นๆ
          ที่ดำเนินการโดยใช้บัญชี ODAILY หรือบริการ ODAILY ของคุณ
        </p>
        <p>
          5.2 คุณต้องไม่ใช้บัญชี ODAILY หรือบริการ ODAILY
          ของคุณเพื่อดำเนินการดังต่อไปนี้:
        </p>
        <p>
          (1) การส่งหรือเผยแพร่ข้อมูลที่เป็นเท็จ หรือแอบอ้าง
          หรือใช้ชื่อของผู้อื่น (2) บังคับหรือจูงใจให้ผู้ใช้รายอื่นให้ความสนใจ
          คลิกบนเพจที่เชื่อมโยง หรือแชร์ข้อมูล (3)
          บิดเบือนข้อเท็จจริงและปกปิดความจริงเพื่อให้เข้าใจผิด
          หรือหลอกลวงผู้อื่น (4) ละเมิดสิทธิ์ทางกฎหมายอื่น ๆ เช่น
          สิทธิ์ในชื่อเสียง สิทธิ์ในบุคคล สิทธิ์ในทรัพย์สินทางปัญญา
          ความลับทางการค้า ฯลฯ (5) ใช้บัญชี ODAILY
          เพื่อโปรโมตบุคคลที่สามโดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจาก ODAILY
          (รวมถึงแต่ไม่ใช่ จำกัดเฉพาะการเพิ่มลิงก์ โฆษณา ฯลฯ ของบุคคลที่สาม) (6)
          ใช้ปลั๊กอิน ปลั๊กอิน หรือเครื่องมือและบริการอื่น ๆ
          ของบุคคลที่สามเพื่อเข้าถึงบริการนี้และระบบที่เกี่ยวข้องโดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจาก
          ODAILY (7 ) ใช้บัญชี ODAILY หรือบริการ ODAILY
          เพื่อมีส่วนร่วมในกิจกรรมที่ผิดกฎหมายและอาชญากรรม (8) สร้าง
          เผยแพร่วิธีการและเครื่องมือที่เกี่ยวข้องกับพฤติกรรมข้างต้น
          หรือดำเนินการหรือเผยแพร่วิธีการและเครื่องมือดังกล่าว
          โดยไม่คำนึงว่าพฤติกรรมเหล่านี้มีวัตถุประสงค์เพื่อการค้าหรือไม่;
          หรือการกระทำที่ไม่ได้รับอนุญาตอย่างชัดแจ้งจาก ODAILY
        </p>
        <h6>6. [การจัดการบัญชี]</h6>
        <p>
          6.1 ความเป็นเจ้าของบัญชี ODAILY เป็นของ Hainan Moti Culture Media Co.,
          Ltd.
          หลังจากที่ผู้ใช้แพลตฟอร์มทำตามขั้นตอนการลงทะเบียนแอปพลิเคชันเสร็จสิ้นแล้วพวกเขาจะได้รับสิทธิ์ในการใช้บัญชี
          ODAILY สิทธิ์ในการใช้นี้เป็นของผู้สมัครเริ่มต้นเท่านั้น ผู้ลงทะเบียน
          การให้ การยืม การเช่า และการโอนเป็นสิ่งต้องห้ามหรือขาย Hainan Moti
          Culture Media Co., Ltd.
          มีสิทธิ์ที่จะยุติบริการนี้เนื่องจากความต้องการทางธุรกิจ
        </p>
        <p>
          6.2
          คุณมีหน้าที่รับผิดชอบในการรักษาข้อมูลบัญชีที่ลงทะเบียนและรหัสผ่านของบัญชีให้ปลอดภัย
          และคุณต้องรับผิดชอบทางกฎหมายสำหรับพฤติกรรมภายใต้บัญชีและรหัสผ่านที่ลงทะเบียน
          คุณตกลงที่จะไม่เปิดเผยข้อมูลบัญชีหรือรหัสผ่านแก่ผู้อื่นไม่ว่าในกรณีใดๆ
          เมื่อคุณสงสัยว่ามีผู้อื่นใช้บัญชีหรือรหัสผ่านของคุณ คุณตกลงที่จะแจ้ง
          Hainan Moti Culture Media Co., Ltd. ทันที
        </p>
        <p>
          6.3
          คุณควรปฏิบัติตามข้อกำหนดของข้อตกลงนี้และใช้บริการอย่างถูกต้องและเหมาะสม
          หากคุณละเมิดข้อกำหนดใด ๆ ของข้อตกลงนี้ Hainan Moti Culture Media Co.,
          Ltd. มีสิทธิ์ที่จะยุติการให้บริการแก่บัญชี ODAILY ของคุณใน
          ตามข้อตกลงนี้ ให้บริการ ในขณะเดียวกัน ODAILY ขอสงวนสิทธิ์ในการถอนบัญชี
          ODAILY และชื่อผู้ใช้แพลตฟอร์มเมื่อใดก็ได้
        </p>
        <h6>7. 【การจัดเก็บข้อมูล】</h6>
        <p>
          7.1 ODAILY
          จะไม่รับผิดชอบต่อความล้มเหลวในการลบหรือจัดเก็บข้อมูลที่เกี่ยวข้องของคุณในบริการนี้
        </p>
        <p>
          7.2 ODAILY
          มีสิทธิ์กำหนดระยะเวลาจัดเก็บข้อมูลสูงสุดสำหรับข้อมูลของผู้ใช้แพลตฟอร์มเดียวในบริการนี้ตามสถานการณ์จริง
          และจัดสรรพื้นที่จัดเก็บข้อมูลสูงสุดสำหรับข้อมูลบนเซิร์ฟเวอร์
          คุณสามารถสำรองข้อมูลที่เกี่ยวข้องในบริการนี้ได้ตามความต้องการของคุณเอง
        </p>
        <p>
          7.3 หากคุณหยุดใช้บริการหรือบริการถูกยุติหรือยกเลิก ODAILY
          สามารถลบข้อมูลของคุณออกจากเซิร์ฟเวอร์อย่างถาวร หลังจากบริการหยุด ยุติ
          หรือยกเลิก ODAILY ไม่จำเป็นต้องส่งคืนข้อมูลใด ๆ ให้กับคุณ
        </p>
        <h6>8. 【ความเสี่ยงและการปฏิเสธความรับผิดชอบ】</h6>
        <p>
          8.1
          คุณเข้าใจและตกลงว่าบริการนี้เป็นเพียงแพลตฟอร์มสำหรับผู้ใช้แพลตฟอร์มในการแบ่งปัน
          เผยแพร่ และรับข้อมูล
          และคุณต้องรับผิดชอบต่อการกระทำทั้งหมดภายใต้บัญชีที่ลงทะเบียนของคุณ
          รวมถึงความถูกต้อง ความชอบด้วยกฎหมาย ความถูกต้อง
          และความถูกต้องของเนื้อหาที่คุณ เผยแพร่ เพศ
          และรับผลที่เกิดจากการใช้บริการนี้ ผู้ใช้แพลตฟอร์มควรตัดสินเนื้อหาใน
          ODAILY ด้วยตัวเอง
          และแบกรับความเสี่ยงทั้งหมดที่เกิดขึ้นจากการใช้เนื้อหา
          รวมถึงความเสี่ยงที่เกิดจากการพึ่งพาความถูกต้อง ความชอบด้วยกฎหมาย
          ความแม่นยำ และความถูกต้องของเนื้อหา ODAILY
          ไม่สามารถและจะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายที่เกิดจากการกระทำของผู้ใช้แพลตฟอร์ม
        </p>
        <p>
          หากคุณพบว่ามีผู้ใดละเมิดข้อกำหนดของข้อตกลงนี้หรือใช้บริการของ ODAILY
          ในทางที่ไม่เหมาะสม โปรดรายงานหรือร้องเรียนกับ ODAILY ทันที
          และเราจะดำเนินการตามกฎหมาย
        </p>
        <p>
          8.2 คุณเข้าใจและยอมรับว่าเนื่องจากความต้องการในการพัฒนาธุรกิจ ODAILY
          ขอสงวนสิทธิ์ในการเปลี่ยนแปลง ระงับ จำกัด
          ยุติหรือยกเลิกเนื้อหาบริการทั้งหมดหรือบางส่วนของบริการนี้แต่เพียงฝ่ายเดียวได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          และผู้ใช้แพลตฟอร์มจะต้องแบกรับ ความเสี่ยงความรับผิดชอบ
        </p>
        <p>
          8.3 บทความ จดหมายข่าว ใบเสนอราคา รายงาน ฯลฯ ที่เผยแพร่โดย ODAILY
          ไม่ถือเป็นคำแนะนำการลงทุนสำหรับคุณ
          คุณตระหนักดีและเข้าใจถึงความเสี่ยงในการลงทุนของบล็อกเชนและสกุลเงินดิจิทัล
          และเรื่องการลงทุนทั้งหมดขึ้นอยู่กับความเข้าใจของคุณเอง
        </p>
        <h6>9. 【คำชี้แจงทรัพย์สินทางปัญญา】</h6>
        <p>
          9.1 สิทธิ์ในทรัพย์สินทางปัญญาของเนื้อหาที่ ODAILY จัดหาให้ในบริการนี้
          (รวมถึงแต่ไม่จำกัดเฉพาะหน้าเว็บ ข้อความ รูปภาพ เสียง วิดีโอ แผนภูมิ
          ฯลฯ) เป็นของ ODAILY และสิทธิ์ในทรัพย์สินทางปัญญาของเนื้อหาที่สร้างโดย
          ผู้ใช้แพลตฟอร์มในการใช้บริการนี้เป็นของแพลตฟอร์ม
          สงวนลิขสิทธิ์โดยผู้ใช้หรือผู้ถือสิทธิ์ที่เกี่ยวข้อง
        </p>
        <p>
          9.2 เว้นแต่จะระบุไว้เป็นอย่างอื่นโดยเฉพาะ ลิขสิทธิ์ สิทธิ์ในสิทธิบัตร
          และสิทธิ์ในทรัพย์สินทางปัญญาอื่นๆ ของซอฟต์แวร์ที่ ODAILY
          ให้บริการนี้เป็นของ ODAILY
        </p>
        <p>
          9.3 &quot;ODAILY&quot; และโลโก้เชิงพาณิชย์อื่นๆ ที่ ODAILY
          ใช้ในบริการนี้ ลิขสิทธิ์หรือสิทธิ์ในเครื่องหมายการค้าเป็นของ Hainan
          Moti Culture Media Co., Ltd.
        </p>
        <p>
          9.4 สิทธิ์ในทรัพย์สินทางปัญญาของเนื้อหาข้างต้นและเนื้อหาอื่น ๆ
          ที่มีอยู่ในบริการนี้ได้รับการคุ้มครองตามกฎหมาย
          หากไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจาก ODAILY
          ผู้ใช้แพลตฟอร์มหรือผู้มีภาระหน้าที่ที่เกี่ยวข้อง
          จะไม่มีใครใช้หรือสร้างงานดัดแปลงที่เกี่ยวข้องในรูปแบบใด ๆ
        </p>
        <h6>X. 【ความรับผิดชอบทางกฎหมาย】</h6>
        <p>
          10.1 หาก ODAILY
          พบหรือได้รับรายงานหรือข้อร้องเรียนจากผู้อื่นว่าผู้ใช้แพลตฟอร์มละเมิดข้อตกลงนี้
          ODAILY
          มีสิทธิ์ที่จะลบเนื้อหาที่เกี่ยวข้องได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          และขึ้นอยู่กับสถานการณ์ของพฤติกรรม ลงโทษบัญชีที่ละเมิด
          รวมถึงแต่ไม่จำกัดเพียง เพื่อเตือน,
          ลบบทลงโทษบางส่วนหรือทั้งหมดสำหรับการสมัครสมาชิกผู้ใช้,
          จำกัดหรือห้ามการใช้ฟังก์ชั่นทั้งหมดหรือบางส่วน,
          การแบนบัญชีหรือการยกเลิก, และการประกาศผล
        </p>
        <p>
          10.2 คุณเข้าใจและยอมรับว่า ODAILY
          มีสิทธิ์ที่จะลงโทษการละเมิดกฎหมายและข้อบังคับที่เกี่ยวข้องหรือข้อกำหนดของข้อตกลงนี้ตามดุลยพินิจที่สมเหตุสมผล
          ดำเนินการทางกฎหมายที่เหมาะสมกับใครก็ตามที่ละเมิดกฎหมายและข้อบังคับ
          และจัดเก็บข้อมูลที่เกี่ยวข้องเพื่อรายงานต่อหน่วยงานที่เกี่ยวข้อง
          ตามกฎหมายและข้อบังคับ ฯลฯ
          ผู้ใช้แพลตฟอร์มจะต้องรับผิดชอบทางกฎหมายทั้งหมดที่เกิดขึ้นจากนั้น
        </p>
        <p>
          10.3 คุณเข้าใจและตกลงว่าคุณจะชดใช้ค่าเสียหายให้กับ ODAILY
          และบริษัทในเครือและบริษัทในเครือสำหรับการเรียกร้อง ความต้องการ
          หรือการสูญเสียใด ๆ ที่เรียกร้องโดยบุคคลที่สาม
          รวมถึงค่าทนายความที่สมเหตุสมผล
          ซึ่งเกิดจากหรือเกิดจากการที่คุณละเมิดข้อกำหนดของข้อตกลงนี้หรือข้อกำหนดที่เกี่ยวข้อง
          ของการบริการ บริษัท และปกป้องจากอันตราย
        </p>
        <h6>11. [ความมีผลบังคับใช้และการเปลี่ยนแปลงของข้อตกลง]</h6>
        <p>
          11.1 การใช้บริการของคุณถือว่าได้อ่านและตกลงที่จะผูกพันตามข้อตกลงนี้
        </p>
        <p>
          11.2 ODAILY มีสิทธิ์ในการแก้ไขข้อกำหนดของข้อตกลงนี้เมื่อจำเป็น
          คุณสามารถตรวจสอบเงื่อนไขข้อตกลงฉบับล่าสุดได้ที่หน้าบริการที่เกี่ยวข้อง
        </p>
        <p>
          11.3 หลังจากข้อกำหนดของข้อตกลงนี้มีการเปลี่ยนแปลง หากคุณยังคงใช้บริการ
          ODAILY ต่อไป จะถือว่าคุณได้ยอมรับข้อตกลงที่แก้ไขแล้ว
          หากคุณไม่ยอมรับข้อตกลงฉบับแก้ไข คุณควรหยุดใช้บริการนี้
        </p>
        <h6>12. [อื่นๆ]</h6>
        <p>
          12.1 สถานที่ลงนามในข้อตกลงนี้คือเขตเฉาหยาง กรุงปักกิ่ง
          สาธารณรัฐประชาชนจีน
        </p>
        <p>
          12.2 การจัดตั้ง การมีผลใช้บังคับ การปฏิบัติ การตีความ
          และการระงับข้อพิพาทของข้อตกลงนี้จะอยู่ภายใต้กฎหมายของแผ่นดินใหญ่ของสาธารณรัฐประชาชนจีน
          (ไม่รวมการขัดกันของกฎหมาย)
        </p>
        <p>
          12.3 หากมีข้อพิพาทหรือการโต้เถียงใดๆ ระหว่างคุณและ ODAILY
          ขั้นแรกควรได้รับการแก้ไขผ่านการเจรจาฉันมิตร หากการเจรจาล้มเหลว
          คุณตกลงที่จะส่งข้อพิพาทหรือข้อพิพาทไปยังศาลประชาชนที่มีอำนาจ ณ
          สถานที่ที่ลงนามในข้อตกลงนี้
        </p>
        <p>
          12.4
          หัวเรื่องของทุกข้อในข้อตกลงนี้มีไว้เพื่อความสะดวกในการอ่านเท่านั้น
          และไม่มีความหมายที่แท้จริงในตัวเอง
          และไม่สามารถใช้เป็นฐานในการตีความความหมายของข้อตกลงนี้ได้
        </p>
        <p>
          12.5
          ไม่ว่าส่วนใดของข้อกำหนดของข้อตกลงนี้จะไม่ถูกต้องหรือไม่สามารถบังคับใช้ได้ไม่ว่าด้วยเหตุผลใดก็ตาม
          ข้อกำหนดที่เหลือยังคงมีผลบังคับใช้และมีผลผูกพันทั้งสองฝ่าย
        </p>
        <p>12.6 ข้อตกลงนี้จะมีผลใช้บังคับในวันที่ 1 พฤษภาคม 2018</p>
      </div>
    );
  }
}

export default AgreementTh;
