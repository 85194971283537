// eslint-disable-next-line react/display-name
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Helmet from 'react-helmet';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import { PostItem } from '../../components/columnsPost';
import { NewsItem } from '../../components/newsflash';
import LoadMore from '../../components/common/homeloadmore';
import AdPicture from '../../components/adPicture';
import { HotPostItem } from '../../components/recommendPost/item';
import { RePost } from '../../components/recommendPost';

import {
  getSecondaryTag,
  getSecondaryList,
  getSecondaryClassicList,
  getSecondaryNewsflasheList
} from '../../common/api/columns';
import { getNewsflash } from '../../common/api/newsflash';
import { getAdvert } from '../../common/api/slide';
import { getHelmet } from '../../common/scripts/helmet';
import { getTopPost } from '../../common/api/found';
import wechatShare from '../../common/scripts/wechat';
import locales from '../../utils/locales';

const navList = [
  {
    title: 'article',
    type: 'post',
    children: [
      { title: 'featured_articles', type: 'classic' },
      { title: 'all_articles', type: '' }
    ]
  },
  {
    title: 'flash',
    type: 'news'
  }
];

export class SecondTag extends React.Component {
  static propTypes = {
    columnPosts: PropTypes.array.isRequired,
    columnClassic: PropTypes.array.isRequired,
    columnInfo: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    columnsPages: PropTypes.object.isRequired,
    pagesClassic: PropTypes.object.isRequired,
    newsList: PropTypes.array.isRequired,
    advertBottom: PropTypes.array,
    secondaryNewsflashe: PropTypes.array.isRequired,
    newsflashePage: PropTypes.object.isRequired,
    topPost: PropTypes.array.isRequired
  };

  static fetchData(store, match, res) {
    const columnId = match.params.id;
    let secondary_tag_id = null;
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      getSecondaryTag(columnId, lang)
        .then(data => {
          const columnData = data.data;
          store.dispatch({
            type: 'SET_COLUMN_INFO',
            list: columnData
          });
          secondary_tag_id = columnData.id;
          return getNewsflash({}, lang);
        })
        .then(data => {
          const list = data.data.items.slice(0, 5);
          store.dispatch({
            type: 'SET_NEWS_LIST',
            list: list
          });
          return getSecondaryList(columnId, undefined, undefined, lang);
        })
        .then(data => {
          const number = Math.ceil(
            data.data.total_count / Number(data.data.page_size)
          );
          const pages = {
            total_count: data.data.total_count,
            page: data.data.page,
            page_size: data.data.page_size,
            number
          };
          store.dispatch({
            type: 'SET_COLUMN_PAGE',
            list: pages
          });
          store.dispatch({
            type: 'SET_COLUMN_POSTS',
            list: data.data.items || []
          });
          return getSecondaryClassicList(secondary_tag_id, 1, 10, lang);
        })
        .then(data => {
          const number = Math.ceil(
            data.data.total_count / Number(data.data.page_size)
          );
          const pages = {
            total_count: data.data.total_count,
            page: data.data.page,
            page_size: data.data.page_size,
            number
          };
          store.dispatch({
            type: 'SET_CLASSIC_POSTS',
            list: data.data.items || []
          });
          store.dispatch({
            type: 'SET_CLASSIC_PAGE',
            list: pages
          });
          return getSecondaryNewsflasheList(secondary_tag_id, 1, 10, lang);
        })
        .then(data => {
          const number = Math.ceil(
            data.data.total / Number(data.data.per_page)
          );
          const pages = {
            total_count: data.data.total,
            page: data.data.current_page,
            page_size: data.data.per_page,
            number
          };
          store.dispatch({
            type: 'SET_NEWSFLASHE_PAGE',
            list: pages
          });
          const list = data.data.data;
          store.dispatch({
            type: 'SET_SECONDARY_NEWSFLASHE',
            list: list
          });
          return getTopPost();
        })
        .then(data => {
          const postList = data.data.items;
          store.dispatch({
            type: 'SET_TOPPOST',
            list: postList
          });
          return getAdvert('home_advertisement_bottom', lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ADVERT_BOTTOM',
            list: list
          });
          resolve();
        })
        .catch(() => {
          res.redirect(postHref);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      navFlag:
        props.columnClassic && props.columnClassic.length ? 'classic' : '',
      secondTagNavFlag: 'post',
      screenHeight: '100%'
    };
  }

  componentDidMount() {
    const props = this.props;
    if (props.columnPosts.length < 10) {
      this.setState({
        loaded: true
      });
    }
    this.setState({
      screenHeight: window.innerHeight - 70 - 281 + 'px'
    });

    const { name } = this.props.columnInfo;
    let title = `${name}_${name}文章_${name}技术`;
    wechatShare({ title });
  }

  loadMore() {
    const { dispatch, match, columnPosts, columnsPages } = this.props;
    const total = columnsPages.number;
    let page = 1;
    page = columnsPages.page;
    const columnsId = match.params.id;
    this.setState({
      loading: true
    });
    if (page < total) {
      page++;
      getSecondaryList(columnsId, page)
        .then(data => {
          if (!data.data.items) {
            this.setState({
              loaded: true
            });
            return false;
          }
          if (data.data.items.length < 10) {
            this.setState({
              loaded: true,
              loading: false
            });
          }
          const pages = Object.assign(columnsPages, { page });
          const list = _.concat(columnPosts, data.data.items);
          dispatch({
            type: 'SET_COLUMN_POSTS',
            list
          });
          dispatch({
            type: 'SET_COLUMN_PAGE',
            list: pages
          });
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loaded: true,
        loading: false
      });
    }
  }

  loadMoreClassic() {
    const { dispatch, columnClassic, pagesClassic, columnInfo } = this.props;
    const total = pagesClassic.number;
    let page = 1;
    page = pagesClassic.page;
    const columnsId = columnInfo.id;
    this.setState({
      loading: true
    });
    if (page < total) {
      page++;
      getSecondaryClassicList(columnsId, page)
        .then(data => {
          if (!data.data.items) {
            this.setState({
              loaded: true
            });
            return false;
          }
          if (data.data.items.length < 10) {
            this.setState({
              loaded: true,
              loading: false
            });
          }
          const pages = Object.assign(pagesClassic, { page });
          const list = _.concat(columnClassic, data.data.items);
          dispatch({
            type: 'SET_CLASSIC_POSTS',
            list
          });
          dispatch({
            type: 'SET_CLASSIC_PAGE',
            list: pages
          });
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loaded: true,
        loading: false
      });
    }
  }

  loadNews() {
    const {
      dispatch,
      secondaryNewsflashe,
      newsflashePage,
      columnInfo
    } = this.props;
    const total = newsflashePage.number;
    let page = 1;
    page = newsflashePage.page;
    const columnsId = columnInfo.id;
    this.setState({
      loading: true
    });
    if (page < total) {
      page++;
      getSecondaryNewsflasheList(columnsId, page)
        .then(data => {
          if (!data.data.data) {
            this.setState({
              loaded: true
            });
            return false;
          }
          if (data.data.data.length < 10) {
            this.setState({
              loaded: true,
              loading: false
            });
          }
          const pages = Object.assign(newsflashePage, { page });
          const list = _.concat(secondaryNewsflashe, data.data.data);
          dispatch({
            type: 'SET_NEWSFLASHE_PAGE',
            list: pages
          });

          dispatch({
            type: 'SET_SECONDARY_NEWSFLASHE',
            list: list
          });
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loaded: true,
        loading: false
      });
    }
  }

  changeSecondTagNavFlag(type) {
    this.setState({
      secondTagNavFlag: type,
      loading: false,
      loaded: false
    });
  }

  changeNavFlag(type) {
    this.setState({
      navFlag: type,
      loading: false,
      loaded: false
    });
  }

  getHotPosts(value) {
    this.setState(
      {
        postType: value.value
      },
      () => {
        this.getTopPost();
      }
    );
  }

  getTopPost() {
    const { postType } = this.state;
    getTopPost(postType)
      .then(data => {
        this.props.dispatch({
          type: 'SET_TOPPOST',
          list: data.data.items
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  rendersSecondTagNav() {
    const { columnsPages, newsflashePage, pagesClassic, match } = this.props;
    const { secondTagNavFlag, navFlag } = this.state;
    const { lang } = match.params;
    let page =
      secondTagNavFlag === 'news'
        ? newsflashePage
        : navFlag
        ? pagesClassic
        : columnsPages;
    return (
      <div className={styles.secondTag_nav}>
        <div className={styles.secondTag_nav_wrapper}>
          <div className={styles.navList}>
            {navList.map((item, index) => {
              return (
                <div
                  className={`${styles.secondTag_nav_item} ${
                    secondTagNavFlag === item.type
                      ? styles.secondTag_nav_item_active
                      : ''
                  }`}
                  key={`secondTag_nav_item${index}`}
                  onClick={() => {
                    this.changeSecondTagNavFlag(item.type);
                  }}
                  style={{
                    display:
                      newsflashePage.total_count === 0 && index === 1
                        ? 'none'
                        : null
                  }}
                >
                  {locales(lang, item.title)}
                </div>
              );
            })}
          </div>

          <div className={styles.post_num}>
            {secondTagNavFlag === 'news'
              ? `共${page.total_count}篇快讯`
              : navFlag
              ? `共${page.total_count}篇精选`
              : `共${page.total_count}篇文章`}
          </div>
        </div>

        {secondTagNavFlag === 'post' && pagesClassic.total_count > 0 ? (
          <div className={styles.nav_children}>
            {navList[0].children.map((item, index) => {
              return (
                <div
                  className={`${styles.nav_children_item} ${
                    navFlag === item.type ? styles.nav_children_item_active : ''
                  }`}
                  key={`nav_children_item${index}`}
                  onClick={() => {
                    this.changeNavFlag(item.type);
                  }}
                >
                  {locales(lang, item.title)}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  renderPosts() {
    const { columnPosts, columnClassic } = this.props;
    const { navFlag } = this.state;

    if (navFlag) {
      if (!columnClassic) {
        return null;
      }

      return columnClassic.map((post, index) => {
        const props = {
          ...post,
          type: 'normal'
        };
        return (
          <React.Fragment key={index}>
            <PostItem {...props} />
          </React.Fragment>
        );
      });
    } else {
      if (!columnPosts) {
        return null;
      }

      return columnPosts.map((post, index) => {
        const props = {
          ...post,
          type: 'normal'
        };
        return (
          <React.Fragment key={index}>
            <PostItem {...props} />
          </React.Fragment>
        );
      });
    }
  }

  renderNews() {
    const { secondaryNewsflashe } = this.props;

    if (!secondaryNewsflashe) {
      return null;
    }

    return secondaryNewsflashe.map((post, index) => {
      const props = {
        ...post,
        type: 'normal'
      };
      return (
        <React.Fragment key={index}>
          <NewsItem key={post.id} {...props} />
        </React.Fragment>
      );
    });
  }

  renderPostHot() {
    const postList = this.props.topPost;

    if (postList.length > 0) {
      return postList
        .filter((item, index) => {
          return index < 5;
        })
        .map((item, index) => {
          const props = Object.assign(item, {
            type: 'small',
            sort: index + 1
          });
          return <HotPostItem {...props} key={index} />;
        });
    }
  }

  render() {
    const state = this.state;
    const { navFlag, screenHeight, secondTagNavFlag } = this.state;
    const {
      columnsPages,
      pagesClassic,
      advertBottom,
      newsflashePage
    } = this.props;
    const { name, introduction } = this.props.columnInfo;
    let pages =
      secondTagNavFlag === 'news'
        ? newsflashePage
        : navFlag
        ? pagesClassic
        : columnsPages;

    let helmetData = getHelmet({
      title: `${name}_${name}文章_${name}技术`,
      description: `ODAILY${name}文章资讯，这里汇聚了${name}相关信息、${name}区块链技术。ODAILY为对区块链感兴趣客户提供深度报告。`,
      keywords: `${name},${name}区块链游戏,${name}区块链资讯,${name}文章,ODAILY`
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container} style={{ minHeight: screenHeight }}>
          <div className={styles.content}>
            <div className={styles.content_left}>
              {/* 头部 */}
              <div className={styles.secondTag_header}>
                <div className={styles.title}>
                  标签：<span>{name}</span>
                </div>

                {introduction ? (
                  <div className={styles.secondTag_introduction}>
                    {introduction}
                  </div>
                ) : (
                  ''
                )}
              </div>

              {this.rendersSecondTagNav()}

              {/* 内容 */}
              <div className={styles.PostList}>
                {secondTagNavFlag === 'post'
                  ? this.renderPosts()
                  : this.renderNews()}
              </div>

              {/* 加载更多 */}
              {pages.page < pages.number ? (
                <div className={styles.LoadMore}>
                  <LoadMore
                    loading={state.loading}
                    loaded={state.loaded}
                    onClick={
                      secondTagNavFlag === 'news'
                        ? this.loadNews.bind(this)
                        : navFlag
                        ? this.loadMoreClassic.bind(this)
                        : this.loadMore.bind(this)
                    }
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={styles.content_right}>
              <div className={styles.RePost}>
                <RePost
                  getHotPosts={this.getHotPosts.bind(this)}
                  title="文章热榜"
                >
                  {this.renderPostHot()}
                </RePost>
              </div>

              {/* 首页边栏-底部 */}
              {advertBottom &&
              advertBottom.length > 0 &&
              advertBottom[0].url &&
              advertBottom[0].cover ? (
                <div className={styles.advertMiddle}>
                  <AdPicture
                    width="340px"
                    height="226px"
                    url={advertBottom[0].url}
                    img={advertBottom[0].cover}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    columnInfo: state.secondTag.columnInfo,
    columnPosts: state.secondTag.posts,
    columnClassic: state.secondTag.classic,
    columnsPages: state.secondTag.pages,
    pagesClassic: state.secondTag.pages_classic,
    newsList: state.home.newsList,
    advertBottom: state.home.advertBottom,
    secondaryNewsflashe: state.secondTag.secondaryNewsflashe,
    newsflashePage: state.secondTag.newsflashePage,
    topPost: state.home.topPost
  };
};

export default withRouter(connect(mapStateToProps)(SecondTag));
