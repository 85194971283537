import React, { Component } from 'react';
import styles from '../../index.scss';

export class ApplicationEn extends Component {
  render() {
    return (
      <div className={styles.content}>
        <h5>Application Permission Application and Usage Instructions</h5>
        <p>
          1. In order to ensure the function realization and safe and stable
          operation of ODAILYApp products, we may apply for or use the relevant
          permissions of the operating system;
        </p>
        <p>
          2. In order to protect your right to know, we will display the
          relevant operating system permissions that the product may apply for
          and use through the following list, and you can manage the relevant
          permissions according to your actual needs;
        </p>
        <p>
          3. According to product upgrades, the types and purposes of
          application and use permissions may change, and we will promptly
          adjust the list according to these changes to ensure that you are
          informed of the application and use of permissions in a timely manner;
        </p>
        <p>
          4. Please be aware that for the function and security needs of our
          business and products, we may also use third-party SDKs, and these
          third parties may also apply for or use relevant operating system
          permissions. For details, please refer to the fifth part of
          &quot;ODAILY Privacy Policy&quot; The &quot;Information Sharing
          List&quot; provided by the article;
        </p>
        <p>
          5. In the process of using the product, you may use H5 pages or
          applets developed by third parties, and these plug-ins or applets
          developed by third parties may also apply for or use relevant
          operating system permissions as necessary for business functions;
        </p>
        <p>
          6. The following applies to ODAILY App, and if there is a separately
          applicable scenario or purpose, it will be described in the following
          list.
        </p>

        <h6>Andriod application permission application list</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>permission name</h6>
              </td>
              <td>
                <h6>Permission Description</h6>
              </td>
              <td>
                <h6>Use scenario or purpose</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>read external memory</p>
              </td>
              <td>
                <p>Used for users to upload pictures, files, etc.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>write to external memory</p>
              </td>
              <td>
                <p>
                  Used for users to save pictures and record crash log
                  information locally (if any)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MOUNT_UNMOUNT_FILESYSTEMS</p>
              </td>
              <td>
                <p>Mount/unmount external filesystems</p>
              </td>
              <td>
                <p>Used for users to upload pictures, files, etc.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_PHONE_STATE</p>
              </td>
              <td>
                <p>read phone status</p>
              </td>
              <td>
                <p>
                  It is used to access mobile phone device identification and
                  other information. This permission cannot monitor or obtain
                  any of your call content and information
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAMERA</p>
              </td>
              <td>
                <p>use camera</p>
              </td>
              <td>
                <p>
                  It is used for users to take photos when changing their
                  avatars
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_ALERT_WINDOW</p>
              </td>
              <td>
                <p>System floating window</p>
              </td>
              <td>
                <p>
                  It is used to show the user to look at the floating window
                  later when the user exits from browsing the article
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>SYSTEM_OVERLAY_WINDOW</p>
              </td>
              <td>
                <p>system top level window</p>
              </td>
              <td>
                <p>
                  Used for users to receive push notifications in a timely
                  manner when using the app
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>INTERNET</p>
              </td>
              <td>
                <p>access network</p>
              </td>
              <td>
                <p>For device networking</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_NETWORK_STATE</p>
              </td>
              <td>
                <p>Get network status information</p>
              </td>
              <td>
                <p>
                  It is used to play or pause video according to network status
                  (cellular, wifi) to save user traffic consumption
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_WIFI_STATE</p>
              </td>
              <td>
                <p>Get current Wifi status information</p>
              </td>
              <td>
                <p>
                  It is used to play or pause video according to network status
                  (cellular, wifi) to save user traffic consumption
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_WIFI_STATE</p>
              </td>
              <td>
                <p>Change WiFi status</p>
              </td>
              <td>
                <p>
                  Used to assist GPS to determine the location, this permission
                  cannot obtain your wifi account and password
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_NETWORK_STATE</p>
              </td>
              <td>
                <p>Allows a program to change the state of the network</p>
              </td>
              <td>
                <p>
                  Used to provide users with better services when the network is
                  unstable
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_LOGS</p>
              </td>
              <td>
                <p>read syslog</p>
              </td>
              <td>
                <p>Used to collect application error log information</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WRITE_SETTINGS</p>
              </td>
              <td>
                <p>write system settings</p>
              </td>
              <td>
                <p>
                  Used to synchronize system notifications, brightness and other
                  settings
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>READ_SETTINGS</p>
              </td>
              <td>
                <p>read system settings</p>
              </td>
              <td>
                <p>
                  Used for users to change system notifications, brightness and
                  other settings
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MODIFY_AUDIO_SETTINGS</p>
              </td>
              <td>
                <p>modify sound settings</p>
              </td>
              <td>
                <p>Used to adjust the volume while watching a video</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WAKE_LOCK</p>
              </td>
              <td>
                <p>Allow keep running on lock screen</p>
              </td>
              <td>
                <p>
                  When used to watch videos, the screen does not automatically
                  lock
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECEIVE_BOOT_COMPLETED</p>
              </td>
              <td>
                <p>Allow auto-start</p>
              </td>
              <td>
                <p>
                  Used to improve the arrival rate of message push, this
                  permission cannot be automatically activated under any
                  conditions
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VIBRATE</p>
              </td>
              <td>
                <p>Allow the program to use vibration</p>
              </td>
              <td>
                <p>
                  Use vibration to remind users when receiving important pushes
                  or partial interactions
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FOREGROUND_SERVICE</p>
              </td>
              <td>
                <p>Allow use of foreground services</p>
              </td>
              <td>
                <p>
                  When used to play audio content, prevent service interruption
                  while the user is listening to the audio
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECORD_AUDIO</p>
              </td>
              <td>
                <p>use microphone</p>
              </td>
              <td>
                <p>
                  Used to access and open ODAILY business, and use audio to
                  record notes in some scenarios
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MANAGE_EXTERNAL_STORAGE</p>
              </td>
              <td>
                <p>Allow access to all files</p>
              </td>
              <td>
                <p>
                  Used to access and open ODAILY business, users save pictures,
                  and record crash log information locally (if any)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>FLASHLIGHT</p>
              </td>
              <td>
                <p>Allow access to flash</p>
              </td>
              <td>
                <p>
                  Used to remind the user with a flashing light when receiving
                  an important push
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_INSTALL_PACKAGES</p>
              </td>
              <td>
                <p>Install permissions from unknown sources</p>
              </td>
              <td>
                <p>
                  For the update installation of ODAILYApp, this permission
                  cannot install other applications
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RECIEVE_MCS_MESSAGE</p>
              </td>
              <td>
                <p>full network access</p>
              </td>
              <td>
                <p>for accessing the network</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CALL_PHONE</p>
              </td>
              <td>
                <p>Initiate a phone call UI without going through the dialer</p>
              </td>
              <td>
                <p>for dialing</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_FINE_LOCATION</p>
              </td>
              <td>
                <p>Obtain location information permission</p>
              </td>
              <td>
                <p>for getting location</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>REQUEST_IGNORE_BATTERY_OPTIMIZATIONS</p>
              </td>
              <td>
                <p>Get battery optimization permission</p>
              </td>
              <td>
                <p>Used to optimize battery usage</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_SHORTCUT</p>
              </td>
              <td>
                <p>update permissions</p>
              </td>
              <td>
                <p>for updating permissions</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_BADGE</p>
              </td>
              <td>
                <p>Message Notification Permission</p>
              </td>
              <td>
                <p>for push messages</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_COUNT</p>
              </td>
              <td>
                <p>Update Quantity Permission</p>
              </td>
              <td>
                <p>Used to get the number of updates</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>UPDATE_BADGE</p>
              </td>
              <td>
                <p>Update SMS notification</p>
              </td>
              <td>
                <p>Used to get SMS content</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CHANGE_BADGE</p>
              </td>
              <td>
                <p>Apply desktop badge permissions</p>
              </td>
              <td>
                <p>Used to display corner mark information</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>GET_TASKS</p>
              </td>
              <td>
                <p>Allows an application to get the current process</p>
              </td>
              <td>
                <p>to get the current process</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH</p>
              </td>
              <td>
                <p>get bluetooth</p>
              </td>
              <td>
                <p>for using bluetooth</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BLUETOOTH_ADMIN</p>
              </td>
              <td>
                <p>Get bluetooth permission</p>
              </td>
              <td>
                <p>Used to obtain bluetooth permission</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ACCESS_COARSE_LOCATION</p>
              </td>
              <td>
                <p>Get approximate location information</p>
              </td>
              <td>
                <p>Used to obtain location information</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MIPUSH_RECEIVE</p>
              </td>
              <td>
                <p>Receive Xiaomi Push</p>
              </td>
              <td>
                <p>Used to receive Xiaomi push</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_ADDED</p>
              </td>
              <td>
                <p>accept implicit broadcast</p>
              </td>
              <td>
                <p>for receiving broadcasts</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_CHANGED</p>
              </td>
              <td>
                <p>Listen for application changes</p>
              </td>
              <td>
                <p>Used to view application changes</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_INSTALL</p>
              </td>
              <td>
                <p>application installation</p>
              </td>
              <td>
                <p>for installing applications</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>BROADCAST_PACKAGE_REPLACED</p>
              </td>
              <td>
                <p>application replacement</p>
              </td>
              <td>
                <p>Used to replace older version applications</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>RESTART_PACKAGES</p>
              </td>
              <td>
                <p>application restart</p>
              </td>
              <td>
                <p>for restarting the application</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>MESSAGE</p>
              </td>
              <td>
                <p>access to information</p>
              </td>
              <td>
                <p>for obtaining information</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>C2D_MESSAGE</p>
              </td>
              <td>
                <p>Get C2D message</p>
              </td>
              <td>
                <p>Used to obtain C2D information</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>iOS App Permission Application List</h6>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>permission name</h6>
              </td>
              <td>
                <h6>Permission Description</h6>
              </td>
              <td>
                <h6>Use scenario or purpose</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-CameraUsageDescription</p>
              </td>
              <td>
                <p>use camera</p>
              </td>
              <td>
                <p>
                  It is used for users to take photos when changing their
                  avatars
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-MicrophoneUsageDescription</p>
              </td>
              <td>
                <p>use microphone</p>
              </td>
              <td>
                <p>
                  Used to access and open ODAILY business, and use audio to
                  record notes in some scenarios
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryAdditionsUsageDescription</p>
              </td>
              <td>
                <p>Add content to album</p>
              </td>
              <td>
                <p>For users to save pictures</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Privacy-NSPhotoLibraryUsageDescription</p>
              </td>
              <td>
                <p>Read the contents of the album</p>
              </td>
              <td>
                <p>For users to upload avatars</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ApplicationEn;
