import http from '../scripts/http';
import { service } from '../scripts/http';

export function getSlide(lang) {
  return http.get('/focus?type=home_page', { lang: lang });
}

export function getAdvert(type, lang) {
  return http.get(`/focus?type=${type}`, { lang });
}

export function getHome_activity(lang) {
  return service.get('/scheme/slide_show?type=home_activity', { lang });
}
