import { combineReducers } from 'redux';

const list = (state = {}, action) => {
  switch (action.type) {
    case 'SET_VIEWPOINT_LIST':
      return action.list;
    default:
      return state;
  }
};

const page = (state = {}, action) => {
  switch (action.type) {
    case 'SET_VIEWPOINT_PAGE':
      return action.page;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  page
});
