import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styles from './index.scss';

import Layout from '../../components/common/layout';
import Carousel from '../../components/activityPage/Carousel';
import ActivityList from '../../components/activityPage/list';
import HomeLoadMore from '../../components/common/homeloadmore';

import {
  getActivity,
  getSlide,
  getActivityList
} from '../../common/api/activity';

import wechatShare from '../../common/scripts/wechat';

import { getHelmet, helment_locale } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

export class ActivityPage extends Component {
  static propTypes = {
    activityList: PropTypes.array.isRequired,
    slideList: PropTypes.array.isRequired,
    slideMore: PropTypes.object.isRequired,
    match: PropTypes.object
  };

  static fetchData(store, match, res) {
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      getActivity(lang)
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_LIST',
            list
          });
          return getSlide(lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_SLIDE',
            list
          });
          return getActivityList(8, 1, 16, lang);
        })
        .then(data => {
          const list = data.data.items;
          store.dispatch({
            type: 'SET_ACTIVITY_MORE',
            list
          });
          resolve();
        })
        .catch(() => {
          res.redirect(postHref);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 2,
      per_page: 16,
      slideMore: props.slideMore.data.filter(
        item => item.type === 'offline' || item.type === 'live'
      ),
      loading: false,
      loaded: false,
      total: props.slideMore.total
    };
  }

  componentDidMount() {
    getSlide();
    wechatShare({});
  }

  changeSlideMore() {
    let { page, slideMore, per_page, total } = this.state;
    const { lang } = this.props.match.params;

    this.setState({
      loading: true
    });

    getActivityList(8, page, per_page, lang).then(data => {
      if (data.code === 0) {
        let num = page + 1;
        let list = data.data.items.data;

        list
          .filter(item => item.type === 'offline' || item.type === 'live')
          .map(item => {
            slideMore.push(item);
          });

        this.setState({
          slideMore,
          page: num,
          loading: false
        });

        if (slideMore.length >= total) {
          this.setState({
            loading: false,
            loaded: true
          });
        }
      }
    });
  }

  renderActivityList() {
    const { activityList } = this.props;
    return activityList.map((item, index) => {
      return (
        <div className={styles.activityPage_list} key={`activityList${index}`}>
          <ActivityList
            title={item.title}
            href={`/activityMore/${item.id}`}
            list={item.scheme}
            filterNum={4}
            buttonFlag={item.scheme && item.scheme.length > 4 ? true : false}
          />
        </div>
      );
    });
  }

  render() {
    const { slideList, activityList, match } = this.props;
    const { slideMore, loading, loaded } = this.state;

    const { lang } = match.params;
    let helmetData = getHelmet(
      {
        title: `${helment_locale('title', lang)}${locales(lang, 'activity')}`,
        titleFlag: true
      },
      lang
    );
    return (
      <Layout>
        {/* 管理文档头，可以通过js动态的改变标签内的内容 */}
        <Helmet {...helmetData} />

        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* 轮播图 */}
            {slideList.length > 0 ? (
              <div className={styles.carousel}>
                <Carousel list={slideList} width={900} height={400} />
              </div>
            ) : (
              ''
            )}

            <div className={styles.activityPage_list_wrapper}>
              {this.renderActivityList()}

              {/* 更多活动 */}
              <div
                className={styles.activityPage_list}
                style={{ marginTop: slideList.length > 0 ? null : '30px' }}
              >
                <ActivityList
                  title={
                    slideList.length > 0
                      ? locales(lang, 'more_activities')
                      : locales(lang, 'all_activities')
                  }
                  href="/activityMore/8"
                  list={slideMore}
                  buttonFlag={
                    slideList.length > 0 && activityList && activityList.length
                      ? true
                      : false
                  }
                />
              </div>
              <div className={styles.HomeLoadMore}>
                <HomeLoadMore
                  loading={loading}
                  loaded={loaded}
                  onClick={this.changeSlideMore.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    activityList: state.activity.list,
    slideList: state.activity.slide,
    slideMore: state.activity.more
  };
};

export default withRouter(connect(mapStateToProps)(ActivityPage));
