import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import styles from './index.scss';
import _ from 'lodash';

import Layout from '../../components/common/layout';
import { PostItem } from '../../components/post';
import NewsFlashItem from '../../components/topic/newsFlashItem';
import { getTopicById } from '../../common/api/topic';
import { getHelmet } from '../../common/scripts/helmet';
import wechatShare from '../../common/scripts/wechat';
import locales from '../../utils/locales';

export class Topic extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    list: PropTypes.object.isRequired,
    share: PropTypes.object.isRequire,
    next_monographic: PropTypes.object.isRequire,
    template_info: PropTypes.object.isRequire,
    match: PropTypes.object
  };
  static fetchData(store, match, res) {
    const lang = match.params.lang;
    const postHref = lang ? `/${lang}/404` : `/404`;
    return new Promise(resolve => {
      const topicId = match.params.id;
      getTopicById(topicId, lang)
        .then(data => {
          const topicData = data.data;
          store.dispatch({
            type: 'SET_TOPIC',
            data: topicData
          });
          resolve();
        })
        .catch(() => {
          res.redirect(postHref);
        });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
      template_info: props.template_info ? JSON.parse(props.template_info) : {}
    };
  }

  componentDidMount() {
    const lists = this.props.list.layout_data;
    const { lang } = this.props.match.params;
    let newsList = _.cloneDeep(lists);
    for (var i = 0; i < lists.length; i++) {
      for (var j = 0; j < lists[i].childList.length; j++) {
        newsList[i].childList[j].data.items = lists[i].childList[
          j
        ].data.items.slice(0, 5);
      }
    }
    this.setState({
      newsList
    });
    wechatShare({ title: `${this.props.title} | ${locales(lang, 'special')}` });
  }

  changedata(obj) {
    const { newsList } = this.state;
    const lastarr = _.cloneDeep(newsList);
    const lists = this.props.list.layout_data;
    let newarr = _.cloneDeep(lists);
    let lastitem = [];
    newarr[obj.index].childList.forEach(item => {
      if (item.type === obj.type && item.ids[0] === obj.id) {
        lastitem.push(item.data.items);
      }
    });
    lastarr[obj.index].childList.forEach(item => {
      if (item.type === obj.type && item.ids[0] === obj.id) {
        return (item.data.items = lastitem[0]);
      }
    });
    this.setState({
      newsList: lastarr
    });
  }

  renderMore(obj, lang) {
    return (
      <div
        className={styles.loadMore}
        onClick={this.changedata.bind(this, obj)}
      >
        <p className={styles.tips}> {locales(lang, 'view_all')}</p>
      </div>
    );
  }

  renderPosts(lang) {
    const myList = this.props.list.layout_data;
    const { newsList } = this.state;

    return newsList.map((datas, firstIndex) => {
      return datas.childList.map((item, index) => {
        if (item.type === 'post') {
          const postItem = item.data.items.map(post => {
            const extraction_tags = post.extraction_tags
              ? JSON.parse(post.extraction_tags)
              : [];
            const props = Object.assign(post, {
              type: 'normal',
              entity_id: post.id
            });
            return (
              <PostItem
                key={post.entity_id}
                {...props}
                extraction_tags={extraction_tags}
              />
            );
          });
          return (
            <div className={styles.topic_box} key={`topic_box1${index}`}>
              {item.name ? (
                <div className={styles.itemTitle_wrapper}>
                  <div className={styles.itemTitle}>{item.name}</div>
                </div>
              ) : null}
              <div className={styles.topic_item}>{postItem}</div>
              {item.data.items.length == 5 &&
                myList[firstIndex].childList[index].data.items.length > 5 &&
                this.renderMore(
                  {
                    type: 'post',
                    index: firstIndex,
                    id: item.ids[0]
                  },
                  lang
                )}
            </div>
          );
        } else if (item.type === 'newsflash') {
          const newsItem = item.data.items.map((news, index) => {
            return <NewsFlashItem key={index} {...news} />;
          });
          return (
            <div className={styles.topic_box} key={`topic_box2${index}`}>
              {item.name ? (
                <div className={styles.itemTitle_wrapper}>
                  <div className={styles.itemTitle}>{item.name}</div>
                </div>
              ) : null}
              <div className={styles.topic_newsflash_item}>{newsItem}</div>
              {item.data.items.length == 5 &&
                myList[firstIndex].childList[index].data.items.length > 5 &&
                this.renderMore(
                  {
                    type: 'newsflash',
                    index: firstIndex,
                    id: item.ids[0]
                  },
                  lang
                )}
            </div>
          );
        }
      });
    });
  }

  render() {
    const { title, summary, match } = this.props;
    const { newsList, template_info } = this.state;
    const { lang } = match.params;
    let helmetData = getHelmet({
      title: `${this.props.title}_${locales(lang, 'special')}`,
      description: `${this.props.summary}`,
      twitterTitle: `${this.props.title}`,
      twitterDescription: `${this.props.summary}`,
      ogTitle: `${this.props.title}`,
      ogDescription: `${this.props.summary}`,
      ogUrl: `${process.env.ODAILY}${match.url}`
    });
    return (
      <Layout>
        <Helmet {...helmetData} />
        <div className={styles.container}>
          <div className={styles.topic_wrapper}>
            <div className={styles.topic_header_box}>
              <div className={styles.topic_img_box}>
                <div
                  className={styles.topic_img}
                  style={{
                    backgroundImage: `url(${
                      template_info.template_cover
                        ? template_info.template_cover[0]
                        : ''
                    })`
                  }}
                />
              </div>

              <div className={styles.topic_header}>
                <div className={styles.topic_title}>{title}</div>
                <div className={styles.topic_summary}>{summary}</div>
              </div>
            </div>

            <div className={styles.topic_list}>
              {newsList && newsList.length ? this.renderPosts(lang) : ''}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    id: state.topic.id,
    title: state.topic.title,
    summary: state.topic.summary,
    cover: state.topic.cover,
    list: state.topic.list,
    share: state.topic.share_data.default,
    next_monographic: state.topic.next_monographic,
    template_info: state.topic.template_info
  };
};

export default withRouter(connect(mapStateToProps)(Topic));
