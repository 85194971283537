exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a {\n  text-decoration: none; }\n\n.src-components-activity-item--container-index {\n  width: 100%;\n  height: 61px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  border-bottom: 1px dashed #434343; }\n  .src-components-activity-item--container-index .src-components-activity-item--time-index {\n    font-size: 14px;\n    font-weight: 400;\n    color: #797979;\n    line-height: 61px; }\n  .src-components-activity-item--container-index P {\n    font-size: 16px;\n    font-weight: 400;\n    color: white;\n    line-height: 61px; }\n  .src-components-activity-item--container-index:last-of-type {\n    border-bottom: none; }\n", ""]);

// exports
exports.locals = {
	"container": "src-components-activity-item--container-index",
	"time": "src-components-activity-item--time-index"
};