import React, { Component } from 'react';
import styles from '../../index.scss';

export class AgreementJa extends Component {
  render() {
    return (
      <div className={`${styles.content} ${styles.agreement}`}>
        <h5>ODAILYメディアユーザーサービス契約</h5>
        <h6>【前編・紹介】</h6>
        <p>
          オデイリーへようこそ！
          ODAILYサービス（以下「本サービス」といいます）をご利用いただくには、「ODAILYメディアユーザーサービス規約」（以下「本規約」といいます）をよくお読みいただき、これに従う必要があります。各条項、特に免責条項、免責条項、特定サービスの開設・利用に関する別途規約等の内容をよくお読みいただき、十分理解した上で、承諾するか否かを選択してください。制限および免責事項は、太字で注意を促す場合があります。本契約および関連契約のすべての条項を読んで同意しない限り、サービスを使用する権利はありません。本サービス上でのログイン、情報の閲覧、投稿、その他の行為は、本規約を読み、それに拘束されることに同意したものとみなされます。
          18
          歳未満の場合は、法定保護者の同伴のもと、本規約および上記のその他の規約をお読みいただき、未成年者向けの利用規約には特にご注意ください。
        </p>
        <h6>1. 【契約の範囲】</h6>
        <p>
          1.1 本契約は、ODAILY の使用に関するお客様と ODAILY との間の合意です。
          「ODAILY」とは、Hainan Moti Culture Media Co., Ltd.
          の運営関連会社およびその関連サービスを指します。 「プラットフォーム
          ユーザー」とは、ODAILY
          アカウントを登録、ログイン、使用する個人または組織を指し、本契約では「お客様」と呼ばれます。
          「その他のユーザー」とは、加入者およびプラットフォームユーザー自身以外の他のユーザーを含む、サービスに関連するユーザーを指します。
        </p>
        <p>
          1.2 このサービスには、ODAILY 公式ウェブサイト
          www.odaily.news、ODAILYAPP、Odaily APP、ODAILY WeChat パブリック
          アカウント Odaily (WeChat ID: O-daily)、Odaily WeChat サービス
          アカウント、ODAILYAPP オープン プラットフォーム、ODAILYAPP Weibo
          などが含まれますが、これらに限定されません。 .
          ビジネスプラットフォームはコミュニケーションチャネルであり、ODAILYは個人または企業向けに情報リリースとブランドプロモーションサービスを提供します。プラットフォームユーザーはODAILYアカウントを登録すると、ODAILYを通じて情報リリースやブランドプロモーション、購読ユーザーのコメント管理などの業務を行うことができます。
        </p>
        <h6>2.【アカウント登録とアカウント権限】</h6>
        <p>
          本サービスを利用する前にODAILYアカウントの登録が必要であり、ODAILYアカウントは携帯電話番号などの情報を紐付けて生成されます。
        </p>
        <p>
          2.2
          定期的に、登録されたアカウント情報を確認します。あなたは、提供したアカウント情報の信頼性、合法性、正確性、有効性について単独で責任を負うものとします。
          ODAILYまたは第三者に損害が生じた場合、お客様は法律に従って賠償するものとします。
        </p>
        <p>
          2.3
          ODAILYは、ODAILYを通じて公開するニュース（テキスト、写真、ビデオ、チャートなどを含みますがこれらに限定されません）を変更する権利を有します。Odaily
          APP、ODAILY WeChat公式アカウントOdaily（WeChat ID：O-daily）、Odaily
          WeChatサービスアカウント、ODAILYAPP Open Platform、ODAILYAPP
          Weibo、その他の ODAILY ビジネス
          プラットフォームは一般公開されています。 ODAILY
          とその関連会社およびパートナーは、ODAILY
          を通じてアップロードおよび公開されるあらゆるコンテンツを世界規模で永続的、取消不能、無料かつ非独占的に使用する権利を有します。
        </p>
        <p>
          2.4
          本サービスを利用することにより、以下の権利を取得することができます。
        </p>
        <p>
          2.4.1 ODAILY を通じてメッセージを管理し、購読者と対話する; 2.4.2
          ODAILY アカウントの購読者数とメッセージのクリック数を取得する; 2.4.3
          ODAILY の同意を得て、ODAILY
          の商業活動を通じてメッセージを送信する権利を有します。ブランドメッセージや有料購読の投稿。また、ODAILY
          が ODAILY
          を通じたお客様の商業活動に対して技術サービス費用を請求する権利を有することにも同意するものとします。
        </p>
        <h6>3. 【プラットフォーム利用者の個人情報保護】</h6>
        <p>
          3.1
          このサービスへの申し込みのプロセス中に、必要な情報を提供する必要がある場合があります。ODAILY
          がタイムリーかつ効果的なサポートを提供できるように、この情報を真実、正確、合法、有効に保ち、タイムリーな更新に注意してください。より良いサービスを提供します
          あなたはサービスを提供します。関連する法律、規制、政策に従って、実際の身元情報を入力してください。ご入力いただいた情報に不備や不正確な場合は、サービスをご利用いただけない場合や、ご利用に制限が生じる場合がございます。
        </p>
        <p>
          3.2
          ODAILYは、プラットフォームユーザーとともに個人情報の保護に努めており、プラットフォームユーザーの個人情報を保護することはODAILYの基本原則です。
          ODAILYは、法令に別段の定めがある場合を除き、お客様の同意がない限り、お客様の個人情報をODAILY以外の企業、団体、個人に開示することはありません。
        </p>
        <p>
          3.3
          あなたは、このサービスを通じて知り、受け取り、またはアクセスできる他のユーザーを含むがこれに限定されないあらゆる人の個人情報を完全に尊重する必要があり、他のユーザーの個人情報を収集、コピー、保存、配布、または使用してはなりません。その結果生じる結果はあなたが負担するものとします。
        </p>
        <h6>4. 【プラットフォームコンテンツ仕様】</h6>
        <p>
          4.1 この記事で言及されているプラ​​ットフォーム コンテンツとは、ODAILY
          アカウントのアバター、名前、プラットフォーム
          ユーザーの説明、その他の登録情報、またはテキストを含むがこれらに限定されない、このサービスの使用中にお客様が作成、コピー、公開、配布したあらゆるコンテンツを指します。
          、音声、写真、メッセージの送信、返信、または自動返信、および関連リンク
          ページ、ならびにビデオ、写真、テキストなどの ODAILY アカウントまたは
          ODAILY サービスを使用して生成されるその他のコンテンツ。
        </p>
        <p>
          4.2
          本サービスを利用する場合、法令、社会主義制度、国益、国民の正当な権利利益、社会公序良俗、情報の信頼性などの「7つのボトムライン」要件を遵守しなければなりません。
        </p>
        <p>
          次の法律、規制、ポリシーで禁止されているコンテンツを作成、コピー、公開、または配布するためにこのサービスを使用することはできません。
        </p>
        <p>
          (1) 憲法で定められた基本原則に反対すること、(2)
          国家の安全を危険にさらし、国家機密を漏洩し、国家権力を転覆し、国家統一を損なうこと
          (3) 国家の名誉と利益を損なうこと、(4)
          民族憎悪、民族差別を扇動すること(5)
          国の宗教政策を弱体化させ、カルトや封建的迷信を促進する (6)
          噂を広め、社会秩序を破壊し、社会の安定を損なう (7)
          わいせつ、ポルノ、ギャンブル、暴力、殺人を広める (8) )
          他人を侮辱または中傷し、他人の正当な権利と利益を侵害すること、(9)
          社会秩序を破壊するために違法な集会、結​​社、行列、デモ、集会を扇動すること、
          {10}{' '}
          違法な民間団体の名を借りた活動、(11)その他、法律、規制、政策により禁止されている内容を含む情報。
        </p>
        <p>
          4.3 お客様は、ODAILY がプラットフォーム
          ユーザーに文明的で健全で標準化された秩序あるネットワーク環境を提供することに常に注力していることを理解し、これに同意するものとし、本サービスを利用して以下の情報を作成、複製、出版、または配布することは許可されません。
          ODAILYの正常な動作、または他のユーザーもしくは第三者の法律に違反する行為
          特典内容：
        </p>
        <p>
          (1) 性的または性的示唆を含むコンテンツを含むもの、(2)
          広告、嫌がらせ、スパム、(3)
          他人のプライバシー、個人情報、またはデータに関与するもの、(4)
          他人の名誉権、肖像権、知的財産権を侵害するもの、商業的 (5) ODAILY
          の通常の動作を妨げ、他のユーザーまたは第三者の法的権利と利益を侵害するその他の情報が含まれています。
        </p>
        <h6>5. 【プラットフォーム利用規約】</h6>
        <p>
          5.1
          この記事で言及されているプラ​​ットフォームの使用とは、登録とログイン、アカウントの操作とプロモーション、および
          ODAILY アカウントまたは ODAILY
          サービスを使用して実行されるその他の動作を含むがこれらに限定されない、このサービスを使用して実行されるあらゆる動作を指します。
        </p>
        <p>
          5.2 お客様は、ODAILY アカウントまたは ODAILY
          サービスを使用して、以下の行為を行ってはなりません。
        </p>
        <p>
          (1)
          虚偽の情報を送信または公表すること、または他者になりすますことまたは名前を使用すること
          (2)
          他のユーザーに注意を払うこと、リンク先のページをクリックすること、または情報を共有することを強制または誘導すること
          (3) 誤解を招くために事実を捏造し、真実を隠蔽すること(4)
          評判権、肖像権、知的財産権、営業秘密などのその他の法的権利を侵害すること
          (5) ODAILY の書面による許可なく、ODAILY
          アカウントを使用して第三者を宣伝すること
          (これには含まれませんが、これらは含まれません) (6) ODAILY
          の書面による許可なしに、プラグイン、プラグイン、またはその他のサードパーティのツールやサービスを使用して、このサービスおよび関連システムにアクセスすること。(7)
          ) ODAILY アカウントまたは ODAILY
          サービスを使用して、違法および犯罪行為に従事すること、(8)
          これらの行為が商業目的であるかどうかに関係なく、上記の行為に関連する方法およびツールを作成、公開すること、またはそのような方法およびツールを運用または配布すること。または、ODAILY
          によって明示的に許可されていない行為。
        </p>
        <h6>6. 【アカウント管理】</h6>
        <p>
          6.1 ODAILY アカウントの所有権は海南 Moti Culture Media Co., Ltd.
          が所有しており、プラットフォーム利用者はアプリケーション登録手続きを完了した後、ODAILY
          アカウントを使用する権利を取得し、この使用権は最初の申請者にのみ帰属します。贈与、貸与、レンタル、譲渡、販売は禁止されています。
          Hainan Moti Culture Media Co., Ltd.
          は、ビジネス上の必要によりこのサービスを終了する権利を有します。
        </p>
        <p>
          6.2
          お客様は、登録されたアカウント情報およびアカウントのパスワードを適切に安全に保管する責任を負い、登録されたアカウントおよびパスワードに基づく行為に対して法的責任を負う必要があります。あなたは、いかなる状況においてもアカウントまたはパスワード情報を他人に開示しないことに同意します。他人があなたのアカウントまたはパスワードを使用している疑いがある場合、あなたはただちに海南モティ文化メディア有限公司に通知することに同意します。
        </p>
        <p>
          6.3
          お客様は、本契約の条項を遵守し、サービスを正しく適切に利用する必要があります。本契約の条項に違反した場合、海南モティカルチャーメディア株式会社は、お客様のODAILYアカウントへのサービスの提供を終了する権利を有します。この契約に従ってサービスを提供します。同時に、ODAILY
          は、ODAILY
          アカウントおよびプラットフォームのユーザー名をいつでも取り消す権利を留保します。
        </p>
        <h6>7. 【データ保管】</h6>
        <p>
          7.1 ODAILY
          は、本サービスにおけるお客様の関連データの削除または保存の失敗に対して責任を負いません。
        </p>
        <p>
          7.2
          ODAILYは、実際の状況に応じて、本サービスにおける単一のプラットフォームユーザーのデータの最大保存期間を決定し、サーバー上のデータの最大保存スペースを割り当てる権利を有します。必要に応じて、このサービス内の関連データをバックアップできます。
        </p>
        <p>
          7.3
          お客様がサービスの使用を停止した場合、またはサービスが終了またはキャンセルされた場合、ODAILY
          はお客様のデータをサーバーから完全に削除することができます。サービスが停止、終了、またはキャンセルされた後、ODAILY
          はデータをお客様に返却する義務を負いません。
        </p>
        <h6>8. 【リスクと免責事項】</h6>
        <p>
          8.1
          あなたは、このサービスがプラットフォームユーザーが情報を共有、広め、取得するためのプラットフォームにすぎないことを理解し、これに同意します。また、あなたは、登録したアカウントに基づくすべての行為（コンテンツの信頼性、合法性、正確性、有効性を含む）に対して責任を負わなければなりません。性別を公開し、このサービスの使用から生じる結果を負担します。プラットフォームユーザーは、ODAILY
          のコンテンツをご自身で判断し、コンテンツの信頼性、合法性、正確性、有効性への依存から生じるリスクを含め、コンテンツの使用から生じるすべてのリスクを負担する必要があります。
          ODAILY は、プラットフォーム
          ユーザーの行為によって生じた損失や損害に対して責任を負いませんし、今後も責任を負いません。
        </p>
        <p>
          誰かが本規約の条項に違反したり、その他不適切な方法でODAILYサービスを使用したりしていることを発見した場合は、直ちにODAILYに報告または苦情を申し立ててください。当社は法に従って対処します。
        </p>
        <p>
          8.2 お客様は、ビジネス開発の必要性により、ODAILY
          が予告なしにいつでも本サービスのサービス内容の全部または一部を一方的に変更、一時停止、制限、終了または中止する権利を留保し、プラットフォームユーザーが負担するものであることを理解し、これに同意するものとします。責任のリスク。
        </p>
        <p>
          8.3 ODAILY
          が発行する記事、ニュースレター、引用、レポートなどは、お客様に対する投資アドバイスを構成するものではありません。あなたはブロックチェーンとデジタル通貨の投資リスクを十分に認識し理解しており、すべての投資事項はあなた自身の理解に基づいています。
        </p>
        <h6>9. 【知的財産に関する声明】</h6>
        <p>
          9.1
          本サービスにおいてODAILYが提供するコンテンツ（Webページ、テキスト、画像、音声、動画、図表等を含みますがこれらに限定されません）の知的財産権はODAILYに帰属し、ODAILYによって生成されたコンテンツの知的財産権はODAILYに帰属します。本サービスを利用するプラットフォームユーザーは、プラットフォームに帰属し、その一切の権利はユーザーまたは関連権利所有者に留保されます。
        </p>
        <p>
          9.2
          ODAILYが本サービスを提供するソフトウェアの著作権、特許権、その他の知的財産権は、特に断りのない限り、ODAILYが所有します。
        </p>
        <p>
          9.3
          本サービスにおいてODAILYが使用する「ODAILY」およびその他の商用ロゴは、その著作権または商標権をHainan
          Moti Culture Media Co., Ltd.が所有します。
        </p>
        <p>
          9.4
          上記および本サービスに含まれるその他のコンテンツの知的財産権は法律で保護されており、ODAILY、プラットフォームユーザーまたは関連権利者の書面による許可がない限り、いかなる形式であっても関連する二次的著作物を使用または作成することはできません。
        </p>
        <h6>X. 【法的責任】</h6>
        <p>
          10.1
          ODAILYがプラットフォームユーザーが本規約に違反しているという他者からの報告や苦情を発見または受け取った場合、ODAILYは、いつでも予告なく関連コンテンツを削除する権利を有し、行為の状況に応じて、違反したアカウントを処罰する権利を有します。登録ユーザーに対する警告、ペナルティの一部または全部の削除、機能の全部または一部の利用制限または禁止、アカウントの禁止または解除、結果の公表までを行います。
        </p>
        <p>
          10.2 お客様は、ODAILY
          が合理的な判断に基づいて関連法令または本契約の規定の違反を罰し、法令に違反する者に対して適切な法的措置を講じ、関連部門に報告するために関連情報を保管する権利を有することを理解し、これに同意するものとします。法令等に従い、そこから生じる法的責任はプラットフォーム利用者が単独で負うものとします。
        </p>
        <p>
          10.3
          お客様は、本契約の条項または関連条件への違反に起因する、合理的な弁護士費用を含む、第三者から請求される請求、要求または損失について、ODAILY
          およびその協力会社および関連会社に補償するものであることを理解し、これに同意するものとします。サービス会社を危害から守ります。
        </p>
        <h6>11. 【契約の発効および変更】</h6>
        <p>
          11.1
          お客様によるサービスの使用は、本契約を読み、それに拘束されることに同意したものとみなされます。
        </p>
        <p>
          11.2 ODAILY
          は、必要に応じて本契約の条件を変更する権利を有します。最新版の規約は各サービスページでご確認いただけます。
        </p>
        <p>
          11.3 本契約の条件が変更された後、ODAILY
          サービスを引き続き使用する場合、変更された契約に同意したものとみなされます。改訂された規約に同意しない場合は、本サービスの使用を中止してください。
        </p>
        <h6>12.【その他】</h6>
        <p>12.1 この協定の署名場所は中華人民共和国北京市朝陽区です。</p>
        <p>
          12.2
          本契約の成立、発効、履行、解釈および紛争解決は、中華人民共和国本土の法律に準拠するものとします（法の抵触を除く）。
        </p>
        <p>
          12.3
          お客様とODAILYとの間に紛争または論争がある場合、まず友好的な交渉を通じて解決されるべきであり、交渉が失敗した場合、お客様は、本契約が署名された場所の管轄の人民法院にその紛争または紛争を提出することに同意するものとします。
        </p>
        <p>
          12.4
          本契約のすべての条項のタイトルは、読みやすさのみを目的としており、それ自体に実際の意味はなく、本契約の意味を解釈する根拠として使用することはできません。
        </p>
        <p>
          12.5
          本契約の条項のどの部分が何らかの理由で無効または強制不能であっても、残りの条項は引き続き有効であり、両当事者を拘束します。
        </p>
        <p>12.6 本契約は、2018 年 5 月 1 日に発効するものとします。</p>
      </div>
    );
  }
}

export default AgreementJa;
