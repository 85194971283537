import footer1 from '../../assets/image/特邀媒体.png';
import footer2 from '../../assets/image/战略媒体.png';
import footer3 from '../../assets/image/合作媒体.png';
import footer4 from '../../assets/image/合作社区.png';

const mainList = [
  {
    title: '十大 NFT 意见领袖',
    text:
      '他们自早期关注和研究 NFT 市场，发现价值、挖掘价值、聚焦价值，不吝向公众传播这一新兴领域中令人振奋的创意和创新，不断为市场注入新的活力。我们将从持有量、活跃度、影响力等维度评选出十大NFT意见领袖，展示 NFT 背后的风向标。',
    list: [
      {
        img: 'https://piccdn.0daily.com/202111/09063312/gp0sje7wnu3xaf12.jpeg',
        title: 'Beeple',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09051702/eawoblirkx48d3gg.jpeg',
        title: '曹寅',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09063312/n3mw6pjd92sqw6kn.jpeg',
        title: 'Ellio Trades',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09063312/n06ci4wsxhojjnpv.jpeg',
        title: 'Matty@DCLBlogger',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09063312/9xkakw9woqxh3ufs.jpeg',
        title: 'NiftyWhale',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09063312/g527d8rt62sr6g26.jpeg',
        title: 'Pak',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09063312/va7hvtregofkq25e.jpeg',
        title: 'Pranksy@pranksy',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09060344/z1ezskiidezowi2c.jpg',
        title: '孙宇晨',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09063312/zgps5s5gyx3i34ru.jpeg',
        title: 'Whale Shark',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09063312/4t94unsa954tmbsi.jpeg',
        title: '888@crypto888crypto',
        text: ''
      }
    ],
    href: 'http://wazb4mx1qj5oojkv.mikecrm.com/k0P5uWc'
  },
  {
    title: '十大 NFT 投资机构',
    text:
      '他们独具慧眼，坚定地押注 NFT 赛道，寻找最具增长潜力的价值项目，并在财务投资外给予一系列的投后服务，助力一批优质项目成长。我们将从布局时间、投资回报率等维度评选出十大NFT赛道的投资机构，寻找眼光独到的独角兽捕猎者。',
    list: [
      {
        img: 'https://piccdn.0daily.com/202111/09025857/1pdz61e64jf5dy26.png',
        title: 'a16z',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/205q8aaq5zbrbhb2.png',
        title: 'Animoca Brands',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/i8xo2aqyup3zmo3a.png',
        title: 'Alameda Research',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/puc0swbzawzzgw35.png',
        title: 'Coinbase Ventures',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09035934/40t5pfrdx9ackbqs.png',
        title: 'Consensys',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/25h3j8or8u2665jl.png',
        title: 'Dapper Labs',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/hhzhu3s5mig9pb4c.png',
        title: 'Digital Currency Group',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/acfs4i3wp1pofa5j.png',
        title: 'Dragonfly Capital',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/bz3jrw7cd8zapdou.png',
        title: 'Hashkey Capital',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09025857/ws2rme3mqaq15lka.png',
        title: 'Multicoin Capital',
        text: ''
      }
    ],
    href: 'http://wazb4mx1qj5oojkv.mikecrm.com/k0P5uWc'
  },
  {
    title: '十大 NFT 交易平台',
    text:
      '他们为一个个独特的NFT作品提供价值流通的场所，同时在用户体验、产品功能、界面展示等方面不断创新突破。我们将从交易量、作品数、用户量、特色产品功能等维度评选出十大NFT交易平台，发现NFT世界的流量聚集处。',
    list: [
      {
        img: 'https://piccdn.0daily.com/202111/09030345/68w3qxpx4tww2e5d.png',
        title: 'Art Blocks',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/db5i6iddeyyojpw1.png',
        title: 'Binance NFT Marketplace',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/b2ar5iare0amyt6q.png',
        title: 'BCA',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/wriw6dsdqzfxvtce.png',
        title: 'CryptoArt.Ai',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/s52u4n7cl9k6a7c7.png',
        title: 'element',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/pdzn348mu7amdvzj.png',
        title: 'Nifty Gateway',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/b9b65wa9u7iita3t.png',
        title: 'Opensea',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/cshp7msqlke2ln1v.png',
        title: 'Rarible',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/5hdx2ur7oi4zx6s5.png',
        title: 'SuperRare',
        text: ''
      },
      {
        img: 'https://piccdn.0daily.com/202111/09030345/k4fazooppqb4x47i.png',
        title: 'Solanart',
        text: ''
      }
    ],
    href: 'http://wazb4mx1qj5oojkv.mikecrm.com/k0P5uWc'
  }
];

const timerList = [
  {
    time: '8.30',
    text: '各项榜单候选人征集'
  },
  {
    time: '09.14',
    text: '人气榜单投票开启'
  },
  {
    time: '10.13',
    text: '人气榜单投票截止'
  },
  {
    time: '10.14-15',
    text: '所有榜单数据统计'
  },
  {
    time: '10.18-19',
    text: '榜单结果公布'
  }
  // {
  //   time: '01.07',
  //   text: '奖项公布及颁奖典礼'
  // }
];

const footerList = [
  { title: '特邀媒体', img: footer1 },
  { title: '战略媒体', img: footer2 },
  { title: '合作媒体', img: footer3 },
  { title: '合作社区', img: footer4 }
];

const popularityList = [
  {
    title: '十大 NFT 基础设施',
    id: 15,
    text:
      '他们在荒漠上建立秩序，推动市场从无到有，从小众到破圈，搭建平台，提供服务，为上下游相关从业者搭建沟通桥梁，推动NFT世界走走向结构化、产业化。我们将从用户规模、创作者、品牌影响力等维度评选出十大NFT基础设施，寻找支撑 NFT 发展的中坚力量。',
    imgList: [
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 }
    ],
    href: 'http://wazb4mx1qj5oojkv.mikecrm.com/k0P5uWc'
  },
  {
    title: '二十大人气 NFT 项目',
    id: 16,
    text:
      '他们是势不可挡的行业新秀、媒体争相报道的流量宠儿、社区讨论度爆表的话题热点，人气榜单将集结最具热度的 NFT人气项目，由社区投票和权威机构评审共同评判，决选出最具人气的 NFT 项目。',
    imgList: [
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 },
      { name: '虚位以待', num: 100 }
    ],
    href: 'http://wazb4mx1qj5oojkv.mikecrm.com/k0P5uWc'
  }
];

export { mainList, footerList, timerList, popularityList };
