exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-textOverflow--textOverflow-index {\n  position: relative;\n  overflow: hidden;\n  -webkit-transition: max-height 0.5s;\n  transition: max-height 0.5s; }\n\n.src-components-textOverflow--textOverflow_button_bg-index {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  display: block;\n  text-align: right;\n  padding-left: 2px; }\n\n.src-components-textOverflow--textOverflow_button-index {\n  font-weight: 400;\n  color: #002cae;\n  font-family: PingFangSC-Regular, PingFang SC;\n  margin-left: 5px;\n  cursor: pointer; }\n", ""]);

// exports
exports.locals = {
	"textOverflow": "src-components-textOverflow--textOverflow-index",
	"textOverflow_button_bg": "src-components-textOverflow--textOverflow_button_bg-index",
	"textOverflow_button": "src-components-textOverflow--textOverflow_button-index"
};