import { getCurrentUser } from '../api/user';
import locales from '../../utils/locales';

export function wechatLogin() {
  location.href = `${process.env.BASEURL}/api/oauth/wechat?from_url=${
    location.href
  }${process.env.NODE_ENV === 'development' ? '&is_test=111' : ''}`;
}

export function weiboLogin() {
  location.href = `${process.env.BASEURL}/api/oauth/weibo?from_url=${
    location.href
  }${process.env.NODE_ENV === 'development' ? '&is_test=111' : ''}`;
}

export function loginFlag(dispatch, flag, type = 'VerificationLogin') {
  // flag: true打开登录 false关闭登录
  // type: PasswordLogin密码登录  VerificationLogin验证码登录
  dispatch({
    type: 'SET_LOGIN_FLAG',
    flag
  });

  if (type) {
    dispatch({
      type: 'SET_LOGIN_TYPE',
      flag: type
    });
  }
}

export function loginState(dispatch) {
  getCurrentUser().then(data => {
    if (data.code === 0) {
      const userData = data.data;
      dispatch({
        type: 'SET_CURRENT_USER',
        user: userData
      });
    }
  });
}

export function verificationPhone(password, rule, lang) {
  rule = null;
  if (password === '') {
    return locales(lang, 'loginEmptyPhoneTip');
  }
  if (rule) {
    let reg = eval(rule);
    if (!reg.test(password)) {
      return locales(lang, 'loginRightPhoneNOTip');
    }
  }
  return '';
}

export function verificationVerify(password, lang) {
  if (password === '') {
    return locales(lang, 'loginEmptyVerifyCodeTip');
  }
  if (password.length != 6) {
    return locales(lang, 'loginErrorVerifyCodeTip');
  }

  return '';
}

export function verificationPassword(password, lang) {
  let reg = /^\w{6,16}$/;
  if (password === '') {
    return locales(lang, 'loginEmptyPhoneTip');
  }
  if (password.length < 6 || password.length > 16) {
    return locales(lang, 'enterPwd');
  }
  if (!reg.test(password)) {
    return locales(lang, 'enterPwd1');
  }

  return '';
}
