import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';

export default class Slide extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      interval: 5000,
      speed: 300,
      activeIndex: 1,
      data: [props.list[props.list.length - 1], ...props.list, props.list[0]],
      pausedFlag: false,
      classFlag: false,
      jianFlag: false,
      previous: 0
    };
  }

  componentDidMount() {
    let { interval } = this.state;
    this.autoTimer = setInterval(this.autoMove.bind(this), interval);
  }

  autoMove() {
    let { activeIndex, speed, data } = this.state;
    activeIndex++;
    this.setState({
      classFlag: false
    });
    if (activeIndex >= data.length - 1) {
      this.setState(
        {
          activeIndex: 0,
          speed: 0
        },
        () => {
          let a = this.wrapper.offsetLeft;
          activeIndex = 1;
          this.setState({
            activeIndex,
            speed,
            a
          });
        }
      );
      return;
    }
    this.setState({
      activeIndex,
      speed
    });
  }

  focusChange(index) {
    let { speed } = this.state;
    this.setState({
      activeIndex: index,
      speed
    });
  }

  buttonChange(dir) {
    let { activeIndex, data, speed, previous } = this.state;
    let now = Date.now();
    if (now - previous < speed) {
      return;
    }
    this.setState({
      previous: now
    });
    if (dir === 'left') {
      activeIndex--;
      if (activeIndex < 1) {
        this.setState(
          {
            activeIndex: data.length - 1,
            speed: 0
          },
          () => {
            let a = this.wrapper.offsetLeft;
            this.setState({
              activeIndex: data.length - 2,
              speed,
              a
            });
          }
        );
        return;
      }
      this.setState({
        activeIndex,
        speed
      });
      return;
    }

    setTimeout(() => {
      this.autoMove();
    }, 0);
  }

  renderSlideList() {
    let { data, activeIndex, speed } = this.state;
    let { width } = this.props;
    return data.map((item, index) => {
      let listUrl = `/activity/${item.id}`;
      return (
        <div
          className={styles.slide}
          key={index}
          style={{
            width: `${width}px`,
            justifyContent: activeIndex - 1 === index ? 'flex-end' : null,
            transition: `${speed}ms`,
            transform:
              activeIndex !== index
                ? index < activeIndex
                  ? 'translate(11%) scale(0.78)'
                  : 'translate(-11%) scale(0.78)'
                : null
          }}
        >
          <a href={listUrl} target={'_blank'} rel="noreferrer">
            <div
              className={styles.img}
              style={{
                backgroundImage: `url(${item.cover})`,
                backgroundColor:
                  activeIndex === index ? null : 'rgba(0, 0, 0, 0.8)'
              }}
            />
          </a>

          <div
            className={styles.content_box}
            style={{ display: activeIndex === index ? null : 'none' }}
          >
            <div className={styles.content_wrapper}>
              <div
                className={styles.content}
                style={{
                  backgroundImage: `url(${data[activeIndex].cover})`
                }}
              />

              <div className={styles.content_main}>
                <div
                  className={styles.content_title}
                  style={{ WebkitBoxOrient: 'vertical' }}
                >
                  {data[activeIndex].title}
                </div>

                <div className={styles.content_place}>
                  <div
                    className={styles.content_place_icon}
                    style={{
                      backgroundImage:
                        'url(https://piccdn.0daily.com/202203/14101910/d00z10nvv16avw7e.png)'
                    }}
                  />
                  <div className={styles.content_place_text}>
                    {data[activeIndex].published_at
                      .replace(/-/g, '/')
                      .slice(0, 16)}
                  </div>
                </div>

                <div
                  className={styles.content_place}
                  style={{
                    display: data[activeIndex].site ? null : 'none'
                  }}
                >
                  <div
                    className={styles.content_place_icon}
                    style={{
                      backgroundImage:
                        'url(https://piccdn.0daily.com/202203/15094927/94kpyxaed0q9gz8e.png)'
                    }}
                  />
                  <div
                    className={styles.content_place_text}
                    style={{ WebkitBoxOrient: 'vertical' }}
                  >
                    {data[activeIndex].site}
                  </div>
                </div>

                <div
                  className={styles.content_place}
                  style={{ display: data[activeIndex].sponsor ? null : 'none' }}
                >
                  <div
                    className={styles.content_place_icon}
                    style={{
                      backgroundImage:
                        'url(https://piccdn.0daily.com/202203/15094921/bwsmh6thlxhemxtb.png)'
                    }}
                  />
                  <div
                    className={styles.content_place_text}
                    style={{ WebkitBoxOrient: 'vertical' }}
                  >
                    {data[activeIndex].sponsor}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  // renderPagination() {
  //   let { activeIndex } = this.state;
  //   let { list } = this.props;
  //   return (
  //     <div className={styles.pagination} style={{ left: 'calc(50% - 145px)' }}>
  //       {list.map((item, index) => {
  //         return (
  //           <span
  //             className={activeIndex === index + 1 ? styles.active : null}
  //             key={index}
  //             onClick={() => {
  //               this.focusChange(index + 1);
  //             }}
  //           />
  //         );
  //       })}
  //     </div>
  //   );
  // }

  render() {
    let { activeIndex, speed, data, interval, jianFlag } = this.state;
    let { width, height } = this.props;
    return (
      <div className={styles.main_box}>
        <div className={styles.main}>
          <div
            className={styles.container}
            style={{ height: `${height}px` }}
            onMouseEnter={() => {
              clearInterval(this.autoTimer);
              this.setState({
                pausedFlag: true,
                classFlag: false,
                jianFlag: true
              });
            }}
            onMouseLeave={() => {
              this.autoTimer = setInterval(this.autoMove.bind(this), interval);
              this.setState({
                pausedFlag: false,
                classFlag: true,
                jianFlag: false
              });
            }}
          >
            <div
              className={styles.wrapper}
              ref={e => (this.wrapper = e)}
              style={{
                width: `${data.length * width}px`,
                left: `${-activeIndex * width + 148}px`,
                transition: `left ${speed}ms linear`
              }}
            >
              {this.renderSlideList()}
            </div>

            <div
              className={styles.button}
              style={{ display: jianFlag ? null : 'none' }}
            >
              <a
                href="javascript:;"
                className={styles.buttom_prev}
                onClick={() => {
                  this.buttonChange('left');
                }}
              />
              <a
                href="javascript:;"
                className={styles.buttom_next}
                onClick={() => {
                  this.buttonChange('right');
                }}
              />
            </div>
            {/* {this.renderPagination()} */}
          </div>
        </div>

        <div className={styles.main_box_border} />
      </div>
    );
  }
}
