import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import styles from './index.scss';

import Layout from '../../components/common/layout';

import ImgPreviewLeft from './image/preview-left.png';
import ImgPreviewRight from './image/preview-right.png';
import ImgPreviewIPhone from './image/preview-iphone.png';
import ImgDownloadLogo from './image/logo.png';
import ImgDownloadIPhone from './image/download-iphone.png';
import ImgDownloadAndroid from './image/download-android.png';
const ImgDownloadQRCode =
  'https://piccdn.0daily.com/202206/22062533/12cj9xwb8wmhzgow.png';
import wechatShare from '../../common/scripts/wechat';
import PropTypes from 'prop-types';
import { getHelmet } from '../../common/scripts/helmet';
import locales from '../../utils/locales';

export class Download extends Component {
  static propTypes = {
    match: PropTypes.object
  };
  static fetchData() {}

  componentDidMount() {
    wechatShare({ title: 'APP下载' });
  }
  render() {
    let helmetData = getHelmet({
      title: 'APP Download'
    });
    const { lang } = this.props.match.params;
    return (
      <div className={styles.container}>
        <Helmet {...helmetData} />
        <Layout>
          <div className={styles.content}>
            <div className={styles['preview-container']}>
              <img
                className={styles['preview-left-img']}
                alt=""
                src={ImgPreviewLeft}
                loading="lazy"
              />
              <img
                className={styles['preview-right-img']}
                alt=""
                src={ImgPreviewRight}
                loading="lazy"
              />
              <img
                className={styles['preview-iphone-img']}
                alt=""
                src={ImgPreviewIPhone}
                loading="lazy"
              />
            </div>
            <div className={styles['desc-container']}>
              <img
                className={styles['download-logo']}
                src={ImgDownloadLogo}
                alt="ODAILY"
                loading="lazy"
              />
              <p className={styles['download-text']}>
                {locales(lang, 'krExclusive')}
              </p>
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://itunes.apple.com/cn/app/id1380052002?mt=8"
              >
                <img
                  className={styles['download-button']}
                  src={ImgDownloadIPhone}
                  alt="ODAILY-iPhone"
                  loading="lazy"
                />
              </a>
              {/*<a*/}
              {/*  target="_blank"*/}
              {/*  rel="nofollow noopener noreferrer"*/}
              {/*  href="https://a.app.qq.com/o/simple.jsp?pkgname=com.odaily.news"*/}
              {/*>*/}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://resource.odaily.news/download/base-app-release.apk"
              >
                <img
                  className={styles['download-button']}
                  src={ImgDownloadAndroid}
                  alt="ODAILY-Android"
                  loading="lazy"
                />
              </a>

              <img
                className={styles['download-qrcode']}
                src={ImgDownloadQRCode}
                alt="ODAILY-客户端下载二维码"
                loading="lazy"
              />
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default withRouter(connect()(Download));
