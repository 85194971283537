import React, { Component } from 'react';
import styles from '../../index.scss';
import PropTypes from 'prop-types';

export class PrivacyZhcn extends Component {
  static propTypes = {
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.content}>
        <h5>{this.props.title}</h5>
        <h6>目录</h6>

        <p>一．引言</p>
        <p>二．关于我们</p>
        <p>三．我们如何收集和使用您的个人信息</p>
        <p>四．我们如何使用Cookie和同类技术</p>
        <p>五．我们如何委托处理、对外提供、转让、公开披露您的个人信息</p>
        <p>六．我们如何存储和保护您的个人信息</p>
        <p>七．您如何管理您的个人信息</p>
        <p>八．未成年人的个人信息保护</p>
        <p>九．隐私政策的修订和通知</p>
        <p>十．我们对去世用户的个人信息保护</p>
        <p>十一．您如何联系我们</p>
        <p>十二．争议解决</p>

        <h6>一．引言</h6>
        <p>
          本《ODAILY隐私政策》（以下简称“本隐私政策”）适用于ODAILY（以下简称“本平台”或“我们”）的软件及相关服务（以下简称“ODAILY服务”）。各条款标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本隐私政策条款的含义或解释。为维护您的自身权益，建议您仔细阅读各条款具体表述。
        </p>
        <p>
          请您特别注意：本隐私政策适用于我们以网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项ODAILY服务。如我们及关联公司的产品或服务中使用了ODAILY服务，但未设独立隐私权政策的，则本隐私政策同样适用于该部分服务。我们及关联公司就其向您提供的ODAILY服务单独设有隐私权政策的，则相应产品或服务适用相应隐私权政策。
        </p>
        <p>
          您在申请注册流程中或在本隐私政策更新时点击同意本隐私政策之前，应当认真阅读本隐私政策。请您务必审慎阅读、充分理解各条款内容，
          <b>
            有关您个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。
          </b>
        </p>
        <p>
          当您按照注册页面提示填写信息、阅读并同意本隐私政策且完成全部注册程序后或在本隐私政策更新时点击同意，即表示您已充分阅读、理解并接受本隐私政策的全部内容。
          <b>
            阅读本隐私政策的过程中，如果您拒绝同意本隐私政策或其中任何条款约定，将可能导致您将无法正常使用相关ODAILY服务和部分功能，或者无法达到我们预期到达的服务效果。
          </b>
        </p>
        <h6>二．关于我们</h6>
        <p>
          1.本平台的运营者及ODAILY服务的提供者均为海南省莫蒂文化传媒有限责任公司。
        </p>
        <p>
          2.关联公司是指在现在、将来控制海南省莫蒂文化传媒有限责任公司、受海南省莫蒂文化传媒有限责任公司控制或与海南省莫蒂文化传媒有限责任公司处于共同控制下的公司、机构。控制指通过所有权、有投票权的股份、合同、实际运营关联或其他被依法认定的方式直接或间接地拥有影响被控制对象管理/经营的能力。
        </p>
        <h6>三．我们如何收集和使用您的个人信息</h6>
        <p>
          1.我们依据法律法规以及遵循正当、合法、必要的原则而收集和使用您在使用服务过程中主动提供或由于ODAILY服务需要而产生的个人信息。如果我们欲将您的个人信息用于本隐私政策未载明的其它用途，或基于特定目的将收集而来的信息用于其他目的，我们会及时以合理的方式向您告知，并在使用前再次征得您的同意。
        </p>
        <p>
          2.我们收集和使用的您的个人信息类型包括两种：第一种：ODAILY服务的核心业务功能所必需的信息：此类信息为本平台正常运行的必备信息，您须授权我们收集。如您拒绝提供，可能将导致您将无法正常使用相关ODAILY服务和部分功能；第二种：附加业务功能可能需要收集的信息：此信息为非核心业务功能所需的信息，您可以选择是否授权我们收集。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。
        </p>
        <p>
          3.
          <b>
            我们需要特别提醒您的是：由于ODAILY服务较多，为您提供的内容也不同，因此核心业务功能（包括其收集的您的个人信息类型）也会因ODAILY服务的内容不同而有所区别，具体以ODAILY服务实际提供为准。除此之外，您理解并同意，我们希望提供给您的ODAILY服务是完善的，所以我们会不断改进ODAILY服务，包括技术，这意味着我们可能会经常推出新的业务功能，可能需要收集新的个人信息或变更个人信息使用目的或方式。如果某一需要收集您的个人信息的功能或服务未能在本隐私政策中予以说明的，我们会通过更新本隐私政策、页面提示、弹窗、网站公告等方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在征得您明示同意后收集。
          </b>
          在此过程中，如果您对相关事宜有任何疑惑的，可以通过本隐私政策第十一．一条提供的方式联系我们，我们会尽快为您作出解答。
        </p>
        <p>
          4.本隐私政策中敏感个人信息是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括
          <b>生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹</b>
          等信息，以及<b>不满十四周岁未成年人的个人信息</b>，例如，
          <b>
            敏感个人信息包括身份证件号码、个人生物识别信息、银行帐号、通信内容、健康生理信息
          </b>
          等。我们收集的敏感个人信息已在本隐私政策中通过显著标识进行展示。一旦您的敏感个人信息被泄露、篡改或被非法使用，将会对您的人身或财产安全造成损害。为防止您的敏感个人信息被泄露或被非法使用，我们已采取适当的技术和组织保护措施，尽力保障您的信息安全。
        </p>
        <p>5. 通常情况下，我们会在以下场景中收集和使用您的个人信息：</p>
        <p>5.1 注册及登录状态下ODAILY服务</p>
        <p>
          为便于我们为您提供持续稳定的服务，并保障您使用ODAILY服务的安全性，我们需要您主动提供基本注册或登录信息，包括手机号码、电子邮箱地址，并创建您的帐号、用户名和密码，其中
          <b>手机号码、密码属于个人敏感信息</b>
          ，收集前述信息是为了满足相关法律法规的网络实名制要求以及履行本隐私政策的要求。在部分单项服务中，如果您仅需使用浏览、搜索基本功能，您不需要注册成为ODAILY用户及提供上述信息。
        </p>
        <p>
          您也可以使用第三方平台账号注册ODAILY用户，可选择授权我们在符合相关法律法规要求的前提下读取并获得
          <b>您在该第三方平台上公布、记录的公开信息（包括昵称、头像）</b>
          。我们需要您授权从第三方获取上述信息是为了记住您作为ODAILY用户的登录身份，收集前述信息是基于您的同意，在您使用第三方账号注册或登录后，您的第三方账号将会与ODAILY账号进行绑定。
        </p>
        <p>
          基于我们与通信运营商的合作，当您使用ODAILY“一键登录”功能时，经过您的明示同意，运营商会将您的手机号码发送给我们，便于我们为您提供快捷的登录服务。您也可以自主选择手动填写手机号码的方式完成注册或登录。
        </p>
        <p>
          您在注册、登录或后续使用过程中，也可以主动填写或补充您的其他额外信息（您的昵称、头像、性别、出生日期、身份、行业），收集前述信息是基于您的同意，这些信息可能会在您的个人主页和评论区展示，用以标识您的ODAILY用户身份，方便您和其他ODAILY用户交流互动。但如果您不提供这些信息，并不会影响您使用ODAILY服务的基本功能。
        </p>
        <p>5.2 非注册及登录状态下ODAILY服务</p>
        <p>
          如果您在未注册或未登录状态下使用ODAILY服务，非登录模式下我们仅能为您提供部分功能。为保障功能实现，我们可能会主动申请获取您的设备信息、网络标识信息，以便实现浏览、搜索、点赞、收藏、分享功能，收集前述信息是为了满足相关法律法规的网络日志信息留存义务要求；
        </p>
        <p>
          除上述信息外，在您使用过程中，我们可能会主动申请获取您的
          <b>设备权限</b>
          以实现其他附加功能（见本隐私政策本条第5.9款“为您提供安全保障”)。
        </p>
        <p>5.3 资讯浏览</p>
        <p>
          1） ODAILY可能会向您推荐更感兴趣的信息，为实现这一功能，
          <b>我们可能会自动收集必要的日志信息</b>，包括：
        </p>

        <ul className={styles.privacy_ul}>
          <li>
            a）您操作、使用的行为信息：点击、关注、收藏、搜索、浏览、分享；
          </li>
          <li>b）设备信息：设备唯一识别码；</li>
          <li>
            c）网络信息：GPS信息、WLAN接入点、蓝牙和基站等传感器信息获取到的精确地理位置信息、以及通过网络位置信息（基站、IP及WLAN）获取的大致地理位置信息；
          </li>
        </ul>

        <p>
          2）<b>GPS地理位置是个人敏感信息</b>
          ，若您拒绝提供，我们将不会根据GPS信息向您推荐信息，且不会影响本平台其他功能的正常使用。
        </p>

        <p>
          3）我们会基于大致地理位置信息对应的城市向您展示、推荐您所在地区的本地相关信息。
        </p>

        <p>
          4）收集前述信息是为了满足相关法律法规的网络日志信息留存义务要求和履行本隐私政策的要求，
          <b>
            我们收集、使用的上述信息进行了去标识化处理，数据分析仅对应特定的、无法直接关联您身份的编码，不会与您的真实身份相关联
          </b>
          。
        </p>

        <p>5.4 发布与互动</p>
        <p>1）信息发布</p>
        <ul className={styles.privacy_ul}>
          <li>
            a）您发布内容或评论时，我们将自动收集您发布的信息，并展示您的昵称、头像、发布内容。
          </li>
          <li>
            b）您使用上传图片、发布音视频功能时，我们会请求您主动授权摄像头（相机）、相册（存储空间）、麦克风权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用本平台的其他功能。
          </li>
          <li>
            c）请注意，您公开发布的信息中可能会涉及用户或他人的个人信息，若您公开发布的信息中包含他人个人信息的，在上传发布之前，您需确保为法律法规允许使用或已获得合法授权。
          </li>
          <li>
            d）您在使用开通直播功能时，根据法律法规、安全保障要求和履行本隐私政策的要求，需要进行实名认证。在实名认证的过程中，认证服务的第三方认证机构需要您主动提供
            <b>真实姓名及身份证号码信息。这些信息是个人敏感信息</b>
            ，您可以拒绝提供，且不会影响本平台其他功能的正常使用。这些信息仅供实名认证及法律法规所规定的用途，未经您明示授权，不会用作其他目的。
          </li>
          <li>
            e）收集前述信息是为了满足相关法律法规的服务提供方留存信息发布账号、公众账号、直播用户等发布的信息内容的义务要求和履行本隐私政策的要求。
          </li>
        </ul>

        <p>2）互动交流</p>
        <ul className={styles.privacy_ul}>
          <li>
            a）您关注内容时，我们会收集您关注的内容或账号，并向您展示您该内容或该账号发布内容。
          </li>
          <li>
            b）收集前述信息是为了满足相关法律法规的网络日志信息留存义务要求和履行本隐私政策的要求。
          </li>
        </ul>

        <p>5.5 搜索</p>
        <p>
          1）您使用搜索服务时，我们会自动收集您的搜索关键字信息、日志信息记录进行分析以便向您展现相关程度更高的搜索结果。
        </p>
        <p>
          2）我们还可能会自动记录您在使用我们服务时提供、形成或留存的信息，如您在使用搜索时输入查询词,点击历史词、提示词和点击的链接,以及您参与搜索运营活动时提交的信息。您有权拒绝提供上述信息,但这将导致您可能无法使用特定服务或功能,或者无法达到相关服务拟达到的效果。
        </p>
        <p>
          3）收集前述信息是为了满足相关法律法规的网络日志信息留存义务要求和履行本隐私政策的要求，为了提供高效的搜索服务，部分前述信息还会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。
        </p>

        <p>
          5.6 <b>消息通知</b>
        </p>
        <p>
          <b>
            您知悉并同意，对于您在使用产品及/或服务的过程中主动提供的您的联系方式（联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研、争议处理用途；此外，我们也可能会向前述过程中收集的手机号码以短信、电话的方式，为您提供您可能感兴趣的服务、功能或活动商业性信息的用途，但请您放心，如您不愿接受这些信息，您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。
          </b>
        </p>

        <p>5.7 客户服务</p>
        <p>
          当您向我们进行意见反馈时，为了方便与您联系或帮助您解决问题，我们可能需要您主动提供姓名、手机号码、电子邮箱及其他联系方式，并可能使用这些信息以与您取得联系。如果您提交的是侵权投诉，我们还会依据法律规定要求您提交
          <b>身份证、授权委托、权属证明</b>，以明确您作为投诉主体的资格。
        </p>

        <p>5.8 交易服务</p>
        <p>
          当您购买在线阅读、在线课程、积分商城商品以及通过其他方式购买我们的产品或服务时，我们将自动收集您的交易记录和日志信息。收集前述信息是为了满足相关法律法规的网络日志信息留存义务和交易信息留存义务要求和履行本隐私政策的要求，其中
          <b>交易记录信息是个人敏感信息</b>
          ，拒绝提供这信息可能会使我们无法提供交易服务，但不影响您正常使用ODAILY服务的其他功能。
        </p>

        <p>5.9 为您提供安全保障</p>
        <p>
          为满足《中华人民共和国网络安全法》（以下简称“《网络安全法》”）等法律法规的要求、网络安全保障的需要和履行本隐私政策的要求，为您提供安全、可靠的服务，保障系统运行的稳定性和安全性，防止您的账号被盗、个人信息被非法获取，保护您的账号安全，我们需要主动申请收集您的1）设备信息（包括设备型号、设备MAC地址、操作系统类型、客户端版本、分辩率、设备设置）；设备唯一识别码（IMEI/android
          ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息）；2）网络身份标识信息（包括
          <b>GPS定位信息</b>
          、IP地址、电信运营商、网络环境、软件版本号、浏览器类型、
          <b>网站浏览记录</b>
          、操作日志）。来判断您的账号风险，以及预防、发现、调查危害系统运行安全、非法或违反我们规则、协议的行为。我们也会收集您的前述设备信息、软件信息用于对我们产品或服务系统问题进行分析、统计流量并排查可能存在的风险。如果您不提供上述信息，我们则无法在您使用我们的产品或服务的过程中对产品或服务及您的账号安全进行保护。
        </p>

        <p>5.10 提高使用感受和满意度</p>
        <p>
          为提高您对ODAILY服务使用感受和满意度，我们将自动收集您的操作行为，并基于您的操作行为对用户数据进行调查研究和分析，从而进一步优化ODAILY服务。
        </p>

        <p>
          5.11 <b>征得同意的例外</b>
        </p>
        <p>
          <b>
            请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1）与国家安全、国防安全有关的；</li>
          <li>2）与公共安全、公共卫生、重大公共利益有关的；</li>
          <li>3）与犯罪侦查、起诉、审判和判决执行等有关的；</li>
          <li>
            4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </li>
          <li>5）所收集的个人信息是个人信息主体自行向社会公众公开的；</li>
          <li>
            6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；但是您明确拒绝或者处理该信息侵害您重大利益的除外；
          </li>
          <li>7）根据您的要求签订合同所必需的；</li>
          <li>
            8）用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
          </li>
          <li>9）为合法的新闻报道所必需的；</li>
          <li>
            10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
          </li>
          <li>11）法律法规规定的其他情形。</li>
        </ul>
        <p>
          <b>
            请注意，单独或与其他信息相结合无法识别您的身份或者与您直接建立联系的信息，不属于个人信息。如果我们将单独无法与任何特定个人建立联系的信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。
          </b>
        </p>

        <p>5.12 设备权限调用</p>
        <p>
          为确保相关ODAILY服务的正常实现，我们需要根据具体的使用场景调用对应的必要权限，具体的权限调用说明请见
          <a href="?application-authority" target="_blank">
            《应用权限申请与使用说明》
          </a>
          。
        </p>
        <p>
          您可以在设备的设置中选择关闭部分或者全部权限，这可能导致对应的相关ODAILY服务无法实现或者无法达到预期效果。
        </p>

        <p>5.13 个性化推荐</p>
        <p>
          请知悉，ODAILY服务不涉及个性化内容的推荐与展示，我们不会收集您的浏览及搜索信息、行为偏好等信息进行画像分析。
        </p>

        <h6>四．我们如何使用Cookie和同类技术</h6>
        <p>
          1.Cookie可以帮助网站辨认注册用户，计算用户数量，通常被各网站用来判定完成注册的用户是否已经实现登录。本平台承诺，对Cookie信息的研究仅用于提升服务/产品质量及优化用户体验之目的。同时，如不希望个人信息保留在cookie中，您可以对浏览器进行配置：选择“拒绝Cookie”或“当网站发送Cookie时通知您”。您在此确认，鉴于ODAILY服务是通过支持Cookie来是实现的，完成关闭Cookie的操作后，可能会影响到您访问本平台或不能充分取得本平台的服务。当您不禁用Cookie时，可能会得到提示：是否在下一次进入此网站时保留用户信息以便简化登录手续（如一键登录）。
        </p>
        <p>
          2.本平台使用web
          beacon的方法和目的与Cookie是相同的，上述条款应参照适用。
        </p>

        <h6>五．我们如何委托处理、对外提供、转让、公开披露您的个人信息</h6>
        <p>1.委托处理您的个人信息</p>
        <p>
          为了向您提供更完善、优质的ODAILY服务，某些功能可能由我们的服务合作方提供（如我们的第三方服务供应商、承包商、代理），我们会委托服务合作方代表我们处理您的某些个人信息，包括但不限于为ODAILY提供一键登录服务、推送消息服务、数据分析与统计服务的合作伙伴。我们会通过书面协议、现场审计等方式要求服务合作方遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。委托关系解除后，服务合作方将不再保存您的个人信息。
        </p>
        <p>2.对外提供</p>
        <p>
          我们不会向本产品服务提供者以外的公司、组织和个人提供您的个人信息，但以下情况除外：
        </p>
        <p>
          2.1
          履行法定义务所必需：我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，或具备法律规定的其他合法事由，如为应对突发公共卫生事件或者紧急情况下为保护您的生命健康和财产安全所必需，或者为公共利益实施新闻报道、舆论监督等行为，对外提供您的个人信息。
        </p>
        <p>
          2.2
          基于您的同意或您主动提出要求：如您单独同意或主动要求我们向第三方提供您的个人信息的，我们将基于您同意的目的，在相应页面中以适当方式告知您个人信息接收方的名称和联系信息。如您使用信息分享服务的，我们会在页面提供第三方分享平台名称，具体联系方式可查看第三方隐私政策。
        </p>
        <p>
          2.3
          基于履行服务协议所必需：我们的部分ODAILY服务可能是我们的关联公司和合作机构或我们与其共同向您提供。因此，为履行与您订立的服务协议，我们必须将您的个人信息提供给我们的关联公司或合作机构。我们制定了
          <a href="?info-share-list" target="_blank">
            《信息共享清单》
          </a>
          向您清晰、简洁地介绍我们关联公司、合作机构的情况，查看《信息共享清单》
        </p>

        <p>
          1）向关联公司提供：为便于在您使用我们与关联公司或其他产品提供的产品或服务，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享您的敏感个人信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
        </p>

        <p>
          2）向我们的合作机构提供：为了向您提供完善的产品和服务，我们的某些服务或技术将由授权合作机构提供。请您知悉，我们不会主动向其他个人信息处理者提供您的个人信息，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。我们将向其他个人信息处理者提供您的个人信息，向您告知接收方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，并取得您的单独同意。
          同时，我们会与合作机构签署严格的保密协定，要求他们严格按照本隐私政策以及其他任何相关的保密政策并采取安全措施来处理您的个人信息。我们要求我们的合作机构无权将共享的个人信息用于任何其他用途。
          <b>
            当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的
            Cookie
            或使用其他技术手段，这些技术手段不受我们的控制，且它们的使用不受本隐私政策的约束。合作机构可能有其独立的个人信息保护政策和用户协议，我们建议您阅读并遵守第三方的个人信息保护政策及用户协议。
          </b>
        </p>

        <p>
          2.4
          基于法律规定的其他合法事由：我们可能会基于法律规定的其他合法事由，如为应对突发公共卫生事件或者紧急情况下为保护您的生命健康和财产安全所必需，或者为公共利益实施新闻报道、舆论监督等行为，向第三方提供您的个人信息的，我们会在适当时机（如紧急情况消除后）向您告知有关信息。
        </p>

        <p>3.转让</p>
        <p>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1）在获取明确同意的情况下转让。获得您的明确同意后，我们会向其他方转让您的个人信息。
          </li>
          <li>
            2）
            在涉及合并、收购或破产清算时转让。在涉及ODAILY合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的控制您个人信息的公司、组织继续受本隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
          </li>
        </ul>

        <p>4.公开披露</p>
        <p>
          4.1公开披露是指向社会或不特定人群发布信息的行为。除了因需要对违规账号、欺诈行为等进行处罚、公布中奖/获胜者等名单时脱敏展示相关信息等必要事宜而进行的必要披露外，我们不会对您的个人信息进行公开披露，如具备合理事由确需公开披露的，我们会在公开披露前向您告知公开披露的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容），并在征得您的授权同意后再公开披露，但法律法规另有规定的或本隐私政策另有约定的除外。
        </p>
        <p>
          4.2对于公开披露的您的个人信息，我们会在收到公开披露申请后第一时间且审慎审查其正当性、合理性、合法性，并在公开披露时和公开披露后采取最严格个人信息安全保护措施和手段对其进行保护。
        </p>

        <p>5.接入第三方SDK情况</p>
        <p>
          为保障您能够使用和享受更好的ODAILY服务，我们提供部分功能由我们合作的第三方机构以SDK插件的形式提供，SDK技术服务提供方会基于向您提供功能或服务之必须获取相应权限及信息。我们会对App中集成的SDK均进行安全监测，确保这些SDK仅收集了为实现其功能或服务所必需的个人信息。您可以点击此处查看
          <a href="?info-share-list" target="_blank">
            《信息共享清单》。
          </a>
        </p>

        <p>
          6.委托处理、对外提供、转让、公开披露个人信息时事先征得授权同意的例外
        </p>
        <p>
          <b>
            根据相关法律法规规定，以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
          </b>
        </p>
        <ul className={styles.privacy_ul}>
          <li>1）与我们履行法律法规规定的义务相关的；</li>
          <li>2）与国家安全、国防安全直接相关的；</li>
          <li>3）与公共安全、公共卫生、重大公共利益直接想关的；</li>
          <li>
            4）与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；
          </li>
          <li>
            5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </li>
          <li>6）您自行向社会公众公开的个人信息；</li>
          <li>
            7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          </li>
        </ul>
        <p>
          <b>
            请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的委托处理、对外提供、转让、公开披露无需另行向您通知并征得您的同意
          </b>
        </p>

        <h6>六．我们如何存储和保护您的个人信息</h6>
        <p>1.存储</p>
        <p>
          1.1
          存储地点：我们依照法律法规的规定，将在中华人民共和国境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，会严格遵守中国的相关法律、监管政策，并会遵循相关国家规定或者征求您的同意。
        </p>
        <p>
          1.2
          存储时间：我们承诺始终按照法律的规定在合理必要期限内在存储您个人信息。超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。
        </p>
        <p>
          1.3
          如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
        </p>

        <p>2.保护</p>
        <p>
          2.1
          我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。目前，我们已经通过了
          <b>国家信息安全等级保护（三级）的测评和备案。</b>
        </p>
        <p>
          2.2
          我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
        </p>
        <p>
          2.3
          我们会建立专门的安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息。
        </p>
        <p>
          2.4
          <b>
            尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
          </b>
        </p>
        <p>
          2.5
          <b>
            您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码及相关个人信息透露给他人。
          </b>
        </p>
        <p>
          2.6
          我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
        </p>
        <p>
          2.7
          您一旦离开本平台及ODAILY服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在本平台及ODAILY服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于本平台的链接或引导。
        </p>

        <h6>七．您如何管理您的个人信息</h6>
        <p>1.您有权查询、修改您的个人信息</p>
        <p>
          我们非常重视您对个人信息的关注，提供了多种功能以保护您对于个人信息查询和修改的权利。
        </p>
        <p>1.1 管理账号信息</p>
        <p>
          “我的”→“账号管理”您可通过这一功能修改账号密码，修改绑定手机号、绑定第三方账号。
        </p>
        <p>
          “我的”→“账户名片”→“编辑”您可以在编辑资料中查看和修改您的账号信息，包括头像、昵称、性别、生日、个人简介、身份、所属行业。
        </p>
        <p>1.2 管理评论与文章信息</p>
        <p>
          “我的”→“个人主页”点击单条发表的评论或文章进入内容详情页，您可以了解您发布的评论或文章情况。
        </p>
        <p>1.3 管理浏览记录</p>
        <p>“我的” → “浏览”您可以查看您的浏览记录。</p>
        <p>1.4 管理关注记录</p>
        <p>
          “我的” →
          “关注”您可以管理所有关注列表，查看及修改您关注的用户、作者、主题。
        </p>
        <p>“我的” → “收藏”您可以管理您收藏的文章、音视频及其他。</p>
        <p>
          您变更您的授权范围后，我们将据此调整本平台向您提供的ODAILY服务。但请您理解，这不会影响此前基于您的授权而开展的个人信息处理。
        </p>

        <p>2.删除您的个人信息</p>
        <p>我们将提供以下方式帮助您删除在您在本平台上发布的信息：</p>
        <p>2.1 删除评论与文章信息</p>
        <p>
          “我的”→“个人主页”点击单条发表的评论或文章进入内容详情页，并可以随时通过长按的方式删除您已发布的评论或文章。但您删除已发布的评论或文章，仅是取消其公开展示，我们仍需要依据法律法规对您曾经发布的评论或文章进行后台的存储保留。
        </p>
        <p>2.2 删除浏览记录</p>
        <p>“我的” → “浏览”您可以删除您的浏览记录。</p>
        <p>2.3 删除关注记录</p>
        <p>“我的” → “关注”您可以删除您关注的用户、作者、主题。</p>
        <p>“我的” → “收藏”您可以删除您收藏的文章、音视频及其他。</p>
        <p>
          2.4
          在以下情形中，我们将主动删除您的个人信息。如我们未删除的，您可通过发送邮件至om@odaily.email向我们提出删除个人信息的请求：
        </p>
        <ul className={styles.privacy_ul}>
          <li>（1）处理目的已实现、无法实现或者为实现处理目的不再必要；</li>
          <li>（2） 我们停止提供产品或者服务，或者保存期限已届满；</li>
          <li>（3）您撤回同意；</li>
          <li>（4）我们违反法律、行政法规或者违反约定处理个人信息；</li>
          <li>（5）法律、行政法规规定的其他情形。</li>
        </ul>
        <p>
          您删除信息后，我们将据此调整本平台向您提供的ODAILY服务。但请您理解，这不会影响此前基于您的授权而开展的个人信息处理。
        </p>

        <p>3.获取您的个人信息副本</p>
        <p>
          如您需要获取您的个人信息副本，您可发送邮件至om@odaily.email，我们将根据您的书面请求向您提供我们已收集的您的个人信息。
        </p>

        <p>4.您有权撤回您对个人信息的授权</p>
        <p>
          您可以在设备本身操作系统中，关闭GPS地理位置、摄像头、麦克风、相册权限、改变同意范围或撤回您的授权。关闭授权后我们将不再收集与这些权限相关信息。，从而拒绝我们收集您相应的个人信息。您需理解，当您撤回授权后，我们无法继续为您提供撤回授权所对应的ODAILY服务。
          <b>
            但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
          </b>
        </p>

        <p>5.个人信息转移权</p>
        <p>
          若您需要将您的个人信息转移至其他平台、企业或组织，您可以通过本隐私政策第十一条提供的方式联系我们。我们将对您的请求进行审核，在具备法律规定的个人信息转移前提的条件下，我们将为您提供转移相应个人信息的途径。
        </p>

        <p>6.ODAILY账号注销</p>
        <p>
          如果您需要注销您的ODAILY账号，请在“我的” →
          “账号管理”→“注销账号”中提交申请。在注销账号之后，我们将停止为您提供ODAILY服务，并依据法律法规相关要求通过匿名化等方式保护您的信息。
        </p>

        <p>7.自主管理控制营销信息及定向推送</p>
        <p>
          7.1
          消息推送——如您不希望收到App页面上的消息通知，可以在首次安装App时拒绝接收消息通知，或者在“我的”
          → “推送管理”关掉对应的通知功能。
        </p>
        <p>
          7.2
          推广/回访——如您不希望接收我们的推广短信或电话回访，可通过信息中相应的退订功能进行退订，或者在电话回访中明确表示拒绝。
        </p>

        <p>8.响应您的需求</p>
        <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <ul className={styles.privacy_ul}>
          <li>1）与我们履行法律法规规定的义务相关的；</li>
          <li>2）与国家安全、国防安全直接相关的；</li>
          <li>3）与公共安全、公共卫生、重大公共利益直接相关的；</li>
          <li>4）与刑事犯罪侦查、起诉、审判和执行判决等直接相关的；</li>
          <li>5）有充分证据表明个人信息主体存在主观恶意或滥用权利的；</li>
          <li>
            6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
          </li>
          <li>
            7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
          </li>
          <li>8）涉及商业秘密的。</li>
        </ul>

        <h6>八．未成年人的个人信息保护</h6>
        <p>
          1.我们非常重视对未成年个人信息的保护。根据相关法律法规的规定，若您是未满18周岁的未成年人，在使用ODAILY服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。
        </p>
        <p>
          2.我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、储存、共享、转让或披露未成年人的个人信息；如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
        </p>
        <p>
          3.若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策第十一条提供的方式联系我们。
        </p>

        <h6>九．隐私政策的修订和通知</h6>
        <p>
          1.为给您提供更好的服务，我们的ODAILY服务将不时变化，本隐私政策也将随之调整。
        </p>
        <p>
          2.未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会通过
          App
          客户端推送通知、弹窗形式等合理方式通知您，以便您能及时了解本隐私政策所做的任何变更。您可通过“我的”
          → “更多服务”→ “隐私政策”查看本隐私政策的最新内容。
        </p>

        <p>
          3.对于重大变更，视具体情况我们还会提供更为显著的通知说明本隐私政策的具体变更内容。重大变更包括但不限于：
        </p>
        <ul className={styles.privacy_ul}>
          <li>
            1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          </li>
          <li>
            2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
          </li>
          <li>3）个人信息共享、转让或公开披露的主要对象发生变化；</li>
          <li>4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</li>
          <li>
            5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；
          </li>
          <li>6）个人信息安全影响评估报告表明存在高风险时。</li>
        </ul>

        <p>
          4.若您不同意修改后的隐私政策，您有权并应立即停止使用ODAILY服务。但请您理解，如您不同意修改后的隐私政策，将可能导致您将无法继续完整正常使用相关ODAILY服务和部分功能，或者无法达到我们预期到达的服务效果。
        </p>

        <h6>十．我们对去世用户的个人信息保护</h6>
        <p>
          我们从2021年11月1日起，根据《个人信息保护法》的相关规定制订死者个人信息保护制度。ODAILY用户（仅限自然人）去世后，其近亲属为了自身的合法、正当利益，可以通过本隐私政策第十一条提供的联系方式，对去世用户的相关个人信息行使查阅、复制、更正、删除等权利，但是去世用户生前另有安排的除外。
        </p>

        <h6>十一．您如何联系我们</h6>
        <p>
          如您对个人信息保护问题有任何投诉、建议、疑问，或您对本隐私政策有任何疑问需要我们进行解释说明，您可以通过以下方式联系我们，我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内予以答复：
        </p>
        <p>1.发送邮件至om@odaily.email与我们个人信息保护负责人联系；</p>
        <p>2.您可通过“我的” → “意见反馈”→ “其他问题”与我们联系。</p>

        <h6>十二．争议解决</h6>
        <p>
          因本隐私政策以及我们处理您个人信息事宜引起的任何争议，您可诉至北京市朝阳区人民法院。如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。
        </p>
      </div>
    );
  }
}

export default PrivacyZhcn;
